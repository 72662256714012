import {
  UPDATE_GAIN_PROPS_CHANGED,
  UPDATE_GAIN_INITIAL_STATE,
  UPDATE_GAIN_TO_CUSTOMERS_LIST,
  AppThunk,
} from './types';

import {
  UpdateGainPropsChangedAction,
  UpdateGainInitialStateAction,
} from '../modules/UpdateGain/updateGain.types';
import { updateCustumerGain } from '../apis/updateGainApi';

export const updateGainPropsChanged = (
  prop: string,
  value: string
): UpdateGainPropsChangedAction => {
  return { type: UPDATE_GAIN_PROPS_CHANGED, prop, value };
};

export const updateGainInitialState = (): UpdateGainInitialStateAction => {
  return { type: UPDATE_GAIN_INITIAL_STATE };
};

export const updateGain = (
  walletId: string,
  customerId: string,
  gain: number,
  callback: () => void
): AppThunk => (dispatch) => {
  dispatch({
    type: UPDATE_GAIN_PROPS_CHANGED,
    prop: 'loading',
    value: true,
  });
  updateCustumerGain(walletId, customerId, gain).then((res: any) => {
    if (res.data.status === 'success') {
      //dispatch new gain to wallet reducer
      dispatch({
        type: UPDATE_GAIN_TO_CUSTOMERS_LIST,
        gain: res.data.response.data.gain,
        customerId,
      });
      dispatch({
        type: UPDATE_GAIN_PROPS_CHANGED,
        prop: 'loading',
        value: false,
      });
      callback();
    } else {
      dispatch({
        type: UPDATE_GAIN_PROPS_CHANGED,
        prop: 'loading',
        value: false,
      });
    }
  });
};
