import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { useStyles } from './table.styles';
import moment from 'moment';
import { getDataFromCache } from '../../utils/cache';

type RowProps = {
  row: any;
};

const Row: React.FC<RowProps> = (props) => {
  const intl = useIntl();

  const { row } = props;

  const classes = useStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell align='center'>{row.email}</TableCell>
        <TableCell align='center' className={classes.capital}>
          {row.passKind}
        </TableCell>
        <TableCell align='center'>
          {moment(row.createdAt).format('LL')}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

type CollapsibleTableProps = {
  data: Array<any>;
};

const SentDemosTable: React.FC<CollapsibleTableProps> = (props) => {
  const classes = useStyles();
  const intl = useIntl();
  const { data } = props;

  return (
    <TableContainer
      className={classes.tableContainer}
      component={Paper}
      elevation={0}
    >
      <Table stickyHeader aria-label='sticky table'>
        <TableHead>
          <TableRow>
            <TableCell align='center'>
              {intl.formatMessage({ id: 'customersTable.email' })}
            </TableCell>
            <TableCell align='center'>
              {intl.formatMessage({ id: 'customersTable.passKind' })}
            </TableCell>
            <TableCell align='center'>
              {intl.formatMessage({ id: 'customersTable.createdAt' })}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <Row key={row._id} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SentDemosTable;
