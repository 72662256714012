import axios from 'axios';
import { URL } from './config';
import { OfferApiResponse } from './ApiTypes';

export const getOffersList = (walletID: string | null) => {
  const requestURL = `${URL}/wallets/${walletID}/offers`;

  return axios
    .get(requestURL)
    .then(({ data }: { data: Array<OfferApiResponse> }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const updateOffer = (
  offerId: string,
  walletId: string,
  action: string
) => {
  const requestURL = `${URL}/wallets/${walletId}/offers/${offerId}`;
  return axios
    .put(requestURL, { status: action })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};
