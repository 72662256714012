import styled from 'styled-components';

export const Wrapper = styled('div')<{
  backgroundColor: any;
  mainContentTextColor: string;
  height: any;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  //height: 57vh;
  height : ${(props) => props.height};
  font-family: 'Google Sans',Roboto,Arial,sans-serif !important;
  background-color: #201f24;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  width: 350px;
  align-items: center;
  justify-content: flex-start;


  .hidden{
    display: none;
  }

  .mainSection {
    background-color: ${(props) => props.backgroundColor.hex};
    width: 100%;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    height: fit-content;
    align-items: center;
    .mainSection-header {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
.mainSection-origin-destination{

  margin-top: 20px;
  display: flex;
justify-content: space-between;
width: 100%;
}
.mainSection-origin-name , .mainSection-destination-name{
  letter-spacing: .07272727em;
font-family: Roboto,Arial,sans-serif;
font-size: .6875rem;
font-weight: 500;
line-height: 1rem;
text-transform: uppercase;
line-height: 11px;
padding-bottom: 5px;
min-height: 11px;
overflow-wrap: break-word;
width: 100%;
word-wrap: break-word;
}

.arrow{
  display: flex;
justify-content: center;
align-items: center;
}
.arrivalDate > h3:nth-child(2){
  text-align:right;
}
.mainSection-destination-destinationCode{
  text-align: right;
}

    .mainSection-header-logo {
      -webkit-align-items: center;
      align-items: center;
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      height: 64px;
      -webkit-justify-content: center;
      justify-content: center;
      margin-bottom: 14px;
      img {
        background: #fff;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        height: 64px;
        width: 64px;
    }
      }
    }
    .mainSection-content-banner {
      height: 100px;
      width: 270px;
      overflow: hidden;
      border-radius: 3px;
      margin-top: 20px;
      img {
        object-fit: contain;
        height: 100px;
        width: 270px;
      }
    }
    .mainSection-content-enseigne {
       display: flex;
      margin-top: 20px;
      flex-direction: column;
      align-items: center;
      gap: 5px;
      width: 100%;
      h4 {
        font-weight: 300;
        text-align: center;
        color: ${(props) => props.mainContentTextColor};
        margin: 0;
        word-break: break-all;
      }
      h3 {
        font-family: 'Google Sans',Roboto,Arial,sans-serif;
        font-size: 1.375rem;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 1.75rem;
        line-height: 22px;
        margin-top: 6px;
        color: ${(props) => props.mainContentTextColor};
    
      }
    }
    .mainSection-origin-stationCode,.mainSection-destination-destinationCode {

      font-family: 'Google Sans',Roboto,Arial,sans-serif;
font-size: 2.25rem !important;
font-weight: 400!important;
letter-spacing: 0;
line-height: 2.75rem;
line-height: 36px;
overflow-wrap: break-word;
word-wrap: break-word;
    }
    .mainSection-content-dates {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
    .mainSection-content-passengerName{
      text-align: center;
      margin-top: 34px;
    }
    .mainSection-content-barCode {
      margin-top: 20px;
      .barCode {
        width: 270px;
        background: #ffffff;
        height: 100px;
        display: flex;
        border-radius: 3px;
        justify-content: center;
        align-items: center;
        img {
          width: 219px;
          height: 65px;
          object-fit: initial;
        }
      }
      .walletId {
        font-size: 12px;
        color: ${(props) => props.mainContentTextColor};
        text-align: center;
        margin: 7px 0px 10px 0px;
      }
    }
    .mainSection-label {
      color: ${(props) => props.mainContentTextColor};
      font-weight: 300;
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 0.1em;
      margin: 0px;
    }
    .mainSection-data {
      color: ${(props) => props.mainContentTextColor};
      font-size: 16px;
      font-weight: 400;
      margin: 0px;
    }
  }
  .dataSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 0px 10px 20px 10px;
    .sectionIcon {
      margin-top: 20px;
      gap: 10px;
      align-items: center;
      display: flex;
      .data {
        color: #ffffff;
        font-weight: 500;
        font-size: 14px;
        margin: 0px;
        word-break: break-all;
      }
    }
    .sectionLabel {
      margin-top: 20px;
      .label {
        color: #999999;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 0.075em;
        margin: 0px;
      }
      .data {
        color: #ffffff;
        font-weight: 500;
        font-size: 14px;
        margin: 0px;
        word-break: break-all;
      }
    }
    
  }
  .details_btn {
      display: inline-flex;
      color: #fff;
      .MuiSvgIcon-root {
      fill: #3f51b5;
      }
    }
    .margins {
      margin-top: 15px;
    }
`;
