import React from 'react';
import {
  GoogleMap,
  Marker,
  StandaloneSearchBox,
  LoadScript,
} from '@react-google-maps/api';
import { useIntl } from 'react-intl';

type MapComponentType = {
  locations: {
    name: null;
    longitude: number;
    latitude: number;
    relevantText: string;
  }[];
  onChange: (value: any, prop: string, type: string) => void;
};

type MarkerProps = {
  lng: number;
  lat: number;
};

const mapContainerStyle = {
  height: '400px',
  width: '100%',
  marginBottom: '40px',
};

const MapComponent: React.FC<MapComponentType> = (props) => {
  const [searchBox, setSearchBox] = React.useState<any>(null);
  const intl = useIntl();

  const handleLoad = React.useCallback((ref) => {
    setSearchBox(ref);
  }, []);

  const hanldePlacesChanged = React.useCallback(() => {
    props.onChange(
      {
        lat: searchBox.getPlaces()[0].geometry.location.lat(),
        lng: searchBox.getPlaces()[0].geometry.location.lng(),
      },
      'locations',
      'latLng'
    );
  }, [searchBox]);

  const [test, setTest] = React.useState('');

  const [map, setMap] = React.useState(null);

  return (
    <LoadScript
      id='script-loader'
      googleMapsApiKey='AIzaSyCGHI3mKErwGzcUXJtg7pRI09hhRR6qaa0'
      libraries={['places']}
    >
      <StandaloneSearchBox
        onLoad={handleLoad}
        onPlacesChanged={hanldePlacesChanged}
      >
        <input
          type='text'
          placeholder={intl.formatMessage({ id: 'mapComponent.label.search' })}
          className='MapSearch'
          style={{
            boxSizing: `border-box`,
            border: `1px solid transparent`,
            width: `clamp(190px, 20vw + 1.5rem, 350px)`,
            height: `38px`,
            padding: `0 12px`,
            borderRadius: `3px`,
            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            fontSize: `14px`,
            outline: `none`,
            textOverflow: `ellipses`,
            position: 'absolute',
            top: 37,
            right: 60,
          }}
        />
      </StandaloneSearchBox>

      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={{
          lat: props.locations[0].latitude,
          lng: props.locations[0].longitude,
        }}
        zoom={15}
        onClick={(e: google.maps.MapMouseEvent) =>
          props.onChange(
            { lat: e?.latLng?.lat() || 0, lng: e?.latLng?.lng() },
            'locations',
            'latLng'
          )
        }
      >
        <Marker
          position={{
            lat: props.locations[0].latitude,
            lng: props.locations[0].longitude,
          }}
        />
      </GoogleMap>
    </LoadScript>
  );
};

export default MapComponent;
