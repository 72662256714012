import {
  COUPONS_GET_LIST_COUPONS,
  COUPONS_ADD_COUPON_TO_LIST,
  COUPONS_INITIAL_STATE,
  COUPONS_DELETE_COUPON,
  COUPONS_DELETE_COUPON_LOADING,
  COUPONS_UPDATE_COUPON_STATUS,
  COUPONS_UPDATE_PROPS,
  COUPONS_GET_SENT_COUPONS,
  COUPONS_PROPS_CHANGED,
  UPDATE_SENT_COUPONS_LIST,
  COUPONS_DELETE_CUSTOMER_COUPON,
  COUPONS_FILTER_COUPONS,
} from '../actions/types';
import {
  CouponsActionTypes,
  CouponsState,
} from '../modules/Coupons/coupons.types';
import { CouponApiResponse } from '../apis/ApiTypes';
import moment from 'moment';

const INITIAL_STATE: CouponsState = {
  couponsList: [],
  couponsFilteredList: [],
  loading: true,
  loadingSend: false,
  search: '',
  total: 0,
  select: '',
  status: 'ALL',
  startDate: null,
  endDate: null,
  allTime: false,
  //errors
  startDateError: '',
  endDateError: '',
};

export default (state = INITIAL_STATE, action: CouponsActionTypes) => {
  switch (action.type) {
    case UPDATE_SENT_COUPONS_LIST:
      let couponsListO = [...state.couponsList];

      couponsListO.forEach((row: any, index: number) => {
        if (
          row._id === action.row._id &&
          row.customerId === action.row.customerId
        ) {
          couponsListO[index].value = action.row.value;
          couponsListO[index].description = action.row.description;
          couponsListO[index].expirationDate = action.row.expirationDate;
        }
      });

      return { ...state, couponsList: couponsListO };

    case COUPONS_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };

    case COUPONS_GET_LIST_COUPONS:
      let couponList = action.payload;

      couponList = couponList.map((coupon) => {
        coupon.couponPictureUrl = `${coupon.couponPictureUrl}?${Math.random()}`;
        return coupon;
      });

      return {
        ...state,
        couponsList: couponList,
        couponsFilteredList: couponList,
        loading: false,
      };

    case COUPONS_GET_SENT_COUPONS:
      return {
        ...state,
        couponsList: action.payload,
        total: action.total,
        loading: false,
      };

    case COUPONS_ADD_COUPON_TO_LIST: {
      let couponsSortedByExpirartionDate: Array<CouponApiResponse> = Object.assign(
        [],
        state.couponsList
      );

      couponsSortedByExpirartionDate.push(action.payload);
      couponsSortedByExpirartionDate.sort(
        (a, b) =>
          <any>new Date(a.expirationDate) - <any>new Date(b.expirationDate)
      );
      return {
        ...state,
        couponsList: couponsSortedByExpirartionDate,
      };
    }
    case COUPONS_DELETE_COUPON_LOADING: {
      return { ...state, loading: true };
    }
    case COUPONS_UPDATE_PROPS: {
      return {
        ...state,
        [action.prop]: action.value,
      };
    }
    case COUPONS_UPDATE_COUPON_STATUS: {
      let coupons: Array<CouponApiResponse> = Object.assign(
        [],
        state.couponsList
      );

      for (let index = 0; index < coupons.length; index++) {
        const c = coupons[index];
        if (c._id == action.id) {
          c.status = action.status;
          break;
        }
      }

      return {
        ...state,
        couponsList: coupons,
      };
    }
    case COUPONS_DELETE_COUPON:
      var newCouponsList = state.couponsList.filter(function (item) {
        return item._id !== action.payload;
      });
      return { ...state, loading: false, couponsList: newCouponsList };

    case COUPONS_DELETE_CUSTOMER_COUPON:
      var newCouponsList = state.couponsList.filter(function (item) {
        return item.passId !== action.payload;
      });
      return { ...state, loading: false, couponsList: newCouponsList };

    case COUPONS_FILTER_COUPONS: {
      if (action.allTime) {
        return {
          ...state,
          startDate: null,
          endDate: null,
          couponsList: action.payload.filter(function (item) {
            return item.status !== 'DELETED';
          }),
        };
      }
      return {
        ...state,
        couponsList: action.payload.filter(function (item) {
          return item.status !== 'DELETED';
        }),
      };
    }

    case COUPONS_INITIAL_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};
