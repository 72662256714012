import axios from 'axios';
import { URL } from './config';
import { CouponState } from '../modules/Coupon/coupon.types';

export const createCoupon = (walletId: string | null, coupon: any) => {
  const requestURL = `${URL}/wallets/${walletId}/coupons`;
  return axios
    .post(requestURL, coupon)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export const editCoupon = (
  walletId: string | null,
  couponId: string | null,
  coupon: any
) => {
  const requestURL = `${URL}/wallets/${walletId}/coupons/${couponId}/template`;
  return axios
    .put(requestURL, coupon)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};
export const editCustomerCoupon = (
  walletId: string | null,
  couponId: string | null,
  customerId: string | null,
  coupon: any
) => {
  const requestURL = `${URL}/wallets/${walletId}/coupons/${couponId}/customers/${customerId}`;
  return axios
    .put(requestURL, coupon)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};
