import React from 'react';
import { useIntl } from 'react-intl';

import { Route, Switch, RouteComponentProps } from 'react-router-dom';
import SideBar from '../../components/Drawer';

import Coupons from '../../modules/Coupons';
import { useStyles } from './couponsRouter.styles';
import NotFound from '../../modules/NotFound';
import CouponCustomers from '../../modules/CouponCustomers';
//Modules

const CouponsRouter: React.FC<RouteComponentProps> = (props) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.rootAppRouter}>
      <SideBar title={intl.formatMessage({ id: 'drawer.menuList.coupons' })}>
        <Switch>
          {' '}
          <Route path='/coupons/:couponID' component={CouponCustomers} />
          <Route path='/coupons' component={Coupons} />
          <Route path='/coupons/*' component={NotFound} />
        </Switch>
      </SideBar>
    </div>
  );
};

export default CouponsRouter;
