import React from 'react';
import { useIntl } from 'react-intl';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FranceFlag from '../../icons/FranceFlag';
import EnglishFlag from '../../icons/EnglishFlag';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      display: 'block',
      height: '34px',
      minWidth: '35px',
      border: '1px solid #fff',
    },
    root: {
      height: '35px',
    },
    itemIcon: {
      minWidth: '35px',
    },
  })
);

export default function LanguagesSelect() {
  const classes = useStyles();
  const intl = useIntl();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChangeLanguage = (value: string) => {
    localStorage.setItem('language', value);
    window.location.reload();
    handleClose();
  };

  const showLanguageSelected = () => {
    if (localStorage.getItem('language') === 'fr') {
      return <FranceFlag />;
    }
    return <EnglishFlag />;
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      style={{
        position: 'absolute',
        color: '#fff',
        top: '1rem',
        right: '2.5rem',
      }}
    >
      <Button className={classes.button} onClick={handleClick}>
        {showLanguageSelected()}
      </Button>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          value={'fr'}
          onClick={() => handleChangeLanguage('fr')}
          className={classes.root}
        >
          <ListItemIcon className={classes.itemIcon}>
            <FranceFlag />
          </ListItemIcon>
          <ListItemText
            primary={intl.formatMessage({
              id: 'settings.langauges.french',
            })}
          />
        </MenuItem>
        <MenuItem
          value={'en'}
          onClick={() => handleChangeLanguage('en')}
          className={classes.root}
        >
          <ListItemIcon className={classes.itemIcon}>
            <EnglishFlag />
          </ListItemIcon>
          <ListItemText
            primary={intl.formatMessage({
              id: 'settings.langauges.english',
            })}
          />
        </MenuItem>
      </Menu>
    </div>
  );
}
