let airports: { [key: string]: any } = require('./airports.json');

function keyBy(entities: any, id = 'id') {
  return entities.reduce((acc: any, entity: any) => {
    acc[entity[id]] = entity;
    return acc;
  }, {});
}

airports = keyBy(airports, 'iata');

export const lookupByIataCode = function (iataCode: string) {
  return airports[iataCode];
};
