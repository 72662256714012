import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import { useIntl } from 'react-intl';
import Text from '../../commons/Text';
import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Wrapper } from './notificationsSettingsTable.style';
import FormControlLabel from '@material-ui/core/FormControlLabel';
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(
  subTitle: string | any,
  emailCheckBox: JSX.Element | null,
  smsCheckBox: JSX.Element | null,
  pushNotifCheckBox: JSX.Element | null,
  isTitle: boolean = false
) {
  return { subTitle, emailCheckBox, smsCheckBox, pushNotifCheckBox, isTitle };
}

interface SettingsProps {
  // storecard
  enableNewStorecardEmailMessage: boolean;
  enableStorecardRemainderEmailMessage: boolean;
  enableNewStorecardSMSMessage: boolean;
  enableStorecardRemainderSMSMessage: boolean;
  enableNewUpdateNotification: boolean;

  // event
  enableUpdateEventEmailMessage: boolean;
  enableUpdateEventNotification: boolean;

  // coupon
  enableWelcomeCouponEmailMessageForExternalClients: boolean;
  enableWelcomeCouponEmailMessageForInternalClients: boolean;
  enableWelcomeCouponEmailMessageForCRMClients: boolean;
  enableUpdateCouponSMSMessage: boolean;
  enableWelcomeCouponSMSMessageForExternalClients: boolean;
  enableWelcomeCouponSMSMessageForInternalClients: boolean;
  enableWelcomeCouponSMSMessageForCRMClients: boolean;
  enableWelcomeCouponNotificationMessageForExternalClients: boolean;
  enableWelcomeCouponNotificationMessageForInternalClients: boolean;
  enableWelcomeCouponNotificationMessageForCRMClients: boolean;
  enableUpdateCouponNotificationMessage: boolean;
  enableNewCouponNotificationMessage: boolean;

  // offer
  enableExpiredOfferEmailMessage: boolean;
  enableNewOfferNotificationMessage: boolean;
  enableExpiredOfferNotificationMessage: boolean;
  enableExpiredOfferSMSMessage: boolean;
  //
  enableNewUpdateEmailMessage: boolean;
  enableOfferEmailMessage: boolean;
  enableOfferSMSMessage: boolean;
  enableCouponEmailMessage: boolean;
  enableCouponSMSMessage: boolean;
  enableUpdateCouponEmailMessage: boolean;
  enableStorecardReminderEmailMessage: boolean;
  enableStorecardReminderSMSMessage: boolean;
  handleChangeNotificationsCheckBox: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
}

export default function BasicTable(props: SettingsProps) {
  const {
    //storecard
    enableNewStorecardEmailMessage,
    enableStorecardRemainderEmailMessage,
    enableNewStorecardSMSMessage,
    enableStorecardRemainderSMSMessage,
    enableNewUpdateNotification,
    enableNewUpdateEmailMessage,
    //event
    enableUpdateEventEmailMessage,
    enableUpdateEventNotification,

    //coupon
    enableWelcomeCouponEmailMessageForExternalClients,
    enableWelcomeCouponEmailMessageForInternalClients,
    enableWelcomeCouponEmailMessageForCRMClients,
    enableUpdateCouponSMSMessage,
    enableWelcomeCouponSMSMessageForExternalClients,
    enableWelcomeCouponSMSMessageForInternalClients,
    enableWelcomeCouponSMSMessageForCRMClients,
    enableWelcomeCouponNotificationMessageForExternalClients,
    enableWelcomeCouponNotificationMessageForInternalClients,
    enableWelcomeCouponNotificationMessageForCRMClients,
    enableUpdateCouponNotificationMessage,
    enableNewCouponNotificationMessage,
    enableUpdateCouponEmailMessage,

    //offer
    enableExpiredOfferEmailMessage,
    enableNewOfferNotificationMessage,
    enableExpiredOfferNotificationMessage,
    enableOfferEmailMessage,
    enableOfferSMSMessage,
    enableCouponEmailMessage,
    enableCouponSMSMessage,
    handleChangeNotificationsCheckBox,
    enableExpiredOfferSMSMessage,
    enableStorecardReminderEmailMessage,
    enableStorecardReminderSMSMessage,
  } = props;
  const intl = useIntl();
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const rows = [
    createData(
      intl.formatMessage({
        id: 'notificationTable.label.storecard',
      }),
      null,
      null,
      null,
      true
    ),
    createData(
      intl.formatMessage({
        id: 'notificationTable.label.new',
      }),
      <FormControlLabel
        control={
          <Checkbox
            checked={enableNewStorecardEmailMessage}
            onChange={handleChangeNotificationsCheckBox}
            name='enableNewStorecardEmailMessage'
          />
        }
        label='Email'
      />,
      <FormControlLabel
        control={
          <Checkbox
            checked={enableNewStorecardSMSMessage}
            onChange={handleChangeNotificationsCheckBox}
            name='enableNewStorecardSMSMessage'
          />
        }
        label='SMS'
      />,

      <FormControlLabel
        disabled
        control={
          <Checkbox
            checked={false}
            onChange={handleChangeNotificationsCheckBox}
            name='2'
          />
        }
        label='Notification Push'
      />
    ),
    createData(
      intl.formatMessage({
        id: 'notificationTable.label.update',
      }),
      <FormControlLabel
        control={
          <Checkbox
            checked={enableNewUpdateEmailMessage}
            onChange={handleChangeNotificationsCheckBox}
            name='enableNewUpdateEmailMessage'
          />
        }
        label='Email'
      />,
      <FormControlLabel disabled control={<Checkbox name='1' />} label='SMS' />,
      <FormControlLabel
        control={
          <Checkbox
            checked={enableNewUpdateNotification}
            onChange={handleChangeNotificationsCheckBox}
            name='enableNewUpdateNotification'
          />
        }
        label='Notification push'
      />
    ),
    createData(
      intl.formatMessage({
        id: 'notificationTable.label.reminder',
      }),
      <FormControlLabel
        control={
          <Checkbox
            checked={enableStorecardReminderEmailMessage}
            onChange={handleChangeNotificationsCheckBox}
            name='enableStorecardReminderEmailMessage'
          />
        }
        label='Email'
      />,
      <FormControlLabel
        control={
          <Checkbox
            checked={enableStorecardReminderSMSMessage}
            onChange={handleChangeNotificationsCheckBox}
            name='enableStorecardReminderSMSMessage'
          />
        }
        label='SMS'
      />,
      <FormControlLabel
        disabled
        control={<Checkbox name='1' />}
        label='Notification push'
      />
    ),
    createData(
      intl.formatMessage({
        id: 'notificationTable.label.coupon',
      }),
      null,
      null,
      null,
      true
    ),

    createData(
      intl.formatMessage({
        id: 'notificationTable.label.new',
      }),
      <FormControlLabel
        control={
          <Checkbox
            checked={enableCouponEmailMessage}
            onChange={handleChangeNotificationsCheckBox}
            name='enableCouponEmailMessage'
          />
        }
        label='Email'
      />,
      <FormControlLabel
        control={
          <Checkbox
            checked={enableCouponSMSMessage}
            onChange={handleChangeNotificationsCheckBox}
            name='enableCouponSMSMessage'
          />
        }
        label='SMS'
      />,
      <FormControlLabel
        control={
          <Checkbox
            checked={enableNewCouponNotificationMessage}
            onChange={handleChangeNotificationsCheckBox}
            name='enableNewCouponNotificationMessage'
          />
        }
        label='Notification push'
      />
    ),
    createData(
      intl.formatMessage({
        id: 'notificationTable.label.update',
      }),

      <FormControlLabel
        control={
          <Checkbox
            checked={enableUpdateCouponEmailMessage}
            onChange={handleChangeNotificationsCheckBox}
            name='enableUpdateCouponEmailMessage'
          />
        }
        label='Email'
      />,
      <FormControlLabel
        control={
          <Checkbox
            checked={enableUpdateCouponSMSMessage}
            onChange={handleChangeNotificationsCheckBox}
            name='enableUpdateCouponSMSMessage'
          />
        }
        label='SMS'
      />,
      <FormControlLabel
        control={
          <Checkbox
            checked={enableUpdateCouponNotificationMessage}
            onChange={handleChangeNotificationsCheckBox}
            name='enableUpdateCouponNotificationMessage'
          />
        }
        label='Notification push'
      />
    ),
    createData(
      intl.formatMessage({
        id: 'notificationTable.label.welcomeInternal',
      }),
      <FormControlLabel
        control={
          <Checkbox
            checked={enableWelcomeCouponEmailMessageForInternalClients}
            onChange={handleChangeNotificationsCheckBox}
            name='enableWelcomeCouponEmailMessageForInternalClients'
          />
        }
        label='Email'
      />,
      <FormControlLabel
        control={
          <Checkbox
            checked={enableWelcomeCouponSMSMessageForInternalClients}
            onChange={handleChangeNotificationsCheckBox}
            name='enableWelcomeCouponSMSMessageForInternalClients'
          />
        }
        label='SMS'
      />,
      <FormControlLabel
        control={
          <Checkbox
            checked={enableWelcomeCouponNotificationMessageForInternalClients}
            onChange={handleChangeNotificationsCheckBox}
            name='enableWelcomeCouponNotificationMessageForInternalClients'
          />
        }
        label='Notification push'
      />
    ),

    createData(
      intl.formatMessage({
        id: 'notificationTable.label.welcomeCRM',
      }),
      <FormControlLabel
        control={
          <Checkbox
            checked={enableWelcomeCouponEmailMessageForCRMClients}
            onChange={handleChangeNotificationsCheckBox}
            name='enableWelcomeCouponEmailMessageForCRMClients'
          />
        }
        label='Email'
      />,
      <FormControlLabel
        control={
          <Checkbox
            checked={enableWelcomeCouponSMSMessageForCRMClients}
            onChange={handleChangeNotificationsCheckBox}
            name='enableWelcomeCouponSMSMessageForCRMClients'
          />
        }
        label='SMS'
      />,
      <FormControlLabel
        control={
          <Checkbox
            checked={enableWelcomeCouponNotificationMessageForCRMClients}
            onChange={handleChangeNotificationsCheckBox}
            name='enableWelcomeCouponNotificationMessageForCRMClients'
          />
        }
        label='Notification push'
      />
    ),
    createData(
      intl.formatMessage({
        id: 'notificationTable.label.welcomeExternal',
      }),
      <FormControlLabel
        control={
          <Checkbox
            checked={enableWelcomeCouponEmailMessageForExternalClients}
            onChange={handleChangeNotificationsCheckBox}
            name='enableWelcomeCouponEmailMessageForExternalClients'
          />
        }
        label='Email'
      />,
      <FormControlLabel
        control={
          <Checkbox
            checked={enableWelcomeCouponSMSMessageForExternalClients}
            onChange={handleChangeNotificationsCheckBox}
            name='enableWelcomeCouponSMSMessageForExternalClients'
          />
        }
        label='SMS'
      />,
      <FormControlLabel
        control={
          <Checkbox
            checked={enableWelcomeCouponNotificationMessageForExternalClients}
            onChange={handleChangeNotificationsCheckBox}
            name='enableWelcomeCouponNotificationMessageForExternalClients'
          />
        }
        label='Notification push'
      />
    ),
    createData(
      intl.formatMessage({
        id: 'notificationTable.label.event',
      }),
      null,
      null,
      null,
      true
    ),

    createData(
      intl.formatMessage({
        id: 'notificationTable.label.update',
      }),
      <FormControlLabel
        control={
          <Checkbox
            checked={enableUpdateEventEmailMessage}
            onChange={handleChangeNotificationsCheckBox}
            name='enableUpdateEventEmailMessage'
          />
        }
        label='Email'
      />,
      <FormControlLabel disabled control={<Checkbox name='1' />} label='SMS' />,
      <FormControlLabel
        control={
          <Checkbox
            checked={enableUpdateEventNotification}
            onChange={handleChangeNotificationsCheckBox}
            name='enableUpdateEventNotification'
          />
        }
        label='Notification push'
      />
    ),
    createData(
      intl.formatMessage({
        id: 'notificationTable.label.offer',
      }),
      null,
      null,
      null,
      true
    ),

    createData(
      intl.formatMessage({
        id: 'notificationTable.label.new',
      }),
      <FormControlLabel
        control={
          <Checkbox
            checked={enableOfferEmailMessage}
            onChange={handleChangeNotificationsCheckBox}
            name='enableOfferEmailMessage'
          />
        }
        label='Email'
      />,
      <FormControlLabel
        control={
          <Checkbox
            checked={enableOfferSMSMessage}
            onChange={handleChangeNotificationsCheckBox}
            name='enableOfferSMSMessage'
          />
        }
        label='SMS'
      />,
      <FormControlLabel
        control={
          <Checkbox
            checked={enableNewOfferNotificationMessage}
            onChange={handleChangeNotificationsCheckBox}
            name='enableNewOfferNotificationMessage'
          />
        }
        label='Notification push'
      />
    ),
    createData(
      intl.formatMessage({
        id: 'notificationTable.label.expired',
      }),
      <FormControlLabel
        control={
          <Checkbox
            checked={enableExpiredOfferEmailMessage}
            onChange={handleChangeNotificationsCheckBox}
            name='enableExpiredOfferEmailMessage'
          />
        }
        label='Email'
      />,
      <FormControlLabel
        control={
          <Checkbox
            checked={enableExpiredOfferSMSMessage}
            onChange={handleChangeNotificationsCheckBox}
            name='enableExpiredOfferSMSMessage'
          />
        }
        label='SMS'
      />,
      <FormControlLabel
        control={
          <Checkbox
            checked={enableExpiredOfferNotificationMessage}
            onChange={handleChangeNotificationsCheckBox}
            name='enableExpiredOfferNotificationMessage'
          />
        }
        label='Notification push'
      />
    ),
  ];

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
      },
      heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
        marginLeft: '140px',
      },
      secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
      },

      checkBoxes: {
        marginLeft: '140px',
        width: '295px',
        display: 'inline-block',
      },
      checkBox: {
        margin: ' auto 17px',
      },
      label: {
        marginTop: '15px !important',
        marginBottom: '32px !important',
      },
    })
  );
  const classes = useStyles();

  return (
    <>
      {' '}
      <Text
        fontWeight={500}
        fontSize={18}
        lineHeight={24}
        color='#404040'
        style={{ alignSelf: 'flex-start' }}
        className={classes.label}
      >
        {intl.formatMessage({
          id: 'notificationTable.label.communicationSettings',
        })}
      </Text>
      <Wrapper>
        <div className={classes.root}>
          <Accordion
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1bh-content'
              id='panel1bh-header'
            >
              <Typography className={classes.heading}>
                {rows[0].subTitle}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <span className={classes.checkBoxes}> {rows[1].subTitle}</span>
                <span className={classes.checkBox}>
                  {' '}
                  {rows[1].emailCheckBox}
                </span>
                <span className={classes.checkBox}> {rows[1].smsCheckBox}</span>
                <span className={classes.checkBox}>
                  {rows[1].pushNotifCheckBox}
                </span>
              </div>
              <div>
                <span className={classes.checkBoxes}> {rows[2].subTitle}</span>
                <span className={classes.checkBox}>
                  {' '}
                  {rows[2].emailCheckBox}
                </span>
                <span className={classes.checkBox}> {rows[2].smsCheckBox}</span>
                <span className={classes.checkBox}>
                  {rows[2].pushNotifCheckBox}
                </span>
              </div>{' '}
              <div>
                <span className={classes.checkBoxes}> {rows[3].subTitle}</span>
                <span className={classes.checkBox}>
                  {' '}
                  {rows[3].emailCheckBox}
                </span>
                <span className={classes.checkBox}> {rows[3].smsCheckBox}</span>
                <span className={classes.checkBox}>
                  {rows[3].pushNotifCheckBox}
                </span>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === 'panel2'}
            onChange={handleChange('panel2')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1bh-content'
              id='panel1bh-header'
            >
              <Typography className={classes.heading}>
                {rows[4].subTitle}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <span className={classes.checkBoxes}> {rows[5].subTitle}</span>
                <span className={classes.checkBox}>
                  {' '}
                  {rows[5].emailCheckBox}
                </span>
                <span className={classes.checkBox}> {rows[5].smsCheckBox}</span>
                <span className={classes.checkBox}>
                  {rows[5].pushNotifCheckBox}
                </span>
              </div>
              <div>
                <span className={classes.checkBoxes}> {rows[6].subTitle}</span>
                <span className={classes.checkBox}>
                  {' '}
                  {rows[6].emailCheckBox}
                </span>
                <span className={classes.checkBox}> {rows[6].smsCheckBox}</span>
                <span className={classes.checkBox}>
                  {rows[6].pushNotifCheckBox}
                </span>
              </div>{' '}
              <div>
                <span className={classes.checkBoxes}> {rows[7].subTitle}</span>
                <span className={classes.checkBox}>
                  {' '}
                  {rows[7].emailCheckBox}
                </span>
                <span className={classes.checkBox}> {rows[7].smsCheckBox}</span>
                <span className={classes.checkBox}>
                  {rows[7].pushNotifCheckBox}
                </span>
              </div>{' '}
              <div>
                <span className={classes.checkBoxes}> {rows[8].subTitle}</span>
                <span className={classes.checkBox}>
                  {' '}
                  {rows[8].emailCheckBox}
                </span>
                <span className={classes.checkBox}> {rows[8].smsCheckBox}</span>
                <span className={classes.checkBox}>
                  {rows[8].pushNotifCheckBox}
                </span>
              </div>{' '}
              <div>
                <span className={classes.checkBoxes}> {rows[9].subTitle}</span>
                <span className={classes.checkBox}>
                  {' '}
                  {rows[9].emailCheckBox}
                </span>
                <span className={classes.checkBox}> {rows[9].smsCheckBox}</span>
                <span className={classes.checkBox}>
                  {rows[9].pushNotifCheckBox}
                </span>
              </div>
            </AccordionDetails>
          </Accordion>{' '}
          <Accordion
            expanded={expanded === 'panel3'}
            onChange={handleChange('panel3')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1bh-content'
              id='panel1bh-header'
            >
              <Typography className={classes.heading}>
                {rows[10].subTitle}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <span className={classes.checkBoxes}> {rows[11].subTitle}</span>
                <span className={classes.checkBox}>
                  {' '}
                  {rows[11].emailCheckBox}
                </span>
                <span className={classes.checkBox}>
                  {' '}
                  {rows[11].smsCheckBox}
                </span>
                <span className={classes.checkBox}>
                  {rows[11].pushNotifCheckBox}
                </span>
              </div>
            </AccordionDetails>
          </Accordion>{' '}
          <Accordion
            expanded={expanded === 'panel4'}
            onChange={handleChange('panel4')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1bh-content'
              id='panel1bh-header'
            >
              <Typography className={classes.heading}>
                {rows[12].subTitle}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <span className={classes.checkBoxes}> {rows[13].subTitle}</span>
                <span className={classes.checkBox}>
                  {' '}
                  {rows[13].emailCheckBox}
                </span>
                <span className={classes.checkBox}>
                  {' '}
                  {rows[13].smsCheckBox}
                </span>
                <span className={classes.checkBox}>
                  {rows[13].pushNotifCheckBox}
                </span>
              </div>
              <div>
                <span className={classes.checkBoxes}> {rows[14].subTitle}</span>
                <span className={classes.checkBox}>
                  {' '}
                  {rows[14].emailCheckBox}
                </span>
                <span className={classes.checkBox}>
                  {' '}
                  {rows[14].smsCheckBox}
                </span>
                <span className={classes.checkBox}>
                  {rows[14].pushNotifCheckBox}
                </span>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </Wrapper>
    </>
  );
}
