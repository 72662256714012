import React, { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import Handlebars from 'handlebars';
import { URL_IMAGE } from '../../apis/config';
import { useIntl } from 'react-intl';

export const HtmlRenderer: React.FC<any> = (props: any) => {
  const {
    socialMedia,
    website,
    address,
    emailMessage,
    logoPictureUrl,
    htmlCode,
  } = props;

  const intl = useIntl();

  const context = {
    jwtLink: '',
    emailMessageContent: emailMessage,
    walletAddress: address,
    walletLogo: `${URL_IMAGE}/${logoPictureUrl}`,
    customerLastName: 'Nom',
    customerFirstName: 'Prénom',
    facebookLink: socialMedia.facebook,
    instagramLink: socialMedia.instagram,
    linkedinLink: socialMedia.linkedin,
    websiteLink: website,
    year: new Date().getFullYear(),
  };
  const renderNewAccountEmailHtml = () => {
    try {
      const template = Handlebars.compile(htmlCode);
      return parse(DOMPurify.sanitize(template(context)));
    } catch (error) {
      console.log(
        '🚀 ~ file: index.tsx ~ line 23 ~ renderNewAccountEmailHtml ~ error',
        error
      );
      return <div>Le code html contient des erreurs </div>;
    }
  };

  return (
    <div>
      {htmlCode && (
        <span className='label'>
          {intl.formatMessage({
            id: 'settings.label.preview',
          })}
        </span>
      )}
      {renderNewAccountEmailHtml()}
    </div>
  );
};
