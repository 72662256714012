import {
  createTram,
  getTram,
  updateTram,
  editCustomerTram,
} from '../apis/tramApi';
import {
  TRAM_FORM_PROPS,
  TRAM_FORM_PROPS_CHANGED,
  AppThunk,
  TRAMS_ADD_TRAM_TO_LIST,
  TRAM_INITIAL_STATE,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
  TRAM_GET_TRAM_PROPS,
  TRAMS_GET_LIST_TRAMS,
  EDIT_TRAM_INITIAL_STATE,
  UPDATE_SENT_TRAMS_LIST,
  TRAM_PROPS_CHANGED,
  CUSTOMER_TRAM_FORM_PROPS_CHANGED,
  CUSTOMER_TRAM_FORM_PROPS,
} from './types';
import {
  TramInitialStateAction,
  TramPropsChangedAction,
  TramState,
  CustomerTramPropsChangedAction,
} from '../modules/Tram/tram.types';

import {
  validateFieldsTram,
  newCustomerTramObject,
  validateCustomerFieldsTram,
  newTramObject,
} from '../modules/Tram/tram.utils';
import { URL_IMAGE } from '../apis/config';
import { getTramsList } from '../apis/tramsApi';
import { TransitsApiResponse } from '../apis/ApiTypes';
import moment from 'moment';

export const tramPropsChanged = (
  prop: string,
  value: any
): TramPropsChangedAction => {
  return { type: TRAM_FORM_PROPS_CHANGED, prop, value };
};

export const addTram = (
  walletId: string | null,
  props: TramState,
  callback: () => void
): AppThunk => (dispatch) => {
  const { newState, valid } = validateFieldsTram(props);
  dispatch({ type: TRAM_FORM_PROPS, newState });
  const newTram = newTramObject(newState);

  if (valid) {
    dispatch({
      type: TRAM_FORM_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });

    createTram(walletId, newTram).then((res: any) => {
      if (res.status == 200) {
        dispatch({
          type: TRAMS_ADD_TRAM_TO_LIST,
          payload: res.data,
        });
        dispatch({
          type: SNACKBAR_SUCCESS,
          value: 'transit.success.added',
        });
        callback();
      } else {
        dispatch({
          type: SNACKBAR_ERROR,
          value: 'wallet.error.occured',
        });
        dispatch({
          type: TRAM_FORM_PROPS_CHANGED,
          prop: 'loading',
          value: false,
        });
      }
    });
  }
};

export const getTrambyId = (walletId: string, tramId: string): AppThunk => (
  dispatch
) => {
  getTram(walletId, tramId).then((res: any) => {
    const tram = res.response.data;
    dispatch({
      type: TRAM_GET_TRAM_PROPS,
      payload: {
        ...tram,
      },
    });
  });
};

export const editTramConfirm = (
  walletId: string | null,
  tramId: string | null,
  props: TramState,
  callback: () => void
): AppThunk => (dispatch) => {
  const { newState, valid } = validateFieldsTram(props);
  dispatch({ type: TRAM_FORM_PROPS, newState });
  const newTram = newTramObject(newState);

  if (valid) {
    dispatch({
      type: TRAM_FORM_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });

    updateTram(walletId, tramId, newTram)
      .then((res: any) => {
        if (res.status === 200) {
          dispatch({
            type: SNACKBAR_SUCCESS,
            value: 'transit.success.updated',
          });

          callback();
        } else if (res.status === 400) {
          dispatch({
            type: TRAM_FORM_PROPS_CHANGED,
            prop: 'messageError',
            value: 'coupon.error.datesUnavailable',
          });
          dispatch({
            type: SNACKBAR_ERROR,
            value: 'wallet.error.occured',
          });
        }
        dispatch({
          type: TRAM_FORM_PROPS_CHANGED,
          prop: 'loading',
          value: false,
        });
      })
      .finally(() => {
        getTramsList(walletId as any).then(
          (res: Array<TransitsApiResponse>) => {
            dispatch({
              type: TRAMS_GET_LIST_TRAMS,
              payload: res,
            });
          }
        );
      });
  }
};

export const tramInitialState = (): TramInitialStateAction => {
  return { type: TRAM_INITIAL_STATE };
};

export const editTramInitialState = (tram: any): any => {
  return {
    type: EDIT_TRAM_INITIAL_STATE,
    prop: 'payload',
    value: {
      ...tram,
      image: URL_IMAGE + '/' + tram.transitPictureUrl,
    },
  };
};

export const editTramCustomerConfirm = (
  walletId: string | null,
  tramId: string | null,
  customerId: string | null,
  props: TramState,
  callback: () => void
): AppThunk => (dispatch) => {
  const { newState, valid } = validateCustomerFieldsTram(props);
  dispatch({ type: CUSTOMER_TRAM_FORM_PROPS, newState });
  const { passengerType, tripType, arrivalDate, departureDate } = newState;
  const tramObject = {
    passengerType,
    tripType,
    arrivalDate: arrivalDate.format('YYYY-MM-DD').slice(0, 10),
    departureDate: moment(departureDate).format('YYYY-MM-DD'),
    customerId,
  };
  // newState.customerId = customerId;
  // const newTram = newCustomerTramObject(newState);

  if (valid) {
    dispatch({
      type: TRAM_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });

    editCustomerTram(walletId, tramId, tramObject).then((res: any) => {
      if (res.status === 200) {
        dispatch({
          type: SNACKBAR_SUCCESS,
          value: 'transit.success.updated',
        });

        callback();
      } else if (res.status === 400) {
        dispatch({
          type: TRAM_PROPS_CHANGED,
          prop: 'messageError',
          value: 'coupon.error.datesUnavailable',
        });
        dispatch({
          type: SNACKBAR_ERROR,
          value: 'wallet.error.occured',
        });
      }
      dispatch({
        type: TRAM_PROPS_CHANGED,
        prop: 'loading',
        value: false,
      });
    });
  }
};
export const customerTramPropsChanged = (
  prop: string,
  value: any
): CustomerTramPropsChangedAction => {
  return { type: CUSTOMER_TRAM_FORM_PROPS_CHANGED, prop, value };
};
