import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='40'
      height='40'
      fill='none'
      viewBox='0 0 51 51'
    >
      <path
        fill='#9D30BB'
        fillRule='evenodd'
        d='M46.849 18.246l1.83 1.83c1.45 1.424 2.238 3.355 2.238 5.389a7.56 7.56 0 01-2.187 5.413l-.05.052a1.973 1.973 0 00-.026.024l-1.805 1.805a3.81 3.81 0 00-1.12 2.697v2.615c0 4.22-3.432 7.653-7.652 7.653h-2.62a3.808 3.808 0 00-2.694 1.116l-1.831 1.83a7.612 7.612 0 01-5.416 2.237 7.701 7.701 0 01-5.415-2.21l-1.857-1.857a3.808 3.808 0 00-2.695-1.116h-2.618c-4.221 0-7.654-3.434-7.654-7.653v-2.615c0-1.02-.407-1.985-1.118-2.722l-1.831-1.805c-2.975-2.971-3-7.828-.026-10.825l1.857-1.858a3.823 3.823 0 001.118-2.72v-2.592c0-4.22 3.433-7.648 7.654-7.648h2.618c1.017 0 1.983-.409 2.695-1.12l1.831-1.83c2.975-2.997 7.831-2.997 10.832-.024l1.856 1.853a3.813 3.813 0 002.695 1.121h2.619c4.22 0 7.653 3.429 7.653 7.648v2.62c0 1.015.407 1.98 1.119 2.692zM18.956 34.26c.61 0 1.17-.23 1.577-.661l13.069-13.062a2.242 2.242 0 000-3.154 2.215 2.215 0 00-3.128 0L17.405 30.446a2.24 2.24 0 000 3.152c.407.432.967.66 1.551.66zm10.857-2.237c0 1.245.992 2.237 2.238 2.237 1.22 0 2.212-.992 2.212-2.237a2.215 2.215 0 00-2.212-2.211 2.22 2.22 0 00-2.238 2.211zM18.982 16.747c1.22 0 2.212.99 2.212 2.21a2.217 2.217 0 01-2.212 2.238 2.239 2.239 0 01-2.238-2.237c0-1.22 1.017-2.211 2.238-2.211z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}

export default Icon;
