import {
  SNACKBAR_CLEAR,
  SNACKBAR_ERROR,
  SNACKBAR_INFO,
  SNACKBAR_SUCCESS,
} from '../actions/types';

const INITIAL_STATE: any = {
  snackMessage: '',
  type: 'success',
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SNACKBAR_SUCCESS:
      return {
        ...state,
        snackMessage: action.value,
        type: 'success',
      };
    case SNACKBAR_ERROR:
      return {
        ...state,
        snackMessage: action.value,
        type: 'error',
      };

    case SNACKBAR_INFO:
      return {
        ...state,
        snackMessage: action.value,
        type: 'info',
      };
    case SNACKBAR_CLEAR:
      return {
        snackMessage: '',
      };
    default:
      return state;
  }
};
