import {
  PROFILE_PROPS_CHANGED,
  PROFILE_VALIDATION_PROPS,
  PROFILE_INITIAL_STATE,
  PROFILE_GET_WALLET_EMAIL_SETTINGS,
} from '../actions/types';

import { ObjectUser } from '../apis/ApiTypes';
import { getDataFromCache } from '../utils/cache';

import {
  ProfileState,
  ProfileActionTypes,
} from '../modules/Profile/profile.types';

let user: ObjectUser = getDataFromCache('user');
const INITIAL_STATE: ProfileState = {
  userEmail: user?.email || '',
  userPassword: '',
  communicationEmailHost: '',
  communicationEmailPort: 0,
  communicationEmailUser: '',
  communicationEmailPassword: '',
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
  oldPasswordError: '',
  newPasswordError: '',
  confirmPasswordError: '',
  emailSettingsError: '',
  userEmailError: '',
  userPasswordError: '',
  userPrincipalEamilExistsError: '',
  successMessage: '',
  emailingConfig: '',
  loading: false,
};

export default (
  state = INITIAL_STATE,
  action: ProfileActionTypes
): ProfileState => {
  switch (action.type) {
    case PROFILE_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };
    case PROFILE_VALIDATION_PROPS:
      return { ...state, ...action.newState, loading: false };
    case PROFILE_GET_WALLET_EMAIL_SETTINGS:
      return {
        ...state,
        userEmail: action.email,
        communicationEmailHost: action.payload.host,
        communicationEmailPort: action.payload.port,
        communicationEmailUser: action.payload.auth?.user,
        communicationEmailPassword: action.payload.auth?.pass,
        emailingConfig: action.payload.emailingConfig,
      };
    case PROFILE_INITIAL_STATE:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
