import {
  EventState,
  EventActionTypes,
} from '../modules/CreateEvent/createEvent.types';
import moment from 'moment';
import {
  EVENT_FORM_PROPS,
  EVENT_FORM_PROPS_CHANGED,
  EVENT_GET_EVENT_PROPS,
  EVENT_INITIAL_STATE,
} from '../actions/types';

const INITIAL_STATE: EventState = {
  address: '',
  labelColor: {
    hex: '#000000',
    rgb: { r: 0, g: 0, b: 0, a: 1 },
  },
  backgroundColor: {
    hex: '#ffffff',
    rgb: { r: 255, g: 255, b: 255, a: 1 },
  },
  foregroundColor: {
    hex: '#000000',
    rgb: { r: 0, g: 0, b: 0, a: 1 },
  },
  barcodeType: 'QR',
  date: {
    openingHour: moment(),
    closingHour: moment(),
    doorOpeningHour: moment(),
  },
  contactPhone: '',
  email: '',
  strip: null,
  locationName: '',

  name: '',
  price: '',
  terms: '',
  website: '',
  loading: false,
  emailSubscriptionMessage: "<p>Merci de vous être abonné à l'événement</p>",
  locations: [
    {
      name: null,
      longitude: 2.3125185928956062,
      latitude: 49.07575430649549,
      relevantText: '',
    },
  ],
  enableOneTimeUse: false,
  disableIOSPassSharing: false,
  disableMultipleHolders: false,
  enableSecurityAnimation: false,
  enableRotatingBarcode: false,
  disableEmailSharing: false,
  //errors
  imageError: '',
  nameError: '',
  locationNameError: '',
  addressError: '',
  openingHourError: '',
  closingHourError: '',
  doorOpeningHourError: '',
  termsError: '',
  priceError: '',
  contactPhoneError: '',
  emailError: '',
  websiteError: '',
  labelColorError: '',
  foregroundColorError: '',
  backgroundColorError: '',
  emailSubscriptionMessageError: '',
};

export default (state = INITIAL_STATE, action: EventActionTypes) => {
  switch (action.type) {
    case EVENT_FORM_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };
    case EVENT_FORM_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };
    case EVENT_FORM_PROPS:
      return { ...state, ...action.newState, loading: false };
    case EVENT_GET_EVENT_PROPS:
      return { ...state, ...action.payload, ...action.payload.security };
    case EVENT_INITIAL_STATE:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
