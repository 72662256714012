export const validateCustomerFieldsFlight = (state: any) => {
  let newState: any = Object.assign({}, state);

  if (!newState.seatNumber) {
    newState.seatNumberError = 'flight.error.gateRequired';
  } else {
    newState.seatNumberError = '';
  }
  if (!newState.boardingGroup) {
    newState.boardingGroupError = 'flight.error.gateRequired';
  } else {
    newState.boardingGroupError = '';
  }
  if (!newState.seatClass) {
    newState.seatClassError = 'flight.error.terminalRequired';
  } else {
    newState.seatClassError = '';
  }
  if (!newState.confirmationCode) {
    newState.confirmationCodeError = 'flight.error.airportIATAcodeRequired';
  } else {
    newState.confirmationCodeError = '';
  }

  let valid = false;
  if (
    newState.confirmationCodeError === '' &&
    newState.seatClassError === '' &&
    newState.boardingGroupError === '' &&
    newState.seatNumberError === ''
  ) {
    valid = true;
  }

  return { newState, valid };
};

export const newCustomerFlightObject = (props: any) => {
  const flight = {
    seatClass: props.seatClass,
    confirmationCode: props.confirmationCode,
    seatNumber: props.seatNumber,
    boardingGroup: props.boardingGroup,
    customerId: props.customerId,
    flightId: props.flightTemplateId,
  };
  return flight;
};
