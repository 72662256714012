import {
  WALLET_GET_WALLET_DETAIL,
  WALLET_GET_WALLET_DETAIL_FAILED,
  WALLET_INITIAL_STATE,
  WALLET_GET_CUSTOMER_HISTORY,
  WALLET_LOADING_HISTORY,
  WALLET_PROPS_CHANGED,
  WALLET_FILTER_CUSTOMERS,
  ADD_CUSTOMER_TO_CUSTOMERS_LIST,
  UPDATE_GAIN_TO_CUSTOMERS_LIST,
  WALLET_DELETE_CUSTOMER,
  WALLET_GET_WALLET_CUSTOMER_STATS,
} from '../actions/types';
import {
  WalletDetailActionTypes,
  WalletDetailState,
} from '../modules/Wallet/wallet.types';
import { WalletDetailApi, Customer } from '../apis/ApiTypes';

const INITIAL_STATE: WalletDetailState = {
  wallet: null,
  select: 'firstName',
  search: '',
  loading: true,
  error: '',
  communicationChannels: '',
  customers: [],
  totalCustomersCount: 0,
  totalDeleted: 0,
  totalActive: 0,
  totalInactive: 0,
  snackMessageError: '',
  snackMessageSuccess: '',
  loadingImport: false,
  loadingExport: false,
};

export default (state = INITIAL_STATE, action: any): any => {
  switch (action.type) {
    case WALLET_GET_WALLET_DETAIL:
      return {
        ...state,
        wallet: action.payload,
        ...action.response,
        customers: action.payload.customers,
        loading: false,
      };

    case WALLET_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };

    case WALLET_DELETE_CUSTOMER:
      let newWall: WalletDetailApi = Object.assign({}, state.wallet);
      const customers = newWall.customers.filter(
        (item: Customer) => item._id !== action.id
      );
      newWall.customers = customers;
      return { ...state, wallet: newWall };

    case WALLET_GET_WALLET_DETAIL_FAILED:
      return { ...state, error: action.payload, loading: false };

    case WALLET_GET_WALLET_CUSTOMER_STATS:
      return { ...state, ...action.payload, loading: false };

    case WALLET_GET_CUSTOMER_HISTORY: {
      let newWallet: WalletDetailApi = Object.assign({}, state.wallet);
      const customer = newWallet.customers.find(
        (item: Customer) => item._id === action.customerID
      );
      if (customer) {
        customer.loading = false;
        customer.history = action.payload;
      }

      return { ...state, wallet: newWallet };
    }

    case WALLET_LOADING_HISTORY: {
      let newWallet: WalletDetailApi = Object.assign({}, state.wallet);
      const customer = newWallet.customers.find(
        (item: Customer) => item._id === action.customerID
      );
      if (customer) {
        customer.loading = action.payload;
      }

      return { ...state, wallet: newWallet };
    }

    case WALLET_FILTER_CUSTOMERS: {
      if (state.wallet) {
        return {
          ...state,
          search: action.search,
          wallet: { ...state.wallet, customers: action.customers },
        };
      }
      return {
        ...state,
        search: action.search,
      };
    }

    case ADD_CUSTOMER_TO_CUSTOMERS_LIST: {
      let newWallet: WalletDetailApi = Object.assign({}, state.wallet);
      newWallet.customers.unshift(...action.payload);

      return { ...state, wallet: newWallet };
    }

    case UPDATE_GAIN_TO_CUSTOMERS_LIST:
      let newWallet: WalletDetailApi = Object.assign({}, state.wallet);
      const customer = newWallet.customers.find(
        (item: Customer) => item._id === action.customerId
      );
      if (customer) {
        customer.gain = action.gain;
      }

      return { ...state, wallet: newWallet };

    case WALLET_INITIAL_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};
