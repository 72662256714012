import React from 'react';
import { Wrapper } from './tramWireframe.style';

import { ic_close } from 'react-icons-kit/md/ic_close';
import { ic_call } from 'react-icons-kit/md/ic_call';
import { ic_email } from 'react-icons-kit/md/ic_email';
import { ic_location_on } from 'react-icons-kit/md/ic_location_on';

import { ratio } from 'wcag-color';
import barcode from '../../assets/images/barcode.png';

import cover from '../../assets/images/lg-thumbnail.png';

import { ic_more_vert } from 'react-icons-kit/md/ic_more_vert';
import Icon from 'react-icons-kit';

import moment from 'moment';

import { getDataFromCache } from '../../utils/cache';
import { URL_IMAGE } from '../../apis/config';
import QRCode from 'qrcode.react';
import Ferry from '../../icons/Ferry';
import { convertToMoment } from '../../utils/common';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useIntl } from 'react-intl';

import CODE128 from '../../assets/images/code128.png';
import PDF417 from '../../assets/images/pdf417.png';
import QR from '../../assets/images/qr.png';
import AZTEC from '../../assets/images/aztec.png';
import { switchBarcode } from '../../utils/common';

type WireframeProps = {
  backgroundColor: any;
  strip: any;
  number: any;
  originStationCode: any;
  originName: any;
  destinationName: any;
  destinationStationCode: any;
  departureTime: any;
  arrivalTime: any;
  tarif: any;
  customerServiceNumber: any;
  details: any;
  terms: any;
  QrCode: any;
  name: string;
  logo?: any;
  barcodeType: string;
  animatedBorder?: boolean;
};

const TramWireframeAndroid: React.FC<WireframeProps> = (props) => {
  const {
    backgroundColor,
    strip,
    number,
    originStationCode,
    originName,
    destinationName,
    destinationStationCode,
    departureTime,
    arrivalTime,
    tarif,
    customerServiceNumber,
    details,
    terms,
    name,
    QrCode,
    logo,
    barcodeType,
    animatedBorder,
  } = props;
  const currentWallet = getDataFromCache('wallet');
  const [showDetails, setShowDetails] = React.useState(false);
  const [height, setHeight] = React.useState<string>('');
  const intl = useIntl();
  const handleChange = () => {
    setShowDetails(!showDetails);
    height == '100%' ? setHeight('90%') : setHeight('100%');
  };

  const { barcode, barcodeClass } = switchBarcode(barcodeType, {
    CODE128,
    PDF417,
    QR,
    AZTEC,
  });

  return (
    <Wrapper
      backgroundColor={backgroundColor}
      mainContentTextColor={
        ratio('#ffffff', backgroundColor.hex) > 3.0 ? '#ffffff' : '#000000'
      }
      height={height}
    >
      <div className='mainSection'>
        <div className='mainSection-header'>
          <Icon
            size={18}
            icon={ic_close}
            style={
              ratio('#ffffff', backgroundColor.hex) > 3.0
                ? { color: '#ffffff' }
                : { color: '#000000' }
            }
          />
          <Icon
            size={18}
            icon={ic_more_vert}
            style={
              ratio('#ffffff', backgroundColor.hex) > 3.0
                ? { color: '#ffffff' }
                : { color: '#000000' }
            }
          />
        </div>
        <div className='mainSection-header-logo'>
          <img
            src={logo ? logo : `${URL_IMAGE}/${currentWallet.logoPictureUrl}`}
            alt='wallet Logo'
          />
        </div>
        <div className='mainSection-content-enseigne'>
          <div className='mainSection-issuerName'>{name}</div>
          <div className='mainSection-origin-destination'>
            <div className='origin'>
              <h3 className='mainSection-origin-name'>{originName}</h3>
              <h3 className='mainSection-origin-stationCode'>
                {originStationCode}
              </h3>
            </div>
            <div className='arrow'>
              <svg
                width='24'
                height='24'
                xmlns='http://www.w3.org/2000/svg'
                fillRule='evenodd'
                clipRule='evenodd'
              >
                <path d='M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z' />
              </svg>
            </div>
            <div className='destination'>
              <h3 className='mainSection-destination-name'>
                {destinationName}
              </h3>
              <h3 className='mainSection-destination-destinationCode'>
                {destinationStationCode}
              </h3>
            </div>
          </div>
        </div>
        <div className='mainSection-content-dates'>
          <div className='departureDate'>
            <h3 className='mainSection-label'>
              {' '}
              {intl.formatMessage({ id: 'transit.label.departureTime' })}
            </h3>
            <h3 className='mainSection-data'>
              {moment(convertToMoment(departureTime)).format('MMM DD,HH:mm')}
            </h3>
          </div>
          <div className='arrivalDate'>
            <h3 className='mainSection-label'>
              {' '}
              {intl.formatMessage({ id: 'transit.label.arrivalTime' })}
            </h3>
            <h3 className='mainSection-data'>
              {moment(convertToMoment(arrivalTime)).format('HH:mm')}
            </h3>
          </div>
        </div>

        <div className='mainSection-content-passengerName'>
          <h3 className='mainSection-label'>
            {' '}
            {intl.formatMessage({ id: 'transit.label.passenger' })}
          </h3>
          <h3 className='mainSection-data'>Prenom Nom</h3>
        </div>
        <div
          className={
            barcodeType === 'NONE'
              ? 'mainSection-content-barCode hidden'
              : 'mainSection-content-barCode'
          }
        >
          {' '}
          <div className='barCode'>
            <img
              className={`${barcodeClass} ${
                animatedBorder && 'animatedBorder'
              }`}
              src={barcode}
              alt='barcode'
            />{' '}
          </div>
          <p className='walletId'>6066e4315abbcc1f9526d392</p>
        </div>
      </div>
      <div className='dataSection'>
        <div className='margins' onClick={handleChange}>
          {!showDetails ? (
            <div className='details_btn'>
              <ExpandMoreIcon color='primary' />
              Show details
            </div>
          ) : (
            <div className='details_btn'>
              <ExpandLessIcon />
              Hide details
            </div>
          )}
        </div>
      </div>
      {showDetails && (
        <div className='dataSection'>
          <div className='sectionLabel'>
            <h3 className='label'>
              {' '}
              {intl.formatMessage({ id: 'transit.label.departureDate' })}
            </h3>
            <h3 className='data'>
              {moment(convertToMoment(departureTime)).format(
                'MMMM DD YYYY,HH:mm'
              )}
            </h3>
          </div>
          <div className='sectionLabel'>
            <h3 className='label'>
              {' '}
              {intl.formatMessage({ id: 'transit.label.arrivalDate' })}
            </h3>{' '}
            <h3 className='data'>
              {' '}
              {moment(convertToMoment(arrivalTime)).format(
                'MMMM DD YYYY,HH:mm'
              )}
            </h3>
          </div>
          <div className='sectionLabel'>
            <h3 className='label'>
              {' '}
              {intl.formatMessage({ id: 'ship.label.number' })}
            </h3>
            <h3 className='data'>{`${tarif} €` || ''}</h3>
          </div>
          <div className='sectionLabel'>
            <h3 className='label'>Numéro</h3>
            <h3 className='data'>{number}</h3>
          </div>{' '}
          <div className='mainSection-content-banner'>
            <img
              src={
                !strip
                  ? cover
                  : typeof strip === 'string'
                  ? strip
                  : URL.createObjectURL(strip)
              }
              alt='banner'
            />
          </div>
          <div className='sectionLabel'>
            <h3 className='label'>Transit Details</h3>
            <h3 className='data'>{details}</h3>
          </div>
          <div className='sectionIcon'>
            <Icon
              icon={ic_location_on}
              size={18}
              style={{ color: '#999999' }}
            />
            <h3 className='data'>Google Map</h3>
          </div>
          <div className='sectionIcon'>
            <Icon icon={ic_call} size={18} style={{ color: '#999999' }} />
            <h3 className='data'>Info Line</h3>
          </div>
          <div className='sectionIcon'>
            <Icon icon={ic_email} size={18} style={{ color: '#999999' }} />
            <h3 className='data'>Email</h3>
          </div>
        </div>
      )}
    </Wrapper>
  );
};

export default TramWireframeAndroid;
