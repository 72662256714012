import { css } from 'styled-components';
import { opacify } from 'polished';

export const getInputTextAreaStyles = (props: any) => {
  let border = 'blue';
  let shadow = 'rgba(0, 0, 0, 0.4)';
  let margin = props.margin ? props.margin : '5px 0';
  let width = props.width ? `${props.width}px` : '100%';

  if (props.theme) {
    border = props.theme.primary;
    shadow = opacify(0.8, props.theme.primary);
  }

  return css`
    resize: vertical;
    /* height: 100%; */
    max-height: 200px;
    min-height: 100px;
    width: ${width};
    color: rgb(0, 0, 0);
    padding-top: 15px;
    box-sizing: border-box;
    font-size: 16px;
    resize: none;
    line-height: 21px;
    background: #f8f6fe;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(249, 249, 249);
    border-image: initial;
    border-radius: 8px;
    padding: 10px;
    margin: ${margin};
    outline: none;
    /* @media (max-width: 850px) {
      width: 230px;
    } */
    ::placeholder {
      color: #c0c0c0;
      font-size: 14px;
      /* line-height: 21px; */
    }
    ::-moz-placeholder {
      color: #c0c0c0;
      font-size: 14px;
      /* line-height: 40px; */
    }
    :focus {
      border: 1px solid ${border}4a;
      box-shadow: 0 0 3px 3px ${shadow}24;
      background: white;
    }
  `;
};
