import React from 'react';
import { useIntl } from 'react-intl';
import { Route, Switch, RouteComponentProps, Router } from 'react-router-dom';
import SideBar from '../../components/Drawer';

import Buses from '../../modules/Buses';
import { useStyles } from './busesRouter.styles';

import NotFound from '../../modules/NotFound';
import Bus from '../../modules/SentTransits/Bus';
//Modules

const BusesRouter: React.FC<RouteComponentProps> = (props) => {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <div className={classes.rootAppRouter}>
      <SideBar title={intl.formatMessage({ id: 'drawer.menuList.buses' })}>
        <Switch>
          <Route path='/buses/:busID' component={Bus} />
          <Route path='/buses' component={Buses} exact />
          <Route path='/buses/*' component={NotFound} />
        </Switch>
      </SideBar>
    </div>
  );
};

export default BusesRouter;
