import moment from 'moment';
import {
  OFFERS_GET_LIST_OFFERS,
  OFFERS_PROPS_CHANGED,
  OFFERS_ADD_OFFER_TO_LIST,
  OFFERS_FILTER_OFFERS,
  OFFERS_INITIAL_STATE,
  OFFERS_DELETE_OFFER,
  OFFERS_DESACTIVATE_OFFER,
  OFFERS_UPDATE_OFFER_LOADING,
  OFFERS_UPDATE_OFFER,
} from '../actions/types';
import { OffersActionTypes, OffersState } from '../modules/Offers/offers.types';
import { OfferApiResponse } from '../apis/ApiTypes';

const INITIAL_STATE: OffersState = {
  offersList: [],
  offersFilterList: [],
  status: 'ALL',
  allTime: false,
  startDate: null,
  endDate: null,
  loading: true,
  //errors
  startDateError: '',
  endDateError: '',
};

export default (state = INITIAL_STATE, action: OffersActionTypes) => {
  switch (action.type) {
    case OFFERS_UPDATE_OFFER_LOADING: {
      return { ...state, loading: true };
    }
    case OFFERS_GET_LIST_OFFERS: {
      let notDeletedOffers = action.payload.filter(function (item) {
        return item.status !== 'DELETED';
      });
      const offersList = notDeletedOffers.map((offer) => {
        offer.offerPictureUrl = `${offer.offerPictureUrl}?${Math.random()}`;
        return offer;
      });

      return {
        ...state,
        offersList,
        offersFilterList: action.payload,
        loading: false,
      };
    }
    case OFFERS_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };

    case OFFERS_ADD_OFFER_TO_LIST:
      let offersSortedByStartDate: Array<OfferApiResponse> = Object.assign(
        [],
        state.offersList
      );

      offersSortedByStartDate = [...state.offersList, ...[action.payload]];

      return {
        ...state,
        offersList: [
          ...offersSortedByStartDate.sort(
            (a, b) => <any>new Date(a.startDate) - <any>new Date(b.startDate)
          ),
        ],
      };

    case OFFERS_FILTER_OFFERS: {
      if (action.allTime) {
        return {
          ...state,
          startDate: null,
          endDate: null,
          offersList: action.payload.filter(function (item) {
            return item.status !== 'DELETED';
          }),
        };
      }
      return {
        ...state,
        offersList: action.payload.filter(function (item) {
          return item.status !== 'DELETED';
        }),
      };
    }

    case OFFERS_DESACTIVATE_OFFER: {
      let newoffersList: Array<OfferApiResponse> = Object.assign(
        [],
        state.offersList
      );

      const offer = newoffersList.find(
        (item: OfferApiResponse) => item._id === action.offerId
      );
      if (offer) {
        offer.status = action.status;
      }
      return {
        ...state,
        loading: false,
        offersList: newoffersList,
      };
    }

    case OFFERS_DELETE_OFFER:
      var newOffersList = state.offersList.filter(function (item) {
        return item._id !== action.offerId;
      });
      return { ...state, loading: false, offersList: newOffersList };

    case OFFERS_UPDATE_OFFER:
      const offersList = state.offersList.map((offer) => {
        if (offer._id === action.payload._id) {
          return {
            ...offer,
            ...action.payload,
          };
        }
        return offer;
      });

      return { ...state, loading: false, offersList };

    case OFFERS_INITIAL_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};
