import 'date-fns';
import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
//Style
import styled from 'styled-components';
import { couponStyles } from './coupon.styles';
//Material
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
//Components
import InputLabel from '../../commons/Inputs/InputLabel';
//Commomns
import ColorPicker from '../../commons/ColorPicker';

import Button from '../../commons/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '../../commons/Inputs/Input';
import Select from '@material-ui/core/Select';
//Actions
import {
  couponPropsChanged,
  couponInitialState,
  editCouponCustomerConfirm,
} from '../../actions';
//Reducers
import { RootState } from '../../reducers';
//Type
import { CouponFormProps, CouponStateError } from './coupon.types';

import { isNumber, getNatural, getDecimal } from '../../utils/validations';

import { Currency } from '../../apis/ApiTypes';

const EditCustomerCoupon: React.FC<any> = (props) => {
  const intl = useIntl();
  const {
    couponId,
    customerId,
    walletId,
    expirationDate,
    percentReduction,
    description,
    loading,
    currencies,
    symbol,
    originalCouponId,
    expirationDateError,
    percentReductionError,
    descriptionError,
    onSaveChanged,
    onCancel,
  } = props;
  /*
  React.useEffect(() => {
    props.couponInitialState();
  }, []);
*/

  const handleDateChange = (prop: string) => (date: Date | null) => {
    props.couponPropsChanged(prop, date);
  };

  const handleClickConfirm = () => {
    props.editCouponCustomerConfirm(
      walletId,
      couponId,
      customerId,
      originalCouponId,
      props,
      () => onSaveChanged()
    );
  };

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    const name = event.target.name;
    if (value == '') {
      props.couponPropsChanged(name, value);
    }
    props.couponPropsChanged('promoCodeError', '');
    props.couponPropsChanged('percentReductionError', '');
    if (name == 'description' && value.length > 200) return;
    if (name !== 'description' && value.length > 25) return;
    if (name === 'percentReduction' && !isNumber(Number.parseInt(value))) {
      return;
    }

    if (name === 'percentReduction') {
      if (symbol == '%') {
        if (value.indexOf('.') != -1 || value.indexOf(',') != -1) {
          props.couponPropsChanged(
            'percentReductionError',
            'Seuls les chiffres sont autorisés'
          );
          return;
        }
        if (Number.parseInt(value) > 100) {
          props.couponPropsChanged(
            'percentReductionError',
            '100% est le pourcentage maximum autorisé'
          );
          return;
        }
      } else {
        if (getNatural(value).toString().length > 4) {
          props.couponPropsChanged(
            'percentReductionError',
            'Vous avez dépassé le nombre maximum avant le point/virgule'
          );
          return;
        }

        if (getDecimal(value).toString().length > 2) {
          props.couponPropsChanged(
            'percentReductionError',
            'Vous avez dépassé le nombre maximum aprés le point/virgule'
          );
          return;
        }
      }
    }

    props.couponPropsChanged(name, value);
  };

  const getFormatedMessage = (prop: CouponStateError) => {
    return props[prop] !== ''
      ? intl.formatMessage({ id: props[prop] })
      : props[prop];
  };
  const handleSelectChangePercent = (event: any) => {
    props.couponPropsChanged('symbol', event.target.value);
  };

  return (
    <Wrapper>
      <span className='title'>Modifier Coupon</span>
      <div className='div-container' style={{ marginLeft: '15px' }}>
        <div className='div-content' style={{ marginLeft: '30px' }}>
          <KeyboardDatePicker
            required
            margin='normal'
            id='start-date'
            label={intl.formatMessage({ id: 'coupon.label.expirationDate' })}
            format='DD/MM/yyyy'
            value={expirationDate}
            onChange={handleDateChange('expirationDate')}
            error={expirationDateError !== ''}
            helperText={getFormatedMessage('expirationDateError')}
            disablePast
            fullWidth
            inputVariant='outlined'
          />
          <span className='percentLabel'>
            {intl.formatMessage({ id: 'coupon.label.percent' })}
          </span>
          <div className='div-input-filter'>
            <div
              className='select'
              style={{
                marginBottom: 'initial',
                display: 'flex',
                width: '90%',
                alignItems: 'center',
              }}
            >
              <Select
                value={symbol}
                onChange={(e) => handleSelectChangePercent(e)}
                name='symbol'
                placeholder='%'
              >
                <MenuItem value='%'>
                  <span>%</span>
                </MenuItem>
                {currencies.map((currency: Currency, index: number) => {
                  return (
                    <MenuItem value={currency.currencySymbol} key={index}>
                      <span>{currency.currencySymbol}</span>
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <Input
              name='percentReduction'
              value={percentReduction}
              placeholder='20'
              onChange={(e) => handleEventChange(e)}
              className='inputPercent'
            />
          </div>
          <span className='input-text-error'>
            {getFormatedMessage('percentReductionError')}
          </span>

          <InputLabel
            inputType='textArea'
            label={intl.formatMessage({ id: 'coupon.label.description' })}
            name='description'
            value={description}
            error={getFormatedMessage('descriptionError')}
            width={415}
            placeholder={intl.formatMessage({
              id: 'coupon.placeholder.description',
            })}
            onChange={(e) => handleEventChange(e)}
          />
        </div>
        <div
          className='section wireframe-section'
          style={{ marginLeft: 25, marginRight: 25, maxWidth: 356 }}
        ></div>
      </div>{' '}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
        }}
      >
        <Button onClick={onCancel}>
          {intl.formatMessage({ id: 'settings.button.cancel' })}
        </Button>
        <Button onClick={handleClickConfirm}>
          {loading ? (
            <CircularProgress size={20} color='inherit' />
          ) : (
            'Sauvgarder'
          )}
        </Button>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${(props) => couponStyles(props)}
`;

const mapStateToProps = ({ coupon, walletForm }: RootState) => {
  const {
    expirationDate,
    labelColor,
    backgroundColor,
    foregroundColor,
    percentReduction,
    promoCode,
    image,
    source,
    template,
    description,
    loading,
    symbol,
    name,
    expirationDateError,
    labelColorError,
    backgroundColorError,
    foregroundColorError,
    percentReductionError,
    promoCodeError,
    nameError,
    imageError,
    descriptionError,
  } = coupon;
  return {
    expirationDate,
    template,
    labelColor,
    backgroundColor,
    foregroundColor,
    percentReduction,
    promoCode,
    image,
    source,
    description,
    loading,
    symbol,
    name,
    currencies: walletForm.currencies,
    expirationDateError,
    labelColorError,
    backgroundColorError,
    foregroundColorError,
    percentReductionError,
    promoCodeError,
    nameError,
    imageError,
    descriptionError,
  };
};

export const connector = connect(mapStateToProps, {
  couponPropsChanged,
  couponInitialState,
  editCouponCustomerConfirm,
});

export default connector(EditCustomerCoupon);
