import React from 'react';
import { Wrapper } from './ferryWireframe.style';

import { ellipses } from 'react-icons-kit/iconic/ellipses';
import { ratio } from 'wcag-color';
import barcode from '../../assets/images/barcode.png';

import cover from '../../assets/images/lg-thumbnail.png';
import { getDataFromCache } from '../../utils/cache';
import { URL_IMAGE } from '../../apis/config';
import Icon from 'react-icons-kit';

import moment from 'moment';
import QRCode from 'qrcode.react';
import Ferry from '../../icons/Ferry';
import { convertToMoment } from '../../utils/common';
import { ic_more_vert } from 'react-icons-kit/md/ic_more_vert';
import { useIntl } from 'react-intl';

import CODE128 from '../../assets/images/code128.png';
import PDF417 from '../../assets/images/pdf417.png';
import QR from '../../assets/images/qr.png';
import AZTEC from '../../assets/images/aztec.png';
import { switchBarcode } from '../../utils/common';

type WireframeProps = {
  backgroundColor: any;
  destinationTerminal: any;
  originTerminal: any;
  originGate: any;
  destinationGate: any;
  locations: any;
  layovers: any;
  originIATACode: any;
  destinationIATACode: any;
  originAirportName: any;
  destinationAirportName: any;
  departureDateTime: any;
  details: any;
  QrCode: any;
  strip: any;
  number: any;
  customerServiceNumber: any;
  titleColor: any;
  textColor: any;
  logo?: any;
  barcodeType: string;
};

const FlightWireframeIPhone: React.FC<WireframeProps> = (props) => {
  const {
    backgroundColor,
    destinationTerminal,
    originTerminal,
    originGate,
    destinationGate,
    locations,
    layovers,
    originIATACode,
    destinationIATACode,
    originAirportName,
    destinationAirportName,
    departureDateTime,
    details,
    number,
    QrCode,
    customerServiceNumber,
    titleColor,
    textColor,
    logo,
    barcodeType,
  } = props;
  const intl = useIntl();

  const currentWallet = getDataFromCache('wallet');
  const [showDetails, setShowDetails] = React.useState(true);
  const backFlip = () => {
    setShowDetails(!showDetails);
  };
  const { barcode, barcodeClass } = switchBarcode(barcodeType, {
    CODE128,
    PDF417,
    QR,
    AZTEC,
  });

  return (
    <Wrapper
      backgroundColor={backgroundColor}
      mainContentTextColor={
        ratio('#ffffff', backgroundColor.hex) > 3.0 ? '#ffffff' : '#000000'
      }
      titleColor={titleColor}
      textColor={textColor}
    >
      {showDetails ? (
        <div className='mainSection'>
          <div className='firstSection'>
            <div className='mainSection-header-logoSection'>
              <img
                className='mainSection-header-logo'
                src={
                  logo ? logo : `${URL_IMAGE}/${currentWallet.logoPictureUrl}`
                }
                alt='wallet Logo'
              />
            </div>
            <div
              style={{
                width: '205px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
              }}
            >
              <h3 className='mainSection-label'>
                {intl.formatMessage({ id: 'flight.label.terminalAndGate' })}
              </h3>
              <h3 className='mainSection-data'>
                {originTerminal ? originTerminal + '/' + originGate + 1 : 'A1'}
              </h3>
            </div>
          </div>

          <div className='mainSection-origin-destination'>
            <div className='origin'>
              <h3 className='mainSection-origin-name'>
                {originAirportName || 'Ho Chi Minh City'}
              </h3>
              <h3 className='mainSection-origin-stationCode'>
                {originIATACode || 'LAX'}
              </h3>
            </div>
            <div className='arrow'>
              <svg width='24' height='24' xmlns='http://www.w3.org/2000/svg'>
                <path d='M3.691 10h6.309l-3-7h2l7 7h5c1.322-.007 3 1.002 3 2s-1.69 1.993-3 2h-5l-7 7h-2l3-7h-6.309l-2.292 2h-1.399l1.491-4-1.491-4h1.399l2.292 2' />
              </svg>
            </div>
            <div className='destination'>
              <h3 className='mainSection-destination-name'>
                {destinationAirportName || 'Tokyo Haneda'}
              </h3>
              <h3 className='mainSection-destination-destinationCode'>
                {destinationIATACode || 'SFO'}
              </h3>
            </div>
          </div>

          <div className='firstSection'>
            <div className='number'>
              <div className='date'>
                <h3 className='mainSection-label'>
                  {intl.formatMessage({ id: 'transit.label.departureTime' })}
                </h3>
                <h3 className='mainSection-data'>
                  {moment(convertToMoment(departureDateTime)).format(
                    'h:mm A'
                  ) || 'Date'}
                </h3>
              </div>
            </div>
            <div className='number'>
              <div className='date'>
                <h3 className='mainSection-label'>
                  {intl.formatMessage({ id: 'flight.label.number' })}
                </h3>
                <h3 className='mainSection-data'>{number || '15'}</h3>
              </div>
            </div>
            <div className='number'>
              <div className='date'>
                <h3 className='mainSection-label'>
                  {intl.formatMessage({ id: 'flight.label.class' })}
                </h3>
                <h3 className='mainSection-data'>{'VIP'}</h3>
              </div>
            </div>
            <div className='number'>
              <div className='date'>
                <h3 className='mainSection-label'>Date</h3>
                <h3 className='mainSection-data' style={{ textAlign: 'right' }}>
                  {moment(convertToMoment(departureDateTime)).format(
                    'MM/DD/YYYY'
                  ) || 'Date'}
                </h3>
              </div>
            </div>
          </div>
          <span className='divider'></span>
          <div>
            <div className='firstSection'>
              <div className='date'>
                <h3 className='mainSection-label'>
                  {intl.formatMessage({ id: 'transit.label.passenger' })}
                </h3>
                <h3 className='mainSection-data'>{'Prenom Nom'}</h3>
              </div>
              <div className='date'>
                <h3 className='mainSection-label'>
                  {intl.formatMessage({ id: 'flight.label.zone' })}
                </h3>
                <h3 className='mainSection-data'>C 5</h3>
              </div>
            </div>
          </div>
          <div
            className={
              barcodeType === 'NONE'
                ? 'mainSection-content-barCode hidden'
                : 'mainSection-content-barCode'
            }
          >
            {' '}
            <div className='barCode'>
              <img className={barcodeClass} src={barcode} alt='barcode' />
            </div>
            <p className='walletId'>6066e4315abbcc1f9526d392</p>
          </div>
          <div className='BackFlip_btn' onClick={backFlip}>
            <Icon size={18} icon={ic_more_vert} className='backflip' />
          </div>
        </div>
      ) : (
        <div className='dataSection'>
          <div className='sectionLabel'>
            <h3 className='label'>
              {intl.formatMessage({ id: 'flight.label.confirmationCode' })}
            </h3>
            <h3 className='data'>123456</h3>
          </div>

          <span className='divider'></span>
          <div className='sectionLabel'>
            <h3 className='label'>Terms & conditions</h3>
            <h3 className='data'>{details || ''}</h3>
          </div>
          <div className='BackFlip_btn' onClick={backFlip}>
            <Icon size={18} icon={ic_more_vert} className='backflip' />
          </div>
        </div>
      )}
    </Wrapper>
  );
};

export default FlightWireframeIPhone;
