import { isEmpty, isEmail, isNumber } from '../../utils/validations';
import maxChars from '../../utils/fieldsMaxLength';
export const validateFieldsAddCustomer = (props: any) => {
  let newProps = {
    firstName: props.firstName,
    lastName: props.lastName,
    email: props.email,
    phoneNumber: props.phoneNumber,
    barcode: props.barcode,
    firstNameError: props.firstNameError,
    lastNameError: props.lastNameError,
    emailError: props.emailError,
    phoneNumberError: props.phoneNumberError,
    barcodeError: props.barcodeError,
    emailBarcodeError: props.emailBarcodeError,
  };

  const communicationChannels = props.communicationChannels;

  if (isEmpty(newProps.firstName)) {
    newProps.firstNameError = 'addCustomer.error.firstNameRequired';
  } else if (newProps.firstName.length > maxChars.firstName) {
    newProps.firstNameError = 'addCustomer.error.firstNameLength';
  } else {
    newProps.firstNameError = '';
  }
  //todo: verify this
  if (isEmpty(newProps.phoneNumber) && isEmpty(newProps.email)) {
    newProps.emailBarcodeError = 'addCustomer.error.requiredFields';
  } else {
    newProps.emailBarcodeError = '';
  }

  if (!isEmpty(newProps.phoneNumber)) {
    if (!isNumber(newProps.phoneNumber)) {
      newProps.phoneNumberError = 'addCustomer.error.phoneNumberInvalid';
    } else if (newProps.phoneNumber.toString().length > maxChars.phoneNumber) {
      newProps.phoneNumberError = 'addCustomer.error.phoneNumberLength';
    } else {
      newProps.phoneNumberError = '';
    }
  }

  if (isEmpty(newProps.lastName)) {
    newProps.lastNameError = 'addCustomer.error.lastNameRequired';
  } else if (newProps.lastName.length > maxChars.lastName) {
    newProps.lastNameError = 'addCustomer.error.lastNameLength';
  } else {
    newProps.lastNameError = '';
  }

  if (!isEmpty(newProps.email)) {
    if (!isEmail(newProps.email.trim())) {
      newProps.emailError = 'addCustomer.error.emailInvalid';
    } else if (newProps.email.length > maxChars.email) {
      newProps.emailError = 'addCustomer.error.emailLength';
    } else {
      newProps.emailError = '';
    }
  }

  if (
    newProps.barcode.length > 0 &&
    newProps.barcode.length > maxChars.barcode
  ) {
    newProps.barcodeError = 'addCustomer.error.barcodeInvalid';
  }

  let valid = true;

  if (
    newProps.firstNameError ||
    newProps.lastNameError ||
    newProps.emailError ||
    newProps.phoneNumberError ||
    newProps.barcodeError ||
    newProps.emailBarcodeError
  ) {
    valid = false;
  }

  return { newProps, valid };
};
