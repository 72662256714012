import {
  FORGOT_PASSWORD_PROPS_CHANGED,
  FORGOT_PASSWORD_INITIAL_STATE,
} from '../actions/types';
import {
  ForgotPasswordState,
  ForgotPasswordActionTypes,
} from '../modules/ForgotPassword/forgotPassword.types';

const INITIAL_STATE: ForgotPasswordState = {
  username: '',
  emailError: '',
  loading: false,
};

export default (
  state = INITIAL_STATE,
  action: ForgotPasswordActionTypes
): ForgotPasswordState => {
  switch (action.type) {
    case FORGOT_PASSWORD_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };

    case FORGOT_PASSWORD_INITIAL_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};
