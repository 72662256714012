import moment, { Moment } from 'moment';

export const convertToMoment = (value: string) => {
  if (moment(value).isValid()) return value;
  const d = new Date();
  d.setHours(+value?.slice(0, 2));
  d.setMinutes(+value?.slice(3));
  return moment(d);
};

export const getBrowserLanguage = () => {
  return (navigator.languages && navigator.languages[0]) || navigator.language;
};

export const convertMomentToTime = (date: Moment | string) => {
  if (moment.isMoment(date)) {
    return moment(date).format('HH:mm');
  }
  return date;
};

export const getImageErrorMessage = (file: File): string => {
  const fileType = file.type.replace('image/', '');
  return `${fileType.charAt(0)?.toUpperCase()}${fileType.slice(
    1
  )} n'est pas un type supporté`;
};

export const switchBarcode = (barcodeType: string, barcodes: any) => {
  let barcodeClass = 'rectangleCode';
  if (['AZTEC', 'QR'].includes(barcodeType)) {
    barcodeClass = 'squareCode';
  }
  return { barcode: barcodes[barcodeType], barcodeClass };
};
