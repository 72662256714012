import React from 'react';

function Icon({
  fill = '#ff5078',
  style,
  className,
}: {
  fill?: string;
  style?: any;
  className?: any;
}) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='20'
      fill='none'
      viewBox='0 0 18 20'
      className={className}
    >
      <path
        fill={fill}
        fillRule='evenodd'
        d='M13.41.769l.002.75c2.754.215 4.574 2.092 4.577 4.97L18 14.917c.004 3.138-1.968 5.069-5.128 5.074l-7.72.01c-3.14.004-5.137-1.973-5.141-5.12L0 6.553c-.004-2.898 1.752-4.77 4.506-5.023V.78a.746.746 0 01.758-.77.747.747 0 01.762.769v.7l5.865-.009v-.7A.747.747 0 0112.65 0a.753.753 0 01.76.769zM1.522 6.862l14.949-.02v-.35c-.043-2.15-1.121-3.277-3.056-3.445v.77c0 .43-.334.77-.758.77a.752.752 0 01-.762-.768v-.81l-5.865.008v.81c0 .43-.324.77-.759.77a.752.752 0 01-.76-.769l-.002-.77c-1.925.193-2.99 1.325-2.988 3.493l.001.31zm10.719 4.542v.011c.01.46.385.809.84.799a.823.823 0 00.789-.852.83.83 0 00-.82-.797.828.828 0 00-.81.84zm.815 4.488a.848.848 0 01-.821-.848.833.833 0 01.809-.852h.01c.463 0 .84.379.84.848a.844.844 0 01-.838.852zM8.172 11.42c.02.46.396.819.85.799a.823.823 0 00.78-.86.816.816 0 00-.821-.8c-.454.02-.81.402-.809.861zm.854 4.427a.823.823 0 01-.85-.798c0-.46.355-.84.809-.861a.815.815 0 01.82.797c.02.461-.335.841-.779.862zm-4.922-4.392c.02.46.396.82.85.799.445-.02.8-.4.778-.86a.815.815 0 00-.82-.8.852.852 0 00-.808.861zm.854 4.397a.822.822 0 01-.85-.799.853.853 0 01.809-.86.815.815 0 01.82.799c.021.46-.333.84-.779.86z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}

export default Icon;
