import styled from 'styled-components';

export const Wrapper = styled('div')<{
  backgroundColor: any;
  mainContentTextColor: string;
  height: any;
}>`
  display: flex;
  flex-direction: column;
  height:100vh;
  font-family: 'Google Sans',Roboto,Arial,sans-serif !important;
  background-color: #201f24;
  border-radius: 10px;
  overflow-y: auto;
  padding: 10px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;


  .hidden{
    display: none;
  }

  .mainSection {
    background-color: ${(props) => props.backgroundColor.hex};
    width: 100%;
    height:624px !important;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    height: fit-content;
    align-items: center;
    .mainSection-header {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .mainSection-header-logo {
      -webkit-align-items: center;
      align-items: center;
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      height: 60px;
      width : 60px;
      -webkit-justify-content: center;
      justify-content: center;
      img {
        background: #fff;
        object-fit: cover;
        height: 60px;
        width: 60px;
    }
      }
    }
    .mainSection-content-banner {
      /* height: 100px;
      width: 270px; */
      overflow: hidden;
      /* border-radius: 3px;
      margin-top: 20px; */
      img {
        object-fit: fill;
        height: 300px;
        width: 100%;
        /* margin-top:-100px; */
      }
    }
    .mainSection-content-enseigne {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
      h4 {
        font-weight: 500;
        text-align: center;
        font-size:16px;
        color: #FF5078;
        margin: 0;
        word-break: break-all;
      }
      h3 {
        font-family: 'Google Sans',Roboto,Arial,sans-serif;
        font-size: 1.375rem;
        font-weight: 400;
        line-height: 1.75rem;
        line-height: 22px;
        margin-top: 6px;
        color: ${(props) => props.mainContentTextColor};
    
      }
    }
    .mainSection-content-datePoints {
      display: flex;
      margin-top: 20px;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      .points {
        text-align: right;
      }
    }
    .mainSection-content-barCode {
      margin-top: -27px;
      .barCode {
        width: 270px;
        background: #ffffff;
        height: 100px;
        display: flex;
        border-radius: 3px;
        justify-content: center;
        align-items: center;
        img {
          width: 219px;
          height: 65px;
          object-fit: initial;
        }
      }
      .walletId {
        font-size: 12px;
        color: ${(props) => props.mainContentTextColor};
        text-align: center;
        margin: 7px 0px 10px 0px;
      }
    }
    .mainSection-label {
      color: ${(props) => props.mainContentTextColor};
      font-weight: 300;
      text-transform: uppercase;
      font-size: 12px;
      margin: 0px;
    }
    .mainSection-data {
      color: ${(props) => props.mainContentTextColor};
      font-size: 14px;
      font-weight: 400;
      margin: 0px;
    }
  }
  .dataSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 0px 10px 20px 10px;
    .sectionIcon {
      margin-top: 50px;
      gap: 10px;
      align-items: center;
      display: flex;
      .data {
        color: #ffffff;
        font-weight: 500;
        font-size: 14px;
        margin: 0px;
        word-break: break-all;
      }
    }
    .sectionLabel {
      margin-top: 20px;
      .label {
        color: #FF5078;
        font-weight: 300;
        text-transfom: uppercase;
        font-size: 12px;
        margin: 0px;
      }
      .data {
        color: #ffffff;
        font-weight: 500;
        font-size: 14px;
        margin: 0px;
        word-break: break-all;
      }
    }
  }
  .details_btn {
      display: inline-flex;
      color: #fff;
      .MuiSvgIcon-root {
      fill: #3f51b5;
      }
    }
    .margins {
      margin-top: 15px;
    }
`;
