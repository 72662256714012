import React, {
  Fragment,
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import InputLabel from '../../commons/Inputs/InputLabel';
import InputFile from '../../commons/Inputs/InputFile';
import Button from '../../commons/Button';
import ColorPicker from '../../commons/ColorPicker';
import MapComponent from '../../components/MapComponent';
import SelectInput from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { FormWrapper } from './walletForm.styles';
import { walletFormPropsChanged, walletFormEditWallet } from '../../actions';
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch,
} from '@material-ui/core';
import defaultLogo from '../../assets/images/sm-thumbnail.png';
import cover from '../../assets/images/lg-thumbnail.png';
import FormLabel from '@material-ui/core/FormLabel';
import {
  additionalContacts,
  Currency,
  description,
  FromWalletProps,
} from './walletForm.types';
import { RootState } from '../../reducers';
import { isUrl, isEmail, isPhoneNumber } from '../../utils/validations';
import { getImageErrorMessage } from '../../utils/common';

import Wireframe from '../../components/Wireframe';
import WireframeIPhone from '../../components/WireframeIPhone';

import invisible from '../../assets/images/invisible.svg';
import visible from '../../assets/images/visibility.svg';

import { ic_add_circle_outline } from 'react-icons-kit/md/ic_add_circle_outline';
import { ic_delete } from 'react-icons-kit/md/ic_delete';
import { ic_warning } from 'react-icons-kit/md/ic_warning';
import Icon from 'react-icons-kit';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Input from '../../commons/Inputs/Input';
import Select from '@material-ui/core/Select';

import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';

import axios from 'axios';
import { useIntl } from 'react-intl';
import SimpleDialog from '../Dialog';
import maxChars from '../../utils/fieldsMaxLength';
import ExpirationDateSection from './expirationDateSection';
import TextField from '@material-ui/core/TextField';
import BarcodeTypeSelection from '../barcodeSelection';

const WalletForm: React.FC<FromWalletProps> = (props) => {
  const {
    id,
    name,
    logo,
    icon,
    strip,
    labelColor,
    backgroundColor,
    foregroundColor,
    address,
    facebook,
    instagram,
    linkedin,
    website,
    locations,
    businessPhone,
    servicePhone,
    openingHours,
    descriptions,
    additionalContacts,
    username,
    email,
    login,
    communicationEmail,
    barcodeType,
    password,
    confirmPassword,
    displayWalletName,
    button,
    gainUnit,
    loading,
    currencies,
    title,
    primaryFields,
    label,
    value,
    labelPersonnalizedText,
    valuePersonnalizedText,
    //errors
    businessPhoneError,
    nameError,
    logoError,
    iconError,
    stripError,
    labelColorError,
    backgroundColorError,
    foregroundColorError,
    addressError,
    openingHoursError,
    facebookError,
    instagramError,
    linkedinError,
    websiteError,
    usernameError,
    emailError,
    passwordError,
    confirmPasswordError,
    descriptionError,
    additionalContactError,
    openWalletStyle,
    openWalletInfos,
    getLogo,
  } = props;

  const history = useHistory();
  const location = useLocation();
  const intl = useIntl();

  const [switchPreview, setSwitchPreview] = React.useState<string>('android');
  const [selectOpener, setSelectOpener] = React.useState<boolean>(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [newGainUnit, setNewGainUnit] = React.useState<string>('NONE');
  const handlePreviewChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSwitchPreview((event.target as HTMLInputElement).value);
  };

  const areTextAreasValid = (fieldName: string, value: string): boolean => {
    if (value.length > (maxChars.wallet[fieldName] || maxChars.fieldName)) {
      props.walletFormPropsChanged(
        `${fieldName}Error`,
        'La longueur maximale a été atteinte'
      );
      return false;
    }
    return true;
  };

  const isInputTextValid = (fieldName: string, value: string): boolean => {
    const phones = ['businessPhone', 'servicePhone'];
    const websites = ['website', 'facebook', 'instagram', 'linkedin'];
    if (value.length > (maxChars.wallet[fieldName] || maxChars.fieldName)) {
      props.walletFormPropsChanged(
        `${fieldName}Error`,
        'La longueur maximale a été atteinte'
      );
      return false;
    }
    if (websites.includes(fieldName)) {
      if (!isUrl(value)) {
        props.walletFormPropsChanged(fieldName, value);
        props.walletFormPropsChanged(`${fieldName}Error`, 'URL est invalid');
        return false;
      }
    }

    if (phones.includes(fieldName)) {
      if (!isPhoneNumber(value)) {
        props.walletFormPropsChanged(fieldName, value);
        props.walletFormPropsChanged(`${fieldName}Error`, 'Phone est invalid');
        return false;
      }
    }

    return true;
  };

  const handleEventChange = (event: any) => {
    const value = event.target.value;
    const fieldName = event.target.name;
    const inputFeilds = [
      'businessPhone',
      'servicePhone',
      'website',
      'facebook',
      'instagram',
      'linkedin',
    ];
    const textAreas = ['address', 'openingHours'];

    if (textAreas.includes(fieldName))
      if (!areTextAreasValid(fieldName, value)) return;

    if (inputFeilds.includes(fieldName))
      if (!isInputTextValid(fieldName, value)) return;

    props.walletFormPropsChanged(fieldName, value);
  };

  const handleColorChange = (color: any, prop: string) => {
    props.walletFormPropsChanged(prop, color);
  };
  const updateGains = () => {
    props.walletFormPropsChanged('gainUnit', newGainUnit);
    setOpenDialog(false);
  };

  const handleSelectChange = (event: any) => {
    setNewGainUnit(event.target.value);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    // props.history.push(`/wallets/${objectUser.wallet}`);
  };
  const handleLocationEventChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    let locationClone = [...locations];
    if (type === 'lat') {
      locationClone[0].latitude = Number(event.target.value);
    } else {
      locationClone[0].longitude = Number(event.target.value);
    }
    props.walletFormPropsChanged('locations', locationClone);
  };

  const handleLocationChange = (value: any, prop: string, type: string) => {
    let locationClone = [...locations];
    if (type === 'latLng') {
      locationClone[0].latitude = value.lat;
      locationClone[0].longitude = value.lng;
    } else if (type === 'description') {
      locationClone[0].relevantText = value;
    }
    props.walletFormPropsChanged(prop, locationClone);
  };

  const handleFileChange = (event: any, prop: string) => {
    const file = event.target.files[0];
    if (!['image/jpeg', 'image/png'].includes(file.type)) {
      props.walletFormPropsChanged(`${prop}Error`, getImageErrorMessage(file));
      return;
    }

    props.walletFormPropsChanged(prop, file);
  };

  if (location.pathname.includes('edit') && id === null) {
    history.push('/wallets');
  }
  const contactRef: any = useRef(null);
  const descriptionRef: any = useRef(null);
  const styleRef: any = useRef(null);
  const walletInfoRef: any = useRef(null);

  const getErrorsLocation = () => {
    const walletInfoSectionHasErrors = [
      nameError,
      businessPhoneError,
      instagramError,
      linkedinError,
      websiteError,
      addressError,
      openingHoursError,
      facebookError,
    ].some((element) => element != '');
    const descriptionSectionHasErrors = [
      descriptionError,
      additionalContactError,
    ].some((element) => element != '');

    const styleSectionHasErrors = [
      logoError,
      iconError,
      stripError,
      labelColorError,
      backgroundColorError,
      foregroundColorError,
    ].some((element) => element != '');
    const contactSectionHasErrors = additionalContactError !== '';

    if (walletInfoSectionHasErrors) return walletInfoRef;
    if (contactSectionHasErrors) return contactRef;
    if (descriptionSectionHasErrors) return descriptionRef;
    if (styleSectionHasErrors) return styleRef;

    return null;
  };
  const executeScroll = () => {
    let elemPosition = getErrorsLocation();

    if (elemPosition && elemPosition.current) {
      elemPosition.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'end',
      });
    }
  };
  const [buttonClicked, setButtonClicked] = React.useState<boolean>(false);

  useEffect(() => {
    executeScroll();
  }, [buttonClicked]);

  const handleClickSave = () => {
    if (button === 'Modifier') {
      props.walletFormEditWallet(props, () => window.location.reload());
      setButtonClicked(!buttonClicked);
    }
  };
  const handleChangeCheckBox = (event: any) => {
    props.walletFormPropsChanged('displayWalletName', event.target.checked);
  };

  const handleDescriptionTitleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    props.walletFormPropsChanged('descriptionError', '');
    let descriptionsClone = [...descriptions];
    descriptionsClone[index].title = e.target.value;
    props.walletFormPropsChanged('descriptions', descriptionsClone);
  };

  const handleDescriptionValueChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    props.walletFormPropsChanged('descriptionError', '');
    let descriptionsClone = [...descriptions];
    descriptionsClone[index].value = e.target.value;
    props.walletFormPropsChanged('descriptions', descriptionsClone);
  };

  const handleAddDescriptionChange = () => {
    if (
      descriptions[descriptions.length - 1].title === '' ||
      descriptions[descriptions.length - 1].value === ''
    ) {
      props.walletFormPropsChanged(
        'descriptionError',
        'La description ' +
          descriptions.length +
          " doit être remplie avant d'en ajouter une nouvelle."
      );
    } else {
      props.walletFormPropsChanged('descriptionError', '');
      let descriptionsClone = [...descriptions];
      descriptionsClone.push({ title: '', value: '' });
      props.walletFormPropsChanged('descriptions', descriptionsClone);
    }
  };

  const handleAdditionalContactValueChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    props.walletFormPropsChanged('additionalContactError', '');
    let additionalContactClone = [...additionalContacts];
    additionalContactClone[index].value = e.target.value;
    props.walletFormPropsChanged('additionalContacts', additionalContactClone);
  };

  const handleDeleteDescriptionChange = (index: number) => {
    let descriptionsClone = [...descriptions];
    descriptionsClone.splice(index, 1);
    props.walletFormPropsChanged('descriptions', descriptionsClone);
  };

  const handleDeleteAdditionalContactChange = (index: number) => {
    let additionalContactClone = [...additionalContacts];
    additionalContactClone.splice(index, 1);
    props.walletFormPropsChanged('additionalContacts', additionalContactClone);
  };

  const handleAdditionalContactTypeChnage = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
    index: number
  ) => {
    props.walletFormPropsChanged('additionalContactError', '');
    let additionalContactClone = [...additionalContacts];
    additionalContactClone[index].kind = e.target.value;
    props.walletFormPropsChanged('additionalContacts', additionalContactClone);
  };

  const handleAdditionalContactTitleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    props.walletFormPropsChanged('additionalContactError', '');
    let additionalContactClone = [...additionalContacts];
    additionalContactClone[index].title = e.target.value;
    props.walletFormPropsChanged('additionalContacts', additionalContactClone);
  };

  const handleAddAdditionalContactChange = () => {
    if (
      additionalContacts[additionalContacts.length - 1].title === '' ||
      additionalContacts[additionalContacts.length - 1].value === ''
    ) {
      props.walletFormPropsChanged(
        'additionalContactError',
        'Contact supplémentaire ' +
          descriptions.length +
          " doit être remplie avant d'en ajouter une nouvelle."
      );
    } else {
      props.walletFormPropsChanged('additionalContactError', '');
      let additionalContactClone = [...additionalContacts];
      additionalContactClone.push({ title: '', kind: 'URL', value: '' });
      props.walletFormPropsChanged(
        'additionalContacts',
        additionalContactClone
      );
    }
  };

  //password
  const [showpassword, setShowpassword] = useState<boolean>(false);
  const [showConfirmpassword, setShowConfirmpassword] = useState<boolean>(
    false
  );
  const [dycriptedPassword, setdycriptedPassword] = useState<
    string | undefined
  >('');
  const getPassword = () => {
    const URL = process.env.REACT_APP_API_URL;
    const requestURL = `${URL}/settings/${id}/password/`;
    axios.get(requestURL).then(
      (response) => {
        setdycriptedPassword(response.data.response.data.decryptedPassword);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <FormWrapper>
      <Fragment>
        <div className='buttons-container'>
          {(businessPhoneError ||
            nameError ||
            logoError ||
            iconError ||
            stripError ||
            labelColorError ||
            backgroundColorError ||
            foregroundColorError ||
            addressError ||
            openingHoursError ||
            facebookError ||
            instagramError ||
            linkedinError ||
            websiteError ||
            usernameError ||
            emailError ||
            passwordError ||
            confirmPasswordError ||
            descriptionError ||
            additionalContactError) && (
            <div className='error-icon'>
              <Icon icon={ic_warning} size={24} style={{ color: '#FF5078' }} />
            </div>
          )}
          <Button onClick={() => handleClickSave()}>
            {loading ? (
              <CircularProgress size={20} color='inherit' />
            ) : (
              intl.formatMessage({ id: 'settings.button.save' })
            )}
          </Button>
        </div>
        <div
          className='container'
          style={{
            marginLeft: '-3rem',
          }}
        >
          <div className='form-container'>
            {openWalletInfos && (
              <div className='section' style={{ boxShadow: 'none' }}>
                <h3
                  className='section-title'
                  ref={(el: any) => {
                    contactRef.current = el;
                  }}
                >
                  {intl.formatMessage({
                    id: 'settings.label.accountInformation',
                  })}
                </h3>
                {button !== 'Modifier' && (
                  <Fragment>
                    <div className='section-row'>
                      <InputLabel
                        label='Email'
                        name='email'
                        value={email}
                        error={emailError}
                        placeholder='Email'
                        onChange={(e) => handleEventChange(e)}
                      />
                      <InputLabel
                        label="Nom d'utilisateur"
                        name='username'
                        value={username}
                        error={usernameError}
                        placeholder='Login'
                        onChange={(e) => handleEventChange(e)}
                      />
                    </div>
                    <h3 className='section-title'>Mot de passe</h3>
                    <div className='section-row'>
                      <div className='password-section'>
                        <InputLabel
                          type={showpassword ? 'text' : 'password'}
                          label='Mot de passe'
                          name='password'
                          value={password}
                          error={passwordError}
                          placeholder='Mot de passe'
                          onChange={(e) => handleEventChange(e)}
                        />
                        <span className='icon'>
                          {showpassword ? (
                            <img
                              src={visible}
                              alt='visible'
                              onClick={() => {
                                setShowpassword(false);
                              }}
                            />
                          ) : (
                            <img
                              src={invisible}
                              alt='invisible'
                              onClick={() => {
                                setShowpassword(true);
                              }}
                            />
                          )}
                        </span>
                      </div>
                      <div className='password-section'>
                        <InputLabel
                          type={showConfirmpassword ? 'text' : 'password'}
                          label='Mot de passe'
                          name='confirmPassword'
                          value={confirmPassword}
                          error={confirmPasswordError}
                          placeholder='Confirmez le mot de passe'
                          // disabled={button === 'Modifier'}
                          onChange={(e) => handleEventChange(e)}
                        />
                        <span className='icon'>
                          {showConfirmpassword ? (
                            <img
                              src={visible}
                              alt='visible'
                              onClick={() => {
                                setShowConfirmpassword(false);
                              }}
                            />
                          ) : (
                            <img
                              src={invisible}
                              alt='invisible'
                              onClick={() => {
                                setShowConfirmpassword(true);
                              }}
                            />
                          )}
                        </span>
                      </div>
                    </div>
                  </Fragment>
                )}
                {button === 'Modifier' && (
                  <Fragment>
                    <div className='section-row'>
                      <InputLabel
                        label={intl.formatMessage({
                          id: 'settings.label.userName',
                        })}
                        name='username'
                        value={login}
                        placeholder={intl.formatMessage({
                          id: 'settings.placeHolder.userName',
                        })}
                        disabled
                      />
                      <div className='password-section'>
                        <InputLabel
                          label={intl.formatMessage({
                            id: 'settings.label.password',
                          })}
                          name='mdp'
                          value={
                            dycriptedPassword ? dycriptedPassword : '**********'
                          }
                          placeholder={intl.formatMessage({
                            id: 'settings.placeHolder.username',
                          })}
                          disabled
                        />
                      </div>
                    </div>
                    <div className='section-row'>
                      <InputLabel
                        label={intl.formatMessage({
                          id: 'settings.label.communicationEmail',
                        })}
                        name='communicationEmail'
                        value={communicationEmail}
                        placeholder={intl.formatMessage({
                          id: 'settings.placeHolder.communicationEmail',
                        })}
                        disabled
                      />
                      <InputLabel
                        label={intl.formatMessage({
                          id: 'settings.label.email',
                        })}
                        name='email'
                        value={email}
                        placeholder={intl.formatMessage({
                          id: 'settings.placeHolder.email',
                        })}
                        disabled
                      />
                    </div>
                  </Fragment>
                )}
              </div>
            )}
            {openWalletInfos && (
              <div className='section' style={{ boxShadow: 'none' }}>
                <h3 className='section-title'>
                  {intl.formatMessage({
                    id: 'settings.label.walletInformation',
                  })}
                </h3>
                <BarcodeTypeSelection
                  setSelectedBarcode={(barcodeType) => {
                    props.walletFormPropsChanged('barcodeType', barcodeType);
                  }}
                  barcodeType={barcodeType}
                ></BarcodeTypeSelection>
                <div className='section-row'>
                  <div className='showWalletName'>
                    <FormGroup row style={{ marginTop: '21px' }}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={displayWalletName}
                            name='displayWalletName'
                          />
                        }
                        label={intl.formatMessage({
                          id: 'settings.label.showWalletName',
                        })}
                        onChange={handleChangeCheckBox}
                      />
                    </FormGroup>
                  </div>
                  <div className='div-input-filter' style={{ flexGrow: 1 }}>
                    <FormControl className='filter-container' fullWidth>
                      <Input value={gainUnit} type='text' disabled />
                      <div
                        className='filter-button'
                        onClick={() => setSelectOpener(!selectOpener)}
                      >
                        <h3>Gains</h3>
                        <ExpandMoreRoundedIcon
                          style={{ color: 'white' }}
                          className={selectOpener ? 'filter-opened' : ''}
                        />
                      </div>
                      <SimpleDialog
                        title={intl.formatMessage({
                          id: 'gainUnit.dialog.text.title',
                        })}
                        text={intl.formatMessage({
                          id: `gainUnit.dialog.text.change`,
                        })}
                        handleAgree={updateGains}
                        open={openDialog}
                        handleClose={handleCloseDialog}
                      ></SimpleDialog>
                      <Select
                        className='select-input'
                        value={gainUnit}
                        onChange={handleSelectChange}
                        placeholder='Gains'
                        id='filter'
                        inputProps={{ className: 'input' }}
                        open={selectOpener}
                        onClose={() => setSelectOpener(false)}
                      >
                        <MenuItem value='NONE'>
                          <span>Aucun</span>
                        </MenuItem>
                        {currencies.map((currency: Currency) => {
                          return (
                            <MenuItem value={currency.currency}>
                              <span>
                                {currency.currency} / {currency.currencySymbol}
                              </span>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className='section-row'>
                  <InputLabel
                    label={intl.formatMessage({
                      id: 'settings.label.commercialPhone',
                    })}
                    name='businessPhone'
                    error={businessPhoneError}
                    value={businessPhone}
                    placeholder={intl.formatMessage({
                      id: 'settings.placeholder.commercialPhone',
                    })}
                    limit={
                      maxChars.wallet.businessPhone || maxChars.businessPhone
                    }
                    onChange={(e) => handleEventChange(e)}
                  />
                  <InputLabel
                    label={intl.formatMessage({
                      id: 'settings.label.afterSalePhone',
                    })}
                    name='servicePhone'
                    value={servicePhone}
                    placeholder={intl.formatMessage({
                      id: 'settings.placeholder.afterSalePhone',
                    })}
                    limit={
                      maxChars.wallet.servicePhone || maxChars.servicePhone
                    }
                    onChange={(e) => handleEventChange(e)}
                  />
                </div>
                <div className='section-row'>
                  <InputLabel
                    label={intl.formatMessage({ id: 'settings.label.website' })}
                    name='website'
                    value={website}
                    error={websiteError}
                    placeholder={intl.formatMessage({
                      id: 'settings.placeholder.website',
                    })}
                    limit={maxChars.wallet.website || maxChars.website}
                    onChange={(e) => handleEventChange(e)}
                  />

                  <InputLabel
                    label='Facebook'
                    name='facebook'
                    value={facebook}
                    error={facebookError}
                    placeholder='https://'
                    limit={maxChars.wallet.facebook || maxChars.website}
                    onChange={(e) => handleEventChange(e)}
                  />
                </div>
                <div className='section-row'>
                  <InputLabel
                    label='Instagram'
                    name='instagram'
                    value={instagram}
                    error={instagramError}
                    placeholder='https://'
                    limit={maxChars.wallet.instagram || maxChars.website}
                    onChange={(e) => handleEventChange(e)}
                  />
                  <InputLabel
                    label='Linkedin'
                    name='linkedin'
                    value={linkedin}
                    error={linkedinError}
                    placeholder='https://'
                    limit={maxChars.wallet.linkedin || maxChars.website}
                    onChange={(e) => handleEventChange(e)}
                  />
                </div>
                <div className='section-row'>
                  <InputLabel
                    inputType='textArea'
                    label={intl.formatMessage({ id: 'settings.label.address' })}
                    name='address'
                    rows={10}
                    height={158}
                    value={address}
                    error={addressError}
                    placeholder={intl.formatMessage({
                      id: 'settings.placeholder.address',
                    })}
                    limit={maxChars.wallet.address || maxChars.address}
                    onChange={(e) => handleEventChange(e)}
                  />

                  <InputLabel
                    label={intl.formatMessage({
                      id: 'settings.label.openingHours',
                    })}
                    inputType='textArea'
                    rows={10}
                    height={158}
                    name='openingHours'
                    value={openingHours}
                    error={openingHoursError}
                    placeholder={intl.formatMessage({
                      id: 'settings.placeholder.openingHours',
                    })}
                    limit={
                      maxChars.wallet.openingHours || maxChars.openingHours
                    }
                    onChange={(e) => handleEventChange(e)}
                  />
                </div>
                <div className='iosheaderSection'>
                  <p>
                    {intl.formatMessage({
                      id: 'settings.label.headerFields',
                    })}
                  </p>
                  <div className='section-row '>
                    <div className='select'>
                      <p>
                        {intl.formatMessage({
                          id: 'settings.label.firstField',
                        })}
                      </p>
                      <SelectInput
                        label={intl.formatMessage({
                          id: 'settings.label.firstField',
                        })}
                        onChange={(e) => {
                          handleEventChange(e);
                        }}
                        defaultValue={'REWARDS'}
                        value={label}
                        name='label'
                      >
                        <MenuItem value='REWARDS'>
                          {intl.formatMessage({
                            id: 'settings.label.rewards',
                          })}
                        </MenuItem>
                        <MenuItem value='TYPE'>
                          {intl.formatMessage({
                            id: 'settings.label.type',
                          })}
                        </MenuItem>
                        <MenuItem value='FULLNAME'>
                          {intl.formatMessage({
                            id: 'settings.label.fullName',
                          })}
                        </MenuItem>

                        <MenuItem value='TEXT'>
                          {intl.formatMessage({
                            id: 'settings.label.text',
                          })}
                        </MenuItem>

                        <MenuItem value='DISABLED'>
                          {intl.formatMessage({
                            id: 'settings.label.disabled',
                          })}
                        </MenuItem>
                      </SelectInput>
                    </div>
                    {label === 'TEXT' ? (
                      <InputLabel
                        label={intl.formatMessage({
                          id: 'settings.label.personalizedText',
                        })}
                        name='labelPersonnalizedText'
                        value={labelPersonnalizedText}
                        limit={
                          maxChars.wallet.contactTitle || maxChars.contactTitle
                        }
                        onChange={(e) => {
                          handleEventChange(e);
                        }}
                      />
                    ) : (
                      <div style={{ width: '100%' }} />
                    )}
                  </div>

                  <div className='section-row'>
                    <div className='select'>
                      <p>
                        {intl.formatMessage({
                          id: 'settings.label.secondField',
                        })}
                      </p>
                      <SelectInput
                        label={intl.formatMessage({
                          id: 'settings.label.secondField',
                        })}
                        onChange={(e) => {
                          handleEventChange(e);
                        }}
                        defaultValue={'TYPE'}
                        value={value}
                        name='value'
                      >
                        <MenuItem value='REWARDS'>
                          {intl.formatMessage({
                            id: 'settings.label.rewards',
                          })}
                        </MenuItem>
                        <MenuItem value='TYPE'>
                          {intl.formatMessage({
                            id: 'settings.label.type',
                          })}
                        </MenuItem>
                        <MenuItem value='FULLNAME'>
                          {intl.formatMessage({
                            id: 'settings.label.fullName',
                          })}
                        </MenuItem>

                        <MenuItem value='TEXT'>
                          {intl.formatMessage({
                            id: 'settings.label.text',
                          })}
                        </MenuItem>

                        <MenuItem value='DISABLED'>
                          {intl.formatMessage({
                            id: 'settings.label.disabled',
                          })}
                        </MenuItem>
                      </SelectInput>
                    </div>
                    {value === 'TEXT' ? (
                      <InputLabel
                        label={intl.formatMessage({
                          id: 'settings.label.personalizedText',
                        })}
                        name='valuePersonnalizedText'
                        value={valuePersonnalizedText}
                        onChange={(e) => {
                          handleEventChange(e);
                        }}
                      />
                    ) : (
                      <div style={{ width: '100%' }} />
                    )}
                  </div>
                </div>
                <div className='iosheaderSection'>
                  <p>
                    {intl.formatMessage({
                      id: 'settings.label.primaryFields',
                    })}
                  </p>
                  <div className='section-row '>
                    <div className='select'>
                      <p>
                        {intl.formatMessage({
                          id: 'settings.label.firstField',
                        })}
                      </p>
                      <SelectInput
                        label={intl.formatMessage({
                          id: 'settings.label.firstField',
                        })}
                        onChange={(e) => {
                          const obj = {
                            ...primaryFields,
                            firstField: e.target.value,
                          };
                          props.walletFormPropsChanged('primaryFields', obj);
                        }}
                        defaultValue={'CREATION_DATE'}
                        value={primaryFields.firstField}
                        name='firstField'
                      >
                        <MenuItem value='CREATION_DATE'>
                          {intl.formatMessage({
                            id: 'settings.label.creationDate',
                          })}
                        </MenuItem>
                        <MenuItem value='DISABLED'>
                          {intl.formatMessage({
                            id: 'settings.label.disabled',
                          })}
                        </MenuItem>
                      </SelectInput>
                    </div>{' '}
                    <div style={{ width: '100%' }} />
                  </div>

                  <div className='section-row'>
                    <div className='select'>
                      <p>
                        {intl.formatMessage({
                          id: 'settings.label.secondField',
                        })}
                      </p>
                      <SelectInput
                        label={intl.formatMessage({
                          id: 'settings.label.secondField',
                        })}
                        onChange={(e) => {
                          const obj = {
                            ...primaryFields,
                            secondField: e.target.value,
                          };
                          props.walletFormPropsChanged('primaryFields', obj);
                        }}
                        defaultValue={'TYPE'}
                        value={primaryFields.secondField}
                        name='secondField'
                      >
                        <MenuItem value='REWARDS'>
                          {intl.formatMessage({
                            id: 'settings.label.rewards',
                          })}
                        </MenuItem>

                        <MenuItem value='DISABLED'>
                          {intl.formatMessage({
                            id: 'settings.label.disabled',
                          })}
                        </MenuItem>
                      </SelectInput>
                    </div>
                    <div style={{ width: '100%' }} />
                  </div>
                </div>
              </div>
            )}
            {openWalletInfos && (
              <div
                className='section'
                ref={(el: any) => {
                  contactRef.current = el;
                }}
                style={{ boxShadow: 'none' }}
              >
                <h3 className='section-title'>
                  {intl.formatMessage({
                    id: 'settings.label.additionalContactTitle',
                  })}
                </h3>
                {additionalContacts.map((el: any, index: number) => (
                  <div key={index} className='additionalContact'>
                    <div className='additionalContact-title'>
                      <h3>
                        {intl.formatMessage({
                          id: 'settings.label.additionalContact',
                        })}
                        {index + 1}
                      </h3>
                      {index > 0 && (
                        <span
                          onClick={() =>
                            handleDeleteAdditionalContactChange(index)
                          }
                        >
                          <Icon
                            icon={ic_delete}
                            size={18}
                            style={{ color: '#FF5078' }}
                          />{' '}
                          {intl.formatMessage({ id: 'settings.button.delete' })}
                        </span>
                      )}
                    </div>

                    <InputLabel
                      label={intl.formatMessage({
                        id: 'settings.label.title',
                      })}
                      width={730}
                      name='contactTitle'
                      value={el.title}
                      placeholder={intl.formatMessage({
                        id: 'settings.placeholder.title',
                      })}
                      limit={
                        maxChars.wallet.contactTitle || maxChars.contactTitle
                      }
                      onChange={(e) =>
                        handleAdditionalContactTitleChange(e, index)
                      }
                    />
                    <div className='select'>
                      <p>
                        {intl.formatMessage({
                          id: 'settings.placeholder.fieldType',
                        })}
                      </p>
                      <SelectInput
                        // open={selectOpen}
                        // onOpen={() => setSelectOpen(true)}
                        label={intl.formatMessage({
                          id: 'settings.label.fieldType',
                        })}
                        onChange={(e) =>
                          handleAdditionalContactTypeChnage(e, index)
                        }
                        defaultValue='URL'
                        value={el.kind}
                      >
                        <MenuItem value='URL'>
                          {' '}
                          {intl.formatMessage({
                            id: 'settings.label.url',
                          })}
                        </MenuItem>
                        <MenuItem value='PHONE'>
                          {' '}
                          {intl.formatMessage({
                            id: 'settings.label.phoneNumber',
                          })}
                        </MenuItem>
                        <MenuItem value='EMAIL'>
                          {' '}
                          {intl.formatMessage({
                            id: 'settings.label.email',
                          })}
                        </MenuItem>
                      </SelectInput>
                    </div>
                    <InputLabel
                      // type={el.type === 'PHONE' ? 'tel' : 'text'}
                      label={intl.formatMessage({
                        id: 'settings.label.value',
                      })}
                      width={730}
                      name='contactValue'
                      // width={280}
                      rows={20}
                      value={el.value}
                      // error={descriptionError}
                      placeholder={intl.formatMessage({
                        id: 'settings.placeholder.value',
                      })}
                      limit={
                        maxChars.wallet.contactValue || maxChars.contactValue
                      }
                      onChange={(e) =>
                        handleAdditionalContactValueChange(e, index)
                      }
                    />
                  </div>
                ))}
                <div className='additionalContact-action'>
                  <Button
                    color='#FF5078'
                    border='1px solid #FF5078'
                    background='#fff'
                    onClick={() => handleAddAdditionalContactChange()}
                  >
                    <Icon
                      style={{ color: '#FF5078', marginRight: '10px' }}
                      icon={ic_add_circle_outline}
                      size={26}
                    />
                    {intl.formatMessage({
                      id: 'settings.label.addAdditionalContact',
                    })}
                  </Button>
                  <p className='error-message'>{additionalContactError}</p>
                </div>
              </div>
            )}
            {openWalletInfos && (
              <div className='section' style={{ boxShadow: 'none' }}>
                <h3 className='section-title'>
                  {' '}
                  {intl.formatMessage({
                    id: 'settings.label.locatisation',
                  })}
                </h3>
                <div className='section-row'>
                  <InputLabel
                    type='number'
                    label={intl.formatMessage({
                      id: 'settings.label.latitude',
                    })}
                    name='latitude'
                    placeholder={intl.formatMessage({
                      id: 'settings.placeholder.latitude',
                    })}
                    value={locations[0].latitude}
                    // disabled
                    // error={latitudeError}
                    onChange={(e) => handleLocationEventChange(e, 'lat')}
                  />
                  <InputLabel
                    type='number'
                    label={intl.formatMessage({
                      id: 'settings.label.longitude',
                    })}
                    name='longitude'
                    placeholder={intl.formatMessage({
                      id: 'settings.placeholder.longitude',
                    })}
                    value={locations[0].longitude}
                    // disabled
                    // error={latitudeError}
                    onChange={(e) => handleLocationEventChange(e, 'lng')}
                  />
                </div>
                <div className='section-row'>
                  <InputLabel
                    label={intl.formatMessage({
                      id: 'settings.label.locatisationNotificationMessage',
                    })}
                    inputType='textArea'
                    rows={6}
                    height={40}
                    width={730}
                    name='notifMessage'
                    value={locations[0].relevantText}
                    placeholder={intl.formatMessage({
                      id: 'settings.placholder.locatisationNotificationMessage',
                    })}
                    limit={
                      maxChars.wallet.notifMessage || maxChars.notifMessage
                    }
                    onChange={(e) =>
                      handleLocationChange(
                        e.target.value,
                        'locations',
                        'description'
                      )
                    }
                  />
                </div>
                <div className='map-section'>
                  <MapComponent
                    onChange={handleLocationChange}
                    locations={locations}
                  />
                </div>
                <div
                  className='section-description'
                  ref={(el: any) => {
                    descriptionRef.current = el;
                  }}
                >
                  {descriptions.map((el: any, index: number) => (
                    <div key={index} className='description'>
                      <div className='description-title'>
                        <h3>
                          {' '}
                          {intl.formatMessage({
                            id: 'settings.label.description',
                          })}{' '}
                          {index + 1}*
                        </h3>
                        {index > 0 && (
                          <span
                            onClick={() => handleDeleteDescriptionChange(index)}
                          >
                            <Icon
                              icon={ic_delete}
                              size={18}
                              style={{ color: '#FF5078' }}
                            />{' '}
                            {intl.formatMessage({
                              id: 'settings.button.delete',
                            })}
                          </span>
                        )}
                      </div>

                      <InputLabel
                        label=''
                        width={730}
                        name='descriptionTitle'
                        value={el.title}
                        placeholder={intl.formatMessage({
                          id: 'settings.placeholder.descriptionTitle',
                        })}
                        limit={
                          maxChars.wallet.descriptionTitle ||
                          maxChars.descriptionTitle
                        }
                        onChange={(e) => handleDescriptionTitleChange(e, index)}
                      />
                      <InputLabel
                        height={158}
                        inputType='textArea'
                        label=''
                        width={730}
                        name='description'
                        // width={280}
                        rows={20}
                        value={el.value}
                        // error={descriptionError}
                        placeholder={intl.formatMessage({
                          id: 'settings.placeholder.description',
                        })}
                        limit={
                          maxChars.wallet.description || maxChars.description
                        }
                        onChange={(e) => handleDescriptionValueChange(e, index)}
                      />
                    </div>
                  ))}
                  <div className='description-action'>
                    <Button
                      color='#FF5078'
                      border='1px solid #FF5078'
                      background='#fff'
                      onClick={() => handleAddDescriptionChange()}
                    >
                      <Icon
                        style={{ color: '#FF5078', marginRight: '10px' }}
                        icon={ic_add_circle_outline}
                        size={26}
                      />
                      {intl.formatMessage({
                        id: 'settings.button.addDescription',
                      })}
                    </Button>
                    <p className='error-message'>{descriptionError}</p>
                  </div>
                  <ExpirationDateSection />
                </div>
              </div>
            )}

            {openWalletStyle && (
              <div
                className='section'
                ref={(el: any) => {
                  styleRef.current = el;
                }}
                style={{ boxShadow: 'none' }}
              >
                <h3 className='section-title'>
                  {intl.formatMessage({ id: 'settings.label.walletStyle' })}
                </h3>
                <div className='section-row'>
                  <ColorPicker
                    label={intl.formatMessage({
                      id: 'settings.label.styles.titlesColor',
                    })}
                    color={labelColor}
                    error={labelColorError}
                    onChange={(color: any) => {
                      handleColorChange(color, 'labelColor');
                    }}
                  />

                  <ColorPicker
                    label={intl.formatMessage({
                      id: 'settings.label.styles.textColor',
                    })}
                    color={foregroundColor}
                    error={foregroundColorError}
                    onChange={(color: any) => {
                      handleColorChange(color, 'foregroundColor');
                    }}
                  />
                  <ColorPicker
                    label={intl.formatMessage({
                      id: 'settings.label.styles.backgroundColor',
                    })}
                    color={backgroundColor}
                    error={backgroundColorError}
                    onChange={(color: any) => {
                      handleColorChange(color, 'backgroundColor');
                    }}
                  />
                </div>

                <h3 className='section-title'>Image</h3>
                <div className='section-row'>
                  <InputFile
                    className='btn_parcourir '
                    width={110}
                    label={intl.formatMessage({
                      id: 'settings.label.styles.changeLogo',
                    })}
                    image={logo}
                    defaultImage={defaultLogo}
                    error={logoError}
                    onChange={(e) => {
                      handleFileChange(e, 'logo');
                    }}
                    settingsSection={true}
                  ></InputFile>

                  <InputFile
                    className='btn_parcourir '
                    width={110}
                    label={intl.formatMessage({
                      id: 'settings.label.styles.changeIcon',
                    })}
                    image={icon}
                    defaultImage={cover}
                    error={iconError}
                    onChange={(e) => {
                      handleFileChange(e, 'icon');
                    }}
                    settingsSection={true}
                  ></InputFile>
                  <InputFile
                    className='btn_parcourir '
                    label={intl.formatMessage({
                      id: 'settings.label.styles.changeStrip',
                    })}
                    image={strip}
                    defaultImage={cover}
                    error={stripError}
                    onChange={(e) => {
                      handleFileChange(e, 'strip');
                    }}
                    settingsSection={true}
                  ></InputFile>
                </div>
              </div>
            )}
          </div>
          {openWalletInfos && (
            <div className='section wireframe-section'>
              <RadioGroup
                name='previews'
                value={switchPreview}
                onChange={handlePreviewChange}
                className='radioGroup'
              >
                <FormControlLabel
                  value='android'
                  control={<Radio />}
                  label='Android'
                />
                <FormControlLabel value='iOS' control={<Radio />} label='iOS' />
              </RadioGroup>

              {switchPreview === 'android' && (
                <Wireframe
                  backgroundColor={backgroundColor}
                  logo={logo}
                  name={name}
                  strip={strip}
                  address={address}
                  descriptions={descriptions}
                  openingHours={openingHours}
                  additionalContacts={additionalContacts}
                  gainUnit={gainUnit}
                  barcodeType={barcodeType}
                  primaryFields={primaryFields}
                />
              )}

              {switchPreview === 'iOS' && (
                <WireframeIPhone
                  backgroundColor={backgroundColor}
                  logo={logo}
                  name={name}
                  textColor={foregroundColor}
                  titleColor={labelColor}
                  strip={strip}
                  address={address}
                  descriptions={descriptions}
                  openingHours={openingHours}
                  additionalContacts={additionalContacts}
                  facebook={facebook}
                  linkedin={linkedin}
                  website={website}
                  businessPhone={businessPhone}
                  servicePhone={servicePhone}
                  instagram={instagram}
                  gainUnit={gainUnit}
                  displayWalletName={displayWalletName}
                  barcodeType={barcodeType}
                  label={label}
                  value={value}
                  labelPersonalizedText={labelPersonnalizedText}
                  valuePersonalizedText={valuePersonnalizedText}
                  primaryFields={primaryFields}
                />
              )}
            </div>
          )}
          {openWalletStyle && (
            <div className='section wireframe-section'>
              <RadioGroup
                name='previews'
                value={switchPreview}
                onChange={handlePreviewChange}
                className='radioGroup'
              >
                <FormControlLabel
                  value='android'
                  control={<Radio />}
                  label='Android'
                />
                <FormControlLabel value='iOS' control={<Radio />} label='iOS' />
              </RadioGroup>

              {switchPreview === 'android' && (
                <Wireframe
                  backgroundColor={backgroundColor}
                  logo={logo}
                  name={name}
                  strip={strip}
                  address={address}
                  descriptions={descriptions}
                  openingHours={openingHours}
                  additionalContacts={additionalContacts}
                  gainUnit={gainUnit}
                  barcodeType={barcodeType}
                  primaryFields={primaryFields}
                />
              )}

              {switchPreview === 'iOS' && (
                <WireframeIPhone
                  backgroundColor={backgroundColor}
                  logo={logo}
                  name={name}
                  textColor={foregroundColor}
                  titleColor={labelColor}
                  strip={strip}
                  address={address}
                  descriptions={descriptions}
                  openingHours={openingHours}
                  additionalContacts={additionalContacts}
                  facebook={facebook}
                  linkedin={linkedin}
                  website={website}
                  businessPhone={businessPhone}
                  servicePhone={servicePhone}
                  instagram={instagram}
                  gainUnit={gainUnit}
                  displayWalletName={displayWalletName}
                  barcodeType={barcodeType}
                  label={label}
                  value={value}
                  labelPersonalizedText={labelPersonnalizedText}
                  valuePersonalizedText={valuePersonnalizedText}
                  primaryFields={primaryFields}
                />
              )}
            </div>
          )}
        </div>
      </Fragment>
    </FormWrapper>
  );
};

const mapStateToProps = ({ walletForm }: RootState) => {
  const {
    id,
    name,
    logo,
    icon,
    strip,
    labelColor,
    backgroundColor,
    foregroundColor,
    businessPhone,
    servicePhone,
    additionalContacts,
    address,
    facebook,
    instagram,
    linkedin,
    locations,
    website,
    openingHours,
    descriptions,
    username,
    email,
    gainUnit,
    login,
    communicationEmail,
    label,
    value,
    labelPersonnalizedText,
    valuePersonnalizedText,
    password,
    confirmPassword,
    currencies,
    displayWalletName,
    expirationDate,
    period,
    periodLength,
    primaryFields,
    distributionUpdateStrategy,
    expirationType,
    nameError,
    barcodeType,
    logoError,
    iconError,
    stripError,
    labelColorError,
    backgroundColorError,
    foregroundColorError,
    addressError,
    openingHoursError,

    businessPhoneError,
    facebookError,
    instagramError,
    linkedinError,
    websiteError,
    usernameError,
    emailError,
    passwordError,
    confirmPasswordError,
    descriptionError,
    //Label
    title,
    //Button
    button,
    //loading
    loading,
    additionalContactError,
  } = walletForm;
  return {
    id,
    name,
    logo,
    icon,
    strip,
    labelColor,
    backgroundColor,
    foregroundColor,
    address,
    facebook,
    instagram,
    linkedin,
    website,
    openingHours,
    businessPhone,
    servicePhone,
    descriptions,
    additionalContacts,
    username,
    email,
    displayWalletName,
    gainUnit,
    locations,
    login,
    communicationEmail,
    password,
    confirmPassword,
    expirationDate,
    period,
    periodLength,
    barcodeType,
    distributionUpdateStrategy,
    primaryFields,
    expirationType,
    label,
    value,
    labelPersonnalizedText,
    valuePersonnalizedText,
    nameError,
    logoError,
    iconError,
    stripError,
    labelColorError,
    backgroundColorError,
    foregroundColorError,
    businessPhoneError,
    addressError,
    openingHoursError,
    facebookError,
    instagramError,
    linkedinError,
    websiteError,
    usernameError,
    emailError,
    passwordError,
    confirmPasswordError,
    descriptionError,
    additionalContactError,
    currencies,
    //Label
    title,
    //Button
    button,
    //loading
    loading,
  };
};
export const connector = connect(
  mapStateToProps,
  {
    walletFormPropsChanged,
    walletFormEditWallet,
  },
  null,
  { forwardRef: true }
);

export default connector(WalletForm);
