import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import axios from 'axios';
import { getDataFromCache } from '../utils/cache';
import { isAllowed } from '../utils/guard';

const GuardedRoute: React.FC<any> = ({
  component: Component,
  type,
  ...rest
}) => {
  const token = getDataFromCache('token');
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  const objectUser: any = getDataFromCache('user');
  const canAccess: boolean =
    token && objectUser && isAllowed(type, objectUser['allowedPasses']);
  return (
    <Route
      {...rest}
      render={(props) =>
        canAccess ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/statistics',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default GuardedRoute;
