import moment from 'moment';
import {
  HOTEL_FORM_PROPS,
  HOTEL_FORM_PROPS_CHANGED,
  HOTEL_GET_HOTEL_PROPS,
  HOTEL_INITIAL_STATE,
} from '../actions/types';
import { HotelActionTypes, HotelState } from '../modules/Hotel/hotel.types';

const INITIAL_STATE: HotelState = {
  image: null,
  address: '',
  labelColor: {
    hex: '#000000',
    rgb: { r: 0, g: 0, b: 0, a: 1 },
  },
  backgroundColor: {
    hex: '#ffffff',
    rgb: { r: 255, g: 255, b: 255, a: 1 },
  },
  foregroundColor: {
    hex: '#000000',
    rgb: { r: 0, g: 0, b: 0, a: 1 },
  },
  barcodeType: 'CODE128',
  contactPhone: '',
  email: '',
  strip: null,
  name: '',
  number: '',
  type: '',
  terms: '',
  website: '',
  loading: false,
  locations: [
    {
      name: null,
      longitude: 2.3125185928956062,
      latitude: 49.07575430649549,
      relevantText: '',
    },
  ],
  //errors
  imageError: '',
  nameError: '',
  addressError: '',
  termsError: '',
  numberError: '',
  typeError: '',
  contactPhoneError: '',
  emailError: '',
  websiteError: '',
  labelColorError: '',
  foregroundColorError: '',
  backgroundColorError: '',
  //image: '',
  createdAt: '',
  checkin: '',
  checkout: '',
  createdAtError: '',
  checkinError: '',
  checkoutError: '',
};

export default (state = INITIAL_STATE, action: HotelActionTypes) => {
  switch (action.type) {
    case HOTEL_FORM_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };
    case HOTEL_FORM_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };
    case HOTEL_FORM_PROPS:
      return { ...state, ...action.newState, loading: false };
    case HOTEL_GET_HOTEL_PROPS:
      return { ...state, ...action.payload };
    case HOTEL_INITIAL_STATE:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
