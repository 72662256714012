import axios from 'axios';
import { URL } from './config';
import { CinemasTicketsApiResponse } from './ApiTypes';

export const getMoviesTicketsList = (walletID: string, searchFilter: any) => {
  let requestURL = `${URL}/wallets/${walletID}/cinemas`;
  if (searchFilter) {
    const filterItem = searchFilter.find(
      (elem: { name: string; value: string }) => elem.value !== ''
    );
    if (filterItem !== undefined)
      requestURL = `${URL}/wallets/${walletID}/cinemas?${filterItem.name}=${filterItem.value}`;
  }

  return axios
    .get(requestURL)
    .then(({ data }: { data: Array<CinemasTicketsApiResponse> }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
};
export const getCinemaTicketsChildrenList = (
  walletId: any,
  ticketId: any,
  page: number | string,
  size: number | string,
  searchFilter: any | null = null
) => {
  let requestURL = `${URL}/wallets/${walletId}/cinemas/${ticketId}/sent`;
  if (searchFilter) {
    const filterItem = searchFilter.find(
      (elem: { name: string; value: string }) => elem.value !== ''
    );
    if (filterItem !== undefined)
      requestURL = `${URL}/wallets/${walletId}/cinemas/${ticketId}/sent?${filterItem.name}=${filterItem.value}`;
  }
  return axios
    .get(requestURL, {
      params: {
        page,
        size,
      },
    })
    .then(({ data }: { data: Array<any> }) => {
      return data;
    })
    .catch((error) => {
      return error.response;
    });
};
