import { combineReducers } from 'redux';
import LoginReducer from './loginReducer';
import WalletReducer from './walletReducer';
import AddCustomerReducer from './addCustomerReducer';
import OffersReducer from './offersReducer';
import OfferReducer from './offerReducer';
import UpdateGainReducer from './updateGainReducer';
import SettingsReducer from './settingsReducer';
import CouponReducer from './couponReducer';
import CouponsReducer from './couponsReducer';
import ProfileReducer from './profileReducer';
import ForgotPasswordReducer from './forgotPasswordReducer';
import ResetPasswordReducer from './resetPasswordReducer';
import eventsReducer from './eventsReducer';
import eventReducer from './eventReducer';
import eventsCustomerReducer from './eventsCustomerReducer';
import walletFormReducer from './walletFormReducer';
import statsReducer from './statsReducer';
import snackbarReducer from './snackbarReducer';
import statsDetailReducer from './statsDetailReducer';
import ShipsReducer from './shipsReducer';
import ShipReducer from './shipReducer';
import BusesReducer from './busesReducer';
import BusReducer from './busReducer';
import TramsReducer from './tramsReducer';
import TramReducer from './tramReducer';
import RailsReducer from './railsReducer';
import RailReducer from './railReducer';
import CinemaTickets from './cinemaTicketsReducer';
import CinemaTicket from './cinemaTicketReducer';
import HotelsReducer from './hotelsReducer';
import HotelReducer from './hotelReducer';
import CustomerFerryReducer from './customerFerryReducer';
import CustomerBusReducer from './customerBusReducer';
import CustomerTramReducer from './customerTramReducer';
import CustomerRailReducer from './customerRailReducer';
import FlightsReducer from './flightsReducer';
import FlightReducer from './flightReducer';
import CustomerFlightReducer from './customerFlightReducer';
import demoReducer from './demoReducer';
import customerInactiveReducer from './customerInactiveReducer';

const reducers = combineReducers({
  login: LoginReducer,
  walletDetail: WalletReducer,
  walletForm: walletFormReducer,
  customer: AddCustomerReducer,
  offers: OffersReducer,
  offer: OfferReducer,
  updateGain: UpdateGainReducer,
  settings: SettingsReducer,
  coupon: CouponReducer,
  coupons: CouponsReducer,
  profile: ProfileReducer,
  forgotPassword: ForgotPasswordReducer,
  resetPassword: ResetPasswordReducer,
  events: eventsReducer,
  event: eventReducer,
  eventsCustomers: eventsCustomerReducer,
  stats: statsReducer,
  snackbar: snackbarReducer,
  statsDetails: statsDetailReducer,
  ferries: ShipsReducer,
  ferry: ShipReducer,
  buses: BusesReducer,
  bus: BusReducer,
  trams: TramsReducer,
  tram: TramReducer,
  rails: RailsReducer,
  cinemaTickets: CinemaTickets,
  cinemaTicket: CinemaTicket,

  rail: RailReducer,
  hotels: HotelsReducer,
  hotel: HotelReducer,
  customerferry: CustomerFerryReducer,
  customerbus: CustomerBusReducer,
  customertram: CustomerTramReducer,
  customerrail: CustomerRailReducer,
  flight: FlightReducer,
  customerflight: CustomerFlightReducer,
  flights: FlightsReducer,
  demos: demoReducer,
  inactivesCustomer: customerInactiveReducer,
});

export type RootState = ReturnType<typeof reducers>;

export default reducers;
