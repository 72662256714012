import React from 'react';
import { useIntl } from 'react-intl';
import { Route, Switch, RouteComponentProps, Router } from 'react-router-dom';
import SideBar from '../../components/Drawer';

import Trams from '../../modules/Trams';
import { useStyles } from './tramsRouter.styles';
import NotFound from '../../modules/NotFound';
import Tram from '../../modules/SentTransits/Tram';
//Modules

const TramsRouter: React.FC<RouteComponentProps> = (props) => {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <div className={classes.rootAppRouter}>
      <SideBar title={intl.formatMessage({ id: 'drawer.menuList.trams' })}>
        <Switch>
          <Route path='/trams/:tramID' component={Tram} />
          <Route path='/trams' component={Trams} exact />
          <Route path='/trams/*' component={NotFound} />
        </Switch>
      </SideBar>
    </div>
  );
};

export default TramsRouter;
