import React, { useEffect, useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import InputLabel from '../../commons/Inputs/InputLabel';
import SelectInput from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { DatePicker } from '@material-ui/pickers';
import { TextField, Divider, IconButton } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import Calendar from '../../icons/Calendar';

import { walletFormPropsChanged } from '../../actions';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import { flexbox } from '@material-ui/system';
import { Wrapper } from './expirationDateSection.styles';
import { useIntl } from 'react-intl';

const ExpirationDateSection = (props: any) => {
  const {
    expirationDate,
    period,
    periodLength,
    distributionUpdateStrategy,
    expirationType,
    walletFormPropsChanged,
  } = props;
  const intl = useIntl();

  const handleEventChange = (value: any, fieldName: string) => {
    walletFormPropsChanged(fieldName, value);
  };

  return (
    <Wrapper>
      <h3 className='section-title'>
        {intl.formatMessage({
          id: 'issuing.label.title',
        })}
      </h3>

      <FormGroup style={{ marginLeft: '-22px' }}>
        <FormControlLabel
          control={
            <Checkbox
              value={expirationType}
              onChange={(e) =>
                handleEventChange(
                  e.target.checked ? 'AFTER_PERIOD' : 'NO_EXPIRATION',
                  'expirationType'
                )
              }
              checked={expirationType !== 'NO_EXPIRATION'}
            />
          }
          label={intl.formatMessage({
            id: 'issuing.checkbox.expirationDate',
          })}
        />
      </FormGroup>
      {expirationType !== 'NO_EXPIRATION' && (
        <div>
          <h5>
            {intl.formatMessage({
              id: 'issuing.label.passWillExpire',
            })}
          </h5>
          <FormControl component='fieldset'>
            <RadioGroup
              aria-label='expirationType'
              name='expirationType'
              value={expirationType}
              defaultValue='AFTER_PERIOD'
              onChange={(e) =>
                handleEventChange(e.target.value, 'expirationType')
              }
            >
              <div style={{ display: 'flex' }}>
                <FormControlLabel
                  value='AFTER_PERIOD'
                  control={<Radio />}
                  label={intl.formatMessage({
                    id: 'issuing.button.afterPeriod',
                  })}
                />
                {expirationType == 'AFTER_PERIOD' && (
                  <div style={{ display: 'flex' }}>
                    <InputLabel
                      label=''
                      name='periodLength'
                      value={periodLength}
                      error={''}
                      type='number'
                      min={1}
                      width={85}
                      max={100}
                      placeholder='1'
                      onChange={(e) => {
                        handleEventChange(
                          Number(e.target.value),
                          'periodLength'
                        );
                      }}
                    />
                    <SelectInput
                      style={{ marginLeft: '30px' }}
                      name='period'
                      onChange={(e) => {
                        handleEventChange(e.target.value, 'period');
                      }}
                      defaultValue='YEARS'
                      value={period}
                    >
                      <MenuItem value='WEEKS'>
                        {intl.formatMessage({
                          id: 'issuing.select.weeks',
                        })}
                      </MenuItem>
                      <MenuItem value='MONTHS'>
                        {intl.formatMessage({
                          id: 'issuing.select.months',
                        })}
                      </MenuItem>
                      <MenuItem value='YEARS'>
                        {intl.formatMessage({
                          id: 'issuing.select.years',
                        })}
                      </MenuItem>
                    </SelectInput>
                  </div>
                )}
              </div>
              <div style={{ display: 'flex' }}>
                <FormControlLabel
                  value='AT_DATETIME'
                  control={<Radio />}
                  label={intl.formatMessage({
                    id: 'issuing.button.atDateTime',
                  })}
                />
                {expirationType === 'AT_DATETIME' && (
                  <DatePicker
                    id='expirationDATE'
                    label={''}
                    format='MMM dd yyyy'
                    inputVariant='outlined'
                    value={expirationDate}
                    onChange={(e) => handleEventChange(e, 'expirationDate')}
                    clearable
                    disablePast
                    TextFieldComponent={({ InputProps, ...rest }) => (
                      <TextField
                        {...rest}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <IconButton
                                onClick={
                                  (InputProps as any)?.endAdornment?.props
                                    ?.children?.props?.onClick
                                }
                              >
                                <Calendar />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              </div>
            </RadioGroup>
          </FormControl>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) =>
                    handleEventChange(
                      e.target.checked ? 'UPDATE_ALL' : 'NO_UPDATE',
                      'distributionUpdateStrategy'
                    )
                  }
                  checked={distributionUpdateStrategy === 'UPDATE_ALL'}
                />
              }
              label={intl.formatMessage({
                id: 'issuing.checkbox.updateAll',
              })}
            />
          </FormGroup>
        </div>
      )}
    </Wrapper>
  );
};
const mapStateToProps = ({ walletForm }: RootState) => {
  const {
    expirationDate,
    period,
    periodLength,
    distributionUpdateStrategy,
    expirationType,
  } = walletForm;
  return {
    expirationDate,
    period,
    periodLength,
    distributionUpdateStrategy,
    expirationType,
  };
};
export const connector = connect(mapStateToProps, {
  walletFormPropsChanged,
});

export default connector(ExpirationDateSection);
