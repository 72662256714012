import { EditorState } from 'draft-js';
import React, { useState, useEffect } from 'react';
import { convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';

type TextEditorProps = {
  oldState: string;
  label?: string;
  placeholder?: string;
  changeValue: (value: string, editorName: string | undefined) => void;
  error?: string;
  editorName?: string;
};

const TextEditor: React.FC<TextEditorProps> = (props) => {
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );
  useEffect(() => {
    if (props.oldState) {
      let contentBlock = htmlToDraft(props.oldState);
      let contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      let editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    }
  }, []);

  const editorStateChange = (e: EditorState) => {
    setEditorState(e);
    props.changeValue(
      draftToHtml(convertToRaw(editorState.getCurrentContent())),
      props.editorName
    );
  };

  return (
    <div className='editor-container'>
      <span
        className='label'
        style={{
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '21px',
          fontSize: '14px',
          color: '#828282',
          margin: '5px',
        }}
      >
        {props.label}
      </span>
      <Editor
        placeholder={props.placeholder}
        editorState={editorState}
        wrapperClassName='editor-wrapper'
        editorClassName='editor-component'
        onEditorStateChange={(e) => editorStateChange(e)}
      />
      {props.error !== '' && <p className='error'>{props.error}</p>}
    </div>
  );
};

export default TextEditor;
