import React from 'react';
import axios from 'axios';

import { Route, Redirect, RouteProps } from 'react-router-dom';
import { getDataFromCache } from './cache';
import { User } from '../apis/ApiTypes';

export const PrivateRoute = ({ component, ...rest }: RouteProps) => {
  const objectUser: any = getDataFromCache('user');
  const token = getDataFromCache('token');
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  const Component: any = component;
  return (
    <Route
      {...rest}
      render={(props) =>
        token && objectUser && objectUser.wallet ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};
