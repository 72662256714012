import {
  EDIT_BUS_INITIAL_STATE,
  BUS_FORM_PROPS,
  BUS_FORM_PROPS_CHANGED,
  BUS_GET_BUS_PROPS,
  BUS_INITIAL_STATE,
} from '../actions/types';
import { BusState, BusActionTypes } from '../modules/Bus/bus.types';
import moment from 'moment';

const INITIAL_STATE: any = {
  loading: false,
  labelColor: {
    hex: '#000000',
    rgb: { r: 0, g: 0, b: 0, a: 1 },
  },
  backgroundColor: {
    hex: '#ffffff',
    rgb: { r: 255, g: 255, b: 255, a: 1 },
  },
  foregroundColor: {
    hex: '#000000',
    rgb: { r: 0, g: 0, b: 0, a: 1 },
  },
  number: '',
  originStationCode: '',
  originName: '',
  destinationStationCode: '',
  destinationName: '',
  departureTime: '',
  arrivalTime: '',
  tarif: '',
  details: '',
  terms: '',
  customerServiceNumber: '',
  unlimited: false,
  barcodeType: 'PDF417',
  enableOneTimeUse: false,
  disableIOSPassSharing: false,
  disableMultipleHolders: false,
  enableSecurityAnimation: false,
  enableRotatingBarcode: false,
  disableEmailSharing: false,
  //errors
  numberError: '',
  originStationCodeError: '',
  originNameError: '',
  destinationStationCodeError: '',
  destinationNameError: '',
  departureTimeError: '',
  arrivalTimeError: '',
  tarifError: '',
  detailsError: '',
  termsError: '',
  customerServiceNumberError: '',
  imageError: '',
  strip: null,
  image: null,
  stripError: '',
  labelColorError: '',
  backgroundColorError: '',
  foregroundColorError: '',
  createdAt: '',
  passengerType: '',
  tripType: '',
  phoneNumber: '',
  createdAtError: '',
  passengerTypeError: '',
  tripTypeError: '',
  phoneNumberError: '',
};

export default (state = INITIAL_STATE, action: BusActionTypes) => {
  switch (action.type) {
    // case BUS_FORM_PROPS_CHANGED:
    //   return { ...state, [action.prop]: action.value };
    case BUS_FORM_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };
    case BUS_FORM_PROPS:
      return { ...state, ...action.newState, loading: false };
    case BUS_GET_BUS_PROPS:
      return { ...state, ...action.payload, ...action.payload.security };
    case BUS_INITIAL_STATE:
      return { ...INITIAL_STATE };
    case EDIT_BUS_INITIAL_STATE:
      return {
        ...INITIAL_STATE,
        ...action.value,
      };
    default:
      return state;
  }
};
