import { css } from 'styled-components';

export type BusesStylesProps = {
  loading: string;
  couponsLength: number;
};

export const busesStyles = (props: any) => {
  let flex = 0;
  let justifyContent = 'flex-start';

  if (props.loading === 'true') {
    flex = 1;
    justifyContent = 'center';
  } else if (props.couponsLength === 0 && props.loading === 'false') {
    flex = 1;
    justifyContent = 'center';
  }
  return css`
    .MuiTablePagination-root {
      margin-top: 16px;
      float: right;
    }

    .div-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 30px 0;

      .div-input-filter {
        min-width: 400px;
        margin-right: 15px;
      }
      .select-input {
        width: 150px;
        visibility: hidden;
        position: absolute;
        left: 260px;
        top: 70px;
      }
      .filter-button {
        display: flex;
        position: absolute;
        left: 290px;
        width: 100px;
        align-items: center;
        justify-content: space-between;
        background: #4914f4;
        height: 44px;
        box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.08);
        border-radius: 15px;
        padding: 0 10px;
        transition: all 0.3s ease;
        :hover {
          cursor: pointer;
          background: #6438f5;
        }
        > h3 {
          font-family: Poppins;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: white;
          width: 80px;
        }
      }
      .filter-opened {
        transform: rotate(180deg);
      }
      .filter-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        > input {
          background-color: #fff;
          font-family: Poppins;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          color: #404040;
          box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.08);
          border-radius: 15px;
        }
      }
      .div-input-search {
        flex: 1;
        position: relative;
        margin-left: 15px;
        min-width: 450px;
        .input-search {
          box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.08);
          border-radius: 15px;
        }
        .button-search {
          position: absolute;
          top: 10px;
          right: -1px;
          margin: 0;
          height: 53px;
          border-radius: 15px;
        }
      }

      /* padding: 10px; */

      .div-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;
      }
      .div-list {
        margin: 15px 0;
        gap: 15px;
        display: flex;
        flex: ${flex};
        justify-content: ${justifyContent};
        flex-wrap: wrap;
        /* min-height: 500px; */
        align-items: center;
      }
    }
  `;
};
