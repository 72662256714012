import { OfferState } from './offer.types';
import { isEmpty } from '../../utils/validations';
import moment from 'moment';
// import { OfferApiResponse } from '../../apis/ApiTypes';

export const validateFieldsOffer = (state: OfferState) => {
  let newState: OfferState = Object.assign({}, state);

  if (!newState.image) {
    newState.imageError = 'offer.error.imageRequired';
  } else {
    newState.imageError = '';
  }

  if (!newState.startDate) {
    newState.startDateError = 'offer.error.startDateRequired';
  } else if (newState.startDate && !newState.startDate.isValid()) {
    newState.startDateError = 'offer.error.startDateInvalid';
  } else {
    newState.startDateError = '';
  }

  if (!newState.endDate) {
    newState.endDateError = 'offer.error.endDateRequired';
  } else if (newState.endDate && !newState.endDate.isValid()) {
    newState.endDateError = 'offer.error.endDateInvalid';
  } else {
    newState.endDateError = '';
  }

  if (newState.startDate && newState.endDate) {
    if (newState.startDate.isAfter(newState.endDate, 'day')) {
      newState.endDateError = 'offer.error.endDateBeforeStartDate';
    } else {
      newState.endDateError = '';
    }
  }

  if (isEmpty(newState.description)) {
    newState.descriptionError = 'offer.error.descriptionRequired';
  } else {
    newState.descriptionError = '';
  }

  let valid = false;
  if (
    newState.imageError === '' &&
    newState.startDateError === '' &&
    newState.endDateError === '' &&
    newState.descriptionError === ''
  ) {
    valid = true;
  }

  return { newState, valid };
};

export const newOfferObject = (offer: any) => {
  let formData = new FormData();

  if (offer.image) {
    formData.append('startDate', moment(offer.startDate).format('YYYY-MM-DD'));
    formData.append('endDate', moment(offer.endDate).format('YYYY-MM-DD'));
    formData.append('description', offer.description);
    formData.append('strip', offer.image);
    formData.append('status', offer.status);

    return formData;
  }

  return null;
};
