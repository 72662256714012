import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='22'
      height='24'
      fill='none'
      viewBox='0 0 22 24'
    >
      <path
        fill='#404040'
        d='M19.97 7.974c-.771 0-1.396.624-1.396 1.395v6.377a1.395 1.395 0 102.79 0V9.367c0-.771-.625-1.395-1.395-1.395zM2.032 7.974c-.771 0-1.395.624-1.395 1.395v6.377a1.395 1.395 0 102.79 0V9.367c0-.771-.625-1.395-1.396-1.395h.001zM4.424 7.973v9.567c0 .88.713 1.594 1.594 1.594h.997v3.388a1.395 1.395 0 102.79-.001v-3.388h2.391v3.388a1.395 1.395 0 102.79 0v-3.39h.997c.88 0 1.593-.713 1.593-1.594V7.971l-13.152.002zM17.39 5.98c-.41-1.459-1.385-2.705-2.701-3.526a11.524 11.524 0 00-.452-.26l.252-.453.792-1.428a.21.21 0 00-.082-.286.208.208 0 00-.284.081l-.803 1.448-.253.455a4.651 4.651 0 00-.242-.1c-.082-.03-.164-.063-.247-.092a7.129 7.129 0 00-5.222.192l-.253-.456L7.092.11a.21.21 0 00-.368.203l.787 1.425.254.452a6.727 6.727 0 00-.453.26c-1.315.821-2.292 2.068-2.7 3.525-.114.39-.176.791-.186 1.196h13.147-.001a4.713 4.713 0 00-.186-1.195l.004.004zm-9.378-.797a.598.598 0 11.598-.598c0 .33-.269.598-.598.598zm5.98 0h-.001a.598.598 0 11.598-.598c0 .33-.269.598-.598.598z'
      ></path>
    </svg>
  );
}

export default Icon;
