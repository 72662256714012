import { isEmpty, isEqual } from '../../utils/validations';
import { ProfileState, ProfileEmailSettings } from './profile.types';

export const validateFieldsProfileUpdatePassword = (state: ProfileState) => {
  let newState: ProfileState = Object.assign({}, state);

  if (isEmpty(newState.oldPassword)) {
    newState.oldPasswordError = 'profile.error.oldPasswordRequired';
  } else {
    newState.oldPasswordError = '';
  }

  if (isEmpty(newState.newPassword)) {
    newState.newPasswordError = 'profile.error.newPasswordRequired';
  } else if (newState.newPassword.length < 4) {
    newState.newPasswordError = 'profile.error.newPasswordRequiredLength';
  } else {
    newState.newPasswordError = '';
  }

  if (isEmpty(newState.confirmPassword)) {
    newState.confirmPasswordError = 'profile.error.confirmPasswordRequired';
  } else if (!isEqual(newState.newPassword, newState.confirmPassword)) {
    newState.confirmPasswordError = 'profile.error.confirmPasswordNotMatch';
  } else {
    newState.confirmPasswordError = '';
  }

  let valid = false;
  if (
    newState.newPasswordError === '' &&
    newState.confirmPasswordError === ''
  ) {
    valid = true;
  }
  return { newState, valid };
};

export const validateFieldsProfileCommunicationEmailSettings = (
  prop: string
) => {
  if (isEmpty(prop)) {
    return true;
  }
  return false;
};
