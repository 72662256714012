import {
  HOTEL_FORM_PROPS,
  HOTEL_FORM_PROPS_CHANGED,
  AppThunk,
  HOTELS_ADD_HOTEL_TO_LIST,
  HOTEL_INITIAL_STATE,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
  HOTELS_DELETE_HOTEL,
  HOTELS_DELETE_HOTEL_LOADING,
  EDIT_HOTEL_INITIAL_STATE,
  HOTEL_PROPS_CHANGED,
  CUSTOMER_HOTEL_FORM_PROPS_CHANGED,
} from './types';
import {
  CustomerHotelPropsChangedAction,
  HotelInitialStateAction,
  HotelPropsChangedAction,
  HotelState,
} from '../modules/Hotel/hotel.types';
import moment from 'moment';
import { URL_IMAGE } from '../apis/config';
import {
  validateFieldsHotel,
  newHotelObject,
  validateCustomerFieldsHotel,
  newCustomerHotelObject,
} from '../modules/Hotel/hotel.utils';
import {
  createHotel,
  removeHotel,
  editHotel,
  editCustomerHotel,
} from '../apis/hotelApi';

export const hotelPropsChanged = (
  prop: string,
  value: any
): HotelPropsChangedAction => {
  return { type: HOTEL_FORM_PROPS_CHANGED, prop, value };
};

export const addHotel = (
  walletId: string | null,
  props: HotelState,
  callback: () => void
): AppThunk => (dispatch) => {
  const { newState, valid } = validateFieldsHotel(props);
  dispatch({ type: HOTEL_FORM_PROPS, newState });
  const newHotel = newHotelObject(newState);

  if (valid) {
    dispatch({
      type: HOTEL_FORM_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });

    createHotel(walletId, newHotel).then((res: any) => {
      if (res.status == 200) {
        dispatch({
          type: HOTELS_ADD_HOTEL_TO_LIST,
          payload: res.data,
        });
        dispatch({
          type: SNACKBAR_SUCCESS,
          value: 'room.success.added',
        });
        callback();
      } else {
        dispatch({
          type: SNACKBAR_ERROR,
          value: 'wallet.error.occured',
        });
        dispatch({
          type: HOTEL_FORM_PROPS_CHANGED,
          prop: 'loading',
          value: false,
        });
      }
    });
  }
};
export const editHotelInitialState = (hotel: any): any => {
  return {
    type: EDIT_HOTEL_INITIAL_STATE,
    prop: 'payload',
    value: {
      ...hotel,
      image: URL_IMAGE + '/' + hotel.hotel,
      expirationDate: moment(hotel.expirationDate),
    },
  };
};
export const hotelInitialState = (): HotelInitialStateAction => {
  return { type: HOTEL_INITIAL_STATE };
};

export const editHotelCustomerConfirm = (
  walletId: string | null,
  hotelId: string | null,
  customerId: string | null,
  props: HotelState,
  callback: () => void
): AppThunk => (dispatch) => {
  const { newState, valid } = validateCustomerFieldsHotel(props);
  dispatch({ type: HOTEL_FORM_PROPS, newState });
  // newState.customerId = customerId;
  // const { formData: newHotel, objectData } = newCustomerHotelObject(newState);
  const { checkin, checkout } = newState;
  const hotelObject = { checkin, checkout, customerId };
  if (valid) {
    dispatch({
      type: HOTEL_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });

    editCustomerHotel(walletId, hotelId, hotelObject).then((res: any) => {
      if (res.status === 200) {
        dispatch({
          type: SNACKBAR_SUCCESS,
          value: 'room.success.updated',
        });

        callback();
      } else if (res.status === 400) {
        dispatch({
          type: HOTEL_PROPS_CHANGED,
          prop: 'messageError',
          value: 'hotel.error.datesUnavailable',
        });
        dispatch({
          type: SNACKBAR_ERROR,
          value: 'wallet.error.occured',
        });
      }
      dispatch({
        type: HOTEL_PROPS_CHANGED,
        prop: 'loading',
        value: false,
      });
    });
    // .finally(() => {
    //   dispatch({
    //     type: UPDATE_SENT_HOTELS_LIST,
    //     row: { ...objectData, _id: hotelId },
    //   });
    // });
  }
};
export const customerHotelPropsChanged = (
  prop: string,
  value: any
): CustomerHotelPropsChangedAction => {
  return { type: CUSTOMER_HOTEL_FORM_PROPS_CHANGED, prop, value };
};
