import axios from 'axios';
import { URL } from './config';

export const getFlightsList = (walletID: string) => {
  const requestURL = `${URL}/wallets/${walletID}/flights`;

  return axios
    .get(requestURL)
    .then(({ data }: { data: Array<any> }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const getCouponsChildrenList = (
  walletId: any,
  flightId: any,
  page: number | string,
  size: number | string,
  searchFilter: any | null = null
) => {
  let requestURL = `${URL}/wallets/${walletId}/flights/${flightId}/sent`;
  if (searchFilter) {
    const filterItem = searchFilter.find(
      (elem: { name: string; value: string }) => elem.value !== ''
    );
    if (filterItem !== undefined)
      requestURL = `${URL}/wallets/${walletId}/flights/${flightId}/sent?${filterItem.name}=${filterItem.value}`;
  }
  return axios
    .get(requestURL, {
      params: {
        page,
        size,
      },
    })
    .then(({ data }: { data: Array<any> }) => {
      return data;
    })
    .catch((error) => {
      return error.response;
    });
};
export const removeCustomerFlight = (
  flightId: string,
  customerId: string,
  walletId: string
) => {
  const requestURL = `${URL}/wallets/${walletId}/flights/${flightId}/customers/${customerId}`;

  return axios
    .delete(requestURL)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};
