import 'date-fns';
import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
//Style
import styled from 'styled-components';
import { hotelStyles } from './hotel.styles';
//Material
import {
  Divider,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Typography,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  TimePicker,
} from '@material-ui/pickers';
//Components
import InputLabel from '../../commons/Inputs/InputLabel';
//Commomns
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import Button from '../../commons/Button';
import { getImageErrorMessage } from '../../utils/common';

//Actions
import { hotelPropsChanged, addHotel, hotelInitialState } from '../../actions';
//Reducers
import { RootState } from '../../reducers';
//Type

// import { AddCustomerFormProps } from './addCustomer.types';

import cover from '../../assets/images/lg-thumbnail.png';
import { HotelFormProps, HotelStateError } from './hotel.types';
import InputFile from '../../commons/Inputs/InputFile';
import { CircularProgress } from '@material-ui/core';
import Text from '../../commons/Text';
import moment from 'moment';
import ColorPicker from '../../commons/ColorPicker';
import HotelWireframeAndroid from '../../components/HotelWireframeAndroid';
import HotelWireframeIPhone from '../../components/HotelWireframeIPhone';
import {
  isNumber,
  getNatural,
  getDecimal,
  isPhoneNumber,
} from '../../utils/validations';
import { getDataFromCache } from '../../utils/cache';
import { useHistory } from 'react-router-dom';
import Calendar from '../../icons/Calendar';
import Icon from 'react-icons-kit';
import { ic_warning } from 'react-icons-kit/md/ic_warning';
import maxChars from '../../utils/fieldsMaxLength';
import BarcodeTypeSelection from '../../components/barcodeSelection';

const Hotel: React.FC<HotelFormProps> = (props) => {
  const intl = useIntl();
  const [hasStateChanged, setHasStateChange] = React.useState<boolean>(false);
  const [switchPreview, setSwitchPreview] = React.useState<string>('android');
  const [QRcode, setQRcode] = React.useState<any>({ state: true, value: '' });
  const history = useHistory();
  const {
    address,
    contactPhone,
    email,
    strip,
    image,
    backgroundColor,
    foregroundColor,
    labelColor,
    name,
    number,
    type,
    terms,
    website,
    barcodeType,

    loading,
    locations,
    nameError,
    addressError,
    typeError,
    numberError,
    termsError,
    contactPhoneError,
    emailError,
    websiteError,
    imageError,
    labelColorError,
    backgroundColorError,
    foregroundColorError,
    onSaveChanged,
  } = props;
  const objectUser = getDataFromCache('user');
  const myRef = useRef(null);
  React.useEffect(() => {
    props.hotelInitialState();
  }, []);
  const handleColorChange = (color: any, prop: string) => {
    props.hotelPropsChanged(prop, color);
  };

  const getFormatedMessage = (prop: HotelStateError) => {
    return props[prop] !== ''
      ? intl.formatMessage({ id: props[prop] })
      : props[prop];
  };
  const handleFileChange = (event: any, prop: string) => {
    const file = event.target.files[0];
    if (!['image/jpeg', 'image/png'].includes(file.type)) {
      props.hotelPropsChanged(`${prop}Error`, getImageErrorMessage(file));
      return;
    }
    props.hotelPropsChanged(prop, file);
  };
  const handlePreviewChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSwitchPreview(event.target.value);
  };

  const areTextAreasValid = (fieldName: string, value: string): boolean => {
    if (value.length > (maxChars.hotel[fieldName] || maxChars[fieldName])) {
      props.hotelPropsChanged(
        `${fieldName}Error`,
        'La longueur maximale a été atteinte'
      );
      return false;
    }
    return true;
  };

  const handleClickAddHotel = () => {
    props.addHotel(objectUser.wallet, props, () =>
      onSaveChanged(window.location.reload())
    );
  };

  const handleHotelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHasStateChange(true);

    const value = event.target.value;
    const fieldName = event.target.name;
    props.hotelPropsChanged(`${fieldName}Error`, '');

    if (fieldName === 'contactPhone') {
      if (!isPhoneNumber(value)) {
        props.hotelPropsChanged(fieldName, value);
        props.hotelPropsChanged(`${fieldName}Error`, 'Phone is invalid');
        return false;
      }
    }

    if (!areTextAreasValid(fieldName, value)) return;

    props.hotelPropsChanged(fieldName, value);
  };

  const handleSelectChange = (event: any) => {
    props.hotelPropsChanged('type', event.target.value);
  };
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Text color='#404040' fontSize={16} fontWeight={600} lineHeight={24}>
            <span className='title'>
              {' '}
              {intl.formatMessage({ id: 'hotel.label.addHotel' })}
            </span>
          </Text>
        </div>
        <div>
          <div className='action'>
            {(nameError ||
              addressError ||
              typeError ||
              numberError ||
              contactPhoneError ||
              emailError ||
              websiteError ||
              termsError ||
              imageError ||
              labelColorError ||
              backgroundColorError ||
              foregroundColorError) && (
              <div className='error-icon'>
                <Icon
                  icon={ic_warning}
                  size={24}
                  style={{ color: '#FF5078' }}
                />
              </div>
            )}
          </div>
          <Button width={135} onClick={handleClickAddHotel}>
            {loading ? (
              <CircularProgress size={20} color='inherit' />
            ) : (
              intl.formatMessage({ id: 'hotel.button.add' })
            )}
          </Button>
        </div>
      </div>
      <Wrapper>
        <div className='section'>
          <div className='section-one '>
            <InputFile
              className='input-label'
              width={270}
              label={intl.formatMessage({ id: 'hotel.label.logo' })}
              image={image}
              defaultImage={cover}
              error={getFormatedMessage('imageError')}
              onChange={(e) => {
                handleFileChange(e, 'image');
              }}
            ></InputFile>
            <div className='div-content' style={{ marginLeft: '30px' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '26px',
                }}
              >
                <ColorPicker
                  label={intl.formatMessage({ id: 'coupon.label.textColor' })}
                  color={labelColor}
                  error={getFormatedMessage('labelColorError')}
                  onChange={(color: any) => {
                    handleColorChange(color, 'labelColor');
                  }}
                />
                <ColorPicker
                  label={intl.formatMessage({
                    id: 'coupon.label.foregroundColor',
                  })}
                  color={foregroundColor}
                  error={getFormatedMessage('foregroundColorError')}
                  onChange={(color: any) => {
                    handleColorChange(color, 'foregroundColor');
                  }}
                />
                <ColorPicker
                  label={intl.formatMessage({
                    id: 'coupon.label.backgroundColor',
                  })}
                  color={backgroundColor}
                  error={getFormatedMessage('backgroundColorError')}
                  onChange={(color: any) => {
                    handleColorChange(color, 'backgroundColor');
                  }}
                />
              </div>
            </div>
            <div className='section-row'>
              <BarcodeTypeSelection
                setSelectedBarcode={(barcodeType) => {
                  props.hotelPropsChanged('barcodeType', barcodeType);
                }}
                barcodeType={barcodeType}
              ></BarcodeTypeSelection>
            </div>
            <div>
              <InputLabel
                label={intl.formatMessage({ id: 'hotel.label.name' })}
                placeholder='Nom du Hotel'
                name='name'
                value={name}
                error={getFormatedMessage('nameError')}
                limit={maxChars.hotel.name || maxChars.name}
                onChange={(e) => handleHotelChange(e)}
              />

              <div className='select'>
                <p>Type de Chambre:</p>
                <Select
                  // open={selectOpen}
                  // onOpen={() => setSelectOpen(true)}
                  //label={intl.formatMessage({ id: 'hotel.label.type' })}
                  onChange={(e) => handleSelectChange(e)}
                  defaultValue='single'
                  value={type}
                >
                  <MenuItem value='single'>
                    {intl.formatMessage({ id: 'hotel.select.single' })}
                  </MenuItem>
                  <MenuItem value='double'>
                    {intl.formatMessage({ id: 'hotel.select.double' })}
                  </MenuItem>
                  {/* <MenuItem value='triple'>
                    {intl.formatMessage({ id: 'hotel.select.triple' })}
                  </MenuItem> */}
                  <MenuItem value='quad'>
                    {intl.formatMessage({ id: 'hotel.select.Quad' })}
                  </MenuItem>
                  {/* <MenuItem value='Queen'>
                    {intl.formatMessage({ id: 'hotel.select.Queen' })}
                  </MenuItem> */}
                  <MenuItem value='king'>
                    {intl.formatMessage({ id: 'hotel.select.King' })}
                  </MenuItem>
                  <MenuItem value='twin'>
                    {intl.formatMessage({ id: 'hotel.select.Twin' })}
                  </MenuItem>
                  <MenuItem value='doubleDouble'>
                    {intl.formatMessage({ id: 'hotel.select.DoubleDouble' })}
                  </MenuItem>
                  <MenuItem value='studio'>
                    {intl.formatMessage({ id: 'hotel.select.Studio' })}
                  </MenuItem>
                  <MenuItem value='suite'>
                    {intl.formatMessage({ id: 'hotel.select.Suite' })}
                  </MenuItem>
                  <MenuItem value='presidentSuite'>
                    {intl.formatMessage({ id: 'hotel.select.PresidentSuite' })}
                  </MenuItem>
                  <MenuItem value='juniorSuite'>
                    {intl.formatMessage({ id: 'hotel.select.JuniorSuite' })}
                  </MenuItem>
                  <MenuItem value='apartment'>
                    {intl.formatMessage({ id: 'hotel.select.apartment' })}
                  </MenuItem>
                  <MenuItem value='connectingRoom'>
                    {intl.formatMessage({ id: 'hotel.select.ConnectingRoom' })}
                  </MenuItem>
                  <MenuItem value='murphyRoom'>
                    {intl.formatMessage({ id: 'hotel.select.MurphyRoom' })}
                  </MenuItem>
                  <MenuItem value='accessibleRoom'>
                    {intl.formatMessage({ id: 'hotel.select.AccessibleRoom' })}
                  </MenuItem>
                  <MenuItem value='adjoiningRoom'>
                    {intl.formatMessage({ id: 'hotel.select.AdjoiningRoom' })}
                  </MenuItem>
                  <MenuItem value='adjacentRoom'>
                    {intl.formatMessage({ id: 'hotel.select.AdjacentRoom' })}
                  </MenuItem>
                  <MenuItem value='villa'>
                    {intl.formatMessage({ id: 'hotel.select.villa' })}
                  </MenuItem>
                </Select>
              </div>
              <InputLabel
                label={intl.formatMessage({ id: 'hotel.label.number' })}
                placeholder='Numéro de chambre ...'
                name='number'
                value={number}
                error={getFormatedMessage('numberError')}
                limit={maxChars.hotel.number || maxChars.number}
                onChange={(e) => handleHotelChange(e)}
              />
              <InputLabel
                label={intl.formatMessage({
                  id: 'hotel.label.address',
                })}
                placeholder='Address ... '
                name='address'
                value={address}
                error={getFormatedMessage('addressError')}
                limit={maxChars.hotel.address || maxChars.address}
                onChange={(e) => handleHotelChange(e)}
              />
              <InputLabel
                label={intl.formatMessage({ id: 'hotel.label.email' })}
                placeholder='exemple@exemple.fr...'
                name='email'
                value={email}
                error={getFormatedMessage('emailError')}
                limit={maxChars.hotel.email || maxChars.email}
                onChange={(e) => handleHotelChange(e)}
              />

              <InputLabel
                label={intl.formatMessage({ id: 'hotel.label.website' })}
                placeholder='exemple.fr'
                name='website'
                value={website}
                error={getFormatedMessage('websiteError')}
                limit={maxChars.hotel.website || maxChars.website}
                onChange={(e) => handleHotelChange(e)}
              />
              <InputLabel
                label={intl.formatMessage({
                  id: 'hotel.label.contactPhone',
                })}
                placeholder='numéro Télephone'
                name='contactPhone'
                value={contactPhone}
                error={getFormatedMessage('contactPhoneError')}
                limit={maxChars.hotel.contactPhone || maxChars.phoneNumber}
                onChange={(e) => handleHotelChange(e)}
              />
              <InputLabel
                label={intl.formatMessage({ id: 'hotel.label.terms' })}
                placeholder='terms'
                name='terms'
                value={terms}
                error={getFormatedMessage('termsError')}
                limit={maxChars.hotel.terms || maxChars.terms}
                onChange={(e) => handleHotelChange(e)}
              />
            </div>
          </div>
          <div className='section-two '>
            <div
              className=' wireframe-section'
              style={{ marginLeft: 25, marginRight: 25, maxWidth: 470 }}
            >
              <RadioGroup
                name='previews'
                value={switchPreview}
                onChange={handlePreviewChange}
                className='radioGroup'
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <FormControlLabel
                  value='android'
                  control={<Radio />}
                  label='Android'
                />
                <FormControlLabel value='iOS' control={<Radio />} label='iOS' />
              </RadioGroup>

              {switchPreview === 'android' && (
                <HotelWireframeAndroid
                  backgroundColor={backgroundColor}
                  strip={image}
                  number={number}
                  terms={terms}
                  QrCode={QRcode}
                  name={name}
                  type={type}
                  email={email}
                  address={address}
                  contactPhone={contactPhone}
                  website={website}
                  barcodeType={barcodeType}
                />
              )}

              {switchPreview === 'iOS' && (
                <HotelWireframeIPhone
                  backgroundColor={backgroundColor}
                  strip={image}
                  number={number}
                  terms={terms}
                  QrCode={QRcode}
                  titleColor={labelColor.hex}
                  textColor={foregroundColor.hex}
                  name={name}
                  type={type}
                  email={email}
                  address={address}
                  contactPhone={contactPhone}
                  website={website}
                  barcodeType={barcodeType}
                />
              )}
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

const Wrapper = styled('div')`
  ${(props) => hotelStyles(props)}
`;

const mapStateToProps = ({ hotel }: RootState) => {
  const {
    address,
    contactPhone,
    email,
    strip,
    image,
    backgroundColor,
    foregroundColor,
    labelColor,
    name,
    number,
    type,
    terms,
    barcodeType,

    website,
    loading,
    locations,
    imageError,
    nameError,
    addressError,
    typeError,
    numberError,
    termsError,
    contactPhoneError,
    emailError,
    websiteError,
    labelColorError,
    backgroundColorError,
    foregroundColorError,
    checkin,
    checkout,
  } = hotel;
  return {
    address,
    contactPhone,
    email,
    strip,
    image,
    backgroundColor,
    foregroundColor,
    labelColor,
    name,
    number,
    barcodeType,

    type,
    terms,
    website,
    loading,
    locations,
    imageError,
    nameError,
    addressError,
    typeError,
    numberError,
    termsError,
    contactPhoneError,
    emailError,
    websiteError,
    labelColorError,
    backgroundColorError,
    foregroundColorError,
  };
};

export const connector = connect(mapStateToProps, {
  hotelPropsChanged,
  addHotel,
  hotelInitialState,
});

export default connector(Hotel);
