import {
  ADD_CUSTOMER_PROPS_CHANGED,
  ADD_CUSTOMER_FORM_CUSTOMER,
  ADD_CUSTOMER_INITIAL_STATE,
} from '../actions/types';
import {
  AddCustomerState,
  AddCustomerActionTypes,
} from '../modules/AddCustomer/addCustomer.types';

const INITIAL_STATE: AddCustomerState = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  barcode: '',
  //errors
  firstNameError: '',
  lastNameError: '',
  emailError: '',
  phoneNumberError: '',
  barcodeError: '',
  emailBarcodeError: '',
  customers: [],
  wallet: null,
  //Loading,
  loading: false,
};

export default (state = INITIAL_STATE, action: AddCustomerActionTypes) => {
  switch (action.type) {
    case ADD_CUSTOMER_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };

    case ADD_CUSTOMER_FORM_CUSTOMER:
      return { ...state, ...action.newProps, loading: false };

    case ADD_CUSTOMER_INITIAL_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};
