import React from 'react';
import { Wrapper } from './ferryWireframe.style';

import { ic_close } from 'react-icons-kit/md/ic_close';
import { ic_call } from 'react-icons-kit/md/ic_call';
import { ic_email } from 'react-icons-kit/md/ic_email';
import { ic_location_on } from 'react-icons-kit/md/ic_location_on';

import { ratio } from 'wcag-color';
import barcode from '../../assets/images/barcode.png';

import cover from '../../assets/images/lg-thumbnail.png';

import { ic_more_vert } from 'react-icons-kit/md/ic_more_vert';
import Icon from 'react-icons-kit';

import moment from 'moment';

import { getDataFromCache } from '../../utils/cache';
import { URL_IMAGE } from '../../apis/config';
import QRCode from 'qrcode.react';
import { convertToMoment } from '../../utils/common';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useIntl } from 'react-intl';

import CODE128 from '../../assets/images/code128.png';
import PDF417 from '../../assets/images/pdf417.png';
import QR from '../../assets/images/qr.png';
import AZTEC from '../../assets/images/aztec.png';
import { switchBarcode } from '../../utils/common';

type WireframeProps = {
  backgroundColor: any;
  strip: any;
  number: any;
  originTerminal: any;
  destinationTerminal: any;
  carrierIataCode: any;
  locations: any;
  layovers: any;
  originIATACode: any;
  destinationIATACode: any;
  originAirportName: any;
  destinationAirportName: any;
  departureDateTime: any;
  originGate: any;
  destinationGate: any;
  details: any;
  QrCode: any;
  logo?: any;
  barcodeType: string;
  animatedBorder?: boolean;
};

const FlightWireframeAndroid: React.FC<WireframeProps> = (props) => {
  const {
    backgroundColor,
    strip,
    originGate,
    destinationGate,
    number,
    destinationTerminal,
    originTerminal,
    carrierIataCode,
    locations,
    layovers,
    originIATACode,
    destinationIATACode,
    originAirportName,
    destinationAirportName,
    departureDateTime,
    details,
    QrCode,
    logo,
    barcodeType,
    animatedBorder,
  } = props;
  const currentWallet = getDataFromCache('wallet');
  const [showDetails, setShowDetails] = React.useState(false);
  const [height, setHeight] = React.useState<string>('800px');
  const intl = useIntl();
  const { barcode, barcodeClass } = switchBarcode(barcodeType, {
    CODE128,
    PDF417,
    QR,
    AZTEC,
  });
  const handleChange = () => {
    setShowDetails(!showDetails);
    height == '100%' ? setHeight('100%') : setHeight('100%');
  };
  return (
    <Wrapper
      backgroundColor={backgroundColor}
      mainContentTextColor={
        ratio('#ffffff', backgroundColor.hex) > 3.0 ? '#ffffff' : '#000000'
      }
      titleColor='#FB4F7B'
    >
      <div className='mainSection'>
        <div className='mainSection-header'>
          <Icon
            size={18}
            icon={ic_close}
            style={
              ratio('#ffffff', backgroundColor.hex) > 3.0
                ? { color: '#ffffff' }
                : { color: '#000000' }
            }
          />
          <Icon
            size={18}
            icon={ic_more_vert}
            style={
              ratio('#ffffff', backgroundColor.hex) > 3.0
                ? { color: '#ffffff' }
                : { color: '#000000' }
            }
          />
        </div>
        <div className='mainSection-header-logo'>
          <img
            src={logo ? logo : `${URL_IMAGE}/${currentWallet.logoPictureUrl}`}
            alt='wallet Logo'
          />
        </div>
        <div className='mainSection-content-enseigne'>
          <div className='mainSection-issuerName'>
            {currentWallet.name || 'best wallet'}
          </div>
          <div className='mainSection-origin-destination'>
            <div className='origin'>
              <h3 className='mainSection-origin-name'>
                {originAirportName || 'Ho Chi Minh City'}
              </h3>
              <h3 className='mainSection-origin-stationCode'>
                {originIATACode || 'LAX'}
              </h3>
            </div>
            <div className='arrow'>
              <svg width='24' height='24' xmlns='http://www.w3.org/2000/svg'>
                <path d='M3.691 10h6.309l-3-7h2l7 7h5c1.322-.007 3 1.002 3 2s-1.69 1.993-3 2h-5l-7 7h-2l3-7h-6.309l-2.292 2h-1.399l1.491-4-1.491-4h1.399l2.292 2' />
              </svg>
            </div>
            <div className='destination'>
              <h3 className='mainSection-destination-name'>
                {destinationAirportName || 'Tokyo Haneda'}
              </h3>
              <h3 className='mainSection-destination-destinationCode'>
                {destinationIATACode || 'SFO'}
              </h3>
            </div>
          </div>
        </div>
        <div className='mainSection-content-dates'>
          <div className='arrivalDate'>
            <h3 className='mainSection-label'>
              {intl.formatMessage({ id: 'flight.label.terminalAndGate' })}
            </h3>
            <h3 className='mainSection-data' style={{ textAlign: 'left' }}>
              {originTerminal ? originTerminal + '/' + originGate + 1 : 'A1'}
            </h3>
          </div>

          <div className='arrivalDate'>
            <h3 className='mainSection-label'>
              {intl.formatMessage({ id: 'flight.label.class' })}
            </h3>
            <h3 className='mainSection-data'>VIP</h3>
          </div>
          <div className='arrivalDate'>
            <h3 className='mainSection-label'>
              {intl.formatMessage({ id: 'transit.label.departureTime' })}
            </h3>
            <h3 className='mainSection-data'>
              {moment(convertToMoment(departureDateTime)).format('hh:mm A')}
            </h3>
          </div>
        </div>
        <span className='divider'></span>
        <div className='mainSection-content-dates'>
          <div className='arrivalDate'>
            <h3 className='mainSection-label'>
              {intl.formatMessage({ id: 'transit.label.passenger' })}
            </h3>
            <h3 className='mainSection-data'>Stephen Hawking</h3>
          </div>
          <div className='arrivalDate'>
            <h3 className='mainSection-label'>
              {intl.formatMessage({ id: 'flight.label.zone' })}
            </h3>
            <h3 className='mainSection-data'>C 5</h3>
          </div>
        </div>
        <div
          className={
            barcodeType === 'NONE'
              ? 'mainSection-content-barCode hidden'
              : 'mainSection-content-barCode'
          }
        >
          {' '}
          <div className='barCode'>
            <img
              className={`${barcodeClass} ${
                animatedBorder && 'animatedBorder'
              }`}
              src={barcode}
              alt='barcode'
            />{' '}
          </div>
          <p className='walletId'>6066e4315abbcc1f9526d392</p>
        </div>
      </div>
      <div className='dataSection'>
        <div className='margins' onClick={handleChange}>
          {!showDetails ? (
            <div className='details_btn'>
              <ExpandMoreIcon color='primary' />
              Show details
            </div>
          ) : (
            <div className='details_btn'>
              <ExpandLessIcon />
              Hide details
            </div>
          )}
        </div>
      </div>
      {showDetails && (
        <div className='dataSection'>
          <div className='sectionLabel'>
            <h3 className='label'>
              {intl.formatMessage({ id: 'flight.label.number' })}
            </h3>
            <h3 className='data'>{number || 'LX 15'}</h3>
          </div>
          <div className='sectionLabel'>
            <h3 className='label'>
              {intl.formatMessage({ id: 'flight.label.confirmationCode' })}
            </h3>
            <h3 className='data'>{'123456789'}</h3>
          </div>
          <div className='sectionLabel'>
            <h3 className='label'>
              {intl.formatMessage({
                id: 'flight.label.estimatedDepartureTime',
              })}
            </h3>
            <h3 className='data'>
              {moment(convertToMoment(departureDateTime)).format(
                'MMM DD yyyy,HH:mm'
              )}
            </h3>
          </div>
          <div className='sectionLabel'>
            <h3 className='label'>
              {intl.formatMessage({ id: 'flight.label.arrivalGate' })}
            </h3>
            <h3 className='data'>{destinationGate || 'C3'}</h3>
          </div>
          <div className='sectionLabel'>
            <h3 className='label'>
              {intl.formatMessage({ id: 'flight.label.destinationTerminale' })}
            </h3>
            <h3 className='data'>{destinationGate || '2'}</h3>
          </div>
          <div className='mainSection-content-banner'>
            <img
              src={
                !strip
                  ? cover
                  : typeof strip === 'string'
                  ? strip
                  : URL.createObjectURL(strip)
              }
              alt='banner'
            />
          </div>
          <div className='sectionLabel'>
            <h3 className='label'>
              {intl.formatMessage({ id: 'flight.label.layover' })} N:1
            </h3>
            <h3 className='data'>{details}</h3>
          </div>
          <div className='sectionLabel'>
            <h3 className='label'>Transit Details</h3>
            <h3 className='data'>{details}</h3>
          </div>
          <div className='sectionIcon'>
            <Icon
              icon={ic_location_on}
              size={18}
              style={{ color: '#999999' }}
            />
            <h3 className='data'>Google Map</h3>
          </div>
          <div className='sectionIcon'>
            <Icon icon={ic_call} size={18} style={{ color: '#999999' }} />
            <h3 className='data'>Info Line</h3>
          </div>
          <div className='sectionIcon'>
            <Icon icon={ic_email} size={18} style={{ color: '#999999' }} />
            <h3 className='data'>Email</h3>
          </div>
        </div>
      )}
    </Wrapper>
  );
};

export default FlightWireframeAndroid;
