import {
  EDIT_FLIGHT_INITIAL_STATE,
  FLIGHT_FORM_PROPS,
  FLIGHT_FORM_PROPS_CHANGED,
  FLIGHT_GET_FLIGHT_PROPS,
  FLIGHT_INITIAL_STATE,
} from '../actions/types';
import { FlightState, FlightActionTypes } from '../modules/Flight/flight.types';
import moment from 'moment';
import { URL_IMAGE } from '../apis/config';

const INITIAL_STATE: FlightState = {
  loading: false,
  labelColor: {
    hex: '#000000',
    rgb: { r: 0, g: 0, b: 0, a: 1 },
  },
  backgroundColor: {
    hex: '#ffffff',
    rgb: { r: 255, g: 255, b: 255, a: 1 },
  },
  foregroundColor: {
    hex: '#000000',
    rgb: { r: 0, g: 0, b: 0, a: 1 },
  },
  barcodeType: 'AZTEC',
  details: '',
  customerServiceNumber: '',
  departureDateTime: '',
  number: '',
  destinationIATACode: '',
  destinationTerminal: '',
  originIATACode: '',
  originTerminal: '',
  originAirportName: '',
  destinationAirportName: '',
  layovers: [],
  locations: {},
  strip: null,
  image: null,
  destinationGate: '',
  originGate: '',
  carrierIataCode: '',
  enableOneTimeUse: false,
  disableIOSPassSharing: false,
  disableMultipleHolders: false,
  enableSecurityAnimation: false,
  enableRotatingBarcode: false,
  disableEmailSharing: false,
  //errors
  stripError: '',
  labelColorError: '',
  backgroundColorError: '',
  foregroundColorError: '',
  numberError: '',

  originIATACodeError: '',
  originGateError: '',
  originTerminalError: '',
  originAirportNameError: '',
  destinationIATACodeError: '',
  destinationGateError: '',
  destinationTerminalError: '',
  destinationAirportNameError: '',

  imageError: '',
  loadingError: '',

  detailsError: '',
  customerServiceNumberError: '',
  departureDateTimeError: '',
  carrierIataCodeError: '',

  layoversError: '',
  locationsError: '',
};

export default (state = INITIAL_STATE, action: FlightActionTypes) => {
  switch (action.type) {
    // case FLIGHT_FORM_PROPS_CHANGED:
    //   return { ...state, [action.prop]: action.value };
    case FLIGHT_FORM_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };
    case FLIGHT_FORM_PROPS:
      return { ...state, ...action.newState, loading: false };
    case FLIGHT_GET_FLIGHT_PROPS:
      return { ...state, ...action.payload, ...action.payload.security };
    case FLIGHT_INITIAL_STATE:
      return { ...INITIAL_STATE };
    case EDIT_FLIGHT_INITIAL_STATE:
      return {
        ...INITIAL_STATE,
        ...action.value,
        destinationIATACode: action.value.destination.airportIataCode,
        destinationTerminal: action.value.destination.terminal,
        destinationGate: action.value.destination.gate,
        destinationAirportName: action.value.destination.airportName,
        originIATACode: action.value.origin.airportIataCode,
        originTerminal: action.value.origin.terminal,
        originAirportName: action.value.origin.airportName,
        originGate: action.value.origin.gate,
        image: URL_IMAGE + '/' + action.value.flighttPictureUrl,
      };
    default:
      return state;
  }
};
