import { css } from 'styled-components';

export const getInputLabelStyles = (props: any) => {
  return css`
    display: flex;
    flex-direction: column;
    margin: 5px 0;
    /* max-width: 280px; */
    width: 100%;
    /* @media (max-width: 850px) {
      max-width: 230px;
      width: 230px;
    } */
    .label {
      font-style: normal;
      font-weight: 600;
      line-height: 21px;
      font-size: 14px;
      color: #828282;
      margin: 5px;
    }
    .input-text-error {
      /* align-self: flex-start; */
      font-size: 14px;
      color: #ed0723;
      margin-bottom: 8px;
      margin: 5px;
    }
  `;
};
