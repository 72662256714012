import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='28'
      height='28'
      fill='none'
      viewBox='0 0 28 28'
    >
      <path
        fill='#C7C7C7'
        fillRule='evenodd'
        d='M15.707 2.132a1.306 1.306 0 00-.457.403l-.174.263-.018 5.111-.017 5.111h4.911c3.226 0 4.996-.024 5.158-.07.307-.084.69-.415.807-.694.12-.286.111-.616-.036-1.37-.28-1.432-.86-2.824-1.709-4.103-.668-1.006-1.973-2.305-2.997-2.983a11.225 11.225 0 00-4.062-1.683c-.817-.16-1.058-.157-1.406.015zM11.49 3.858c-3.19.495-5.977 2.28-7.685 4.92-2.155 3.33-2.387 7.318-.654 11.203.44.986 1.274 2.363 1.737 2.868.994 1.086 3.672 2.43 5.752 2.888 1.892.416 3.987.336 5.816-.222 2.193-.669 4.253-2.142 5.693-4.072.552-.74 1.215-2.006 1.538-2.938.295-.852.553-2.108.574-2.8.016-.5.007-.537-.165-.71l-.18-.18-5.35-.018-5.348-.018-.002-5.333c-.002-4.09-.021-5.357-.082-5.437-.212-.276-.608-.312-1.644-.151z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}

export default Icon;
