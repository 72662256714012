import {
  createFlight,
  editCustomerFlight,
  getFlight,
  updateFlight,
} from '../apis/flightApi';
import {
  FLIGHT_FORM_PROPS,
  FLIGHT_FORM_PROPS_CHANGED,
  AppThunk,
  FLIGHTS_ADD_FLIGHT_TO_LIST,
  FLIGHT_INITIAL_STATE,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
  FLIGHT_GET_FLIGHT_PROPS,
  FLIGHTS_GET_LIST_FLIGHTS,
  EDIT_FLIGHT_INITIAL_STATE,
  CUSTOMER_FLIGHT_FORM_PROPS_CHANGED,
} from './types';
import {
  CustomerFlightPropsChangedAction,
  FlightInitialStateAction,
  FlightPropsChangedAction,
  FlightState,
} from '../modules/Flight/flight.types';

import {
  validateFieldsFlight,
  newFlightObject,
} from '../modules/Flight/flight.utils';
import { URL_IMAGE } from '../apis/config';
import { getFlightsList } from '../apis/flightsApi';
import { FlightsApiResponse } from '../apis/ApiTypes';
import {
  validateCustomerFieldsFlight,
  newCustomerFlightObject,
} from '../modules/EditCustomerFlight/flight.utils';

export const flightPropsChanged = (
  prop: string,
  value: any
): FlightPropsChangedAction => {
  return { type: FLIGHT_FORM_PROPS_CHANGED, prop, value };
};
export const customerFlightPropsChanged = (
  prop: string,
  value: any
): CustomerFlightPropsChangedAction => {
  return { type: CUSTOMER_FLIGHT_FORM_PROPS_CHANGED, prop, value };
};

export const addFlight = (
  walletId: string | null,
  props: FlightState,
  callback: () => void
): AppThunk => (dispatch) => {
  const { newState, valid } = validateFieldsFlight(props);
  dispatch({ type: FLIGHT_FORM_PROPS, newState });
  const newFlight = newFlightObject(newState);

  if (valid) {
    dispatch({
      type: FLIGHT_FORM_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });

    createFlight(walletId, newFlight).then((res: any) => {
      if (res.status == 200) {
        dispatch({
          type: FLIGHTS_ADD_FLIGHT_TO_LIST,
          payload: res.data,
        });
        dispatch({
          type: SNACKBAR_SUCCESS,
          value: 'flight.success.added',
        });
        callback();
      } else {
        dispatch({
          type: SNACKBAR_ERROR,
          value: 'wallet.error.occured',
        });
        dispatch({
          type: FLIGHT_FORM_PROPS_CHANGED,
          prop: 'loading',
          value: false,
        });
      }
    });
  }
};

export const getFlightbyId = (walletId: string, flightId: string): AppThunk => (
  dispatch
) => {
  getFlight(walletId, flightId).then((res: any) => {
    const flight = res.response.data;
    dispatch({
      type: FLIGHT_GET_FLIGHT_PROPS,
      payload: {
        ...flight,
      },
    });
  });
};

export const editFlightConfirm = (
  walletId: string | null,
  flightId: string | null,
  props: FlightState,
  callback: () => void
): AppThunk => (dispatch) => {
  const { newState, valid } = validateFieldsFlight(props);
  dispatch({ type: FLIGHT_FORM_PROPS, newState });
  const newFlight = newFlightObject(newState);

  if (valid) {
    dispatch({
      type: FLIGHT_FORM_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });

    updateFlight(walletId, flightId, newFlight)
      .then((res: any) => {
        if (res.status === 200) {
          dispatch({
            type: SNACKBAR_SUCCESS,
            value: 'flight.success.updated',
          });

          callback();
        } else if (res.status === 400) {
          dispatch({
            type: SNACKBAR_ERROR,
            value: 'wallet.error.occured',
          });
        }
        dispatch({
          type: FLIGHT_FORM_PROPS_CHANGED,
          prop: 'loading',
          value: false,
        });
      })
      .finally(() => {
        getFlightsList(walletId as any).then(
          (res: Array<FlightsApiResponse>) => {
            dispatch({
              type: FLIGHTS_GET_LIST_FLIGHTS,
              payload: res,
            });
          }
        );
      });
  }
};

export const flightInitialState = (): FlightInitialStateAction => {
  return { type: FLIGHT_INITIAL_STATE };
};

export const editFlightInitialState = (flight: any): any => {
  return {
    type: EDIT_FLIGHT_INITIAL_STATE,
    prop: 'payload',
    value: {
      ...flight,
      image: URL_IMAGE + '/' + flight.flightPictureUrl,
    },
  };
};

export const editFlightCustomerConfirm = (
  walletId: string | null,
  customerId: string | null,
  props: FlightState,
  callback: () => void
): AppThunk => (dispatch) => {
  const { newState, valid } = validateCustomerFieldsFlight(props);
  dispatch({ type: FLIGHT_FORM_PROPS, newState });
  newState.customerId = customerId;
  const newFlight = newCustomerFlightObject(newState);

  if (valid) {
    dispatch({
      type: FLIGHT_FORM_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });
    editCustomerFlight(walletId, newFlight)
      .then((res: any) => {
        if (res.status === 200) {
          dispatch({
            type: SNACKBAR_SUCCESS,
            value: 'flight.success.updated',
          });

          callback();
        } else if (res.status === 400) {
          dispatch({
            type: SNACKBAR_ERROR,
            value: 'wallet.error.occured',
          });
        }
        dispatch({
          type: FLIGHT_FORM_PROPS_CHANGED,
          prop: 'loading',
          value: false,
        });
      })
      .finally(() => {
        getFlightsList(walletId as any).then(
          (res: Array<FlightsApiResponse>) => {
            dispatch({
              type: FLIGHTS_GET_LIST_FLIGHTS,
              payload: res,
            });
          }
        );
      });
  }
};
