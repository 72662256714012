const textAreaMaxLength: number = 200;

interface maxChars {
  firstName: number;
  lastName: number;
  phoneNumber: number;
  barcode: number;
  email: number;
  offer: { description: number };
  coupon: Coupon;
  event: {};
  transit: {};
  flight: {};
  hotel: {};
}
export interface Coupon {
  description: number;
  promoCode: number;
  name: number;
}
const object: any = {
  firstName: 35,
  lastName: 35,
  phoneNumber: 30,
  barcode: 45,
  email: 256,
  website: 100,
  subscriptionMessage: 200,
  name: 18,
  description: 200,
  terms: 200,
  address: 60,
  offer: { description: 200 },
  coupon: { promocode: 30, description: 100 },
  event: {
    emailSubscriptionMessage: 200,
    subscriptionMessage: 200,
    date: 35,
    locationName: 35,
  },
  transit: {
    originStationCode: 3,
    destinationStationCode: 3,
    tarif: 10,
    details: 200,
    terms: 200,
    number: 10,
    originName: 10,
    destinationName: 10,
    customerServiceNumber: 15,
  },
  flight: {
    originStationCode: 3,
    destinationStationCode: 3,
    tarif: 10,
    details: 200,
    terms: 200,
    number: 10,
    originName: 10,
    destinationName: 10,
    customerServiceNumber: 15,
    destinationAirportName: 45,
    destinationIATACode: 3,
    destinationTerminal: 13,
    destinationGate: 3,
    carrierIataCode: 3,
    originAirportName: 45,
    originIATACode: 3,
    originTerminal: 13,
    originGate: 3,
  },
  hotel: {},
  cinema: {
    name: 18,
    terms: 200,
    website: 100,
    locationName: 40,
    movieName: 40,
    rating: 20,
    screen: 1,
    address: 60,
  },
  wallet: {
    businessPhone: 30,
    openingHours: 200,
    facebook: 100,
    instagram: 100,
    linkedin: 100,
    contactTitle: 100,
    descriptionTitle: 100,
    notifMessage: 100,
    contactValue: 15,
  },

  settings: {
    successMessage: 200,
    subscriptionMessage: 200,
    newOfferNotificationMessage: 200,
    newCouponNotificationMessage: 200,
    expiredOfferNotificationMessage: 200,
    newUpdateNotificationMessage: 200,
    newOfferEmailMessage: 200,
    expiredOfferEmailMessage: 200,
    newUpdateEmailMessage: 200,
    smsTitle: 11,
    newOfferSMSMessage: 120,
    newAccountSMSMessage: 120,
    newCouponSMSMessage: 120,
    updateNotificationText: 40,
  },
};
export default object;
