import moment, { Moment } from 'moment';
import {
  getBusesList,
  getBusesChildrenList,
  removeCustomerBus,
} from '../apis/busesApi';
import { removeBus } from '../apis/busApi';

import {
  BUSES_GET_LIST_BUSES,
  BUSES_INITIAL_STATE,
  AppThunk,
  BUSES_PROPS_CHANGED,
  BUSES_FILTER_BUSES,
  BUSES_DELETE_BUS_LOADING,
  BUSES_DELETE_BUS,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
  BUSES_DELETE_CUSTOMER_BUS,
  BUSES_GET_SENT_BUSES,
} from './types';
import { TransitsApiResponse } from '../apis/ApiTypes';
import {
  BusesChangedAction,
  BusesInitialStateAction,
} from '../modules/Buses/buses.types';

export const busesGetAllBuses = (walletId: string): AppThunk => (dispatch) => {
  getBusesList(walletId).then((res: Array<TransitsApiResponse>) => {
    dispatch({
      type: BUSES_GET_LIST_BUSES,
      payload: res,
    });
  });
};

export const busesPropsChanged = (
  prop: string,
  value: any
): BusesChangedAction => {
  return { type: BUSES_PROPS_CHANGED, prop, value };
};

export const busesFilterBuses = (
  status: string,
  allTime: boolean,
  startDate: Moment | null,
  endDate: Moment | null,
  buses: Array<TransitsApiResponse>
): AppThunk => (dispatch) => {
  let newBuses: Array<TransitsApiResponse> = Object.assign([], buses);
  const result: Array<TransitsApiResponse> = [];
  let dateStart = `${moment(startDate).format('YYYY-MM-DD')}T00:00:00.000Z`;
  let dateEnd = `${moment(endDate).format('YYYY-MM-DD')}T00:00:00.000Z`;
  // let offersFilterByStatus = newBuses.filter(
  //   (item: OfferApiResponse) => item.status === status
  //   // &&moment(item.startDate).isSameOrAfter(dateStart)
  //   // &&moment(item.endDate).isSameOrBefore(dateEnd);
  // );

  if (startDate && endDate && startDate.isAfter(endDate, 'day')) {
    dispatch({
      type: BUSES_PROPS_CHANGED,
      prop: 'endDateError',
      value: 'offer.error.endDateBeforeStartDate',
    });
  } else {
    // dispatch({
    //   type: BUSES_PROPS_CHANGED,
    //   prop: 'endDateError',
    //   value: '',
    // });

    // if (allTime) {
    //   if (status === 'ALL') {
    //     dispatch({
    //       type: BUSES_FILTER_BUSES,
    //       allTime,
    //       payload: newBuses,
    //     });
    //   } else {
    //     newBuses.forEach((item) => {
    //       if (item.status === status) {
    //         result.push(item);
    //       }
    //     });
    //     dispatch({
    //       type: BUSES_FILTER_BUSES,
    //       allTime,
    //       payload: result,
    //     });
    //   }
    // } else if (startDate && endDate) {
    //   if (status === 'ALL') {
    //     newBuses.forEach((item) => {
    //       if (
    //         moment(item.departureTime).isSameOrAfter(dateStart) &&
    //         moment(item.arrivalTime).isSameOrBefore(dateEnd)
    //       ) {
    //         result.push(item);
    //       }
    //     });
    //     dispatch({
    //       type: BUSES_FILTER_BUSES,
    //       allTime,
    //       payload: result,
    //     });
    //   } else {
    //     newBuses.forEach((item) => {
    //       if (
    //         item.status === status &&
    //         moment(item.departureTime).isSameOrAfter(dateStart) &&
    //         moment(item.arrivalTime).isSameOrBefore(dateEnd)
    //       ) {
    //         result.push(item);
    //       }
    //     });

    //     dispatch({
    //       type: BUSES_FILTER_BUSES,
    //       allTime,
    //       payload: result,
    //     });
    //   }
    // } else {
    //   if (status === 'ALL') {
    //     dispatch({
    //       type: BUSES_FILTER_BUSES,
    //       allTime,
    //       payload: newBuses,
    //     });
    //   } else {
    //     newBuses.forEach((item) => {
    //       if (item.status === status) {
    //         result.push(item);
    //       }
    //     });
    //     dispatch({
    //       type: BUSES_FILTER_BUSES,
    //       allTime,
    //       payload: result,
    //     });
    //   }
    // }
    if (startDate) {
      newBuses.forEach((item) => {
        if (moment(item.departureTime).isSameOrAfter(moment(dateStart))) {
          result.push(item);
        }
      });

      dispatch({
        type: BUSES_FILTER_BUSES,
        allTime,
        payload: result,
      });
    } else if (startDate && endDate) {
      newBuses.forEach((item) => {
        if (
          moment(item.departureTime).isSameOrAfter(moment(dateStart)) &&
          moment(item.arrivalTime).isSameOrBefore(moment(dateEnd))
        ) {
          result.push(item);
        }
      });

      dispatch({
        type: BUSES_FILTER_BUSES,
        allTime,
        payload: result,
      });
    } else {
      dispatch({
        type: BUSES_FILTER_BUSES,
        allTime,
        payload: newBuses,
      });
    }
  }
};

export const deleteBus = (busId: string, walletId: string): AppThunk => (
  dispatch
) => {
  dispatch({
    type: BUSES_DELETE_BUS_LOADING,
    payload: true,
  });
  removeBus(busId, walletId).then((res) => {
    if (res.data.status === 'success') {
      dispatch({
        type: BUSES_DELETE_BUS,
        payload: busId,
      });
      dispatch({
        type: SNACKBAR_SUCCESS,
        value: 'transit.success.delete',
      });
    } else
      dispatch({
        type: SNACKBAR_ERROR,
        value: 'wallet.error.occured',
      });
  });
};
export const busesGetChildrens = (
  walletId: string,
  busId: string,
  page: number | string,
  size: number | string,
  searchFilter: any
): AppThunk => (dispatch) => {
  getBusesChildrenList(walletId, busId, page, size, searchFilter).then(
    (res: any) => {
      if (res.status === 500) {
        dispatch({
          type: SNACKBAR_ERROR,
          value: 'wallet.error.occured',
        });
      } else if (res.status === 401) {
        dispatch({
          type: SNACKBAR_ERROR,
          value: 'Id malformés',
        });
        busesPropsChanged('loading', false);
      } else
        dispatch({
          type: BUSES_GET_SENT_BUSES,
          payload: res.data,
          total: res.total,
        });
    }
  );
};

export const deleteCustomerBus = (
  busId: string,
  customerId: string,
  passId: string,
  walletId: string
): AppThunk => (dispatch) => {
  dispatch({
    type: BUSES_DELETE_BUS_LOADING,
    payload: true,
  });
  removeCustomerBus(busId, customerId, walletId).then((res) => {
    if (res.data.status === 'success') {
      dispatch({
        type: BUSES_DELETE_CUSTOMER_BUS,
        payload: passId,
      });

      dispatch({
        type: SNACKBAR_SUCCESS,
        value: 'transit.error.delete',
      });
    } else {
      dispatch({
        type: SNACKBAR_ERROR,
        value: 'wallet.error.occured',
      });
    }
  });
};

export const busesInitialState = (): BusesInitialStateAction => {
  return { type: BUSES_INITIAL_STATE };
};
