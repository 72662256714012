import {
  CinemaTicketState,
  ColorProps,
  EventDateState,
} from './createCinemaTicket.types';
import { isEmpty } from '../../utils/validations';
import moment from 'moment';

export const validateFieldsCinemaTicket = (state: CinemaTicketState) => {
  let newState: CinemaTicketState = Object.assign({}, state);

  newState.date.openingHour = moment(newState.date.openingHour);
  newState.date.doorOpeningHour = moment(newState.date.doorOpeningHour);
  newState.date.closingHour = moment(newState.date.closingHour);

  if (!newState.strip) {
    newState.imageError = 'event.error.imageRequiredRequired';
  } else {
    newState.imageError = '';
  }

  if (isEmpty(newState.name)) {
    newState.nameError = 'event.error.nameRequired';
  } else {
    newState.nameError = '';
  }

  if (isEmpty(newState.locationName)) {
    newState.locationNameError = 'event.error.locationNameRequired';
  } else {
    newState.locationNameError = '';
  }

  if (isEmpty(newState.address)) {
    newState.addressError = 'event.error.addressRequired';
  } else {
    newState.addressError = '';
  }

  if (!newState.date.openingHour) {
    newState.openingHourError = 'event.error.openingHourErrorRequired';
  } else if (
    newState.date.openingHour &&
    !newState.date.openingHour.isValid()
  ) {
    newState.openingHourError = 'event.error.openingHourErrorInvalid';
  } else {
    newState.openingHourError = '';
  }

  if (!newState.date.closingHour) {
    newState.closingHourError = 'event.error.closingHourErrorRequired';
  } else if (
    newState.date.closingHour &&
    !newState.date.closingHour.isValid()
  ) {
    newState.closingHourError = 'event.error.closingHourErrorInvalid';
  } else {
    newState.closingHourError = '';
  }

  if (!newState.date.doorOpeningHour) {
    newState.doorOpeningHourError = 'event.error.doorOpeningHourRequired';
  } else if (
    newState.date.doorOpeningHour &&
    !newState.date.doorOpeningHour.isValid()
  ) {
    newState.doorOpeningHourError = 'event.error.doorOpeningHourErrorInvalid';
  } else {
    newState.doorOpeningHourError = '';
  }

  if (!newState.price) {
    newState.priceError = 'event.error.priceRequired';
  } else {
    newState.priceError = '';
  }

  if (!newState.terms) {
    newState.termsError = 'event.error.termsRequired';
  } else {
    newState.termsError = '';
  }

  if (isEmpty(newState.contactPhone)) {
    newState.contactPhoneError = 'event.error.contactPhoneRequired';
  } else {
    newState.contactPhoneError = '';
  }

  if (isEmpty(newState.email)) {
    newState.emailError = 'event.error.emailRequired';
  } else {
    newState.emailError = '';
  }

  if (isEmpty(newState.website)) {
    newState.websiteError = 'event.error.websiteError';
  } else {
    newState.websiteError = '';
  }

  let valid = false;
  if (
    newState.locationNameError === '' &&
    newState.nameError === '' &&
    newState.imageError === '' &&
    newState.addressError === '' &&
    newState.openingHourError === '' &&
    newState.closingHourError === '' &&
    newState.doorOpeningHourError === '' &&
    newState.priceError === '' &&
    newState.termsError === '' &&
    newState.contactPhoneError === '' &&
    newState.emailError === '' &&
    newState.websiteError === '' &&
    newState.labelColorError === '' &&
    newState.foregroundColorError === '' &&
    newState.backgroundColorError === ''
  ) {
    valid = true;
  }

  return { newState, valid };
};

export const newCinemaTicketObject = (CinemaTicket: CinemaTicketState) => {
  let formData = new FormData();

  if (CinemaTicket.strip) {
    formData.append('address', CinemaTicket.address);

    formData.append(
      'backgroundColor',
      objectColorJSON(CinemaTicket.backgroundColor)
    );
    formData.append('contactPhone', CinemaTicket.contactPhone);
    formData.append('date', JSON.stringify(CinemaTicket.date));
    formData.append('email', CinemaTicket.email);
    formData.append('strip', CinemaTicket.strip);
    formData.append(
      'foregroundColor',
      objectColorJSON(CinemaTicket.foregroundColor)
    );
    formData.append('labelColor', objectColorJSON(CinemaTicket.labelColor));
    formData.append('locationName', CinemaTicket.locationName);
    formData.append('name', CinemaTicket.name);
    formData.append('price', CinemaTicket.price.toString());
    formData.append('terms', CinemaTicket.terms);
    formData.append('website', CinemaTicket.website);
    formData.append('locations', JSON.stringify(CinemaTicket.locations));
    formData.append('movieName', CinemaTicket.movieName);
    formData.append('rating', CinemaTicket.rating);
    formData.append('screen', CinemaTicket.screen.toString());
    formData.append('barcodeType', CinemaTicket.barcodeType);

    const security = {
      enableOneTimeUse: CinemaTicket.enableOneTimeUse,
      disableIOSPassSharing: CinemaTicket.disableIOSPassSharing,
      disableMultipleHolders: CinemaTicket.disableMultipleHolders,
      enableSecurityAnimation: CinemaTicket.enableSecurityAnimation,
      enableRotatingBarcode: CinemaTicket.enableRotatingBarcode,
      disableEmailSharing: CinemaTicket.disableEmailSharing,
    };

    formData.append('security', JSON.stringify(security));
    return formData;
  }

  return null;
};

export const objectColorJSON = (objectColor: ColorProps) => {
  let newObjectColor = {
    hex: objectColor.hex,
    rgb:
      typeof objectColor.rgb.r === 'number' &&
      typeof objectColor.rgb.g === 'number' &&
      typeof objectColor.rgb.b === 'number'
        ? `rgb(${objectColor.rgb.r},${objectColor.rgb.g},${objectColor.rgb.b})`
        : objectColor.rgb,
  };

  const newObjectColorJSON = JSON.stringify(newObjectColor);
  return newObjectColorJSON;
};

export const objectDateJSON = (objectDate: EventDateState) => {
  let newEventDate = {
    openingHour: objectDate.openingHour,
    closingHour: objectDate.closingHour,
    doorOpeningHour: objectDate.doorOpeningHour,
  };

  const newObjectEventDateJSON = JSON.stringify(newEventDate);
  return newObjectEventDateJSON;
};
