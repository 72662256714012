import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import frLocale from 'date-fns/locale/fr';
import enLocale from 'date-fns/locale/en-US';
import format from 'date-fns/format';

//COMPONENTS

// MODULES
import Login from './modules/Login';
import Statistics from './modules/Statistics';
import Settings from './modules/Settings';
import Profile from './modules/Profile';
import NotFound from './modules/NotFound';
import ResetPassword from './modules/ResetPassword';
import EventInscription from './modules/EventInscription';
//ROUTES
import MainRoute from './routes/MainRoute';
import WalletRouter from './routes/WalletRouter';
import OffersRouter from './routes/OffersRouter';
import CouponsRouter from './routes/CouponsRouter';
import EventsRouter from './routes/EventsRouter';

//Utils
import { PrivateRoute } from './utils/Navigation';
import SnackBar from './modules/SnackBar';
import GuardedRoute from './routes/GuardedRoute';

import ShipsRouter from './routes/ShipsRouter';
import BusesRouter from './routes/BusesRouter';
import TramsRouter from './routes/TramsRouter';
import RailsRouter from './routes/RailsRouter';
import HotelsRouter from './routes/HotelsRouter';
import CinemasTicketsRouter from './routes/CinemasTicketsRouter';
import FlightsRouter from './routes/FlightsRouter';
import { IntlProvider } from 'react-intl';
import enMessages from './translation/messages.en';
import frMessages from './translation/messages.fr';
import deMessages from './translation/messages.de';

import './App.css';

export const theme = {
  primary: '#ff5078',
  secondary: '#4c15f1',
  bluePrimary: '#1A2D35',
  white: '#ffffff',
};

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const localeMap = {
  en: enLocale,
  fr: frLocale,
};

class LocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date: any) {
    return format(date, 'd MMM yyyy', { locale: this.locale });
  }
}

const localeUtilsMap = {
  en: DateFnsUtils,
  fr: LocalizedUtils,
};

type currentLocaleType = 'en' | 'fr';

const App: React.FC = (props) => {
  const [currentLocale, setCurrentLocale] = useState<currentLocaleType>(
    getInitialLocal()
  );

  //localstorage
  function getInitialLocal() {
    const savedLocale = localStorage.getItem('language');
    return (savedLocale as currentLocaleType) || 'fr';
  }

  useEffect(() => {
    setCurrentLocale(
      (localStorage.getItem('language') as currentLocaleType) || 'fr'
    );
  }, [currentLocale]);

  const messages: any = {
    en: enMessages,
    fr: frMessages,
    de: deMessages,
  };

  return (
    <IntlProvider
      locale={currentLocale}
      messages={messages[`${currentLocale}`]}
    >
      <Provider
        store={createStore(reducers, composeEnhancers(applyMiddleware(thunk)))}
      >
        <MuiPickersUtilsProvider
          locale={localeMap[currentLocale]}
          utils={localeUtilsMap[currentLocale]}
        >
          <ThemeProvider theme={theme}>
            <SnackBar />
            <Router>
              <Switch>
                <Route path='/login' component={Login} />
                {/* <PrivateRoute path='/' component={MainRoute} /> */}
                <Redirect exact from='/' to='/statistics' />
                <PrivateRoute path='/statistics' component={Statistics} />
                <Route
                  path='/reset_password/:token'
                  component={ResetPassword}
                  exact
                />
                <Route
                  path='/inscription/event/:token'
                  component={EventInscription}
                  exact
                />
                <PrivateRoute
                  path='/wallet/:walletId'
                  component={WalletRouter}
                />
                <GuardedRoute
                  path='/offers'
                  component={OffersRouter}
                  type={'offer'}
                />
                <GuardedRoute
                  path='/coupons'
                  component={CouponsRouter}
                  type={'coupon'}
                />
                <GuardedRoute
                  path='/event'
                  component={EventsRouter}
                  type={'eventTicket'}
                />
                <PrivateRoute path='/Settings' component={Settings} />
                <PrivateRoute path='/profile' component={Profile} />
                <GuardedRoute
                  type={'transit'}
                  path='/ships'
                  component={ShipsRouter}
                />
                <GuardedRoute
                  type={'transit'}
                  path='/buses'
                  component={BusesRouter}
                />
                <GuardedRoute
                  type={'transit'}
                  path='/trams'
                  component={TramsRouter}
                />
                <GuardedRoute
                  type={'transit'}
                  path='/rails'
                  component={RailsRouter}
                />
                <GuardedRoute
                  path='/cinemaTickets'
                  component={CinemasTicketsRouter}
                  type={'cinemaPass'}
                />

                <GuardedRoute
                  path='/hotels'
                  component={HotelsRouter}
                  type={'hotelPass'}
                />
                <GuardedRoute
                  type={'boardingPass'}
                  path='/flights'
                  component={FlightsRouter}
                />

                <Route path='/*' component={NotFound} />
              </Switch>
            </Router>
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </Provider>
    </IntlProvider>
  );
};

export default App;
