import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
    >
      <g>
        <path fill='#006' d='M0 0H20V20H0z'></path>
        <path
          fill='#FFF'
          d='M0 0v2.234L17.766 20H20v-2.234L2.234 0zm20 0v2.234L2.234 20H0v-2.234L17.766 0zm0 0'
        ></path>
        <path
          fill='#FFF'
          d='M8.332 0v20h3.336V0zM0 6.668v6.664h20V6.668zm0 0'
        ></path>
        <path
          fill='#C00'
          d='M0 8v4h20V8zm9-8v20h2V0zm0 0M0 20l6.668-6.668h1.488L1.492 20zM0 0l6.668 6.668H5.176L0 1.492zm11.844 6.668L18.508 0H20l-6.668 6.668zM20 20l-6.668-6.668h1.492L20 18.508zm0 0'
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
