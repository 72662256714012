import { css } from 'styled-components';

export const hotelStyles = (props: any) => {
  return css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0;
    overflow: hidden;
    .div-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 20px;

      /* width: 95%; */
    }
    .div-content {
      display: flex;
    }
    .MuiOutlinedInput-adornedEnd {
      background: #f8f6fe;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #404040;

      border-radius: 15px;
    }
    .MuiIconButton-root {
      color: #ff5078;
    }
    .MuiIconButton-root.Mui-disabled {
      color: rgba(0, 0, 0, 0.38) !important;
    }
    .div-date-cont {
      display: flex;
    }
    .div-date {
      margin-right: 20px;
    }
    .title {
      display: flex;
      align-self: flex-start;

      font-size: 18px;
      font-family: 500;
      font-weight: bold;
      margin: 16px;
    }
    .btn_parcourir {
      box-sizing: border-box;
      font-size: 14px;
      //margin: 15px 0 10px 0;
      padding: 9px 30px 9px 30px;
      border-radius: 50px;
      width: fit-content;
      text-align: center;
      color: #fff;
      background: #ff5078;
      margin-top: -43px;
      margin-left: 18rem;
      //margin-top: 10px;
    }
    .select {
      width: 100%;
      margin-bottom: 20px;
      p {
        font-style: normal;
        font-weight: 600;
        line-height: 21px;
        font-size: 14px;
        color: #828282;
        margin: 5px;
      }

      .MuiInput-underline::before {
        border: none;
      }
      .MuiInput-underline::after {
        border: none;
      }
      .MuiInputBase-root {
        width: 100%;
        background-color: #f8f6fe;
        color: #404040;
        border: none;
        border-radius: 8px;
        padding: 7px 15px;
        .MuiSelect-icon {
          right: 10px;
        }
      }
    }
    .section {
      display: flex;
      gap: 60px;
      padding-left: 10px;
    }
    .section-one {
      display: flex;
      flex-direction: column;
    }
    .section-two {
      display: flex;
      flex-direction: column;
    }
    .divider {
      height: 28px;
      width: 2px;
      margin: 0px;
      /* margin-right: 15px; */
      background: #404040;
    }
  `;
};
