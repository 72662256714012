import { ColorProps, TramState } from './tram.types';
import { isEmpty } from '../../utils/validations';
import moment from 'moment';
import { convertMomentToTime } from '../../utils/common';

export const validateFieldsTram = (state: TramState) => {
  let newState: TramState = Object.assign({}, state);

  if (!newState.number) {
    newState.numberError = 'ship.error.numberRequired';
  } else if (newState.number && !newState.number) {
    newState.numberError = 'ship.error.numberInvalid';
  } else {
    newState.numberError = '';
  }

  if (!newState.labelColor) {
    newState.labelColorError = 'ship.error.labelColorRequired';
  } else {
    newState.labelColorError = '';
  }

  if (!newState.backgroundColor) {
    newState.backgroundColorError = 'ship.error.backgroundColorRequired';
  } else {
    newState.backgroundColorError = '';
  }

  if (!newState.foregroundColor) {
    newState.foregroundColorError = 'ship.error.foregroundColorRequired';
  } else {
    newState.foregroundColorError = '';
  }

  if (!newState.originName) {
    newState.originNameError = 'ship.error.originNameRequired';
  } else if (newState.originName && !newState.originName) {
    newState.originNameError = 'ship.error.originNameInvalid';
  } else {
    newState.originNameError = '';
  }
  if (!newState.destinationName) {
    newState.destinationNameError = 'ship.error.destinationNameRequired';
  } else if (newState.destinationName && !newState.destinationName) {
    newState.destinationNameError = 'ship.error.destinationNameInvalid';
  } else {
    newState.destinationNameError = '';
  }
  if (!newState.originStationCode) {
    newState.originStationCodeError = 'ship.error.originStationCodeRequired';
  } else if (newState.originStationCode && !newState.originStationCode) {
    newState.originStationCodeError = 'ship.error.originStationCodeInvalid';
  } else {
    newState.originStationCodeError = '';
  }
  if (!newState.destinationStationCode) {
    newState.destinationStationCodeError =
      'ship.error.destinationStationCodeRequired';
  } else if (
    newState.destinationStationCode &&
    !newState.destinationStationCode
  ) {
    newState.destinationStationCodeError =
      'ship.error.destinationStationCodeInvalid';
  } else {
    newState.destinationStationCodeError = '';
  }

  if (!newState.image) {
    newState.imageError = 'ship.error.imageRequired';
  } else {
    newState.imageError = '';
  }

  if (isEmpty(newState.details)) {
    newState.detailsError = 'ship.error.detailsRequired';
  } else {
    newState.detailsError = '';
  }
  if (isEmpty(newState.tarif)) {
    newState.tarifError = 'ship.error.tarifRequired';
  } else {
    newState.tarifError = '';
  }
  if (isEmpty(newState.customerServiceNumber)) {
    newState.customerServiceNumberError =
      'ship.error.customerServiceNumberRequired';
  } else {
    newState.customerServiceNumberError = '';
  }
  if (isEmpty(newState.terms)) {
    newState.termsError = 'ship.error.termsRequired';
  } else {
    newState.termsError = '';
  }

  let valid = false;
  if (
    newState.labelColorError === '' &&
    newState.backgroundColorError === '' &&
    newState.foregroundColorError === '' &&
    newState.imageError === '' &&
    newState.arrivalTimeError === '' &&
    newState.departureTimeError === '' &&
    newState.originStationCodeError === '' &&
    newState.originNameError === '' &&
    newState.destinationNameError === '' &&
    newState.destinationStationCodeError === '' &&
    newState.numberError === '' &&
    newState.tarifError === '' &&
    newState.detailsError === '' &&
    newState.termsError === '' &&
    newState.stripError === '' &&
    newState.customerServiceNumberError === ''
  ) {
    valid = true;
  }

  return { newState, valid };
};

// export const newTramObject = (tram: any) => {
//   let formData = new FormData();

//   if (tram) {
//     formData.append(
//       'departureTime',
//       moment(tram.departureTime).format('YYYY-MM-DD')
//     );
//     formData.append(
//       'arrivalTime',
//       moment(tram.arrivalTime).format('YYYY-MM-DD')
//     );
//     formData.append('originName', tram.originName);
//     formData.append('destinationName', tram.destinationName);
//     return formData;
//   }

//   return null;
// };

export const newTramObject = (tram: TramState) => {
  let formData = new FormData();

  if (tram.image) {
    formData.append('departureTime', convertMomentToTime(tram.departureTime));
    formData.append('arrivalTime', convertMomentToTime(tram.arrivalTime));
    formData.append('labelColor', objectColorJSON(tram.labelColor));
    formData.append('backgroundColor', objectColorJSON(tram.backgroundColor));
    formData.append('foregroundColor', objectColorJSON(tram.foregroundColor));
    formData.append('strip', tram.image);
    formData.append('originName', tram.originName);
    formData.append('destinationName', tram.destinationName);
    formData.append('originStationCode', tram.originStationCode);
    formData.append('destinationStationCode', tram.destinationStationCode);
    formData.append('number', tram.number);
    formData.append('details', tram.details);
    formData.append('terms', tram.terms);
    formData.append('tarif', tram.tarif);
    formData.append('barcodeType', tram.barcodeType);
    const security = {
      enableOneTimeUse: tram.enableOneTimeUse,
      disableIOSPassSharing: tram.disableIOSPassSharing,
      disableMultipleHolders: tram.disableMultipleHolders,
      enableSecurityAnimation: tram.enableSecurityAnimation,
      enableRotatingBarcode: tram.enableRotatingBarcode,
      disableEmailSharing: tram.disableEmailSharing,
    };

    formData.append('security', JSON.stringify(security));
    formData.append('customerServiceNumber', tram.customerServiceNumber);
    formData.append('type', 'tram');
    formData.append(
      'locations',
      JSON.stringify([
        {
          longitude: 0,
          latitude: 0,
        },
      ])
    );
    return formData;
  }

  return null;
};

export const objectColorJSON = (objectColor: ColorProps) => {
  let newObjectColor = {
    hex: objectColor.hex,
    rgb:
      typeof objectColor.rgb.r === 'number' &&
      typeof objectColor.rgb.g === 'number' &&
      typeof objectColor.rgb.b === 'number'
        ? `rgb(${objectColor.rgb.r},${objectColor.rgb.g},${objectColor.rgb.b})`
        : objectColor.rgb,
  };

  const newObjectColorJSON = JSON.stringify(newObjectColor);
  return newObjectColorJSON;
};

export const validateCustomerFieldsTram = (state: any) => {
  let newState: any = Object.assign({}, state);

  if (!newState.departureDate) {
    newState.departureDateError = 'ferry.error.departureDateRequired';
  } else {
    newState.departureDateError = '';
  }

  if (!newState.arrivalDate) {
    newState.arrivalDateError = 'ferry.error.arrivalDateRequired';
  } else {
    newState.arrivalDateError = '';
  }
  if (!newState.passengerType) {
    newState.passengerTypeError = 'ferry.error.passengerTypeRequired';
  } else {
    newState.passengerTypeError = '';
  }
  if (!newState.tripType) {
    newState.tripTypeError = 'ferry.error.tripTypeRequired';
  } else {
    newState.tripTypeError = '';
  }

  let valid = false;
  if (
    newState.departureDateError === '' &&
    newState.arrivalDateError === '' &&
    newState.passengerTypeError === '' &&
    newState.tripTypeError === ''
  ) {
    valid = true;
  }
  return { newState, valid };
};

// export const newCustomerTramObject = (tram: any) => {
//   let formData = new FormData();

//   let createdAt = `${moment(tram.createdAt).format(
//     'YYYY-MM-DD'
//   )}T22:59:59.000+01:00`;

//   formData.append('createdAt', createdAt);
//   formData.append('passengerType', tram.passengerType);
//   formData.append('tripType', tram.tripType);
//   formData.append('phoneNumber', tram.phoneNumber);
//   formData.append('customerId', tram.customerId);

//   return {
//     formData,
//     objectData: {
//       createdAt,
//       passengerType: tram.passengerType,
//       tripType: tram.tripType,
//       phoneNumber: tram.phoneNumber,
//       customerId: tram.customerId,
//     },
//   };
// };
export const newCustomerTramObject = (props: any) => {
  const tram = {
    departureDate: props.departureDate,
    arrivalDate: props.arrivalDate,
    passengerType: props.passengerType,
    tripType: props.tripType,
    customerId: props.customerId,
  };
  return tram;
};
