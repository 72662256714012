import {
  CUSTOMER_FLIGHT_FORM_PROPS_CHANGED,
  EDIT_FLIGHT_INITIAL_STATE,
  FLIGHT_FORM_PROPS,
  FLIGHT_FORM_PROPS_CHANGED,
  FLIGHT_GET_FLIGHT_PROPS,
  FLIGHT_INITIAL_STATE,
} from '../actions/types';
import { FlightState, FlightActionTypes } from '../modules/Flight/flight.types';

const INITIAL_STATE: Partial<FlightState> = {
  loading: false,
  seatNumber: 0,
  boardingGroup: '',
  seatClass: '',
  confirmationCode: 0,
  flightTemplateId: '',
  customerId: '',
  flightId: '',
  //errors
  seatNumberError: '',
  boardingGroupError: '',
  seatClassError: '',
  confirmationCodeError: '',
};

export default (state = INITIAL_STATE, action: FlightActionTypes) => {
  switch (action.type) {
    case CUSTOMER_FLIGHT_FORM_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };

    default:
      return state;
  }
};
