import { isEmpty, isEqual } from '../../utils/validations';
import { ResetPasswordState } from './resetPassword.types';

export const validateFieldsResetPassword = (state: ResetPasswordState) => {
  let newState: ResetPasswordState = Object.assign({}, state);

  if (isEmpty(newState.password)) {
    newState.passwordError = 'resetPassword.error.passwordRequired';
  } else if (newState.password.length < 4) {
    newState.passwordError = 'resetPassword.error.passwordRequiredLength';
  } else {
    newState.passwordError = '';
  }

  if (isEmpty(newState.passwordConfirm)) {
    newState.passwordConfirmError =
      'resetPassword.error.passwordConfirmRequired';
  } else if (!isEqual(newState.password, newState.passwordConfirm)) {
    newState.passwordConfirmError =
      'resetPassword.error.passwordConfirmNotMatch';
  } else {
    newState.passwordConfirmError = '';
  }

  let valid = false;
  if (newState.passwordError === '' && newState.passwordConfirmError === '') {
    valid = true;
  }
  return { newState, valid };
};
