import 'date-fns';
import React from 'react';

import { useIntl } from 'react-intl';
import styled from 'styled-components';
//Material
import FormControl from '@material-ui/core/FormControl';
import emptyState from '../../assets/images/emptyState.svg';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import { DateTimePicker } from '@material-ui/pickers';
import Text from '../../commons/Text';
import Button from '../../commons/Button';
import Input from '../../commons/Inputs/Input';
import { Divider } from '@material-ui/core';
import Calendar from '../../icons/Calendar';
import { StyledCheckbox } from '../../commons/CheckBox/index';
import ShipModal from '../../components/Modal';
import { RailsStyles, RailsStylesProps } from './rails.styles';
import EmptyData from '../../components/EmptyData';
import { TransitsApiResponse } from '../../apis/ApiTypes';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import { getDataFromCache } from '../../utils/cache';
import {
  railsGetAllRails,
  railsInitialState,
  railsPropsChanged,
  railsFilterRails,
  editRailConfirm,
  deleteRail,
  getRailbyId,
  editRailInitialState,
} from '../../actions';
import CreateRail from '../Rail';
import SimpleDialog from '../../components/Dialog';
import { useHistory } from 'react-router-dom';
import EditRail from '../EditRail';
import RailCard from '../../components/Cards/RailCard';
import { IconButton, TextField, InputAdornment } from '@material-ui/core';

const Ships: React.FC<any> = (props) => {
  const intl = useIntl();
  const [open, setOpen] = React.useState<boolean>(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectOpener, setSelectOpener] = React.useState<boolean>(false);
  const [railId, setRailId] = React.useState('');
  const [railIdConfirmed, setRailIdConfirmed] = React.useState('');
  const [EditDialogOpen, setEditDialogOpen] = React.useState(false);

  const {
    railsList,
    railsFilterList,
    status,
    search,
    startDate,
    endDate,
    allTime,
    loading,
    loadingSend,
  } = props;
  const objectUser: any = getDataFromCache('user');
  const history = useHistory();
  React.useEffect(() => {
    props.railsGetAllRails(objectUser.wallet);
  }, []);

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    props.railsPropsChanged('status', event.target.value);
  };
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.railsPropsChanged(event.target.name, event.target.checked);
  };
  const handleDateChange = (prop: string) => (date: Date | null) => {
    props.railsPropsChanged(prop, date);
  };

  const HandleClickSearch = () => {
    props.railsFilterRails(
      status,
      allTime,
      startDate,
      endDate,
      // railsFilterList
      status && search
        ? (railsList?.length === railsFilterList?.length
            ? railsList
            : railsFilterList
          )?.filter((item: any) =>
            item?.[status]?.toLowerCase()?.includes(search?.toLowerCase())
          )
        : railsFilterList
    );
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setRailIdConfirmed('');
  };
  const handleClickSend = () => {
    props.railsSendRail(objectUser.wallet, railId);
    setRailIdConfirmed(railId);
    setOpenDialog(false);
  };
  const handleClickDeleterail = (railId: string) => {
    props.deleteRail(railId, objectUser.wallet);
  };
  const RedirectToCustomers = (url: string) => {
    history.push(url);
  };

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'search') {
      if (event.target.value.length > 60) return;
      props.railsPropsChanged(event.target.name, event.target.value);
    }
    props.railsPropsChanged(event.target.name, event.target.value);
  };

  const handleNameStatus = (value: string) => {
    switch (value) {
      case 'originName':
        return intl.formatMessage({ id: 'ships.select.originName' });
      case 'destinationName':
        return intl.formatMessage({ id: 'ships.select.destinationName' });
      // case 'departureTime':
      //   return intl.formatMessage({ id: 'ships.select.departureTime' });
      // case 'arrivalTime':
      //   return intl.formatMessage({ id: 'ships.select.arrivalTime' });
      default:
        return 'Tous';
    }
  };

  return (
    <Wrapper>
      <div className='div-search'>
        <FormControl className='filter-container' fullWidth>
          <Input value={handleNameStatus(status)} disabled margin='0' />
          <div
            className='filter-button'
            onClick={() => setSelectOpener(!selectOpener)}
          >
            <h3>
              {intl.formatMessage({
                id: 'wallet.label.filter',
              })}
            </h3>
            <ExpandMoreRoundedIcon
              style={{ color: 'white' }}
              className={selectOpener ? 'filter-opened' : ''}
            />
          </div>
          <Select
            className='select-input '
            value={status}
            onChange={handleSelectChange}
            placeholder={intl.formatMessage({
              id: 'wallet.label.filter',
            })}
            id='filter'
            inputProps={{ className: 'input' }}
            open={selectOpener}
            onClose={() => setSelectOpener(false)}
          >
            <MenuItem
              value='originName'
              onMouseEnter={(e) =>
                ((e.target as HTMLInputElement).style.color = '#ff5078')
              }
              onMouseLeave={(e) =>
                ((e.target as HTMLInputElement).style.color = '#000000')
              }
            >
              <span>
                {intl.formatMessage({ id: 'ships.select.originName' })}
              </span>
            </MenuItem>
            <MenuItem
              value='destinationName'
              onMouseEnter={(e) =>
                ((e.target as HTMLInputElement).style.color = '#ff5078')
              }
              onMouseLeave={(e) =>
                ((e.target as HTMLInputElement).style.color = '#000000')
              }
            >
              <span>
                {intl.formatMessage({ id: 'ships.select.destinationName' })}
              </span>
            </MenuItem>
            <MenuItem
              value=''
              onMouseEnter={(e) =>
                ((e.target as HTMLInputElement).style.color = '#ff5078')
              }
              onMouseLeave={(e) =>
                ((e.target as HTMLInputElement).style.color = '#000000')
              }
            >
              <span>Tous</span>
            </MenuItem>
          </Select>
        </FormControl>

        <Input
          value={search}
          background='#fff'
          type='text'
          name='search'
          placeholder={intl.formatMessage({
            id: 'wallet.button.rechercher',
          })}
          className='input-search'
          onChange={handleEventChange}
          margin='0'
          disabled={!status}
        />

        {/* <DateTimePicker
          id='start-date'
          label={intl.formatMessage({ id: 'ships.label.startDate' })}
          format='E MMM dd yyyy HH:mm'
          value={startDate}
          onChange={handleDateChange('startDate')}
          inputVariant='outlined'
          maxDate={new Date()}
          disabled={allTime}
          clearable
          TextFieldComponent={({ InputProps, ...rest }) => (
            <TextField
              {...rest}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <Divider
                      className='divider'
                      orientation='vertical'
                      variant='inset'
                      light
                    />
                    <IconButton
                      onClick={
                        (InputProps as any)?.endAdornment?.props?.children
                          ?.props?.onClick
                      }
                    >
                      <Calendar />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <DateTimePicker
          id='end-date'
          label={intl.formatMessage({ id: 'ships.label.endDate' })}
          format='E MMM dd yyyy HH:mm'
          inputVariant='outlined'
          value={endDate}
          onChange={handleDateChange('endDate')}
          maxDate={new Date()}
          disabled={!startDate || allTime}
          clearable
          TextFieldComponent={({ InputProps, ...rest }) => (
            <TextField
              {...rest}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <Divider
                      className='divider'
                      orientation='vertical'
                      variant='inset'
                      light
                    />
                    <IconButton
                      onClick={
                        (InputProps as any)?.endAdornment?.props?.children
                          ?.props?.onClick
                      }
                    >
                      <Calendar />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        /> */}

        {/* <FormControlLabel
          control={
            <StyledCheckbox
              checked={allTime}
              onChange={handleCheckboxChange}
              name='allTime'
            />
          }
          label={intl.formatMessage({ id: 'ships.label.allTime' })}
        /> */}

        <Button
          onClick={HandleClickSearch}
          width={120}
          background='#FF5078'
          className='button-search'
        >
          {intl.formatMessage({ id: 'wallet.button.rechercher' })}
        </Button>
      </div>

      <div className='div-header'>
        <Text color='#404040' fontSize={24} lineHeight={36} fontWeight={600}>
          {intl.formatMessage({ id: 'rails.label.title' })}
        </Text>
        <Button
          onClick={() => {
            setOpen(!open);
            //props.railsInitialState();
          }}
        >
          {intl.formatMessage({ id: 'ships.button.create' })}
        </Button>
      </div>
      <ShipModal open={open} onClose={() => setOpen(!open)}>
        <CreateRail
          walletId={objectUser.wallet}
          onSaveChanged={() => setOpen(!open)}
        ></CreateRail>
      </ShipModal>
      <ShipModal open={EditDialogOpen} onClose={() => setEditDialogOpen(false)}>
        <EditRail
          walletId={objectUser.wallet}
          railId={railId}
          onCancel={() => setEditDialogOpen(false)}
          onSaveChanged={() => setEditDialogOpen(!EditDialogOpen)}
        ></EditRail>
      </ShipModal>
      <div className='div-list'>
        <SimpleDialog
          title={intl.formatMessage({ id: 'coupons.dialog.title' })}
          text={intl.formatMessage({ id: 'coupons.dialog.text' })}
          handleAgree={handleClickSend}
          open={openDialog}
          handleClose={handleCloseDialog}
        ></SimpleDialog>
        <EmptyData
          data={railsList}
          loading={loading}
          message={intl.formatMessage({ id: 'rails.label.emptyList' })}
          image={emptyState}
        >
          {railsList.map((rail: TransitsApiResponse) => (
            <RailCard
              loading={loadingSend}
              key={rail._id}
              onClickDelete={handleClickDeleterail}
              rail={rail}
              railId={railIdConfirmed}
              onClickRedirect={() => history.push('/rails/' + rail._id)}
              onClickSend={() => {
                setOpenDialog(true);
                setRailId(rail._id);
              }}
              onClickEdit={(id) => {
                props.editRailInitialState(rail);
                setEditDialogOpen(true);
                setRailId(rail._id);
              }}
            ></RailCard>
          ))}
        </EmptyData>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled('div')<RailsStylesProps>`
  ${(props) => RailsStyles(props)}
`;
const mapStateToProps = ({ rails }: RootState) => {
  const {
    railsList,
    railsFilterList,
    startDate,
    endDate,
    allTime,
    loading,
    status,
    search,
  } = rails;

  return {
    railsList,
    railsFilterList,
    startDate,
    endDate,
    allTime,
    loading,
    status,
    search,
  };
};
export const connector = connect(mapStateToProps, {
  railsGetAllRails,
  railsInitialState,
  railsPropsChanged,
  railsFilterRails,
  editRailConfirm,
  editRailInitialState,
  getRailbyId,
  deleteRail,
});
export default connector(Ships);
