import { ColorProps, FlightState } from './flight.types';
import { isEmpty } from '../../utils/validations';
import moment from 'moment';

export const validateFieldsFlight = (state: FlightState) => {
  let newState: FlightState = Object.assign({}, state);

  if (!newState.number) {
    newState.numberError = 'flight.error.numberRequired';
  } else if (newState.number && !newState.number) {
    newState.numberError = 'flight.error.numberInvalid';
  } else {
    newState.numberError = '';
  }

  if (!newState.originGate) {
    newState.originGateError = 'flight.error.gateRequired';
  } else {
    newState.originGateError = '';
  }
  if (!newState.originTerminal) {
    newState.originTerminalError = 'flight.error.terminalRequired';
  } else {
    newState.originTerminalError = '';
  }
  if (!newState.originIATACode) {
    newState.originIATACodeError = 'flight.error.airportIATAcodeRequired';
  } else {
    newState.originIATACodeError = '';
  }
  if (!newState.originAirportName) {
    newState.originAirportNameError = 'flight.error.airportNameRequired';
  } else {
    newState.originAirportNameError = '';
  }
  if (!newState.destinationAirportName) {
    newState.destinationAirportNameError = 'flight.error.airportNameRequired';
  } else {
    newState.destinationAirportNameError = '';
  }
  if (!newState.destinationIATACode) {
    newState.destinationIATACodeError = 'flight.error.airportIATAcodeRequired';
  } else {
    newState.destinationIATACodeError = '';
  }
  if (!newState.destinationGate) {
    newState.destinationGateError = 'flight.error.gateRequired';
  } else {
    newState.destinationGateError = '';
  }
  if (!newState.carrierIataCode) {
    newState.carrierIataCodeError = 'flight.error.carrierIataCodeRequired';
  } else {
    newState.carrierIataCodeError = '';
  }

  if (!newState.labelColor) {
    newState.labelColorError = 'flight.error.labelColorRequired';
  } else {
    newState.labelColorError = '';
  }

  if (!newState.backgroundColor) {
    newState.backgroundColorError = 'flight.error.backgroundColorRequired';
  } else {
    newState.backgroundColorError = '';
  }

  if (!newState.foregroundColor) {
    newState.foregroundColorError = 'flight.error.foregroundColorRequired';
  } else {
    newState.foregroundColorError = '';
  }

  if (!newState.image) {
    newState.imageError = 'flight.error.imageRequired';
  } else {
    newState.imageError = '';
  }

  if (isEmpty(newState.details)) {
    newState.detailsError = 'flight.error.detailsRequired';
  } else {
    newState.detailsError = '';
  }

  if (isEmpty(newState.customerServiceNumber)) {
    newState.customerServiceNumberError =
      'flight.error.customerServiceNumberRequired';
  } else {
    newState.customerServiceNumberError = '';
  }

  let valid = false;
  if (
    newState.labelColorError === '' &&
    newState.backgroundColorError === '' &&
    newState.foregroundColorError === '' &&
    newState.imageError === '' &&
    newState.carrierIataCodeError === '' &&
    newState.originIATACodeError === '' &&
    newState.originGateError === '' &&
    newState.originTerminalError === '' &&
    newState.originAirportNameError === '' &&
    newState.destinationIATACodeError === '' &&
    newState.destinationGateError === '' &&
    newState.destinationTerminalError === '' &&
    newState.destinationAirportNameError === '' &&
    newState.numberError === '' &&
    newState.detailsError === '' &&
    newState.stripError === '' &&
    newState.customerServiceNumberError === ''
  ) {
    valid = true;
  }

  return { newState, valid };
};

export const newFlightObject = (flight: FlightState) => {
  let formData = new FormData();

  const origin = {
    airportIataCode: flight.originIATACode,
    gate: flight.originGate,
    terminal: flight.originTerminal,
    airportName: flight.originAirportName,
  };
  const destination = {
    airportIataCode: flight.destinationIATACode,
    gate: flight.destinationGate,
    terminal: flight.destinationTerminal,
    airportName: flight.destinationAirportName,
  };

  if (flight.image) {
    formData.append('departureDateTime', flight.departureDateTime);
    formData.append('labelColor', objectColorJSON(flight.labelColor));
    formData.append('backgroundColor', objectColorJSON(flight.backgroundColor));
    formData.append('foregroundColor', objectColorJSON(flight.foregroundColor));
    formData.append('strip', flight.image);
    formData.append('origin', JSON.stringify(origin));
    formData.append('destination', JSON.stringify(destination));
    formData.append('number', flight.number);
    formData.append('details', flight.details);
    formData.append('barcodeType', flight.barcodeType);
    const security = {
      enableOneTimeUse: flight.enableOneTimeUse,
      disableIOSPassSharing: flight.disableIOSPassSharing,
      disableMultipleHolders: flight.disableMultipleHolders,
      enableSecurityAnimation: flight.enableSecurityAnimation,
      enableRotatingBarcode: flight.enableRotatingBarcode,
      disableEmailSharing: flight.disableEmailSharing,
    };

    formData.append('security', JSON.stringify(security));
    formData.append('carrierIataCode', flight.carrierIataCode);
    formData.append('layovers', JSON.stringify(flight.layovers));
    formData.append('customerServiceNumber', flight.customerServiceNumber);
    formData.append(
      'locations',
      JSON.stringify([
        {
          longitude: 0,
          latitude: 0,
        },
      ])
    );
    return formData;
  }

  return null;
};

export const objectColorJSON = (objectColor: ColorProps) => {
  let newObjectColor = {
    hex: objectColor.hex,
    rgb:
      typeof objectColor.rgb.r === 'number' &&
      typeof objectColor.rgb.g === 'number' &&
      typeof objectColor.rgb.b === 'number'
        ? `rgb(${objectColor.rgb.r},${objectColor.rgb.g},${objectColor.rgb.b})`
        : objectColor.rgb,
  };

  const newObjectColorJSON = JSON.stringify(newObjectColor);
  return newObjectColorJSON;
};
