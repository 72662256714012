import 'date-fns';
import React from 'react';

import { useIntl } from 'react-intl';
import styled from 'styled-components';
//Material
import FormControl from '@material-ui/core/FormControl';
import emptyState from '../../assets/images/emptyState.svg';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import { DateTimePicker } from '@material-ui/pickers';
import Text from '../../commons/Text';
import Button from '../../commons/Button';
import Input from '../../commons/Inputs/Input';
import { Divider } from '@material-ui/core';
import Calendar from '../../icons/Calendar';
import { StyledCheckbox } from '../../commons/CheckBox/index';
import ShipModal from '../../components/Modal';
import { ShipsStyles, ShipsStylesProps } from './ships.styles';
import EmptyData from '../../components/EmptyData';
import ShipCard from '../../components/Cards/ShipCard';
import { TransitsApiResponse } from '../../apis/ApiTypes';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import { getDataFromCache } from '../../utils/cache';
import {
  ferriesGetAllFerries,
  ferriesInitialState,
  ferriesPropsChanged,
  ferriesFilterFerries,
  editFerryConfirm,
  deleteFerry,
  getFerrybyId,
  editFerryInitialState,
} from '../../actions';
import CreateShip from '../Ship';
import SimpleDialog from '../../components/Dialog';
import { useHistory } from 'react-router-dom';
import EditFerry from '../EditFerry';
import { IconButton, TextField, InputAdornment } from '@material-ui/core';

const Ships: React.FC<any> = (props) => {
  const intl = useIntl();
  const [open, setOpen] = React.useState<boolean>(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectOpener, setSelectOpener] = React.useState<boolean>(false);
  const [ferryId, setFerryId] = React.useState('');
  const [ferryIdConfirmed, setFerryIdConfirmed] = React.useState('');
  const [EditDialogOpen, setEditDialogOpen] = React.useState(false);

  const {
    ferriesList,
    ferriesFilterList,
    select,
    search,
    startDate,
    endDate,
    allTime,
    loading,
    loadingSend,
  } = props;
  const objectUser: any = getDataFromCache('user');
  const history = useHistory();

  React.useEffect(() => {
    props.ferriesGetAllFerries(objectUser.wallet);
  }, []);

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    props.ferriesPropsChanged('select', event.target.value);
  };
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.ferriesPropsChanged(event.target.name, event.target.checked);
  };
  const handleDateChange = (prop: string) => (date: Date | null) => {
    props.ferriesPropsChanged(prop, date);
  };

  const HandleClickSearch = () => {
    props.ferriesFilterFerries(
      select,
      allTime,
      startDate,
      endDate,
      // ferriesFilterList
      select && search
        ? (ferriesList?.length === ferriesFilterList?.length
            ? ferriesList
            : ferriesFilterList
          )?.filter((item: any) =>
            item?.[select]?.toLowerCase()?.includes(search?.toLowerCase())
          )
        : ferriesFilterList
    );
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setFerryIdConfirmed('');
  };
  const handleClickSend = () => {
    props.ferriesSendFerry(objectUser.wallet, ferryId);
    setFerryIdConfirmed(ferryId);
    setOpenDialog(false);
  };
  const handleClickDeleteferry = (ferryId: string) => {
    props.deleteFerry(ferryId, objectUser.wallet);
  };
  const RedirectToCustomers = (url: string) => {
    history.push(url);
  };
  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'search') {
      if (event.target.value.length > 60) return;
      props.ferriesPropsChanged(event.target.name, event.target.value);
    }
    props.ferriesPropsChanged(event.target.name, event.target.value);
  };

  const handleNameStatus = (value: string) => {
    switch (value) {
      case 'originName':
        return intl.formatMessage({ id: 'ships.select.originName' });
      case 'destinationName':
        return intl.formatMessage({ id: 'ships.select.destinationName' });
      // case 'departureTime':
      //   return intl.formatMessage({ id: 'ships.select.departureTime' });
      // case 'arrivalTime':
      //   return intl.formatMessage({ id: 'ships.select.arrivalTime' });
      default:
        return 'Tous';
    }
  };

  return (
    <Wrapper>
      <div className='div-search'>
        <FormControl className='filter-container' fullWidth>
          <Input value={handleNameStatus(select)} disabled margin='0' />
          <div
            className='filter-button'
            onClick={() => setSelectOpener(!selectOpener)}
          >
            <h3>
              {intl.formatMessage({
                id: 'wallet.label.filter',
              })}
            </h3>
            <ExpandMoreRoundedIcon
              style={{ color: 'white' }}
              className={selectOpener ? 'filter-opened' : ''}
            />
          </div>
          <Select
            className='select-input '
            value={select}
            onChange={handleSelectChange}
            placeholder={intl.formatMessage({
              id: 'wallet.label.filter',
            })}
            id='filter'
            inputProps={{ className: 'input' }}
            open={selectOpener}
            onClose={() => setSelectOpener(false)}
          >
            <MenuItem
              value='originName'
              onMouseEnter={(e) =>
                ((e.target as HTMLInputElement).style.color = '#ff5078')
              }
              onMouseLeave={(e) =>
                ((e.target as HTMLInputElement).style.color = '#000000')
              }
            >
              <span>
                {intl.formatMessage({ id: 'ships.select.originName' })}
              </span>
            </MenuItem>
            <MenuItem
              value='destinationName'
              onMouseEnter={(e) =>
                ((e.target as HTMLInputElement).style.color = '#ff5078')
              }
              onMouseLeave={(e) =>
                ((e.target as HTMLInputElement).style.color = '#000000')
              }
            >
              <span>
                {intl.formatMessage({ id: 'ships.select.destinationName' })}
              </span>
            </MenuItem>
            {/* <MenuItem
              value='departureTime'
              onMouseEnter={(e) =>
                ((e.target as HTMLInputElement).style.color = '#ff5078')
              }
              onMouseLeave={(e) =>
                ((e.target as HTMLInputElement).style.color = '#000000')
              }
            >
              <span>
                {intl.formatMessage({ id: 'ships.select.departureTime' })}
              </span>
            </MenuItem>
            <MenuItem
              value='arrivalTime'
              onMouseEnter={(e) =>
                ((e.target as HTMLInputElement).style.color = '#ff5078')
              }
              onMouseLeave={(e) =>
                ((e.target as HTMLInputElement).style.color = '#000000')
              }
            >
              <span>
                {intl.formatMessage({ id: 'ships.select.arrivalTime' })}
              </span>
            </MenuItem> */}
          </Select>
        </FormControl>

        <Input
          value={search}
          background='#fff'
          type='text'
          name='search'
          placeholder={intl.formatMessage({
            id: 'wallet.button.rechercher',
          })}
          className='input-search'
          onChange={handleEventChange}
          margin='0'
          disabled={!select}
        />

        {/* <DateTimePicker
          id='start-date'
          label={intl.formatMessage({ id: 'ships.label.startDate' })}
          format='E MMM dd yyyy HH:mm'
          value={startDate}
          onChange={handleDateChange('startDate')}
          inputVariant='outlined'
          maxDate={new Date()}
          disabled={allTime}
          clearable
          TextFieldComponent={({ InputProps, ...rest }) => (
            <TextField
              {...rest}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <Divider
                      className='divider'
                      orientation='vertical'
                      variant='inset'
                      light
                    />
                    <IconButton
                      onClick={
                        (InputProps as any)?.endAdornment?.props?.children
                          ?.props?.onClick
                      }
                    >
                      <Calendar />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <DateTimePicker
          id='end-date'
          label={intl.formatMessage({ id: 'ships.label.endDate' })}
          format='E MMM dd yyyy HH:mm'
          inputVariant='outlined'
          value={endDate}
          onChange={handleDateChange('endDate')}
          minDate={startDate}
          maxDate={new Date()}
          disabled={!startDate || allTime}
          TextFieldComponent={({ InputProps, ...rest }) => (
            <TextField
              {...rest}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <Divider
                      className='divider'
                      orientation='vertical'
                      variant='inset'
                      light
                    />
                    <IconButton
                      onClick={
                        (InputProps as any)?.endAdornment?.props?.children
                          ?.props?.onClick
                      }
                    >
                      <Calendar />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        /> */}
        {/* 
        <FormControlLabel
          control={
            <StyledCheckbox
              checked={allTime}
              onChange={handleCheckboxChange}
              name='allTime'
            />
          }
          label={intl.formatMessage({ id: 'ships.label.allTime' })}
        /> */}

        <Button
          onClick={HandleClickSearch}
          width={120}
          background='#FF5078'
          className='button-search'
        >
          {intl.formatMessage({ id: 'wallet.button.rechercher' })}
        </Button>
      </div>

      <div className='div-header'>
        <Text color='#404040' fontSize={24} lineHeight={36} fontWeight={600}>
          {intl.formatMessage({ id: 'ships.label.title' })}
        </Text>
        <Button
          onClick={() => {
            setOpen(!open);
          }}
        >
          {intl.formatMessage({ id: 'ships.button.create' })}
        </Button>
      </div>
      <ShipModal open={open} onClose={() => setOpen(!open)}>
        <CreateShip
          walletId={objectUser.wallet}
          onSaveChanged={() => setOpen(!open)}
        ></CreateShip>
      </ShipModal>
      <ShipModal open={EditDialogOpen} onClose={() => setEditDialogOpen(false)}>
        <EditFerry
          walletId={objectUser.wallet}
          ferryId={ferryId}
          onCancel={() => setEditDialogOpen(false)}
          onSaveChanged={() => setEditDialogOpen(!EditDialogOpen)}
        ></EditFerry>
      </ShipModal>
      <div className='div-list'>
        <SimpleDialog
          title={intl.formatMessage({ id: 'coupons.dialog.title' })}
          text={intl.formatMessage({ id: 'coupons.dialog.text' })}
          handleAgree={handleClickSend}
          open={openDialog}
          handleClose={handleCloseDialog}
        ></SimpleDialog>
        <EmptyData
          data={ferriesList}
          loading={loading}
          message={intl.formatMessage({ id: 'ships.label.emptyList' })}
          image={emptyState}
        >
          {ferriesList.map((ferry: TransitsApiResponse) => (
            <ShipCard
              loading={loadingSend}
              key={ferry._id}
              onClickDelete={handleClickDeleteferry}
              ferry={ferry}
              ferryId={ferryIdConfirmed}
              onClickRedirect={() => history.push('/ships/' + ferry._id)}
              onClickSend={() => {
                setOpenDialog(true);
                setFerryId(ferry._id);
              }}
              onClickEdit={(id) => {
                props.editFerryInitialState(ferry);
                setEditDialogOpen(true);
                setFerryId(ferry._id);
              }}
            ></ShipCard>
          ))}
        </EmptyData>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled('div')<ShipsStylesProps>`
  ${(props) => ShipsStyles(props)}
`;
const mapStateToProps = ({ ferries }: RootState) => {
  const {
    ferriesList,
    ferriesFilterList,
    startDate,
    endDate,
    allTime,
    loading,
    select,
    search,
  } = ferries;

  return {
    ferriesList,
    ferriesFilterList,
    startDate,
    endDate,
    allTime,
    loading,
    select,
    search,
  };
};
export const connector = connect(mapStateToProps, {
  ferriesGetAllFerries,
  ferriesInitialState,
  ferriesPropsChanged,
  ferriesFilterFerries,
  editFerryConfirm,
  editFerryInitialState,
  getFerrybyId,
  deleteFerry,
});
export default connector(Ships);
