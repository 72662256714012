import axios from 'axios';
import { URL } from './config';

export const updatePassword = (userId: string | null, props: any) => {
  const requestURL = `${URL}/users/${userId}/update_password`;
  return axios
    .patch(requestURL, {
      passwordCurrent: props.oldPassword,
      password: props.newPassword,
      passwordConfirm: props.confirmPassword,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export const updateEmail = (email: string) => {
  const requestURL = `${URL}/settings/update-email`;
  return axios
    .patch(requestURL, {
      email,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export const updateEmailSettings = (props: any) => {
  const requestURL = `${URL}/settings/update-config`;
  return axios
    .post(requestURL, {
      communicationEmail: props.communicationEmail,
      email: props.email,
      password: props.password,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};
