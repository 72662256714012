import { css } from 'styled-components';

export const createEventStyles = (props: any) => {
  return css`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    border-radius: 10px;
    .form-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 30px 0px;
      /* box-shadow: 0px 1px 3px #eeee; */

      .title {
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
      }
    }
    .input-label {
      box-sizing: border-box;
      font-size: 14px;
      //margin: 15px 0 10px 0;
      padding: 9px 30px 9px 30px;
      border-radius: 50px;
      width: fit-content;
      text-align: center;
      color: #fff;
      background: #ff5078;
      /* margin-top: -43px;
    margin-left: 19rem; */
      margin-top: 10px;
    }
    .div-container {
      display: flex;
      flex: 1;
      flex-direction: column;
      //box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.08);
      border-radius: 20px;
      padding: 0px 30px 30px 30px;
    }
    .section-title {
      font-weight: 500;
      font-size: 18px;
      font-family: Poppins;
      margin: 0;
      margin-bottom: 30px;
      margin-top: 30px;
    }
    /* .section:not(:first-of-type) {
      margin-top: 20px;
    } */
    .first-section {
      display: flex;
      align-items: center;
      gap: 40px;
      flex-wrap: wrap;
    }
    .second-section {
      display: flex;
      gap: 5px;
      flex-direction: column;
      align-items: flex-start;
    }
    .section-row {
      display: flex;
      gap: 20px;
    }
    .info_subContainer_wrap {
      @media screen and (max-width: 1180px) {
        flex-wrap: wrap;
      }
    }
    .second-section-row {
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      flex-direction: column;
      gap: 10px;
    }
    .wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 40px;
      @media screen and (max-width: 1120px) {
        flex-wrap: wrap;
      }
    }
    .wrapper_left {
      min-width: fit-content;
    }
    .wrapper_right {
      flex-grow: 1;
      max-width: 620px;
    }
    .wrapper_right_container {
      display: flex;
      justify-content: flex-start;
      align-items: start;
      gap: 7px 40px;
      flex-wrap: wrap;
      & > div {
        width: calc(50% - 20px);
        min-width: 290px;
      }
    }
    .action {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 14px;
      * {
        margin: 0;
      }
    }
    .map {
      height: 400px;
      width: 100%;
      margin-bottom: 40px;
    }
    .MapSearch {
      height: 32px;
      z-index: 99;
      margin-top: 15px;
    }
    .dateLabel {
      font-style: normal;
      font-weight: 600;
      line-height: 21px;
      font-size: 14px;
      color: #828282;
      margin: 5px;
    }
    .map-label {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 10px;
      span {
        font-style: normal;
        font-weight: 500;
        line-height: 21px;
        font-size: 14px;
        color: #828282;
        margin: 5px;
      }
    }
    .div-row {
      display: flex;
      gap: 20px;
      justify-content: flex-start;
      align-items: center;
      flex: 1;
    }
    .div-location > div:first-of-type {
      width: 300px !important;
    }
    .rdw-editor-main {
      border: 1px solid #f1f1f1;
      height: 200px;
      width: 100%;
      padding: 0 10px;
    }
    .public-DraftStyleDefault-block {
      margin: 5px 0;
    }
    .rdw-option-wrapper {
      * {
        border-radius: unset;
      }
    }
    .editor-wrapper {
      margin-top: 15px;
      width: 100%;
    }
    .editor-container {
      width: 100%;
    }
    .MuiOutlinedInput-adornedEnd {
      background-color: #fff;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #404040;
      box-shadow: 0px 0px 20px 2px rgb(55 55 55 / 8%);
      border-radius: 15px;
    }
    .divider {
      height: 28px;
      width: 2px;
      margin: 0px;
      /* margin-right: 15px; */
      background: #404040;
    }
    .MuiDivider-root {
      background-color: #c7c7c7 !important;
    }
    .MuiFormControl-marginNormal {
      margin-top: 10px;
    }
    .btn_parcourir {
      box-sizing: border-box;
      font-size: 14px;
      padding: 9px 30px 9px 30px;
      border-radius: 50px;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      text-align: center;
      color: #fff;
      background: #ff5078;
      margin-top: 10px;
    }
  `;
};
