import { ThunkAction } from 'redux-thunk';
import { RootState } from '../reducers';
import { Action } from 'redux';

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

//LOGIN
export const LOGIN_FORM_PROPS_CHANGED = 'login_form_props_changed';
export const LOGINING_SUCCESS = 'logining_success';
export const LOGINING_FAILED = 'logining_failed';
export const LOGINING_INITIAL_STATE = 'logining_initial_state';

//WALLET FORM
export const WALLET_FORM_PROPS_CHANGED = 'wallet_form_props_changed';
export const WALLET_FORM_WALLET = 'wallet_form_wallet';
export const WALLETS_ADD_WALLET_TO_LIST = 'wallets_add_wallet_to_list';
export const WALLET_FORM_INITIAL_STATE = 'wallet_form_initial_state';
export const WALLETS_UPDATE_WALLET_TO_LIST = 'wallets_update_wallet_to_list';
export const WALLETS_SET_WALLET = 'wallets_set_wallet';

//WALLET DETAIL
export const WALLET_GET_WALLET_DETAIL = 'wallet_get_wallet_detail';
export const WALLET_GET_WALLET_INACTIVE_DETAIL =
  'wallet_get_wallet_inactive_detail';
export const WALLET_INITIAL_STATE = 'wallet_initial_state';
export const WALLET_GET_WALLET_DETAIL_FAILED =
  'wallet_get_wallet_detail_failed';
export const WALLET_GET_WALLET_CUSTOMER_STATS =
  'wallet_get_wallet_customer_stats';

export const WALLET_GET_CUSTOMER_HISTORY = 'wallet_get_customer_history';
export const WALLET_LOADING_HISTORY = 'wallet_loading_history';
export const WALLET_PROPS_CHANGED = 'wallet_props_changed';
export const WALLET_FILTER_CUSTOMERS = 'wallet_filter_customers';
export const WALLET_DELETE_CUSTOMER = 'wallet_delete_customer';
export const WALLET_FILTER_INACTIVES_CUSTOMERS =
  'wallet_filter_inactives_customers';
export const WALLET_INACTIVE_CUSTOMERS_PROPS_CHANGED =
  'wallet_inactive_customers_props_changed';
//ADD CUSTOMER

export const ADD_CUSTOMER_PROPS_CHANGED = 'add_customer_props_changed';
export const ADD_CUSTOMER_FORM_CUSTOMER = 'add_customer_form_customer';
export const ADD_CUSTOMER_TO_CUSTOMERS_LIST = 'add_customer_to_customers_list';
export const ADD_CUSTOMER_INITIAL_STATE = 'add_customer_initial_state';

//OFFERS
export const OFFERS_GET_LIST_OFFERS = 'offers_get_list_offers';
export const OFFERS_PROPS_CHANGED = 'offers_props_changed';
export const OFFERS_ADD_OFFER_TO_LIST = 'offers_add_offer_to_list';
export const OFFERS_FILTER_OFFERS = 'offers_filter_offers';
export const OFFERS_INITIAL_STATE = 'offers_initial_state';
export const OFFERS_UPDATE_OFFER_LOADING = 'offers_update_offer_loading';
export const OFFERS_UPDATE_OFFER = 'offers_update_offer';
export const OFFERS_DELETE_OFFER = 'offers_delete_offer';

export const OFFERS_DESACTIVATE_OFFER = 'offers_desactivate_offer';

//OFFER
export const OFFER_PROPS_CHANGED = 'offer_props_changed';
export const OFFER_FORM_PROPS = 'offer_form_props';
export const OFFER_INITIAL_STATE = 'offer_initial_state';
export const EDIT_OFFER_INITIAL_STATE = 'edit_offer_initial_state';

//UPDATE GAIN
export const UPDATE_GAIN_PROPS_CHANGED = 'update_gain_props_changed';
export const UPDATE_GAIN_TO_CUSTOMERS_LIST = 'update_gain_to_customers_list';
export const UPDATE_GAIN_INITIAL_STATE = 'update_gain_initial_state';

//SETTINGS
export const SETTINGS_PROPS_CHANGED = 'settings_props_changed';
export const SETTINGS_GET_WALLET_SETTINGS = 'settings_get_wallet_settings';
export const SETTINGS_GET_WALLET_DETAIL_FAILED =
  'settings_get_wallet_detail_failed';
export const SETTINGS_GET_WALLET_DETAIL = 'settings_get_wallet_detail';
export const SETTINGS_INITIAL_STATE = 'settings_initial_state';
export const SETTINGS_GET_WALLET_FORM = 'settings_get_wallet_form';
//COUPONS
export const COUPONS_GET_SENT_COUPONS = 'coupons_get_sent_coupons';
export const COUPONS_GET_LIST_COUPONS = 'coupons_get_list_coupons';
export const COUPONS_ADD_COUPON_TO_LIST = 'coupons_add_coupon_to_list';
export const COUPONS_INITIAL_STATE = 'coupons_initial_state';
export const COUPONS_DELETE_COUPON_LOADING = 'coupons_delete_coupon_loading';
export const COUPONS_PROPS_CHANGED = 'coupons_props_changed';
export const UPDATE_SENT_COUPONS_LIST = 'update_sent_coupons_list';
export const COUPONS_DELETE_COUPON = 'coupons_delete_coupon';
export const COUPONS_DELETE_CUSTOMER_COUPON = 'coupons_delete_customer_coupon';
export const COUPONS_FILTER_COUPONS = 'coupons_filter_coupons';

export const COUPONS_ERROR = 'coupons_error';
export const COUPONS_UPDATE_DIALOG_MESSAGE = 'coupons_update_dialog_message';
export const COUPONS_UPDATE_COUPON_STATUS = 'coupons_update_coupon_message';
export const COUPONS_UPDATE_PROPS = 'coupons_update_props';
//EVENTS
export const EVENTS_GET_LIST_EVENTS = 'events_get_list_events';
export const EVENTS_INITIAL_STATE = 'events_initial_state';
export const EVENTS_PROPS_CHANGED = 'events_props_changed';
export const EVENTS_FILTER_EVENTS = 'events_filter_events';
export const EVENT_FORM_PROPS_CHANGED = 'event_form_props_changed';
export const EVENT_FORM_PROPS = 'event_form_props';
export const EVENTS_ADD_EVENT_TO_LIST = 'events_add_event_to_list';
export const EVENT_INITIAL_STATE = 'event_initial_state';
export const EVENTS_CUSTOMERS_INITIAL_STATE = 'events_customers_initial_state';
export const EVENTS_GET_CUSTOMERS_LIST = 'events_get_customers_list';
export const EVENT_CUSTOMERS_PROPS_CHANGED = 'event_customers_props_changed';
export const EVENT_FILTER_CUSTOMERS = 'event_filter_customers';
export const EVENTS_DELETE_EVENT_LOADING = 'events_delete_event_loading';
export const EVENTS_DELETE_EVENT = 'events_delete_event';
export const EVENTS_UPDATE_EVENT = 'events_update_event';
export const EVENT_GET_EVENT_PROPS = 'event_customers_props_changed';

//COUPON
export const COUPON_PROPS_CHANGED = 'coupon_props_changed';
export const COUPON_FORM_PROPS = 'coupon_form_props';
export const COUPON_INITIAL_STATE = 'coupon_initial_state';
export const EDIT_COUPON_INITIAL_STATE = 'edit_coupon_initial_state';
//PROFILE
export const PROFILE_PROPS_CHANGED = 'profile_props_changed';
export const PROFILE_VALIDATION_PROPS = 'profile_validation_props';
export const PROFILE_INITIAL_STATE = 'profile_initial_state';
export const PROFILE_GET_WALLET_EMAIL_SETTINGS =
  'profile_get_wallet_email_settings';
export const PROFILE_EMAIL_SETTINGS = 'profile_email_settings';

//FORGOT PASSWORD
export const FORGOT_PASSWORD_PROPS_CHANGED = 'forgot_password_props_changed';
export const FORGOT_PASSWORD_INITIAL_STATE = 'forgot_password_initial_state';

//RESET PASSWORD
export const RESET_PASSWORD_PROPS_CHANGED = 'reset_password_props_changed';
export const RESET_PASSWORD_VALIDATION_PROPS =
  'reset_password_validation_props';
export const RESET_PASSWORD_INITIAL_STATE = 'reset_password_initial_state';
export const RESET_PASSWORD_VERIFICATION_TOKEN_FAILED =
  'reset_password_verification_token_failed';
export const RESET_PASSWORD_VERIFICATION_TOKEN_SUCCESS =
  'reset_password_verification_token_success';

//STATISTICS
export const STATISTICS_INITIAL_STATE = 'statistics_initial_state';
export const STATISTICS_GET_SMS_COUNT = 'statistics_get_sms_count';
export const STATISTICS_GET_EMAIL_COUNT = 'statistics_get_email_count';

export const STATISTICS_GET_CLIENTS_COUNT = 'statistics_get_clients_count';

export const STATISTICS_PROPS_CHANGED = 'statistics_props_changed';
export const STATISTICS_FILTER_SMS_STATS = 'statistics_filter_sms_stats';
export const STATISTICS_FORM_PROPS = 'statistics_form_props';

//STATISTICS_DETAILS
export const STATISTICS_GET_STATS_DETAIL = 'statistics_get_stats_detail';
export const STATISTICS_DETAILS_PROPS_CHANGED =
  'statistics_details_props_changed';

//Ferry
//Ferries Transits
export const FERRIES_GET_SENT_FERRIES = 'ferries_get_sent_ferries';
export const FERRIES_GET_LIST_FERRIES = 'ferries_get_list_ferries';
export const FERRIES_INITIAL_STATE = 'ferries_initial_state';
export const FERRIES_PROPS_CHANGED = 'ferries_props_changed';
export const FERRIES_FILTER_FERRIES = 'ferries_filter_ferries';
export const FERRY_FORM_PROPS_CHANGED = 'ferry_form_props_changed';
export const FERRY_FORM_PROPS = 'ferry_form_props';
export const FERRIES_ADD_FERRY_TO_LIST = 'ferries_add_ferry_to_list';
export const FERRY_INITIAL_STATE = 'ferry_initial_state';
export const FERRIES_CUSTOMERS_INITIAL_STATE =
  'ferries_customers_initial_state';
export const FERRIES_GET_CUSTOMERS_LIST = 'ferries_get_customers_list';
export const FERRY_CUSTOMERS_PROPS_CHANGED = 'ferry_customers_props_changed';
export const FERRY_FILTER_CUSTOMERS = 'ferry_filter_customers';
export const FERRIES_DELETE_FERRY_LOADING = 'ferries_delete_ferry_loading';
export const FERRIES_DELETE_FERRY = 'ferries_delete_ferry';
export const FERRY_GET_FERRY_PROPS = 'ferry_customers_props_changed';
export const EDIT_FERRY_INITIAL_STATE = 'edit_ferry_initial_state';
export const FERRIES_DELETE_CUSTOMER_FERRY = 'ferries_delete_customer_ferry';
export const FERRY_PROPS_CHANGED = 'ferry_props_changed';
export const UPDATE_SENT_FERRIES_LIST = 'update_sent_ferries_list';
export const CUSTOMER_FERRY_FORM_PROPS_CHANGED =
  'customer_ferry_form_props_changed';
export const CUSTOMER_FERRY_FORM_PROPS = ' customer_ferry_form_props';
//BUS
//buses Transits
export const BUSES_GET_LIST_BUSES = 'buses_get_list_buses';
export const BUSES_INITIAL_STATE = 'buses_initial_state';
export const BUSES_PROPS_CHANGED = 'buses_props_changed';
export const BUSES_FILTER_BUSES = 'buses_filter_buses';
export const BUS_FORM_PROPS_CHANGED = 'bus_form_props_changed';
export const BUS_FORM_PROPS = 'bus_form_props';
export const BUSES_ADD_BUS_TO_LIST = 'buses_add_bus_to_list';
export const BUS_INITIAL_STATE = 'bus_initial_state';
export const BUSES_CUSTOMERS_INITIAL_STATE = 'buses_customers_initial_state';
export const BUSES_GET_CUSTOMERS_LIST = 'buses_get_customers_list';
export const BUS_CUSTOMERS_PROPS_CHANGED = 'bus_customers_props_changed';
export const BUS_FILTER_CUSTOMERS = 'bus_filter_customers';
export const BUSES_DELETE_BUS_LOADING = 'buses_delete_bus_loading';
export const BUSES_DELETE_BUS = 'buses_delete_bus';
export const BUS_GET_BUS_PROPS = 'bus_customers_props_changed';
export const EDIT_BUS_INITIAL_STATE = 'edit_bus_initial_state';
export const BUS_PROPS_CHANGED = 'bus_props_changed';
export const UPDATE_SENT_BUSES_LIST = 'update_sent_buses_list';
export const BUSES_GET_SENT_BUSES = 'buses_get_sent_buses';
export const BUSES_DELETE_CUSTOMER_BUS = 'buses_delete_customer_bus';
export const CUSTOMER_BUS_FORM_PROPS_CHANGED =
  'customer_bus_form_props_changed';
export const CUSTOMER_BUS_FORM_PROPS = ' customer_bus_form_props';

//Tram
//Trams Transits
export const TRAMS_GET_LIST_TRAMS = 'trams_get_list_trams';
export const TRAMS_INITIAL_STATE = 'trams_initial_state';
export const TRAMS_PROPS_CHANGED = 'trams_props_changed';
export const TRAMS_FILTER_TRAMS = 'trams_filter_trams';
export const TRAM_FORM_PROPS_CHANGED = 'tram_form_props_changed';
export const TRAM_FORM_PROPS = 'tram_form_props';
export const TRAMS_ADD_TRAM_TO_LIST = 'trams_add_tram_to_list';
export const TRAM_INITIAL_STATE = 'tram_initial_state';
export const TRAMS_CUSTOMERS_INITIAL_STATE = 'trams_customers_initial_state';
export const TRAMS_GET_CUSTOMERS_LIST = 'trams_get_customers_list';
export const TRAM_CUSTOMERS_PROPS_CHANGED = 'tram_customers_props_changed';
export const TRAM_FILTER_CUSTOMERS = 'tram_filter_customers';
export const TRAMS_DELETE_TRAM_LOADING = 'trams_delete_tram_loading';
export const TRAMS_DELETE_TRAM = 'trams_delete_tram';
export const TRAM_GET_TRAM_PROPS = 'tram_customers_props_changed';
export const EDIT_TRAM_INITIAL_STATE = 'edit_tram_initial_state';
export const TRAM_PROPS_CHANGED = 'tram_props_changed';
export const UPDATE_SENT_TRAMS_LIST = 'update_sent_trams_list';
export const TRAMS_GET_SENT_TRAMS = 'trams_get_sent_trams';
export const TRAMS_DELETE_CUSTOMER_TRAM = 'trams_delete_customer_tram';
export const CUSTOMER_TRAM_FORM_PROPS_CHANGED =
  'customer_tram_form_props_changed';
export const CUSTOMER_TRAM_FORM_PROPS = ' customer_tram_form_props';

//Rail
//Rails Transits
export const RAILS_GET_LIST_RAILS = 'rails_get_list_rails';
export const RAILS_INITIAL_STATE = 'rails_initial_state';
export const RAILS_PROPS_CHANGED = 'rails_props_changed';
export const RAILS_FILTER_RAILS = 'rails_filter_rails';
export const RAIL_FORM_PROPS_CHANGED = 'rails_form_props_changed';
export const RAIL_FORM_PROPS = 'rails_form_props';
export const RAILS_ADD_RAIL_TO_LIST = 'rails_add_rails_to_list';
export const RAIL_INITIAL_STATE = 'rails_initial_state';
export const RAILS_CUSTOMERS_INITIAL_STATE = 'rails_customers_initial_state';
export const RAILS_GET_CUSTOMERS_LIST = 'rails_get_customers_list';
export const RAIL_CUSTOMERS_PROPS_CHANGED = 'rails_customers_props_changed';
export const RAIL_FILTER_CUSTOMERS = 'rails_filter_customers';
export const RAILS_DELETE_RAIL_LOADING = 'rails_delete_rails_loading';
export const RAILS_DELETE_RAIL = 'rails_delete_rails';
export const RAIL_GET_RAIL_PROPS = 'rails_customers_props_changed';
export const EDIT_RAIL_INITIAL_STATE = 'edit_rails_initial_state';
export const CUSTOMER_RAIL_FORM_PROPS = 'customer_rail_form_props';
export const CUSTOMER_RAIL_FORM_PROPS_CHANGED =
  'customer_rail_form_props_changed';
export const RAIL_PROPS_CHANGED = 'rail_props_changed';
export const RAILS_DELETE_CUSTOMER_RAIL = 'rails_delete_customer_rail';
export const RAILS_GET_SENT_RAILS = 'rails_get_sent_rails';
//Hotel
//Hotels
export const HOTELS_GET_LIST_HOTELS = 'hotels_get_list_hotels';
export const HOTELS_INITIAL_STATE = 'hotels_initial_state';
export const HOTELS_PROPS_CHANGED = 'hotels_props_changed';
export const HOTELS_FILTER_HOTELS = 'hotels_filter_hotels';
export const HOTEL_FORM_PROPS_CHANGED = 'hotel_form_props_changed';
export const HOTEL_FORM_PROPS = 'hotel_form_props';
export const HOTELS_ADD_HOTEL_TO_LIST = 'hotels_add_hotel_to_list';
export const HOTEL_INITIAL_STATE = 'hotel_initial_state';
export const HOTELS_CUSTOMERS_INITIAL_STATE = 'hotels_customers_initial_state';
export const HOTELS_GET_CUSTOMERS_LIST = 'hotels_get_customers_list';
export const HOTEL_CUSTOMERS_PROPS_CHANGED = 'hotel_customers_props_changed';
export const HOTEL_FILTER_CUSTOMERS = 'hotel_filter_customers';
export const HOTELS_DELETE_HOTEL_LOADING = 'hotels_delete_hotel_loading';
export const HOTELS_DELETE_HOTEL = 'hotels_delete_hotel';
export const HOTEL_GET_HOTEL_PROPS = 'hotel_customers_props_changed';
export const EDIT_HOTEL_INITIAL_STATE = 'edit_hotel_initial_state';
export const HOTELS_GET_SENT_HOTELS = 'hotels_get_sent_hotels';
export const HOTELS_DELETE_CUSTOMER_HOTEL = 'hotels_delete_customer_hotel';
export const HOTEL_PROPS_CHANGED = 'hotel_props_changed';
export const UPDATE_SENT_HOTELS_LIST = 'update_sent_hotels_list';
export const CUSTOMER_HOTEL_FORM_PROPS_CHANGED =
  'customer_hotel_form_props_changed';
export const CUSTOMER_HOTEL_FORM_PROPS = ' customer_hotel_form_props';
//CinemasTickets

export const CINEMATICKETS_GET_SENT_CINEMATICKETS =
  'cinemaTickets_get_sent_cinemaTickets';
export const CINEMATICKETS_GET_LIST_CINEMATICKETS =
  'cinemaTickets_get_list_cinemaTickets';
export const CINEMATICKETS_ADD_CINEMATICKET_TO_LIST =
  'cinemaTickets_add_coupon_to_list';
export const CINEMATICKETS_INITIAL_STATE = 'cinemaTickets_initial_state';
export const CINEMATICKETS_DELETE_CINEMATICKET_LOADING =
  'cinemaTickets_delete_coupon_loading';
export const CINEMATICKETS_PROPS_CHANGED = 'cinemaTickets_props_changed';
export const UPDATE_SENT_CINEMATICKETS_LIST = 'update_sent_cinemaTickets_list';
export const CINEMATICKETS_DELETE_CINEMATICKET = 'cinemaTickets_delete_coupon';
export const CINEMATICKETS_DELETE_CUSTOMER_CINEMATICKET =
  'cinemaTickets_delete_customer_coupon';

export const CINEMATICKETS_ERROR = 'cinemaTickets_error';
export const CINEMATICKETS_UPDATE_DIALOG_MESSAGE =
  'cinemaTickets_update_dialog_message';
export const CINEMATICKETS_UPDATE_CINEMATICKET_STATUS =
  'cinemaTickets_update_coupon_message';
export const CINEMATICKETS_UPDATE_PROPS = 'cinemaTickets_update_props';

//SNACKBAR
export const SNACKBAR_SUCCESS = 'snackbar_success';
export const SNACKBAR_ERROR = 'snackbar_error';
export const SNACKBAR_INFO = 'snackbar_info';
export const SNACKBAR_CLEAR = 'snackbar_clear';

//FLIGHTS
export const FLIGHTS_GET_SENT_FLIGHTS = 'flights_get_sent_flights';

export const FLIGHTS_GET_LIST_FLIGHTS = 'flights_get_list_flights';
export const FLIGHTS_INITIAL_STATE = 'flights_initial_state';
export const FLIGHTS_PROPS_CHANGED = 'flights_props_changed';
export const FLIGHTS_FILTER_FLIGHTS = 'flights_filter_flights';
export const FLIGHT_FORM_PROPS_CHANGED = 'flight_form_props_changed';
export const FLIGHT_FORM_PROPS = 'flight_form_props';
export const FLIGHTS_ADD_FLIGHT_TO_LIST = 'flights_add_flight_to_list';
export const FLIGHT_INITIAL_STATE = 'flight_initial_state';
export const FLIGHTS_CUSTOMERS_INITIAL_STATE =
  'flights_customers_initial_state';
export const FLIGHTS_GET_CUSTOMERS_LIST = 'flights_get_customers_list';
export const FLIGHT_CUSTOMERS_PROPS_CHANGED = 'flight_customers_props_changed';
export const FLIGHT_FILTER_CUSTOMERS = 'flight_filter_customers';
export const FLIGHTS_DELETE_FLIGHT_LOADING = 'flights_delete_flight_loading';
export const FLIGHTS_DELETE_FLIGHT = 'flights_delete_flight';
export const FLIGHT_GET_FLIGHT_PROPS = 'flight_customers_props_changed';
export const EDIT_FLIGHT_INITIAL_STATE = 'edit_flight_initial_state';
export const CUSTOMER_FLIGHT_FORM_PROPS_CHANGED =
  'customer_flight_form_props_changed';
export const UPDATE_SENT_FLIGHTS_LIST = 'update_sent_flights_list';

// demos
export const DEMOS_GET_SENT_DEMOS = 'demos_get_sent_demos';
export const DEMOS_PROPS_CHANGED = 'demos_props_changed';
