import axios from 'axios';
import { URL } from './config';

export const createOffer = (walletId: string | null, offer: any) => {
  const requestURL = `${URL}/wallets/${walletId}/offers`;
  return axios
    .post(requestURL, offer)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};
export const editOffer = (
  walletId: string | null,
  offerId: string | null,
  offer: any
) => {
  const requestURL = `${URL}/wallets/${walletId}/offers/${offerId}`;
  return axios
    .put(requestURL, offer)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export const getOffer = (walletId: string | null, offerId: string | null) => {
  const requestURL = `${URL}/wallets/${walletId}/offers/${offerId}`;

  return axios
    .get(requestURL)
    .then(({ data }: { data: any }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
};
