import React from 'react';
import { Wrapper } from './hotelWireframe.style';

import { ellipses } from 'react-icons-kit/iconic/ellipses';
import { ratio } from 'wcag-color';
import barcode from '../../assets/images/barcode.png';

import cover from '../../assets/images/lg-thumbnail.png';
import { getDataFromCache } from '../../utils/cache';
import { URL_IMAGE } from '../../apis/config';
import Icon from 'react-icons-kit';

import moment from 'moment';
import QRCode from 'qrcode.react';
import Ferry from '../../icons/Ferry';
import { convertToMoment } from '../../utils/common';
import { ic_more_vert } from 'react-icons-kit/md/ic_more_vert';
import { useIntl } from 'react-intl';
import CODE128 from '../../assets/images/code128.png';
import PDF417 from '../../assets/images/pdf417.png';
import QR from '../../assets/images/qr.png';
import AZTEC from '../../assets/images/aztec.png';
import { switchBarcode } from '../../utils/common';

type WireframeProps = {
  backgroundColor: any;
  strip: any;
  number: any;
  name: any;
  terms: any;
  type: any;
  email: any;
  address: any;
  contactPhone: any;
  website: any;
  QrCode: any;
  titleColor?: any;
  textColor?: any;
  state?: any;
  logo?: any;
  barcodeType: string;
};

const HotelWireframeIPhone: React.FC<WireframeProps> = (props) => {
  const {
    backgroundColor,
    strip,
    number,
    name,
    terms,
    type,
    email,
    address,
    contactPhone,
    website,
    QrCode,
    titleColor,
    textColor,
    logo,
    barcodeType,
  } = props;
  const currentWallet = getDataFromCache('wallet');
  const [showDetails, setShowDetails] = React.useState(true);
  const backFlip = () => {
    setShowDetails(!showDetails);
  };
  const { barcode, barcodeClass } = switchBarcode(barcodeType, {
    CODE128,
    PDF417,
    QR,
    AZTEC,
  });
  const intl = useIntl();
  return (
    <Wrapper
      backgroundColor={backgroundColor}
      mainContentTextColor={
        ratio('#ffffff', backgroundColor.hex) > 3.0 ? '#ffffff' : '#000000'
      }
      titleColor={titleColor}
      textColor={textColor}
    >
      {showDetails ? (
        <div className='mainSection'>
          <div className='firstSection'>
            <img
              className='mainSection-header-logo'
              src={logo ? logo : `${URL_IMAGE}/${currentWallet.logoPictureUrl}`}
              alt='wallet Logo'
            />

            <div className='date'>
              <h3 className='mainSection-labels'>{'Prenom Nom'}</h3>
            </div>

            <div className='date'>
              <h3 className='mainSection-data'>{name}</h3>
            </div>

            <div className='date'>
              <h3 className='mainSection-labels'>{address}</h3>
            </div>

            <div className='mainSection-content-banner'>
              <img
                src={
                  !strip
                    ? cover
                    : typeof strip === 'string'
                    ? strip
                    : URL.createObjectURL(strip)
                }
                alt='banner'
              />
            </div>
          </div>

          <div className='datasect'>
            <div className='date'>
              <h3 className='mainSection-data'>Type:</h3>
              <h3 className='mainSection-labels'>{type}</h3>
            </div>
            <div className='date'>
              <h3 className='mainSection-data'>
                {' '}
                {intl.formatMessage({ id: 'transit.label.number' })}
              </h3>
              <h3 className='mainSection-labels'>{number}</h3>
            </div>
          </div>

          <div
            className={
              barcodeType === 'NONE'
                ? 'mainSection-content-barCode hidden'
                : 'mainSection-content-barCode'
            }
          >
            {' '}
            <div className='barCode'>
              <img className={barcodeClass} src={barcode} alt='barcode' />
              <p className='walletId'>6066e4315abbcc1f9526d392</p>
            </div>
          </div>
          <div className='BackFlip_btn' onClick={backFlip}>
            <Icon size={18} icon={ic_more_vert} className='backflip' />
          </div>
        </div>
      ) : (
        <div className='dataSection'>
          <div className='sectionLabel'>
            <h3 className='label'>BENIFICIARE DU BILLET</h3>
            <h3 className='data'>{'Nom Prenom'}</h3>
          </div>
          <span className='divider'></span>
          <div className='sectionLabel'>
            <h3 className='label'>Address</h3>
            <h3 className='data'>{'Address'}</h3>
          </div>
          <span className='divider'></span>
          <div className='sectionLabel'>
            <h3 className='label'>
              {intl.formatMessage({ id: 'ship.label.ticketNumber' })}
            </h3>
            <h3 className='data'>6066e4315abbcc1f9526d392</h3>
          </div>
          <span className='divider'></span>
          <div className='sectionLabel'>
            <h3 className='label'>website</h3>
            <h3 className='data'>{`${website} ` || ''}</h3>
          </div>
          <span className='divider'></span>
          <div className='sectionLabel'>
            <h3 className='label'>Terms & conditions</h3>
            <h3 className='data'>{terms || ''}</h3>
          </div>
          <span className='divider'></span>

          <div className='sectionLabel'>
            <h3 className='label'>Info Line</h3>
            <h3 className='data'>{contactPhone || ''}</h3>
          </div>
          <div className='BackFlip_btn' onClick={backFlip}>
            <Icon size={18} icon={ic_more_vert} className='backflip' />
          </div>
        </div>
      )}
    </Wrapper>
  );
};

export default HotelWireframeIPhone;
