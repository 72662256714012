import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 340,
      maxHeight: 300,
      marginRight: '15px',
      background: 'linear-gradient(180deg, #FDF3FE 0%, #FFFFFF 100%)',
      position: 'relative',
      //boxShadow: '0px 0px 20px 2px rgba(55, 55, 55, 0.08)',
      borderRadius: '20px',
      border: '2px solid #FF5078',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    media: {
      height: 130,
      width: 200,
      borderRadius: '20px',
      border: '1px solid #CDCDCD',
      boxSizing: 'border-box',
    },
    cardContent: {
      //display: 'flex',
      //justifyContent: 'space-between',
      marginTop: '30px',
      padding: '20px',
      height: '235px',
    },
    cardItemContent: {
      display: 'flex',
      justifyContent: 'center',
    },
    cardItem: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      // position: 'absolute',
    },
    margins: {
      marginBottom: '10px',
    },
    cardLogo: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'end',
    },
    cardLogoItems: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'end',
    },
    cardItemNumber: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '10px',
    },

    cardItemID: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '10px',
    },

    label: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginBottom: '8px',
    },
    location: {
      display: 'flex',
      flexDirection: 'column',
      gap: '5px',
      justifyContent: 'space-between',
      // alignItems: 'center',
      marginBottom: '8px',
      marginRight: '20px',
    },
    text2: {
      marginLeft: '8px',
    },
    cardDescription: {
      padding: '8px',
      border: '1px solid #C7C7C7',
      borderRadius: '10px',
      marginTop: '8px',
    },
    description: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    activateCoupon: {
      display: 'flex',
      alignItems: 'center',
    },
    cardMenu: {
      position: 'relative',
      height: '0px',
      left: '300px',
      top: '15px',
    },
    menuIcon: {
      fill: '#ffffff',
    },
    iconButton: {},
    cardPosition: {
      '& .MuiPaper-root .MuiMenu-paper': {
        top: '176px',
        left: '324px',
      },
    },
    '$root.Mui-MuiMenu-paper &': {
      top: '176px',
      left: '324px',
    },
  })
);
