import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { CircularProgress, Divider } from '@material-ui/core';

//Components
import SideBar from '../../components/Drawer';
import { StyledCheckbox } from '../../commons/CheckBox';
import Button from '../../commons/Button';
import Text from '../../commons/Text';
import StatsCard from '../../components/Cards/StatsCard';

//Actions
import {
  getSMSstatistics,
  statsPropsChanged,
  StatsFilterSMS,
  getClientstatistics,
  getEmailStatistics,
  StatsDetailsFilterSMS,
} from '../../actions';

//Api
import { RootState } from '../../reducers';

import { getDataFromCache } from '../../utils/cache';
import { isAllowed } from '../../utils/guard';

import Calendar from '../../icons/Calendar';
import CreatePlus from '../../icons/CreatePlus';
import Discount from '../../icons/Discount';
import TicketStar from '../../icons/TicketStar';
import CouponIcon from '../../icons/CouponIcon';
import Circle from '../../icons/Circle';
import Android from '../../icons/Android';
import Appel from '../../icons/Appel';
import Lines from '../../icons/Lines';
import VerticalLines from '../../icons/VerticalLines';

import emptyState from '../../assets/images/emptyState.svg';

import { smsStateError } from './stats.types';

import { offersStyles, OffersStylesProps } from './stats.styles';
import { useStyles } from './statsTable.styles';

const Statistics: React.FC<any> = (props) => {
  const intl = useIntl();
  const objectUser: any = getDataFromCache('user');
  const classes = useStyles();

  const {
    deletedAccountsiOS,
    deletedAccountsAndroid,
    actifAccountsiOS,
    actifAccountsAndroid,
    actifAccounts,
    InactifAccounts,
    extrnalSource,
    internalSource,
    crmSource,
    totalCustomers,
    sentMessagesForCoupons,
    sentMessagesForOffers,
    sentMessagesForEvents,
    sentMessagesForAccountCreation,
    couponEmails,
    totalEmails,
    flightEmails,
    passwordResetEmails,
    eventsEmails,
    walletUpdateEmails,
    newAccountsEmails,
    offersEmails,
    creditsQuantity,
    total,
    allTime,
    startDate,
    endDate,
    endDateError,
    loading,
    wallet,
    typeDetails,
    loadingDetails,
    type,
    loadingClientsStats,
    loadingClientsSMSStats,
    loadingEmailStats,
    cinemaEmails,
    hotelEmails,
    transitEmails,
  } = props;

  const cards = [
    {
      title: intl.formatMessage({ id: 'stats.label.newAccount' }),
      count: sentMessagesForAccountCreation,
      type: 'newAccount',
      icon: <CreatePlus />,
      style: { border: '1px solid #691FDE', color: '#691FDE' },
      className: 'title',
    },
    ...(isAllowed('offer')
      ? [
          {
            title: intl.formatMessage({ id: 'stats.label.offers' }),
            count: sentMessagesForOffers,
            type: 'offer',
            icon: <Discount />,
            style: { border: '1px solid #9D30BB', color: '#9D30BB' },
          },
          ...(isAllowed('eventTicket')
            ? [
                {
                  title: intl.formatMessage({ id: 'stats.label.events' }),
                  count: sentMessagesForEvents,
                  type: 'event',
                  icon: <TicketStar />,
                  style: { border: '1px solid #FF5078', color: '#FF5078' },
                },
              ]
            : []),
          ...(isAllowed('coupon')
            ? [
                {
                  title: intl.formatMessage({ id: 'stats.label.coupons' }),
                  count: sentMessagesForCoupons,
                  type: 'coupon',
                  icon: <CouponIcon />,
                  style: { border: '1px solid #FC9877', color: '#FC9877' },
                },
              ]
            : []),
          {
            title: intl.formatMessage({ id: 'stats.label.total' }),
            count: total,
            type: 'total',
            style: {
              width: '100px',
              color: '#FF5078',
              border: '1px solid #FF5078',
            },
          },
        ]
      : []),
  ];

  const emailStats = [
    {
      title: intl.formatMessage({ id: 'stats.label.newAccount' }),
      count: newAccountsEmails,
      type: 'email',
    },
    ...(isAllowed('offer')
      ? [
          {
            title: intl.formatMessage({ id: 'stats.label.offers' }),
            count: offersEmails,
            type: 'email',
            icon: <Circle />,
          },
        ]
      : []),
    ...(isAllowed('eventTicket')
      ? [
          {
            title: intl.formatMessage({ id: 'stats.label.events' }),
            count: eventsEmails,
            type: 'email',
            icon: <Circle />,
          },
        ]
      : []),
    ...(isAllowed('coupon')
      ? [
          {
            title: intl.formatMessage({ id: 'stats.label.coupons' }),
            count: couponEmails,
            type: 'email',
            icon: <Circle />,
          },
        ]
      : []),
    ...(isAllowed('boardingPass')
      ? [
          {
            title: intl.formatMessage({ id: 'stats.label.flightsTickets' }),
            count: flightEmails,
            type: 'email',
            icon: <Circle />,
          },
        ]
      : []),
    {
      title: intl.formatMessage({ id: 'stats.label.walletUpdate' }),
      count: walletUpdateEmails,
      type: 'email',
    },
    ...(isAllowed('cinemaPass')
      ? [
          {
            title: intl.formatMessage({ id: 'stats.label.moviesTickets' }),
            count: cinemaEmails,
            type: 'email',
            icon: <Circle />,
          },
        ]
      : []),
    ...(isAllowed('transit')
      ? [
          {
            title: intl.formatMessage({ id: 'stats.label.transitTickets' }),
            count: transitEmails,
            type: 'email',
            icon: <Circle />,
          },
        ]
      : []),
    ...(isAllowed('hotelPass')
      ? [
          {
            title: intl.formatMessage({ id: 'stats.label.reservations' }),
            count: hotelEmails,
            type: 'email',
            icon: <Circle />,
          },
        ]
      : []),
  ];

  const emailTotalStats = {
    title: intl.formatMessage({ id: 'stats.label.total' }),
    count: totalEmails,
    type: 'total',
  };

  const clientsStats = [
    {
      title: intl.formatMessage({ id: 'stats.label.activeClients' }),
      count: actifAccounts,
      type: 'client',
    },

    {
      title: intl.formatMessage({ id: 'stats.label.inactiveClients' }),
      count: InactifAccounts,
      type: 'client',
    },
    {
      title: intl.formatMessage({ id: 'stats.label.deletedClients' }),
      count: deletedAccountsAndroid + deletedAccountsiOS,
      type: 'client',
    },
  ];

  const clientsTotalStats = [
    {
      title: intl.formatMessage({ id: 'stats.label.totalClients' }),
      count: totalCustomers,
      type: 'total',
    },
  ];

  const clientsStatsEIC = [
    {
      title: intl.formatMessage({ id: 'stats.label.external' }),
      count: extrnalSource,
      type: 'client',
      //style: { border: '3px solid #691FDE', color: '#691FDE ' },
      className: 'externe',
    },
    {
      title: intl.formatMessage({ id: 'stats.label.internal' }),
      count: internalSource,
      type: 'client',
      //style: { border: '3px solid #9D30BB', color: '#9D30BB' },
      className: 'interne',
    },
    {
      title: 'CRM',
      count: crmSource,
      type: 'client',
      //style: { border: '3px solid #FF5078', color: '#FF5078' },
      className: 'crm',
    },
  ];

  const clientDeleted = [
    {
      title: intl.formatMessage({ id: 'stats.label.androidActive' }),
      count: actifAccountsAndroid,
      type: 'client',
      icon: <Android />,
    },
    {
      title: intl.formatMessage({ id: 'stats.label.iosActive' }),
      count: actifAccountsiOS,
      type: 'client',
      icon: <Appel />,
    },

    {
      title: intl.formatMessage({ id: 'stats.label.deletedAndroid' }),
      count: deletedAccountsAndroid,
      type: 'client',
      icon: <Android />,
    },
    {
      title: intl.formatMessage({ id: 'stats.label.deletedIos' }),
      count: deletedAccountsiOS,
      type: 'client',
      icon: <Appel />,
    },
  ];
  React.useEffect(() => {
    props.getSMSstatistics(objectUser.wallet);
    props.getEmailStatistics(objectUser.wallet);
    props.getClientstatistics(objectUser.wallet);
    // eslint-disable-next-line
  }, []);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.statsPropsChanged(event.target.name, event.target.checked);
  };

  const handleDateChange = (prop: string) => (date: Date | null) => {
    props.statsPropsChanged(prop, date);
  };

  const HandleClickSearch = () => {
    props.StatsFilterSMS(objectUser.wallet, allTime, startDate, endDate);
  };
  const getFormatedMessage = (prop: smsStateError) => {
    if (!prop) {
      return '';
    }

    return intl.formatMessage({ id: prop });
  };

  const renderDetails = () => {
    if (loadingDetails) {
      return (
        <div className={classes.historyContainer}>
          <CircularProgress
            size={30}
            className={classes.circularProgressStyle}
          />
        </div>
      );
    } else if (typeDetails.length === 0 && type && !loadingDetails) {
      return (
        <div className={classes.historyContainer}>
          <div className={classes.imgContainer}>
            <img
              src={emptyState}
              className={classes.imgStyle}
              alt='empty-state'
            />
          </div>
          <p className={classes.textEmpty}>La liste est vide</p>
        </div>
      );
    }
  };
  const [isTypeSms, setIsTypeSms] = React.useState<boolean>(true);
  const [isTypeEmail, setIsTypeEmail] = React.useState<boolean>(true);
  const [isTypeClient, setIsTypeClient] = React.useState<boolean>(true);

  return (
    <SideBar>
      <Wrapper loading={loading.toString()}>
        <div className='div-search'>
          <div className='div-search-inputs'>
            <div className='div-search-date'>
              <KeyboardDatePicker
                id='start-date'
                label={intl.formatMessage({ id: 'offers.label.startDate' })}
                format='MMM dd yyyy'
                value={allTime === false ? startDate : null}
                onChange={handleDateChange('startDate')}
                inputVariant='outlined'
                style={{ width: '250px' }}
                disabled={allTime}
                TextFieldComponent={({ InputProps, ...rest }) => (
                  <TextField
                    {...rest}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <Divider
                            className='divider'
                            orientation='vertical'
                            variant='inset'
                            light
                          />
                          <IconButton
                            onClick={
                              (InputProps as any)?.endAdornment.props.children
                                .props.onClick
                            }
                          >
                            <Calendar />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <KeyboardDatePicker
                id='end-date'
                label={intl.formatMessage({ id: 'offers.label.endDate' })}
                format='MMM dd yyyy'
                inputVariant='outlined'
                value={allTime === false ? endDate : null}
                onChange={handleDateChange('endDate')}
                error={endDateError !== ''}
                helperText={getFormatedMessage(endDateError)}
                style={{ width: '250px' }}
                disabled={startDate === null ? true : false || allTime}
                minDate={startDate}
                TextFieldComponent={({ InputProps, ...rest }) => (
                  <TextField
                    {...rest}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <Divider
                            className='divider'
                            orientation='vertical'
                            variant='inset'
                            light
                          />
                          <IconButton
                            onClick={
                              (InputProps as any)?.endAdornment.props.children
                                .props.onClick
                            }
                          >
                            <Calendar />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <FormControlLabel
                control={
                  <StyledCheckbox
                    checked={allTime}
                    onChange={handleCheckboxChange}
                    name='allTime'
                  />
                }
                label={intl.formatMessage({ id: 'offers.label.allTime' })}
              />
            </div>
          </div>

          <Button background='#FF5078' onClick={HandleClickSearch}>
            {intl.formatMessage({ id: 'offers.button.search' })}
          </Button>
        </div>

        <div className='div-header'>
          <Text color='#404040' fontSize={24} lineHeight={36} fontWeight={600}>
            {intl.formatMessage({ id: 'stats.label.title' })}
          </Text>
          {loadingClientsSMSStats && (
            <div className={classes.historyContainer}>
              <CircularProgress
                size={25}
                className={classes.circularProgressStyle}
              />
            </div>
          )}
          {creditsQuantity >= 0 ? (
            <div>
              {intl.formatMessage({ id: 'stats.label.availableCredit' })}:{' '}
              <span style={{ fontWeight: 'bold' }}>{creditsQuantity}</span>
            </div>
          ) : (
            <>{intl.formatMessage({ id: 'stats.label.noAssociatedAccount' })}</>
          )}
        </div>

        <div className='div-list'>
          {cards.map((item, index) => (
            <StatsCard
              key={item.title + index}
              title={item.title}
              count={item.count}
              type={item.type}
              walletId={objectUser.wallet}
              startDate={startDate}
              endDate={endDate}
              allTime={allTime}
              icon={item.icon}
              style={item.style}
              className={item.className}
              isTypeSms={isTypeSms}
              setIsTypeSms={setIsTypeSms}
            ></StatsCard>
          ))}

          {typeDetails.length > 0 && (
            <TableContainer style={{ marginTop: 28 }}>
              <Table size='small' aria-label='a dense table'>
                <TableHead>
                  <TableRow className={classes.root}>
                    <TableCell>
                      <span style={{ textTransform: 'capitalize' }}>
                        {type}
                      </span>{' '}
                      Id
                    </TableCell>
                    <TableCell align='right'> Customers contacted</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {typeDetails.map((row: any) => (
                    <TableRow key={row._id}>
                      <TableCell component='th' scope='row'>
                        {row._id}
                      </TableCell>
                      <TableCell align='right'>{row.count}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
        {renderDetails()}
        <div className='div-header'>
          <Text color='#404040' fontSize={24} lineHeight={36} fontWeight={600}>
            {intl.formatMessage({ id: 'stats.label.emailReport' })}
          </Text>
          <StatsCard
            className='total-card'
            key={emailTotalStats.title}
            title={emailTotalStats.title}
            count={emailTotalStats.count}
            type={emailTotalStats.type}
            walletId={objectUser.wallet}
            startDate={startDate}
            endDate={endDate}
            allTime={allTime}
            isTypeEmail={isTypeEmail}
            setIsTypeEmail={setIsTypeEmail}
          ></StatsCard>

          {loadingEmailStats && (
            <div className={classes.historyContainer}>
              <CircularProgress
                size={25}
                className={classes.circularProgressStyle}
              />
            </div>
          )}
        </div>

        <div className='div-list'>
          {emailStats.map((item, index) => (
            <StatsCard
              className='email-card'
              key={item.title + index}
              title={item.title}
              count={item.count}
              type={item.type}
              walletId={objectUser.wallet}
              startDate={startDate}
              endDate={endDate}
              allTime={allTime}
              isTypeEmail={isTypeEmail}
              setIsTypeEmail={setIsTypeEmail}
            ></StatsCard>
          ))}
          {typeDetails.length > 0 && (
            <TableContainer style={{ marginTop: 28 }}>
              <Table size='small' aria-label='a dense table'>
                <TableHead>
                  <TableRow className={classes.root}>
                    <TableCell>
                      <span style={{ textTransform: 'capitalize' }}>
                        {type}
                      </span>{' '}
                      Id
                    </TableCell>
                    <TableCell align='right'> Customers contacted</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {typeDetails.map((row: any) => (
                    <TableRow key={row._id}>
                      <TableCell component='th' scope='row'>
                        {row._id}
                      </TableCell>
                      <TableCell align='right'>{row.count}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>

        <div className='div-header'>
          <Text color='#404040' fontSize={24} lineHeight={36} fontWeight={600}>
            {intl.formatMessage({ id: 'statsCLients.label.title' })}
          </Text>
          {clientsTotalStats.map((item, index) => (
            <StatsCard
              key={item.title + index}
              title={item.title}
              count={item.count}
              type={item.type}
              walletId={objectUser.wallet}
              startDate={startDate}
              endDate={endDate}
              allTime={allTime}
              isTypeClient={isTypeClient}
              setIsTypeClient={setIsTypeClient}
            ></StatsCard>
          ))}
          {loadingClientsStats == true && (
            <div className={classes.historyContainer}>
              <CircularProgress
                size={25}
                className={classes.circularProgressStyle}
              />
            </div>
          )}
        </div>
        <div
        //  style={{ marginLeft: '100px' }}
        >
          <div className='div-list-container '>
            {clientsStatsEIC.map((item, index) => (
              <StatsCard
                key={item.title + index}
                title={item.title}
                count={item.count}
                type={item.type}
                walletId={objectUser.wallet}
                startDate={startDate}
                endDate={endDate}
                allTime={allTime}
                className={item.className}
                isTypeClient={isTypeClient}
                setIsTypeClient={setIsTypeClient}
              ></StatsCard>
            ))}
          </div>

          <div className='div-list-container '>
            {clientsStats.map((item, index) => (
              <StatsCard
                key={item.title + index}
                title={item.title}
                count={item.count}
                type={item.type}
                walletId={objectUser.wallet}
                startDate={startDate}
                endDate={endDate}
                allTime={allTime}
                isTypeClient={isTypeClient}
                setIsTypeClient={setIsTypeClient}
              ></StatsCard>
            ))}
            <div
              style={{
                position: 'absolute',
              }}
            >
              <div className='lines-one horizontal-line-one'>
                <Lines />
              </div>
              <div className='lines-one '>
                <VerticalLines />
              </div>
              <div className='lines-two horizontal-line-two'>
                <Lines />
              </div>
              <div className='lines-two '>
                <VerticalLines />
              </div>
            </div>
          </div>
          <div className='div-list-container' id='stats-card'>
            {clientDeleted.map((item, index) => (
              <StatsCard
                style={{
                  order: index < 3 && window.innerWidth < 820 ? index : null,
                }}
                key={item.title + index}
                title={item.title}
                count={item.count}
                type={item.type}
                walletId={objectUser.wallet}
                startDate={startDate}
                endDate={endDate}
                allTime={allTime}
                icon={item.icon}
                isTypeClient={isTypeClient}
                setIsTypeClient={setIsTypeClient}
              ></StatsCard>
            ))}
          </div>
        </div>
      </Wrapper>
    </SideBar>
  );
};

const Wrapper = styled('div')<OffersStylesProps>`
  ${(props) => offersStyles(props)}
`;

const mapStateToProps = ({ stats, statsDetails }: RootState) => {
  const {
    couponEmails,
    flightEmails,
    cinemaEmails,
    hotelEmails,
    transitEmails,
    passwordResetEmails,
    eventsEmails,
    walletUpdateEmails,
    newAccountsEmails,
    offersEmails,
    totalEmails,
    sentMessagesForCoupons,
    sentMessagesForOffers,
    sentMessagesForEvents,
    sentMessagesForAccountCreation,
    creditsQuantity,
    deletedAccountsiOS,
    deletedAccountsAndroid,
    actifAccountsiOS,
    actifAccountsAndroid,
    actifAccounts,
    InactifAccounts,
    extrnalSource,
    internalSource,
    crmSource,
    totalCustomers,
    total,
    allTime,
    startDate,
    endDate,
    endDateError,
    loading,
    loadingClientsStats,
    loadingClientsSMSStats,
    loadingEmailStats,
    wallet,
  } = stats;

  const { type, typeDetails, loadingDetails } = statsDetails;

  return {
    couponEmails,
    flightEmails,
    passwordResetEmails,
    eventsEmails,
    walletUpdateEmails,
    newAccountsEmails,
    offersEmails,
    sentMessagesForCoupons,
    sentMessagesForOffers,
    sentMessagesForEvents,
    sentMessagesForAccountCreation,
    creditsQuantity,
    deletedAccountsiOS,
    deletedAccountsAndroid,
    actifAccountsiOS,
    actifAccountsAndroid,
    totalCustomers,
    actifAccounts,
    InactifAccounts,
    extrnalSource,
    internalSource,
    crmSource,
    total,
    allTime,
    startDate,
    endDate,
    endDateError,
    loading,
    loadingDetails,
    wallet,
    type,
    cinemaEmails,
    hotelEmails,
    transitEmails,
    typeDetails,
    totalEmails,
    loadingClientsStats,
    loadingClientsSMSStats,
    loadingEmailStats,
  };
};

const mapDispatchToProps = {
  getSMSstatistics,
  statsPropsChanged,
  StatsFilterSMS,
  StatsDetailsFilterSMS,
  getClientstatistics,
  getEmailStatistics,
};

export const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Statistics);
