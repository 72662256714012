import {
  getWalletListCustomers,
  getCustomerHistoryList,
  removeCustomer,
  getWalletListCustomersInCSV,
  addCustomersFromCSV,
  getCustomersStats,
  getWalletListInactiveCustomers,
  sendSelectedRowsInactiveCustomers,
} from '../apis/walletApi';
import {
  WALLET_GET_WALLET_DETAIL,
  WALLET_GET_WALLET_INACTIVE_DETAIL,
  WALLET_GET_WALLET_DETAIL_FAILED,
  WALLET_INITIAL_STATE,
  WALLET_GET_CUSTOMER_HISTORY,
  WALLET_LOADING_HISTORY,
  WALLET_PROPS_CHANGED,
  WALLET_FILTER_CUSTOMERS,
  AppThunk,
  WALLET_DELETE_CUSTOMER,
  SNACKBAR_ERROR,
  SNACKBAR_INFO,
  SNACKBAR_SUCCESS,
  ADD_CUSTOMER_TO_CUSTOMERS_LIST,
  WALLET_GET_WALLET_CUSTOMER_STATS,
  WALLET_FILTER_INACTIVES_CUSTOMERS,
  WALLET_INACTIVE_CUSTOMERS_PROPS_CHANGED,
} from './types';
import { Customer, InactiveCustomer } from '../apis/ApiTypes';
import {
  WalletChangedAction,
  SelectCustomerProps,
} from '../modules/Wallet/wallet.types';
import { getSettings } from '../apis/settingsApi';
import { saveAs } from 'file-saver';

export const walletPropsChanged = (
  prop: string,
  value: any
): WalletChangedAction => {
  return { type: WALLET_PROPS_CHANGED, prop, value };
};

export const deleteCustomer = (id: string, callback: () => void): AppThunk => (
  dispatch
) => {
  removeCustomer(id).then((res) => {
    if (res.data.status === 'success') {
      dispatch({
        type: WALLET_DELETE_CUSTOMER,
        id: id,
      });
      dispatch({
        type: SNACKBAR_SUCCESS,
        value: 'customer.success.deleted',
      });
      callback();
    } else {
      dispatch({
        type: SNACKBAR_ERROR,
        prop: 'wallet.error.occured',
      });
    }
  });
};

function getCurrentDate() {
  return new Date()
    .toLocaleString()
    .replaceAll(',', '-')
    .replaceAll('/', '-')
    .replaceAll(':', '-')
    .replaceAll(' ', '');
}

export const exportClientList = (
  walletId: any,
  walletName: string = 'Wallet'
): AppThunk => (dispatch) => {
  dispatch({
    type: WALLET_PROPS_CHANGED,
    prop: 'loadingExport',
    value: true,
  });

  getWalletListCustomersInCSV(walletId, (error: any, res: Blob) => {
    dispatch({
      type: WALLET_PROPS_CHANGED,
      prop: 'loadingExport',
      value: false,
    });

    if (error) {
      dispatch({
        type: SNACKBAR_ERROR,
        value: 'wallet.error.occured',
      });
      return;
    }

    if (res.size === 0) {
      dispatch({
        type: SNACKBAR_INFO,
        value: 'customer.info.emptyList',
      });
      return;
    }
    saveAs(res, `Clients of ${walletName.trim()}-${getCurrentDate()}.csv`);

    if (error) {
      let message = '';
      if (error && error.response.status === 404) {
        message = 'Wallet est introuvable';
      }

      dispatch({
        type: WALLET_GET_WALLET_DETAIL_FAILED,
        payload: message,
      });
    } else {
      dispatch({
        type: SNACKBAR_SUCCESS,
        value: 'customer.success.exported',
      });
    }
  });
};

export const importClientList = (
  walletId: any,
  file: any,
  sendNotifications: boolean = true
): AppThunk => (dispatch) => {
  dispatch({ type: WALLET_PROPS_CHANGED, prop: 'loadingImport', value: true });

  addCustomersFromCSV(
    walletId,
    file,
    sendNotifications,
    (error: any, res: any) => {
      dispatch({
        type: WALLET_PROPS_CHANGED,
        prop: 'loadingImport',
        value: false,
      });

      if (error) {
        let message = '';
        if (error && error?.response?.status === 404) {
          message = 'Wallet est introuvable';
        }

        dispatch({
          type: WALLET_GET_WALLET_DETAIL_FAILED,
          payload: message,
        });
      } else {
        if (res.addedClients > 0) {
          dispatch({
            type: SNACKBAR_SUCCESS,
            value: `La liste des clients a été importé avec succée | Importés: ${res.addedClients}. Dupliqués: ${res.duplicates}. Malformés: ${res.malformedClients}`,
          });
        } else {
          dispatch({
            type: SNACKBAR_INFO,
            value: `La liste ne contient que des doublons | Dupliqués: ${res.duplicates}. Malformés: ${res.malformedClients}`,
          });
        }

        dispatch({
          type: ADD_CUSTOMER_TO_CUSTOMERS_LIST,
          payload: res.data,
        });
      }
    }
  );
};

export const walletFilterCustomers = (
  select: SelectCustomerProps,
  search: string,
  customers: Array<Customer>
): AppThunk => (dispatch) => {
  let newCustomers: Array<Customer> = Object.assign([], customers);

  const result = newCustomers.filter((item: Customer) => {
    if (typeof item[select] === 'string') {
      return item[select].toLocaleLowerCase().match(search.toLocaleLowerCase());
    }
  });

  dispatch({
    type: WALLET_FILTER_CUSTOMERS,
    search,
    customers: result,
  });
};

export const getCustomersByWallet = (
  walletID: string,
  page: number | string,
  size: number | string,
  search: { firstName: string; lastName: string; email: string } | null = null
): AppThunk => (dispatch) => {
  /* 
  dispatch({
    type: WALLET_INITIAL_STATE,
  });*/
  getWalletListCustomers(
    walletID,
    page,
    size,
    search,
    (error: any, res: any) => {
      if (error) {
        let message = '';
        if (error && error.response.status === 404) {
          message = 'Wallet est introuvable';
        }

        dispatch({
          type: WALLET_GET_WALLET_DETAIL_FAILED,
          payload: message,
        });
      } else {
        // setInStorage('wallet', res.response.data[0]);

        getSettings(walletID).then((res: any) => {});

        dispatch({
          type: WALLET_GET_WALLET_DETAIL,
          payload: {
            ...res.response.data[0],
            customers:
              res.response.data[0] &&
              res.response.data[0].customers &&
              res.response.data[0].customers.map((item: Customer) => ({
                ...item,
                history: [],
                loading: false,
              })),
          },
          response: res,
        });
      }
    }
  );

  /*getCustomersStats(walletID, search, (error: any, res: any) => {
    dispatch({
      type: WALLET_GET_WALLET_CUSTOMER_STATS,
      payload: res.response.data,
    });
  });*/
};

export const getCustomerHistory = (customerID: string): AppThunk => (
  dispatch
) => {
  dispatch({
    type: WALLET_LOADING_HISTORY,
    customerID,
    payload: true,
  });
  getCustomerHistoryList(customerID).then(
    (res) => {
      dispatch({
        type: WALLET_GET_CUSTOMER_HISTORY,
        payload: res.response.data[0].history,
        customerID,
      });
    },
    (err) => {
      dispatch({
        type: WALLET_LOADING_HISTORY,
        customerID,
        payload: false,
      });
    }
  );
};

export const getInactiveCustomersByWallet = (
  walletID: string,
  search: {
    firstName?: string;
    lastName?: string;
    email?: string;
    creationDate?: string;
    page: number | string;
    size: number | string;
  } | null = null
): AppThunk => (dispatch) => {
  getWalletListInactiveCustomers(walletID, search, (error: any, res: any) => {
    dispatch({
      type: WALLET_GET_WALLET_INACTIVE_DETAIL,
      response: { status: 'OK', data: [] },
      //total: res.total,
    });
    if (error) {
      console.log(error);
    } else {
      dispatch({
        type: WALLET_GET_WALLET_INACTIVE_DETAIL,
        response: res,
        //total: res.total,
      });
    }
  });
};

export const walletFilterInactivesCustomers = (
  select: SelectCustomerProps,
  search: string,
  inactives: Array<InactiveCustomer>
): AppThunk => (dispatch) => {
  let newInactivesCustomers: Array<InactiveCustomer> = Object.assign(
    [],
    inactives
  );

  const result = newInactivesCustomers.filter((item: InactiveCustomer) => {
    if (typeof item[select] === 'string') {
      return item[select].toLocaleLowerCase().match(search.toLocaleLowerCase());
    }
  });

  dispatch({
    type: WALLET_FILTER_INACTIVES_CUSTOMERS,
    search,
    inactives: result,
  });
};

export const inactivesCustomersPropsChanged = (
  prop: string,
  value: any
): AppThunk => (dispatch) => {
  dispatch({
    type: WALLET_INACTIVE_CUSTOMERS_PROPS_CHANGED,
    prop,
    value,
  });
};

export const sendSelectedInactivesIds = (
  walletID: string,
  customerIds: string[],
  creationDate: any,
  allTime: boolean
): AppThunk => (dispatch) => {
  sendSelectedRowsInactiveCustomers(
    walletID,
    customerIds,
    creationDate,
    allTime
  ).then((res: any) => {
    if (res.status == 200) {
      dispatch({
        type: SNACKBAR_SUCCESS,
        value: 'sent.success',
      });
    } else {
      dispatch({
        type: SNACKBAR_ERROR,
        value: 'wallet.error.occured',
      });
    }
  });
};
export const displayAlert = (message: string): AppThunk => (dispatch) => {
  dispatch({
    type: SNACKBAR_INFO,
    value: message,
  });
};
