import axios from 'axios';
import { Currency } from './ApiTypes';
import { URL } from './config';

export const getCurrencies = () => {
  const requestURL = `${URL}/currencies`;
  return axios
    .get(requestURL)
    .then(({ data }: { data: Currency[] }) => {
      return data;
    })
    .catch((error) => {
      return error.response;
    });
};
