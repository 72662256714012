import styled from 'styled-components';

export const InputFileWrapper = styled.div<{ width?: number }>`
  display: flex;
  flex-direction: column;

  max-width: 280px;
  width: 280px;
  @media (max-width: 850px) {
    max-width: 230px;
    width: 230px;
  }
  /* flex: 1; */
  .input-label {
    box-sizing: border-box;
    font-size: 14px;
    //margin: 15px 0 10px 0;
    padding: 9px 30px 9px 30px;
    border-radius: 50px;
    width: fit-content;
    text-align: center;
    color: #fff;
    background: #ff5078;
    /* margin-top: -43px;
    margin-left: 19rem; */
    margin-top: 10px;
  }
  .logo-text {
    font-family: 'Alata', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #828282;
    margin: 5px;
    text-align: center;
  }
  .img-content {
    margin-top: 8px;
    height: 120px;
    width: ${(props) => (props.width ? props.width : 280)}px;
    box-sizing: border-box;
    border-width: 0px;
    border-radius: 20px;
    overflow: hidden;
    border: 3px dashed #ff5078;
    /* background: linear-gradient(
      140deg,
      #4814f40d -10%,
      #f75aa00d 50%,
      #feae6e0d 110%
    ); */
    @media (max-width: 850px) {
      width: 230px;
    }
  }
  img {
    object-fit: fill;
    width: 100%;
    height: 100%;
  }
  .text-error {
    /* align-self: flex-start; */
    font-size: 14px;
    color: #ed0723;
    margin-bottom: 8px;
    margin: 5px;
  }
`;
