import axios from 'axios';
import { URL } from './config';
import { EventsApiResponse } from './ApiTypes';

export const getEvent = (walletId: string, eventId: string) => {
  const requestURL = `${URL}/wallets/${walletId}/events/${eventId}`;

  return axios
    .get(requestURL)
    .then(({ data }: { data: any }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const createEvent = (walledId: string | null, event: any) => {
  const requestURL = `${URL}/wallets/${walledId}/events`;
  return axios
    .post(requestURL, event)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export const updateEvent = (
  walledId: string | null,
  eventId: string,
  event: any
): Promise<any> => {
  const requestURL = `${URL}/wallets/${walledId}/events/${eventId}`;
  return axios
    .put(requestURL, event)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export const removeEvent = (eventId: string, walletId: string) => {
  const requestURL = `${URL}/wallets/${walletId}/events/${eventId}`;

  return axios
    .delete(requestURL)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};
