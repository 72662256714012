import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='29'
      height='35'
      fill='none'
      viewBox='0 0 29 35'
    >
      <path
        fill='#000'
        fillRule='evenodd'
        d='M11.943.016C8.76.13 6.445.477 4.67 1.103c-1.354.478-2.24 1.008-3.028 1.813C.847 3.73.395 4.605.125 5.86.006 6.411 0 6.945 0 16.43c0 8.155.017 10.065.091 10.4.224 1.011.713 1.972 1.353 2.66l.327.351.024 1.848c.023 1.769.03 1.862.184 2.208.207.471.656.874 1.129 1.014.283.083.624.102 1.587.084 1.218-.023 1.229-.024 1.592-.228.375-.21.709-.605.857-1.014.047-.13.081-.66.084-1.33l.005-1.111h14.534l.005 1.112c.003.668.037 1.198.084 1.33.148.408.482.803.857 1.013.363.204.374.206 1.592.228.963.017 1.304 0 1.587-.084.473-.14.922-.543 1.13-1.014.152-.346.16-.44.183-2.209l.024-1.847.327-.351c.64-.688 1.129-1.649 1.353-2.66.074-.335.091-2.245.091-10.4 0-9.484-.006-10.018-.125-10.57-.27-1.254-.722-2.13-1.517-2.943C25.798 1.325 23.372.5 19.21.143c-1.123-.096-5.844-.179-7.267-.127zm13.44 11.95l-.016 4.6H3.633l-.017-4.6L3.6 7.364H25.4l-.017 4.602zM7.1 22.226c1.626.494 2.405 2.308 1.653 3.854-.818 1.682-3.014 2.057-4.314.736a2.885 2.885 0 01-.772-2.341c.228-1.642 1.875-2.72 3.433-2.248zm16.3-.004c.175.04.495.187.71.329 1.179.774 1.572 2.205.968 3.518a2.653 2.653 0 01-3.534 1.29 2.593 2.593 0 01-1.295-1.28c-.684-1.407-.113-3.056 1.285-3.706.613-.286 1.087-.324 1.865-.15z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}

export default Icon;
