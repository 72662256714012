import {
  HOTELS_PROPS_CHANGED,
  HOTELS_GET_LIST_HOTELS,
  HOTELS_INITIAL_STATE,
  HOTELS_DELETE_HOTEL,
  HOTELS_DELETE_HOTEL_LOADING,
  HOTELS_GET_SENT_HOTELS,
} from '../actions/types';

const INITIAL_STATE: any = {
  hotelsList: [],
  loading: true,
  hotelsFilterList: [],
  startDate: null,
  endDate: null,
  allTime: false,
  //error
  startDateError: '',
  endDateError: '',
  search: '',
  select: '',
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case HOTELS_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };

    case HOTELS_GET_LIST_HOTELS: {
      let newhotelsList = action.payload;
      if (state.hotelsList.length === action.payload.length) {
        action.payload.forEach((row: any, index: any) => {
          newhotelsList[index] = state.hotelsList[index];
          newhotelsList[index].hotelPictureUrl =
            state.hotelsList[index].hotelPictureUrl + `?${Math.random()}`;
        });
      }
      return {
        ...state,
        hotelsList: newhotelsList,
        hotelsFilterList: action.payload,
        loading: false,
      };
    }
    case HOTELS_INITIAL_STATE:
      return { ...INITIAL_STATE };
    case HOTELS_DELETE_HOTEL_LOADING: {
      return { ...state, loading: true };
    }
    case HOTELS_DELETE_HOTEL: {
      var newhotelsList = state.hotelsList.filter(function (item: {
        _id: any;
      }) {
        return item._id !== action.payload;
      });
      return { ...state, loading: false, hotelsList: newhotelsList };
    }
    case HOTELS_DELETE_HOTEL_LOADING: {
      return { ...state, loading: true };
    }

    case HOTELS_GET_SENT_HOTELS:
      return {
        ...state,
        hotelsList: action.payload,
        total: action.total,
        loading: false,
      };

    default:
      return state;
  }
};
