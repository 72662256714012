import {
  CINEMATICKETS_PROPS_CHANGED,
  CINEMATICKETS_GET_LIST_CINEMATICKETS,
  CINEMATICKETS_INITIAL_STATE,
  CINEMATICKETS_DELETE_CINEMATICKET,
  CINEMATICKETS_DELETE_CINEMATICKET_LOADING,
  CINEMATICKETS_GET_SENT_CINEMATICKETS,
} from '../actions/types';

const INITIAL_STATE: any = {
  cinemaTicketsList: [],
  loading: true,
  cinemaTicketsFilterList: [],
  startDate: null,
  endDate: null,
  allTime: false,
  //error
  startDateError: '',
  endDateError: '',
  search: '',
  total: 0,
  select: '',
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case CINEMATICKETS_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };

    case CINEMATICKETS_GET_LIST_CINEMATICKETS: {
      let newCinemaTicketsList = action.payload;
      if (state.cinemaTicketsList.length === action.payload.length) {
        action.payload.forEach((row: any, index: any) => {
          newCinemaTicketsList[index] = state.cinemaTicketsList[index];
          newCinemaTicketsList[index].moviePictureUrl =
            state.cinemaTicketsList[index].moviePictureUrl +
            `?${Math.random()}`;
        });
      }
      return {
        ...state,
        cinemaTicketsList: newCinemaTicketsList,
        cinemaTicketsFilterList: action.payload,
        loading: false,
      };
    }
    case CINEMATICKETS_INITIAL_STATE:
      return { ...INITIAL_STATE };
    case CINEMATICKETS_DELETE_CINEMATICKET_LOADING: {
      return { ...state, loading: true };
    }
    case CINEMATICKETS_DELETE_CINEMATICKET: {
      var newEventsList = state.cinemaTicketsList.filter(function (item: {
        _id: any;
      }) {
        return item._id !== action.payload;
      });
      return { ...state, loading: false, cinemaTicketsList: newEventsList };
    }
    case CINEMATICKETS_DELETE_CINEMATICKET_LOADING: {
      return { ...state, loading: true };
    }

    case CINEMATICKETS_GET_SENT_CINEMATICKETS:
      return {
        ...state,
        cinemaTicketsList: action.payload,
        total: action.total,
        loading: false,
      };

    default:
      return state;
  }
};
