import React, { useEffect, useState } from 'react';
import { URL_IMAGE } from '../../apis/config';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
//Style
import styled from 'styled-components';
import { walletDetailStyles, useStyles } from './wallet.styles';
//Material
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';

import CircularProgress from '@material-ui/core/CircularProgress';
//Components
import Table from '../../components/Table';
import SentDemosTable from '../../components/sentDemosTable';

import EmptyData from '../../components/EmptyData';
import WalletModal from '../../components/Modal';
import { useHistory } from 'react-router-dom';
import EventModal from '../../components/Modal';
import Modal from '../../components/Modal';

//Actions
import {
  getInactiveCustomersByWallet,
  getSentDemos,
  walletFilterInactivesCustomers,
  inactivesCustomersPropsChanged,
  sendSelectedInactivesIds,
  displayAlert,
} from '../../actions';

//Api
import InactiveClientsModal from '../../components/InactiveClientsModal';
import emptyState from '../../assets/images/emptyState.svg';
import { WalletDetailProps } from './wallet.types';
import { RootState } from '../../reducers';
import Button from '../../commons/Button';
import AddCustomer from '../AddCustomer';
import Text from '../../commons/Text';
import Input from '../../commons/Inputs/Input';
import TablePagination from '@material-ui/core/TablePagination';
import {
  Backdrop,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Menu,
  Typography,
} from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import IncativeCustomerTable from '../../components/InactiveCustomer';

import { KeyboardDatePicker } from '@material-ui/pickers';
import { IconButton, TextField, InputAdornment } from '@material-ui/core';
import Calendar from '../../icons/Calendar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { StyledCheckbox } from '../../commons/CheckBox/index';
import moment from 'moment';
import { SNACKBAR_SUCCESS } from '../../actions/types';

const InactiveCustomers: React.FC<WalletDetailProps> = (props) => {
  const intl = useIntl();
  const [relanceOpen, setRelanceOpen] = React.useState<boolean>(false);
  const [allTime, setAllTime] = React.useState<boolean>(false);
  const [creationDate, setCreationDate] = React.useState<any>(null);

  const classes = useStyles();
  const [relance_is_loading, setIsLoading] = React.useState<any>(null);
  const {
    wallet,
    loading,
    error,
    select,
    search,
    inactives,
    searchQuery,
    inactivesCustomersPropsChanged,
    page,
    size,
    total,
    firstName,
    lastName,
    email,
    selectedId,
    getInactiveCustomersByWallet,
    displayAlert,
  } = props;
  const walletId = props.match.params.walletId;

  useEffect(() => {
    props.getInactiveCustomersByWallet(walletId, {
      page: 0,
      size: 10,
    });
  }, []);

  const handleClickRow = (prop: string, value: any) => {
    props.inactivesCustomersPropsChanged(prop, value);
  };

  const HandleSendSelectedCustomerIds = () => {
    setRelanceOpen(false);

    if (props.sendAll) {
      props.sendSelectedInactivesIds(
        walletId,
        [],
        creationDate && moment(creationDate).format('YYYY-MM-DD'),
        allTime
      );
      inactivesCustomersPropsChanged('sendAll', false);
      return;
    }

    props.sendSelectedInactivesIds(
      walletId,
      props.selectedId,
      creationDate && moment(creationDate).format('YYYY-MM-DD'),
      allTime
    );
  };

  const handleClickSearchInactives = () => {
    props.walletFilterInactivesCustomers(select, search, []);
  };

  const handleChangeInactivesPage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    inactivesCustomersPropsChanged('loading', true);
    inactivesCustomersPropsChanged('page', newPage);
    inactivesCustomersPropsChanged('inactives', []);
    getInactiveCustomersByWallet(walletId, {
      page: newPage,
      size,
      firstName,
      lastName,
      email,
    });
  };

  const handleChangeRowsPerInactivesPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    inactivesCustomersPropsChanged('loading', true);
    inactivesCustomersPropsChanged('page', 0);
    inactivesCustomersPropsChanged('size', event.target.value);

    getInactiveCustomersByWallet('6149faef9f08400007e90948', {
      page,
      size: event.target.value,
      firstName,
      lastName,
      email,
    });
  };
  const handleDateChange = (prop: string) => (date: Date | null) => {
    getInactiveCustomersByWallet(walletId, {
      page,
      size,
      firstName,
      lastName,
      email,
      creationDate: moment(date).format('YYYY-MM-DD'),
    });
    setCreationDate(date);
    inactivesCustomersPropsChanged('loading', true);
  };
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAllTime(!allTime);
    if (allTime) {
      setCreationDate(null);
    }
    getInactiveCustomersByWallet(walletId, {
      page,
      size,
      firstName,
      lastName,
      email,
      creationDate: '',
    });

    inactivesCustomersPropsChanged('loading', true);
  };

  const renderBody = () => {
    return (
      <Wrapper>
        <div className='div-search'>
          <div className='div-search-inputs'>
            <div className='div-input-filter'>
              <FormControl className='filter-container' fullWidth>
                <Input
                  value={firstName}
                  background='#fff'
                  type='text'
                  name='search'
                  placeholder={intl.formatMessage({
                    id: 'wallet.button.rechercher',
                  })}
                  margin='10px 0'
                  className={classes.inputSearch}
                  onChange={(event) => {
                    inactivesCustomersPropsChanged('loading', true);

                    inactivesCustomersPropsChanged(
                      'firstName',
                      event.target.value
                    );
                    inactivesCustomersPropsChanged('page', 0);
                    getInactiveCustomersByWallet(walletId, {
                      page,
                      size,
                      firstName: event.target.value,
                    });
                  }}
                />

                <Button
                  onClick={handleClickSearchInactives}
                  width={120}
                  height={43}
                  background='#FF5078'
                  className={classes.buttonSearch}
                >
                  {intl.formatMessage({
                    id: 'wallet.button.rechercher',
                  })}
                </Button>
              </FormControl>
            </div>
            <div style={{ display: 'flex' }}>
              {!allTime ? (
                <div className='div-search-date'>
                  <KeyboardDatePicker
                    id='start-date'
                    label={intl.formatMessage({
                      id: 'offers.label.startDate',
                    })}
                    format='DD/MM/yyyy'
                    value={creationDate}
                    onChange={handleDateChange('startDate')}
                    inputVariant='outlined'
                    style={{ width: '250px' }}
                    disableFuture={true}
                    TextFieldComponent={({ InputProps, ...rest }) => (
                      <TextField
                        {...rest}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <Divider
                                className='divider'
                                orientation='vertical'
                                variant='inset'
                                light
                              />
                              <IconButton
                                onClick={
                                  (InputProps as any)?.endAdornment.props
                                    .children.props.onClick
                                }
                              >
                                <Calendar />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </div>
              ) : (
                <div className='div-search-date'></div>
              )}
              <FormControlLabel
                control={
                  <StyledCheckbox
                    checked={allTime}
                    onChange={handleCheckboxChange}
                    name='allTime'
                  />
                }
                label={intl.formatMessage({ id: 'offers.label.allTime' })}
              />
            </div>
          </div>
        </div>

        <div className='div-header'>
          <Text color='#404040' fontSize={24} lineHeight={36} fontWeight={600}>
            {intl.formatMessage({ id: 'inactives.label.title' })}
          </Text>
          <div style={{ display: 'flex' }}>
            <Button
              className={classes.buttonSendAll}
              onClick={() => {
                if (loading) {
                  displayAlert('inactives.label.loading');
                  return;
                }
                if (total === 0) {
                  displayAlert('inactives.label.emptyTable');
                  return;
                }
                setRelanceOpen(true);
                inactivesCustomersPropsChanged('sendAll', true);
              }}
            >
              {relance_is_loading ? (
                <CircularProgress size={20} color='inherit' />
              ) : (
                ''
              )}
              {intl.formatMessage({
                id: 'inactives.message.resendAll',
              })}
            </Button>
            <Button
              className={classes.relaunchButton}
              onClick={() => {
                if (loading) {
                  displayAlert('inactives.label.loading');
                  return;
                }
                if (selectedId.length === 0) {
                  displayAlert('inactives.label.noRowsSelected');
                  return;
                }
                setRelanceOpen(true);
              }}
            >
              {relance_is_loading ? (
                <CircularProgress size={20} color='inherit' />
              ) : (
                ''
              )}
              {intl.formatMessage({
                id: 'inactives.message.resend',
              })}
            </Button>
          </div>
        </div>

        <Modal open={relanceOpen} onClose={() => setRelanceOpen(!relanceOpen)}>
          <div className={classes.modalRelance}>
            <Text
              lineHeight={24}
              fontSize={16}
              color='#404040'
              style={{ textAlign: 'center' }}
            >
              {intl.formatMessage({
                id: props.sendAll
                  ? `Vous voulez envoyer des rappels à ${total} clients?`
                  : `Voulez-vous envoyer des rappels à vos clients selectionnés(${selectedId.length})?`,
              })}
              <br />
            </Text>
            {relanceOpen ? (
              <div
                style={{
                  display: 'flex',
                  gap: '25px',
                  marginTop: '30px',
                  justifyContent: 'center',
                }}
              >
                <Button
                  background='#fff'
                  color='#FF5078'
                  border='1px solid #FF5078'
                  onClick={() => {
                    setRelanceOpen(false);
                    inactivesCustomersPropsChanged('sendAll', false);
                  }}
                >
                  Non
                </Button>

                <Button onClick={HandleSendSelectedCustomerIds}>
                  {relance_is_loading ? (
                    <CircularProgress size={20} color='inherit' />
                  ) : (
                    ''
                  )}
                  Oui
                </Button>
              </div>
            ) : (
              ''
            )}
          </div>
        </Modal>
        <div className='div-list'>
          <EmptyData
            data={inactives}
            loading={loading}
            message='Client introuvable'
            image={emptyState}
          >
            <IncativeCustomerTable
              data={inactives}
              handleClickRow={handleClickRow}
              loading={loading}
              total={total}
              size={size}
            />
          </EmptyData>
          {inactives.length != 0 && (
            <div className={classes.tableBottom}>
              <div className='selectedRows'>
                <span>{selectedId.length || 0}</span>{' '}
                <span>
                  {intl.formatMessage({
                    id: 'inactives.message.selected',
                  })}
                </span>
              </div>

              <TablePagination
                style={{ display: 'inline' }}
                count={total}
                page={+page}
                onChangePage={handleChangeInactivesPage}
                rowsPerPage={+size}
                rowsPerPageOptions={[10, 25, 50, 100]}
                onChangeRowsPerPage={handleChangeRowsPerInactivesPage}
                labelRowsPerPage={'Lignes par page'}
              />
            </div>
          )}
        </div>
      </Wrapper>
    );
  };

  return <React.Fragment>{renderBody()}</React.Fragment>;
};

type WrapperProps = {
  background?: string;
};

const Wrapper = styled('div')<WrapperProps>`
  ${(props) => walletDetailStyles(props)}
`;

const mapStateToProps = ({ inactivesCustomer }: RootState) => {
  const {
    inactives,
    total,
    customerIds,
    searchQuery,
    selectedId,
    sendAll,
    page,
    size,
    firstName,
    lastName,
    email,
    loading,
  } = inactivesCustomer;
  return {
    page: page,
    size: size,
    total: total,
    firstName: firstName,
    lastName: lastName,
    email: email,
    loading: loading,
    customerIds,
    searchQuery,
    selectedId,
    sendAll,
    inactives,
  };
};

export const connector = connect(mapStateToProps, {
  getInactiveCustomersByWallet,
  getSentDemos,
  walletFilterInactivesCustomers,
  inactivesCustomersPropsChanged,
  sendSelectedInactivesIds,
  displayAlert,
});

export default connector(InactiveCustomers);
