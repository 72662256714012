import {
  createFerry,
  getFerry,
  updateFerry,
  editCustomerFerry,
} from '../apis/ferryApi';
import {
  FERRY_FORM_PROPS,
  FERRY_FORM_PROPS_CHANGED,
  AppThunk,
  FERRIES_ADD_FERRY_TO_LIST,
  FERRY_INITIAL_STATE,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
  FERRY_GET_FERRY_PROPS,
  FERRIES_GET_LIST_FERRIES,
  EDIT_FERRY_INITIAL_STATE,
  FERRY_PROPS_CHANGED,
  UPDATE_SENT_FERRIES_LIST,
  CUSTOMER_FERRY_FORM_PROPS_CHANGED,
  FERRIES_GET_CUSTOMERS_LIST,
  CUSTOMER_FERRY_FORM_PROPS,
} from './types';
import {
  CustomerFerryPropsChangedAction,
  FerryInitialStateAction,
  FerryPropsChangedAction,
  FerryState,
} from '../modules/Ship/ship.types';

import {
  validateFieldsFerry,
  newFerryObject,
} from '../modules/Ship/ship.utils';
// import {
//   validateCustomerFieldsFerry,
//   newCustomerFerryObject,
// } from '../modules/SentTransits/EditCustomerFerry/ferry.utils';
import { URL_IMAGE } from '../apis/config';
import {
  getFerriesChildrenList,
  getFerriesCustomerList,
  getFerriesList,
} from '../apis/ferriesApi';
import { TransitsApiResponse } from '../apis/ApiTypes';
import { validateCustomerFieldsFerry } from '../modules/SentTransits/EditCustomerFerry/ferry.utils';
import moment, { Moment } from 'moment';

export const ferryPropsChanged = (
  prop: string,
  value: any
): FerryPropsChangedAction => {
  return { type: FERRY_FORM_PROPS_CHANGED, prop, value };
};

export const addFerry = (
  walletId: string | null,
  props: FerryState,
  callback: () => void
): AppThunk => (dispatch) => {
  const { newState, valid } = validateFieldsFerry(props);
  dispatch({ type: FERRY_FORM_PROPS, newState });
  const newFerry = newFerryObject(newState);

  if (valid) {
    dispatch({
      type: FERRY_FORM_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });

    createFerry(walletId, newFerry).then((res: any) => {
      if (res.status == 200) {
        dispatch({
          type: FERRIES_ADD_FERRY_TO_LIST,
          payload: res.data,
        });
        dispatch({
          type: SNACKBAR_SUCCESS,
          value: 'transit.success.added',
        });
        callback();
      } else {
        dispatch({
          type: SNACKBAR_ERROR,
          value: 'wallet.error.occured',
        });
        dispatch({
          type: FERRY_FORM_PROPS_CHANGED,
          prop: 'loading',
          value: false,
        });
      }
    });
  }
};

export const getFerrybyId = (walletId: string, ferryId: string): AppThunk => (
  dispatch
) => {
  getFerry(walletId, ferryId).then((res: any) => {
    const ferry = res.response.data;
    dispatch({
      type: FERRY_GET_FERRY_PROPS,
      payload: {
        ...ferry,
      },
    });
  });
};

export const editFerryConfirm = (
  walletId: string | null,
  ferryId: string | null,
  props: FerryState,
  callback: () => void
): AppThunk => (dispatch) => {
  const { newState, valid } = validateFieldsFerry(props);
  dispatch({ type: FERRY_FORM_PROPS, newState });
  const newFerry = newFerryObject(newState);

  if (valid) {
    dispatch({
      type: FERRY_FORM_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });

    updateFerry(walletId, ferryId, newFerry)
      .then((res: any) => {
        if (res.status === 200) {
          dispatch({
            type: SNACKBAR_SUCCESS,
            value: 'transit.success.updated',
          });

          callback();
        } else if (res.status === 400) {
          dispatch({
            type: FERRY_FORM_PROPS_CHANGED,
            prop: 'messageError',
            value: 'coupon.error.datesUnavailable',
          });
          dispatch({
            type: SNACKBAR_ERROR,
            value: 'wallet.error.occured',
          });
        }
        dispatch({
          type: FERRY_FORM_PROPS_CHANGED,
          prop: 'loading',
          value: false,
        });
      })
      .finally(() => {
        getFerriesList(walletId as any).then((res: Array<any>) => {
          dispatch({
            type: FERRIES_GET_LIST_FERRIES,
            payload: res,
          });
        });
      });
  }
};

export const ferryInitialState = (): FerryInitialStateAction => {
  return { type: FERRY_INITIAL_STATE };
};

export const editFerryInitialState = (ferry: any): any => {
  return {
    type: EDIT_FERRY_INITIAL_STATE,
    prop: 'payload',
    value: {
      ...ferry,
      image: URL_IMAGE + '/' + ferry.transitPictureUrl,
    },
  };
};
export const editFerryCustomerConfirm = (
  walletId: string | null,
  ferryId: string | null,
  customerId: string | null,
  props: FerryState,
  callback: () => void
): AppThunk => (dispatch) => {
  const { newState, valid } = validateCustomerFieldsFerry(props);
  dispatch({ type: CUSTOMER_FERRY_FORM_PROPS, newState });

  const { passengerType, tripType, arrivalDate, departureDate } = newState;

  // const departureDate = moment().format('YYYY-MM-DD').slice(0, 10);
  // const arrivalDate = moment(arrivalDate).format('YYYY-MM-DD').slice(0, 10);
  const ferryObject = {
    passengerType,
    tripType,
    arrivalDate: arrivalDate.format('YYYY-MM-DD').slice(0, 10),
    departureDate: moment(departureDate).format('YYYY-MM-DD'),
    customerId,
  };

  if (valid) {
    dispatch({
      type: FERRY_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });

    editCustomerFerry(walletId, ferryId, ferryObject).then((res: any) => {
      if (res.status === 200) {
        dispatch({
          type: SNACKBAR_SUCCESS,
          value: 'transit.success.updated',
        });

        callback();
      } else if (res.status === 400) {
        dispatch({
          type: FERRY_PROPS_CHANGED,
          prop: 'messageError',
          value: 'coupon.error.datesUnavailable',
        });
        dispatch({
          type: SNACKBAR_ERROR,
          value: 'wallet.error.occured',
        });
      }
      dispatch({
        type: FERRY_PROPS_CHANGED,
        prop: 'loading',
        value: false,
      });
    });
  }
};
export const customerFerryPropsChanged = (
  prop: string,
  value: any
): CustomerFerryPropsChangedAction => {
  return { type: CUSTOMER_FERRY_FORM_PROPS_CHANGED, prop, value };
};
