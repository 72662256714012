import { StatisticsState } from './stats.types';
import moment from 'moment';

export const validateFieldsStats = (state: any) => {
  let newState: StatisticsState = Object.assign({}, state);

  if (!newState.startDate) {
    newState.startDateError = 'offer.error.startDateRequired';
  } else if (newState.startDate && !moment(newState.startDate)?.isValid()) {
    newState.startDateError = 'offer.error.startDateInvalid';
  } else {
    newState.startDateError = '';
  }

  if (!newState.endDate) {
    newState.endDateError = 'offer.error.endDateRequired';
  } else if (newState.endDate && !moment(newState.endDate)?.isValid()) {
    newState.endDateError = 'offer.error.endDateInvalid';
  } else {
    newState.endDateError = '';
  }

  if (newState.startDate && newState.endDate) {
    if (moment(newState.startDate)?.isAfter(newState.endDate, 'day')) {
      newState.endDateError = 'offer.error.endDateBeforeStartDate';
    } else {
      newState.endDateError = '';
    }
  }

  let valid = false;
  if (newState.startDateError === '' && newState.endDateError === '') {
    valid = true;
  }

  return { newState, valid };
};

export const newOfferObject = (offer: any) => {
  let formData = new FormData();

  if (offer.image) {
    formData.append('startDate', moment(offer.startDate).format('YYYY-MM-DD'));
    formData.append('endDate', moment(offer.endDate).format('YYYY-MM-DD'));

    return formData;
  }

  return null;
};
