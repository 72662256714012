import {
  WALLET_GET_WALLET_INACTIVE_DETAIL,
  WALLET_INACTIVE_CUSTOMERS_PROPS_CHANGED,
} from '../actions/types';
import {
  WalletDetailActionTypes,
  WalletInactivesDetailState,
} from '../modules/Wallet/wallet.types';

const INITIAL_STATE: WalletInactivesDetailState = {
  firstName: '',
  lastName: '',
  email: '',
  searchQuery: '',
  page: 0,
  size: 10,
  loading: true,
  inactives: [],
  customerIds: [],
  selectedId: [],
  sendAll: false,
  total: 0,
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case WALLET_GET_WALLET_INACTIVE_DETAIL:
      return {
        ...state,
        inactives: action.response.data,
        total: action.response.total || 0,
        loading: false,
      };
    case WALLET_INACTIVE_CUSTOMERS_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };

    default:
      return state;
  }
};
