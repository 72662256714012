import moment from 'moment';
import {
  CUSTOMER_BUS_FORM_PROPS_CHANGED,
  EDIT_BUS_INITIAL_STATE,
  BUS_FORM_PROPS,
  BUS_FORM_PROPS_CHANGED,
  BUS_GET_BUS_PROPS,
  BUS_INITIAL_STATE,
} from '../actions/types';
import { BusActionTypes, BusState } from '../modules/Bus/bus.types';

const INITIAL_STATE: Partial<BusState> = {
  loading: false,
  passengerType: '',
  tripType: '',
  departureDate: moment(),
  arrivalDate: moment(),
  customerId: '',
  busId: '',
  //errors
  passengerTypeError: '',
  tripTypeError: '',
  departureDateError: '',
  arrivalDateError: '',
};

export default (state = INITIAL_STATE, action: BusActionTypes) => {
  switch (action.type) {
    case CUSTOMER_BUS_FORM_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };

    default:
      return state;
  }
};
