import React from 'react';

function Icon({
  width = '28',
  height = '28',
  fill = '#C7C7C7',
}: // className,
{
  // className?: string;
  width?: string;
  height?: string;
  fill?: string;
}) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      fill='none'
      viewBox='0 0 24 20'
      // className='MuiSvgIcon-root'
    >
      <path
        fill={fill}
        fillRule='evenodd'
        d='M23.42 8.296a.858.858 0 01-.599.24c-.835 0-1.512.658-1.512 1.459 0 .805.67 1.46 1.496 1.468.466.005.862.32.862.773v2.812c0 2.366-1.974 4.285-4.411 4.285h-3.679c-.38 0-.688-.299-.688-.668v-2.368c0-.46-.372-.822-.846-.822a.833.833 0 00-.846.822v2.368c0 .37-.308.668-.688.668H4.746c-2.426 0-4.412-1.918-4.412-4.285v-2.812c0-.453.396-.768.862-.773.828-.008 1.496-.663 1.496-1.468 0-.779-.654-1.37-1.512-1.37a.858.858 0 01-.598-.242.81.81 0 01-.248-.58v-2.84C.334 2.6 2.324.667 4.757.667h7.752c.38 0 .688.299.688.668v2.806c0 .45.383.822.846.822a.833.833 0 00.846-.822V1.335c0-.37.308-.668.688-.668h3.679c2.437 0 4.411 1.918 4.411 4.285v2.763a.81.81 0 01-.248.58zm-9.377 5.053a.833.833 0 00.846-.822V8.142a.833.833 0 00-.846-.822c-.463 0-.846.373-.846.822v4.385c0 .449.383.822.846.822z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}

export default Icon;
