import { ColorProps, HotelState } from './hotel.types';
import { isEmpty } from '../../utils/validations';
import moment from 'moment';

export const validateFieldsHotel = (state: HotelState) => {
  let newState: HotelState = Object.assign({}, state);

  if (!newState.number) {
    newState.numberError = 'ship.error.numberRequired';
  } else if (newState.number && !newState.number) {
    newState.numberError = 'ship.error.numberInvalid';
  } else {
    newState.numberError = '';
  }

  if (!newState.labelColor) {
    newState.labelColorError = 'ship.error.labelColorRequired';
  } else {
    newState.labelColorError = '';
  }

  if (!newState.backgroundColor) {
    newState.backgroundColorError = 'ship.error.backgroundColorRequired';
  } else {
    newState.backgroundColorError = '';
  }

  if (!newState.foregroundColor) {
    newState.foregroundColorError = 'ship.error.foregroundColorRequired';
  } else {
    newState.foregroundColorError = '';
  }

  if (!newState.name) {
    newState.nameError = 'ship.error.nameRequired';
  } else if (newState.name && !newState.name) {
    newState.nameError = 'ship.error.nameInvalid';
  } else {
    newState.nameError = '';
  }
  if (!newState.type) {
    newState.typeError = 'ship.error.typeRequired';
  } else if (newState.type && !newState.type) {
    newState.typeError = 'ship.error.typeInvalid';
  } else {
    newState.typeError = '';
  }
  if (!newState.address) {
    newState.addressError = 'ship.error.addressRequired';
  } else if (newState.address && !newState.address) {
    newState.addressError = 'ship.error.addressInvalid';
  } else {
    newState.addressError = '';
  }
  if (!newState.contactPhone) {
    newState.contactPhoneError = 'ship.error.contactPhoneRequired';
  } else if (newState.contactPhone && !newState.contactPhone) {
    newState.contactPhoneError = 'ship.error.contactPhoneInvalid';
  } else {
    newState.contactPhoneError = '';
  }

  if (!newState.image) {
    newState.imageError = 'ship.error.imageRequired';
  } else {
    newState.imageError = '';
  }

  if (isEmpty(newState.terms)) {
    newState.termsError = 'ship.error.termsRequired';
  } else {
    newState.termsError = '';
  }
  if (!newState.email) {
    newState.emailError = 'ship.error.emailRequired';
  } else {
    newState.emailError = '';
  }
  if (!newState.website) {
    newState.websiteError = 'ship.error.websiteRequired';
  } else {
    newState.websiteError = '';
  }

  let valid = false;
  if (
    newState.labelColorError === '' &&
    newState.backgroundColorError === '' &&
    newState.foregroundColorError === '' &&
    newState.imageError === '' &&
    newState.nameError === '' &&
    newState.typeError === '' &&
    newState.numberError === '' &&
    newState.contactPhoneError === '' &&
    newState.emailError === '' &&
    newState.websiteError === '' &&
    newState.termsError === '' &&
    newState.addressError === ''
  ) {
    valid = true;
  }

  return { newState, valid };
};

export const newHotelObject = (hotel: HotelState) => {
  let formData = new FormData();
  if (hotel.image) {
    formData.append('labelColor', objectColorJSON(hotel.labelColor));
    formData.append('backgroundColor', objectColorJSON(hotel.backgroundColor));
    formData.append('foregroundColor', objectColorJSON(hotel.foregroundColor));
    formData.append('strip', hotel.image);
    formData.append('name', hotel.name);
    formData.append('type', hotel.type);
    formData.append('number', hotel.number);
    formData.append('website', hotel.website);
    formData.append('email', hotel.email);
    formData.append('contactPhone', hotel.contactPhone);
    formData.append('terms', hotel.terms);
    formData.append('barcodeType', hotel.barcodeType);

    formData.append('address', hotel.address);
    formData.append(
      'locations',
      JSON.stringify([
        {
          longitude: 0,
          latitude: 0,
        },
      ])
    );
    return formData;
  }

  return null;
};

export const objectColorJSON = (objectColor: ColorProps) => {
  let newObjectColor = {
    hex: objectColor.hex,
    rgb:
      typeof objectColor.rgb.r === 'number' &&
      typeof objectColor.rgb.g === 'number' &&
      typeof objectColor.rgb.b === 'number'
        ? `rgb(${objectColor.rgb.r},${objectColor.rgb.g},${objectColor.rgb.b})`
        : objectColor.rgb,
  };

  const newObjectColorJSON = JSON.stringify(newObjectColor);
  return newObjectColorJSON;
};

export const validateCustomerFieldsHotel = (state: any) => {
  let newState: any = Object.assign({}, state);

  if (!newState.createdAt) {
    newState.createdAtError = 'hotel.error.createdAtRequired';
  } else if (newState.createdAt && !newState.createdAt.isValid()) {
    newState.createdAtError = 'hotel.error.createdAtInvalid';
  } else {
    newState.createdAtError = '';
  }
  if (!newState.checkin) {
    newState.checkinError = 'hotel.error.checkinRequired';
  } else if (newState.checkin && !newState.checkin.isValid()) {
    newState.checkinError = 'hotel.error.checkinInvalid';
  } else {
    newState.checkinError = '';
  }
  if (!newState.checkout) {
    newState.checkoutError = 'hotel.error.checkoutRequired';
  } else if (newState.checkout && !newState.checkout.isValid()) {
    newState.checkoutError = 'hotel.error.checkoutInvalid';
  } else {
    newState.checkoutError = '';
  }

  let valid = false;
  if (
    newState.createdAtError === '' &&
    newState.checkinError === '' &&
    newState.checkoutError === ''
  ) {
    valid = true;
  }
  return { newState, valid };
};

export const newCustomerHotelObject = (hotel: any) => {
  let formData = new FormData();

  let createdAt = `${moment(hotel.createdAt).format(
    'YYYY-MM-DD'
  )}T22:59:59.000+01:00`;
  let checkin = `${moment(hotel.checkin).format(
    'YYYY-MM-DD'
  )}T22:59:59.000+01:00`;
  let checkout = `${moment(hotel.checkout).format(
    'YYYY-MM-DD'
  )}T22:59:59.000+01:00`;

  formData.append('createdAt', createdAt);
  formData.append('checkin', hotel.checkin);
  formData.append('checkout', hotel.checkout);
  formData.append('customerId', hotel.customerId);

  return {
    formData,
    objectData: {
      createdAt: hotel.createdAt,
      checkin: hotel.checkin,
      checkout: hotel.checkout,
      customerId: hotel.customerId,
    },
  };
};
