import 'date-fns';
import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
//Style
import styled from 'styled-components';
import { tramStyles } from './tram.styles';
//Material
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
//Components
import InputLabel from '../../../commons/Inputs/InputLabel';
//Commomns
import ColorPicker from '../../../commons/ColorPicker';

import Button from '../../../commons/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '../../../commons/Inputs/Input';
import Select from '@material-ui/core/Select';
//Actions
import {
  customerTramPropsChanged,
  tramInitialState,
  editTramCustomerConfirm,
} from '../../../actions';
//Reducers
import { RootState } from '../../../reducers';
//Type

const EditCustomerTram: React.FC<any> = (props) => {
  const intl = useIntl();
  const {
    tramId,
    customerId,
    walletId,
    loading,
    onSaveChanged,
    onCancel,
    passengerType,
    passengerTypeError,
    tripType,
    tripTypeError,
    departureDate,
    departureDateError,
    arrivalDate,
    arrivalDateError,
    createdAt,
    createdAtError,
  } = props;
  /*
  React.useEffect(() => {
    props.couponInitialState();
  }, []);
*/
  const handleClickConfirm = () => {
    props.editTramCustomerConfirm(walletId, tramId, customerId, props, () =>
      onSaveChanged(window.location.reload())
    );
  };
  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    const name = event.target.name;
    if (value == '') {
      props.customerTramPropsChanged(name, value);
    }

    props.customerTramPropsChanged(name, value);
  };
  const handleDateChange = (prop: string) => (date: Date | null) => {
    props.customerTramPropsChanged(prop, date);
  };
  const getFormatedMessage = (prop: any) => {
    return props[prop] !== ''
      ? intl.formatMessage({ id: props[prop] })
      : props[prop];
  };

  return (
    <Wrapper>
      <span className='title'>Modifier Transit</span>
      <div className='div-container' style={{ marginLeft: '15px' }}>
        <div className='div-content' style={{ marginLeft: '30px' }}>
          <KeyboardDatePicker
            required
            margin='normal'
            id='start-date'
            label={intl.formatMessage({ id: 'tram.label.departureDate' })}
            format='YYYY-MM-DD'
            value={departureDate}
            onChange={handleDateChange('departureDate')}
            error={departureDateError !== ''}
            helperText={getFormatedMessage('departureDateError')}
            disablePast
            fullWidth
            inputVariant='outlined'
          />
          <KeyboardDatePicker
            required
            margin='normal'
            id='end-date'
            label={intl.formatMessage({ id: 'tram.label.arrivalDate' })}
            format='YYYY-MM-DD'
            value={arrivalDate}
            onChange={handleDateChange('arrivalDate')}
            error={arrivalDateError !== ''}
            helperText={getFormatedMessage('arrivalDateError')}
            disablePast
            fullWidth
            inputVariant='outlined'
          />
          <InputLabel
            label={intl.formatMessage({ id: 'tram.label.passengerType' })}
            placeholder='type de passager'
            name='passengerType'
            value={passengerType}
            error={getFormatedMessage('passengerTypeError')}
            onChange={(e) => handleEventChange(e)}
          />
          <InputLabel
            label={intl.formatMessage({ id: 'tram.label.tripType' })}
            placeholder='type de voyage'
            name='tripType'
            value={tripType}
            error={getFormatedMessage('tripTypeError')}
            onChange={(e) => handleEventChange(e)}
          />
        </div>
        <div
          className='section wireframe-section'
          style={{ marginLeft: 25, marginRight: 25, maxWidth: 356 }}
        ></div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
        }}
      >
        <Button onClick={onCancel}>
          {intl.formatMessage({ id: 'settings.button.cancel' })}
        </Button>
        <Button onClick={handleClickConfirm}>
          {loading ? (
            <CircularProgress size={20} color='inherit' />
          ) : (
            'Sauvgarder'
          )}
        </Button>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${(props) => tramStyles(props)}
`;

const mapStateToProps = ({ customertram }: RootState) => {
  const {
    labelColor,
    backgroundColor,
    foregroundColor,
    image,
    loading,
    labelColorError,
    backgroundColorError,
    foregroundColorError,
    imageError,
    passengerType,
    passengerTypeError,
    tripType,
    tripTypeError,
    departureDate,
    departureDateError,
    arrivalDate,
    arrivalDateError,
  } = customertram;
  return {
    labelColor,
    backgroundColor,
    foregroundColor,
    image,
    loading,
    labelColorError,
    backgroundColorError,
    foregroundColorError,
    imageError,
    passengerType,
    passengerTypeError,
    tripType,
    tripTypeError,
    departureDate,
    departureDateError,
    arrivalDate,
    arrivalDateError,
  };
};

export const connector = connect(mapStateToProps, {
  customerTramPropsChanged,
  tramInitialState,
  editTramCustomerConfirm,
});

export default connector(EditCustomerTram);
