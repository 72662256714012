import axios from 'axios';
import { URL } from './config';
import { EventsApiResponse } from './ApiTypes';

export const getHotel = (walletId: string, HotelId: string) => {
  const requestURL = `${URL}/wallets/${walletId}/hotels/${HotelId}`;

  return axios
    .get(requestURL)
    .then(({ data }: { data: any }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const createHotel = (walledId: string | null, hotel: any) => {
  const requestURL = `${URL}/wallets/${walledId}/hotels`;
  return axios
    .post(requestURL, hotel)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};
export const editHotel = (
  walletId: string | null,
  hotelId: string | null,
  hotel: any
) => {
  const requestURL = `${URL}/wallets/${walletId}/hotels/${hotelId}/template`;
  return axios
    .put(requestURL, hotel)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};
export const removeHotel = (hotelId: string, walletId: string) => {
  const requestURL = `${URL}/wallets/${walletId}/hotels/${hotelId}`;

  return axios
    .delete(requestURL)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export const editCustomerHotel = (
  walletId: string | null,
  hotelId: string | null,
  hotel: any
) => {
  const requestURL = `${URL}/wallets/${walletId}/transits/${hotelId}/customer`;
  return axios
    .put(requestURL, hotel)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};
