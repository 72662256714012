import React, { useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import QRCode from 'qrcode.react';
import TextEditor from '../../components/TextEditor';
import { ic_warning } from 'react-icons-kit/md/ic_warning';
import {
  isNumber,
  getNatural,
  getDecimal,
  isUrl,
  isEmail,
  isPhoneNumber,
} from '../../utils/validations';
import { getImageErrorMessage } from '../../utils/common';

//Style
import styled from 'styled-components';
import { createEventStyles } from './createEvent.styles';
//Material
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
//Components
import InputLabel from '../../commons/Inputs/InputLabel';
//Commomns
import ColorPicker from '../../commons/ColorPicker';
import Button from '../../commons/Button';

import MomentUtils from '@date-io/moment';
import moment from 'moment';
//Actions
import { eventPropsChanged, addEvent, eventInitialState } from '../../actions';
//Reducers
import { RootState } from '../../reducers';
import cover from '../../assets/images/lg-thumbnail.png';
import {
  EventFormProps,
  EventStateError,
  EventDateState,
} from './createEvent.types';
import InputFile from '../../commons/Inputs/InputFile';
import {
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
  Switch,
  Typography,
  Divider,
} from '@material-ui/core';
import { getDataFromCache } from '../../utils/cache';
import MapComponent from '../../components/MapComponent';

import Icon from 'react-icons-kit';
import EventWireframeAndroid from '../../components/EventWireframeAndroid';
import EventWireframeIPhone from '../../components/EventWireframeIPhone';
import { Prompt } from 'react-router';
import Calendar from '../../icons/Calendar';
import { IconButton, TextField, InputAdornment } from '@material-ui/core';
//onDateChange?: (date: Moment | null) => void;
import maxChars from '../../utils/fieldsMaxLength';
import BarcodeTypeSelection from '../../components/barcodeSelection';
import SecurityOptions from '../../components/SecurityOptions';

const CreateEvent: React.FC<EventFormProps> = (props) => {
  const intl = useIntl();
  const {
    address,
    contactPhone,
    date,
    email,
    strip,
    backgroundColor,
    foregroundColor,
    labelColor,
    locationName,
    emailSubscriptionMessage,
    barcodeType,
    enableOneTimeUse,
    disableIOSPassSharing,
    disableMultipleHolders,
    enableSecurityAnimation,
    enableRotatingBarcode,
    disableEmailSharing,
    name,
    price,
    terms,
    website,
    loading,
    locations,
    imageError,
    nameError,
    locationNameError,
    addressError,
    openingHourError,
    closingHourError,
    doorOpeningHourError,
    priceError,
    termsError,
    contactPhoneError,
    emailError,
    websiteError,
    labelColorError,
    backgroundColorError,
    foregroundColorError,
    emailSubscriptionMessageError,
    onSaveChanged,
  } = props;
  const objectUser = getDataFromCache('user');
  const myRef = useRef(null);
  const eventInfoRef: any = useRef(null);
  const contactInfoRef: any = useRef(null);
  const locationInfoRef: any = useRef(null);
  const styleRef: any = useRef(null);

  const getErrorsLocation = () => {
    const eventInfoSectionHasErrors = [priceError, nameError].some(
      (element) => element != ''
    );
    const locationInfoSectionHasErrors = [
      locationNameError,
      addressError,
      openingHourError,
      closingHourError,
      doorOpeningHourError,
    ].some((element) => element != '');

    const contactInfoSectionHasErrors = [
      termsError,
      contactPhoneError,
      emailError,
      websiteError,
      emailSubscriptionMessageError,
    ].some((element) => element != '');

    const styleSectionError = [
      labelColorError,
      backgroundColorError,
      foregroundColorError,
      imageError,
    ].some((element) => element != '');

    if (eventInfoSectionHasErrors) return eventInfoRef;
    if (styleSectionError) return styleRef;
    if (locationInfoSectionHasErrors) return locationInfoRef;
    if (contactInfoSectionHasErrors) return contactInfoRef;

    return null;
  };
  const executeScroll = () => {
    let elemPosition = getErrorsLocation();

    if (elemPosition && elemPosition.current) {
      elemPosition.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'end',
      });
    }
  };
  const [buttonClicked, setButtonClicked] = React.useState<boolean>(false);

  useEffect(() => {
    executeScroll();
  }, [buttonClicked]);

  React.useEffect(() => {
    props.eventInitialState();
    /* eslint-disable-next-line */
    //   myRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  React.useEffect(() => {
    var options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };

    function success(pos: any) {
      var crd = pos.coords;
      handleLocationChange(
        { lat: crd.latitude, lng: crd.longitude },
        'locations',
        'latLng'
      );
    }

    function error(err: any) {
      console.warn(`ERROR(${err.code}): ${err.message}`);
    }

    navigator.geolocation.getCurrentPosition(success, error, options);
  }, []);
  const [hasStateChanged, setHasStateChange] = React.useState<boolean>(false);
  const [switchPreview, setSwitchPreview] = React.useState<string>('android');
  const [QRcode, setQRcode] = React.useState<any>({ state: true, value: '' });
  const handlePreviewChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSwitchPreview((event.target as HTMLInputElement).value);
  };

  const handleDateChange = (prop: string) => (dateRes: Date | null) => {
    setHasStateChange(true);
    let dateClone = { ...date };
    switch (prop) {
      case 'openingHour': {
        dateClone.openingHour = moment(dateRes);
        break;
      }
      case 'closingHour': {
        dateClone.closingHour = moment(dateRes);
        break;
      }
      case 'doorOpeningHour': {
        dateClone.doorOpeningHour = moment(dateRes);
        break;
      }
      default:
        break;
    }
    props.eventPropsChanged('date', dateClone);
  };

  const handleColorChange = (color: any, prop: string) => {
    setHasStateChange(true);

    props.eventPropsChanged(prop, color);
  };

  const handleFileChange = (event: any, prop: string) => {
    setHasStateChange(true);

    const file = event.target.files[0];
    if (!['image/jpeg', 'image/png'].includes(file.type)) {
      props.eventPropsChanged(`${prop}Error`, getImageErrorMessage(file));
      return;
    }
    props.eventPropsChanged(prop, file);
  };

  const isPriceValid = (value: string): boolean => {
    if (!isNumber(value)) return false;
    if (getNatural(value).toString().length > 5) {
      props.eventPropsChanged(
        'priceError',
        'Vous avez dépassé le nombre maximum avant le point/virgule'
      );
      return false;
    }

    if (getDecimal(value).toString().length > 2) {
      props.eventPropsChanged(
        'priceError',
        'Vous avez dépassé le nombre maximum aprés le point/virgule'
      );
      return false;
    }
    return true;
  };

  const areTextAreasValid = (fieldName: string, value: string): boolean => {
    if (value.length > (maxChars.event[fieldName] || maxChars[fieldName])) {
      props.eventPropsChanged(
        `${fieldName}Error`,
        'La longueur maximale a été atteinte'
      );
      return false;
    }
    return true;
  };

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHasStateChange(true);
    const textAreas = ['terms', 'emailSubscriptionMessage'];

    const inputFeilds = [
      'contactPhone',
      'date',
      'email',
      'locationName',
      'name',
      'website',
      'address',
    ];
    const value = event.target.value;
    const fieldName = event.target.name;
    props.eventPropsChanged(`${fieldName}Error`, '');

    if (fieldName === 'price') {
      if (!isPriceValid(value)) return;
    }

    if (fieldName === 'name') {
      if (value.length > 25) {
        return;
      }
      setemailMessageEditorValue(
        `<p>Vous étes invités à l'événement ${value}.</p> <p>Pour avoir plus de détails veuillez cliquer sur le bouton ci-dessous.</p>`
      );
    }

    if (!areTextAreasValid(fieldName, value)) return;

    props.eventPropsChanged(fieldName, value);
  };

  const handleClickConfirm = () => {
    setHasStateChange(false);
    props.addEvent(objectUser.wallet, props, () => history.push('/event'));
  };

  const [emailMessageEditorValue, setemailMessageEditorValue] = React.useState<
    string
  >(
    `<p>Vous étes invités à l'événement.</p> <p>Pour avoir plus de détails veuillez cliquer sur le bouton ci-dessous.</p>`
  );

  const handleEmailMessageChange = (value: string) => {
    setHasStateChange(true);

    props.eventPropsChanged('emailSubscriptionMessage', value);
    // setemailMessageEditorValue(value);
  };

  const getFormatedMessage = (prop: EventStateError) => {
    return props[prop] !== ''
      ? intl.formatMessage({ id: props[prop] })
      : props[prop];
  };

  const handleLocationChange = (value: any, prop: string, type: string) => {
    setHasStateChange(true);

    let locationClone = [...locations];
    if (type === 'latLng') {
      locationClone[0].latitude = value.lat;
      locationClone[0].longitude = value.lng;
    } else if (type === 'description') {
      locationClone[0].relevantText = value;
    }
    props.eventPropsChanged(prop, locationClone);
  };

  const history = useHistory();

  return (
    <Wrapper style={{ display: 'flex', flexDirection: 'row' }}>
      <Prompt
        when={hasStateChanged}
        message='Vous avez des changements non enregistrés. Êtes-vous sûr de vouloir quitter cette page ?'
      />
      <div>
        <div className='form-header'>
          <span className='title'>
            {intl.formatMessage({ id: 'event.label.title' })}
          </span>
          {/* <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginLeft: '47rem',
            }}
          >
            <Button
              background='#FFFFFF'
              border='1px solid #FF5078'
              color='#FF5078'
              onClick={() => history.push('/event')}
            >
              {intl.formatMessage({ id: 'settings.button.cancel' })}
            </Button>
            <Button styled='primary' onClick={handleClickConfirm}>
              {loading ? (
                <CircularProgress size={20} color='inherit' />
              ) : (
                intl.formatMessage({ id: 'event.label.create' })
              )}
            </Button>
          </div> */}
          <div className='action'>
            {(imageError ||
              nameError ||
              locationNameError ||
              addressError ||
              openingHourError ||
              closingHourError ||
              doorOpeningHourError ||
              priceError ||
              termsError ||
              contactPhoneError ||
              emailError ||
              websiteError ||
              labelColorError ||
              backgroundColorError ||
              foregroundColorError ||
              emailSubscriptionMessageError) && (
              <div className='error-icon'>
                <Icon
                  icon={ic_warning}
                  size={24}
                  style={{ color: '#FF5078' }}
                />
              </div>
            )}
          </div>
        </div>
        <div className='div-container'>
          <div
            className='section first-section'
            ref={(el: any) => {
              styleRef.current = el;
            }}
          >
            {/* <h3 className='section-title'>
              {intl.formatMessage({ id: 'event.label.section.style' })}
            </h3> */}
            <div>
              <InputFile
                className='input-label'
                width={280}
                label={intl.formatMessage({ id: 'event.label.image' })}
                image={strip}
                defaultImage={cover}
                error={getFormatedMessage('imageError')}
                onChange={(e) => {
                  handleFileChange(e, 'strip');
                }}
              ></InputFile>
            </div>
            <div className='section-row'>
              <ColorPicker
                label={intl.formatMessage({ id: 'event.label.labelColor' })}
                color={labelColor}
                error={getFormatedMessage('labelColorError')}
                onChange={(color: any) => {
                  handleColorChange(color, 'labelColor');
                }}
              />

              <ColorPicker
                label={intl.formatMessage({
                  id: 'coupon.label.foregroundColor',
                })}
                color={foregroundColor}
                error={getFormatedMessage('foregroundColorError')}
                onChange={(color: any) => {
                  handleColorChange(color, 'foregroundColor');
                }}
              />
              <ColorPicker
                label={intl.formatMessage({
                  id: 'coupon.label.backgroundColor',
                })}
                color={backgroundColor}
                error={getFormatedMessage('backgroundColorError')}
                onChange={(color: any) => {
                  handleColorChange(color, 'backgroundColor');
                }}
              />
            </div>

            <BarcodeTypeSelection
              setSelectedBarcode={(barcodeType) => {
                props.eventPropsChanged('barcodeType', barcodeType);
              }}
              barcodeType={barcodeType}
            ></BarcodeTypeSelection>
            <SecurityOptions
              handleSecurityPropsChange={(name: string, value: boolean) => {
                props.eventPropsChanged(name, value);
              }}
              {...{
                layout: 'column',

                enableOneTimeUse,
                disableIOSPassSharing,
                disableMultipleHolders,
                enableSecurityAnimation,
                enableRotatingBarcode,
                disableEmailSharing,
              }}
            ></SecurityOptions>
          </div>
          <div className='wrapper'>
            <div className='wrapper_left'>
              <h3
                className='section-title'
                ref={(el: any) => {
                  eventInfoRef.current = el;
                }}
              >
                {intl.formatMessage({ id: 'event.label.section.info' })}
              </h3>
              <InputLabel
                label={intl.formatMessage({ id: 'event.label.name' })}
                name='name'
                value={name}
                error={getFormatedMessage('nameError')}
                placeholder={intl.formatMessage({ id: 'event.label.name' })}
                onChange={(e) => handleEventChange(e)}
                limit={maxChars.event.name || maxChars.name}
                margin='10px 0px 0px'
              />
              <InputLabel
                label={intl.formatMessage({ id: 'event.label.price' })}
                name='price'
                value={price}
                error={getFormatedMessage('priceError')}
                placeholder={intl.formatMessage({ id: 'event.label.price' })}
                onChange={(e) => handleEventChange(e)}
                margin='10px 0px 0px'
              />
            </div>
            <div className='wrapper_right'>
              <h3
                className='section-title'
                ref={(el: any) => {
                  locationInfoRef.current = el;
                }}
              >
                {intl.formatMessage({ id: 'event.label.section.timeLocation' })}
              </h3>
              <div className='wrapper_right_container'>
                <div>
                  <div className='dateLabel'>
                    {intl.formatMessage({
                      id: 'event.label.doorOpeningHour',
                    })}
                  </div>
                  <KeyboardDateTimePicker
                    required
                    fullWidth
                    margin='normal'
                    // label={intl.formatMessage({
                    //   id: 'event.label.doorOpeningHour',
                    // })}
                    format='dd MMM yyyy HH:MM'
                    ampm={false}
                    inputVariant='outlined'
                    value={date.doorOpeningHour}
                    onChange={handleDateChange('doorOpeningHour')}
                    error={doorOpeningHourError !== ''}
                    helperText={getFormatedMessage('doorOpeningHourError')}
                    disablePast
                    TextFieldComponent={({ InputProps, ...rest }) => (
                      <TextField
                        {...rest}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <Divider
                                className='divider'
                                orientation='vertical'
                                variant='inset'
                                light
                              />
                              <IconButton
                                onClick={
                                  (InputProps as any)?.endAdornment.props
                                    .children.props.onClick
                                }
                              >
                                <Calendar />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </div>
                <div>
                  <div className='dateLabel'>
                    {intl.formatMessage({ id: 'event.label.openingHour' })}
                  </div>
                  <KeyboardDateTimePicker
                    required
                    fullWidth
                    margin='normal'
                    id='start-date'
                    format='dd MMM yyyy HH:MM'
                    value={date.openingHour}
                    onChange={handleDateChange('openingHour')}
                    error={openingHourError !== ''}
                    ampm={false}
                    helperText={getFormatedMessage('openingHourError')}
                    disablePast
                    inputVariant='outlined'
                    minDate={date.doorOpeningHour}
                    TextFieldComponent={({ InputProps, ...rest }) => (
                      <TextField
                        {...rest}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <Divider
                                className='divider'
                                orientation='vertical'
                                variant='inset'
                                light
                              />
                              <IconButton
                                onClick={
                                  (InputProps as any)?.endAdornment.props
                                    .children.props.onClick
                                }
                              >
                                <Calendar />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </div>
                <div>
                  <div className='dateLabel'>
                    {intl.formatMessage({ id: 'event.label.closingHour' })}
                  </div>
                  <KeyboardDateTimePicker
                    required
                    fullWidth
                    margin='normal'
                    ampm={false}
                    format='dd MMM yyyy HH:MM'
                    inputVariant='outlined'
                    value={date.closingHour}
                    onChange={handleDateChange('closingHour')}
                    error={closingHourError !== ''}
                    helperText={getFormatedMessage('closingHourError')}
                    disablePast
                    minDate={date.openingHour}
                    TextFieldComponent={({ InputProps, ...rest }) => (
                      <TextField
                        {...rest}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <Divider
                                className='divider'
                                orientation='vertical'
                                variant='inset'
                                light
                              />
                              <IconButton
                                onClick={
                                  (InputProps as any)?.endAdornment.props
                                    .children.props.onClick
                                }
                              >
                                <Calendar />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* <div className='second-section'>
            <h3 className='section-title'>
              {intl.formatMessage({ id: 'event.label.section.info' })}
            </h3>
            <div className='second-section-row'>
              <InputLabel
                label={intl.formatMessage({ id: 'event.label.name' })}
                name='name'
                value={name}
                error={getFormatedMessage('nameError')}
                placeholder={intl.formatMessage({ id: 'event.label.name' })}
                onChange={(e) => handleEventChange(e)}
              />
              <InputLabel
                label={intl.formatMessage({ id: 'event.label.price' })}
                name='price'
                value={price}
                error={getFormatedMessage('priceError')}
                placeholder={intl.formatMessage({ id: 'event.label.price' })}
                onChange={(e) => handleEventChange(e)}
              />
            </div>
          </div> */}

          <div className='section'>
            <h3 className='section-title'>
              {intl.formatMessage({ id: 'event.label.selectLocation' })}
            </h3>
            <div className='section-row'>
              <InputLabel
                label={intl.formatMessage({ id: 'event.label.locationName' })}
                name='locationName'
                value={locationName}
                error={getFormatedMessage('locationNameError')}
                placeholder={intl.formatMessage({
                  id: 'event.label.locationName',
                })}
                limit={maxChars.event.locationName || maxChars.locationName}
                onChange={(e) => handleEventChange(e)}
              />

              <InputLabel
                label={intl.formatMessage({ id: 'event.label.address' })}
                name='address'
                value={address}
                error={getFormatedMessage('addressError')}
                placeholder={intl.formatMessage({
                  id: 'event.label.address',
                })}
                limit={maxChars.event.address || maxChars.address}
                onChange={(e) => handleEventChange(e)}
              />
            </div>
            <div className='map'>
              <div className='map-label'>
                {/* <span>
                  {intl.formatMessage({ id: 'event.label.selectLocation' })}
                </span> */}
                <span>
                  Lat: {locations[0].latitude}, Lng: {locations[0].longitude}
                </span>
              </div>
              <MapComponent
                onChange={handleLocationChange}
                locations={locations}
              />
            </div>
          </div>
          <div className='section'>
            <h3
              className='section-title'
              ref={(el: any) => {
                contactInfoRef.current = el;
              }}
              style={{ marginTop: '2rem' }}
            >
              {intl.formatMessage({ id: 'event.label.section.contact' })}
            </h3>
            <div className='section-row info_subContainer_wrap'>
              <InputLabel
                label={intl.formatMessage({ id: 'event.label.contactPhone' })}
                name='contactPhone'
                value={contactPhone}
                error={getFormatedMessage('contactPhoneError')}
                placeholder={intl.formatMessage({
                  id: 'event.label.contactPhone',
                })}
                limit={maxChars.event.phoneNumber || maxChars.phoneNumber}
                onChange={(e) => handleEventChange(e)}
              />
              <InputLabel
                label={intl.formatMessage({ id: 'event.label.email' })}
                name='email'
                value={email}
                error={getFormatedMessage('emailError')}
                placeholder={intl.formatMessage({ id: 'event.label.email' })}
                limit={maxChars.event.email || maxChars.email}
                onChange={(e) => handleEventChange(e)}
              />
              <InputLabel
                label={'Site Web*'}
                name='website'
                value={website}
                limit={maxChars.event.website || maxChars.website}
                error={getFormatedMessage('websiteError')}
                placeholder={intl.formatMessage({ id: 'event.label.website' })}
                onChange={(e) => handleEventChange(e)}
              />
            </div>
            <div className='section-row'>
              <InputLabel
                inputType='textArea'
                label={intl.formatMessage({ id: 'event.label.terms' })}
                name='terms'
                value={terms}
                error={getFormatedMessage('termsError')}
                // width={320}
                placeholder={intl.formatMessage({
                  id: 'event.label.terms',
                })}
                limit={maxChars.event.terms || maxChars.terms}
                onChange={(e) => handleEventChange(e)}
              />
            </div>
          </div>
          <div className='section'>
            <h3 className='section-title'>
              {intl.formatMessage({ id: 'event.label.section.emailMessage' })}
            </h3>
            <div className='section-row'>
              <TextEditor
                label={intl.formatMessage({
                  id: 'email.label.emailMessage',
                })}
                placeholder={intl.formatMessage({
                  id: 'email.label.emailMessage',
                })}
                oldState={emailMessageEditorValue}
                changeValue={handleEmailMessageChange}
                error={getFormatedMessage('emailSubscriptionMessageError')}
              />
            </div>
          </div>
          {/* <div className='div-content'> */}
        </div>
      </div>

      <div
        className='section wireframe-section'
        style={{
          marginTop: 100,
          marginLeft: 5,
          width: '326px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: '-4.8rem',
            marginBottom: '1rem',
          }}
        >
          <Button
            background='#FFFFFF'
            border='1px solid #FF5078'
            color='#FF5078'
            onClick={() => history.push('/event')}
          >
            {intl.formatMessage({ id: 'wallet.button.cancel' })}
          </Button>
          <Button styled='primary' onClick={handleClickConfirm}>
            {loading ? (
              <CircularProgress size={20} color='inherit' />
            ) : (
              intl.formatMessage({ id: 'event.label.create' })
            )}
          </Button>
        </div>
        <RadioGroup
          name='previews'
          value={switchPreview}
          onChange={handlePreviewChange}
          className='radioGroup'
          style={{ display: 'flex', flexDirection: 'row', width: 200 }}
        >
          <FormControlLabel
            value='android'
            control={<Radio />}
            label='Android'
          />
          <FormControlLabel value='iOS' control={<Radio />} label='iOS' />
        </RadioGroup>

        {switchPreview === 'android' && (
          <EventWireframeAndroid
            backgroundColor={backgroundColor}
            strip={strip}
            price={price}
            startDate={date.openingHour}
            endDate={date.closingHour}
            openDate={date.doorOpeningHour}
            terms={terms}
            name={name}
            locationName={locationName}
            QrCode={QRcode}
            barcodeType={barcodeType}
            animatedBorder={enableSecurityAnimation}
          />
        )}

        {switchPreview === 'iOS' && (
          <EventWireframeIPhone
            backgroundColor={backgroundColor}
            contactPhone={contactPhone}
            locations={locations}
            email={email}
            strip={strip}
            price={price}
            startDate={date.openingHour}
            endDate={date.closingHour}
            openDate={date.doorOpeningHour}
            terms={terms}
            name={name}
            locationName={locationName}
            titleColor={labelColor.hex}
            textColor={foregroundColor.hex}
            QrCode={QRcode}
            barcodeType={barcodeType}
          />
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${(props) => createEventStyles(props)}
`;

const mapStateToProps = ({ event }: RootState) => {
  const {
    address,
    contactPhone,
    date,
    email,
    strip,
    backgroundColor,
    foregroundColor,
    labelColor,
    locationName,
    emailSubscriptionMessage,
    name,
    price,
    terms,
    website,
    loading,
    locations,
    barcodeType,
    enableOneTimeUse,
    disableIOSPassSharing,
    disableMultipleHolders,
    enableSecurityAnimation,
    enableRotatingBarcode,
    disableEmailSharing,
    //errors
    imageError,
    nameError,
    locationNameError,
    addressError,
    openingHourError,
    closingHourError,
    doorOpeningHourError,
    priceError,
    termsError,
    contactPhoneError,
    emailError,
    websiteError,
    labelColorError,
    backgroundColorError,
    foregroundColorError,
    emailSubscriptionMessageError,
  } = event;
  return {
    address,
    contactPhone,
    date,
    email,
    strip,
    backgroundColor,
    foregroundColor,
    labelColor,
    locationName,
    emailSubscriptionMessage,
    name,
    price,
    terms,
    website,
    loading,
    locations,
    barcodeType,
    enableOneTimeUse,
    disableIOSPassSharing,
    disableMultipleHolders,
    enableSecurityAnimation,
    enableRotatingBarcode,
    disableEmailSharing,
    //errors
    imageError,
    nameError,
    locationNameError,
    addressError,
    openingHourError,
    closingHourError,
    doorOpeningHourError,
    priceError,
    termsError,
    contactPhoneError,
    emailError,
    websiteError,
    labelColorError,
    backgroundColorError,
    foregroundColorError,
    emailSubscriptionMessageError,
  };
};

export const connector = connect(mapStateToProps, {
  eventPropsChanged,
  addEvent,
  eventInitialState,
});

export default connector(CreateEvent);
