import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      display: 'flex',
      width: 233,
      height: 60,
      alignItems: 'center',
      justifyContent: 'center',
      background: '#FFFFFF',
      marginTop: '20px',
      marginRight: '15px',
      borderRadius: '13px',
      padding: '8px 16px',
      boxShadow: '0px 0px 20px 2px rgba(55, 55, 55, 0.08)',
      boxSizing: 'border-box',
    },
    content: {
      padding: 0,
    },
    container: {
      display: 'flex',
      alignItems: 'center',
    },
    titleCard: {
      fontSize: '12px',
      fontFamily: 'Poppins',
      fontWeight: 600,
      lineHeight: 1.5,
      alignItems: 'center',
    },
    media: {
      height: 185,
      borderRadius: '20px',
      border: '1px solid #CDCDCD',
      boxSizing: 'border-box',
    },
    label: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '8px',
    },

    description: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    cardDescription: {
      padding: '8px',
      border: '1px solid #C7C7C7',
      borderRadius: '10px',
      marginTop: '8px',
    },
    cardMenu: {
      position: 'relative',
      height: '0px',
      left: '240px',
      top: '15px',
    },

    menuIcon: {
      fill: '#ffffff',
    },
    iconButton: {},
    title: {
      color: 'red',
    },
    circle: {
      position: 'relative',
      height: '85px',
      width: '85px',
      backgroundColor: '#404040',
      borderRadius: '50%',
      zIndex: 5,
      top: 23,
    },
    countText: {
      position: 'absolute',
      textAlign: 'center',
      top: '2rem',
      left: '2.5rem',
      color: 'red',
    },
    Container: {
      //position: 'relative',
      width: 180,
      // marginRight: '50px',
      //paddingLeft: '10px',
      background: '#FFFFFF',
      boxShadow: '0px 0px 20px 2px rgba(55, 55, 55, 0.08)',
      borderRadius: '10px',
      //marginTop: '40px',
      minHeight: 40,
      border: '1px solid #404040',
      //left: '-25px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      left: '-2rem',
      top: '-1rem',
      zIndex: -1,
      paddingLeft: '30px',
    },
    BoxContainer: {
      height: '50px',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      width: '230px',
      maxHeight: '55px',
      //border: '2px solid #404040',
      boxShadow: 'none',
      margin: '0px 1rem',
    },
    emailRoot: {},
    emailContainer: {
      display: 'flex',
      // marginRight: '3rem',
      alignItems: 'center',
    },
    emailCircle: {
      width: '55px',
      height: '55px',
      backgroundColor: '#404040',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '2rem',
      color: '#fff',
      // position: 'relative',
      // left: '2rem',
      [theme.breakpoints.up('sm')]: {
        height: '45px',
        width: '45px',
      },
    },
    clientRect: {
      width: '86px',
      height: '30px',
      backgroundColor: '#404040',
      borderRadius: '7px',
      marginLeft: '75px',
      color: '#FFF',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      // ['@media only screen and (min-width: sm) and (max-width: lg) ']: {
      //   marginLeft: '45px',
      // },
    },
    clientContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: '75px',
      // [theme.breakpoints.up('sm')]: {
      //   marginRight: '75px',
      // },
    },
    clientContent: {
      //position: 'relative',
      width: 178,
      // marginRight: '50px',
      background: '#FFFFFF',
      boxShadow: '0px 0px 20px 2px rgba(55, 55, 55, 0.08)',
      borderRadius: '15px',
      //marginTop: '40px',
      minHeight: 41,
      border: '2px solid #404040',
      //left: '-25px',
      display: 'flex',
      justifyContent: 'center',
      //alignItems: 'center',
      position: 'relative',
      left: '2rem',
      top: '-1rem',
      zIndex: -1,
      alignItems: 'center',
      height: '66px',
      //justifyContent: 'center',
      ['@media only screen and (min-width: sm) and (max-width: 1300px) ']: {
        width: '178px',
        left: '2rem',
      },
    },
    clientTotalCard: {
      width: '230px',
      '& .makeStyles-clientContainer-74': {
        '& .makeStyles-clientContent-75': {
          border: '2px solid #FF5078 !important',
          justifyContent: 'flex-start',
          paddingLeft: '1rem',
          marginTop: '2rem',
          color: '#FF5078',
          paddingTop: '2px',
          textTransform: 'uppercase',
          height: '0px',
        },
        '& .makeStyles-clientRect-73': {
          backgroundColor: '#fff !important',
          color: '#FF5078',
          width: '0px',
          height: '0px',
          position: 'relative',
          left: '9rem',
          alignItems: 'revert',
          fontWeight: 600,
          marginTop: '4px',

          top: '29px',
        },
        '& .MuiTypography-subtitle2': {
          fontSize: '0.975rem',

          fontWeight: 600,
        },
      },
    },
  })
);

export const childStyles = makeStyles((theme: Theme) =>
  createStyles({
    totalCard: {
      width: '243px',
      // border: '2px solid #FF5078 ',
      marginTop: '31px',
    },
    emailContainer: {
      //display: 'flex',
      // marginRight: '3rem',
      // alignItems: 'center',
    },
    emailCircle: {
      position: 'relative',
      backgroundColor: '#fff ',
      color: '#FF5078 ',
      width: '0px ',
      height: '0px ',
      left: '10rem',
      top: '-6px',
      alignItems: 'revert ',
      fontWeight: 600,
      marginTop: '4px ',
    },
    Container: {
      width: 231,
      // marginRight: '50px',
      paddingLeft: '13px',
      background: '#FFFFFF',
      boxShadow: '0px 0px 20px 2px rgba(55, 55, 55, 0.08)',
      borderRadius: '15px',
      //marginTop: '40px',
      minHeight: 41,
      border: '2px solid #FF5078',
      //left: '-25px',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      position: 'relative',
      left: '-2rem',
      top: '-1rem',
      zIndex: -1,
      color: '#FF5078',
      textTransform: 'capitalize',
      '& .MuiTypography-subtitle2': {
        fontWeight: 600,
        marginLeft: '7px',
        marginTop: '7px',
        fontSize: '0.975rem',
      },
    },

    clientContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: '30px',
      marginTop: '30px',
    },
    clientRect: {
      backgroundColor: '#fff !important',
      color: '#FF5078',
      width: '0px',
      height: '0px',
      position: 'relative',
      left: '6rem',
      alignItems: 'revert',
      fontWeight: 600,
      //marginTop: '4px',

      top: '-4px',
    },
    clientContent: {
      //position: 'relative',
      width: 231,
      // marginRight: '50px',
      background: '#FFFFFF',
      boxShadow: '0px 0px 20px 2px rgba(55, 55, 55, 0.08)',
      borderRadius: '15px',
      //marginTop: '40px',
      minHeight: 41,
      border: '2px solid #FF5078 !important',
      //left: '-25px',
      display: 'flex',
      justifyContent: 'flex-start',
      //alignItems: 'center',
      position: 'relative',
      left: '-2rem',
      top: '-1rem',
      zIndex: -1,
      color: '#FF5078',
      alignItems: 'center',
      height: '0px',
      //justifyContent: 'center',
      '& .MuiTypography-subtitle2': {
        fontWeight: 600,
        fontSize: '0.975rem',
        // marginTop: '7px',
        marginLeft: '20px',
      },
    },
    interne: {
      clientRect: {
        backgroundColor: '#9D30BB',
      },
      clientContent: {
        border: ' 2px solid #9D30BB',
        color: '#9D30BB',
      },
    },
    interneRect: {
      backgroundColor: '#9D30BB',
    },
  })
);

export const internesStyles = makeStyles((theme: Theme) =>
  createStyles({
    clientRect: {
      backgroundColor: '#9D30BB',
    },
    clientContent: {
      border: ' 2px solid #9D30BB',
      color: '#9D30BB',
    },
  })
);
export const externesStyles = makeStyles((theme: Theme) =>
  createStyles({
    clientRect: {
      backgroundColor: '#691FDE',
    },
    clientContent: {
      border: ' 2px solid #691FDE',
      color: '#691FDE',
    },
  })
);
export const crmStyles = makeStyles((theme: Theme) =>
  createStyles({
    clientRect: {
      backgroundColor: '#FF5078',
    },
    clientContent: {
      border: ' 2px solid #FF5078',
      color: '#FF5078',
    },
  })
);
