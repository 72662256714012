import React from 'react';
import styled from 'styled-components';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { SketchPicker } from 'react-color';
import { getColorPickerStyles } from './colorPicker.styles';

const ColorPicker = (props: any) => {
  const { label, color, error, onChange } = props;

  const [displayColorPicker, setDisplayColorPicker] = React.useState(false);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleClickAway = () => {
    setDisplayColorPicker(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Wrapper color={color.hex}>
        <label className='label'>{label}</label>
        <div className='div-contain'>
          <label>{color.hex}</label>
          <div className='swatch' onClick={handleClick}>
            <div className='color' />
          </div>
          {displayColorPicker ? (
            <div className='popover'>
              <div className='cover' onClick={handleClose} />

              <SketchPicker color={color.hex} onChange={onChange} />
            </div>
          ) : null}
        </div>
        <span className='text-error'>{error}</span>
      </Wrapper>
    </ClickAwayListener>
  );
};
const Wrapper = styled('div')`
  ${(props) => getColorPickerStyles(props)}
`;

export default ColorPicker;
