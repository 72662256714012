import { css } from 'styled-components';

export const hotelStyles = (props: any) => {
  return css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0;
    overflow: hidden;

    .div-input-filter {
      display: flex;
      align-item: center;
      min-width: 400px;
    }

    .filter-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      > input {
        width: 100%;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #404040;
        box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.08);
        border-radius: 15px;
      }
    }
    .percentLabel {
      align-self: flex-start;
      font-style: normal;
      font-weight: 500;
      line-height: 21px;
      font-size: 14px;
      color: #828282;
      margin: 5px;
    }
    .input-text-error {
      align-self: flex-start;
      font-size: 14px;
      color: #ed0723;
      margin-bottom: 8px;
      margin: 5px;
    }
    .filter-button {
      display: flex;

      width: 100px;
      align-items: center;
      justify-content: space-between;
      background: #4914f4;
      height: 46px;
      box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.08);
      border-radius: 15px;
      padding: 0 10px;
      transition: all 0.3s ease;
      :hover {
        cursor: pointer;
        background: #6438f5;
      }
      > h3 {
        /* font-family: Poppins; */
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: white;
        width: 80px;
      }
    }
    .filter-opened {
      transform: rotate(180deg);
    }

    .select-input {
      width: 150px;
      visibility: hidden;
      position: absolute;
      left: 260px;
      top: 70px;
    }
    .div-container {
      display: flex;
      flex: 1;
      /* overflow: scroll;
      overflow-x: hidden; */
      @media (max-width: 850px) {
        flex-direction: column;
      }
    }
    .div-content {
      display: flex;
      flex-direction: column;
      flex: 1;
      /* justify-content: center; */
      align-items: center;
      margin: 10px;
    }
    .inputPercent {
      margin-left: 14px;
      height: 56px;
      width: 90%;
      order: 1px solid transparent;
      border-radius: 80px;
    }
    .select {
      width: 100%;
      margin-bottom: 20px;
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #1a2d35;
        margin: 5px;
      }
      .MuiInput-underline::before {
        border: none;
      }
      .MuiInput-underline::after {
        border: none;
      }
      .MuiInputBase-root {
        width: 100%;
        background-color: #f8f6fe;
        color: #404040;
        border: none;
        border-radius: 50px;
        padding: 12px 14px;
        .MuiSelect-icon {
          right: 10px;
        }
      }
    }
    .div-date-cont {
      display: flex;
    }
    .div-date {
      margin-right: 20px;
    }

    .title {
      display: flex;
      align-self: flex-start;

      font-size: 18px;
      font-family: 500;
      font-weight: bold;
      margin: 16px;
    }

    .div-contain {
      width: 356px;
    }
  `;
};
