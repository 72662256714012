import React from 'react';
import { Wrapper } from './ferryWireframe.style';

import { ratio } from 'wcag-color';

import { getDataFromCache } from '../../utils/cache';
import { URL_IMAGE } from '../../apis/config';
import Icon from 'react-icons-kit';

import moment from 'moment';
import QRCode from 'qrcode.react';
import Ferry from '../../icons/Ferry';
import { convertToMoment } from '../../utils/common';
import { ic_more_vert } from 'react-icons-kit/md/ic_more_vert';
import { useIntl } from 'react-intl';

import CODE128 from '../../assets/images/code128.png';
import PDF417 from '../../assets/images/pdf417.png';
import QR from '../../assets/images/qr.png';
import AZTEC from '../../assets/images/aztec.png';
import { switchBarcode } from '../../utils/common';

type WireframeProps = {
  backgroundColor: any;
  strip: any;
  number: any;
  originStationCode: any;
  originName: any;
  destinationName: any;
  destinationStationCode: any;
  departureTime: any;
  arrivalTime: any;
  tarif: any;
  customerServiceNumber: any;
  details: any;
  terms: any;
  QrCode: any;
  titleColor?: any;
  textColor?: any;
  state?: any;
  logo?: any;
  barcodeType: string;
};

const FerryWireframeIPhone: React.FC<WireframeProps> = (props) => {
  const {
    backgroundColor,
    strip,
    number,
    originStationCode,
    originName,
    destinationName,
    destinationStationCode,
    departureTime,
    arrivalTime,
    tarif,
    customerServiceNumber,
    details,
    terms,
    QrCode,
    titleColor,
    textColor,
    logo,
    barcodeType,
  } = props;

  const currentWallet = getDataFromCache('wallet');
  const [showDetails, setShowDetails] = React.useState(true);
  const intl = useIntl();
  const backFlip = () => {
    setShowDetails(!showDetails);
  };
  const { barcode, barcodeClass } = switchBarcode(barcodeType, {
    CODE128,
    PDF417,
    QR,
    AZTEC,
  });
  return (
    <Wrapper
      backgroundColor={backgroundColor}
      mainContentTextColor={
        ratio('#ffffff', backgroundColor.hex) > 3.0 ? '#ffffff' : '#000000'
      }
      titleColor={titleColor}
      textColor={textColor}
    >
      {showDetails ? (
        <div className='mainSection'>
          <div className='firstSection'>
            <div>
              <img
                className='mainSection-header-logo'
                src={
                  logo ? logo : `${URL_IMAGE}/${currentWallet.logoPictureUrl}`
                }
                alt='wallet Logo'
              />
            </div>
            <div className='number'>
              <div className='date'>
                <h3 className='mainSection-label'>
                  {' '}
                  {intl.formatMessage({ id: 'ship.label.number' })}
                </h3>
                <h3 className='mainSection-data'>{number || 'N de place'}</h3>
              </div>
            </div>
          </div>

          <div className='firstSection'>
            <div className='mainSection-origin-destination'>
              <div className='origin'>
                <h3 className='mainSection-origin-name'>{originName}</h3>
                <h3 className='mainSection-origin-stationCode'>
                  {originStationCode}
                </h3>
              </div>
              <div className='arrow'>
                <Ferry />
              </div>
              <div className='destination'>
                <h3 className='mainSection-destination-name'>
                  {destinationName}
                </h3>
                <h3 className='mainSection-destination-destinationCode'>
                  {destinationStationCode}
                </h3>
              </div>
            </div>
          </div>
          <div className='firstSection'>
            <div className='number'>
              <div className='date'>
                <h3 className='mainSection-label'>
                  {' '}
                  {intl.formatMessage({ id: 'ship.label.departureDate' })}
                </h3>
                <h3 className='mainSection-data'>
                  {moment(convertToMoment(departureTime)).format(
                    'MM/DD/YY, h:mm a'
                  ) || 'Date'}
                </h3>
              </div>
            </div>
            <div className='number'>
              <div className='date'>
                <h3 className='mainSection-label'>
                  {' '}
                  {intl.formatMessage({ id: 'ship.label.arrivalDate' })}
                </h3>
                <h3 className='mainSection-data'>
                  {moment(convertToMoment(arrivalTime)).format(
                    'MM/DD/YY, h:mm a'
                  ) || 'Date'}
                </h3>
              </div>
            </div>
            <div className='number'>
              <div className='date'>
                <h3 className='mainSection-label'>
                  {' '}
                  {intl.formatMessage({ id: 'ship.label.tarif' })}
                </h3>
                <h3 className='mainSection-data'> {`${tarif} €` || ''}</h3>
              </div>
            </div>
          </div>
          <div>
            <div className='firstSection'>
              <div className='date'>
                <h3 className='mainSection-label'>
                  {' '}
                  {intl.formatMessage({ id: 'ship.label.passenger' })}
                </h3>
                <h3 className='mainSection-data'>{'Prenom Nom'}</h3>
              </div>
            </div>
          </div>

          <div
            className={
              barcodeType === 'NONE'
                ? 'mainSection-content-barCode hidden'
                : 'mainSection-content-barCode'
            }
          >
            {' '}
            <div className='barCode'>
              <img className={barcodeClass} src={barcode} alt='barcode' />
              <p className='walletId'>6066e4315abbcc1f9526d392</p>
            </div>
          </div>
          <div className='BackFlip_btn' onClick={backFlip}>
            <Icon size={18} icon={ic_more_vert} className='backflip' />
          </div>
        </div>
      ) : (
        <div className='dataSection'>
          <div className='sectionLabel'>
            <h3 className='label'>Description</h3>
            <h3 className='data'>{`${details}` || ''}</h3>
          </div>
          <span className='divider'></span>
          <div className='sectionLabel'>
            <h3 className='label'>
              {' '}
              {intl.formatMessage({ id: 'ship.label.departureDate' })}
            </h3>
            <h3 className='data'>
              {moment(convertToMoment(departureTime)).format(
                'MMMM DD YYYY,HH:mm'
              )}
            </h3>
          </div>
          <span className='divider'></span>
          <div className='sectionLabel'>
            <h3 className='label'>
              {' '}
              {intl.formatMessage({ id: 'ship.label.arrivalDate' })}
            </h3>
            <h3 className='data'>
              {moment(convertToMoment(arrivalTime)).format(
                'MMMM DD YYYY,HH:mm'
              )}
            </h3>
          </div>

          <span className='divider'></span>
          <div className='sectionLabel'>
            <h3 className='label'>
              {' '}
              {intl.formatMessage({ id: 'ship.label.ticketNumber' })}
            </h3>
            <h3 className='data'>6066e4315abbcc1f9526d392</h3>
          </div>
          <span className='divider'></span>
          <div className='sectionLabel'>
            <h3 className='label'>
              {' '}
              {intl.formatMessage({ id: 'ship.label.value' })}
            </h3>
            <h3 className='data'>{`${tarif} €` || ''}</h3>
          </div>
          <span className='divider'></span>
          <div className='sectionLabel'>
            <h3 className='label'>Terms & conditions</h3>
            <h3 className='data'>{terms || ''}</h3>
          </div>
          <span className='divider'></span>

          <div className='sectionLabel'>
            <h3 className='label'>
              {' '}
              {intl.formatMessage({ id: 'ship.label.customerServiceNumber' })}
            </h3>
            <h3 className='data'>{customerServiceNumber || ''}</h3>
          </div>
          <div className='BackFlip_btn' onClick={backFlip}>
            <Icon size={18} icon={ic_more_vert} className='backflip' />
          </div>
        </div>
      )}
    </Wrapper>
  );
};

export default FerryWireframeIPhone;
