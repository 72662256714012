import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='85'
      height='85'
      fill='none'
      viewBox='0 0 85 85'
    >
      <path
        fill='#404040'
        d='M85 42.5C85 65.972 65.972 85 42.5 85S0 65.972 0 42.5 19.028 0 42.5 0 85 19.028 85 42.5z'
      ></path>
    </svg>
  );
}

export default Icon;
