import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import QRCode from 'qrcode.react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CircularProgress } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SideBar from '../../components/Drawer';
import { settingsStyles, useStyles } from './settings.styles';
import WalletForm from '../../components/walletForm';
import TextEditor from '../../components/TextEditor';
import Radio from '@material-ui/core/Radio';
import InputLabel from '../../commons/Inputs/InputLabel';
import Button from '../../commons/Button';
import Text from '../../commons/Text';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {
  settingsPropsChanged,
  getWalletSettings,
  getWalletDetail,
  setWallet,
  confirmSettings,
  settingsInitialState,
  showSMSAccountError,
} from '../../actions';
import Icon from 'react-icons-kit';
import { ic_warning } from 'react-icons-kit/md/ic_warning';
import { isAllowed } from '../../utils/guard';
import { RootState } from '../../reducers';
import { getDataFromCache } from '../../utils/cache';

import { HtmlRenderer } from '../../components/HtmlRenderer';
import SimpleDialog from '../../components/Dialog';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { logDOM } from '@testing-library/react';
import NotificationsSettingsTable from '../../components/NotificationsSettingsTable';
import maxChars from '../../utils/fieldsMaxLength';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useTabStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginBottom: '5rem',
    borderRadius: '20px',
    padding: '20px',
    background: '#FFFFFF',
    boxShadow: '0px 0px 20px 2px rgba(55, 55, 55, 0.08)',
    '& .MuiAppBar-colorPrimary': {
      backgroundColor: '#FFF',
      color: '#000000',
    },
    '& .MuiTab-textColorInherit.Mui-selected': {
      opacity: 1,
      color: '#ff5078',
      // width: '17rem',
    },
    '& .MuiTab-textColorInherit': {
      width: '17rem',
    },
    '& .MuiTab-root': {
      boxShadow: '0px 0px 20px 2px rgb(55 55 55 / 8%)',
      borderRadius: '20px 20px 0px 0px',
    },
  },
}));
const Settings: React.FC<any> = (props) => {
  const titleRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  const [copy, setCopy] = React.useState(false);
  const [language, setLanguage] = React.useState<string | null>(
    localStorage.getItem('language')
  );

  const [openDialog, setOpenDialog] = React.useState(false);
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const tabClasses = useTabStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  //TRANSLATION
  const intl = useIntl();
  const {
    icon,
    strip,
    wallet,
    walletForm,
    successMessage,
    emailMessage,
    newOfferEmailMessage,
    newCouponEmailMessage,
    newOfferNotificationMessage,
    newCouponNotificationMessage,
    expiredOfferNotificationMessage,
    newUpdateNotificationMessage,
    hotelEmailSubscriptionMessage,
    cinemaEmailSubscriptionMessage,
    transitEmailSubscriptionMessage,
    trainEmailHtmlCode,
    offerEmailHtmlCode,
    storeCardUpdateEmailHtmlCode,
    flightEmailSubscriptionMessage,
    expiredOfferEmailMessage,
    newUpdateEmailMessage,
    enableNewUpdateEmailMessage,
    enableOfferEmailMessage,

    busEmailHtmlCode,
    //storecard
    enableNewStorecardEmailMessage,
    enableStorecardRemainderEmailMessage,
    enableNewStorecardSMSMessage,
    enableStorecardRemainderSMSMessage,
    enableNewUpdateNotification,
    enableStorecardReminderEmailMessage,
    enableStorecardReminderSMSMessage,
    //event
    enableUpdateEventEmailMessage,
    enableUpdateEventNotification,
    //coupon
    enableCouponEmailMessage,
    enableUpdateCouponEmailMessage,
    enableWelcomeCouponEmailMessageForExternalClients,
    enableWelcomeCouponEmailMessageForInternalClients,
    enableWelcomeCouponEmailMessageForCRMClients,
    enableCouponSMSMessage,
    enableUpdateCouponSMSMessage,
    enableExpiredCouponSMSMessage,
    enableWelcomeCouponSMSMessageForExternalClients,
    enableWelcomeCouponSMSMessageForInternalClients,
    enableWelcomeCouponSMSMessageForCRMClients,
    enableWelcomeCouponNotificationMessageForExternalClients,
    enableWelcomeCouponNotificationMessageForInternalClients,
    enableWelcomeCouponNotificationMessageForCRMClients,
    enableUpdateCouponNotificationMessage,
    enableNewCouponNotificationMessage,
    //offer
    enableExpiredOfferEmailMessage,
    enableOfferSMSMessage,
    enableNewOfferNotificationMessage,
    enableExpiredOfferNotificationMessage,
    enableExpiredOfferSMSMessage,
    boatEmailHtmlCode,
    hotelEmailHtmlCode,
    cinemaEmailHtmlCode,
    flightEmailHtmlCode,
    eventEmailHtmlCode,
    couponEmailHtmlCode,
    newAccountEmailHtmlCode,
    smsTitle,
    smsTitleError,
    hasSMSAccount,
    smsCredits,
    loading,
    loadingSaveCommunicationChannels,
    showBarcode,
    communicationChannels,
    newAccountSMSMessage,
    newAccountSMSMessageError,
    newEventSMSMessage,
    newOfferSMSMessage,
    newOfferSMSMessageError,
    expiredOfferSMSMessage,
    newCouponSMSMessage,
    newCouponSMSMessageError,
    newUpdateSMSMessage,
    newCouponEmailMessageError,
    newOfferNotificationMessageError,
    newCouponNotificationMessageError,
    expiredOfferNotificationMessageError,
    newUpdateNotificationMessageError,
    socialMedia,
  } = props;

  const objectUser: any = getDataFromCache('user');
  const [loadingComponent, setLoadingComponent] = useState<boolean>(true);
  const [newCommchannel, setNewCommchannel] = React.useState<string>(
    'emailAdress'
  );
  React.useEffect(() => {
    props.getWalletSettings(objectUser.wallet);
    props.getWalletDetail(objectUser.wallet);
    setLoadingComponent(false);
  }, []);
  React.useEffect(() => {
    setOpenWalletStyle(true);
    setValue(0);
    setWalletForm();
  }, [walletForm]);

  const setWalletForm = () => {
    if (walletForm?._id) {
      props.setWallet(walletForm);
    }
  };
  const areTextAreasValid = (fieldName: string, value: string): boolean => {
    if (value.length > (maxChars.settings[fieldName] || maxChars.fieldName)) {
      props.settingsPropsChanged(
        `${fieldName}Error`,
        'La longueur maximale a été atteinte'
      );
      return false;
    }
    return true;
  };

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const textAreas = [
      'successMessage',
      'newOfferNotificationMessage',
      'newCouponNotificationMessage',
      'expiredOfferNotificationMessage',
      'newUpdateNotificationMessage',
      'newOfferEmailMessage',
      'expiredOfferEmailMessage',
      'newUpdateEmailMessage',
    ];

    const value = event.target.value;
    const fieldName = event.target.name;

    if (textAreas.includes(fieldName) && !areTextAreasValid(fieldName, value))
      return;
    props.settingsPropsChanged(fieldName, value);
  };

  const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.settingsPropsChanged(event.target.name, event.target.checked);
  };

  const handleChangeNotificationsCheckBox = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    props.settingsPropsChanged(event.target.name, event.target.checked);
  };
  const handleChangeInscriptionFields = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (smsCredits > 0 || event.target.value == 'emailAdress') {
      props.settingsPropsChanged(event.target.name, event.target.value);
      return;
    }
    props.showSMSAccountError(hasSMSAccount, intl);
  };

  const handleLanguageChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const lang = event.target.value as string;
    setLanguage(lang);
  };

  const [emailMessageEditorValue, setemailMessageEditorValue] = useState<
    string
  >('');
  const [newCouponMessageValue, setnewCouponMessageValue] = useState<string>(
    ''
  );

  const handleEditorMessageChange = (
    value: string,
    name: string | undefined
  ) => {
    props.settingsPropsChanged(name, value);
  };

  const handleClickConfirmCommunication = () => {
    handleClickConfirm(true);
  };

  const handleClickConfirm = (loadingComm: boolean = false) => {
    if (language && language !== localStorage.getItem('language')) {
      localStorage.setItem('language', language);
      props.confirmSettings(
        objectUser.wallet,
        {
          ...props,
          ...(emailMessageEditorValue.length > 0 && {
            emailMessage: emailMessageEditorValue,
          }),
          ...(newCouponMessageValue.length > 0 && {
            newCouponEmailMessage: newCouponMessageValue,
          }),
        },
        loadingComm
      );
    } else {
      props.confirmSettings(
        objectUser.wallet,
        {
          ...props,
          ...(emailMessageEditorValue.length > 0 && {
            emailMessage: emailMessageEditorValue,
          }),
          ...(newCouponMessageValue.length > 0 && {
            newCouponEmailMessage: newCouponMessageValue,
          }),
        },
        loadingComm
      );
    }
  };
  // todo add message values of text editors
  const handleClickDetails = () => {
    props.getWalletSettings(objectUser.wallet);
    props.getWalletDetail(objectUser.wallet);
  };

  const [openMessages, setOpenMessages] = useState<boolean>(false);
  const [openWalletStyle, setOpenWalletStyle] = useState<boolean>(false);
  const [openWalletInfos, setOpenWalletInfos] = useState<boolean>(true);
  const [openWalletMsg, setOpenWalletMsg] = useState<boolean>(false);
  const [openWalletNotification, setOpenWalletNotification] = useState<boolean>(
    false
  );
  const [openWalletInfo, setOpenWalletInfo] = useState<boolean>(false);
  const [clickSaved, setClickSaved] = useState<boolean>(false);
  const identifierQr = walletForm && walletForm.identifierQr;
  const url = `${process.env.REACT_APP_API_QR}/wallet/${identifierQr}`;
  const user: any = JSON.parse(localStorage.getItem('user') || '');

  return (
    <SideBar title={intl.formatMessage({ id: 'drawer.menuList.settings' })}>
      {loadingComponent ? (
        <CircularProgress />
      ) : (
        <Wrapper>
          <div className='settings-header'>
            <Text
              ref={titleRef}
              fontWeight={700}
              fontSize={24}
              lineHeight={36}
              color='#404040'
            >
              {intl.formatMessage({ id: 'settings.label.settingsTitle' })}
            </Text>{' '}
          </div>
          <div className='settings-container'>
            <div className='settings'>
              <div className='settings-card-inscription'>
                <Text
                  fontWeight={500}
                  fontSize={18}
                  lineHeight={24}
                  color='#828282'
                  style={{ alignSelf: 'flex-start' }}
                >
                  {intl.formatMessage({
                    id: 'settings.label.inscriptionSettings',
                  })}
                </Text>

                <TableContainer>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          {intl.formatMessage({
                            id: 'settings.label.walletIdentifier',
                          })}
                        </TableCell>
                        <TableCell>
                          {intl.formatMessage({
                            id: 'settings.label.inscriptionLink',
                          })}
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell component='th' scope='row'>
                          {user?.wallet}
                        </TableCell>
                        <TableCell>{url}</TableCell>
                        <TableCell>
                          <CopyToClipboard
                            text={url}
                            onCopy={() => {
                              setCopy(true);
                            }}
                          >
                            <button className='copy-btn'>
                              {intl.formatMessage({
                                id: 'settings.button.copyLink',
                              })}
                            </button>
                          </CopyToClipboard>
                          {copy ? (
                            <span
                              style={{ margin: '0 10px', color: '#ff5078' }}
                            >
                              {intl.formatMessage({
                                id: 'settings.label.linkCopied',
                              })}
                            </span>
                          ) : null}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <FormGroup row style={{ marginTop: '21px' }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showBarcode}
                        onChange={handleChangeCheckBox}
                        name='showBarcode'
                        size='medium'
                      />
                    }
                    label={intl.formatMessage({
                      id: 'settings.label.showBarcodeText',
                    })}
                  />
                </FormGroup>

                <Text
                  fontWeight={500}
                  fontSize={18}
                  lineHeight={24}
                  color='#828282'
                  style={{
                    alignSelf: 'flex-start',
                    marginTop: '20px',
                    marginBottom: '20px',
                  }}
                >
                  {intl.formatMessage({
                    id: 'settings.label.communicationChannels',
                  })}
                </Text>
                <RadioGroup
                  aria-label='subscripition Fields'
                  name='communicationChannels'
                  value={communicationChannels}
                  onChange={handleChangeInscriptionFields}
                  className='radioGroup'
                >
                  <FormControlLabel
                    value='emailAdress'
                    control={<Radio />}
                    label={intl.formatMessage({
                      id: 'settings.label.email',
                    })}
                  />
                  <FormControlLabel
                    value='phoneNumber'
                    control={<Radio />}
                    label={intl.formatMessage({
                      id: 'settings.label.phoneNumber',
                    })}
                  />

                  <FormControlLabel
                    value='all'
                    control={<Radio />}
                    label={intl.formatMessage({
                      id: 'settings.label.emailAndPhoneNumber',
                    })}
                  />
                </RadioGroup>

                <div
                  className='settings-header-buttons'
                  style={{ marginTop: '20px' }}
                >
                  <Button onClick={handleClickConfirmCommunication}>
                    {loadingSaveCommunicationChannels ? (
                      <CircularProgress size={20} color='inherit' />
                    ) : (
                      intl.formatMessage({ id: 'settings.button.save' })
                    )}
                  </Button>
                </div>
              </div>

              <div className='settings-card-qrcode'>
                <Text
                  fontWeight={500}
                  fontSize={14}
                  lineHeight={24}
                  color='#AEAEAE'
                >
                  {intl.formatMessage({
                    id: 'settings.label.QrCode',
                  })}
                </Text>
                <QRCode includeMargin value={url} />
              </div>
            </div>

            <Tabs
              centered
              value={value}
              onChange={handleChange}
              aria-label='simple tabs example'
              style={{ marginTop: '48px' }}
            >
              <Tab
                label={intl.formatMessage({
                  id: 'settings.label.styles',
                })}
                {...a11yProps(0)}
                onClick={() => {
                  setOpenWalletStyle(true);
                  //handleClickDetails();
                }}
              />
              <Tab
                label={intl.formatMessage({
                  id: 'settings.label.information',
                })}
                {...a11yProps(1)}
                onClick={() => {
                  setOpenWalletInfos(true);
                  //handleClickDetails();
                }}
              />
              <Tab
                label={intl.formatMessage({
                  id: 'settings.label.messages',
                })}
                {...a11yProps(2)}
              />
              <Tab
                label={intl.formatMessage({
                  id: 'settings.label.notifications',
                })}
                {...a11yProps(3)}
              />
            </Tabs>
            <div className={tabClasses.root}>
              <TabPanel value={value} index={0}>
                {setOpenWalletStyle && (
                  <>
                    <WalletForm
                      openWalletStyle={openWalletStyle}
                      setOpenWalletStyle={setOpenWalletStyle}
                    />
                  </>
                )}
              </TabPanel>
              <TabPanel value={value} index={1}>
                {setOpenWalletInfos && (
                  <>
                    <WalletForm
                      openWalletInfos={openWalletInfos}
                      setOpenWalletInfos={setOpenWalletInfos}
                    />
                  </>
                )}
              </TabPanel>
              <TabPanel value={value} index={2}>
                <>
                  <div className=' buttons-container'>
                    {(smsTitleError ||
                      newAccountSMSMessageError ||
                      newOfferSMSMessageError ||
                      newCouponSMSMessageError) && (
                      <div className='error-icon'>
                        <Icon
                          icon={ic_warning}
                          size={24}
                          style={{ color: '#FF5078' }}
                        />
                      </div>
                    )}

                    <Button onClick={handleClickConfirm}>
                      {loading ? (
                        <CircularProgress size={20} color='inherit' />
                      ) : (
                        intl.formatMessage({ id: 'settings.button.save' })
                      )}
                    </Button>
                  </div>

                  <div className='card'>
                    <Text
                      fontWeight={500}
                      fontSize={18}
                      lineHeight={24}
                      color='#404040'
                      style={{ alignSelf: 'flex-start' }}
                    >
                      Inscription
                    </Text>
                    <div className='card-inputs'>
                      <InputLabel
                        inputType='textArea'
                        label={intl.formatMessage({
                          id: 'settings.label.validationMessage',
                        })}
                        name='successMessage'
                        value={successMessage}
                        placeholder={intl.formatMessage({
                          id: 'settings.placeholder.letEmpty',
                        })}
                        onChange={(e) => handleEventChange(e)}
                      />{' '}
                      <InputLabel
                        inputType='textArea'
                        label={intl.formatMessage({
                          id: 'settings.label.newAccountSMSMessage',
                        })}
                        error={newAccountSMSMessageError}
                        name='newAccountSMSMessage'
                        value={newAccountSMSMessage}
                        placeholder={intl.formatMessage({
                          id: 'settings.placeholder.letEmpty',
                        })}
                        limit={maxChars.settings.newAccountSMSMessage}
                        onChange={(e) => handleEventChange(e)}
                      />
                      <TextEditor
                        label={intl.formatMessage({
                          id: 'settings.label.emailMessage',
                        })}
                        placeholder={intl.formatMessage({
                          id: 'settings.placeholder.emailMessage',
                        })}
                        oldState={emailMessage}
                        editorName='emailMessage'
                        changeValue={handleEditorMessageChange}
                      />
                      <InputLabel
                        inputType='textArea'
                        label={intl.formatMessage({
                          id: 'settings.label.inscriptionEmailCode',
                        })}
                        name='newAccountEmailHtmlCode'
                        value={newAccountEmailHtmlCode}
                        placeholder={intl.formatMessage({
                          id: 'settings.placeholder.letEmpty',
                        })}
                        spellCheck={false}
                        onChange={(e) => handleEventChange(e)}
                      />{' '}
                      <HtmlRenderer
                        socialMedia={socialMedia}
                        website={walletForm.website}
                        address={walletForm.address}
                        emailMessage={emailMessage}
                        logoPictureUrl={walletForm.logoPictureUrl}
                        htmlCode={newAccountEmailHtmlCode}
                      ></HtmlRenderer>
                    </div>
                  </div>

                  {isAllowed('offer') && (
                    <div className='card'>
                      <Text
                        fontWeight={500}
                        fontSize={18}
                        lineHeight={24}
                        color='#404040'
                        style={{ alignSelf: 'flex-start' }}
                      >
                        {intl.formatMessage({
                          id: 'settings.label.newOfferTitle',
                        })}
                      </Text>
                      <div className='card-inputs'>
                        <InputLabel
                          inputType='textArea'
                          label={intl.formatMessage({
                            id: 'settings.label.SMSMessage',
                          })}
                          name='newOfferSMSMessage'
                          value={newOfferSMSMessage}
                          error={newOfferSMSMessageError}
                          // width={480}

                          placeholder={intl.formatMessage({
                            id: 'settings.placeholder.letEmpty',
                          })}
                          limit={maxChars.settings.newOfferSMSMessage}
                          onChange={(e) => handleEventChange(e)}
                        />
                        <InputLabel
                          inputType='textArea'
                          label={intl.formatMessage({
                            id: 'settings.label.notificationMessage',
                          })}
                          error={newOfferNotificationMessageError}
                          name='newOfferNotificationMessage'
                          value={newOfferNotificationMessage}
                          // width={480}

                          placeholder={intl.formatMessage({
                            id: 'settings.placeholder.offerNotificationMessage',
                          })}
                          onChange={(e) => handleEventChange(e)}
                        />{' '}
                        <InputLabel
                          inputType='textArea'
                          label={intl.formatMessage({
                            id: 'settings.label.offerEmailMessage',
                          })}
                          name='newOfferEmailMessage'
                          value={newOfferEmailMessage}
                          placeholder={intl.formatMessage({
                            id: 'settings.placeholder.letEmpty',
                          })}
                          onChange={(e) => handleEventChange(e)}
                        />
                        <InputLabel
                          inputType='textArea'
                          label={intl.formatMessage({
                            id: 'settings.label.inscriptionEmailCode',
                          })}
                          name='offerEmailHtmlCode'
                          value={offerEmailHtmlCode}
                          placeholder={intl.formatMessage({
                            id: 'settings.placeholder.letEmpty',
                          })}
                          spellCheck={false}
                          onChange={(e) => handleEventChange(e)}
                        />{' '}
                        <HtmlRenderer
                          socialMedia={socialMedia}
                          website={walletForm.website}
                          address={walletForm.address}
                          logoPictureUrl={walletForm.logoPictureUrl}
                          emailMessage={newOfferEmailMessage}
                          htmlCode={offerEmailHtmlCode}
                        ></HtmlRenderer>
                      </div>
                    </div>
                  )}
                  {isAllowed('coupon') && (
                    <div className='card'>
                      <Text
                        fontWeight={500}
                        fontSize={18}
                        lineHeight={24}
                        color='#404040'
                        style={{ alignSelf: 'flex-start' }}
                      >
                        {intl.formatMessage({
                          id: 'settings.label.newCouponTitle',
                        })}
                      </Text>
                      <div className='card-inputs'>
                        <InputLabel
                          inputType='textArea'
                          label={intl.formatMessage({
                            id: 'settings.label.couponSMSMessage',
                          })}
                          name='newCouponSMSMessage'
                          value={newCouponSMSMessage}
                          // width={480}
                          error={newCouponSMSMessageError}
                          placeholder={intl.formatMessage({
                            id: 'settings.placeholder.couponSMSMessage',
                          })}
                          limit={maxChars.settings.newCouponSMSMessage}
                          onChange={(e) => handleEventChange(e)}
                        />
                        <InputLabel
                          inputType='textArea'
                          label={intl.formatMessage({
                            id: 'settings.label.couponNotificationMessage',
                          })}
                          error={newCouponNotificationMessageError}
                          name='newCouponNotificationMessage'
                          value={newCouponNotificationMessage}
                          // width={480}
                          placeholder={intl.formatMessage({
                            id:
                              'settings.placeholder.couponNotificationMessage',
                          })}
                          onChange={(e) => handleEventChange(e)}
                        />{' '}
                        <TextEditor
                          label={intl.formatMessage({
                            id: 'settings.label.couponEmailMessage',
                          })}
                          placeholder={intl.formatMessage({
                            id: 'settings.placeholder.couponEmailMessage',
                          })}
                          error={newCouponEmailMessageError}
                          oldState={newCouponEmailMessage}
                          editorName='newCouponEmailMessage'
                          changeValue={handleEditorMessageChange}
                        />
                        <InputLabel
                          inputType='textArea'
                          label={intl.formatMessage({
                            id: 'settings.label.inscriptionEmailCode',
                          })}
                          name='couponEmailHtmlCode'
                          value={couponEmailHtmlCode}
                          placeholder={
                            'Laisser vide pour appliquer la page par défaut '
                          }
                          spellCheck={false}
                          onChange={(e) => handleEventChange(e)}
                        />{' '}
                        <HtmlRenderer
                          socialMedia={socialMedia}
                          website={walletForm.website}
                          address={walletForm.address}
                          logoPictureUrl={walletForm.logoPictureUrl}
                          emailMessage={newCouponEmailMessage}
                          htmlCode={couponEmailHtmlCode}
                        ></HtmlRenderer>
                      </div>
                    </div>
                  )}
                  {isAllowed('offer') && (
                    <div className='card'>
                      <Text
                        fontWeight={500}
                        fontSize={18}
                        lineHeight={24}
                        color='#404040'
                        style={{ alignSelf: 'flex-start' }}
                      >
                        {intl.formatMessage({
                          id: 'settings.label.expiredOfferTitle',
                        })}
                      </Text>
                      <div className='card-inputs'>
                        <InputLabel
                          inputType='textArea'
                          // label={intl.formatMessage({
                          //   id: 'settings.label.couponEmailMessage',
                          // })}
                          label={intl.formatMessage({
                            id: 'settings.label.expiredOfferEmailText',
                          })}
                          name='expiredOfferEmailMessage'
                          value={expiredOfferEmailMessage}
                          // width={360}
                          // placeholder={intl.formatMessage({
                          //   id: 'settings.placeholder.couponEmailMessage',
                          // })}
                          placeholder={intl.formatMessage({
                            id: 'settings.placeholder.letEmpty',
                          })}
                          onChange={(e) => handleEventChange(e)}
                        />
                        <InputLabel
                          inputType='textArea'
                          // label={intl.formatMessage({
                          //   id: 'settings.label.couponNotificationMessage',
                          // })}
                          label={intl.formatMessage({
                            id: 'settings.label.expiredNotificationText',
                          })}
                          name='expiredOfferNotificationMessage'
                          value={expiredOfferNotificationMessage}
                          error={expiredOfferNotificationMessageError}
                          // width={480}
                          // placeholder={intl.formatMessage({
                          //   id: 'settings.placeholder.couponNotificationMessage',
                          // })}
                          placeholder={intl.formatMessage({
                            id: 'settings.placeholder.letEmpty',
                          })}
                          onChange={(e) => handleEventChange(e)}
                        />
                      </div>
                    </div>
                  )}
                  {isAllowed('boardingPass') && (
                    <div className='card'>
                      <Text
                        fontWeight={500}
                        fontSize={18}
                        lineHeight={24}
                        color='#404040'
                        style={{ alignSelf: 'flex-start' }}
                      >
                        {intl.formatMessage({
                          id: 'settings.label.flight',
                        })}
                      </Text>
                      <div className='card-inputs'>
                        <TextEditor
                          label={intl.formatMessage({
                            id: 'settings.label.newPassEmailMessage',
                          })}
                          placeholder={intl.formatMessage({
                            id: 'settings.placeholder.letEmpty',
                          })}
                          // error={flightEmailSubscriptionMessage}
                          oldState={flightEmailSubscriptionMessage}
                          editorName='flightEmailSubscriptionMessage'
                          changeValue={handleEditorMessageChange}
                        />{' '}
                        <InputLabel
                          inputType='textArea'
                          label={intl.formatMessage({
                            id: 'settings.label.inscriptionEmailCode',
                          })}
                          name='flightEmailHtmlCode'
                          value={flightEmailHtmlCode}
                          placeholder={intl.formatMessage({
                            id: 'settings.placeholder.letEmpty',
                          })}
                          spellCheck={false}
                          onChange={(e) => handleEventChange(e)}
                        />{' '}
                        <HtmlRenderer
                          socialMedia={socialMedia}
                          website={walletForm.website}
                          address={walletForm.address}
                          logoPictureUrl={walletForm.logoPictureUrl}
                          emailMessage={flightEmailSubscriptionMessage}
                          htmlCode={flightEmailHtmlCode}
                        ></HtmlRenderer>
                      </div>
                    </div>
                  )}
                  {isAllowed('transit') && (
                    <div className='card'>
                      <Text
                        fontWeight={500}
                        fontSize={18}
                        lineHeight={24}
                        color='#404040'
                        style={{ alignSelf: 'flex-start' }}
                      >
                        {intl.formatMessage({
                          id: 'settings.label.transit',
                        })}
                      </Text>
                      <div className='card-inputs'>
                        <TextEditor
                          label={intl.formatMessage({
                            id: 'settings.label.newPassEmailMessage',
                          })}
                          placeholder={intl.formatMessage({
                            id: 'settings.placeholder.letEmpty',
                          })}
                          // error={transitEmailSubscriptionMessage}
                          oldState={transitEmailSubscriptionMessage}
                          editorName='transitEmailSubscriptionMessage'
                          changeValue={handleEditorMessageChange}
                        />{' '}
                        <InputLabel
                          inputType='textArea'
                          label={intl.formatMessage({
                            id: 'settings.label.inscriptionEmailCode',
                          })}
                          name='trainEmailHtmlCode'
                          value={trainEmailHtmlCode}
                          placeholder={intl.formatMessage({
                            id: 'settings.placeholder.letEmpty',
                          })}
                          spellCheck={false}
                          onChange={(e) => handleEventChange(e)}
                        />{' '}
                        <HtmlRenderer
                          socialMedia={socialMedia}
                          website={walletForm.website}
                          address={walletForm.address}
                          logoPictureUrl={walletForm.logoPictureUrl}
                          emailMessage={transitEmailSubscriptionMessage}
                          htmlCode={trainEmailHtmlCode}
                        ></HtmlRenderer>
                      </div>
                    </div>
                  )}
                  {isAllowed('hotelPass') && (
                    <div className='card'>
                      <Text
                        fontWeight={500}
                        fontSize={18}
                        lineHeight={24}
                        color='#404040'
                        style={{ alignSelf: 'flex-start' }}
                      >
                        Carte de réservation d'hôtel
                      </Text>
                      <div className='card-inputs'>
                        <TextEditor
                          label={intl.formatMessage({
                            id: 'settings.label.newPassEmailMessage',
                          })}
                          placeholder={intl.formatMessage({
                            id: 'settings.placeholder.letEmpty',
                          })}
                          oldState={hotelEmailSubscriptionMessage}
                          editorName='hotelEmailSubscriptionMessage'
                          changeValue={handleEditorMessageChange}
                        />{' '}
                        <InputLabel
                          inputType='textArea'
                          label={intl.formatMessage({
                            id: 'settings.label.inscriptionEmailCode',
                          })}
                          name='hotelEmailHtmlCode'
                          value={hotelEmailHtmlCode}
                          placeholder={intl.formatMessage({
                            id: 'settings.placeholder.letEmpty',
                          })}
                          spellCheck={false}
                          onChange={(e) => handleEventChange(e)}
                        />{' '}
                        <HtmlRenderer
                          socialMedia={socialMedia}
                          website={walletForm.website}
                          address={walletForm.address}
                          logoPictureUrl={walletForm.logoPictureUrl}
                          emailMessage={hotelEmailSubscriptionMessage}
                          htmlCode={hotelEmailHtmlCode}
                        ></HtmlRenderer>
                      </div>
                    </div>
                  )}
                  {isAllowed('cinemaPass') && (
                    <div className='card'>
                      <Text
                        fontWeight={500}
                        fontSize={18}
                        lineHeight={24}
                        color='#404040'
                        style={{ alignSelf: 'flex-start' }}
                      >
                        Billet de cinéma
                      </Text>
                      <div className='card-inputs'>
                        <TextEditor
                          label={intl.formatMessage({
                            id: 'settings.label.newPassEmailMessage',
                          })}
                          placeholder={intl.formatMessage({
                            id: 'settings.placeholder.newPassEmailMessage',
                          })}
                          // error={cinemaEmailSubscriptionMessage}
                          oldState={cinemaEmailSubscriptionMessage}
                          editorName='cinemaEmailSubscriptionMessage'
                          changeValue={handleEditorMessageChange}
                        />{' '}
                        <InputLabel
                          inputType='textArea'
                          label={intl.formatMessage({
                            id: 'settings.label.inscriptionEmailCode',
                          })}
                          name='cinemaEmailHtmlCode'
                          value={cinemaEmailHtmlCode}
                          placeholder={intl.formatMessage({
                            id: 'settings.placeholder.letEmpty',
                          })}
                          spellCheck={false}
                          onChange={(e) => handleEventChange(e)}
                        />{' '}
                        <HtmlRenderer
                          socialMedia={socialMedia}
                          website={walletForm.website}
                          address={walletForm.address}
                          logoPictureUrl={walletForm.logoPictureUrl}
                          emailMessage={cinemaEmailSubscriptionMessage}
                          htmlCode={cinemaEmailHtmlCode}
                        ></HtmlRenderer>
                      </div>
                    </div>
                  )}
                </>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <>
                  <div className=' buttons-container'>
                    {(smsTitleError ||
                      newAccountSMSMessageError ||
                      newOfferSMSMessageError ||
                      newCouponSMSMessageError) && (
                      <div className='error-icon'>
                        <Icon
                          icon={ic_warning}
                          size={24}
                          style={{ color: '#FF5078' }}
                        />
                      </div>
                    )}

                    <Button onClick={handleClickConfirm}>
                      {loading ? (
                        <CircularProgress size={20} color='inherit' />
                      ) : (
                        intl.formatMessage({ id: 'settings.button.save' })
                      )}
                    </Button>
                  </div>
                  <div className='card'>
                    <Text
                      fontWeight={500}
                      fontSize={18}
                      lineHeight={24}
                      color='#404040'
                      style={{ alignSelf: 'flex-start' }}
                    >
                      Mise à jour
                    </Text>
                    <div className='card-inputs'>
                      <InputLabel
                        inputType='textArea'
                        // label={intl.formatMessage({
                        //   id: 'settings.label.couponEmailMessage',
                        // })}
                        label={intl.formatMessage({
                          id: 'settings.label.emailUpdateText',
                        })}
                        name='newUpdateEmailMessage'
                        value={newUpdateEmailMessage}
                        placeholder={intl.formatMessage({
                          id: 'settings.placeholder.letEmpty',
                        })}
                        onChange={(e) => handleEventChange(e)}
                      />{' '}
                      <InputLabel
                        inputType='textArea'
                        label={intl.formatMessage({
                          id: 'settings.label.emailUpdateText',
                        })}
                        name='storeCardUpdateEmailHtmlCode'
                        value={storeCardUpdateEmailHtmlCode}
                        placeholder={intl.formatMessage({
                          id: 'settings.placeholder.letEmpty',
                        })}
                        spellCheck={false}
                        onChange={(e) => handleEventChange(e)}
                      />{' '}
                      <HtmlRenderer
                        socialMedia={socialMedia}
                        website={walletForm.website}
                        address={walletForm.address}
                        logoPictureUrl={walletForm.logoPictureUrl}
                        emailMessage={newUpdateEmailMessage}
                        htmlCode={storeCardUpdateEmailHtmlCode}
                      ></HtmlRenderer>
                      <InputLabel
                        inputType='textArea'
                        label={intl.formatMessage({
                          id: 'settings.label.updateNotificationText',
                        })}
                        name='newUpdateNotificationMessage'
                        error={newUpdateNotificationMessageError}
                        value={newUpdateNotificationMessage}
                        placeholder={intl.formatMessage({
                          id: 'settings.placeholder.letEmpty',
                        })}
                        limit={maxChars.settings.updateNotificationText}
                        onChange={(e) => handleEventChange(e)}
                      />
                    </div>

                    <NotificationsSettingsTable
                      {...{
                        enableNewUpdateEmailMessage,
                        enableOfferEmailMessage,
                        enableOfferSMSMessage,
                        enableCouponEmailMessage,
                        enableCouponSMSMessage,
                        //storecard
                        enableNewStorecardEmailMessage,
                        enableStorecardRemainderEmailMessage,
                        enableNewStorecardSMSMessage,
                        enableStorecardRemainderSMSMessage,
                        enableNewUpdateNotification,
                        enableStorecardReminderEmailMessage,
                        enableStorecardReminderSMSMessage,
                        //event
                        enableUpdateEventEmailMessage,
                        enableUpdateEventNotification,
                        //coupon
                        enableWelcomeCouponEmailMessageForExternalClients,
                        enableWelcomeCouponEmailMessageForInternalClients,
                        enableWelcomeCouponEmailMessageForCRMClients,
                        enableUpdateCouponEmailMessage,
                        enableUpdateCouponSMSMessage,
                        enableWelcomeCouponSMSMessageForExternalClients,
                        enableWelcomeCouponSMSMessageForInternalClients,
                        enableWelcomeCouponSMSMessageForCRMClients,
                        enableWelcomeCouponNotificationMessageForExternalClients,
                        enableWelcomeCouponNotificationMessageForInternalClients,
                        enableWelcomeCouponNotificationMessageForCRMClients,
                        enableUpdateCouponNotificationMessage,
                        enableNewCouponNotificationMessage,
                        //offer
                        enableExpiredOfferEmailMessage,
                        enableNewOfferNotificationMessage,
                        enableExpiredOfferNotificationMessage,
                        enableExpiredOfferSMSMessage,
                      }}
                      handleChangeNotificationsCheckBox={handleChangeCheckBox}
                    ></NotificationsSettingsTable>
                  </div>
                  <div className='card'>
                    <Text
                      fontWeight={500}
                      fontSize={18}
                      lineHeight={24}
                      color='#404040'
                      style={{ alignSelf: 'flex-start' }}
                    >
                      SMS
                    </Text>
                    <div className='card-inputs'>
                      <InputLabel
                        label={intl.formatMessage({
                          id: 'settings.label.smsTitle',
                        })}
                        name='smsTitle'
                        value={smsTitle}
                        error={smsTitleError}
                        placeholder={walletForm.name}
                        limit={maxChars.settings.smsTitle || maxChars.smsTitle}
                        onChange={(e) => handleEventChange(e)}
                      />
                    </div>
                  </div>{' '}
                </>
              </TabPanel>
            </div>
          </div>
        </Wrapper>
      )}
    </SideBar>
  );
};

const Wrapper = styled('div')`
  ${(props) => settingsStyles(props)}
`;

const mapStateToProps = ({ settings }: RootState) => {
  const {
    busEmailHtmlCode,

    //storecard
    enableNewStorecardEmailMessage,
    enableStorecardRemainderEmailMessage,
    enableNewStorecardSMSMessage,
    enableStorecardRemainderSMSMessage,
    enableNewUpdateNotification,
    enableStorecardReminderEmailMessage,
    enableStorecardReminderSMSMessage,
    //event
    enableUpdateEventEmailMessage,
    enableUpdateEventNotification,

    //coupon
    enableCouponEmailMessage,
    enableUpdateCouponEmailMessage,
    enableWelcomeCouponEmailMessageForExternalClients,
    enableWelcomeCouponEmailMessageForInternalClients,
    enableWelcomeCouponEmailMessageForCRMClients,
    enableCouponSMSMessage,
    enableUpdateCouponSMSMessage,
    enableExpiredCouponSMSMessage,
    enableWelcomeCouponSMSMessageForExternalClients,
    enableWelcomeCouponSMSMessageForInternalClients,
    enableWelcomeCouponSMSMessageForCRMClients,
    enableWelcomeCouponNotificationMessageForExternalClients,
    enableWelcomeCouponNotificationMessageForInternalClients,
    enableWelcomeCouponNotificationMessageForCRMClients,
    enableUpdateCouponNotificationMessage,
    enableNewCouponNotificationMessage,
    enableExpiredOfferSMSMessage,
    logo,
    icon,
    strip,
    wallet,
    walletForm,
    successMessage,
    emailMessage,
    newOfferEmailMessage,
    newOfferNotificationMessage,
    newCouponEmailMessage,
    newCouponNotificationMessage,
    expiredOfferEmailMessage,
    expiredOfferNotificationMessage,
    newUpdateEmailMessage,
    newUpdateNotificationMessage,
    enableNewUpdateEmailMessage,
    enableOfferEmailMessage,
    enableOfferSMSMessage,
    communicationChannels,
    smsTitle,
    smsTitleError,
    loading,
    newAccountSMSMessage,
    newEventSMSMessage,
    newOfferSMSMessage,
    expiredOfferSMSMessage,
    newCouponSMSMessage,

    newUpdateSMSMessage,
    newCouponSMSMessageError,
    showBarcode,
    newAccountSMSMessageError,
    newOfferSMSMessageError,
    hasSMSAccount,
    smsCredits,
    newCouponEmailMessageError,
    newUpdateNotificationMessageError,
    newOfferNotificationMessageError,
    newCouponNotificationMessageError,
    expiredOfferNotificationMessageError,
    loadingSaveCommunicationChannels,
    hotelEmailSubscriptionMessage,
    cinemaEmailSubscriptionMessage,
    transitEmailSubscriptionMessage,

    flightEmailSubscriptionMessage,
    trainEmailHtmlCode,
    boatEmailHtmlCode,
    hotelEmailHtmlCode,
    cinemaEmailHtmlCode,
    flightEmailHtmlCode,
    eventEmailHtmlCode,
    couponEmailHtmlCode,
    newAccountEmailHtmlCode,
    offerEmailHtmlCode,
    storeCardUpdateEmailHtmlCode,
    enableNewOfferNotificationMessage,
    enableExpiredOfferEmailMessage,
    enableExpiredOfferNotificationMessage,
  } = settings;
  return {
    enableExpiredOfferEmailMessage,
    enableExpiredOfferNotificationMessage,
    busEmailHtmlCode,
    //storecard
    enableNewStorecardEmailMessage,
    enableStorecardRemainderEmailMessage,
    enableNewStorecardSMSMessage,
    enableStorecardRemainderSMSMessage,
    enableNewUpdateNotification,
    enableStorecardReminderEmailMessage,
    enableStorecardReminderSMSMessage,
    //event
    enableUpdateEventEmailMessage,
    enableUpdateEventNotification,
    //coupon
    enableCouponEmailMessage,
    enableUpdateCouponEmailMessage,
    enableWelcomeCouponEmailMessageForExternalClients,
    enableWelcomeCouponEmailMessageForInternalClients,
    enableWelcomeCouponEmailMessageForCRMClients,
    enableCouponSMSMessage,
    enableUpdateCouponSMSMessage,
    enableExpiredCouponSMSMessage,
    enableWelcomeCouponSMSMessageForExternalClients,
    enableWelcomeCouponSMSMessageForInternalClients,
    enableWelcomeCouponSMSMessageForCRMClients,
    enableWelcomeCouponNotificationMessageForExternalClients,
    enableWelcomeCouponNotificationMessageForInternalClients,
    enableWelcomeCouponNotificationMessageForCRMClients,
    enableUpdateCouponNotificationMessage,
    enableExpiredOfferSMSMessage,
    enableNewOfferNotificationMessage,
    enableNewCouponNotificationMessage,
    logo,
    icon,
    strip,
    wallet,
    walletForm,
    socialMedia: walletForm.socialMedia || {
      facebook: '',
      linkedin: '',
      instagram: '',
    },
    successMessage,
    emailMessage,
    newOfferEmailMessage,
    showBarcode,
    enableOfferSMSMessage,
    newOfferNotificationMessage,
    newCouponEmailMessage,
    newCouponNotificationMessage,
    expiredOfferEmailMessage,
    expiredOfferNotificationMessage,
    newUpdateEmailMessage,
    newUpdateNotificationMessage,
    enableNewUpdateEmailMessage,
    enableOfferEmailMessage,
    communicationChannels,
    hotelEmailSubscriptionMessage,
    cinemaEmailSubscriptionMessage,
    transitEmailSubscriptionMessage,
    flightEmailSubscriptionMessage,
    trainEmailHtmlCode,
    boatEmailHtmlCode,
    hotelEmailHtmlCode,
    cinemaEmailHtmlCode,
    flightEmailHtmlCode,
    eventEmailHtmlCode,
    newAccountEmailHtmlCode,
    offerEmailHtmlCode,
    storeCardUpdateEmailHtmlCode,

    couponEmailHtmlCode,
    smsTitle,
    hasSMSAccount,
    smsCredits,
    smsTitleError,
    newAccountSMSMessage,
    newEventSMSMessage,
    newOfferSMSMessage,
    expiredOfferSMSMessage,
    newCouponSMSMessage,
    newCouponSMSMessageError,
    newUpdateNotificationMessageError,
    newAccountSMSMessageError,
    newOfferSMSMessageError,
    newUpdateSMSMessage,
    newCouponEmailMessageError,
    newOfferNotificationMessageError,
    newCouponNotificationMessageError,
    expiredOfferNotificationMessageError,
    loadingSaveCommunicationChannels,
    //loading
    loading,
  };
};

export const connector = connect(mapStateToProps, {
  settingsPropsChanged,
  getWalletSettings,
  getWalletDetail,
  confirmSettings,
  setWallet,
  showSMSAccountError,
  settingsInitialState,
});

export default connector(Settings);
