import { createRail, getRail, updateRail, editRail } from '../apis/railApi';
import {
  RAIL_FORM_PROPS,
  RAIL_FORM_PROPS_CHANGED,
  AppThunk,
  RAILS_ADD_RAIL_TO_LIST,
  RAIL_INITIAL_STATE,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
  RAIL_GET_RAIL_PROPS,
  RAILS_GET_LIST_RAILS,
  EDIT_RAIL_INITIAL_STATE,
  RAIL_PROPS_CHANGED,
  CUSTOMER_RAIL_FORM_PROPS_CHANGED,
  CUSTOMER_TRAM_FORM_PROPS,
  CUSTOMER_RAIL_FORM_PROPS,
} from './types';
import {
  CustomerRailPropsChangedAction,
  RailInitialStateAction,
  RailPropsChangedAction,
  RailState,
} from '../modules/Rail/rail.types';

import {
  validateFieldsRail,
  newRailObject,
  validateCustomerFieldsRail,
} from '../modules/Rail/rail.utils';
import { URL_IMAGE } from '../apis/config';
import { getRailsList } from '../apis/railsApi';
import { TransitsApiResponse } from '../apis/ApiTypes';
import moment from 'moment';

export const railPropsChanged = (
  prop: string,
  value: any
): RailPropsChangedAction => {
  return { type: RAIL_FORM_PROPS_CHANGED, prop, value };
};

export const addRail = (
  walletId: string | null,
  props: RailState,
  callback: () => void
): AppThunk => (dispatch) => {
  const { newState, valid } = validateFieldsRail(props);
  dispatch({ type: RAIL_FORM_PROPS, newState });
  const newRail = newRailObject(newState);

  if (valid) {
    dispatch({
      type: RAIL_FORM_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });

    createRail(walletId, newRail).then((res: any) => {
      if (res.status == 200) {
        dispatch({
          type: RAILS_ADD_RAIL_TO_LIST,
          payload: res.data,
        });
        dispatch({
          type: SNACKBAR_SUCCESS,
          value: 'transit.succes.added',
        });
        callback();
      } else {
        dispatch({
          type: SNACKBAR_ERROR,
          value: 'wallet.error.occured',
        });
        dispatch({
          type: RAIL_FORM_PROPS_CHANGED,
          prop: 'loading',
          value: false,
        });
      }
    });
  }
};

export const getRailbyId = (walletId: string, railId: string): AppThunk => (
  dispatch
) => {
  getRail(walletId, railId).then((res: any) => {
    const rail = res.response.data;
    dispatch({
      type: RAIL_GET_RAIL_PROPS,
      payload: {
        ...rail,
      },
    });
  });
};

export const editRailConfirm = (
  walletId: string | null,
  railId: string | null,
  props: RailState,
  callback: () => void
): AppThunk => (dispatch) => {
  const { newState, valid } = validateFieldsRail(props);
  dispatch({ type: RAIL_FORM_PROPS, newState });
  const newRail = newRailObject(newState);

  if (valid) {
    dispatch({
      type: RAIL_FORM_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });

    updateRail(walletId, railId, newRail)
      .then((res: any) => {
        if (res.status === 200) {
          dispatch({
            type: SNACKBAR_SUCCESS,
            value: 'transit.success.updated',
          });

          callback();
        } else if (res.status === 400) {
          dispatch({
            type: RAIL_FORM_PROPS_CHANGED,
            prop: 'messageError',
            value: 'coupon.error.datesUnavailable',
          });
          dispatch({
            type: SNACKBAR_ERROR,
            value: 'wallet.error.occured',
          });
        }
        dispatch({
          type: RAIL_FORM_PROPS_CHANGED,
          prop: 'loading',
          value: false,
        });
      })
      .finally(() => {
        getRailsList(walletId as any).then(
          (res: Array<TransitsApiResponse>) => {
            dispatch({
              type: RAILS_GET_LIST_RAILS,
              payload: res,
            });
          }
        );
      });
  }
};

export const railInitialState = (): RailInitialStateAction => {
  return { type: RAIL_INITIAL_STATE };
};
export const editRailInitialState = (rail: any): any => {
  return {
    type: EDIT_RAIL_INITIAL_STATE,
    prop: 'payload',
    value: {
      ...rail,
      image: URL_IMAGE + '/' + rail.transitPictureUrl,
    },
  };
};

export const editRailCustomerConfirm = (
  walletId: string | null,
  railId: string | null,
  customerId: string | null,
  props: RailState,
  callback: () => void
): AppThunk => (dispatch) => {
  const { newState, valid } = validateCustomerFieldsRail(props);
  dispatch({ type: CUSTOMER_RAIL_FORM_PROPS, newState });
  const { passengerType, tripType, arrivalDate, departureDate } = newState;
  const railObject = {
    passengerType,
    tripType,
    arrivalDate: arrivalDate.format('YYYY-MM-DD').slice(0, 10),
    departureDate: moment(departureDate).format('YYYY-MM-DD'),
    customerId,
  };
  if (valid) {
    dispatch({
      type: RAIL_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });

    editRail(walletId, railId, railObject).then((res: any) => {
      if (res.status === 200) {
        dispatch({
          type: SNACKBAR_SUCCESS,
          value: 'transit.success.updated',
        });

        callback();
      } else if (res.status === 400) {
        dispatch({
          type: RAIL_PROPS_CHANGED,
          prop: 'messageError',
          value: 'coupon.error.datesUnavailable',
        });
        dispatch({
          type: SNACKBAR_ERROR,
          value: 'wallet.error.occured',
        });
      }
      dispatch({
        type: RAIL_PROPS_CHANGED,
        prop: 'loading',
        value: false,
      });
    });
    // .finally(() => {
    //   dispatch({
    //     type: UPDATE_SENT_RAILS_LIST,
    //     row: { ...objectData, _id: railId },
    //   });
    // });
  }
};

export const customerRailPropsChanged = (
  prop: string,
  value: any
): CustomerRailPropsChangedAction => {
  return { type: CUSTOMER_RAIL_FORM_PROPS_CHANGED, prop, value };
};
