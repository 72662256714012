import styled from 'styled-components';

export const Wrapper = styled('div')<{
  backgroundColor: any;
  mainContentTextColor: string;
  textColor: string;
  titleColor: string;
}>`
  width: 100%;
  height: 100%;
  width: 350px;
  background-color: #edecf1;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;

  .hidden {
    visibility: hidden;
  }

  .divider {
    flex-grow: 1;
    border-bottom: 1px solid #eeedf1;
    margin: 5px;
    width: 100% !important;
    height: 0 !important;
    width: 100%;
  }

  .mainSection-header-more-logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 20px;
    i {
      background-color: black;
      border-radius: 50%;
      color: rgb(255, 255, 255);
      padding: 2px 10px;
    }
  }

  .mainSection {
    margin-top: 5px;
    background-color: ${(props) => props.backgroundColor.hex};
    width: 100%;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    height: fit-content;

    .mainSection-header {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .mainSection-header-logo {
      height: 50px;
      width: 50px;
      overflow: hidden;
      img {
        object-fit: contain;
        height: 50px;
        width: 50px;
      }
    }
    .mainSection-header-logoSection {
      display: flex;
      align-items: center;
      img {
        overflow: hidden;
        max-width: 160px;
        max-height: 50px;
        object-fit: contain;
      }
    }
    .mainSection-content-banner {
      height: 100px;
      margin-top: 20px;
      overflow: hidden;
      img {
        object-fit: fill;
        height: 100px;
        width: 100%;
      }
    }
    .mainSection-content-enseigne {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
      h4 {
        font-weight: bold;
        text-align: center;
        color: ${(props) => props.titleColor};
        margin: 0;
        margin-left: 15px;
        word-break: break-all;
      }
      h3 {
        font-weight: 400;
        text-align: center;
        color: ${(props) => props.textColor};
        margin: 0;
      }
    }
    .mainSection-content-name {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
      h5 {
        text-align: center;
        color: ${(props) => props.titleColor};
        margin: 0;
        margin-left: 15px;
        word-break: break-all;
      }
    }

    .mainSection-content-datePoints {
      display: flex;
      margin-top: 20px;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      .points {
        text-align: right;
      }
    }
    .mainSection-origin-destination {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .mainSection-origin-name,
    .mainSection-destination-name {
      letter-spacing: 0.07272727em;
      font-family: Roboto, Arial, sans-serif;
      font-size: 0.6875rem;
      font-weight: 500;
      text-transform: uppercase;
      line-height: 11px;
      padding-bottom: 5px;
      min-height: 11px;
      overflow-wrap: break-word;
      width: 100%;
      word-wrap: break-word;
    }

    .arrow {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .arrivalDate > h3:nth-child(2) {
      text-align: right;
    }
    .mainSection-destination-destinationCode {
      text-align: right;
    }

    .mainSection-header-eventName {
      color: ${(props) => props.titleColor};
    }
    .mainSection-content-barCode > div:nth-child(1) {
      width: 100%;
      display: flex !important;
      justify-content: center !important;
    }
    .mainSection-content-barCode {
      margin-top: 133px;
      margin-bottom: 23px;
      width: 100%;

      .barCode {
        width: 270px;
        margin: auto;
        background: #ffffff;
        height: 100px;
        display: flex;
        border-radius: 3px;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        img {
          width: 235px;
          height: 58px;
          align-self: center;
          margin-top: 11px;
        }
      }
      .walletId {
        font-size: 12px;
        color: black;
        text-align: center;
        margin: 7px 0px 10px 0px;
      }
    }
    div.points:nth-child(2) > h3:nth-child(1) {
      text-align: center;
    }
    div.date:nth-child(2) > h3:nth-child(1) {
      text-align: right;
    }

    div.points:nth-child(1) > h3:nth-child(2) {
      text-align: left;
    }
    .mainSection-label {
      color: ${(props) => props.titleColor};
      font-weight: 600;
      text-transform: uppercase;
      font-size: 10px;
      letter-spacing: 0.1em;
      margin: 0px;
    }
    .mainSection-data {
      color: ${(props) => props.textColor};
      font-size: 12px;
      font-weight: 400;
      margin: 0px;
    }
    .mainSection-labels {
      color: ${(props) => props.titleColor};
      font-weight: 500;
      text-transform: uppercase;
      font-size: 18px;
      letter-spacing: 0.1em;
      margin: 0px;
      .right {
        margin-left: 30px;
      }
    }
    .mainSection-labels-nice {
      color: ${(props) => props.titleColor};
      font-weight: 500;
      text-transform: uppercase;
      font-size: 18px;
      letter-spacing: 0.1em;
      margin: 0px;

      margin-left: 30px;
    }
    .mainSection-datas {
      color: ${(props) => props.textColor};
      font-size: 40px;
      font-weight: 300;
      margin: 0px;
    }
  }
  .dataSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 0px 10px 20px 10px;
    margin-top: 17px;
    background-color: #ffffff;
    .sectionIcon {
      margin-top: 20px;
      gap: 10px;
      align-items: center;
      display: flex;
      .data {
        color: #9b9b9e;
        font-weight: 500;
        font-size: 14px;
        margin: 0px;
        word-break: break-all;
      }
    }
    .sectionLabel {
      margin-top: 6px;
      margin-bottom: 6px;
      .label {
        color: #9b9b9e;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 0.075em;
        margin: 0px;
      }
      .data {
        color: #9b9b9e;
        font-weight: 500;
        font-size: 14px;
        margin: 0px;
        word-break: break-all;
      }
    }
  }
  .firstSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
  }
  .BackFlip_btn {
    display: flex;
    justify-content: flex-end;
  }
  .backflip {
    background-color: #edecf1;
    border-radius: 50%;
    padding-bottom: 2px;
    transform: rotateY(180deg);
  }
  /* .mainSection-content-datePoints {
      display: flex;
      margin-top: 20px;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      .points {
        text-align: right;
      }
    } */
`;
