import axios from 'axios';
import { URL } from './config';

export const getTram = (walletId: string, tramId: string) => {
  const requestURL = `${URL}/wallets/${walletId}/transits/${tramId}`;

  return axios
    .get(requestURL)
    .then(({ data }: { data: any }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const createTram = (walledId: string | null, tram: any) => {
  // tram.locations = [
  //   {
  //     longitude: 0,
  //     latitude: 0,
  //   },
  // ];
  // tram.type = 'tram';
  const requestURL = `${URL}/wallets/${walledId}/transits?=tram`;
  return axios
    .post(requestURL, tram)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export const updateTram = (
  walledId: string | null,
  tramId: string | null,
  tram: any
): Promise<any> => {
  const requestURL = `${URL}/wallets/${walledId}/transits/${tramId}`;
  return axios
    .put(requestURL, tram)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export const removeTram = (tramId: string, walletId: string) => {
  const requestURL = `${URL}/wallets/${walletId}/transits/${tramId}`;

  return axios
    .delete(requestURL)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export const editTram = (
  walletId: string | null,
  tramId: string | null,
  tram: any
) => {
  const requestURL = `${URL}/wallets/${walletId}/transits/${tramId}`;
  return axios
    .put(requestURL, tram)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export const editCustomerTram = (
  walletId: string | null,
  tramId: string | null,
  tram: any
) => {
  const requestURL = `${URL}/wallets/${walletId}/transits/${tramId}/customer`;
  return axios
    .put(requestURL, tram)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};