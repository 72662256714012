export default {
  //**************************************Input File****************************//
  //Buttons
  'inputFile.button.select': 'Select image',
  //**************************************Dialog****************************//
  //Buttons
  'dialog.button.agree': 'Yes',
  'dialog.button.desagree': 'No',
  //**************************************DRAWER****************************//
  //Menu List
  'drawer.menuList.wallet': 'Wallet',
  'drawer.menuList.offers': 'Offer',
  'drawer.menuList.coupons': 'Coupons',
  'drawer.menuList.event': 'Event',
  'drawer.menuList.settings': 'Settings',
  'drawer.menuList.stats': 'Statistics',
  'drawer.menuList.ships': 'Ships',
  'drawer.menuList.buses': 'Bus',
  'drawer.menuList.trams': 'Metro',
  'drawer.menuList.rails': 'Train',
  'drawer.menuList.cinemas': 'Cinema',
  'drawer.menuList.flights': 'Flights',
  'drawer.menuList.hotels': 'Rooms',
  //Menu Item
  'drawer.menuItem.profile': 'Profile',
  'drawer.menuItem.logout': 'Logout',
  //**************************************Login****************************//
  //Labels
  'login.label.forgotPassword': 'Forgot Password ?',
  //Placeholder
  'login.placeholder.username': 'Username',
  'login.placeholder.password': 'Password',
  //Buttons
  'login.button.login': 'Login',
  //Error
  'login.error.fieldsReqiured': 'username and password are required',
  'login.error.incorrectUsernameOrPassword':
    'Username or password is incorrect',
  'login.error.userNotAuthorized': 'User is not authorized',
  //**************************************Settings****************************//
  //Settings Languages
  'settings.langauges.english': 'English',
  'settings.langauges.french': 'French',
  'settings.langauges.german': 'German',
  //Settings Labels
  'settings.label.settingsTitle': 'Settings',
  'settings.label.registrationParameterTitle': 'Registration parameter:',
  'settings.label.link': 'Link:',
  'settings.label.QrCode': 'QR Code:',
  'settings.label.validationMessage': 'Registration validation message:',
  'settings.label.emailMessage': 'Registration email message:',
  'settings.label.offerEmailMessage': 'Offer / communication email message:',
  'settings.label.couponEmailMessage': 'Coupon email message:',
  'settings.label.couponNotificationMessage': 'Notification text',
  'settings.label.couponSMSMessage': 'SMS text',
  'settings.label.pushNotificationMessage': 'Notification text',

  'settings.label.changeLanguageTitle': 'Change language :',
  'settings.label.linkCopied': 'Link copied',
  'settings.label.subscriptionMessage': 'Subscription message',
  'settings.label.note':
    "You are currently using Bestwallet's email service, if you wish to use your SMTP configuration, please contact the administrator",
  'settings.label.smsTitle': 'SMS title',
  'settings.label.inscriptionSettings': 'Inscription settings',
  'settings.label.walletIdentifier': 'Identifier',
  'settings.label.inscriptionLink': 'Link',
  'settings.label.showBarcodeText':
    'Show barcode field when adding a new client',
  'settings.label.communicationChannels': 'Communication channels',
  'settings.label.email': 'Email',
  'settings.label.phoneNumber': 'Phone number',
  'settings.label.emailAndPhoneNumber': 'Email and phone number',
  'settings.label.styles': 'Styles',
  'settings.label.messages': 'Messages',
  'settings.label.information': 'Information',
  'settings.label.notifications': 'Notifications',
  'settings.label.firstField': 'First Field',
  'settings.label.secondField': 'Second Field',
  'settings.label.headerFields': 'Header Fields',
  'settings.label.personalizedText': 'Personalized text',
  'settings.label.primaryFields': 'Primary Fields',
  'settings.label.creationDate': 'Creation date',

  'settings.label.rewards': 'Rewards',
  'settings.label.type': 'Type',
  'settings.label.fullName': 'Full name',
  'settings.label.text': 'Text',
  'settings.label.disabled': 'Disabled',

  'sent.success': 'Sent successfully',

  // Tabs
  // Wallet Style

  'settings.label.walletStyle': 'Wallet style',
  'settings.label.styles.titlesColor': 'Titles (iOS)',
  'settings.label.styles.textColor': 'Text (iOS)',
  'settings.label.styles.backgroundColor': 'Background (iOS-Android)',
  'settings.label.styles.changeLogo': 'Change the wallet logo (200px/200px)',

  'settings.label.styles.changeIcon':
    "Change the notification's icon (29px/29px)",
  'settings.label.styles.changeStrip': 'Change the strip image (240px*400px) ',

  //Information Tab
  'settings.label.accountInformation': 'Account information',
  'settings.label.userName': 'Username',
  'settings.label.password': 'Password',
  'settings.label.communicationEmail': 'Communication email',

  'settings.label.walletInformation': 'Wallet Information',
  'settings.label.walletName': 'Wallet name',
  'settings.label.showWalletName': 'Show wallet name',
  'settings.label.commercialPhone': 'Commercial number',
  'settings.label.afterSalePhone': 'After-Sales service phone number',
  'settings.label.website': 'Website',
  'settings.label.address': 'Adress',
  'settings.label.openingHours': 'Opening Hours',

  'settings.placeholder.walletInformation': 'Wallet Information',
  'settings.placeholder.walletName': 'Wallet name',
  'settings.placeholder.showWalletName': 'Show wallet name',
  'settings.placeholder.commercialPhone': 'Commercial name',
  'settings.placeholder.afterSalePhone': 'After-Sales service phone number',
  'settings.placeholder.website': 'Website',
  'settings.placeholder.address': 'Adress',
  'settings.placeholder.openingHours': 'Opening Hours',

  //additionalContacts

  'settings.label.additionalContactTitle':
    'Additional contacts (Gérant, Responsable administratif ...)',
  'settings.label.additionalContact': 'Additional contact',
  'settings.label.title': 'Title',
  'settings.label.fieldType': 'Type',
  'settings.label.url': 'URL',
  'settings.label.value': 'Value',
  'settings.label.addAdditionalContact': 'Add additional contact',
  'settings.label.transit': 'Transits',
  'settings.placeholder.additionalContactTitle':
    'Additional contacts (Gérant, Responsable administratif ...)',
  'settings.placeholder.additionalContact': 'Additional contact',
  'settings.placeholder.title': 'Title',
  'settings.placeholder.fieldType': 'Type',
  'settings.placeholder.url': 'URL',
  'settings.placeholder.value': 'Value',
  'settings.placeholder.addAdditionalContact': 'Add an additional contact',

  // locationlisation

  'settings.label.locatisation': 'Localisation',
  'settings.label.latitude': 'Latitude',
  'settings.label.longitude': 'Longitude',
  'settings.label.locatisationNotificationMessage':
    'Location change notification message',
  'settings.label.description': 'Description',

  'settings.button.addDescription': 'Add a description',
  'settings.button.delete': 'Delete',

  'settings.placeholder.locatisation': 'Localisation',
  'settings.placeholder.latitude': 'Latitude',
  'settings.placeholder.longitude': 'Longitude',
  'settings.placeholder.locatisationNotificationMessage':
    'Location change notification message',
  'settings.placeholder.descriptionTitle': 'Description title',
  'settings.placeholder.description': 'Description',
  'settings.placeholder.addDescription': 'Ajouter une description',

  // messages tab

  'settings.label.inscriptionEmailCode': 'Email inscription code',
  'settings.label.updateEmailCode': 'Email update code',

  'settings.label.newAccountSMSMessage': 'SMS new inscription message',
  'settings.label.newOfferTitle': 'New Offer',
  'settings.label.subscription': 'Subscription',
  'settings.label.SMSMessage': 'SMS message',
  'settings.label.notificationMessage': 'Push notification message',
  'settings.label.newCouponTitle': 'New coupon',
  'settings.label.expiredOfferTitle': 'Expired offer',
  'settings.label.expiredNotificationText': 'Expired offer notification text',
  'settings.label.flight': 'Flight',
  'settings.label.updatePassEmailMessage': 'Pass update email message',
  'settings.label.preview': 'Preview',
  'settings.placeholder.letEmpty': 'Leave empty to apply the default value',
  'settings.label.newPassEmailMessage': 'New pass email message',
  'settings.label.expiredOfferEmailText': 'Expired offer email text',

  //notifications tab

  'settings.label.emailUpdateText': 'Email message',
  'settings.label.updateNotificationText': 'Push notification text',
  'settings.label.activateEmailNotification': 'Email notification settings',
  'settings.label.walletUpdates': 'Wallet updates',
  'settings.label.newOffers': 'New offers',
  'settings.label.newCoupons': 'New coupons',
  'settings.label.activateSMSNotification': 'SMS notification settings',

  //Placeholder
  'settings.placeholder.validationMessage': 'Registration validation message',
  'settings.placeholder.emailMessage': 'Registration email message',
  'settings.placeholder.offerEmailMessage':
    'Offer / communication email message',
  'settings.placeholder.couponEmailMessage': 'Coupon email message',
  'settings.placeholder.subscriptionMessage': 'your subscrition message here',

  'settings.placeholder.userName': 'Username',
  'settings.placeholder.password': 'Password',
  'settings.placeHolder.communicationEmail': 'Communication email',

  //Settings Buttons
  'settings.button.save': 'Save',
  'settings.button.cancel': 'Cancel',
  'settings.button.copyLink': 'Copy Link',
  'settings.button.verifyEmail': 'Verify Email',
  //**************************************ISSUING****************************//
  'issuing.label.title': 'Issuing settings',
  'issuing.checkbox.expirationDate': 'Expiration date',
  'issuing.label.passWillExpire': 'A pass will expire',
  'issuing.button.afterPeriod': 'After a period of',
  'issuing.button.atDateTime': 'At specified date',

  'issuing.select.weeks': 'Weeks',
  'issuing.select.months': 'Months',
  'issuing.select.years': 'Years',

  'issuing.checkbox.updateAll': 'Apply updates to all clients',

  //**************************************WALLET****************************//
  //Labels
  'wallet.label.searchCustomer': 'Search for a customer',
  'wallet.label.filter': 'Filter by',
  'wallet.label.customersList': 'List of customers',
  'wallet.label.sentDemosList': 'Sent demos list',
  'wallet.label.customersEmptyList': 'The list of customers is empty',
  //Select
  'wallet.select.firstName': 'First name',
  'wallet.select.lastName': 'Last name',
  'wallet.select.email': 'Email',
  //Placeholder
  'wallet.placeholder.searchCustomer': 'Search',
  //Buttons
  'wallet.add': 'Add customer',
  'wallet.button.import': 'Import',
  'wallet.button.export': 'Export',
  //**************************************Customers Table****************************//
  //Labels
  'customersTable.Barcode': 'Barcode',
  'customersTable.id': 'ID',
  'customersTable.firstName': 'First name',
  'customersTable.lastName': 'Last name',
  'customersTable.createdAt': 'Created At',
  'customersTable.gains': 'Gains',
  'customersTable.email': 'Email',
  'customersTable.passKind': 'Pass',

  'customersTable.phoneNumber': 'Phone number',
  'customersTable.source': 'Source',
  'customersTable.source.internal': 'Internal',
  'customersTable.source.external': 'External',
  'customersTable.source.crm': 'CRM',

  'customersTable.status': 'Status',
  'customersTable.suprimer': 'Deleted',
  'customersTable.system': 'Device',
  'customerTable.isDeleted': 'Yes',
  'customerTable.isNotDeleted': 'No',
  //Buttons
  'customersTable.updateGain': 'Update gain',
  //Historical
  'customersTable.historical.title': 'History',
  'customersTable.historical.emptyList': 'History list is empty',
  'customersTable.historical.date': 'Date',
  'customersTable.historical.gain': 'Gain',
  'wallet.label.settings': 'Manage',

  'wallet.label.downloadTemplate': 'Download the template CSV',
  'wallet.label.importFile': 'Import a CSV file',
  'wallet.lable.importFileDropZone': 'Drag a CSV file here or click to import',
  'wallet.label.sendNotifications': 'Send the passes to customers',
  'wallet.button.cancel': 'Cancel',
  'wallet.button.rechercher': 'Search',
  'wallet.button.relance': 'Relaunch',

  //**************************************Update Gain****************************//
  //Labels
  'updateGain.label.gain': 'Update gain :',
  //Placeholder
  'updateGain.placehoder.gain': 'Gain',
  //Buttons
  'updateGain.button.confirm': 'Update',
  //Error
  'updateGain.error.gainNotNumber': 'The gain must be a number',
  'gainUnit.dialog.text.change':
    'Are you sure you want to change the gains? This resets the gains of all customers to 0',
  //**************************************Add Customer****************************//
  //Labels
  'addCustomer.label.firstName': 'First name',
  'addCustomer.label.lastName': 'Last name',
  'addCustomer.label.email': 'Email',
  'addCustomer.label.barcode': 'Barcode',
  'addCustomer.label.qrcode': 'QR code',

  'addCustomer.label.phoneNumber': 'Phone number',
  //Placeholder
  'addCustomer.placeholder.firstName': 'First name',
  'addCustomer.placeholder.lastName': 'Last name',
  'addCustomer.placeholder.email': 'Email',
  'addCustomer.placeholder.barcode': 'Barcode',
  'addCustomer.placeholder.phoneNumber': '+33757130710',
  //Buttons
  'addCustomer.button.add': 'Add',
  //Error
  'addCustomer.error.firstNameRequired': 'First name is required',
  'addCustomer.error.lastNameRequired': 'Last name is required',
  'addCustomer.error.emailRequired': 'Email is required',
  'addCustomer.error.phoneRequired': 'Phone number is required',
  'addCustomer.error.emailInvalid': 'Invalid Email',
  'addCustomer.error.emailExist': 'Email or barcode already exists',
  'addCustomer.error.phoneNumberInvalid': 'Invalid phone number',
  'addCustomer.error.barcodeInvalid':
    'the bar code must be at least 8 characters',

  'addCustomer.error.firstNameLettersOnly':
    'The first name can only contain letters',
  'addCustomer.error.lastNameLettersOnly':
    'The last name can only contain letters',
  'addCustomer.error.firstNameMaxLength':
    'The length of the first name must be at least 3 characters',
  'addCustomer.error.lastNameMaxLength':
    'The length of the name must be at least 3 characters',

  //**************************************Offers List****************************//
  //Labels
  'offers.label.title': 'List of offers',
  'offers.label.emptyList': 'The list of offers is empty',
  'offers.label.filter': 'Filter by',
  'offers.label.startDate': 'Start date',
  'offers.label.endDate': 'End date',
  'offers.label.allTime': 'All time',
  //Select
  'offers.select.all': 'ALL',
  'offers.select.opened': 'OPENED',
  'offers.select.actif': 'ACTIF',
  'offers.select.inactif': 'INACTIF',
  'offers.select.expired': 'EXPIRED',
  'offers.select.deleted': 'DELETED',

  //Dialog
  'offers.dialog.title': 'Information',
  'offers.dialog.text.inactif':
    'Are you sure you want to desactivate this offer ?',
  'offers.dialog.text.deleted': 'Are you sure you want to delete this offer ?',
  //Buttons
  'offers.button.search': 'Search',
  'offers.button.create': 'New Offer',
  //**************************************Offer Card****************************//
  //Labels
  'offerCard.label.status': 'Status:',
  'offerCard.label.startDate': 'Start date:',
  'offerCard.label.endDate': 'End date:',
  'offerCard.label.description': 'Description',
  'offerCard.label.unlimited': 'Unlimited',

  //Buttons
  'offerCard.button.desactivate': 'Deactivate',
  'offerCard.button.delete': 'Delete',
  //**************************************Statistics****************************//
  //Labels
  'stats.label.title': 'SMS report',
  'stats.label.newAccount': 'Storecards',
  'stats.label.offers': 'Offers',
  'stats.label.coupons': 'Coupons',
  'stats.label.events': 'Events',
  'stats.label.total': 'Total',
  'stats.label.availableCredit': 'Available credits',
  'stats.label.noAssociatedAccount': 'No account is associated',

  'stats.label.flightsTickets': 'Flight tickets',
  'stats.label.walletUpdate': 'Wallet updates',
  'stats.label.moviesTickets': 'Movie tickets',
  'stats.label.transitTickets': 'Transit tickets',
  'stats.label.reservations': 'Reservations',
  'statsCLients.label.title': 'Clients report',
  'stats.label.internal': 'Internal',
  'stats.label.external': 'External',
  'stats.label.activeClients': 'Active clients',
  'stats.label.inactiveClients': 'Inactive clients',
  'stats.label.deletedClients': 'Deleted clients',
  'stats.label.androidActive': 'Active on android',
  'stats.label.iosActive': 'Active on IOS',
  'stats.label.deletedAndroid': 'Deleted on android',
  'stats.label.deletedIos': 'Deleted on IOS',
  'stats.label.totalClients': 'Total clients',
  'stats.label.emailReport': 'Emails report',

  //**************************************Offer****************************//
  //Labels
  'offer.label.title': 'Create an offer',
  'offer.label.image': 'Image to insert:',
  'offer.label.description': 'Description',
  'offer.label.startDate': 'Start date',
  'offer.label.endDate': 'End date',
  //Placeholder
  'offer.placeholder.description': 'Description ...',
  //Buttons
  'offer.button.add': 'Add',
  //Error
  'offer.error.imageRequired': 'Picture is required',
  'offer.error.startDateRequired': 'Start Date is required',
  'offer.error.startDateInvalid': 'Invalid Start Date',
  'offer.error.endDateRequired': 'End Date is required',
  'offer.error.endDateInvalid': 'Invalid End Date',
  'offer.error.endDateBeforeStartDate':
    'The end date is earlier than the start date',
  'offer.error.descriptionRequired': 'Description is required',
  'offer.error.datesUnavailable': 'Dates are unavailable',
  //**************************************Coupons List****************************//
  //Labels
  'coupons.label.title': 'List of coupons',
  'coupons.label.emptyList': 'The list of coupons is empty',
  //Dialog
  'coupons.dialog.title': 'Information',
  'coupons.dialog.text': 'Do you want to send this coupon to all customers ?',
  //Buttons
  'coupons.button.create': 'New Coupon ',
  //**************************************Coupon Card****************************//
  //Labels
  'couponCard.label.expirationDate': 'Expiration date:',
  'couponCard.label.percent': 'Percent reduction:',
  'couponCard.label.promoCode': 'Promo code:',
  'couponCard.label.description': 'Description',
  'couponCard.label.status': 'Status',
  'couponCard.label.source': 'Source',
  //Buttons
  'couponCard.button.send': 'send',
  'couponCard.switch.activate': 'Activate',
  //**************************************Coupon****************************//
  //Labels
  'coupon.label.name': 'Name',
  'coupon.label.expirationDate': 'Expiration date',
  'coupon.label.textColor': 'Text Color:',
  'coupon.label.backgroundColor': 'Background color:',
  'coupon.label.foregroundColor': 'Foreground color:',
  'coupon.label.image': 'Image to insert:',
  'coupon.label.percent': 'Percentage or Value:',
  'coupon.label.description': 'Description',
  'coupon.label.promoCode': 'Promo Code',
  'coupon.label.All': 'ALL',
  'coupon.label.Internal': 'INTERNAL',
  'coupon.label.External': 'EXTERNAL',
  'coupon.label.crm': 'CRM',
  'coupon.label.value': 'Value',
  'coupon.label.From': 'From',
  'coupon.label.addCoupon': 'Add a coupon',
  'coupon.label.Inactif': 'INACTIVE',
  'coupon.label.deleted': 'DELETED',
  'coupon.label.updateCoupon': 'Update Coupon',
  'coupon.label.source': 'Source',

  'coupon.label.Actif': 'ACTIVE',
  'coupon.label.Expire': 'EXPIRED',
  //Placeholder
  'coupon.placeholder.description': 'Description ...',
  //Buttons
  'coupon.button.add': 'Add',
  //Error
  'coupon.error.expirationDateRequired': 'Expiration date is required',
  'coupon.error.expirationDateInvalid': 'Invalid expiration date',
  'coupon.error.labelColorRequired': 'Text color is required',
  'coupon.error.backgroundColorRequired': 'Background color is required',
  'coupon.error.foregroundColorRequired': 'Foreground color is required',
  'coupon.error.imageRequired': 'Picture is required',
  'coupon.error.percentReductionRequired': 'Percentage reduction is required',
  'coupon.error.descriptionRequired': 'Description is required',
  'coupon.error.promoCodeRequired': 'Promo Code is Required',

  //**************************************Events List****************************//
  //Labels
  'events.label.title': 'Events list',
  'events.button.create': 'new event',
  'event.label.name': 'Name',
  'event.label.create': 'Create',
  'event.label.openingDate': 'Opening date',
  'event.label.startingDate': 'Start date',
  'event.label.closingDate': 'Closing date',
  'event.label.eventLocation': 'Location of the event',
  'event.label.title': 'Create an event',
  'event.label.image': 'Image to insert',
  'event.label.openingHour': 'Start date and time of the event',
  'event.label.closingHour': 'End date and time of the event',
  'event.label.doorOpeningHour': 'Date and time of door opening',
  'event.label.price': 'Event price',
  'event.label.locationName': 'Location name',
  'event.label.address': 'Location address',
  'event.label.contactPhone': 'Contact phone',
  'event.label.email': 'Communication email',
  'event.label.website': 'Website',
  'event.label.terms': 'Terms',
  'event.label.labelColor': 'Text color',
  'EventsCustomersTable.Id': 'ID (Ticket)',
  'EventsCustomersTable.firstName': 'First name',
  'EventsCustomersTable.lastName': 'Last name',
  'EventsCustomersTable.email': 'Email',
  'EventsCustomersTable.enregistre': 'Checked in',
  'EventsCustomersTable.systeme': 'System',
  'EventsCustomersTable.enregistre.true': 'Yes',
  'EventsCustomersTable.enregistre.false': 'No',
  'event.label.section.style': 'Event style',
  'event.label.section.timeLocation': 'Time and place of the event',
  'event.label.selectLocation': 'Select the exact location',
  'event.label.section.contact': 'Contact information',
  'event.label.subMessage': 'Subscription message',
  'event.label.section.emailMessage': 'Email message',
  'event.label.emailMessage': 'Email message',
  'event.label.section.info': 'Event information',
  'email.label.emailMessage': 'Event message',
  'mapComponent.label.search': 'Search map',
  'event.label.update': 'Update',
  'events.label.emptyList': 'Events list is empty',

  'event.label.eventName': 'Event name',
  'event.label.door': 'Door',
  'event.label.row': 'Row',
  'event.label.place': 'Place',
  'event.label.ticketHolder': 'Ticket holder',
  'event.label.doorsOpen': 'Doors open',
  'event.label.startTime': 'Start date',
  'event.label.endDate': 'End date',
  'event.label.ticketNumber': 'Ticket number',

  //Errors
  'event.error.nameRequired': 'Name of the event is required',
  'event.error.priceRequired': 'Event price is required',
  'event.error.locationNameRequired': 'Location name is required',
  'event.error.addressRequired': 'Event address is required',
  'event.error.contactPhoneRequired': 'Contact phone is required',
  'event.error.emailRequired': 'Communication email is required',
  'event.error.websiteError': 'Website is required',
  'event.error.termsRequired': 'Terms are required',
  'event.error.imageRequiredRequired': 'Strip image is required',
  'event.error.subscriptionMessageRequired': 'Subscription message is required',

  //**************************************ships List****************************//
  //Labels
  'ships.label.title': 'Boat tickets list',
  'ships.label.emptyList': 'The list of boat tickets is empty ',
  'ships.label.filter': 'Filter by',
  'ships.label.startDate': 'Start date',
  'ships.label.endDate': 'End date',
  'ships.label.allTime': 'All time',

  //Select
  'ships.select.originName': 'Origin',
  'ships.select.destinationName': 'Destination',
  'ships.select.departureTime': 'Departure Time',
  'ships.select.arrivalTime': 'Arrival time',
  'ships.label.origin': 'Origin',
  'ships.label.destination': 'Destination',
  'ships.label.departureTime': 'Departure time',
  'ships.label.arrivalTime': 'Arrival time',
  //Dialog
  'ships.dialog.title': 'Information',

  //Buttons
  'ships.button.search': 'Search',
  'ships.button.create': 'New ticket',
  'ship.button.add': 'Save',
  //**************************************ship Card****************************//
  //Labels
  'shipCard.label.id': 'ID:',
  'shipCard.label.status': 'Status',
  'shipCard.label.startDate': 'Start date',
  'shipCard.label.endDate': 'End date',
  'shipCard.label.description': 'Description',
  'ferry.label.departureDate': 'Departure date',
  'ferry.label.arrivalDate': 'Arrival date',
  'rail.label.departureDate': 'Departure date',
  'rail.label.arrivalDate': 'Arrival date',
  'bus.label.departureDate': 'Departure date',
  'bus.label.arrivalDate': 'Arrival date',
  'ferry.label.passengerType': 'Passenger type',
  'ferry.label.tripType': 'Trip type',

  //Buttons
  'shipCard.button.delete': 'Delete',

  //Labels
  'ship.label.number': 'Number',
  'ship.label.originStationCode': 'Origin station code',
  'ship.label.originName': 'Origin name',
  'ship.label.destinationStationCode': 'Destination code',
  'ship.label.destinationName': 'Destination name',
  'ship.label.departureTime': 'Departure time',
  'ship.label.arrivalTime': 'Arrival time',
  'ship.label.tarif': 'Tarif',
  'ship.label.details': 'Details',
  'ship.label.terms': 'Termes',
  'ship.label.logo': 'Logo*',
  'ship.label.customerServiceNumber': 'Customer service number',

  'ship.label.addShip': 'Add a trip',
  'ship.label.departureDate': 'Departure Date',
  'ship.label.arrivalDate': 'Arrival Date',
  'ship.label.passenger': 'Passenger',
  'ship.label.ticketNumber': 'Ticket number',

  //Error
  'ship.error.arrivalTimeRequired': "L'heure est obligatoire",
  'ship.error.departureTimeRequired': "L'heure est obligatoire",
  'ship.error.originNameRequired': 'Station Départ est obligatoire',
  'ship.error.destinationNameRequired': 'Station Arrivé est obligatoire',
  'ship.error.originStationCodeRequired': 'Code gare est obligatoire',
  'ship.error.destinationStationCodeRequired': 'Code gare est obligatoire',
  'ship.error.numberRequired': 'Numéro est obligatoire',
  'ship.error.tarifRequired': 'Tarif est obligatoire',
  'ship.error.detailsRequired': 'Details est obligatoire',
  'ship.error.termsRequired': 'Termes est obligatoire',
  'ship.error.logoRequired': 'Logo  est obligatoire',
  'ship.error.customerServiceNumberRequired':
    'Numéro de service client est obligatoire',
  'ship.error.imageRequired': 'image  est obligatoire',
  'ship.error.stripRequired': 'Logo est obligatoire',
  'ferry.error.createdAtRequired': 'Date est obligatoire',
  'ferry.error.createdAtInvalid': 'Date est invalid',
  'ferry.error.passengerTypeRequired': 'Type est obligatoire',
  'ferry.error.tripTypeRequired': 'Type est obligatoire',
  'ferry.error.phoneNumberRequired': 'Numéro est obligatoire',
  // Buses
  'buses.label.title': 'Bus trips',
  'Buses.label.emptyList': 'Boats list is empty',
  'transit.button.create': 'New Ticket',
  // Trams
  'trams.label.title': 'Metro trips',
  'trams.label.emptyList': 'Trams list is empty',

  // Rails
  'rails.label.title': 'Rail trips',
  'rails.label.emptyList': 'Rails list is empty',

  // Hotels

  //**************************************transit Card****************************//
  //Labels
  'transitCard.label.id': 'ID:',

  'transitCard.label.description': 'Description',
  'transitCard.label.departureDate': 'Departure date',
  'transitCard.label.arrivalDate': 'Arrival date',
  'transitCard.label.departureTime': 'Departure time',
  'transitCard.label.arrivalTime': 'Arrival time',
  'transitCard.label.originName': 'Origin name',
  'transitCard.label.destinationName': 'Destination name',
  'transitCard.label.destination': 'Destination',

  'transit.label.sentTransits': 'List of sent tickets',

  //Labels
  'transitCard.label.status': 'Status',
  'transitCard.label.startDate': 'Start date',
  'transitCard.label.endDate': 'End date',
  'transit.label.passengerType': 'Passenger type',
  'transit.label.tripType': 'Trip type',

  //Labels
  'transit.label.number': 'Number',
  'transit.label.originStationCode': 'Origin station code',
  'transit.label.originName': 'Origin name',
  'transit.label.destinationStationCode': 'Destination code',
  'transit.label.destinationName': 'Destination name',
  'transit.label.departureTime': 'Departure time',
  'transit.label.arrivalTime': 'Arrival time',
  'transit.label.tarif': 'Tarif',
  'transit.label.details': 'Details',
  'transit.label.terms': 'Termes',
  'transit.label.logo': 'Logo*',
  'transit.label.customerServiceNumber': 'Customer service number',

  'transit.label.addTrip': 'Add a trip',
  'transit.label.departureDate': 'Departure Date',
  'transit.label.arrivalDate': 'Arrival Date',
  'transit.label.passenger': 'Passenger',
  'transit.label.ticketNumber': 'Ticket number',

  //**************************************Cinema Card****************************//
  //Labels

  'cinemaCard.label.description': 'Description',
  'cinemaCard.label.departureDate': 'Departure date',
  'cinemaCard.label.arrivalDate': 'Arrival date',
  'cinemaCard.label.departureTime': 'Departure time',
  'cinemaCard.label.arrivalTime': 'Arrival time',
  'cinemaCard.label.originName': 'Origin name',
  'cinemaCard.label.destinationName': 'Destination name',
  'cinemaTickets.label.title': 'List of movies',
  'cinema.label.sentTransits': 'List of sent tickets',
  'cinemaTickets.label.emptyList': 'Movies list is empty',

  'cinemaTicket.label.id': 'ID:',
  'cinemaTicket.label.movieNamec': 'Name',

  'cinemaTicket.label.name': 'Name',
  'cinemaTicket.label.screen': 'Screen',
  'cinemaTicket.label.price': 'Price',
  'cinemaTicket.label.date': 'Date',
  'cinemaTicket.label.time': 'Time',
  'cinemaTicket.label.title': 'Add a ticket',
  'cinemaTicket.label.movieName': 'Movie name',
  'cinemaTicket.label.classification': 'Classification',
  'cinemaTicket.label.rating': 'Rating',

  //buttons
  'cinemaTickets.button.create': 'Add a movie',
  'cinemaTicket.label.create': 'Create ticket',

  //**************************************Flight Card****************************//
  //Labels

  //buttons
  'flights.button.create': 'Add a flight',
  'flights.button.search': 'Search',
  'flights.label.emptyList': 'Flights list is empty',
  'settings.label.letEmpty': 'Leave empty to apply the default value',
  'flights.label.title': 'List of flights',
  'flight.label.number': 'Number',
  'flight.label.carrierIataCode': 'Carrier Iata code',
  'flight.label.airportName': 'Airport name',
  'flight.label.airportIataCode': 'Airport Iata code',
  'flight.label.terminal': 'Terminal',
  'flight.label.gate': 'Gate',
  'flight.label.confirmationCode': 'Confirmation code',
  'flight.label.estimatedDepartureTime': 'Estimated departure time',
  'flight.label.arrivalGate': 'Arrival gate',
  'flight.label.destinationTerminale': 'Destination terminal',
  'flight.label.addLayover': 'Add a layover',
  'flight.label.layover': 'Layover',
  'flight.label.zone': 'Zone / Region',
  'flight.label.class': 'Class',
  'flight.label.terminalAndGate': 'Terminal / Gate',
  'flight.label.addFlight': 'Add flight',
  'flight.label.departureDateTime': 'Departure date and time',

  'flights.select.originName': 'Origin name',
  'flights.select.destinationName': 'Destination name',
  'flights.select.departureTime': 'Departure Time',
  'flights.select.arrivalTime': 'Arrival Time',
  //**************************************Hotels List****************************//
  //Labels
  'hotels.label.title': 'Rooms list',
  'hotels.label.emptyList': 'Rooms list is empty',
  'hotels.label.HotelName': 'Hotel name',
  'hotels.label.RoomType': 'Room type',
  'hotels.label.RoomNumber': 'Room number',
  'hotel.label.addHotel': 'Add a room',
  //Dialog
  'hotels.dialog.title': 'Information',

  //Buttons
  'hotels.button.create': 'New room',

  //////******Hotel ****//////
  ////buttons
  'hotel.button.add': 'Save',
  ////labels
  'hotel.label.name': 'Hotel name:',
  'hotel.label.type': 'Room type:',
  'hotel.label.number': 'Room number:',
  'hotel.label.address': 'Hotel address:',
  'hotel.label.email': 'Email address:',
  'hotel.label.website': 'Hotel website:',
  'hotel.label.contactPhone': 'Contact number:',
  'hotel.label.terms': 'Terms',
  'hotel.label.logo': 'Logo*',
  //////******Hotels ****//////
  //Labels
  'hotels.label.filter': 'Filter by',
  'hotels.label.startDate': 'Start date',
  'hotels.label.endDate': 'End date',
  'hotels.label.allTime': 'All time',
  'hotel.label.createdAt': 'Creation Date',
  'hotel.label.checkin': 'Check in',
  'hotel.label.checkout': 'Check out',
  //Select
  'hotels.select.name': 'Hotel name',
  'hotels.select.type': 'Room type',
  'hotels.select.departureTime': 'Departure Time',
  'hotels.select.arrivalTime': 'Arrival Time',

  'hotel.select.single': 'Single ',
  'hotel.select.double': 'Double',
  'hotel.select.triple': 'Tripe',
  'hotel.select.Quad': 'Quad',
  'hotel.select.Queen': 'Queen',
  'hotel.select.King': 'King',
  'hotel.select.Twin': 'Twin',
  'hotel.select.DoubleDouble': 'Double double',
  'hotel.select.Studio': 'Studio',
  'hotel.select.Suite': 'Suite',
  'hotel.select.JuniorSuite': 'Junior suite',
  'hotel.select.PresidentSuite': 'President suite',
  'hotel.select.apartment': 'Apartment',
  'hotel.select.ConnectingRoom': 'Connecting Room',
  'hotel.select.MurphyRoom': 'Murphy room',
  'hotel.select.AccessibleRoom': 'Accessible room',
  'hotel.select.AdjoiningRoom': 'Adjoining room',
  'hotel.select.AdjacentRoom': 'Adjacent room',
  'hotel.select.villa': 'Villa',

  //error hotel customer

  'hotel.error.createdAtRequired': 'La date de creation est obligatoire',
  'hotel.error.createdAtInvalid': 'Date de creation non valide',

  'hotel.error.checkinRequired': "La date d'arriver est obligatoire",
  'hotel.error.checkinInvalid': "Date d'arriver non valide",

  'hotel.error.checkoutRequired': 'La date de quitter est obligatoire',
  'hotel.error.checkoutInvalid': 'Date de quitter non valide',

  //**************************************Profile****************************//
  //Labels
  'profile.label.email': 'Email address',
  'profile.label.password': 'Password',
  'profile.label.emailSettings': 'Emailing parameters :',
  'profile.label.managerEmail': 'Main Email :',
  'profile.label.communicationEmail': 'Communication Email  :',
  'profile.label.communicationEmailHost': 'Host Server :',
  'profile.label.communicationEmailPort': 'Port Number:',
  'profile.label.communicationEmailUser': 'Username :',
  'profile.label.communicationEmailPass': 'Password :',
  'profile.label.emailSettingsConfirmation': 'Enter your password to confirm:',

  'profile.label.updatePasswordTitle': 'Change password :',
  'profile.label.oldPassword': 'Old password',
  'profile.label.newPassword': 'New password',
  'profile.label.confirmPassword': 'Confirm password',
  //Placeholder
  'profile.placeholder.managerEmail': 'Your Email ...',
  'profile.placeholder.communicationEmailHost': 'xx.xxx.xx ',
  'profile.placeholder.communicationEmailPort': '0000',
  'profile.placeholder.communicationEmailUser': 'username ....',
  'profile.placeholder.communicationEmailPass': 'password ...',
  'profile.placeholder.oldPassword': 'Old password ...',
  'profile.placeholder.newPassword': 'New Password ...',
  'profile.placeholder.confirmPassword': 'Confirm password ...',
  //Buttons
  'profile.button.confirmPassword': 'Confirm Password',
  'profile.button.updateEmailSettings': 'Update Email Settings',
  'profile.button.updatePassword': 'Update Password',
  //Success
  'profile.success.successMessage': 'Updated with success',
  //Error
  'profile.error.oldPasswordRequired': 'Old password is required. ',
  'profile.error.oldPasswordIncorrect': 'Your current password is wrong.',
  'profile.error.newPasswordRequired': 'New password is required.',
  'profile.error.newPasswordRequiredLength':
    'The password must contain at least 4 characters.',
  'profile.error.confirmPasswordRequired': 'Confirm password is required.',
  'profile.error.confirmPasswordNotMatch':
    'The confirmation does not match the new password.',
  'profile.error.emailSettingsError':
    'Configuration error, please check the parameters entered.',

  'profile.error.emailCommunication': 'invalid communication email parameters',
  'profile.error.userPasswordError': 'Incorrect password',
  'profile.error.managerEmail': 'Principal Email is required',
  'profile.error.userPrincipalEamilExistsError':
    'Already existing email address, try another',
  //**************************************Forgot Password****************************//
  //Labels
  'forgotPassword.label.email': 'Enter your user name.',
  //Placeholder
  'forgotPassword.placeholder.email': 'User name',
  //Buttons
  'forgotPassword.button.confirm': 'Confirm',
  //Error
  'forgotPassword.error.emailRequired': 'User name is required',
  'forgotPassword.error.invalidEmail': 'Invalid user name',
  //**************************************Reset Password****************************//
  //Labels
  'resetPassword.label.password': 'New Password:',
  'resetPassword.label.passwordConfirm': 'Confirm the password:',
  //Placeholder
  'resetPassword.placeholder.password': 'Enter your new password',
  'resetPassword.placeholder.passwordConfirm': 'Confirm your password',
  //Buttons
  'resetPassword.button.confirm': 'Confirm',
  //Error
  'resetPassword.error.passwordRequired': 'New password is required.',
  'resetPassword.error.passwordRequiredLength':
    'The password must contain at least 4 characters.',
  'resetPassword.error.passwordConfirmRequired':
    'Confirm password is required.',
  'resetPassword.error.passwordConfirmNotMatch':
    'The confirmation does not match the new password.',
  'resetPassword.error.tokenExpired':
    "Sorry, your token expired! You'll need to resend your email.",

  //messages
  'user.success.added': 'User added successfully',
  'event.success.added': 'Event addedd successfully',
  'wallet.error.occured': 'Error occured',
  'emailsms.error.failedSent': 'Email and SMS failed',
  'sms.error.failedSent': 'SMS sending failed',
  'email.error.failedSent': 'Email sending failed',
  'sms.error.insuffisantCredit': 'Insuffisant credits',
  'phone.error.alreadyExists': 'Phone number already exists',
  'email.error.alreadyExists': 'Email address already exists',
  'sms.error.failedCredits': 'Retrieving the credits failed',

  'transit.success.update': 'Transit updated successfully',
  'transit.success.delete': 'Transit deleted successfully',
  'cinema.success.added': 'Cinema ticket added successfully',
  'cinema.success.deleted': 'Cinema ticket deleted successfully',
  'coupon.success.added': 'Coupon added successfully',
  'coupon.success.updated': 'Coupon updated successfully',

  'coupon.error.duplicatedPromo': 'Duplicated promoCode',
  'coupon.error.updated': 'Coupon updated successfully',
  'coupon.info.couponActive': 'There is already an active coupon',
  'coupon.success.deleted': 'Coupon deleted successfully',
  'coupon.success.sent': 'Coupons sent successfully',
  'coupon.info.noActiveClients': 'There is no active clients on this Wallet!',

  'event.success.deleted': 'Event deleted successfully',
  'event.success.update': 'Event updated successfully',
  'flight.success.added': 'Flight added successfully',
  'flight.success.updated': 'Flight updated successfully',
  'flight.success.deleted': 'Flight deleted successfully',
  'room.success.added': 'Room added successfully',
  'room.success.updated': 'Hotel updated successfully',
  'room.success.deleted': 'Hotel deleted successfully',
  'offer.success.updated': 'Offer updated successfully',
  'offer.success.added': 'Offer added successfully',
  'wallet.success.settingsUpdated': 'Settings updated successfully',
  'transit.success.added': 'Transit added successfully',
  'transit.success.updated': 'Transit updated successfully',
  'transit.success.deleted': 'Transit deleted successfully',
  'customer.success.deleted': 'User deleted successfully',
  'customer.info.emptyList': 'Customers list is empty',
  'customer.success.exported': 'Customers list exported successfully',

  //**************************************communication settings****************************//
  //Labels
  'notificationTable.label.communicationSettings': 'Communication settings',
  'notificationTable.label.reminder': 'Reminder',

  'notificationTable.label.pushNotification': 'Push notifications',
  'notificationTable.label.storecard': 'Storecard',
  'notificationTable.label.event': 'Event',
  'notificationTable.label.coupon': 'Coupon',
  'notificationTable.label.offer': 'Offer',
  'notificationTable.label.new': 'New',
  'notificationTable.label.update': 'Update',
  'notificationTable.label.expired': 'Expired',
  'notificationTable.label.welcomeInternal': 'Welcome for internal clients',
  'notificationTable.label.welcomeExternal': 'Welcome for external clients',
  'notificationTable.label.welcomeCRM': 'Welcome for CRM clients',
  'Copied.to.clipboard': 'Copied to clipboard',
  //**************************************Inactive clients****************************//

  'inactives.message.sendToInactiveClients':
    'Do you want to send reminders to your selected clients ?',
  'inactives.message.sendToAllInactiveClients':
    'Voulez-vous envoyer des rappels à tous les clients inactifs ?',
  'inactives.message.resendAll': 'Resend to All',
  'inactives.message.resend': 'Resend',
  'inactives.message.selected': 'Selected',
  'inactives.label.title': 'Inactive clients list',
  'inactives.label.creationDate': 'Creation date',
  'inactives.label.noRowsSelected': 'No customer is selected',

  //**************************************Common****************************//

  'common.label.maxLengthReached': 'Max Length has been reached',
  'common.label.invalidNumber': 'Invalid number',
  'barcode.label.select': 'Barcode',
  'barcode.checkbox.addBarcode': 'Add barcode',
};
