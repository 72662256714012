import React from 'react';
import { useIntl } from 'react-intl';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';
import SideBar from '../../components/Drawer';

import Offers from '../../modules/Offers';
import { useStyles } from './offersRouter.styles';
import NotFound from '../../modules/NotFound';
//Modules

const OffersRouter: React.FC<RouteComponentProps> = (props) => {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <div className={classes.rootAppRouter}>
      <SideBar title={intl.formatMessage({ id: 'drawer.menuList.offers' })}>
        <Switch>
          <Route path='/offers' component={Offers} />
          <Route path='/offers/*' component={NotFound} />
        </Switch>
      </SideBar>
    </div>
  );
};

export default OffersRouter;
