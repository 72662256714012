import axios from 'axios';
import { URL } from './config';

export const getFlight = (walletId: string, flightId: string) => {
  const requestURL = `${URL}/wallets/${walletId}/flights/${flightId}`;

  return axios
    .get(requestURL)
    .then(({ data }: { data: any }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const createFlight = (walledId: string | null, flight: any) => {
  // flight.locations = [
  //   {
  //     longitude: 0,
  //     latitude: 0,
  //   },
  // ];
  // flight.type = 'flight';
  const requestURL = `${URL}/wallets/${walledId}/flights`;
  return axios
    .post(requestURL, flight)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export const updateFlight = (
  walledId: string | null,
  flightId: string | null,
  flight: any
): Promise<any> => {
  const requestURL = `${URL}/wallets/${walledId}/flights/${flightId}`;
  return axios
    .put(requestURL, flight)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export const removeFlight = (flightId: string, walletId: string) => {
  const requestURL = `${URL}/wallets/${walletId}/flights/${flightId}`;

  return axios
    .delete(requestURL)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export const editCustomerFlight = (walletId: string | null, flight: any) => {
  const requestURL = `${URL}/wallets/${walletId}/flights/${flight.flightId}/customer`;
  return axios
    .put(requestURL, flight)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};
