import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='28'
      height='28'
      fill='none'
      viewBox='0 0 28 28'
    >
      <path
        fill='#C7C7C7'
        fillRule='evenodd'
        d='M23.802 15.843c.417.222.74.572.966.922.441.723.405 1.61-.024 2.392l-.835 1.4a2.485 2.485 0 01-2.11 1.213 2.49 2.49 0 01-1.264-.35c-.31-.198-.668-.268-1.049-.268-1.18 0-2.17.968-2.206 2.123 0 1.342-1.097 2.392-2.468 2.392h-1.621c-1.383 0-2.48-1.05-2.48-2.392-.024-1.155-1.014-2.123-2.194-2.123-.393 0-.751.07-1.05.268-.38.233-.858.35-1.263.35a2.49 2.49 0 01-2.122-1.213l-.823-1.4c-.441-.759-.465-1.669-.024-2.392.191-.35.549-.7.954-.922.334-.163.548-.431.751-.746.596-1.004.239-2.322-.775-2.917a2.385 2.385 0 01-.882-3.302l.799-1.376a2.479 2.479 0 013.362-.887c1.037.56 2.385.187 2.993-.805.19-.327.298-.677.274-1.027a2.082 2.082 0 01.334-1.236 2.56 2.56 0 012.11-1.214h1.681c.882 0 1.681.49 2.122 1.214.215.35.358.781.322 1.236-.024.35.084.7.274 1.027.609.992 1.956 1.365 3.005.805a2.464 2.464 0 013.35.887l.8 1.376a2.365 2.365 0 01-.883 3.302c-1.014.595-1.371 1.913-.763 2.917.19.315.405.583.74.746zm-13.175-1.831c0 1.831 1.515 3.29 3.386 3.29 1.872 0 3.35-1.459 3.35-3.29 0-1.832-1.478-3.302-3.35-3.302-1.871 0-3.386 1.47-3.386 3.302z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}

export default Icon;
