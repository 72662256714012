import React from 'react';
import { useIntl } from 'react-intl';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import ComButton from '../../../commons/Button';
import Typography from '@material-ui/core/Typography';
//Styles
import { useStyles } from './couponCard.styles';
import logo from '../../../assets/images/logo-BestWallet.png';
import { Divider } from '@material-ui/core';
import { CouponApiResponse } from '../../../apis/ApiTypes';
import { URL_IMAGE } from '../../../apis/config';
import moment from 'moment';
import Text from '../../../commons/Text';
import Switch from '@material-ui/core/Switch';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import ConfirmationModal from '../../Modal';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';

type CouponCardProps = {
  coupon: CouponApiResponse;
  onClickSend: (value: string) => void;
  onClickRedirect: () => void;
  onClickActivate: (id: string, status: string) => void;
  status: boolean;
  disabled: boolean;
  loading: boolean;
  onClickDelete: (offerId: string) => void;
  couponId: string;
  onClickEdit: (id: string) => void;
};

const CouponCard: React.FC<CouponCardProps> = (props) => {
  const intl = useIntl();
  const classes = useStyles();
  const {
    coupon,
    onClickSend,
    onClickActivate,
    status,
    disabled,
    loading,
    couponId,
    onClickDelete,
    onClickEdit,
    onClickRedirect,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [is_open, setIsOpen] = React.useState<any>(null);

  const [is_loading, setIsLoading] = React.useState<any>(null);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <Card className={classes.root}>
      <div className={classes.cardMenu}>
        <IconButton
          aria-label='more'
          aria-controls='long-menu'
          aria-haspopup='true'
          className={classes.iconButton}
          onClick={handleClick}
          style={{
            background: 'rgba(0, 0, 0, 0.349)',
            padding: '2px',
          }}
        >
          <MoreHorizIcon fontSize='small' className={classes.menuIcon} />
        </IconButton>
        <Menu
          id='simple-menu'
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: -50 }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          keepMounted
          open={Boolean(anchorEl)}
          onClick={handleClose}
        >
          {' '}
          <MenuItem
            onClick={() => onClickEdit(coupon._id)}
            onMouseEnter={(e) =>
              ((e.target as HTMLInputElement).style.color = '#f742ee')
            }
            onMouseLeave={(e) =>
              ((e.target as HTMLInputElement).style.color = '#000000')
            }
          >
            {intl.formatMessage({ id: 'event.label.update' })}
          </MenuItem>
          <MenuItem
            onClick={() => {
              setIsOpen(true);
            }}
            onMouseEnter={(e) =>
              ((e.target as HTMLInputElement).style.color = '#f742ee')
            }
            onMouseLeave={(e) =>
              ((e.target as HTMLInputElement).style.color = '#000000')
            }
          >
            {intl.formatMessage({ id: 'settings.button.delete' })}
          </MenuItem>
        </Menu>
        <ConfirmationModal open={is_open}>
          <div className='modal-cntr'>
            <span style={{ marginTop: '15 px' }}>
              Voulez vous Supprimer le coupon ?{' '}
            </span>
            {is_open ? (
              <div
                style={{
                  display: 'flex',
                  marginTop: '10px',
                  justifyContent: 'space-evenly',
                }}
              >
                <ComButton
                  styled='secondary'
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  {intl.formatMessage({ id: 'settings.button.cancel' })}
                </ComButton>

                <ComButton
                  styled='primary'
                  onClick={() => {
                    onClickDelete(coupon._id);
                  }}
                >
                  {is_loading ? (
                    <CircularProgress size={20} color='inherit' />
                  ) : (
                    ''
                  )}
                  {intl.formatMessage({ id: 'resetPassword.button.confirm' })}{' '}
                </ComButton>
              </div>
            ) : (
              ''
            )}
          </div>
        </ConfirmationModal>
      </div>
      <CardMedia
        onClick={() => onClickRedirect()}
        className={classes.media}
        image={`${URL_IMAGE}/${coupon.couponPictureUrl}`}
        title='strip'
      />
      <CardContent
        className={classes.cardContent}
        onClick={() => onClickRedirect()}
      >
        <div className={classes.label}>
          <Text color='#828282' fontSize={14} lineHeight={21} fontWeight={500}>
            ID:
          </Text>
          <Text color='#404040' fontSize={14} lineHeight={21} fontWeight={500}>
            {coupon._id}
          </Text>
        </div>{' '}
        {/* <div className={classes.label}>
          <Text color='#828282' fontSize={14} lineHeight={21} fontWeight={500}>
            {intl.formatMessage({ id: 'couponCard.label.name' })}
          </Text>
          <Text color='#404040' fontSize={14} lineHeight={21} fontWeight={500}>
            {coupon.name}
          </Text>
        </div> */}
        <div className={classes.label}>
          <Text color='#828282' fontSize={14} lineHeight={21} fontWeight={500}>
            {intl.formatMessage({ id: 'couponCard.label.expirationDate' })}
          </Text>
          <Text color='#404040' fontSize={14} lineHeight={21} fontWeight={500}>
            {moment(coupon.expirationDate).format('L')}
          </Text>
        </div>
        <div className={classes.label}>
          <Text color='#828282' fontSize={14} lineHeight={21} fontWeight={500}>
            {intl.formatMessage({ id: 'couponCard.label.percent' })}
          </Text>
          <Text color='#404040' fontSize={14} lineHeight={21} fontWeight={500}>
            {coupon.value}
          </Text>
        </div>
        <div className={classes.label}>
          <Text color='#828282' fontSize={14} lineHeight={21} fontWeight={500}>
            {intl.formatMessage({ id: 'couponCard.label.promoCode' })}
          </Text>
          <Text color='#404040' fontSize={14} lineHeight={21} fontWeight={500}>
            {coupon.promoCode}
          </Text>
        </div>
        <div className={classes.label}>
          <Text color='#828282' fontSize={14} lineHeight={21} fontWeight={500}>
            {intl.formatMessage({ id: 'couponCard.label.source' })}
          </Text>
          <Text color='#404040' fontSize={14} lineHeight={21} fontWeight={500}>
            {coupon.source === 'internal'
              ? intl.formatMessage({ id: 'coupon.label.Internal' })
              : coupon.source === 'external'
              ? intl.formatMessage({ id: 'coupon.label.External' })
              : intl.formatMessage({ id: 'coupon.label.All' })}
          </Text>
        </div>
        <div className={classes.label}>
          <Text color='#828282' fontSize={14} lineHeight={21} fontWeight={500}>
            {intl.formatMessage({ id: 'couponCard.label.status' })}
          </Text>
          <Text
            color={coupon.status === 'EXPIRED' ? '#FF5078' : '#404040'}
            fontSize={14}
            lineHeight={21}
            fontWeight={500}
          >
            {coupon.status === 'EXPIRED'
              ? intl.formatMessage({ id: 'coupon.label.Expire' })
              : coupon.status === 'ACTIF'
              ? intl.formatMessage({ id: 'coupon.label.Actif' })
              : intl.formatMessage({ id: 'coupon.label.Inactif' })}
          </Text>
        </div>
        <Text color='#828282' fontSize={14} lineHeight={21} fontWeight={500}>
          {intl.formatMessage({ id: 'couponCard.label.description' })}
        </Text>
        <div className={classes.cardDescription}>
          <Text color='#404040' fontSize={12} lineHeight={21} className='clamp'>
            {coupon.description}
          </Text>
        </div>
      </CardContent>

      {!coupon.template && (
        <CardActions
          style={{
            padding: '8px 16px 16px 16px',
            justifyContent: 'space-between',
          }}
        >
          <div className={classes.activateCoupon}>
            <Switch
              disabled={disabled}
              checked={status}
              size='medium'
              onChange={() => onClickActivate(coupon._id, coupon.status)}
            />
            <Text
              fontSize={12}
              lineHeight={21}
              color={disabled ? '#767676' : '#000000'}
            >
              {intl.formatMessage({ id: 'couponCard.switch.activate' })}
            </Text>
          </div>

          <Button
            size='small'
            color='primary'
            onClick={() => onClickSend(coupon._id)}
            disabled={coupon.status === 'EXPIRED'}
          >
            {(!loading || couponId !== coupon._id) &&
              intl.formatMessage({ id: 'couponCard.button.send' })}

            {loading && couponId === coupon._id && (
              <CircularProgress size={20} color='inherit' />
            )}
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

export default CouponCard;
