import React from 'react';
import { useIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
//Style
import styled from 'styled-components';
import { resetPasswordStyles, useStyles } from './resetPassword.styles';
//Commomns
import InputLabel from '../../commons/Inputs/InputLabel';
import Button from '../../commons/Button';

//Actions
import {
  resetPasswordPropsChanged,
  resetPasswordverificationToken,
  resetPasswordConfirm,
  resetPasswordIntialState,
} from '../../actions';

import logo from '../../assets/images/logo-BestWallet.png';
import { RootState } from '../../reducers';
import {
  ResetPasswordFormProps,
  ResetPasswordStateError,
} from './resetPassword.types';
import { CircularProgress } from '@material-ui/core';

const ResetPassword: React.FC<ResetPasswordFormProps> = (props) => {
  const classes = useStyles();
  const intl = useIntl();
  const {
    password,
    passwordConfirm,
    passwordError,
    passwordConfirmError,
    errorMessage,
    error,
    loading,
    loadingPage,
  } = props;

  // const token = getDataFromCache('token');

  // if (token) {
  //   return <Redirect to={{ pathname: '/wallets' }} />;
  // }

  React.useEffect(() => {
    const token = props.match.params.token;

    props.resetPasswordverificationToken(token);
    /* eslint-disable-next-line */
  }, []);

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.resetPasswordPropsChanged(event.target.name, event.target.value);
  };

  const handleClickResetPassword = () => {
    const token = props.match.params.token;
    props.resetPasswordConfirm(token, props, () =>
      props.history.push('/login')
    );
  };

  const getFormatedMessage = (prop: ResetPasswordStateError) => {
    return props[prop] !== ''
      ? intl.formatMessage({ id: props[prop] })
      : props[prop];
  };

  if (loadingPage) {
    return (
      <div className={classes.root}>
        <CircularProgress size={30} className={classes.circularProgress} />
      </div>
    );
  } else if (error) {
    return (
      <Wrapper>
        <span className='text-error-validation'>
          {getFormatedMessage('error')}
        </span>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <div>
        <img src={logo} alt='logo' width='250' />

        <div className='div-inputs'>
          <InputLabel
            label={intl.formatMessage({ id: 'resetPassword.label.password' })}
            name='password'
            value={password}
            error={getFormatedMessage('passwordError')}
            placeholder={intl.formatMessage({
              id: 'resetPassword.placeholder.password',
            })}
            onChange={(e) => handleEventChange(e)}
          />

          <InputLabel
            label={intl.formatMessage({
              id: 'resetPassword.label.passwordConfirm',
            })}
            name='passwordConfirm'
            value={passwordConfirm}
            error={getFormatedMessage('passwordConfirmError')}
            placeholder={intl.formatMessage({
              id: 'resetPassword.placeholder.passwordConfirm',
            })}
            onChange={(e) => handleEventChange(e)}
          />
        </div>

        {errorMessage && (
          <span className='text-error'>
            {getFormatedMessage('errorMessage')}
          </span>
        )}

        <Button onClick={handleClickResetPassword} margin='10px'>
          {loading ? (
            <CircularProgress size={20} color='inherit' />
          ) : (
            intl.formatMessage({ id: 'resetPassword.button.confirm' })
          )}
        </Button>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${(props) => resetPasswordStyles(props)}
`;

const mapStateToProps = ({ resetPassword }: RootState) => {
  return {
    password: resetPassword.password,
    passwordConfirm: resetPassword.passwordConfirm,
    passwordError: resetPassword.passwordError,
    passwordConfirmError: resetPassword.passwordConfirmError,
    errorMessage: resetPassword.errorMessage,
    error: resetPassword.error,
    loading: resetPassword.loading,
    loadingPage: resetPassword.loadingPage,
  };
};

export const connector = connect(mapStateToProps, {
  resetPasswordPropsChanged,
  resetPasswordverificationToken,
  resetPasswordConfirm,
  resetPasswordIntialState,
});

export default connector(ResetPassword);
