import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { useLocation, useParams } from 'react-router-dom';
import { osName } from 'react-device-detect';
//Material
import { CircularProgress } from '@material-ui/core';

import { CustomerInscriptionStyles } from './eventInscription.styles';
import styled from 'styled-components';

import applePay from '../../assets/images/apple-pay.png';
import googlePay from '../../assets/images/google-pay.png';

import axios from 'axios';
import { URL, API_KEY, API_KEY_VALUE } from '../../apis/config';
import { SettingsSystemDaydreamTwoTone } from '@material-ui/icons';

const EventInscription: React.FC<any> = (props) => {
  const { token } = useParams<{ token: string }>();
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  React.useEffect(() => {
    const fetchData = async () => {
      let requestUrl = `${URL}/customers/inscriptions`;
      const key: any = API_KEY;
      const value: string | undefined = API_KEY_VALUE;
      try {
        const result = await axios.post(
          requestUrl,
          {
            token: token,
          },
          {
            headers: {
              [key]: value,
            },
          }
        );
        setData(result.data);
        // if (osName !== 'iOS' && osName !== 'Mac OS') {
        //   let devices = (await askForPermissioToReceiveNotifications()) as string;
        //   console.log(devices);
        //   if (devices !== undefined) {
        //     await addCustomerDevice(
        //       result.data.response.data.data.newCustomer.wallet,
        //       devices,
        //       result.data.response.data.data.newCustomer._id
        //     );
        //     setData(result.data);
        //   }
        // } else {
        //   setData(result.data);
        // }
      } catch (error) {
        setIsError(true);
        setErrorMessage(error.response.data.message);
      }
      setIsLoading(false);
    };
    fetchData();
  }, []);

  const handleOnClick = (link: string) => {
    window.location.href = link;
  };

  return (
    <Container>
      {data && data.status === 'success' && (
        <Wrapper>
          <div>
            <h2>
              Bonjour
              {' ' +
                data.response.data.data.newCustomer.firstName +
                ' ' +
                data.response.data.data.newCustomer.lastName}
            </h2>
            <p>{data.response.data.data.subscriptionMessage}</p>
            <div className='applinks-cntr'>
              {osName === 'iOS' || osName === 'Mac OS' ? (
                <div
                  onClick={() =>
                    handleOnClick(data.response.data.data.links.applePayLink)
                  }
                >
                  <img src={applePay} alt='Appel Pay' />
                </div>
              ) : (
                <div
                  onClick={() =>
                    handleOnClick(data.response.data.data.links.googlePayLink)
                  }
                >
                  <img src={googlePay} alt='Google Pay' />
                </div>
              )}
            </div>
          </div>
        </Wrapper>
      )}
      {isLoading && <CircularProgress size={40} color='inherit' />}
      {isError && <h2 className='errorMessage'>{errorMessage}</h2>}
      {/* {!isLoading && !data && (
        <h2 className='errorMessage'>Enable Notification to proceed</h2>
      )} */}
    </Container>
  );
};

const Wrapper = styled('div')`
  ${(props) => CustomerInscriptionStyles(props)}
`;

const Container = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .errorMessage {
    color: red;
  }
`;

export default EventInscription;
