// components/SuccessSnackbar.js or whatever you wanna call it
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import React from 'react';
import { clearSnack } from '../../actions/snackbarAction';
import { useIntl } from 'react-intl';

const SnackBar: React.FC<any> = (props) => {
  const { snackMessage, type } = props;
  const intl = useIntl();
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    props.clearSnack();
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
  }

  return (
    <Snackbar
      open={snackMessage != ''}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert severity={type} onClose={handleClose}>
        {snackMessage && intl.formatMessage({ id: snackMessage })}
      </Alert>
    </Snackbar>
  );
};

const mapStateToProps = ({ snackbar }: RootState) => {
  const { snackMessage, type } = snackbar;

  return {
    snackMessage,
    type,
  };
};

export const connector = connect(mapStateToProps, { clearSnack });

export default connector(SnackBar);
