import moment from 'moment';
import {
  CUSTOMER_TRAM_FORM_PROPS_CHANGED,
  EDIT_TRAM_INITIAL_STATE,
  TRAM_FORM_PROPS,
  TRAM_FORM_PROPS_CHANGED,
  TRAM_GET_TRAM_PROPS,
  TRAM_INITIAL_STATE,
} from '../actions/types';
import { TramActionTypes, TramState } from '../modules/Tram/tram.types';

const INITIAL_STATE: Partial<TramState> = {
  loading: false,
  passengerType: '',
  tripType: '',
  departureDate: moment(),
  arrivalDate: moment(),
  customerId: '',
  tramId: '',
  //errors
  passengerTypeError: '',
  tripTypeError: '',
  departureDateError: '',
  arrivalDateError: '',
};

export default (state = INITIAL_STATE, action: TramActionTypes) => {
  switch (action.type) {
    case CUSTOMER_TRAM_FORM_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };

    default:
      return state;
  }
};
