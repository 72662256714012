import React from 'react';
import { useIntl } from 'react-intl';
import { Route, Switch, RouteComponentProps, Router } from 'react-router-dom';
import SideBar from '../../components/Drawer';

import Rails from '../../modules/Rails';
import { useStyles } from './railsRouter.styles';
import NotFound from '../../modules/NotFound';
import Rail from '../../modules/SentTransits/Rail';
//Modules

const RailsRouter: React.FC<RouteComponentProps> = (props) => {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <div className={classes.rootAppRouter}>
      <SideBar title={intl.formatMessage({ id: 'drawer.menuList.rails' })}>
        <Switch>
          <Route path='/rails/:railID' component={Rail} />
          <Route path='/rails' component={Rails} exact />
          <Route path='/rails/*' component={NotFound} />
        </Switch>
      </SideBar>
    </div>
  );
};

export default RailsRouter;
