import styled from 'styled-components';

export const Wrapper = styled('div')`
  div.MuiButtonBase-root {
    background-color: #fff2f5;
  }
  .MuiPaper-elevation1 {
    box-shadow: none;
  }
  .MuiAccordionSummary-root {
    min-height: 68px;
  }
  .MuiTypography-body1 {
    width: 100%;
  }
  .MuiAccordionDetails-root {
    display: block;
  }
`;
