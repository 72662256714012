import moment, { Moment } from 'moment';
import { getEventsList } from '../apis/eventsApi';
import { removeEvent } from '../apis/eventApi';

import {
  EVENTS_GET_LIST_EVENTS,
  EVENTS_INITIAL_STATE,
  AppThunk,
  EVENTS_PROPS_CHANGED,
  EVENTS_FILTER_EVENTS,
  EVENTS_DELETE_EVENT_LOADING,
  EVENTS_DELETE_EVENT,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
} from './types';
import { EventsApiResponse } from '../apis/ApiTypes';
import {
  EventsChangedAction,
  EventsInitialStateAction,
} from '../modules/Events/events.types';

export const eventsGetAllEvents = (walletId: string): AppThunk => (
  dispatch
) => {
  getEventsList(walletId).then((res: Array<EventsApiResponse>) => {
    dispatch({
      type: EVENTS_GET_LIST_EVENTS,
      payload: res,
    });
  });
};

export const eventsPropsChanged = (
  prop: string,
  value: any
): EventsChangedAction => {
  return { type: EVENTS_PROPS_CHANGED, prop, value };
};

export const eventsFilterEvents = (
  allTime: boolean,
  startDate: Moment | null,
  endDate: Moment | null,
  events: Array<EventsApiResponse>
): AppThunk => (dispatch) => {
  let newEvents: Array<EventsApiResponse> = Object.assign([], events);
  const result: Array<EventsApiResponse> = [];
  let dateStart = `${moment(startDate).format('YYYY-MM-DD')}T00:00:00.000Z`;
  let dateEnd = `${moment(endDate).format('YYYY-MM-DD')}T23:00:00.000Z`;

  if (startDate && endDate && moment(startDate).isAfter(endDate, 'day')) {
    dispatch({
      type: EVENTS_PROPS_CHANGED,
      prop: 'endDateError',
      value: 'offer.error.endDateBeforeStartDate',
    });
  } else {
    dispatch({
      type: EVENTS_PROPS_CHANGED,
      prop: 'endDateError',
      value: '',
    });
    if (allTime) {
      dispatch({
        type: EVENTS_FILTER_EVENTS,
        allTime,
        payload: newEvents,
      });
    } else if (startDate && endDate) {
      newEvents.forEach((event) => {
        if (
          moment(event.date.openingHour).isSameOrAfter(dateStart) &&
          moment(event.date.closingHour).isSameOrBefore(dateEnd)
        ) {
          result.push(event);
        }
      });
      dispatch({
        type: EVENTS_FILTER_EVENTS,
        allTime,
        payload: result,
      });
    } else {
      dispatch({
        type: EVENTS_FILTER_EVENTS,
        allTime,
        payload: newEvents,
      });
    }
  }
};

export const deleteEvent = (eventId: string, walletId: string): AppThunk => (
  dispatch
) => {
  dispatch({
    type: EVENTS_DELETE_EVENT_LOADING,
    payload: true,
  });
  removeEvent(eventId, walletId).then((res) => {
    if (res.data.status === 'success') {
      dispatch({
        type: EVENTS_DELETE_EVENT,
        payload: eventId,
      });
      dispatch({
        type: SNACKBAR_SUCCESS,
        value: 'event.success.deleted',
      });
    } else
      dispatch({
        type: SNACKBAR_ERROR,
        value: 'wallet.error.occured',
      });
  });
};

export const eventsInitialState = (): EventsInitialStateAction => {
  return { type: EVENTS_INITIAL_STATE };
};
