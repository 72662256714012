import axios from 'axios';
import { ResetPasswordState } from '../modules/ResetPassword/resetPassword.types';
import { URL } from './config';

export const resetPasswordVerification = (token: string | null) => {
  const requestURL = `${URL}/users/verification_token/${token}`;

  return axios
    .post(requestURL)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export const resetPassword = (
  token: string | null,
  props: ResetPasswordState
) => {
  const requestURL = `${URL}/users/reset_password/${token}`;
  return axios
    .patch(requestURL, {
      password: props.password,
      passwordConfirm: props.passwordConfirm,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};
