import React from 'react';
//material-ui
import Modal from '@material-ui/core/Modal';
//styles
import { useStyles } from './modal.styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

type ModalProps = {
  open: boolean;
  onClose?: () => void;
  onClick?: () => void;
  children?: React.ReactNode;
  minWidth?: string | number;
  width?: string | number;
  left?: string | number;
  height?: string | number;
  top?: string | number;
};

const WalletModal: React.FC<ModalProps> = ({
  open,
  onClose,
  onClick,
  children,
  minWidth,
  width,
  left,
  height,
  top,
}) => {
  const classes = useStyles();

  const handleClickAway = () => {
    onClose && onClose();
  };

  return (
    <Modal
      className={classes.modal}
      aria-labelledby='modal-information'
      aria-describedby='modal-information'
      open={open}
      onClose={onClose}
      onClick={onClick}
    >
      <div className={classes.mainContainer}>{children}</div>
    </Modal>
  );
};

export default WalletModal;
