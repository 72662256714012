import styled from 'styled-components';

export const Wrapper = styled('div')<{}>`
  display: flex;
  flex-direction: column;
  background-color: #f8f6fe;
  border-radius: 10px;
  padding: 20px;
  width: 424px;
  margin-top: 1em;
  .MuiFormGroup-root {
    display: grid;
    grid-template-columns: 130px 130px;
    grid-template-rows: 101px 111px;
    gap: 9px 56px;
    justify-content: center;
  }

  .imgSquare {
    width: 77px;
    height: 77px;
    margin: auto;
  }

  .imgRectangular {
    width: 100%;
    height: 35px;
  }

  .MuiFormControlLabel-root {
    margin-left: -11px !important;
  }

  label.MuiFormControlLabel-root {
    overflow-wrap: break-word;
    width: 424px;
  }
`;

export const Header = styled('div')`
   {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const Body = styled('div')`
   {
    margin-top: 17px;
    display: grid;
    grid-gap: 8px;
  }
`;

export const Title = styled('div')`
   {
    color: #ff5078;
    font-size: 16px;
    font-weight: 401;
    margin-bottom: 7px;
  }
`;

export const Icon = styled('div')`
   {
  }
`;

export const Devices = styled('div')<{ layout: any }>`
   {
    gap: 1em;
    grid-auto-flow: ${(props) => props.layout || 'row'};
    display: grid;
    margin-bottom: 1em;
    width: fit-content !important;
  }
`;
export const SectionTitle = styled('div')<{}>`
   {
    font-weight: 500;
    font-size: 18px;
    font-family: Poppins;
    margin-top: 1em;
  }
`;
