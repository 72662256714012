import {
    RAILS_GET_LIST_RAILS,
    RAILS_INITIAL_STATE,
    RAILS_ADD_RAIL_TO_LIST,
    RAILS_PROPS_CHANGED,
    RAILS_FILTER_RAILS,
    RAILS_DELETE_RAIL,
    RAILS_DELETE_RAIL_LOADING,
    RAILS_GET_SENT_RAILS,
  } from '../actions/types';
  import { RailsActionTypes, RailsState } from '../modules/Rails/rails.types';
  import { TransitsApiResponse } from '../apis/ApiTypes';
  
  const INITIAL_STATE: any = {
    railsList: [],
    loading: true,
    railsFilterList: [],
    startDate: null,
    endDate: null,
    allTime: false,
    //error
    startDateError: '',
    endDateError: '',
    search: '',
    select: '',
  };
  
  export default (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
      case RAILS_PROPS_CHANGED:
        return { ...state, [action.prop]: action.value };
      case RAILS_FILTER_RAILS:
        if (action.allTime) {
          return {
            ...state,
            startDate: null,
            endDate: null,
            railsList: action.payload,
          };
        }
        return {
          ...state,
          railsList: action.payload,
        };
      case RAILS_GET_LIST_RAILS:
        return {
          ...state,
          railsList: action.payload,
          railsFilterList: action.payload,
          loading: false,
        };
      case RAILS_INITIAL_STATE:
        return { ...INITIAL_STATE };
      case RAILS_ADD_RAIL_TO_LIST:
        let railsSortedByStartDate: Array<TransitsApiResponse> = Object.assign(
          [],
          state.railsList
        );
  
        railsSortedByStartDate = [...state.railsList, ...[action.payload]];
  
        return {
          ...state,
          railsList: [
            ...railsSortedByStartDate.sort(
              (a, b) => <any>new Date(a.createdAt) - <any>new Date(b.createdAt)
            ),
          ],
        };
      case RAILS_DELETE_RAIL_LOADING: {
        return { ...state, loading: true };
      }
      case RAILS_DELETE_RAIL:
        var newRailsList = state.railsList.filter(function (item: any) {
          return item._id !== action.payload;
        });
        return { ...state, loading: false, railsList: newRailsList };
        case RAILS_GET_SENT_RAILS : { 
          return {
            ...state,
            railsList: action.payload,
            total: action.total,
            loading: false,
          };
        }
      default:
        return state;
    }
  };
  