import React from 'react';

function Icon({
  fill = '#C7C7C7',
  style,
  className,
  width = '28',
  height = '28',
}: {
  fill?: string;
  style?: any;
  className?: any;
  width?: string;
  height?: string;
}) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      fill='none'
      viewBox='0 0 28 28'
      className='icon'
      id='ship'
    >
      <path
        fill={fill}
        fillRule='evenodd'
        d='M10.234 2.23c-.265.15-.387.482-.387 1.059 0 .82-.02.84-.775.84-.739 0-.776.036-.776.746 0 .675-.058.745-.623.745H7.05l-.707 4.418c-.388 2.43-.706 4.53-.705 4.667 0 .137 1.864-.845 4.142-2.182 2.278-1.338 4.202-2.431 4.275-2.431.074 0 2.013 1.103 4.31 2.45 2.296 1.349 4.207 2.421 4.247 2.383.04-.038-.174-2.075-.474-4.527s-.548-4.53-.55-4.618c0-.088-.251-.16-.556-.16-.485 0-.554-.092-.554-.745 0-.71-.037-.745-.775-.745-.7 0-.775-.058-.775-.591a2.12 2.12 0 00-.345-1.065c-.69-.947-1.87-.359-1.87.933v.737l-2.381-.06-2.381-.06-.074-.745c-.118-1.196-.707-1.572-1.643-1.048zm.933 5.19c.147.17.232.481.19.692-.064.321-.306.383-1.51.383s-1.446-.062-1.51-.383c-.133-.66.384-1.001 1.51-1.001.65 0 1.155.118 1.32.309zm4.208 0c.147.17.232.481.19.692-.064.321-.306.383-1.51.383s-1.446-.062-1.51-.383c-.132-.66.384-1.001 1.51-1.001.65 0 1.155.118 1.32.309zm4.209 0c.146.17.232.481.19.692-.065.321-.307.383-1.51.383-1.204 0-1.447-.062-1.51-.383-.133-.66.383-1.001 1.51-1.001.65 0 1.155.118 1.32.309zM7.648 14.948c-3.097 1.838-5.639 3.4-5.648 3.47-.009.073.673 1.582 1.514 3.357L5.044 25l9.296-.055 9.296-.056 1.28-3.194c.968-2.416 1.217-3.253 1.023-3.44-.357-.343-11.25-6.715-11.337-6.631-.039.037-.143 2.15-.23 4.697-.089 2.546-.246 4.63-.35 4.63-.103 0-.189-.743-.19-1.651 0-2.658-.277-7.72-.42-7.705-.073.007-2.666 1.516-5.764 3.353z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}

export default Icon;
