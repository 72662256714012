import {
  UPDATE_GAIN_PROPS_CHANGED,
  UPDATE_GAIN_INITIAL_STATE,
} from '../actions/types';
import {
  UpdateGainState,
  UpdateGainActionTypes,
} from '../modules/UpdateGain/updateGain.types';

const INITIAL_STATE: UpdateGainState = {
  gain: 0,
  gainError: '',
  loading: false,
};

export default (
  state = INITIAL_STATE,
  action: UpdateGainActionTypes
): UpdateGainState => {
  switch (action.type) {
    case UPDATE_GAIN_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };

    case UPDATE_GAIN_INITIAL_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};
