import {
  COUPON_PROPS_CHANGED,
  COUPON_FORM_PROPS,
  COUPON_INITIAL_STATE,
  EDIT_COUPON_INITIAL_STATE,
} from '../actions/types';
import { CouponState, CouponActionTypes } from '../modules/Coupon/coupon.types';
import moment from 'moment';

const INITIAL_STATE: CouponState = {
  expirationDate: moment(),
  labelColor: {
    hex: '#000000',
    rgb: { r: 0, g: 0, b: 0, a: 1 },
  },
  backgroundColor: {
    hex: '#ffffff',
    rgb: { r: 255, g: 255, b: 255, a: 1 },
  },
  foregroundColor: {
    hex: '#000000',
    rgb: { r: 0, g: 0, b: 0, a: 1 },
  },
  percentReduction: '',
  promoCode: '',
  image: null,
  source: 'ALL',
  description: '',
  loading: false,
  template: '',
  symbol: '%',
  barcodeType: 'CODE128',
  //errors
  expirationDateError: '',
  labelColorError: '',
  backgroundColorError: '',
  foregroundColorError: '',
  percentReductionError: '',
  promoCodeError: '',
  nameError: '',
  imageError: '',
  name: '',
  descriptionError: '',
};

export default (state = INITIAL_STATE, action: CouponActionTypes) => {
  switch (action.type) {
    case COUPON_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };

    case COUPON_FORM_PROPS:
      return { ...state, ...action.newState, loading: false };

    case COUPON_INITIAL_STATE:
      return { ...INITIAL_STATE };
    case EDIT_COUPON_INITIAL_STATE:
      return {
        ...INITIAL_STATE,
        ...action.value,
      };
    default:
      return state;
  }
};
