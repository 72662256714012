import 'date-fns';
import React from 'react';

import { useIntl } from 'react-intl';
import styled from 'styled-components';
//Material
import FormControl from '@material-ui/core/FormControl';
import emptyState from '../../assets/images/emptyState.svg';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';

import Text from '../../commons/Text';
import Button from '../../commons/Button';
import Input from '../../commons/Inputs/Input';

import HotelModal from '../../components/Modal';
import { HotelsStyles, HotelsStylesProps } from './hotels.styles';
import EmptyData from '../../components/EmptyData';
import HotelCard from '../../components/Cards/HotelCard';
import { HotelPassApiResponse } from '../../apis/ApiTypes';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import { getDataFromCache } from '../../utils/cache';
import {
  hotelsGetAllHotels,
  hotelsInitialState,
  hotelsPropsChanged,
  deleteHotel,
} from '../../actions';
import CreateHotel from '../Hotel';

import { useHistory } from 'react-router-dom';
const Hotels: React.FC<any> = (props) => {
  const intl = useIntl();
  const [open, setOpen] = React.useState<boolean>(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectOpener, setSelectOpener] = React.useState<boolean>(false);
  const [hotelId, setHotelId] = React.useState('');
  const [hotelIdConfirmed, setHotelIdConfirmed] = React.useState('');
  const [EditDialogOpen, setEditDialogOpen] = React.useState(false);

  const {
    hotelsList,
    hotelsFilterList,
    status,
    search,
    startDate,
    endDate,
    allTime,
    loading,
    loadingSend,
  } = props;
  const [searchFilter, setSearchFilter] = React.useState<any[]>([
    { value: '', name: 'name' },
    { value: '', name: 'type' },
  ]);

  const objectUser: any = getDataFromCache('user');
  const history = useHistory();

  React.useEffect(() => {
    return () => {
      props.hotelsInitialState();
    };
  }, []);
  React.useEffect(() => {
    props.hotelsGetAllHotels(objectUser.wallet, searchFilter);
  }, []);

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    props.hotelsPropsChanged('status', event.target.value);
  };
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.hotelsPropsChanged(event.target.name, event.target.checked);
  };
  // const handleDateChange = (prop: string) => (date: Date | null) => {
  //   props.hotelsPropsChanged(prop, date);
  // };

  // const HandleClickSearch = () => {
  //   props.hotelsFilterHotels(
  //     status,
  //     allTime,
  //     startDate,
  //     endDate,
  //     hotelsFilterList
  //   );
  // };
  // const handleDateChange = (prop: string) => (date: any) => {
  //   // if (prop === 'time') {
  //   //   setTime(date);
  //   //   searchFilter[1].value = new Date(date);
  //   // }

  //   setSearchFilter(searchFilter);
  // };
  const HandleClickSearch = () => {
    props.hotelsGetAllHotels(objectUser.wallet, searchFilter);
  };
  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'search') {
      if (event.target.value.length > 60) return;
      searchFilter[0].value = event.target.value;

      setSearchFilter(searchFilter);
    }
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setHotelIdConfirmed('');
  };
  const handleClickSend = () => {
    props.hotelsSendHotel(objectUser.wallet, hotelId);
    setHotelIdConfirmed(hotelId);
    setOpenDialog(false);
  };
  const handleClickDeletehotel = (hotelId: string) => {
    props.deleteHotel(hotelId, objectUser.wallet);
  };
  const RedirectToCustomers = (url: string) => {
    history.push(url);
  };

  return (
    <Wrapper loading={loading.toString()} hotelsLength={hotelsList.length}>
      <div className='div-search'>
        <div className='div-search-inputs'>
          <div className='div-input-filter'>
            <FormControl className='filter-container' fullWidth>
              <Input
                value={
                  status === 'name'
                    ? intl.formatMessage({ id: 'hotels.select.name' })
                    : status === 'type'
                    ? intl.formatMessage({
                        id: 'hotels.select.type',
                      })
                    : intl.formatMessage({ id: 'hotels.select.name' })
                }
                type='text'
                disabled
              />
              {/* <Input value={'Nom Hotel'} type='text' disabled /> */}
              <div
                className='filter-button'
                onClick={() => setSelectOpener(!selectOpener)}
              >
                <h3>
                  {intl.formatMessage({
                    id: 'wallet.label.filter',
                  })}
                </h3>
                <ExpandMoreRoundedIcon
                  style={{ color: 'white' }}
                  className={selectOpener ? 'filter-opened' : ''}
                />
              </div>
              <Select
                className='select-input '
                value={status}
                onChange={handleSelectChange}
                placeholder={intl.formatMessage({
                  id: 'wallet.label.filter',
                })}
                id='filter'
                inputProps={{ className: 'input' }}
                open={selectOpener}
                onClose={() => setSelectOpener(false)}
              >
                <MenuItem
                  value='name'
                  onMouseEnter={(e) =>
                    ((e.target as HTMLInputElement).style.color = '#ff5078')
                  }
                  onMouseLeave={(e) =>
                    ((e.target as HTMLInputElement).style.color = '#000000')
                  }
                >
                  <span>
                    {/* {intl.formatMessage({ id: 'hotels.select.name' })} */}
                    {'Nom Hotel'}
                  </span>
                </MenuItem>
                <MenuItem
                  value='type'
                  onMouseEnter={(e) =>
                    ((e.target as HTMLInputElement).style.color = '#ff5078')
                  }
                  onMouseLeave={(e) =>
                    ((e.target as HTMLInputElement).style.color = '#000000')
                  }
                >
                  <span>
                    {/* {intl.formatMessage({
                      id: 'hotels.select.type',
                    })} */}
                    {'Type de Chambre'}
                  </span>
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className='div-input-search'>
            <Input
              value={search}
              background='#fff'
              type='text'
              name='search'
              placeholder={intl.formatMessage({
                id: 'wallet.button.rechercher',
              })}
              // width={690}
              margin='10px 0'
              className='input-search'
              onChange={handleEventChange}
            />
            <Button
              onClick={HandleClickSearch}
              width={120}
              background='#FF5078'
              className='button-search'
            >
              {intl.formatMessage({ id: 'wallet.button.rechercher' })}
            </Button>
          </div>
        </div>
      </div>

      <div className='div-header'>
        <Text color='#404040' fontSize={24} lineHeight={36} fontWeight={600}>
          {intl.formatMessage({ id: 'hotels.label.title' })}
        </Text>
        <Button
          onClick={() => {
            setOpen(!open);
          }}
        >
          {intl.formatMessage({ id: 'hotels.button.create' })}
        </Button>
      </div>
      <HotelModal open={open} onClose={() => setOpen(!open)}>
        <CreateHotel
          walletId={objectUser.wallet}
          onSaveChanged={() => setOpen(!open)}
        ></CreateHotel>
      </HotelModal>
      <div className='div-list'>
        <EmptyData
          data={hotelsList}
          loading={loading}
          message={intl.formatMessage({ id: 'hotels.label.emptyList' })}
          image={emptyState}
        >
          {hotelsList.map((hotel: HotelPassApiResponse) => (
            <HotelCard
              key={hotel._id}
              onClickDelete={handleClickDeletehotel}
              hotel={hotel}
              onClickRedirect={() => history.push('/hotels/' + hotel._id)}
            ></HotelCard>
          ))}
        </EmptyData>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled('div')<HotelsStylesProps>`
  ${(props) => HotelsStyles(props)}
`;
const mapStateToProps = ({ hotels }: RootState) => {
  const {
    hotelsList,
    hotelsFilterList,
    startDate,
    endDate,
    allTime,
    loading,
    status,
  } = hotels;

  return {
    hotelsList,
    hotelsFilterList,
    startDate,
    endDate,
    allTime,
    loading,
    status,
  };
};
export const connector = connect(mapStateToProps, {
  hotelsGetAllHotels,
  hotelsInitialState,
  hotelsPropsChanged,
  deleteHotel,
});
export default connector(Hotels);
