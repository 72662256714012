import {
  getCouponsList,
  sendCoupon,
  activateCoupon,
  removeCoupons,
  getCouponsChildrenList,
  removeCustomerCoupon,
} from '../apis/couponsApi';
import {
  COUPONS_GET_LIST_COUPONS,
  COUPONS_INITIAL_STATE,
  COUPONS_DELETE_COUPON_LOADING,
  COUPONS_DELETE_COUPON,
  AppThunk,
  COUPONS_UPDATE_COUPON_STATUS,
  SNACKBAR_SUCCESS,
  SNACKBAR_INFO,
  SNACKBAR_ERROR,
  COUPONS_UPDATE_PROPS,
  WALLET_FORM_PROPS_CHANGED,
  COUPONS_GET_SENT_COUPONS,
  COUPONS_PROPS_CHANGED,
  COUPONS_DELETE_CUSTOMER_COUPON,
  COUPONS_FILTER_COUPONS,
} from './types';
import { CouponApiResponse, currenciesApi } from '../apis/ApiTypes';
import { CouponsInitialStateAction } from '../modules/Coupons/coupons.types';
import { getCurrencies } from '../apis/currencyApi';
import { Moment } from 'moment';
import moment from 'moment';

export const couponsGetAllCoupons = (walletId: string): AppThunk => (
  dispatch
) => {
  dispatch({
    type: COUPONS_UPDATE_PROPS,
    prop: 'couponsList',
    value: [],
  });
  dispatch({
    type: COUPONS_UPDATE_PROPS,
    prop: 'loading',
    value: true,
  });
  getCouponsList(walletId).then((res: Array<CouponApiResponse>) => {
    dispatch({
      type: COUPONS_GET_LIST_COUPONS,
      payload: res,
    });
  });
  getCurrencies().then((res: currenciesApi) => {
    dispatch({
      type: WALLET_FORM_PROPS_CHANGED,
      prop: 'currencies',
      value: res.data,
    });
  });
};

export const couponsGetChildrens = (
  walletId: string,
  couponId: string,
  page: number | string,
  size: number | string,
  searchFilter: any
): AppThunk => (dispatch) => {
  getCouponsChildrenList(walletId, couponId, page, size, searchFilter).then(
    (res: any) => {
      if (res.status === 500) {
        dispatch({
          type: SNACKBAR_ERROR,
          value: 'wallet.error.occured',
        });
      } else
        dispatch({
          type: COUPONS_GET_SENT_COUPONS,
          payload: res.data,
          total: res.total,
        });
    }
  );
  getCurrencies().then((res: currenciesApi) => {
    dispatch({
      type: WALLET_FORM_PROPS_CHANGED,
      prop: 'currencies',
      value: res.data,
    });
  });
};

export const couponsSendCoupon = (
  walletId: string,
  couponId: string
): AppThunk => (dispatch) => {
  dispatch({
    type: COUPONS_UPDATE_PROPS,
    prop: 'loadingSend',
    value: true,
  });

  sendCoupon(walletId, couponId).then((res: any) => {
    dispatch({
      type: COUPONS_UPDATE_PROPS,
      prop: 'loadingSend',
      value: false,
    });
    if (res.status === 200) {
      if (res.data.status === 'success') {
        dispatch({
          type: SNACKBAR_SUCCESS,
          value: `coupon.success.sent`,
        });
      } else {
        dispatch({
          type: SNACKBAR_INFO,
          value: `coupon.info.noActiveClients`,
        });
      }
    } else {
      dispatch({
        type: SNACKBAR_ERROR,
        value: 'wallet.error.occured',
      });
    }
  });
};

export const couponsUpdateCouponStatus = (
  walletId: string,
  couponId: string,
  status: string
): AppThunk => (dispatch) => {
  activateCoupon(walletId, couponId, status).then((res: any) => {
    if (res.status === 200) {
      dispatch({
        type: COUPONS_UPDATE_COUPON_STATUS,
        id: couponId,
        status,
      });
    } else if (res.status === 400) {
      dispatch({
        type: SNACKBAR_INFO,
        value: 'coupon.info.couponActive',
      });
    } else {
      dispatch({
        type: SNACKBAR_ERROR,
        value: 'wallet.error.occured',
      });
    }
  });
};
export const couponsPropsChanged = (prop: string, value: any): any => {
  return { type: COUPONS_PROPS_CHANGED, prop, value };
};

export const deleteCoupon = (couponId: string, walletId: string): AppThunk => (
  dispatch
) => {
  dispatch({
    type: COUPONS_DELETE_COUPON_LOADING,
    payload: true,
  });
  removeCoupons(couponId, walletId).then((res) => {
    if (res.data.status === 'success') {
      dispatch({
        type: COUPONS_DELETE_COUPON,
        payload: couponId,
      });

      dispatch({
        type: SNACKBAR_SUCCESS,
        value: 'coupon.success.deleted',
      });
    } else {
      dispatch({
        type: SNACKBAR_ERROR,
        value: 'wallet.error.occured',
      });
    }
  });
};

export const deleteCustomerCoupon = (
  couponId: string,
  customerId: string,
  passId: string,
  walletId: string
): AppThunk => (dispatch) => {
  dispatch({
    type: COUPONS_DELETE_COUPON_LOADING,
    payload: true,
  });
  removeCustomerCoupon(couponId, customerId, walletId).then((res) => {
    if (res.data.status === 'success') {
      dispatch({
        type: COUPONS_DELETE_CUSTOMER_COUPON,
        payload: passId,
      });

      dispatch({
        type: SNACKBAR_SUCCESS,
        value: 'coupon.success.deleted',
      });
    } else {
      dispatch({
        type: SNACKBAR_ERROR,
        value: 'wallet.error.occured',
      });
    }
  });
};
export const couponsInitialState = (): CouponsInitialStateAction => {
  return { type: COUPONS_INITIAL_STATE };
};

export const couponsFilterCoupons = (
  status: string,
  allTime: boolean,
  startDate: Moment | null,
  endDate: Moment | null,
  coupons: Array<CouponApiResponse>
): AppThunk => (dispatch) => {
  let newCoupons: Array<any> = Object.assign([], coupons);

  const result: Array<CouponApiResponse> = [];
  let dateStart = `${moment(startDate).format('YYYY-MM-DD')}T00:00:00.000Z`;
  let dateEnd = `${moment(endDate).format('YYYY-MM-DD')}T00:00:00.000Z`;

  if (startDate && endDate && moment(startDate).isAfter(endDate, 'day')) {
    dispatch({
      type: COUPONS_UPDATE_PROPS,
      prop: 'endDateError',
      value: 'offer.error.endDateBeforeStartDate',
    });

    return;
  } else {
    dispatch({
      type: COUPONS_UPDATE_PROPS,
      prop: 'endDateError',
      value: '',
    });
  }

  if (allTime) {
    if (status === 'ALL') {
      dispatch({
        type: COUPONS_FILTER_COUPONS,
        allTime,
        payload: newCoupons,
      });
    } else {
      newCoupons.forEach((item) => {
        if (item.status === status) {
          result.push(item);
        }
      });
      dispatch({
        type: COUPONS_FILTER_COUPONS,
        allTime,
        payload: result,
      });
    }
    return;
  }

  if (startDate && endDate) {
    if (status === 'ALL') {
      newCoupons.forEach((item) => {
        if (
          moment(item.expirationDate).isSameOrAfter(dateStart) &&
          moment(item.expirationDate).isSameOrBefore(dateEnd)
        ) {
          result.push(item);
        }
      });
      dispatch({
        type: COUPONS_FILTER_COUPONS,
        allTime,
        payload: result,
      });
    } else {
      newCoupons.forEach((item) => {
        if (
          item.status === status &&
          moment(item.expirationDate).isSameOrAfter(dateStart) &&
          moment(item.expirationDate).isSameOrBefore(dateEnd)
        ) {
          result.push(item);
        }
      });

      dispatch({
        type: COUPONS_FILTER_COUPONS,
        allTime,
        payload: result,
      });
    }
  } else {
    if (status === 'ALL') {
      dispatch({
        type: COUPONS_FILTER_COUPONS,
        allTime,
        payload: newCoupons,
      });
    } else {
      newCoupons.forEach((item) => {
        if (item.status === status) {
          result.push(item);
        }
      });
      dispatch({
        type: COUPONS_FILTER_COUPONS,
        allTime,
        payload: result,
      });
    }
  }
};
