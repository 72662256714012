import { DEMOS_GET_SENT_DEMOS, DEMOS_PROPS_CHANGED } from '../actions/types';

const INITIAL_STATE: any = {
  total: 0,
  sentDemosList: [],
  loading: true,
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case DEMOS_GET_SENT_DEMOS:
      return {
        ...state,
        loading: false,
        sentDemosList: action.payload.data,
        total: action.payload.total,
      };

    default:
      return state;
  }
};
