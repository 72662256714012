import React from 'react';
import { useIntl } from 'react-intl';
import { Route, Switch, RouteComponentProps, Router } from 'react-router-dom';
import SideBar from '../../components/Drawer';

import Events from '../../modules/Events';
import { useStyles } from './EventsRouter.styles';
import EventCustomers from '../../modules/EventCustomers';
import CreateEvent from '../../modules/CreateEvent';
import EditEvent from '../../modules/EditEvent';

import NotFound from '../../modules/NotFound';
//Modules

const EventsRouter: React.FC<RouteComponentProps> = (props) => {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <div className={classes.rootAppRouter}>
      <SideBar title={intl.formatMessage({ id: 'drawer.menuList.event' })}>
        <Switch>
          <Route path='/event' component={Events} exact />
          <Route path='/event/create' component={CreateEvent} exact />
          <Route path='/event/edit/:eventId' component={EditEvent} />
          <Route path='/event/:eventID' component={EventCustomers} />
          <Route path='/event/*' component={NotFound} />
        </Switch>
      </SideBar>
    </div>
  );
};

export default EventsRouter;
