import {
  FLIGHTS_PROPS_CHANGED,
  FLIGHTS_INITIAL_STATE,
  FLIGHTS_ADD_FLIGHT_TO_LIST,
  FLIGHTS_DELETE_FLIGHT,
  FLIGHTS_DELETE_FLIGHT_LOADING,
  FLIGHTS_FILTER_FLIGHTS,
  FLIGHTS_GET_LIST_FLIGHTS,
  FLIGHTS_GET_SENT_FLIGHTS,
  UPDATE_SENT_FLIGHTS_LIST,
} from '../actions/types';
import { FlightsApiResponse } from '../apis/ApiTypes';
import { FlightsState } from '../modules/Flights/flights.types';

const INITIAL_STATE: FlightsState = {
  flightsList: [],
  loading: true,
  flightsFilterList: [],
  startDate: null,
  endDate: null,
  search: '',
  total: 0,
  select: '',
  allTime: false,
  status: '',
  //error
  startDateError: '',
  endDateError: '',
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case FLIGHTS_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };
    case FLIGHTS_FILTER_FLIGHTS:
      if (action.allTime) {
        return {
          ...state,
          startDate: null,
          flightsList: action.payload,
        };
      }
      return {
        ...state,
        flightsList: action.payload,
      };
    case FLIGHTS_GET_LIST_FLIGHTS:
      return {
        ...state,
        flightsList: action.payload,
        flightsFilterList: action.payload,
        loading: false,
      };
    case FLIGHTS_INITIAL_STATE:
      return { ...INITIAL_STATE };
    case FLIGHTS_ADD_FLIGHT_TO_LIST:
      let flightsSortedByStartDate: Array<FlightsApiResponse> = Object.assign(
        [],
        state.flightsList
      );

      flightsSortedByStartDate = [...state.flightsList, ...[action.payload]];

      return {
        ...state,
        flightsList: [
          ...flightsSortedByStartDate.sort(
            (a, b) => <any>new Date(a.createdAt) - <any>new Date(b.createdAt)
          ),
        ],
      };
    case FLIGHTS_DELETE_FLIGHT_LOADING: {
      return { ...state, loading: true };
    }

    case FLIGHTS_GET_SENT_FLIGHTS:
      return {
        ...state,
        flightsList: action.payload,
        total: action.total,
        loading: false,
      };

    case FLIGHTS_DELETE_FLIGHT:
      var newFerriesList = state.flightsList.filter(function (item) {
        return item._id !== action.payload;
      });
      return { ...state, loading: false, flightsList: newFerriesList };

    case UPDATE_SENT_FLIGHTS_LIST:
      let flightsListO = [...state.flightsList];

      flightsListO.forEach((row: any, index: number) => {
        if (
          row._id === action.row._id &&
          row.customerId === action.row.customerId
        ) {
          flightsListO[index].seatNumber = action.row.seatNumber;
          flightsListO[index].boardingGroup = action.row.boardingGroup;
          flightsListO[index].seatClass = action.row.seatClass;
          flightsListO[index].confirmationCode = action.row.confirmationCode;
        }
      });

    default:
      return state;
  }
};
