import React from 'react';
import clsx from 'clsx';
import {
  createStyles,
  lighten,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { getDataFromCache } from '../../utils/cache';
import { useIntl } from 'react-intl';
const moment = require('moment');
interface Data {
  _id: string;
  customerId: string;
  originAirport: string;
  walletName: string;
  destinationAirport: string;
  departureDateTime: string;
  passenger: string;
  number: string;
  terminalAndGate: string;
  description: string;
  passId: string;
}

function createData(
  _id: string,
  customerId: string,
  originAirport: string,
  walletName: string,
  destinationAirport: string,
  departureDateTime: string,
  passenger: string,
  number: string,
  terminalAndGate: string,
  description: string,
  passId: string
): Data {
  return {
    _id,
    customerId,
    originAirport,
    walletName,
    destinationAirport,
    departureDateTime,
    passenger,
    number,
    terminalAndGate,
    description,
    passId,
  };
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  {
    id: '_id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'walletName',
    numeric: false,
    disablePadding: false,
    label: 'Airline',
  },
  {
    id: 'originAirport',
    numeric: false,
    disablePadding: false,
    label: 'Station de depart',
  },
  {
    id: 'destinationAirport',
    numeric: false,
    disablePadding: false,
    label: 'Station d’arrivée',
  },
  {
    id: 'departureDateTime',
    numeric: false,
    disablePadding: false,
    label: 'Date de Depart',
  },
  {
    id: 'passenger',
    numeric: false,
    disablePadding: false,
    label: 'Passenger',
  },

  {
    id: 'number',
    numeric: false,
    disablePadding: false,
    label: 'Vol',
  },
  {
    id: 'terminalAndGate',
    numeric: false,
    disablePadding: false,
    label: 'Terminal/Gate',
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Description',
  },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property: keyof Data) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: '1 1 100%',
    },
  })
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '&:nth-of-type(odd)': {
        backgroundColor: 'white',
      },
      '&:nth-of-type(even)': {
        backgroundColor: 'grey',
      },
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  })
);
type CollapsibleTableProps = {
  data: Array<any>;
  onClickEdit: (id: any) => void;
  onClickDelete: (id: any) => void;
  page: number;
  rowsPerPage: number;
};
const SortableTable: React.FC<CollapsibleTableProps> = (props) => {
  const { data, onClickEdit, onClickDelete, page, rowsPerPage } = props;
  const currentWallet = getDataFromCache('wallet');
  const rows: Data[] = [];
  const [selectedFlight, setSelectedFlight] = React.useState<Data>();

  data.forEach((row: Data) => {
    rows.push(
      createData(
        row._id,
        row.customerId,
        row.originAirport,
        row.walletName,
        row.destinationAirport,
        row.departureDateTime,
        row.passenger,
        row.number,
        row.terminalAndGate,
        row.description,
        row.passId
      )
    );
  });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClickMore = (flight: Data) => (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setSelectedFlight(flight);
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const [is_open, setIsOpen] = React.useState<any>(null);
  const [selectOpener, setSelectOpener] = React.useState<boolean>(false);

  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('_id');
  const [selected, setSelected] = React.useState<string[]>([]);
  const intl = useIntl();
  const [dense, setDense] = React.useState(false);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n: any) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  return (
    <div className={classes.root}>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby='tableTitle'
          size={dense ? 'small' : 'medium'}
          aria-label='enhanced table'
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy)).map(
              (row, index) => {
                const isItemSelected = isSelected(row._id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role='checkbox'
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                    selected={isItemSelected}
                    style={
                      index % 2
                        ? { background: ' white' }
                        : { background: '#fbfaff' }
                    }
                  >
                    <TableCell
                      component='th'
                      id={labelId}
                      scope='row'
                      padding='none'
                      style={{ paddingLeft: '28px' }}
                    >
                      {row.passId}
                    </TableCell>
                    <TableCell align='left'>{currentWallet.name}</TableCell>
                    <TableCell align='left'>{row.originAirport}</TableCell>
                    <TableCell align='left'>{row.destinationAirport}</TableCell>
                    <TableCell align='left'>{row.departureDateTime}</TableCell>
                    <TableCell align='left'>{row.passenger}</TableCell>
                    <TableCell align='left'>{row.number}</TableCell>
                    <TableCell align='left'>{row.terminalAndGate}</TableCell>
                    <TableCell align='left'>{row.description}</TableCell>

                    <TableCell align='left'>
                      <IconButton onClick={handleClickMore(data[index])}>
                        <MoreVertIcon fontSize='small' />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClick={handleClose}
      >
        <MenuItem onClick={() => onClickEdit(selectedFlight)}>
          {intl.formatMessage({ id: 'event.label.update' })}
        </MenuItem>
        <MenuItem onClick={() => onClickDelete(selectedFlight?.passId)}>
          {intl.formatMessage({ id: 'settings.button.delete' })}
        </MenuItem>
      </Menu>
    </div>
  );
};
export default SortableTable;
