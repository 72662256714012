import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='269'
      height='36'
      fill='none'
      viewBox='0 0 269 36'
    >
      <path
        fill='#404040'
        d='M269 18H270V287H269z'
        transform='rotate(90 269 18)'
      ></path>
      <path
        fill='#404040'
        d='M136 18H137V36H136z'
        transform='rotate(-180 136 18)'
      ></path>
      <path
        fill='#404040'
        d='M269 36H270V54H269z'
        transform='rotate(-180 269 36)'
      ></path>
      <path
        fill='#404040'
        d='M1 36H2V54H1z'
        transform='rotate(-180 1 36)'
      ></path>
    </svg>
  );
}

export default Icon;
