import { css } from 'styled-components';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const eventCustomersDetailStyles = (props: any) => {
  return css`
    display: flex;
    flex-direction: column;
    /* padding: 10px; */

    .div-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 30px 0;

      .div-input-filter {
        min-width: 400px;
        margin-right: 15px;
      } 
      .select-input {
        width: 150px;
        visibility: hidden;
        position: absolute;
        left: 260px;
        top: 70px;
      }
      .filter-button{
        display: flex;
        position: absolute;
        left: 290px;
        width: 100px;
        align-items: center;
        justify-content: space-between;
        background: #4914F4;
        height: 44px;
        box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.08);
        border-radius: 15px;
        padding: 0 10px;
        transition: all 0.3s ease;
        :hover {
          cursor: pointer;
          background: #6438f5;
        }
        > h3{
          font-family: Poppins;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: white;
          width: 80px;
        }
      }
      .filter-opened{
        transform: rotate(180deg)
      }
      .filter-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        > input {
          background-color: #fff;
          font-family: Poppins;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          color: #404040;
          box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.08);
          border-radius: 15px;
        }
      }
      .div-input-search {
        flex: 1; 
        position: relative;
        margin-left: 15px;
        min-width: 450px;
        .input-search {
          box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.08);
          border-radius: 15px;
        }
        .button-search {
          position: absolute;
        top: 10px;
        right: -1px;
        margin: 0;
        height: 53px;
          border-radius: 15px;
        }
      }

      /* .logo-container {
        display: flex;
        align-items: center;

        .logo {
          width: 100px;
          height: 100px;
          border-radius: 6px;
          overflow: hidden;
          background-color: ${props.background};
          img {
            width: 100%;
            height: 100%;
            object-fit: fill;
          }
        }
      } */
      /* .wallet-name {
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 24px;
        color: rgb(50, 50, 70);
        letter-spacing: 0.3px;
        margin: 0 10px;
      } */
    }
 
    .table-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 15px;
    }
    .text-error {
      font-size: 24px;
      color: red;
    }
    label.Mui-focused {
      color: #ff5078;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #ff5078 !important;
      border-width: 2px;
    }
  `;
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },

    circularProgress: {
      color: '#ff5078',
    },
  })
);
