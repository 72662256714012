import { ColorProps, BusState } from './bus.types';
import { isEmpty } from '../../utils/validations';
import moment from 'moment';
import { convertMomentToTime } from '../../utils/common';

export const validateFieldsBus = (state: BusState) => {
  let newState: BusState = Object.assign({}, state);

  if (!newState.number) {
    newState.numberError = 'ship.error.numberRequired';
  } else if (newState.number && !newState.number) {
    newState.numberError = 'ship.error.numberInvalid';
  } else {
    newState.numberError = '';
  }

  if (!newState.labelColor) {
    newState.labelColorError = 'ship.error.labelColorRequired';
  } else {
    newState.labelColorError = '';
  }

  if (!newState.backgroundColor) {
    newState.backgroundColorError = 'ship.error.backgroundColorRequired';
  } else {
    newState.backgroundColorError = '';
  }

  if (!newState.foregroundColor) {
    newState.foregroundColorError = 'ship.error.foregroundColorRequired';
  } else {
    newState.foregroundColorError = '';
  }

  if (!newState.originName) {
    newState.originNameError = 'ship.error.originNameRequired';
  } else if (newState.originName && !newState.originName) {
    newState.originNameError = 'ship.error.originNameInvalid';
  } else {
    newState.originNameError = '';
  }
  if (!newState.destinationName) {
    newState.destinationNameError = 'ship.error.destinationNameRequired';
  } else if (newState.destinationName && !newState.destinationName) {
    newState.destinationNameError = 'ship.error.destinationNameInvalid';
  } else {
    newState.destinationNameError = '';
  }
  if (!newState.originStationCode) {
    newState.originStationCodeError = 'ship.error.originStationCodeRequired';
  } else if (newState.originStationCode && !newState.originStationCode) {
    newState.originStationCodeError = 'ship.error.originStationCodeInvalid';
  } else {
    newState.originStationCodeError = '';
  }
  if (!newState.destinationStationCode) {
    newState.destinationStationCodeError =
      'ship.error.destinationStationCodeRequired';
  } else if (
    newState.destinationStationCode &&
    !newState.destinationStationCode
  ) {
    newState.destinationStationCodeError =
      'ship.error.destinationStationCodeInvalid';
  } else {
    newState.destinationStationCodeError = '';
  }

  if (!newState.image) {
    newState.imageError = 'ship.error.imageRequired';
  } else {
    newState.imageError = '';
  }

  if (isEmpty(newState.details)) {
    newState.detailsError = 'ship.error.detailsRequired';
  } else {
    newState.detailsError = '';
  }
  if (isEmpty(newState.tarif)) {
    newState.tarifError = 'ship.error.tarifRequired';
  } else {
    newState.tarifError = '';
  }
  if (isEmpty(newState.customerServiceNumber)) {
    newState.customerServiceNumberError =
      'ship.error.customerServiceNumberRequired';
  } else {
    newState.customerServiceNumberError = '';
  }
  if (isEmpty(newState.terms)) {
    newState.termsError = 'ship.error.termsRequired';
  } else {
    newState.termsError = '';
  }

  let valid = false;
  if (
    newState.labelColorError === '' &&
    newState.backgroundColorError === '' &&
    newState.foregroundColorError === '' &&
    newState.imageError === '' &&
    newState.arrivalTimeError === '' &&
    newState.departureTimeError === '' &&
    newState.originStationCodeError === '' &&
    newState.originNameError === '' &&
    newState.destinationNameError === '' &&
    newState.destinationStationCodeError === '' &&
    newState.numberError === '' &&
    newState.tarifError === '' &&
    newState.detailsError === '' &&
    newState.termsError === '' &&
    newState.stripError === '' &&
    newState.customerServiceNumberError === ''
  ) {
    valid = true;
  }

  return { newState, valid };
};

// export const newBusObject = (bus: any) => {
//   let formData = new FormData();

//   if (bus) {
//     formData.append(
//       'departureTime',
//       moment(bus.departureTime).format('YYYY-MM-DD')
//     );
//     formData.append(
//       'arrivalTime',
//       moment(bus.arrivalTime).format('YYYY-MM-DD')
//     );
//     formData.append('originName', bus.originName);
//     formData.append('destinationName', bus.destinationName);
//     return formData;
//   }

//   return null;
// };

export const newBusObject = (bus: BusState) => {
  let formData = new FormData();

  if (bus.image) {
    formData.append('departureTime', convertMomentToTime(bus.departureTime));
    formData.append('arrivalTime', convertMomentToTime(bus.arrivalTime));
    formData.append('labelColor', objectColorJSON(bus.labelColor));
    formData.append('backgroundColor', objectColorJSON(bus.backgroundColor));
    formData.append('foregroundColor', objectColorJSON(bus.foregroundColor));
    formData.append('strip', bus.image);
    formData.append('originName', bus.originName);
    formData.append('destinationName', bus.destinationName);
    formData.append('originStationCode', bus.originStationCode);
    formData.append('destinationStationCode', bus.destinationStationCode);
    formData.append('number', bus.number);
    formData.append('details', bus.details);
    formData.append('terms', bus.terms);
    formData.append('tarif', bus.tarif);
    formData.append('customerServiceNumber', bus.customerServiceNumber);
    formData.append('type', 'bus');
    formData.append(
      'locations',
      JSON.stringify([
        {
          longitude: 0,
          latitude: 0,
        },
      ])
    );
    return formData;
  }

  return null;
};

export const objectColorJSON = (objectColor: ColorProps) => {
  let newObjectColor = {
    hex: objectColor.hex,
    rgb:
      typeof objectColor.rgb.r === 'number' &&
      typeof objectColor.rgb.g === 'number' &&
      typeof objectColor.rgb.b === 'number'
        ? `rgb(${objectColor.rgb.r},${objectColor.rgb.g},${objectColor.rgb.b})`
        : objectColor.rgb,
  };

  const newObjectColorJSON = JSON.stringify(newObjectColor);
  return newObjectColorJSON;
};

export const validateCustomerFieldsBus = (state: any) => {
  let newState: any = Object.assign({}, state);

  if (!newState.departureDate) {
    newState.departureDateError = 'ferry.error.departureDateRequired';
  } else {
    newState.departureDateError = '';
  }

  if (!newState.arrivalDate) {
    newState.arrivalDateError = 'ferry.error.arrivalDateRequired';
  } else {
    newState.arrivalDateError = '';
  }
  if (!newState.passengerType) {
    newState.passengerTypeError = 'ferry.error.passengerTypeRequired';
  } else {
    newState.passengerTypeError = '';
  }
  if (!newState.tripType) {
    newState.tripTypeError = 'ferry.error.tripTypeRequired';
  } else {
    newState.tripTypeError = '';
  }

  let valid = false;
  if (
    newState.departureDateError === '' &&
    newState.arrivalDateError === '' &&
    newState.passengerTypeError === '' &&
    newState.tripTypeError === ''
  ) {
    valid = true;
  }
  return { newState, valid };
};
