import axios from 'axios';
import { URL } from './config';
import { SettingsState } from '../modules/Settings/settings.types';
import { SettingsApiResponse } from './ApiTypes';

export const getSettings = (walletID: string) => {
  const requestURL = `${URL}/settings/${walletID}`;

  return axios
    .get(requestURL)
    .then(({ data }: { data: SettingsApiResponse }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const addSettingsToWallet = (
  walletId: string,
  props: SettingsState,
  walletName: string
) => {
  const requestURL = `${URL}/settings/${walletId}`;
  return axios
    .patch(requestURL, {
      successMessage: props.successMessage,
      emailMessage: props.emailMessage,
      newOfferEmailMessage: props.newOfferEmailMessage,
      newOfferNotificationMessage: props.newOfferNotificationMessage,
      newCouponEmailMessage: props.newCouponEmailMessage,
      newCouponNotificationMessage: props.newCouponNotificationMessage,
      expiredOfferEmailMessage: props.expiredOfferEmailMessage,
      expiredOfferNotificationMessage: props.expiredOfferNotificationMessage,
      newUpdateEmailMessage: props.newUpdateEmailMessage,
      newUpdateNotificationMessage: props.newUpdateNotificationMessage,
      enableNewUpdateEmailMessage: props.enableNewUpdateEmailMessage,
      enableOfferEmailMessage: props.enableOfferEmailMessage,
      enableOfferSMSMessage: props.enableOfferSMSMessage,
      showBarcode: props.showBarcode,
      enableCouponEmailMessage: props.enableCouponEmailMessage,
      enableCouponSMSMessage: props.enableCouponSMSMessage,
      communicationChannels: props.communicationChannels,
      smsTitle: props.smsTitle ? props.smsTitle : walletName,
      newAccountSMSMessage: props.newAccountSMSMessage,
      newEventSMSMessage: props.newEventSMSMessage,
      newOfferSMSMessage: props.newOfferSMSMessage,
      expiredOfferSMSMessage: props.expiredOfferSMSMessage,
      newCouponSMSMessage: props.newCouponSMSMessage,
      newUpdateSMSMessage: props.newUpdateSMSMessage,
      hotelEmailSubscriptionMessage: props.hotelEmailSubscriptionMessage,
      cinemaEmailSubscriptionMessage: props.cinemaEmailSubscriptionMessage,
      transitEmailSubscriptionMessage: props.transitEmailSubscriptionMessage,
      flightEmailSubscriptionMessage: props.flightEmailSubscriptionMessage,

      busEmailHtmlCode: props.busEmailHtmlCode,
      trainEmailHtmlCode: props.trainEmailHtmlCode,
      boatEmailHtmlCode: props.boatEmailHtmlCode,
      hotelEmailHtmlCode: props.hotelEmailHtmlCode,
      cinemaEmailHtmlCode: props.cinemaEmailHtmlCode,
      flightEmailHtmlCode: props.flightEmailHtmlCode,
      eventEmailHtmlCode: props.eventEmailHtmlCode,
      newAccountEmailHtmlCode: props.newAccountEmailHtmlCode,
      couponEmailHtmlCode: props.couponEmailHtmlCode,
      offerEmailHtmlCode: props.offerEmailHtmlCode,
      storeCardUpdateEmailHtmlCode: props.storeCardUpdateEmailHtmlCode,

      //storecard
      enableNewStorecardEmailMessage: props.enableNewStorecardEmailMessage,
      enableStorecardRemainderEmailMessage:
        props.enableStorecardRemainderEmailMessage,
      enableNewStorecardSMSMessage: props.enableNewStorecardSMSMessage,
      enableStorecardRemainderSMSMessage:
        props.enableStorecardRemainderSMSMessage,
      enableNewUpdateNotification: props.enableNewUpdateNotification,
      enableStorecardReminderEmailMessage:
        props.enableStorecardReminderEmailMessage,
      enableStorecardReminderSMSMessage:
        props.enableStorecardReminderSMSMessage,
      //event
      enableUpdateEventEmailMessage: props.enableUpdateEventEmailMessage,
      enableUpdateEventNotification: props.enableUpdateEventNotification,

      //coupon
      enableWelcomeCouponEmailMessageForExternalClients:
        props.enableWelcomeCouponEmailMessageForExternalClients,
      enableWelcomeCouponEmailMessageForInternalClients:
        props.enableWelcomeCouponEmailMessageForInternalClients,
      enableWelcomeCouponEmailMessageForCRMClients:
        props.enableWelcomeCouponEmailMessageForCRMClients,
      enableUpdateCouponSMSMessage: props.enableUpdateCouponSMSMessage,
      enableWelcomeCouponSMSMessageForExternalClients:
        props.enableWelcomeCouponSMSMessageForExternalClients,
      enableWelcomeCouponSMSMessageForInternalClients:
        props.enableWelcomeCouponSMSMessageForInternalClients,
      enableWelcomeCouponSMSMessageForCRMClients:
        props.enableWelcomeCouponSMSMessageForCRMClients,
      enableWelcomeCouponNotificationMessageForExternalClients:
        props.enableWelcomeCouponNotificationMessageForExternalClients,
      enableWelcomeCouponNotificationMessageForInternalClients:
        props.enableWelcomeCouponNotificationMessageForInternalClients,
      enableWelcomeCouponNotificationMessageForCRMClients:
        props.enableWelcomeCouponNotificationMessageForCRMClients,
      enableUpdateCouponNotificationMessage:
        props.enableUpdateCouponNotificationMessage,
      enableNewCouponNotificationMessage:
        props.enableNewCouponNotificationMessage,
      enableUpdateCouponEmailMessage: props.enableUpdateCouponEmailMessage,

      //offer
      enableExpiredOfferEmailMessage: props.enableExpiredOfferEmailMessage,
      enableNewOfferNotificationMessage:
        props.enableNewOfferNotificationMessage,
      enableExpiredOfferNotificationMessage:
        props.enableExpiredOfferNotificationMessage,
      enableExpiredOfferSMSMessage: props.enableExpiredOfferSMSMessage,
      wallet: walletId,
    })
    .then(({ data }: { data: SettingsApiResponse }) => {
      return data;
    })
    .catch((error) => {
      return error.response;
    });
};
