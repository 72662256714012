import React from 'react';

function Icon({
  width = '28',
  height = '28',
}: {
  width?: string;
  height?: string;
}) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      fill='none'
      viewBox='0 0 28 28'
    >
      <path
        fill='#C7C7C7'
        fillRule='evenodd'
        d='M22.486 5.083c-.108.094-1.355 3.037-1.352 3.191.002.123.079.233.215.307.144.078 4.292.078 4.435 0 .137-.074.214-.184.216-.307.002-.154-1.244-3.097-1.352-3.191C24.564 5.01 24.44 5 23.567 5s-.998.01-1.081.083zM8.449 8.453c-.58.084-1.162.492-1.473 1.034-.226.393-.266.672-.266 1.874v1.1H8.61v-.251c0-.566.317-1.01.794-1.115.308-.067 2.47-.066 2.78.002.185.04.29.102.461.271.246.243.324.445.324.845v.248h1.577v-.248c0-.4.078-.602.324-.845.302-.298.448-.324 1.856-.324 1.413 0 1.56.026 1.854.326.234.239.325.483.325.876v.215h1.902v-1.1c0-.841-.017-1.166-.072-1.38a2.078 2.078 0 00-1.714-1.533c-.403-.057-10.176-.054-10.573.004zm14.724 3.103v2.165l.37.316.372.316V9.393h-.742v2.164zM4.684 14.41c-.77.656-1.401 1.208-1.403 1.228-.001.02 4.713.035 10.478.035 5.764 0 10.48-.016 10.478-.035-.001-.02-.632-.572-1.402-1.228l-1.4-1.192H6.085l-1.4 1.192zM3 18.083v1.655l.114.116.114.115H24.29l.114-.116.113-.115v-3.31H3v1.655zm.927 3.164c0 .49.007.528.114.637l.114.116H6.25l.114-.116c.107-.109.114-.147.114-.637v-.522H3.927v.522zm17.113 0c0 .49.006.528.113.637l.114.116H23.362l.114-.116c.107-.109.114-.147.114-.637v-.522h-2.551v.522z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}

export default Icon;
