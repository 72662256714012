import {
  FERRIES_PROPS_CHANGED,
  FERRIES_GET_LIST_FERRIES,
  FERRIES_INITIAL_STATE,
  FERRIES_DELETE_FERRY,
  FERRIES_DELETE_FERRY_LOADING,
  FERRIES_GET_SENT_FERRIES  ,
  FERRIES_FILTER_FERRIES,
  FERRIES_ADD_FERRY_TO_LIST,
} from '../actions/types';
import { FerriesActionTypes, FerriesState } from '../modules/Ships/ships.types';
import { TransitsApiResponse } from '../apis/ApiTypes';

const INITIAL_STATE: any = {
  ferriesList: [],
  loading: true,
  ferriesFilterList: [],
  startDate: null,
  endDate: null,
  allTime: false,
  //error
  startDateError: '',
  endDateError: '',
  search: '',
  select: '',
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case FERRIES_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };
    case FERRIES_FILTER_FERRIES:
      if (action.allTime) {
        return {
          ...state,
          startDate: null,
          endDate: null,
          ferriesList: action.payload,
        };
      }
      return {
        ...state,
        ferriesList: action.payload,
      };
    case FERRIES_GET_LIST_FERRIES:
      return {
        ...state,
        ferriesList: action.payload,
        ferriesFilterList: action.payload,
        loading: false,
      };
    case FERRIES_INITIAL_STATE:
      return { ...INITIAL_STATE };
    case FERRIES_ADD_FERRY_TO_LIST:
      let ferriesSortedByStartDate: Array<TransitsApiResponse> = Object.assign(
        [],
        state.ferriesList
      );

      ferriesSortedByStartDate = [...state.ferriesList, ...[action.payload]];

      return {
        ...state,
        ferriesList: [
          ...ferriesSortedByStartDate.sort(
            (a, b) => <any>new Date(a.createdAt) - <any>new Date(b.createdAt)
          ),
        ],
      };
      case FERRIES_DELETE_FERRY_LOADING: {
        return { ...state, loading: true };
      }
      case FERRIES_DELETE_FERRY:
        var newFerriesList = state.ferriesList.filter(function (item: any) {
          return item._id !== action.payload;
        });
        return { ...state, loading: false, ferriesList: newFerriesList };
        case FERRIES_GET_SENT_FERRIES : { 
          return {
            ...state,
            ferriesList: action.payload,
            total: action.total,
            loading: false,
          };
        }
          default:
            return state;
    }
  };
  
