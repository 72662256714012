import {
  OFFER_PROPS_CHANGED,
  OFFER_FORM_PROPS,
  OFFER_INITIAL_STATE,
  EDIT_OFFER_INITIAL_STATE,
} from '../actions/types';
import { OfferState, OfferActionTypes } from '../modules/Offer/offer.types';
import moment from 'moment';

const INITIAL_STATE: OfferState = {
  image: null,
  loading: false,
  startDate: moment(),
  endDate: moment(),
  description: '',
  status: '',
  unlimited: false,
  //errors
  imageError: '',
  statusError: '',
  startDateError: '',
  endDateError: '',
  descriptionError: '',
  messageError: '',
};

export default (
  state = INITIAL_STATE,
  action: OfferActionTypes
): OfferState => {
  switch (action.type) {
    case OFFER_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };

    case OFFER_FORM_PROPS:
      return { ...state, ...action.newState, loading: false };

    case OFFER_INITIAL_STATE:
      return { ...INITIAL_STATE };

    case EDIT_OFFER_INITIAL_STATE:
      return {
        ...INITIAL_STATE,
        ...action.value,
      };
    default:
      return state;
  }
};
