import React from 'react';
import styled from 'styled-components';
import { getInputTextAreaStyles } from './inputTextArea.styles';

export interface TextAreaProps
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {}

const TextArea = styled.textarea<any>`
  ${(props) => getInputTextAreaStyles(props)}
`;

export default TextArea;
