import axios from 'axios';
import { URL } from './config';

export const getBus = (walletId: string, busId: string) => {
  const requestURL = `${URL}/wallets/${walletId}/transits/${busId}`;

  return axios
    .get(requestURL)
    .then(({ data }: { data: any }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const createBus = (walledId: string | null, bus: any) => {
  // bus.locations = [
  //   {
  //     longitude: 0,
  //     latitude: 0,
  //   },
  // ];
  // bus.type = 'bus';
  const requestURL = `${URL}/wallets/${walledId}/transits?=bus`;
  return axios
    .post(requestURL, bus)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export const updateBus = (
  walledId: string | null,
  busId: string | null,
  bus: any
): Promise<any> => {
  const requestURL = `${URL}/wallets/${walledId}/transits/${busId}`;
  return axios
    .put(requestURL, bus)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export const removeBus = (busId: string, walletId: string) => {
  const requestURL = `${URL}/wallets/${walletId}/transits/${busId}`;

  return axios
    .delete(requestURL)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// export const editCustomerBus = (
//   walletId: string | null,
//   busId: string | null,
//   bus: any
// ) => {
//   const requestURL = `${URL}/wallets/${walletId}/transits/${busId}`;
//   return axios
//     .put(requestURL, bus)
//     .then((res) => {
//       return res;
//     })
//     .catch((error) => {
//       return error.response;
//     });
// };

// export const editCustomerBus = (walletId: string | null, bus: any) => {
//   const requestURL = `${URL}/wallets/${walletId}/transits/${bus.busId}/customer`;
//   return axios
//     .put(requestURL, bus)
//     .then((res) => {
//       return res;
//     })
//     .catch((error) => {
//       return error.response;
//     });
// };
export const editCustomerBus = (
  walletId: string | null,
  busId: string | null,
  bus: any
) => {
  const requestURL = `${URL}/wallets/${walletId}/transits/${busId}/customer`;
  return axios
    .put(requestURL, bus)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};