import React from 'react';
import { useIntl } from 'react-intl';
import { Route, Switch, RouteComponentProps, Router } from 'react-router-dom';
import SideBar from '../../components/Drawer';

import Cinemas from '../../modules/Cinemas';
import { useStyles } from './busesRouter.styles';

import NotFound from '../../modules/NotFound';
import CreateCinemaTicket from '../../modules/CreateCinemaTicket';
import sentCinemaTickets from '../../modules/SentCinemaTickets';

//Modules

const CinemasTicketsRouter: React.FC<RouteComponentProps> = (props) => {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <div className={classes.rootAppRouter}>
      <SideBar title={intl.formatMessage({ id: 'drawer.menuList.cinemas' })}>
        <Switch>
          <Route path='/cinemaTickets' component={Cinemas} exact />
          <Route
            path='/cinemaTickets/create'
            component={CreateCinemaTicket}
            exact
          />
          <Route
            path='/cinemaTickets/:cinemaID'
            component={sentCinemaTickets}
          />
          <Route path='/cinemaTickets/*' component={NotFound} />
        </Switch>
      </SideBar>
    </div>
  );
};

export default CinemasTicketsRouter;
