import axios from 'axios';
import { URL, API_KEY, API_KEY_VALUE } from './config';

export const addCustumerToWallet = (walletId: String, props: any) => {
  const requestURL = `${URL}/customers`;
  const key: any = API_KEY;
  const value: string | undefined = API_KEY_VALUE;
  let barcodeValue = null;
  if (props.barcode !== '') {
    barcodeValue = props.barcode;
  }

  let phoneNumberValue = null;
  if (props.phoneNumber) {
    let PhoneNumberInitalValue = props.phoneNumber.trim();
    if (PhoneNumberInitalValue !== '') {
      phoneNumberValue = PhoneNumberInitalValue;
    }
  }
  return axios
    .post(
      requestURL,
      {
        firstName: props.firstName,
        lastName: props.lastName,
        email: props.email,
        barcode: barcodeValue,
        phoneNumber: phoneNumberValue,
        source: 'internal',
        wallet: walletId,
      },
      {
        headers: {
          // [API_KEY]: API_KEY_VALUE,
          [key]: value,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export const sendRemindersToInactiveCustomers = (
  walletId: String,
  props: any
) => {
  const requestURL = `${URL}/customers/${walletId}/reminders`;
  return axios
    .post(requestURL)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};
