import React from 'react';
import { useIntl } from 'react-intl';
import Card from '@material-ui/core/Card';

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import ConfirmationModal from '../../Modal';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import ComButton from '../../../commons/Button';
//Styles
import { useStyles } from './flightCard.styles';

import { FlightsApiResponse } from '../../../apis/ApiTypes';

import moment from 'moment';
import Text from '../../../commons/Text';
import clsx from 'clsx';
import Airplane from '../../../icons/Airplane';
import { Box } from '@material-ui/core';

type FlightCardProps = {
  flight: FlightsApiResponse;
  onClickRedirect: () => void;
  onClickEdit: (id: string) => void;
  onClickSend: (value: string) => void;
  onClickDelete: (flightId: string) => void;
  loading: boolean;
  flightId: string;
};

const FlightCard: React.FC<FlightCardProps> = (props) => {
  const intl = useIntl();
  const classes = useStyles();
  const {
    flight,
    onClickRedirect,
    onClickDelete,
    onClickEdit,
    onClickSend,
    loading,
    flightId,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [is_open, setIsOpen] = React.useState<any>(null);

  const [is_loading, setIsLoading] = React.useState<any>(null);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <Card className={classes.root}>
      <div className={clsx(classes.cardHeader, classes.cardPosition)}>
        <div className={classes.cardItemNum}>
          <Text color='#828282' fontSize={14} lineHeight={21} fontWeight={500}>
            N°:
          </Text>
          <Text color='#404040' fontSize={14} lineHeight={21} fontWeight={500}>
            {flight.number}
          </Text>
        </div>
        <IconButton
          aria-label='more'
          aria-controls='long-menu'
          aria-haspopup='true'
          onClick={handleClick}
          style={{
            background: '#FF5078',
            padding: '2px',
          }}
        >
          <MoreHorizIcon fontSize='small' className={classes.menuIcon} />
        </IconButton>
        <Menu
          id='simple-menu'
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: -50 }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          keepMounted
          open={Boolean(anchorEl)}
          onClick={handleClose}
          className={classes.cardPosition}
        >
          <MenuItem
            onClick={() => {
              onClickEdit(flight._id);
            }}
            onMouseEnter={(e) =>
              ((e.target as HTMLInputElement).style.color = '#FF5078')
            }
            onMouseLeave={(e) =>
              ((e.target as HTMLInputElement).style.color = '#000000')
            }
          >
            {intl.formatMessage({ id: 'event.label.update' })}
          </MenuItem>
          <MenuItem
            onClick={() => {
              setIsOpen(true);
            }}
            onMouseEnter={(e) =>
              ((e.target as HTMLInputElement).style.color = '#FF5078')
            }
            onMouseLeave={(e) =>
              ((e.target as HTMLInputElement).style.color = '#000000')
            }
          >
            {intl.formatMessage({ id: 'settings.button.delete' })}
          </MenuItem>
        </Menu>

        <ConfirmationModal open={is_open}>
          <div className='modal-cntr'>
            <span style={{ marginTop: '15 px' }}>
              Voulez vous Supprimer le transit ?
            </span>
            {is_open ? (
              <div
                style={{
                  display: 'flex',
                  marginTop: '10px',
                  justifyContent: 'space-evenly',
                }}
              >
                <ComButton
                  styled='secondary'
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  {intl.formatMessage({ id: 'settings.button.cancel' })}
                </ComButton>

                <ComButton
                  styled='primary'
                  onClick={() => {
                    onClickDelete(flight._id);
                  }}
                >
                  {is_loading ? (
                    <CircularProgress size={20} color='inherit' />
                  ) : (
                    ''
                  )}
                  {intl.formatMessage({ id: 'resetPassword.button.confirm' })}{' '}
                </ComButton>
              </div>
            ) : (
              ''
            )}
          </div>
        </ConfirmationModal>
      </div>
      <div className={classes.cardFirstSection}>
        <div className={classes.cardLogo}>
          <Airplane />
        </div>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          style={{ gap: 12 }}
        >
          <div className={classes.cardInfos}>
            <div className={classes.cardItemsInfo}>
              <Text
                color='#828282'
                fontSize={14}
                lineHeight={21}
                fontWeight={500}
              >
                {intl.formatMessage({ id: 'ships.label.origin' })}
              </Text>
              <Text
                color='#404040'
                fontSize={14}
                lineHeight={21}
                fontWeight={500}
              >
                {flight.origin && flight.origin.airportName}
              </Text>
            </div>
            <div className={classes.cardItemsInfo}>
              <Text
                color='#828282'
                fontSize={14}
                lineHeight={21}
                fontWeight={500}
              >
                {intl.formatMessage({ id: 'transitCard.label.destination' })}
              </Text>
              <Text
                color='#404040'
                fontSize={14}
                lineHeight={21}
                fontWeight={500}
              >
                {flight.destination && flight.destination.airportName}
              </Text>
            </div>
          </div>
          <div className={classes.cardInfos}>
            <div className={classes.cardItem}>
              <Text
                color='#828282'
                fontSize={14}
                lineHeight={21}
                fontWeight={500}
              >
                {intl.formatMessage({ id: 'flight.label.departureDateTime' })}
              </Text>
              <Text
                color='#404040'
                fontSize={14}
                lineHeight={21}
                fontWeight={500}
              >
                {moment(flight.departureDateTime).format('DD/MM/YYYY HH:mm')}
              </Text>
            </div>
          </div>
        </Box>
      </div>
      <div className={classes.cardSecendSection}>
        <div className={classes.cardItemID}>
          <Text
            color='#828282'
            fontSize={10}
            lineHeight={21}
            fontWeight={500}
            style={{ marginRight: '5px' }}
          >
            ID:
          </Text>
          <Text color='#404040' fontSize={10} lineHeight={21} fontWeight={500}>
            {flight._id}
          </Text>
        </div>
      </div>
    </Card>
  );
};

export default FlightCard;
