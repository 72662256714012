import 'date-fns';
import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
//Style
import styled from 'styled-components';
import { shipStyles } from './flight.styles';
//Material
import {
  Divider,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Typography,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  TimePicker,
} from '@material-ui/pickers';
//Components
import InputLabel from '../../commons/Inputs/InputLabel';
//Commomns
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import Button from '../../commons/Button';
import { getImageErrorMessage } from '../../utils/common';

//Actions
import {
  flightPropsChanged,
  editFlightConfirm,
  flightInitialState,
} from '../../actions';
//Reducers
import { RootState } from '../../reducers';
//Type

import { ic_add_circle_outline } from 'react-icons-kit/md/ic_add_circle_outline';
import { ic_delete } from 'react-icons-kit/md/ic_delete';
// import { AddCustomerFormProps } from './addCustomer.types';

import cover from '../../assets/images/lg-thumbnail.png';
import { FlightFormProps, FlightStateError } from './flight.types';
import InputFile from '../../commons/Inputs/InputFile';
import { CircularProgress } from '@material-ui/core';
import Text from '../../commons/Text';
import moment from 'moment';
import ColorPicker from '../../commons/ColorPicker';
import FlightWireframeAndroid from '../../components/FlightWireframeAndroid';
import FlightWireframeIPhone from '../../components/FlightWireframeIPhone';
import {
  isNumber,
  getNatural,
  getDecimal,
  isPhoneNumber,
} from '../../utils/validations';
import { getDataFromCache } from '../../utils/cache';
import { useHistory } from 'react-router-dom';
import Calendar from '../../icons/Calendar';
import Icon from 'react-icons-kit';
import { ic_warning } from 'react-icons-kit/md/ic_warning';
import maxChars from '../../utils/fieldsMaxLength';
import BarcodeTypeSelection from '../../components/barcodeSelection';
import SecurityOptions from '../../components/SecurityOptions';

const EditFlight: React.FC<FlightFormProps> = (props) => {
  const intl = useIntl();
  const [hasStateChanged, setHasStateChange] = React.useState<boolean>(false);
  const [switchPreview, setSwitchPreview] = React.useState<string>('android');
  const [QRcode, setQRcode] = React.useState<any>({ state: true, value: '' });
  const history = useHistory();
  const {
    walletId,
    flightId,
    onSaveChanged,
    strip,
    labelColor,
    backgroundColor,
    foregroundColor,
    number,
    destinationIATACode,
    destinationTerminal,
    originIATACode,
    originTerminal,
    carrierIataCode,
    image,
    loading,
    details,
    customerServiceNumber,
    departureDateTime,
    originAirportName,
    destinationAirportName,
    layovers,
    locations,
    destinationGate,
    originGate,
    enableOneTimeUse,
    disableIOSPassSharing,
    disableMultipleHolders,
    enableSecurityAnimation,
    enableRotatingBarcode,
    disableEmailSharing,

    stripError,
    labelColorError,
    backgroundColorError,
    foregroundColorError,
    numberError,
    originIATACodeError,
    originGateError,
    originTerminalError,
    originAirportNameError,
    destinationIATACodeError,
    destinationGateError,
    destinationTerminalError,
    destinationAirportNameError,
    imageError,
    loadingError,
    detailsError,
    customerServiceNumberError,
    departureDateTimeError,
    carrierIataCodeError,
    layoversError,
    locationsError,
    barcodeType,
  } = props;

  const objectUser = getDataFromCache('user');

  const handleColorChange = (color: any, prop: string) => {
    props.flightPropsChanged(prop, color);
  };

  const getFormatedMessage = (prop: FlightStateError) => {
    return props[prop] !== ''
      ? intl.formatMessage({ id: props[prop] })
      : props[prop];
  };

  const handleFileChange = (event: any, prop: string) => {
    const file = event.target.files[0];
    if (!['image/jpeg', 'image/png'].includes(file.type)) {
      props.flightPropsChanged(`${prop}Error`, getImageErrorMessage(file));
      return;
    }
    props.flightPropsChanged(prop, file);
  };

  const handlePreviewChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSwitchPreview((event.target as HTMLInputElement).value);
  };

  const isPriceValid = (value: string): boolean => {
    if (!isNumber(value)) return false;
    if (getNatural(value).toString().length > 5) {
      props.flightPropsChanged(
        'tarifError',
        'Vous avez dépassé le nombre maximum avant le point/virgule'
      );
      return false;
    }

    if (getDecimal(value).toString().length > 2) {
      props.flightPropsChanged(
        'tarifError',
        'Vous avez dépassé le nombre maximum aprés le point/virgule'
      );
      return false;
    }
    return true;
  };
  const areTextAreasValid = (fieldName: string, value: string): boolean => {
    if (fieldName === 'customerServiceNumber') {
      if (!isPhoneNumber(value)) {
        props.flightPropsChanged(fieldName, value);
        props.flightPropsChanged(`${fieldName}Error`, 'Phone is invalid');
        return false;
      }
    }
    if (value.length > (maxChars.flight[fieldName] || maxChars[fieldName])) {
      props.flightPropsChanged(
        `${fieldName}Error`,
        'La longueur maximale a été atteinte'
      );
      return false;
    }
    return true;
  };

  const handleClickEditFlight = () => {
    props.editFlightConfirm(walletId, flightId, props, () => onSaveChanged());
  };

  const handleFlightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHasStateChange(true);

    const value = event.target.value;
    const fieldName = event.target.name;
    props.flightPropsChanged(`${fieldName}Error`, '');

    if (fieldName === 'tarif') {
      if (!isPriceValid(value)) return;
    }

    if (!areTextAreasValid(fieldName, value)) return;
    props.flightPropsChanged(fieldName, value);
  };

  const handleAddLayoverChange = () => {
    if (layovers.length == 10) return;
    let layoversClone = [...layovers];
    layoversClone.push('');
    props.flightPropsChanged('layovers', layoversClone);
  };

  const handleDeleteLayoverChange = (index: number) => {
    let layoversClone = [...layovers];
    layoversClone.splice(index, 1);
    props.flightPropsChanged('layovers', layoversClone);
  };
  const handleDescriptionValueChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    let layoversClone = [...layovers];
    layoversClone[index] = e.target.value;
    props.flightPropsChanged('layovers', layoversClone);
  };
  const handleDateChange = (prop: string) => (date: Date | null) => {
    props.flightPropsChanged(prop, date);
  };
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Text color='#404040' fontSize={16} fontWeight={600} lineHeight={24}>
            <span className='title'>
              {' '}
              {intl.formatMessage({ id: 'flight.label.addFlight' })}
            </span>
          </Text>
        </div>
        <div>
          <div className='action'>
            {(imageError ||
              numberError ||
              originIATACodeError ||
              originGateError ||
              originTerminalError ||
              originAirportNameError ||
              destinationIATACodeError ||
              destinationGateError ||
              destinationTerminalError ||
              destinationAirportNameError ||
              departureDateTimeError ||
              carrierIataCodeError ||
              detailsError ||
              customerServiceNumberError ||
              stripError) && (
              <div className='error-icon'>
                <Icon
                  icon={ic_warning}
                  size={24}
                  style={{ color: '#FF5078' }}
                />
              </div>
            )}
          </div>
          <Button width={135} onClick={handleClickEditFlight}>
            {loading ? (
              <CircularProgress size={20} color='inherit' />
            ) : (
              intl.formatMessage({ id: 'ship.button.add' })
            )}
          </Button>
        </div>
      </div>
      <Wrapper>
        <div className='section'>
          <div className='section-one '>
            <InputFile
              className='input-label'
              width={270}
              label={intl.formatMessage({ id: 'ship.label.logo' })}
              image={image}
              defaultImage={cover}
              error={getFormatedMessage('imageError')}
              onChange={(e) => {
                handleFileChange(e, 'image');
              }}
            ></InputFile>
            <div className='div-content' style={{ marginLeft: '30px' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '26px',
                }}
              >
                <ColorPicker
                  label={intl.formatMessage({ id: 'coupon.label.textColor' })}
                  color={labelColor}
                  error={getFormatedMessage('labelColorError')}
                  onChange={(color: any) => {
                    handleColorChange(color, 'labelColor');
                  }}
                />
                <ColorPicker
                  label={intl.formatMessage({
                    id: 'coupon.label.foregroundColor',
                  })}
                  color={foregroundColor}
                  error={getFormatedMessage('foregroundColorError')}
                  onChange={(color: any) => {
                    handleColorChange(color, 'foregroundColor');
                  }}
                />
                <ColorPicker
                  label={intl.formatMessage({
                    id: 'coupon.label.backgroundColor',
                  })}
                  color={backgroundColor}
                  error={getFormatedMessage('backgroundColorError')}
                  onChange={(color: any) => {
                    handleColorChange(color, 'backgroundColor');
                  }}
                />
              </div>
            </div>
            <div className='section-row'>
              <BarcodeTypeSelection
                setSelectedBarcode={(barcodeType) => {
                  props.flightPropsChanged('barcodeType', barcodeType);
                }}
                barcodeType={barcodeType}
              ></BarcodeTypeSelection>
              <SecurityOptions
                handleSecurityPropsChange={(name: string, value: boolean) => {
                  props.flightPropsChanged(name, value);
                }}
                {...{
                  enableOneTimeUse,
                  disableIOSPassSharing,
                  disableMultipleHolders,
                  enableSecurityAnimation,
                  enableRotatingBarcode,
                  disableEmailSharing,
                }}
              ></SecurityOptions>
            </div>
            <div>
              <InputLabel
                label={intl.formatMessage({ id: 'flight.label.number' })}
                placeholder={intl.formatMessage({ id: 'ship.label.number' })}
                name='number'
                value={number}
                error={getFormatedMessage('numberError')}
                limit={maxChars.flight.number || maxChars.transit.number}
                onChange={(e) => handleFlightChange(e)}
              />
              <InputLabel
                label={intl.formatMessage({
                  id: 'flight.label.carrierIataCode',
                })}
                placeholder='LX'
                name='carrierIataCode'
                value={carrierIataCode}
                error={getFormatedMessage('carrierIataCodeError')}
                limit={
                  maxChars.flight.carrierIataCode ||
                  maxChars.transit.carrierIataCode
                }
                onChange={(e) => handleFlightChange(e)}
              />{' '}
              <h4>Origine</h4>
              <InputLabel
                label={intl.formatMessage({
                  id: 'flight.label.airportName',
                })}
                placeholder='Ho Chi Minh City'
                name='originAirportName'
                value={originAirportName}
                error={getFormatedMessage('originAirportNameError')}
                limit={
                  maxChars.flight.originAirportName ||
                  maxChars.transit.originAirportName
                }
                onChange={(e) => handleFlightChange(e)}
              />
              <InputLabel
                label={intl.formatMessage({
                  id: 'flight.label.airportIataCode',
                })}
                placeholder='LAX'
                name='originIATACode'
                value={originIATACode}
                error={getFormatedMessage('originIATACodeError')}
                limit={
                  maxChars.flight.originIATACode ||
                  maxChars.transit.originIATACode
                }
                onChange={(e) => handleFlightChange(e)}
              />
              <InputLabel
                label={intl.formatMessage({
                  id: 'flight.label.terminal',
                })}
                placeholder='Terminal'
                name='originTerminal'
                value={originTerminal}
                error={getFormatedMessage('originTerminalError')}
                limit={
                  maxChars.flight.originTerminal ||
                  maxChars.transit.originTerminal
                }
                onChange={(e) => handleFlightChange(e)}
              />
              <InputLabel
                label={intl.formatMessage({
                  id: 'flight.label.gate',
                })}
                placeholder='Tokyo Haneda'
                name='originGate'
                value={originGate}
                error={getFormatedMessage('originGateError')}
                limit={
                  maxChars.flight.originGate || maxChars.transit.originGate
                }
                onChange={(e) => handleFlightChange(e)}
              />
              <h4>Destination</h4>
              <InputLabel
                label={intl.formatMessage({
                  id: 'flight.label.airportName',
                })}
                placeholder='Tokyo Haneda'
                name='destinationAirportName'
                value={destinationAirportName}
                error={getFormatedMessage('destinationAirportNameError')}
                limit={
                  maxChars.flight.destinationAirportName ||
                  maxChars.transit.destinationAirportName
                }
                onChange={(e) => handleFlightChange(e)}
              />
              <InputLabel
                label={intl.formatMessage({
                  id: 'flight.label.airportIataCode',
                })}
                placeholder='SFO'
                name='destinationIATACode'
                value={destinationIATACode}
                error={getFormatedMessage('destinationIATACodeError')}
                limit={
                  maxChars.flight.destinationIATACode ||
                  maxChars.transit.destinationIATACode
                }
                onChange={(e) => handleFlightChange(e)}
              />
              <InputLabel
                label={intl.formatMessage({
                  id: 'flight.label.terminal',
                })}
                placeholder='Terminal'
                name='destinationTerminal'
                value={destinationTerminal}
                error={getFormatedMessage('destinationTerminalError')}
                limit={
                  maxChars.flight.destinationTerminal ||
                  maxChars.transit.destinationTerminal
                }
                onChange={(e) => handleFlightChange(e)}
              />{' '}
              <InputLabel
                label={intl.formatMessage({
                  id: 'flight.label.gate',
                })}
                placeholder='Tokyo Haneda'
                name='destinationGate'
                value={destinationGate}
                error={getFormatedMessage('destinationGateError')}
                limit={
                  maxChars.flight.destinationGate || maxChars.destinationGate
                }
                onChange={(e) => handleFlightChange(e)}
              />
              <KeyboardDateTimePicker
                required
                fullWidth
                margin='normal'
                label={intl.formatMessage({
                  id: 'flight.label.departureDateTime',
                })}
                format='E MMM dd yyyy HH:MM'
                ampm={false}
                inputVariant='outlined'
                value={departureDateTime || null}
                onChange={handleDateChange('departureDateTime')}
                disablePast
                InputProps={{ readOnly: true }}
              />
              <InputLabel
                label={intl.formatMessage({
                  id: 'ship.label.customerServiceNumber',
                })}
                placeholder='numéro Télephone'
                name='customerServiceNumber'
                value={customerServiceNumber}
                error={getFormatedMessage('customerServiceNumberError')}
                limit={
                  maxChars.flight.customerServiceNumber || maxChars.phoneNumber
                }
                onChange={(e) => handleFlightChange(e)}
              />
              <InputLabel
                label={intl.formatMessage({ id: 'ship.label.details' })}
                placeholder='description...'
                name='details'
                value={details}
                error={getFormatedMessage('detailsError')}
                limit={maxChars.flight.details}
                onChange={(e) => handleFlightChange(e)}
              />
              <div className='section-description'>
                {layovers.map((el: any, index: number) => (
                  <div key={index} className='description'>
                    <div className='description-title'>
                      <h3>
                        {' '}
                        {intl.formatMessage({ id: 'flight.label.layover' })}
                        {index + 1}*
                      </h3>

                      <span onClick={() => handleDeleteLayoverChange(index)}>
                        <Icon
                          icon={ic_delete}
                          size={18}
                          style={{ color: '#FF5078' }}
                        />{' '}
                        {intl.formatMessage({ id: 'settings.button.delete' })}
                      </span>
                    </div>

                    <InputLabel
                      label=''
                      name='layover'
                      rows={20}
                      value={el}
                      placeholder={intl.formatMessage({
                        id: 'flight.label.layover',
                      })}
                      onChange={(e) => handleDescriptionValueChange(e, index)}
                    />
                  </div>
                ))}

                {layovers.length < 10 && (
                  <div className='description-action'>
                    <Button
                      color='#FF5078'
                      border='1px solid #FF5078'
                      background='#fff'
                      onClick={() => handleAddLayoverChange()}
                    >
                      <Icon
                        style={{ color: '#FF5078', marginRight: '10px' }}
                        icon={ic_add_circle_outline}
                        size={26}
                      />
                      {intl.formatMessage({ id: 'flight.label.addLayover' })}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='section-two '>
            <div
              className=' wireframe-section'
              style={{ marginLeft: 25, marginRight: 25, maxWidth: 470 }}
            >
              <RadioGroup
                name='previews'
                value={switchPreview}
                onChange={handlePreviewChange}
                className='radioGroup'
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <FormControlLabel
                  value='android'
                  control={<Radio />}
                  label='Android'
                />
                <FormControlLabel value='iOS' control={<Radio />} label='iOS' />
              </RadioGroup>

              {switchPreview === 'android' && (
                <FlightWireframeAndroid
                  backgroundColor={backgroundColor}
                  strip={image}
                  number={number}
                  destinationTerminal={destinationTerminal}
                  originTerminal={originTerminal}
                  originGate={originGate}
                  destinationGate={destinationGate}
                  carrierIataCode={carrierIataCode}
                  locations={locations}
                  layovers={layovers}
                  originIATACode={originIATACode}
                  destinationIATACode={destinationIATACode}
                  originAirportName={originAirportName}
                  destinationAirportName={destinationAirportName}
                  departureDateTime={departureDateTime}
                  details={details}
                  QrCode={QRcode}
                  barcodeType={barcodeType}
                  animatedBorder={enableSecurityAnimation}
                />
              )}
              {switchPreview === 'iOS' && (
                <FlightWireframeIPhone
                  backgroundColor={backgroundColor}
                  destinationTerminal={destinationTerminal}
                  originTerminal={originTerminal}
                  originGate={originGate}
                  destinationGate={destinationGate}
                  locations={locations}
                  layovers={layovers}
                  originIATACode={originIATACode}
                  destinationIATACode={destinationIATACode}
                  originAirportName={originAirportName}
                  destinationAirportName={destinationAirportName}
                  departureDateTime={departureDateTime}
                  details={details}
                  QrCode={QRcode}
                  strip={image}
                  number={number}
                  customerServiceNumber={customerServiceNumber}
                  titleColor={labelColor.hex}
                  textColor={foregroundColor.hex}
                  barcodeType={barcodeType}
                />
              )}
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

const Wrapper = styled('div')`
  ${(props) => shipStyles(props)}
`;

const mapStateToProps = ({ flight }: RootState) => {
  const {
    flightId,
    strip,
    labelColor,
    backgroundColor,
    foregroundColor,
    number,
    destinationIATACode,
    destinationTerminal,
    originIATACode,
    originTerminal,
    departureDateTime,
    originAirportName,
    destinationGate,
    originGate,
    carrierIataCode,
    customerServiceNumber,
    image,
    enableOneTimeUse,
    disableIOSPassSharing,
    disableMultipleHolders,
    enableSecurityAnimation,
    enableRotatingBarcode,
    disableEmailSharing,
    loading,
    details,
    destinationAirportName,
    layovers,
    locations,
    stripError,
    labelColorError,
    backgroundColorError,
    foregroundColorError,
    numberError,
    originIATACodeError,
    originGateError,
    originTerminalError,
    originAirportNameError,
    destinationIATACodeError,
    destinationGateError,
    destinationTerminalError,
    destinationAirportNameError,
    imageError,
    loadingError,
    detailsError,
    customerServiceNumberError,
    departureDateTimeError,
    carrierIataCodeError,
    layoversError,
    locationsError,
    barcodeType,
  } = flight;

  return {
    strip,
    labelColor,
    backgroundColor,
    foregroundColor,
    number,
    destinationIATACode,
    destinationTerminal,
    originIATACode,
    originTerminal,
    carrierIataCode,

    image,
    loading,
    details,
    customerServiceNumber,
    departureDateTime,
    originAirportName,
    destinationAirportName,
    layovers,
    locations,
    destinationGate,
    originGate,
    enableOneTimeUse,
    disableIOSPassSharing,
    disableMultipleHolders,
    enableSecurityAnimation,
    enableRotatingBarcode,
    disableEmailSharing,
    //errors
    stripError,
    labelColorError,
    backgroundColorError,
    foregroundColorError,
    numberError,
    originIATACodeError,
    originGateError,
    originTerminalError,
    originAirportNameError,
    destinationIATACodeError,
    destinationGateError,
    destinationTerminalError,
    destinationAirportNameError,
    imageError,
    loadingError,
    detailsError,
    customerServiceNumberError,
    departureDateTimeError,
    carrierIataCodeError,
    layoversError,
    locationsError,
    barcodeType,
  };
};

export const connector = connect(mapStateToProps, {
  flightPropsChanged,
  editFlightConfirm,
  flightInitialState,
});

export default connector(EditFlight);
