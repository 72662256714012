import 'date-fns';
import React from 'react';

import { useIntl } from 'react-intl';
import styled from 'styled-components';
//Material
import FormControl from '@material-ui/core/FormControl';
import emptyState from '../../assets/images/emptyState.svg';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import { DateTimePicker } from '@material-ui/pickers';
import Text from '../../commons/Text';
import Button from '../../commons/Button';
import Input from '../../commons/Inputs/Input';
import { Divider } from '@material-ui/core';
import Calendar from '../../icons/Calendar';
import { StyledCheckbox } from '../../commons/CheckBox/index';
import ShipModal from '../../components/Modal';
import { ShipsStyles, ShipsStylesProps } from './flights.styles';
import EmptyData from '../../components/EmptyData';
import FlightCard from '../../components/Cards/FlightCard';
import { FlightsApiResponse } from '../../apis/ApiTypes';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import { getDataFromCache } from '../../utils/cache';
import {
  flightsGetAllFlights,
  flightsInitialState,
  flightsPropsChanged,
  flightsFilterFlights,
  editFlightConfirm,
  deleteFlight,
  getFlightbyId,
  editFlightInitialState,
} from '../../actions';
import CreateFlight from '../Flight';
import SimpleDialog from '../../components/Dialog';
import { useHistory } from 'react-router-dom';
import EditFlight from '../EditFlight';
import { IconButton, TextField, InputAdornment } from '@material-ui/core';

const Flights: React.FC<any> = (props) => {
  const intl = useIntl();
  const [open, setOpen] = React.useState<boolean>(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectOpener, setSelectOpener] = React.useState<boolean>(false);
  const [flightId, setFlightId] = React.useState('');
  const [flightIdConfirmed, setFlightIdConfirmed] = React.useState('');
  const [EditDialogOpen, setEditDialogOpen] = React.useState(false);

  const {
    flightsList,
    flightsFilterList,
    status,
    startDate,
    endDate,
    allTime,
    loading,
    loadingSend,
  } = props;
  const objectUser: any = getDataFromCache('user');
  const history = useHistory();
  React.useEffect(() => {
    props.flightsGetAllFlights(objectUser.wallet);
  }, []);

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    props.flightsPropsChanged('status', event.target.value);
  };
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.flightsPropsChanged(event.target.name, event.target.checked);
  };
  const handleDateChange = (prop: string) => (date: Date | null) => {
    props.flightsPropsChanged(prop, date);
  };

  const HandleClickSearch = () => {
    props.flightsFilterFlights(
      status,
      allTime,
      startDate,
      endDate,
      flightsFilterList
    );
  };
  // const getFormatedMessage = (prop: FlightsStateError) => {};
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setFlightIdConfirmed('');
  };

  const handleClickSend = () => {
    props.flightsSendFlight(objectUser.wallet, flightId);
    setFlightIdConfirmed(flightId);
    setOpenDialog(false);
  };

  const handleClickDeleteflight = (flightId: string) => {
    props.deleteFlight(flightId, objectUser.wallet);
  };

  const RedirectToCustomers = (url: string) => {
    history.push(url);
  };

  const handleNameStatus = (value: string) => {
    switch (value) {
      case 'originName':
        return intl.formatMessage({ id: 'ships.select.originName' });
      case 'destinationName':
        return intl.formatMessage({ id: 'ships.select.destinationName' });
      // case 'departureTime':
      //   return intl.formatMessage({ id: 'ships.select.departureTime' });
      // case 'arrivalTime':
      //   return intl.formatMessage({ id: 'ships.select.arrivalTime' });
      default:
        return 'Tous';
    }
  };

  return (
    <Wrapper>
      <div className='div-search'>
        <FormControl className='filter-container' fullWidth>
          <Input value={handleNameStatus(status)} disabled margin='0' />
          <div
            className='filter-button'
            onClick={() => setSelectOpener(!selectOpener)}
          >
            <h3>
              {intl.formatMessage({
                id: 'wallet.label.filter',
              })}
            </h3>
            <ExpandMoreRoundedIcon
              style={{ color: 'white' }}
              className={selectOpener ? 'filter-opened' : ''}
            />
          </div>
          <Select
            className='select-input '
            value={status}
            onChange={handleSelectChange}
            placeholder={intl.formatMessage({
              id: 'wallet.label.filter',
            })}
            id='filter'
            inputProps={{ className: 'input' }}
            open={selectOpener}
            onClose={() => setSelectOpener(false)}
          >
            <MenuItem
              value='originName'
              onMouseEnter={(e) =>
                ((e.target as HTMLInputElement).style.color = '#ff5078')
              }
              onMouseLeave={(e) =>
                ((e.target as HTMLInputElement).style.color = '#000000')
              }
            >
              <span>
                {intl.formatMessage({ id: 'flights.select.originName' })}
              </span>
            </MenuItem>
            <MenuItem
              value='destinationName'
              onMouseEnter={(e) =>
                ((e.target as HTMLInputElement).style.color = '#ff5078')
              }
              onMouseLeave={(e) =>
                ((e.target as HTMLInputElement).style.color = '#000000')
              }
            >
              <span>
                {intl.formatMessage({
                  id: 'flights.select.destinationName',
                })}
              </span>
            </MenuItem>
            <MenuItem
              value=''
              onMouseEnter={(e) =>
                ((e.target as HTMLInputElement).style.color = '#ff5078')
              }
              onMouseLeave={(e) =>
                ((e.target as HTMLInputElement).style.color = '#000000')
              }
            >
              <span>Tous</span>
            </MenuItem>
          </Select>
        </FormControl>
        {/* <DateTimePicker
          id='start-date'
          label={intl.formatMessage({ id: 'offers.label.startDate' })}
          format='E MMM dd yyyy HH:mm'
          value={startDate}
          onChange={handleDateChange('startDate')}
          inputVariant='outlined'
          TextFieldComponent={({ InputProps, ...rest }) => (
            <TextField
              {...rest}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <Divider
                      className='divider'
                      orientation='vertical'
                      variant='inset'
                      light
                    />
                    <IconButton
                      onClick={
                        (InputProps as any)?.endAdornment?.props?.children
                          ?.props?.onClick
                      }
                    >
                      <Calendar />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <DateTimePicker
          id='end-date'
          label={intl.formatMessage({ id: 'offers.label.endDate' })}
          format='E MMM dd yyyy HH:mm'
          inputVariant='outlined'
          value={endDate}
          onChange={handleDateChange('endDate')}
          disabled={startDate === null ? true : false}
          TextFieldComponent={({ InputProps, ...rest }) => (
            <TextField
              {...rest}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <Divider
                      className='divider'
                      orientation='vertical'
                      variant='inset'
                      light
                    />
                    <IconButton
                      onClick={
                        (InputProps as any)?.endAdornment?.props?.children
                          ?.props?.onClick
                      }
                    >
                      <Calendar />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        /> */}

        {/* <FormControlLabel
          control={
            <StyledCheckbox
              checked={allTime}
              onChange={handleCheckboxChange}
              name='allTime'
            />
          }
          label={intl.formatMessage({ id: 'offers.label.allTime' })}
        /> */}

        <Button background='#FF5078' onClick={HandleClickSearch}>
          {intl.formatMessage({ id: 'flights.button.search' })}
        </Button>
      </div>
      <div className='div-header'>
        <Text color='#404040' fontSize={24} lineHeight={36} fontWeight={600}>
          {intl.formatMessage({ id: 'flights.label.title' })}
        </Text>
        <Button
          onClick={() => {
            setOpen(!open);
          }}
        >
          {intl.formatMessage({ id: 'flights.button.create' })}
        </Button>
      </div>
      <ShipModal open={open} onClose={() => setOpen(!open)}>
        <CreateFlight
          walletId={objectUser.wallet}
          onSaveChanged={() => setOpen(!open)}
        ></CreateFlight>
      </ShipModal>
      <ShipModal open={EditDialogOpen} onClose={() => setEditDialogOpen(false)}>
        {
          <EditFlight
            walletId={objectUser.wallet}
            flightId={flightId}
            onCancel={() => setEditDialogOpen(false)}
            onSaveChanged={() => setEditDialogOpen(!EditDialogOpen)}
          ></EditFlight>
        }
      </ShipModal>
      <div className='div-list'>
        <SimpleDialog
          title={intl.formatMessage({ id: 'coupons.dialog.title' })}
          text={intl.formatMessage({ id: 'coupons.dialog.text' })}
          handleAgree={handleClickSend}
          open={openDialog}
          handleClose={handleCloseDialog}
        ></SimpleDialog>
        <EmptyData
          data={flightsList}
          loading={loading}
          message={intl.formatMessage({ id: 'flights.label.emptyList' })}
          image={emptyState}
        >
          {flightsList.map((flight: FlightsApiResponse) => (
            <FlightCard
              loading={loadingSend}
              key={flight._id}
              onClickDelete={handleClickDeleteflight}
              flight={flight}
              flightId={flightIdConfirmed}
              onClickRedirect={() => history.push('/flights/' + flight._id)}
              onClickSend={() => {
                setOpenDialog(true);
                setFlightId(flight._id);
              }}
              onClickEdit={(id) => {
                props.editFlightInitialState(flight);
                setEditDialogOpen(true);
                setFlightId(flight._id);
              }}
            ></FlightCard>
          ))}
        </EmptyData>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled('div')<ShipsStylesProps>`
  ${(props) => ShipsStyles(props)}
`;
const mapStateToProps = ({ flights }: RootState) => {
  const {
    flightsList,
    flightsFilterList,
    status,
    startDate,
    endDate,
    allTime,
    loading,
  } = flights;

  return {
    flightsList,
    flightsFilterList,
    status,
    startDate,
    endDate,
    allTime,
    loading,
  };
};
export const connector = connect(mapStateToProps, {
  flightsGetAllFlights,
  flightsInitialState,
  flightsPropsChanged,
  flightsFilterFlights,
  editFlightConfirm,
  editFlightInitialState,
  getFlightbyId,
  deleteFlight,
});
export default connector(Flights);
