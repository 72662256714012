import React from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

//Material
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Pagination from '@material-ui/lab/Pagination';

import { offersStyles, OffersStylesProps } from './offers.styles';
import Button from '../../commons/Button';
import OfferModal from '../../components/Modal';
import CreateOffer from '../Offer';
import EditOffer from '../EditOffre';
import OfferCard from '../../components/Cards/OfferCard';
import { StyledCheckbox } from '../../commons/CheckBox/index';
import {
  offersGetAllOffers,
  offersPropsChanged,
  offersFilterOffers,
  offersInitialState,
  editOfferInitialState,
  offerUpdateOffer,
} from '../../actions';
import { RootState } from '../../reducers';
import { OffersProps, OffersStateError } from './offers.types';
//Images
import emptyState from '../../assets/images/emptyState.svg';
import EmptyData from '../../components/EmptyData';
import SimpleDialog from '../../components/Dialog';
import { OfferApiResponse } from '../../apis/ApiTypes';
import { getDataFromCache } from '../../utils/cache';
import Text from '../../commons/Text';
import Input from '../../commons/Inputs/Input';
import { OfferState } from '../EditOffre/offer.types';
import { Divider } from '@material-ui/core';
import Calendar from '../../icons/Calendar';
import { IconButton, TextField, InputAdornment } from '@material-ui/core';

const Offers: React.FC<any> = (props) => {
  const intl = useIntl();
  const {
    offersList,
    offersFilterList,
    status,
    allTime,
    startDate,
    endDate,
    endDateError,
    updateOffer,
    loading,
  } = props;
  const objectUser: any = getDataFromCache('user');
  const rowsperpage = 8;

  const [page, setPage] = React.useState(0);

  const [open, setOpen] = React.useState<boolean>(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [EditDialogOpen, setEditDialogOpen] = React.useState(false);
  const [offerId, setOfferId] = React.useState('');
  const [selectOpener, setSelectOpener] = React.useState<boolean>(false);
  const [action, setAction] = React.useState<string>('action');

  React.useEffect(() => {
    props.offersGetAllOffers(objectUser.wallet);
    /* eslint-disable-next-line */
  }, []);

  React.useEffect(() => {
    return () => {
      props.offersInitialState();
    };
  }, []);

  const handleClickUpdateOffer = () => {
    props.offerUpdateOffer(offerId, objectUser.wallet, action);
    setOpenDialog(false);
  };

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    props.offersPropsChanged('status', event.target.value);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.offersPropsChanged(event.target.name, event.target.checked);
  };

  const handleDateChange = (prop: string) => (date: Date | null) => {
    props.offersPropsChanged(prop, date);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    // props.history.push(`/wallets/${objectUser.wallet}`);
  };

  const HandleClickSearch = () => {
    props.offersFilterOffers(
      status,
      allTime,
      startDate,
      endDate,
      offersFilterList
    );
  };

  const getFormatedMessage = (prop: OffersStateError) => {
    return props[prop] !== ''
      ? intl.formatMessage({ id: props[prop] })
      : props[prop];
  };

  const setCouponActif = (id: string, status: string) => {
    // if (status === 'ACTIF') {
    //   props.offerUpdateOffer(objectUser.wallet, id, 'INACTIF');
    // } else {
    //   props.offerUpdateOffer(objectUser.wallet, id, 'ACTIF');
    // }
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value - 1);
  };

  const calculatePagesCount = (pageSize: number, totalCount: number) => {
    // we suppose that if we have 0 items we want 1 empty page
    return totalCount < pageSize ? 1 : Math.ceil(totalCount / pageSize);
  };

  return (
    <Wrapper loading={loading.toString()} offersLength={offersList.length}>
      <div className='div-search'>
        <FormControl className='filter-container' fullWidth>
          <Input
            value={
              status === 'ALL'
                ? intl.formatMessage({ id: 'offers.select.all' })
                : status === 'OPENED'
                ? intl.formatMessage({ id: 'offers.select.opened' })
                : status === 'ACTIF'
                ? intl.formatMessage({ id: 'offers.select.actif' })
                : status === 'INACTIF'
                ? intl.formatMessage({ id: 'offers.select.inactif' })
                : intl.formatMessage({ id: 'offers.select.expired' })
            }
            type='text'
            disabled
          />
          <div
            className='filter-button'
            onClick={() => setSelectOpener(!selectOpener)}
          >
            <h3>
              {intl.formatMessage({
                id: 'wallet.label.filter',
              })}
            </h3>
            <ExpandMoreRoundedIcon
              style={{ color: 'white' }}
              className={selectOpener ? 'filter-opened' : ''}
            />
          </div>
          <Select
            className='select-input'
            value={status}
            onChange={handleSelectChange}
            placeholder={intl.formatMessage({
              id: 'wallet.label.filter',
            })}
            id='filter'
            inputProps={{ className: 'input' }}
            open={selectOpener}
            onClose={() => setSelectOpener(false)}
          >
            <MenuItem
              value='ALL'
              onMouseEnter={(e) =>
                ((e.target as HTMLInputElement).style.color = '#ff5078')
              }
              onMouseLeave={(e) =>
                ((e.target as HTMLInputElement).style.color = '#000000')
              }
            >
              <span>{intl.formatMessage({ id: 'offers.select.all' })}</span>
            </MenuItem>
            <MenuItem
              value='OPENED'
              onMouseEnter={(e) =>
                ((e.target as HTMLInputElement).style.color = '#ff5078')
              }
              onMouseLeave={(e) =>
                ((e.target as HTMLInputElement).style.color = '#000000')
              }
            >
              <span>{intl.formatMessage({ id: 'offers.select.opened' })}</span>
            </MenuItem>
            <MenuItem
              value='ACTIF'
              onMouseEnter={(e) =>
                ((e.target as HTMLInputElement).style.color = '#ff5078')
              }
              onMouseLeave={(e) =>
                ((e.target as HTMLInputElement).style.color = '#000000')
              }
            >
              <span>{intl.formatMessage({ id: 'offers.select.actif' })}</span>
            </MenuItem>
            <MenuItem
              value='INACTIF'
              onMouseEnter={(e) =>
                ((e.target as HTMLInputElement).style.color = '#ff5078')
              }
              onMouseLeave={(e) =>
                ((e.target as HTMLInputElement).style.color = '#000000')
              }
            >
              <span>{intl.formatMessage({ id: 'offers.select.inactif' })}</span>
            </MenuItem>
            <MenuItem
              value='EXPIRED'
              onMouseEnter={(e) =>
                ((e.target as HTMLInputElement).style.color = '#ff5078')
              }
              onMouseLeave={(e) =>
                ((e.target as HTMLInputElement).style.color = '#000000')
              }
            >
              <span>{intl.formatMessage({ id: 'offers.select.expired' })}</span>
            </MenuItem>
          </Select>
        </FormControl>
        {!allTime && (
          <>
            <KeyboardDatePicker
              id='start-date'
              label={intl.formatMessage({ id: 'offers.label.startDate' })}
              format='MMM dd yyyy HH:mm'
              value={startDate}
              onChange={handleDateChange('startDate')}
              inputVariant='outlined'
              style={{ maxWidth: '250px' }}
              TextFieldComponent={({ InputProps, ...rest }) => (
                <TextField
                  {...rest}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Divider
                          className='divider'
                          orientation='vertical'
                          variant='inset'
                          light
                        />
                        <IconButton
                          onClick={
                            (InputProps as any)?.endAdornment.props.children
                              .props.onClick
                          }
                        >
                          <Calendar />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <KeyboardDatePicker
              id='end-date'
              label={intl.formatMessage({ id: 'offers.label.endDate' })}
              format='MMM dd yyyy HH:mm'
              inputVariant='outlined'
              value={endDate}
              onChange={handleDateChange('endDate')}
              error={endDateError !== ''}
              helperText={getFormatedMessage('endDateError')}
              style={{ maxWidth: '250px' }}
              disabled={startDate === null ? true : false}
              TextFieldComponent={({ InputProps, ...rest }) => (
                <TextField
                  {...rest}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Divider
                          className='divider'
                          orientation='vertical'
                          variant='inset'
                          light
                        />
                        <IconButton
                          onClick={
                            (InputProps as any)?.endAdornment.props.children
                              .props.onClick
                          }
                        >
                          <Calendar />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </>
        )}
        <FormControlLabel
          control={
            <StyledCheckbox
              checked={allTime}
              onChange={handleCheckboxChange}
              name='allTime'
            />
          }
          label={intl.formatMessage({ id: 'offers.label.allTime' })}
        />
        <Button className='btn-search' onClick={HandleClickSearch}>
          {intl.formatMessage({ id: 'offers.button.search' })}
        </Button>
      </div>

      <div className='div-header'>
        <Text color='#404040' fontSize={24} lineHeight={36} fontWeight={600}>
          {intl.formatMessage({ id: 'offers.label.title' })}
        </Text>
        <Button
          onClick={() => {
            setOpen(!open);
          }}
        >
          {intl.formatMessage({ id: 'offers.button.create' })}
        </Button>
      </div>

      <OfferModal
        open={open}
        onClose={() => setOpen(!open)}
        width={600}
        height={900}
      >
        <CreateOffer
          walletId={objectUser.wallet}
          onSaveChanged={() => setOpen(!open)}
        ></CreateOffer>
      </OfferModal>

      <SimpleDialog
        title={intl.formatMessage({ id: 'offers.dialog.title' })}
        text={intl.formatMessage({ id: `offers.dialog.text.${action}` })}
        handleAgree={handleClickUpdateOffer}
        open={openDialog}
        handleClose={handleCloseDialog}
      ></SimpleDialog>

      <OfferModal
        open={EditDialogOpen}
        onClose={() => setEditDialogOpen(false)}
      >
        <EditOffer
          walletId={objectUser.wallet}
          offerId={offerId}
          onCancel={() => setEditDialogOpen(false)}
          onSaveChanged={() => setEditDialogOpen(!EditDialogOpen)}
        ></EditOffer>
      </OfferModal>

      <div className='div-list'>
        <EmptyData
          data={offersList}
          loading={loading}
          message={intl.formatMessage({ id: 'offers.label.emptyList' })}
          image={emptyState}
        >
          {offersList
            ?.slice(page * rowsperpage, page * rowsperpage + rowsperpage)
            ?.map((offer: OfferApiResponse) => (
              <OfferCard
                key={offer._id}
                offer={offer}
                onClickUpdate={(id, action) => {
                  setOpenDialog(true);
                  setAction(action);
                  setOfferId(offer._id);
                }}
                onClickEdit={(id) => {
                  props.editOfferInitialState(offer);
                  setEditDialogOpen(true);
                  setOfferId(offer._id);
                }}
                onClickActivate={setCouponActif}
              />
            ))}
        </EmptyData>
      </div>
      <Box display='flex' justifyContent='flex-end' marginTop='auto'>
        <Pagination
          count={calculatePagesCount(rowsperpage, offersList?.length || 0)}
          variant='outlined'
          shape='rounded'
          onChange={handleChange}
        />
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled('div')<OffersStylesProps>`
  ${(props) => offersStyles(props)}
`;

const mapStateToProps = ({ offers }: RootState) => {
  const {
    offersList,
    offersFilterList,
    status,
    allTime,
    startDate,
    endDate,
    startDateError,
    endDateError,
    loading,
  } = offers;

  return {
    offersList,
    offersFilterList,
    status,
    allTime,
    startDate,
    endDate,
    startDateError,
    endDateError,
    loading,
  };
};

export const connector = connect(mapStateToProps, {
  offersGetAllOffers,
  offersPropsChanged,
  offerUpdateOffer,
  offersFilterOffers,
  offersInitialState,
  editOfferInitialState,
});

export default connector(Offers);
