import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='85'
      height='123'
      fill='none'
      viewBox='0 0 122 123'
    >
      <g filter='url(#filter0_d_185_21186)'>
        <rect
          width='52'
          height='52'
          x='61'
          y='21'
          fill='#fff'
          rx='7'
          transform='rotate(45 61 21)'
        ></rect>
        <path
          fill='#FF5078'
          fillRule='evenodd'
          d='M57.943 40.016c-3.184.115-5.498.461-7.273 1.087-1.354.478-2.24 1.008-3.028 1.813-.795.813-1.247 1.689-1.517 2.943-.119.552-.125 1.086-.125 10.57 0 8.155.017 10.065.091 10.4.224 1.011.713 1.972 1.353 2.66l.327.352.024 1.847c.023 1.769.03 1.862.184 2.208.207.471.656.874 1.129 1.014.283.083.624.102 1.587.084 1.218-.022 1.229-.024 1.592-.228.375-.21.709-.605.857-1.014.047-.13.081-.66.084-1.33l.005-1.111h14.534l.005 1.112c.003.668.036 1.198.084 1.33.148.408.482.803.857 1.013.363.204.374.206 1.592.228.963.017 1.304 0 1.587-.084.473-.14.922-.543 1.13-1.014.152-.346.16-.44.183-2.209l.024-1.846.327-.352c.64-.688 1.129-1.649 1.353-2.66.074-.335.091-2.245.091-10.4 0-9.484-.006-10.018-.125-10.57-.27-1.254-.722-2.13-1.517-2.943-1.56-1.591-3.986-2.417-8.148-2.773-1.122-.096-5.844-.179-7.267-.127zm13.44 11.95l-.016 4.6H49.633l-.017-4.6-.017-4.602H71.4l-.017 4.602zM53.1 62.225c1.626.494 2.405 2.308 1.653 3.853-.818 1.683-3.014 2.058-4.314.737a2.885 2.885 0 01-.772-2.341c.229-1.642 1.875-2.72 3.433-2.248zm16.3-.004c.175.04.495.187.71.329 1.179.774 1.572 2.205.968 3.518a2.653 2.653 0 01-3.534 1.29 2.593 2.593 0 01-1.295-1.28c-.684-1.407-.113-3.056 1.285-3.706.613-.286 1.087-.324 1.865-.15z'
          clipRule='evenodd'
        ></path>
      </g>
      <defs>
        <filter
          id='filter0_d_185_21186'
          width='121.738'
          height='121.74'
          x='0.131'
          y='0.899'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feColorMatrix
            in='SourceAlpha'
            result='hardAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          ></feColorMatrix>
          <feOffset dy='4'></feOffset>
          <feGaussianBlur stdDeviation='13.5'></feGaussianBlur>
          <feComposite in2='hardAlpha' operator='out'></feComposite>
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0'></feColorMatrix>
          <feBlend
            in2='BackgroundImageFix'
            result='effect1_dropShadow_185_21186'
          ></feBlend>
          <feBlend
            in='SourceGraphic'
            in2='effect1_dropShadow_185_21186'
            result='shape'
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

export default Icon;
