import { getSentDemosApis, sendDemoApi } from '../apis/demoApi';
import {
  AppThunk,
  DEMOS_GET_SENT_DEMOS,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
} from './types';

export const sendDemo = (email: string, kind: string): AppThunk => (
  dispatch
) => {
  sendDemoApi(email, kind).then((res: any) => {
    if (res.status == 200) {
      dispatch({
        type: SNACKBAR_SUCCESS,
        value: 'sent.success',
      });
    } else {
      dispatch({
        type: SNACKBAR_ERROR,
        value: 'wallet.error.occured',
      });
    }
  });
};

export const getSentDemos = (
  page: number,
  size: number,
  searchFilter: any
): AppThunk => (dispatch) => {
  getSentDemosApis(page, size, searchFilter).then((res: any) => {
    if (res.status == 200) {
      dispatch({
        type: DEMOS_GET_SENT_DEMOS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: SNACKBAR_ERROR,
        value: 'wallet.error.occured',
      });
    }
  });
};
