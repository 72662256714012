import React from 'react';
import { Wrapper } from './wireframe.style';
import { ic_close } from 'react-icons-kit/md/ic_close';

import { ic_call } from 'react-icons-kit/md/ic_call';
import { ic_email } from 'react-icons-kit/md/ic_email';
import { ic_public } from 'react-icons-kit/md/ic_public';

import { ratio } from 'wcag-color';
import barcode from '../../assets/images/barcode.png';

import defaultLogo from '../../assets/images/sm-thumbnail.png';
import cover from '../../assets/images/lg-thumbnail.png';

import { ic_more_vert } from 'react-icons-kit/md/ic_more_vert';
import Icon from 'react-icons-kit';

import moment from 'moment';

import {
  additionalContacts,
  description,
} from '../walletForm/walletForm.types';

import CODE128 from '../../assets/images/code128.png';
import PDF417 from '../../assets/images/pdf417.png';
import QR from '../../assets/images/qr.png';
import AZTEC from '../../assets/images/aztec.png';
import { switchBarcode } from '../../utils/common';

type WireframeProps = {
  backgroundColor: any;
  name: any;
  logo: any;
  strip: any;
  address: any;
  descriptions: any;
  openingHours: any;
  additionalContacts: any;
  gainUnit: string;
  barcodeType: string;
  primaryFields: {
    firstField: string;
    secondField: string;
  };
};

const Wireframe: React.FC<WireframeProps> = (props) => {
  const {
    backgroundColor,
    logo,
    name,
    strip,
    address,
    descriptions,
    openingHours,
    additionalContacts,
    gainUnit,
    barcodeType,
    primaryFields,
  } = props;

  const { barcode, barcodeClass } = switchBarcode(barcodeType, {
    CODE128,
    PDF417,
    QR,
    AZTEC,
  });

  return (
    <Wrapper
      backgroundColor={backgroundColor}
      mainContentTextColor={
        ratio('#ffffff', backgroundColor.hex) > 3.0 ? '#ffffff' : '#000000'
      }
    >
      <div className='mainSection'>
        <div className='mainSection-header'>
          <Icon
            size={18}
            icon={ic_close}
            style={
              ratio('#ffffff', backgroundColor.hex) > 3.0
                ? { color: '#ffffff' }
                : { color: '#000000' }
            }
          />
          <Icon
            size={18}
            icon={ic_more_vert}
            style={
              ratio('#ffffff', backgroundColor.hex) > 3.0
                ? { color: '#ffffff' }
                : { color: '#000000' }
            }
          />
        </div>
        <div className='mainSection-header-logo'>
          <img
            src={
              !logo
                ? defaultLogo
                : typeof logo === 'string'
                ? logo
                : URL.createObjectURL(logo)
            }
            alt='wallet Logo'
          />
        </div>
        <div className='mainSection-content-enseigne'>
          <h4>{!name ? '"Enseigne"' : name}</h4>
          <h3>Carte de fidélité</h3>
        </div>
        <div className='mainSection-content-banner'>
          <img
            src={
              !strip
                ? cover
                : typeof strip === 'string'
                ? strip
                : URL.createObjectURL(strip)
            }
            alt='banner'
          />
        </div>
        <div className='mainSection-content-datePoints'>
          {primaryFields.firstField === 'CREATION_DATE' && (
            <div className='date'>
              <h3 className='mainSection-label'>Date</h3>
              <h3 className='mainSection-data'>{moment().format('L')}</h3>
            </div>
          )}
          {primaryFields.secondField === 'REWARDS' && (
            <div className='points'>
              <h3 className='mainSection-label'>Gains</h3>
              <h3 className='mainSection-data'>
                0.00 {gainUnit !== 'NONE' ? gainUnit : ''}
              </h3>
            </div>
          )}
        </div>
        <div
          className={
            barcodeType === 'NONE'
              ? 'mainSection-content-barCode hidden'
              : 'mainSection-content-barCode'
          }
        >
          <div
            className={
              barcodeClass == 'squareCode'
                ? 'barCode barcodeStretch'
                : 'barCode '
            }
          >
            <img className={barcodeClass} src={barcode} />
          </div>
          <p className='walletId'>5g1hdvb82154df853fca14835</p>
        </div>
      </div>
      <div className='dataSection'>
        <div className='sectionLabel'>
          <h3 className='label'>Nom du client</h3>
          <h3 className='data'>Nom du client</h3>
        </div>
        <div className='sectionLabel'>
          <h3 className='label'>Member ID</h3>
          <h3 className='data'>6066e4315abbcc1f9526d392</h3>
        </div>
        <div className='sectionLabel'>
          <h3 className='label'>type</h3>
          <h3 className='data'>Silver</h3>
        </div>
        <div className='sectionLabel'>
          <h3 className='label'>Date</h3>
          <h3 className='data'>{moment().format('L HH:mm')}</h3>
        </div>
        <div className='sectionLabel'>
          <h3 className='label'>Adresse</h3>
          <h3 className='data'>{address ? address : '"Adresse"'}</h3>
        </div>
        {descriptions.map((elm: description, index: number) => (
          <div className='sectionLabel' key={index}>
            <h3 className='label'>
              {elm.title ? elm.title : '"Description Title"'}
            </h3>
            <h3 className='data'>{elm.value ? elm.value : '"Value"'}</h3>
          </div>
        ))}
        <div className='sectionLabel'>
          <h3 className='label'>Horaires</h3>
          <h3 className='data'>{openingHours ? openingHours : '"Horaires"'}</h3>
        </div>
        <div className='sectionLabel'>
          <h3 className='label'>Description offre</h3>
          <h3 className='data'>Pas d&apos;offres</h3>
        </div>
        {additionalContacts.map((elm: additionalContacts, index: number) => (
          <div className='sectionIcon' key={index}>
            <Icon
              icon={
                elm.kind === 'PHONE'
                  ? ic_call
                  : elm.kind === 'EMAIL'
                  ? ic_email
                  : ic_public
              }
              size={18}
              style={{ color: '#999999' }}
            />
            <h3 className='data'>
              {elm.title ? elm.title : '"Titre du contact"'}
            </h3>
          </div>
        ))}
        <div className='sectionIcon'>
          <Icon icon={ic_call} size={18} style={{ color: '#999999' }} />
          <h3 className='data'>Téléphone commercial</h3>
        </div>
        <div className='sectionIcon'>
          <Icon icon={ic_call} size={18} style={{ color: '#999999' }} />
          <h3 className='data'>Téléphone S.A.V</h3>
        </div>
        <div className='sectionIcon'>
          <Icon icon={ic_public} size={18} style={{ color: '#999999' }} />
          <h3 className='data'>Site web</h3>
        </div>
        <div className='sectionIcon'>
          <Icon icon={ic_public} size={18} style={{ color: '#999999' }} />
          <h3 className='data'>Facebook</h3>
        </div>
        <div className='sectionIcon'>
          <Icon icon={ic_public} size={18} style={{ color: '#999999' }} />
          <h3 className='data'>Instagram</h3>
        </div>
        <div className='sectionIcon'>
          <Icon icon={ic_public} size={18} style={{ color: '#999999' }} />
          <h3 className='data'>Linkedin</h3>
        </div>
      </div>
    </Wrapper>
  );
};

export default Wireframe;
