import React from 'react';
import { Wrapper } from './couponWireframe.style';
import { ic_close } from 'react-icons-kit/md/ic_close';

import { ratio } from 'wcag-color';
import barcode from '../../assets/images/barcode.png';

import cover from '../../assets/images/lg-thumbnail.png';

import { ic_more_vert } from 'react-icons-kit/md/ic_more_vert';
import Icon from 'react-icons-kit';

import moment from 'moment';

import { getDataFromCache } from '../../utils/cache';
import { URL_IMAGE } from '../../apis/config';
import { useIntl } from 'react-intl';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import CODE128 from '../../assets/images/code128.png';
import PDF417 from '../../assets/images/pdf417.png';
import QR from '../../assets/images/qr.png';
import AZTEC from '../../assets/images/aztec.png';
import { switchBarcode } from '../../utils/common';

type WireframeProps = {
  backgroundColor: any;
  promoCode: any;
  strip: any;
  expires: any;
  promotion: any;
  description: any;
  name: string;
  symbol: string;
  logo?: any;
  barcodeType: string;
};

const CouponWireframeAndroid: React.FC<WireframeProps> = (props) => {
  const {
    backgroundColor,
    promoCode,
    strip,
    expires,
    promotion,
    description,
    name,
    symbol,
    logo,
    barcodeType,
  } = props;
  const currentWallet = getDataFromCache('wallet');
  const intl = useIntl();
  const [showDetails, setShowDetails] = React.useState(false);
  const [height, setHeight] = React.useState<string>('800px');
  const handleChange = () => {
    setShowDetails(!showDetails);
    height == '100%' ? setHeight('100%') : setHeight('100%');
  };

  const { barcode, barcodeClass } = switchBarcode(barcodeType, {
    CODE128,
    PDF417,
    QR,
    AZTEC,
  });

  return (
    <Wrapper
      backgroundColor={backgroundColor}
      mainContentTextColor={
        ratio('#ffffff', backgroundColor.hex) > 3.0 ? '#ffffff' : '#000000'
      }
      height={height}
    >
      <div className='mainSection'>
        <div className='mainSection-header'>
          <Icon
            size={18}
            icon={ic_close}
            style={
              ratio('#ffffff', backgroundColor.hex) > 3.0
                ? { color: '#ffffff' }
                : { color: '#000000' }
            }
          />
          <Icon
            size={18}
            icon={ic_more_vert}
            style={
              ratio('#ffffff', backgroundColor.hex) > 3.0
                ? { color: '#ffffff' }
                : { color: '#000000' }
            }
          />
        </div>
        <div className='mainSection-header-logo'>
          <img
            src={logo ? logo : `${URL_IMAGE}/${currentWallet.logoPictureUrl}`}
            alt='wallet Logo'
          />
        </div>
        <div className='mainSection-content-enseigne'>
          <h4>{name || 'Coupon'}</h4>
          <h3 className='walletName'> {currentWallet.name || 'best wallet'}</h3>
        </div>
        <div className='mainSection-content-banner'>
          <img
            src={
              !strip
                ? cover
                : typeof strip === 'string'
                ? strip
                : URL.createObjectURL(strip)
            }
            alt='banner'
          />
        </div>
        <div
          className={
            barcodeType === 'NONE'
              ? 'mainSection-content-barCode hidden'
              : 'mainSection-content-barCode'
          }
        >
          {' '}
          <div className='barCode'>
            <img className={barcodeClass} src={barcode} alt='barcode' />
          </div>
          <p className='walletId'>{promoCode || 'promoCode'}</p>
        </div>
      </div>
      <div className='dataSection'>
        <div className='margins' onClick={handleChange}>
          {!showDetails ? (
            <div className='details_btn'>
              <ExpandMoreIcon color='primary' />
              Show details
            </div>
          ) : (
            <div className='details_btn'>
              <ExpandLessIcon />
              Hide details
            </div>
          )}
        </div>
      </div>
      {showDetails && (
        <div className='dataSection'>
          <span className='divider'></span>
          <div className='sectionLabel'>
            <h3 className='label'>
              {intl.formatMessage({
                id: 'coupon.label.expirationDate',
              })}
              :
            </h3>
            <h3 className='data'>
              {moment(expires).format('MMM DD, YYYY, h:mm A')}
            </h3>
          </div>
          <span className='divider'></span>
          <div className='sectionLabel'>
            <h3 className='label'>
              {intl.formatMessage({
                id: 'coupon.label.From',
              })}
            </h3>
            <h3 className='data'>{'' || 'wallet'}</h3>
          </div>
          <span className='divider'></span>
          <div className='sectionLabel'>
            <img
              src={
                !strip
                  ? cover
                  : typeof strip === 'string'
                  ? strip
                  : URL.createObjectURL(strip)
              }
              alt='banner'
              style={{ height: 300, width: 300 }}
            />
          </div>
          <span className='divider'></span>
          <div className='sectionLabel'>
            <h3 className='label'>description</h3>
            <h3 className='data'>{description || 'description'}</h3>
          </div>
          <span className='divider'></span>
          <div className='sectionLabel'>
            <h3 className='label'>
              {intl.formatMessage({
                id: 'coupon.label.value',
              })}
            </h3>
            <h3 className='data'>{promotion || 0 + ' ' + symbol}</h3>
          </div>
          <span className='divider'></span>
          <div className='sectionLabel'>
            <h3 className='label'>Promo Code</h3>
            <h3 className='data'>{promoCode || 'promoCode'}</h3>
          </div>
          <span className='divider'></span>
          <div className='sectionLabel'>
            <h3 className='label'>Id coupon</h3>
            <h3 className='data'>61dc3b460779650007267001</h3>
          </div>
        </div>
      )}
    </Wrapper>
  );
};

export default CouponWireframeAndroid;
