import React from 'react';
import { Wrapper } from './eventWireframe.style';

import { ellipses } from 'react-icons-kit/iconic/ellipses';
import { ratio } from 'wcag-color';
import barcode from '../../assets/images/barcode.png';

import cover from '../../assets/images/lg-thumbnail.png';
import { getDataFromCache } from '../../utils/cache';
import { URL_IMAGE } from '../../apis/config';
import Icon from 'react-icons-kit';

import moment from 'moment';
import QRCode from 'qrcode.react';
import { ic_more_vert } from 'react-icons-kit/md/ic_more_vert';
import { useIntl } from 'react-intl';

import CODE128 from '../../assets/images/code128.png';
import PDF417 from '../../assets/images/pdf417.png';
import QR from '../../assets/images/qr.png';
import AZTEC from '../../assets/images/aztec.png';
import { switchBarcode } from '../../utils/common';

type WireframeProps = {
  backgroundColor: any;
  strip: any;
  price: any;
  endDate: any;
  startDate: any;
  openDate: any;
  terms: any;
  name: any;
  locationName: any;
  titleColor: any;
  textColor: any;
  QrCode: any;
  locations: any[];
  email: string;
  contactPhone: string;
  movieName: string;
  screen: number;
  rating: string;
  logo?: any;
  barcodeType: string;
};

const CinemaTicketWireframeIPhone: React.FC<WireframeProps> = (props) => {
  const {
    backgroundColor,
    strip,
    locationName,
    name,
    price,
    endDate,
    startDate,
    openDate,
    terms,
    titleColor,
    textColor,
    QrCode,
    locations,
    contactPhone,
    email,
    movieName,
    screen,
    rating,
    logo,
    barcodeType,
  } = props;
  const currentWallet = getDataFromCache('wallet');
  const [showDetails, setShowDetails] = React.useState(true);

  const { barcode, barcodeClass } = switchBarcode(barcodeType, {
    CODE128,
    PDF417,
    QR,
    AZTEC,
  });

  const intl = useIntl();
  const backFlip = () => {
    setShowDetails(!showDetails);
  };
  return (
    <Wrapper
      backgroundColor={backgroundColor}
      mainContentTextColor={
        ratio('#ffffff', backgroundColor.hex) > 3.0 ? '#ffffff' : '#000000'
      }
      titleColor={titleColor}
      textColor={textColor}
      style={{ maxWidth: '334px' }}
    >
      {showDetails ? (
        <div className='mainSection'>
          <div className='mainSection-header'>
            <div className='mainSection-header-logoSection'>
              <img
                className='mainSection-header-logo'
                src={
                  logo ? logo : `${URL_IMAGE}/${currentWallet.logoPictureUrl}`
                }
                alt='wallet Logo'
              />
              <h5 className='mainSection-header-eventName'>
                {name || "Nom de l'évènement"}
              </h5>
            </div>
          </div>
          <div className='mainSection-content-banner'>
            <img
              src={
                !strip
                  ? cover
                  : typeof strip === 'string'
                  ? strip
                  : URL.createObjectURL(strip)
              }
              alt='banner'
            />
          </div>
          <div className='mainSection-content-datePoints'>
            <div className='date'>
              <h3 className='mainSection-label'>
                {intl.formatMessage({ id: 'coupon.label.value' })}
              </h3>
              <h3 className='mainSection-data'>13 €</h3>
            </div>
            <div className='date'>
              <h3 className='mainSection-label'>
                {intl.formatMessage({ id: 'coupon.label.value' })}
              </h3>
              <h3 className='mainSection-data'>2</h3>
            </div>
            <div className='points'>
              <h3 className='mainSection-label'>
                {intl.formatMessage({ id: 'event.label.row' })}
              </h3>
              <h3 className='mainSection-data'>ds 11</h3>
            </div>
            <div className='points'>
              <h3 className='mainSection-label'>
                {intl.formatMessage({ id: 'event.label.place' })}
              </h3>
              <h3 className='mainSection-data'>5</h3>
            </div>
          </div>
          <div className='mainSection-content-datePoints'>
            <div className='points'>
              <p className='mainSection-label'>
                {intl.formatMessage({ id: 'event.label.ticketHolder' })}
              </p>
              <h3 className='mainSection-data'>{'Nom Prenom'}</h3>
            </div>
            <div className='date'>
              <h3 className='mainSection-label'>
                {intl.formatMessage({ id: 'cinemaTicket.label.date' })}
              </h3>
              <h3 className='mainSection-data'>
                {moment(openDate).format('L') +
                  ' at ' +
                  moment(openDate).format('h:mm A') || ''}
              </h3>
            </div>
          </div>
          <div
            className={
              barcodeType === 'NONE'
                ? 'mainSection-content-barCode hidden'
                : 'mainSection-content-barCode'
            }
          >
            {' '}
            <div className='barCode'>
              <img className={barcodeClass} src={barcode} alt='barcode' />
            </div>
            <p className='walletId'>6066e4315abbcc1f9526d392</p>
          </div>
          <div className='BackFlip_btn' onClick={backFlip}>
            <Icon size={18} icon={ic_more_vert} className='backflip' />
          </div>
        </div>
      ) : (
        <div className='dataSection'>
          <div className='sectionLabel'>
            <h3 className='label'>
              {intl.formatMessage({ id: 'cinemaTicket.label.movieName' })}
            </h3>
            <h3 className='data'>{locationName || 'nom'}</h3>
          </div>
          <span className='divider'></span>
          <div className='sectionLabel'>
            <h3 className='label'>
              {intl.formatMessage({ id: 'cinemaTicket.label.rating' })}
            </h3>
            <h3 className='data'>{locationName || 'location name'}</h3>
          </div>
          <span className='divider'></span>
          <div className='sectionLabel'>
            <h3 className='label'>
              {intl.formatMessage({ id: 'event.label.locationName' })}
            </h3>
            <h3 className='data'>{locationName || 'location name'}</h3>
          </div>
          <span className='divider'></span>
          <div className='sectionLabel'>
            <h3 className='label'>
              {intl.formatMessage({ id: 'event.label.startTime' })}
            </h3>
            {moment(openDate).format('MMM DD, YYYY, h:mm A') || ''}
          </div>
          <span className='divider'></span>
          <div className='sectionLabel'>
            <h3 className='label'>Doors open</h3>
            <h3 className='data'>
              {moment(openDate).format('MMM DD, YYYY, h:mm A') || ''}
            </h3>
          </div>
          <span className='divider'></span>
          <div className='sectionLabel'>
            <h3 className='label'>
              {intl.formatMessage({ id: 'transit.label.ticketNumber' })}
            </h3>
            <h3 className='data'>6066e4315abbcc1f9526d392</h3>
          </div>
          <span className='divider'></span>
          <div className='sectionLabel'>
            <h3 className='label'>Terms & conditions</h3>
            <h3 className='data'>{terms || ''}</h3>
          </div>
          <div className='sectionLabel'>
            <h3 className='label'>Info Line</h3>
            <h3 className='data'>{contactPhone || ''}</h3>
          </div>
          <div className='sectionLabel'>
            <h3 className='label'>Email</h3>
            <h3 className='data'>{email || ''}</h3>
          </div>
          <div className='BackFlip_btn' onClick={backFlip}>
            <Icon size={18} icon={ic_more_vert} className='backflip' />
          </div>
        </div>
      )}
    </Wrapper>
  );
};

export default CinemaTicketWireframeIPhone;
