import React from 'react';
import { Wrapper } from './eventWireframe.style';

import { ic_close } from 'react-icons-kit/md/ic_close';
import { ctrl } from 'react-icons-kit/icomoon/ctrl';
import { ic_call } from 'react-icons-kit/md/ic_call';
import { ic_email } from 'react-icons-kit/md/ic_email';
import { ic_location_on } from 'react-icons-kit/md/ic_location_on';

import { ratio } from 'wcag-color';
import barcode from '../../assets/images/barcode.png';

import cover from '../../assets/images/lg-thumbnail.png';

import { ic_more_vert } from 'react-icons-kit/md/ic_more_vert';
import Icon from 'react-icons-kit';

import moment from 'moment';

import { getDataFromCache } from '../../utils/cache';
import { URL_IMAGE } from '../../apis/config';
import QRCode from 'qrcode.react';
import { useIntl } from 'react-intl';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import CODE128 from '../../assets/images/code128.png';
import PDF417 from '../../assets/images/pdf417.png';
import QR from '../../assets/images/qr.png';
import AZTEC from '../../assets/images/aztec.png';
import { switchBarcode } from '../../utils/common';

type WireframeProps = {
  backgroundColor: any;
  strip: any;
  price: any;
  endDate: any;
  startDate: any;
  openDate: any;
  terms: any;
  name: any;
  locationName: any;
  QrCode: any;
  logo?: any;
  barcodeType: string;
  animatedBorder?: boolean;
};

const EventWireframeAndroid: React.FC<WireframeProps> = (props) => {
  const {
    backgroundColor,
    strip,
    locationName,
    name,
    price,
    endDate,
    startDate,
    openDate,
    terms,
    QrCode,
    logo,
    barcodeType,
    animatedBorder,
  } = props;
  const currentWallet = getDataFromCache('wallet');
  const intl = useIntl();
  const [showDetails, setShowDetails] = React.useState(false);
  const [height, setHeight] = React.useState<string>('800px');
  const { barcode, barcodeClass } = switchBarcode(barcodeType, {
    CODE128,
    PDF417,
    QR,
    AZTEC,
  });

  const handleChange = () => {
    setShowDetails(!showDetails);
    height == '100%' ? setHeight('100%') : setHeight('100%');
  };
  return (
    <Wrapper
      backgroundColor={backgroundColor}
      mainContentTextColor={
        ratio('#ffffff', backgroundColor.hex) > 3.0 ? '#ffffff' : '#000000'
      }
      titleColor={'rgba(255, 80, 120, 0.83)'}
      textColor={'#000'}
    >
      <div className='mainSection'>
        <div className='mainSection-header'>
          <Icon
            size={18}
            icon={ic_close}
            style={
              ratio('#ffffff', backgroundColor.hex) > 3.0
                ? { color: '#ffffff' }
                : { color: '#000000' }
            }
          />
          <Icon
            size={18}
            icon={ic_more_vert}
            style={
              ratio('#ffffff', backgroundColor.hex) > 3.0
                ? { color: '#ffffff' }
                : { color: '#000000' }
            }
          />
        </div>
        <div className='mainSection-header-logo'>
          <img
            src={logo ? logo : `${URL_IMAGE}/${currentWallet.logoPictureUrl}`}
            alt='wallet Logo'
          />
        </div>
        <div className='mainSection-content-enseigne'>
          <div className='mainSection-issuerName'>Astrolab Agency</div>
          <div className='mainSection-eventName'>
            {name || intl.formatMessage({ id: 'event.label.locationName' })}
          </div>
          <div className='mainSection-location'>
            {locationName ||
              intl.formatMessage({ id: 'event.label.locationName' })}
          </div>
        </div>
        <div className='mainSection-content-datePoints'>
          <div className='date'>
            <h3 className='mainSection-label'>Date</h3>
            <h3 className='mainSection-data'>
              {moment(openDate).format('MMM DD, YYYY') || ''}
            </h3>
          </div>
          <div className='points'>
            <h3 className='mainSection-label'>Time</h3>
            <h3 className='mainSection-data'>
              {moment(openDate).format('h:mm A') || ''}
            </h3>
          </div>
        </div>
        <div className='mainSection-content-banner'>
          <img
            src={
              !strip
                ? cover
                : typeof strip === 'string'
                ? strip
                : URL.createObjectURL(strip)
            }
            alt='banner'
          />
        </div>
        <div className='mainSection-content-datePoints'>
          <div className='date'>
            <h3 className='mainSection-label'>
              {intl.formatMessage({ id: 'event.label.door' })}{' '}
            </h3>
            <h3 className='mainSection-data'>porte 11</h3>
          </div>
          <div className='points'>
            <h3 className='mainSection-label'>
              {' '}
              {intl.formatMessage({ id: 'event.label.row' })}
            </h3>
            <h3 className='mainSection-data'>rangee 11</h3>
          </div>
          <div className='points'>
            <h3 className='mainSection-label'>
              {' '}
              {intl.formatMessage({ id: 'event.label.place' })}
            </h3>
            <h3 className='mainSection-data'>chaise 11</h3>
          </div>
        </div>
        <div
          className={
            barcodeType === 'NONE'
              ? 'mainSection-content-barCode hidden'
              : 'mainSection-content-barCode'
          }
        >
          {' '}
          <div className='barCode'>
            <div>
              <img
                className={`${barcodeClass} ${
                  animatedBorder && 'animatedBorder'
                }`}
                src={barcode}
                alt='barcode'
              />
            </div>
          </div>
          <p className='walletId'>6066e4315abbcc1f9526d392</p>
        </div>
      </div>
      <div className='dataSection'>
        {/* <div className='sectionLabel'>
          <h3 className='label' onClick={() => setShowDetails(!showDetails)}>
            {showDetails ? <span>Hide</span> : <span>Show</span>} Details
            <Icon
              size={18}
              icon={ctrl}
              style={{
                color: '#999999',
                transform: showDetails ? '' : 'rotate(180deg)',
                transition: 'transform 150ms ease',
              }}
            />
          </h3>
        </div> */}
        <div className='dataSection btn-section'>
          <div className='margins' onClick={handleChange}>
            {!showDetails ? (
              <div className='details_btn'>
                <ExpandMoreIcon color='primary' />
                Show details
              </div>
            ) : (
              <div className='details_btn'>
                <ExpandLessIcon />
                Hide details
              </div>
            )}
          </div>
        </div>
        {showDetails ? (
          <div>
            <span className='divider'></span>
            <div className='sectionLabel'>
              <h3 className='label'>Ticket holder</h3>
              <h3 className='data'>example@someMail.com</h3>
            </div>
            <span className='divider'></span>
            <div className='sectionLabel'>
              <h3 className='label'>
                {' '}
                {name || intl.formatMessage({ id: 'event.label.eventName' })}
              </h3>
              <h3 className='data'>{locationName || 'location name'}</h3>
            </div>
            <span className='divider'></span>
            <div className='sectionLabel'>
              <h3 className='label'>
                {' '}
                {intl.formatMessage({ id: 'event.label.doorsOpen' })}
              </h3>
              <h3 className='data'>
                {moment(openDate).format('MMM DD, YYYY, h:mm a') || ''}
              </h3>
            </div>
            <span className='divider'></span>
            <div className='sectionLabel'>
              <h3 className='label'>
                {' '}
                {intl.formatMessage({ id: 'event.label.startTime' })}
              </h3>
              <h3 className='data'>
                {moment(startDate).format('MMM DD, YYYY, h:mm A') || ''}
              </h3>
            </div>
            <span className='divider'></span>
            <div className='sectionLabel'>
              <h3 className='label'>
                {intl.formatMessage({ id: 'event.label.ticketNumber' })}
              </h3>
              <h3 className='data'>
                {moment(endDate).format('MMM DD, YYYY, h:mm A') || ''}
              </h3>
            </div>
            <span className='divider'></span>
            <div className='sectionLabel'>
              <h3 className='label'>Ticket number</h3>
              <h3 className='data'>6066e4315abbcc1f9526d392</h3>
            </div>
            <span className='divider'></span>
            <div className='sectionLabel'>
              <h3 className='label'>valeur</h3>
              <h3 className='data'>{price || ''}</h3>
            </div>
            <span className='divider'></span>
            <div className='sectionLabel'>
              <h3 className='label'>Terms & conditions</h3>
              <h3 className='data'>{terms || ''}</h3>
            </div>
            <div className='sectionIcon'>
              <Icon
                icon={ic_location_on}
                size={18}
                style={{ color: '#999999' }}
              />
              <h3 className='data'>Google Map</h3>
            </div>
            <div className='sectionIcon'>
              <Icon icon={ic_call} size={18} style={{ color: '#999999' }} />
              <h3 className='data'>Info Line</h3>
            </div>
            <div className='sectionIcon'>
              <Icon icon={ic_email} size={18} style={{ color: '#999999' }} />
              <h3 className='data'>Email</h3>
            </div>
          </div>
        ) : null}
      </div>
    </Wrapper>
  );
};

export default EventWireframeAndroid;
