import moment from 'moment';
import { Moment } from 'moment';
import { getSMSstatsDetails } from '../apis/statsApi';

import { StatisticsDetailResponseAPI } from '../modules/Statistics/stats.types';
import {
  AppThunk,
  STATISTICS_DETAILS_PROPS_CHANGED,
  STATISTICS_GET_SMS_COUNT,
  STATISTICS_GET_STATS_DETAIL,
  STATISTICS_PROPS_CHANGED,
} from './types';

export const StatsDetailsFilterSMS = (
  walletId: string,
  allTime: boolean,
  startDate: Moment | null,
  endDate: Moment | null,
  type: string
): AppThunk => (dispatch) => {
  dispatch({
    type: STATISTICS_DETAILS_PROPS_CHANGED,
    prop: 'loadingDetails',
    value: true,
  });

  if (allTime || !endDate) {
    getSMSstatsDetails(walletId, null, null, type).then(
      (res: StatisticsDetailResponseAPI) => {
        dispatch({
          type: STATISTICS_DETAILS_PROPS_CHANGED,
          prop: 'loadingDetails',
          value: false,
        });

        dispatch({
          type: STATISTICS_GET_STATS_DETAIL,
          payload: res,
        });
      }
    );
  }

  if (startDate && endDate && !allTime) {
    let dateStart = '';
    let dateEnd = '';
    dateStart = `${moment
      .utc(startDate.toLocaleString())
      .format('YYYY-MM-DD')}T00:00:00.000`;
    dateEnd = `${moment
      .utc(endDate.toLocaleString())
      .format('YYYY-MM-DD')}T23:59:59.000`;

    if (startDate && endDate) {
      getSMSstatsDetails(walletId, dateStart, dateEnd, type).then(
        (res: StatisticsDetailResponseAPI) => {
          dispatch({
            type: STATISTICS_DETAILS_PROPS_CHANGED,
            prop: 'loadingDetails',
            value: false,
          });

          if (res.status === 'success') {
            dispatch({
              type: STATISTICS_GET_STATS_DETAIL,
              payload: res,
            });
          }
        }
      );
    }
  }
};
