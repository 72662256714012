import React from 'react';
import { useIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

export interface SimpleDialogProps {
  open: boolean;
  title: string;
  text: string;
  handleAgree: () => void;
  handleClose: () => void;
}

const SimpleDialog: React.FC<SimpleDialogProps> = (props) => {
  const intl = useIntl();
  const { open, title, text, handleAgree, handleClose } = props;

  return (
    <Dialog
      fullWidth
      disableBackdropClick
      onClose={handleClose}
      aria-labelledby='simple-dialog-title'
      open={open}
    >
      <DialogTitle id='simple-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary' autoFocus>
          {intl.formatMessage({ id: 'dialog.button.desagree' })}
        </Button>
        <Button onClick={handleAgree} color='primary' autoFocus>
          {intl.formatMessage({ id: 'dialog.button.agree' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SimpleDialog;
