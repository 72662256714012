import React from 'react';
import { Wrapper } from './couponWireframe.style';

import { ellipses } from 'react-icons-kit/iconic/ellipses';
import { ratio } from 'wcag-color';
import barcode from '../../assets/images/barcode.png';

import cover from '../../assets/images/lg-thumbnail.png';
import { getDataFromCache } from '../../utils/cache';
import { URL_IMAGE } from '../../apis/config';
import Icon from 'react-icons-kit';

import moment from 'moment';
import { ic_more_vert } from 'react-icons-kit/md/ic_more_vert';

import CODE128 from '../../assets/images/code128.png';
import PDF417 from '../../assets/images/pdf417.png';
import QR from '../../assets/images/qr.png';
import AZTEC from '../../assets/images/aztec.png';
import { switchBarcode } from '../../utils/common';

type WireframeProps = {
  backgroundColor: any;
  promotion: any;
  image: any;
  promoCode: any;
  description: any;
  titleColor: string;
  textColor: string;
  name: string;
  symbol: string;
  logo?: any;
  barcodeType: string;
};

const CouponWireframeIPhone: React.FC<WireframeProps> = (props) => {
  const {
    backgroundColor,
    image,
    promotion,
    promoCode,
    description,
    titleColor,
    textColor,
    name,
    symbol,
    logo,
    barcodeType,
  } = props;
  const currentWallet = getDataFromCache('wallet');
  const [showDetails, setShowDetails] = React.useState(true);

  const { barcode, barcodeClass } = switchBarcode(barcodeType, {
    CODE128,
    PDF417,
    QR,
    AZTEC,
  });

  const backFlip = () => {
    setShowDetails(!showDetails);
  };
  return (
    <Wrapper
      backgroundColor={backgroundColor}
      mainContentTextColor={
        ratio('#ffffff', backgroundColor.hex) > 3.0 ? '#ffffff' : '#000000'
      }
      titleColor={titleColor}
      textColor={textColor}
    >
      {/* <div className='mainSection-header-more-logo'>
        <h4>Coupon</h4>
        <Icon size={8} icon={ellipses} />
      </div> */}
      {showDetails ? (
        <div className='mainSection'>
          <div className='mainSection-header'>
            <div className='mainSection-header-logoSection'>
              <img
                className='mainSection-header-logo'
                src={
                  logo ? logo : `${URL_IMAGE}/${currentWallet.logoPictureUrl}`
                }
                alt='wallet Logo'
              />
              {/* <div className='mainSection-content-enseigne'>
              <h4>{name || 'Coupon'}</h4>
            </div> */}
              <div>
                <h3 className='mainSection-data'>{name || 'Coupon'}</h3>
                {/* <h3 className='mainSection-data' style={{ textAlign: 'end' }}>
                Nom
              </h3> */}
              </div>
            </div>
          </div>

          <div className='mainSection-content-banner'>
            <img
              src={
                !image
                  ? cover
                  : typeof image === 'string'
                  ? image
                  : URL.createObjectURL(image)
              }
              alt='banner'
            />
          </div>
          <div className='mainSection-content-datePoints'>
            <div className='points'>
              <h3 className='mainSection-label'>Valeur</h3>
              <h3 className='mainSection-subdata'>
                {(promotion || 0) + ' ' + symbol}
              </h3>
            </div>
            <div className='date'>
              <h3 className='mainSection-label'>Date d’expiration</h3>
              <h3 className='mainSection-subdata'>{moment().format('L')}</h3>
            </div>
          </div>
          <div
            className={
              barcodeType === 'NONE'
                ? 'mainSection-content-barCode hidden'
                : 'mainSection-content-barCode'
            }
          >
            {' '}
            <div className='barCode'>
              <img className={barcodeType} src={barcode} alt='barcode' />
              <p className='walletId'>{promoCode || ''}</p>
            </div>
          </div>
          <div className='BackFlip_btn' onClick={backFlip}>
            <Icon size={18} icon={ic_more_vert} className='backflip' />
          </div>
        </div>
      ) : (
        <div className='dataSection'>
          {/* <span className='divider'></span> */}
          <div className='sectionLabel'>
            <h3 className='label'>description</h3>
            <h3 className='data'>{description}</h3>
          </div>
          <div className='BackFlip_btn' onClick={backFlip}>
            <Icon size={18} icon={ic_more_vert} className='backflip' />
          </div>
        </div>
      )}
    </Wrapper>
  );
};

export default CouponWireframeIPhone;
