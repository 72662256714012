import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
//Style
import styled from 'styled-components';
import { forgotPasswordStyles } from './forgotPassword.styles';
//Material
import { CircularProgress } from '@material-ui/core';
//Commomns
import InputLabel from '../../commons/Inputs/InputLabel';
import Button from '../../commons/Button';

//Actions
import {
  forgotPasswordPropsChanged,
  forgotPasswordConfirm,
  forgotPasswordInitialState,
} from '../../actions';
//Reducers
import { RootState } from '../../reducers';
//Type
import { ForgotPasswordFormProps } from './forgotPassword.types';
import { isEmail, isEmpty } from '../../utils/validations';

const ForgotPassword: React.FC<ForgotPasswordFormProps> = (props) => {
  const intl = useIntl();
  const { username, emailError, loading, onSaveChanged } = props;

  React.useEffect(() => {
    props.forgotPasswordInitialState();
    /* eslint-disable-next-line */
  }, []);

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.forgotPasswordPropsChanged(event.target.name, event.target.value);
  };

  const handleClickConfirm = () => {
    if (isEmpty(username)) {
      props.forgotPasswordPropsChanged(
        'emailError',
        intl.formatMessage({
          id: 'forgotPassword.error.emailRequired',
        })
      );
    } else {
      props.forgotPasswordPropsChanged('emailError', '');
      props.forgotPasswordConfirm(username, () => onSaveChanged());
    }
  };

  return (
    <Wrapper>
      <div className='div-container'>
        <InputLabel
          label={intl.formatMessage({
            id: 'forgotPassword.label.email',
          })}
          name='username'
          value={username}
          error={emailError}
          placeholder={intl.formatMessage({
            id: 'forgotPassword.placeholder.email',
          })}
          onChange={(e) => handleEventChange(e)}
        />
      </div>

      <Button onClick={handleClickConfirm} margin='10px'>
        {loading ? (
          <CircularProgress size={20} color='inherit' />
        ) : (
          intl.formatMessage({
            id: 'forgotPassword.button.confirm',
          })
        )}
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${(props) => forgotPasswordStyles(props)}
`;

const mapStateToProps = ({ forgotPassword }: RootState) => {
  const { username, emailError, loading } = forgotPassword;
  return {
    username,
    emailError,
    loading,
  };
};

export const connector = connect(mapStateToProps, {
  forgotPasswordPropsChanged,
  forgotPasswordConfirm,
  forgotPasswordInitialState,
});

export default connector(ForgotPassword);
