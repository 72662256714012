import React from 'react';
import styled from 'styled-components';
import { buttonStyles } from './button.styles';

export type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  width?: number;
  height?: number;
  background?: string;
  color?: string;
  border?: string;
  margin?: number;
  fontSize?: number;
  onClick?: () => void;
};

const Button = styled.button<any>`
  ${(props) => buttonStyles(props)}
`;

export default Button;
