import { css } from 'styled-components';

export const profileStyles = (props: any) => {
  return css`
    display: flex;
    flex: 1;
    margin-top: 25px;
    gap: 10px;
    //gap: 20px;
    /* flex-direction: column; */
    .password-section {
      display: flex;
      flex-direction: row;
      /* align-items: flex-end; */
      /* align-self: flex-start; */
      .icon {
        position: relative;
        top: 55px;
        left: -40px;
        img {
          height: 18px;
          width: auto;
        }
      }
    }
    .user-crendentials {
      //gap: 50px;
    }
    .server-credentials {
      // gap: 50px;
    }
    .Container {
      display: grid;
      width: 100%;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 20px;
    }
    .email-card-container {
      // display: flex;
      //flex: 1;
      // flex-direction: column;
      display: grid;
      width: 93%;
      grid-template-columns: 1fr 1fr;
      //grid-gap: 20px;
      /* align-items: center; */
      background: #ffffff;
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 20px;
      padding-right: 20px;
      margin-top: 10px;
      border-radius: 20px;
      box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.08);
    }
    .email-Alert-box {
      // display: flex;
      //flex: 1;
      flex-direction: column;
      justify-content: flex-start;
      display: flex;
      width: 100%;
      height: 180px;
      //background: #ffffff;
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 20px;
      padding-right: 0px;
      margin-top: 6rem;
    }
    .message-box {
      background: #ffcbd733;
      padding: 0px 5px 5px 15px;
      border-radius: 5px;
      max-width: 300px;
    }
    .email-card-settings {
      display: flex;
      flex-direction: column;
    }
    .email-card-communication {
      display: flex;
      flex-direction: column;
    }
    .header-buttons-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }

    .password-card-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #ffffff;
      padding: 20px;
      max-width: 370px;
      margin-top: 10px;
      border-radius: 20px;
      box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.08);
    }
    .email-form {
      /* align-self: flex-start; */
      margin-bottom: 20px;
      .manager-email-input {
        width: 53%;
      }
      .mail-credentials {
        display: flex;
        flex-direction: column;
      }
      .mail-credentials div {
        display: inherit;
      }
    }

    .success-message {
      color: green;
      align-self: flex-start;
    }
    .error-message {
      color: red;
      align-self: flex-start;
    }
    .input-text-error {
      width: 310px !important;
      white-space: nowrap;
    }

    .password-form {
      margin-bottom: 55px;
    }
    /* .input-label-style {
      width: 400px;
    } */
    .section-one {
      background: #fff;
      border-radius: 20px;
      box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.08);
      display: flex;
      align-items: flex-start;
      padding-top: 25px;
      padding-left: 20px;
      padding-bottom: 20px;
      margin-top: 20px;
      padding-right: 20px;

      .section-smpt {
        display: flex;
        flex-direction: column;
      }
      .section-ses {
        display: flex;
        flex-direction: column;
      }
    }
    .section-two {
      background: #fff;
      border-radius: 20px;
      box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.08);
      padding-top: 25px;
      padding-left: 30px;
      padding-right: 20px;
      padding-bottom: 5px;
      margin-top: 15px;
    }
    .section-three {
      //background: green;
    }
  `;
};
