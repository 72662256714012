import * as React from 'react';
import styled from 'styled-components';
import Input from '../Input';
import TextArea from '../InputTextArea';
import { getInputLabelStyles } from './inputLabel.styles';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { useIntl } from 'react-intl';

import {
  ProgressCircle,
  OuterCircle,
  InnerCircle,
  StyledDiv,
  InnerDiv,
  PathDescriptor,
  TextWarn,
} from './allStyles';

export type InputLabelProps = {
  type?: string;
  label: string;
  error?: string;
  inputType?: 'textArea' | 'input';
  className?: string;
  name?: string;
  width?: number;
  margin?: string;
  height?: number;
  value?: any;
  placeholder?: string;
  rows?: number;
  disabled?: boolean;
  ref?: any;
  spellCheck?: boolean;
  limit?: number;
  min?: number;
  max?: number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const InputLabel: React.FC<InputLabelProps> = (props) => {
  const intl = useIntl();

  const {
    label,
    error,
    inputType,
    className,
    limit,
    value,
    ...InputProps
  } = props;
  const defaultLimit = limit || 30;
  const percent = value ? Math.floor((value.length * 100) / defaultLimit) : 0;

  const LimitIndicator = () => {
    return (
      <>
        {value && !props.disabled ? (
          <InnerDiv>
            <ProgressCircle viewBox='0 0 36 36'>
              <OuterCircle strokeDasharray='100, 100' d={`${PathDescriptor}`} />
              <InnerCircle
                percent={percent}
                strokeDasharray={`${percent}, 100`}
                d={`${PathDescriptor}`}
              />
            </ProgressCircle>
          </InnerDiv>
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <Wrapper className={className}>
      <span className='label'>{label}</span>
      {inputType === 'textArea' ? (
        <div>
          <TextArea value={value} {...InputProps} />
          {isNaN(value) && limit && <LimitIndicator></LimitIndicator>}
        </div>
      ) : (
        <div>
          <Input value={value} {...InputProps} />
          {isNaN(value) && limit && <LimitIndicator></LimitIndicator>}
        </div>
      )}

      <span className='input-text-error'>
        {percent > 100
          ? intl.formatMessage({ id: 'common.label.maxLengthReached' })
          : error}
      </span>
    </Wrapper>
  );
};

export const PhoneInputLabel: React.FC<any> = (props) => {
  const { label, error, inputType, className, ...InputProps } = props;

  return (
    <Wrapper className={className}>
      <span className='label'>{label}</span>
      <PhoneInput {...InputProps} />
      <span className='input-text-error'>{error}</span>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${(props) => getInputLabelStyles(props)}
`;
export default InputLabel;
