import React from 'react';
import { URL_IMAGE } from '../../apis/config';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
//Style
import styled from 'styled-components';
import { walletDetailStyles, useStyles } from './wallet.styles';
//Material
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';

import CircularProgress from '@material-ui/core/CircularProgress';
//Components
import Table from '../../components/Table';
import SentDemosTable from '../../components/sentDemosTable';

import EmptyData from '../../components/EmptyData';
import WalletModal from '../../components/Modal';
import { useHistory } from 'react-router-dom';

//Actions
import {
  getInactiveCustomersByWallet,
  getCustomersByWallet,
  getCustomerHistory,
  walletPropsChanged,
  walletFilterCustomers,
  deleteCustomer,
  exportClientList,
  importClientList,
  getSentDemos,
  walletFilterInactivesCustomers,
  inactivesCustomersPropsChanged,
  sendSelectedInactivesIds,
} from '../../actions';

//Api
import InactiveClientsModal from '../../components/InactiveClientsModal';
import emptyState from '../../assets/images/emptyState.svg';
import { WalletDetailProps } from './wallet.types';
import { RootState } from '../../reducers';
import Button from '../../commons/Button';
import AddCustomer from '../AddCustomer';
import Text from '../../commons/Text';
import Input from '../../commons/Inputs/Input';
import TablePagination from '@material-ui/core/TablePagination';
import {
  Backdrop,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  FormGroup,
  Menu,
  Switch,
  Typography,
} from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';

const WalletDetail: React.FC<WalletDetailProps> = (props) => {
  const intl = useIntl();
  const [open, setOpen] = React.useState<boolean>(false);
  const [relanceOpen, setRelanceOpen] = React.useState<boolean>(false);
  const [inactiveCustomerOpen, setInactiveCustomerOpen] = React.useState<
    boolean
  >(false);
  const classes = useStyles();
  const history = useHistory();
  const query = new URLSearchParams(props.location.search);
  let page = query.get('page') || 0;
  let size = query.get('size') || 10;
  let firstName = query.get('firstName') || '';
  let lastName = query.get('lastName') || '';
  let email = query.get('email') || '';
  let passKind = query.get('passKind') || '';
  const [sendNotifcation, setSendNotifcation] = React.useState<boolean>(true);
  const {
    wallet,
    loading,
    error,
    select,
    search,
    totalCustomersCount,
    totalLogsCount,
    customers,
    loadingImport,
    loadingExport,
    sentDemos,
    inactives,
    total,
    searchQuery,
    customerIds,
    inactivesCustomersPropsChanged,
  } = props;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    query.delete('page');
    query.append('page', newPage.toString());
    history.push({ search: query.toString() });
    props.getSentDemos(+newPage, +size, {});
    props.getCustomersByWallet(props.match.params.walletId, newPage, size);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    query.delete('size');

    query.append('size', event.target.value.toString());
    history.push({ search: query.toString() });
    props.getCustomersByWallet(
      props.match.params.walletId,
      page,
      event.target.value.toString()
    );
  };

  const [importDialogOpen, setImportDialogOpen] = React.useState<boolean>(
    false
  );
  const [importFile, setImportFile] = React.useState<File[]>([]);
  const [selectOpener, setSelectOpener] = React.useState<boolean>(false);
  const walletIsADemo = wallet?.name.toLocaleLowerCase().includes('demo');

  React.useEffect(() => {
    const searchFilter = { firstName, lastName, email, passKind };
    let selection = '';
    if (firstName) {
      selection = 'firstName';
    } else if (lastName) {
      selection = 'lastName';
    } else if (email) {
      selection = 'email';
    } else if (passKind) {
      selection = 'passKind';
    }
    if (selection) {
      props.walletPropsChanged('select', selection);
    }
    props.walletPropsChanged(
      'search',
      firstName || lastName || email || passKind
    );
    if (walletIsADemo) {
      props.getSentDemos(+page, +size, searchFilter);
    }

    props.getCustomersByWallet(
      props.match.params.walletId,
      page,
      size,
      searchFilter
    );
  }, [loading]);

  React.useEffect(() => {
    const searchInactivesFilter = { firstName, lastName, email };
    let selection = '';
    if (firstName) {
      selection = 'firstName';
    } else if (lastName) {
      selection = 'lastName';
    } else if (email) {
      selection = 'email';
    }
    if (selection) {
      props.walletPropsChanged('select', selection);
    }
    props.walletPropsChanged('search', firstName || lastName || email);
  }, [loading]);

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    query.delete('firstName');
    query.delete('lastName');
    query.delete('email');
    history.push({ search: query.toString() });
    props.walletPropsChanged('search', '');
    props.walletPropsChanged('select', event.target.value);
  };

  const updateQueryParams = (param: string, value: string) => {
    query.delete(param);
    query.append(param, value);
    history.push({ search: query.toString() });
  };

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'search') {
      if (event.target.value.length > 60) return;
      props.walletPropsChanged(event.target.name, event.target.value);
      const val = event.target.value;
      if (val === '') {
        query.delete('firstName');
        query.delete('lastName');
        query.delete('email');
        history.push({ search: query.toString() });
      }
      switch (select) {
        case 'firstName':
          firstName = val;
          updateQueryParams('firstName', val);
          break;
        case 'lastName':
          lastName = val;
          updateQueryParams('lastName', val);

          break;
        case 'email':
          email = val;
          updateQueryParams('email', val);

          break;
        default:
          break;
      }

      const searchFilter = { firstName, lastName, email };

      props.getCustomersByWallet(
        props.match.params.walletId,
        page,
        size,
        searchFilter
      );
    }
    props.walletPropsChanged(event.target.name, event.target.value);
  };

  const exportCustomerList = () => {
    props.exportClientList(wallet?._id, wallet?.name);
  };

  const handleClickSearch = () => {
    props.walletFilterCustomers(select, search, customers);
  };
  const handleClickSearchInactives = () => {
    props.walletFilterInactivesCustomers(select, search, customers);
  };

  const handleClickHistory = (customerId: string) => {
    props.getCustomerHistory(customerId);
  };
  const onSaveChanged = () => {
    return;
  };
  const handleClickDeleteCustomer = (rowId: string) => {
    props.deleteCustomer(rowId, () => onSaveChanged());
  };

  const handleChangeImportFile = (files: Array<File>) => {
    setImportFile([...files]);
  };
  const handleFileSelected = (files: Array<File>) => {
    if (files.length > 0) {
      handleSendNotifcations(sendNotifcation, files[0]);
      setImportDialogOpen(false);
      setImportFile([]);
      //convert blob to string
    }
  };

  const handleSendNotifcations = (sendNotifications: boolean, file: any) => {
    //setNotificationsOpen(false);

    props.importClientList(wallet?._id, file, sendNotifications);
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [relance_is_loading, setIsLoading] = React.useState<any>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const toggleDisplayInactiveCustomersList = () => {
    props.history.push(`${props.match.params.walletId}/inactives`);
  };

  const renderBody = () => {
    if (loading) {
      return (
        <div className={classes.root}>
          <CircularProgress className={classes.circularProgress} />
        </div>
      );
    } else if (error) {
      return (
        <div className={classes.root}>
          <span className='text-error'>{error}</span>
        </div>
      );
    } else if (wallet) {
      return (
        <Wrapper background={wallet.backgroundColor.hex}>
          <Backdrop
            style={{ zIndex: 6000 }}
            open={loadingImport || loadingExport}
          >
            <CircularProgress color='inherit' />
          </Backdrop>
          <div className='div-header'>
            <div className='div-input-filter'>
              <FormControl className='filter-container' fullWidth>
                <Input
                  value={
                    select === 'firstName'
                      ? intl.formatMessage({ id: 'wallet.select.firstName' })
                      : select === 'lastName'
                      ? intl.formatMessage({ id: 'wallet.select.lastName' })
                      : intl.formatMessage({ id: 'wallet.select.email' })
                  }
                  type='text'
                  disabled
                />
                <div
                  className='filter-button'
                  onClick={() => setSelectOpener(!selectOpener)}
                >
                  <h3>
                    {intl.formatMessage({
                      id: 'wallet.label.filter',
                    })}
                  </h3>
                  <ExpandMoreRoundedIcon
                    style={{ color: 'white' }}
                    className={selectOpener ? 'filter-opened' : ''}
                  />
                </div>
                <Select
                  className='select-input '
                  value={select}
                  onChange={handleSelectChange}
                  placeholder={intl.formatMessage({
                    id: 'wallet.label.filter',
                  })}
                  id='filter'
                  inputProps={{ className: 'input' }}
                  open={selectOpener}
                  onClose={() => setSelectOpener(false)}
                >
                  <MenuItem
                    value='firstName'
                    onMouseEnter={(e) =>
                      ((e.target as HTMLInputElement).style.color = '#ff5078')
                    }
                    onMouseLeave={(e) =>
                      ((e.target as HTMLInputElement).style.color = '#000000')
                    }
                  >
                    <span>
                      {intl.formatMessage({ id: 'wallet.select.firstName' })}
                    </span>
                  </MenuItem>
                  <MenuItem
                    value='lastName'
                    onMouseEnter={(e) =>
                      ((e.target as HTMLInputElement).style.color = '#ff5078')
                    }
                    onMouseLeave={(e) =>
                      ((e.target as HTMLInputElement).style.color = '#000000')
                    }
                  >
                    <span>
                      {intl.formatMessage({ id: 'wallet.select.lastName' })}
                    </span>
                  </MenuItem>
                  <MenuItem
                    value='email'
                    onMouseEnter={(e) =>
                      ((e.target as HTMLInputElement).style.color = '#ff5078')
                    }
                    onMouseLeave={(e) =>
                      ((e.target as HTMLInputElement).style.color = '#000000')
                    }
                  >
                    <span>
                      {intl.formatMessage({ id: 'wallet.select.email' })}
                    </span>
                  </MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className='div-input-search'>
              <Input
                value={search}
                background='#fff'
                type='text'
                name='search'
                placeholder={intl.formatMessage({
                  id: 'wallet.button.rechercher',
                })}
                // width={690}
                margin='10px 0'
                className='input-search'
                onChange={handleEventChange}
              />
              <Button
                onClick={handleClickSearch}
                width={120}
                background='#FF5078'
                className='button-search'
              >
                {intl.formatMessage({ id: 'wallet.button.rechercher' })}
              </Button>
            </div>
          </div>
          <WalletModal open={open} onClose={() => setOpen(!open)}>
            <AddCustomer
              walletId={props.match.params.walletId}
              onSaveChanged={() => setOpen(!open)}
            />
          </WalletModal>

          <div className='div-container'>
            <div className='table-header'>
              <Text
                color='#404040'
                fontSize={24}
                lineHeight={36}
                fontWeight={600}
              >
                {intl.formatMessage({
                  id: !walletIsADemo
                    ? 'wallet.label.customersList'
                    : 'wallet.label.sentDemosList',
                })}
              </Text>{' '}
              {!walletIsADemo && (
                <div className={classes.wrapper}>
                  <Button
                    onClick={() => {
                      setOpen(!open);
                    }}
                  >
                    {intl.formatMessage({ id: 'wallet.add' })}
                  </Button>
                  <Button
                    aria-controls='simple-menu'
                    aria-haspopup='true'
                    onClick={handleClick}
                  >
                    {intl.formatMessage({ id: 'wallet.label.settings' })}
                    <ExpandMoreRoundedIcon
                      style={{
                        color: 'white',
                        marginLeft: '10px',
                        marginRight: '-20px',
                      }}
                      className={selectOpener ? 'filter-opened' : ''}
                    />
                  </Button>
                  <Menu
                    id='simple-menu'
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem
                      disabled={loadingImport}
                      onClick={() => setImportDialogOpen(true)}
                    >
                      {intl.formatMessage({ id: 'wallet.button.import' })}
                    </MenuItem>
                    {loadingExport && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                    <MenuItem
                      onClick={exportCustomerList}
                      disabled={loadingExport}
                    >
                      {intl.formatMessage({ id: 'wallet.button.export' })}
                    </MenuItem>
                    <MenuItem onClick={toggleDisplayInactiveCustomersList}>
                      {intl.formatMessage({ id: 'wallet.button.relance' })}
                    </MenuItem>
                  </Menu>

                  <InactiveClientsModal
                    getInactiveCustomersByWallet={
                      props.getInactiveCustomersByWallet
                    }
                    setRelanceOpen={setRelanceOpen}
                    setInactiveCustomerOpen={setInactiveCustomerOpen}
                    inactivesCustomersPropsChanged={
                      inactivesCustomersPropsChanged
                    }
                    size={props.inactiveSize}
                    total={props.inactiveTotal}
                    page={props.inactivePage}
                    firstName={props.inactiveFirstName}
                    lastName={props.inactiveLastName}
                    email={props.inactiveEmail}
                    walletId={props.match.params.walletId}
                    {...{
                      inactiveCustomerOpen,
                      inactives,
                      relanceOpen,
                      searchQuery,
                      selectedId: props.selectedId,
                      loading: props.inactiveLoading,
                    }}
                  ></InactiveClientsModal>
                  <Dialog open={importDialogOpen}>
                    <DialogContent>
                      <Box className={classes.importDialog}>
                        <Box>
                          <a href={`${URL_IMAGE}/static/template.csv`}>
                            {intl.formatMessage({
                              id: 'wallet.label.downloadTemplate',
                            })}
                          </a>
                        </Box>
                        <Divider style={{ margin: '10px 0 10px 0' }} />

                        <Box>
                          <Typography
                            style={{ margin: '10px 0 10px 0' }}
                            variant='h6'
                          >
                            {intl.formatMessage({
                              id: 'wallet.label.importFile',
                            })}
                          </Typography>
                          <DropzoneArea
                            dropzoneText={intl.formatMessage({
                              id: 'wallet.lable.importFileDropZone',
                            })}
                            acceptedFiles={['text/csv', '.csv']}
                            onChange={handleChangeImportFile}
                          />
                        </Box>
                        {loadingImport && (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgressImport}
                          />
                        )}
                      </Box>
                    </DialogContent>
                    <DialogActions>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={sendNotifcation}
                              onChange={() => {
                                setSendNotifcation(!sendNotifcation);
                              }}
                              name='showBarcode'
                              size='medium'
                            />
                          }
                          label={intl.formatMessage({
                            id: 'wallet.label.sendNotifications',
                          })}
                        />
                      </FormGroup>
                      <Button
                        onClick={() => setImportDialogOpen(false)}
                        disabled={loadingImport}
                      >
                        {intl.formatMessage({ id: 'wallet.button.cancel' })}
                      </Button>
                      <Button
                        disabled={loadingImport}
                        onClick={() => handleFileSelected(importFile)}
                      >
                        {intl.formatMessage({ id: 'wallet.button.import' })}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              )}
            </div>

            <EmptyData
              data={[...wallet.customers, sentDemos]}
              loading={loading}
              message='La liste des clients est vide'
              image={emptyState}
            >
              {walletIsADemo ? (
                <SentDemosTable data={sentDemos}></SentDemosTable>
              ) : (
                <Table
                  data={wallet.customers}
                  handleClickHistory={handleClickHistory}
                  onClickDelete={handleClickDeleteCustomer}
                ></Table>
              )}
            </EmptyData>

            {totalCustomersCount + totalLogsCount > 0 && (
              <TablePagination
                count={walletIsADemo ? totalLogsCount : totalCustomersCount}
                page={+page}
                onChangePage={handleChangePage}
                rowsPerPage={+size}
                rowsPerPageOptions={[10, 25, 50, 100]}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={'Lignes par page'}
              />
            )}
          </div>
        </Wrapper>
      );
    }
  };

  return (
    <React.Fragment>
      {/* <Header history={props.history}></Header> */}
      {renderBody()}
    </React.Fragment>
  );
};

type WrapperProps = {
  background?: string;
};

const Wrapper = styled('div')<WrapperProps>`
  ${(props) => walletDetailStyles(props)}
`;

const mapStateToProps = ({
  walletDetail,
  inactivesCustomer,
  demos,
}: RootState) => {
  const {
    inactives,
    total,
    customerIds,
    searchQuery,
    selectedId,
    sendAll,
  } = inactivesCustomer;

  const {
    wallet,
    customers,
    loading,
    totalCustomersCount,
    search,
    select,
    communicationChannels,
    error,
    snackMessageSuccess,
    snackMessageError,
    loadingImport,
    loadingExport,
    totalDeleted,
    totalActive,
    totalInactive,
  } = walletDetail;

  return {
    sentDemos: demos.sentDemosList,
    totalLogsCount: demos.total,
    inactivePage: inactivesCustomer.page,
    inactiveSize: inactivesCustomer.size,
    inactiveTotal: inactivesCustomer.total,
    inactiveFirstName: inactivesCustomer.firstName,
    inactiveLastName: inactivesCustomer.lastName,
    inactiveEmail: inactivesCustomer.email,
    inactiveLoading: inactivesCustomer.loading,
    wallet,
    customers,
    loading,
    totalCustomersCount,
    search,
    select,
    communicationChannels,
    error,
    snackMessageSuccess,
    snackMessageError,
    loadingImport,
    loadingExport,
    totalDeleted,
    totalActive,
    totalInactive,
    inactives,
    total,
    customerIds,
    selectedId,
    sendAll,
  };
};

export const connector = connect(mapStateToProps, {
  getCustomersByWallet,
  getInactiveCustomersByWallet,
  getCustomerHistory,
  walletPropsChanged,
  walletFilterCustomers,
  deleteCustomer,
  exportClientList,
  importClientList,
  getSentDemos,
  walletFilterInactivesCustomers,
  inactivesCustomersPropsChanged,
  sendSelectedInactivesIds,
});

export default connector(WalletDetail);
