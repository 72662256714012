import React from 'react';
import { useIntl } from 'react-intl';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import ConfirmationModal from '../../Modal';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import ComButton from '../../../commons/Button';
//Styles
import { useStyles } from './hotelCard.styles';
import logo from '../../../assets/images/logo-BestWallet.png';
import { Divider } from '@material-ui/core';
import { HotelPassApiResponse } from '../../../apis/ApiTypes';
import { URL_IMAGE } from '../../../apis/config';
import moment from 'moment';
import Text from '../../../commons/Text';
import Switch from '@material-ui/core/Switch';
import clsx from 'clsx';
// import HotelCard from '../../../icons/HotelCard';

type HotelCardProps = {
  hotel: HotelPassApiResponse;
  onClickRedirect: () => void;
  // onClickEdit: (id: string) => void;
  //onClickSend: (value: string) => void;
  onClickDelete: (hotelId: string) => void;
  //loading: boolean;
  // hotelId: string;
};

const HotelCard: React.FC<HotelCardProps> = (props) => {
  const intl = useIntl();
  const classes = useStyles();
  const {
    hotel,
    onClickRedirect,
    onClickDelete,
    // onClickEdit,
    //onClickSend,
    //loading,
    //hotelId,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [is_open, setIsOpen] = React.useState<any>(null);

  const [is_loading, setIsLoading] = React.useState<any>(null);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <Card className={classes.root}>
      <div className={clsx(classes.cardMenu, classes.cardPosition)}>
        <IconButton
          aria-label='more'
          aria-controls='long-menu'
          aria-haspopup='true'
          className={classes.iconButton}
          onClick={handleClick}
          style={{
            background: '#FF5078',
            padding: '2px',
          }}
        >
          <MoreHorizIcon fontSize='small' className={classes.menuIcon} />
        </IconButton>
        <Menu
          id='simple-menu'
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: -50 }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          keepMounted
          open={Boolean(anchorEl)}
          onClick={handleClose}
          className={classes.cardPosition}
        >
          <MenuItem
            onClick={() => {
              setIsOpen(true);
            }}
            onMouseEnter={(e) =>
              ((e.target as HTMLInputElement).style.color = '#FF5078')
            }
            onMouseLeave={(e) =>
              ((e.target as HTMLInputElement).style.color = '#000000')
            }
          >
            {intl.formatMessage({ id: 'settings.button.delete' })}
          </MenuItem>
        </Menu>
        <ConfirmationModal open={is_open}>
          <div className='modal-cntr'>
            <span style={{ marginTop: '15 px' }}>
              Voulez vous Supprimer le hotel ?{' '}
            </span>
            {is_open ? (
              <div
                style={{
                  display: 'flex',
                  marginTop: '10px',
                  justifyContent: 'space-evenly',
                }}
              >
                <ComButton
                  styled='secondary'
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  {intl.formatMessage({ id: 'settings.button.cancel' })}
                </ComButton>

                <ComButton
                  styled='primary'
                  onClick={() => {
                    onClickDelete(hotel._id);
                  }}
                >
                  {is_loading ? (
                    <CircularProgress size={20} color='inherit' />
                  ) : (
                    ''
                  )}
                  {intl.formatMessage({ id: 'resetPassword.button.confirm' })}{' '}
                </ComButton>
              </div>
            ) : (
              ''
            )}
          </div>
        </ConfirmationModal>
      </div>
      <div onClick={() => onClickRedirect()}>
        {/* <Divider /> */}
        <CardContent className={classes.cardContent}>
          <div className={classes.cardItemContent}>
            <div
              className={classes.cardItem}
              style={{ marginTop: '-25px', marginBottom: '10px' }}
            >
              {/* <Text
                color='#828282'
                fontSize={14}
                lineHeight={21}
                fontWeight={500}
              >
                {intl.formatMessage({ id: 'hotels.select.name' })}
              </Text> */}
              <Text
                color='#404040'
                fontSize={16}
                lineHeight={21}
                fontWeight={600}
              >
                {hotel.name ||
                  `${intl.formatMessage({ id: 'hotels.select.name' })}`}
              </Text>
            </div>
          </div>

          <div className={classes.cardLogo}>
            <CardMedia
              className={classes.media}
              image={`${URL_IMAGE}/${hotel.hotelPictureUrl}`}
              title='strip'
            />
          </div>
          <div className={classes.cardItemID}>
            <Text
              color='#828282'
              fontSize={14}
              lineHeight={21}
              fontWeight={500}
              style={{ marginRight: '5px' }}
            >
              ID:
            </Text>
            <Text
              color='#404040'
              fontSize={14}
              lineHeight={21}
              fontWeight={500}
            >
              {hotel._id}
            </Text>
          </div>
          <div
            className={classes.cardLogo}
            style={{ justifyContent: 'space-between', paddingTop: '4px' }}
          >
            <div className={classes.cardItem}>
              <Text
                color='#828282'
                fontSize={14}
                lineHeight={21}
                fontWeight={500}
              >
                N°:
              </Text>
              <Text
                color='#404040'
                fontSize={14}
                lineHeight={21}
                fontWeight={500}
              >
                {hotel.number}
              </Text>
            </div>
            <div className={classes.cardItem}>
              <Text
                color='#828282'
                fontSize={14}
                lineHeight={21}
                fontWeight={500}
              >
                Type:
              </Text>
              <Text
                color='#404040'
                fontSize={14}
                lineHeight={21}
                fontWeight={500}
              >
                {hotel.type}
              </Text>
            </div>
          </div>
        </CardContent>
      </div>
    </Card>
  );
};

export default HotelCard;
