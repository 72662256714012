import {
  LOGIN_FORM_PROPS_CHANGED,
  LOGINING_SUCCESS,
  LOGINING_FAILED,
  LOGINING_INITIAL_STATE,
} from '../actions/types';
import { LoginActionTypes } from '../modules/Login/login.types';

const INITIAL_STATE = {
  username: '',
  password: '',
  error: '',
  loading: false,
};

export default (state = INITIAL_STATE, action: LoginActionTypes) => {
  switch (action.type) {
    case LOGIN_FORM_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };
    case LOGINING_SUCCESS:
      return { ...state, error: '', loading: false };
    case LOGINING_FAILED:
      return { ...state, error: action.payload, loading: false };
    case LOGINING_INITIAL_STATE:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
