import { ColorProps, FerryState } from './ferry.types';
import moment from 'moment';

export const validateCustomerFieldsFerry = (state: any) => {
  let newState: any = Object.assign({}, state);

  if (!newState.departureDate) {
    newState.departureDateError = 'ferry.error.departureDateRequired';
  } else {
    newState.departureDateError = '';
  }

  if (!newState.arrivalDate) {
    newState.arrivalDateError = 'ferry.error.arrivalDateRequired';
  } else {
    newState.arrivalDateError = '';
  }
  if (!newState.passengerType) {
    newState.passengerTypeError = 'ferry.error.passengerTypeRequired';
  } else {
    newState.passengerTypeError = '';
  }
  if (!newState.tripType) {
    newState.tripTypeError = 'ferry.error.tripTypeRequired';
  } else {
    newState.tripTypeError = '';
  }

  let valid = false;
  if (
    newState.departureDateError === '' &&
    newState.arrivalDateError === '' &&
    newState.passengerTypeError === '' &&
    newState.tripTypeError === ''
  ) {
    valid = true;
  }
  return { newState, valid };
};
