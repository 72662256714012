import {
  SETTINGS_PROPS_CHANGED,
  SETTINGS_GET_WALLET_SETTINGS,
  SETTINGS_GET_WALLET_DETAIL,
  SETTINGS_INITIAL_STATE,
  SETTINGS_GET_WALLET_FORM,
} from '../actions/types';
import {
  SettingsState,
  SettingsActionTypes,
} from '../modules/Settings/settings.types';

const INITIAL_STATE: SettingsState = {
  wallet: null,
  walletDetails: null,
  successMessage: '',
  emailMessage: '',
  newOfferEmailMessage: '',
  newOfferNotificationMessage: '',
  newCouponEmailMessage: '',
  newCouponNotificationMessage: '',
  expiredOfferEmailMessage: '',
  expiredOfferNotificationMessage: '',
  newUpdateEmailMessage: '',
  newUpdateNotificationMessage: '',
  enableNewUpdateEmailMessage: false,
  enableOfferEmailMessage: false,
  enableOfferSMSMessage: false,
  showBarcode: true,
  hasSMSAccount: false,
  smsCredits: -1,
  enableCouponEmailMessage: false,
  enableCouponSMSMessage: false,
  communicationChannels: '',
  walletForm: {},
  smsTitle: '',
  smsTitleError: '',
  newAccountSMSMessage: '',
  newEventSMSMessage: '',
  newOfferSMSMessage: '',
  expiredOfferSMSMessage: '',
  newCouponSMSMessage: '',
  newUpdateSMSMessage: '',
  newAccountSMSMessageError: '',
  newCouponSMSMessageError: '',
  newUpdateNotificationMessageError: '',
  newOfferSMSMessageError: '',
  newCouponEmailMessageError: '',
  newOfferNotificationMessageError: '',
  newCouponNotificationMessageError: '',
  expiredOfferNotificationMessageError: '',
  loadingSaveCommunicationChannelsError: false,
  hotelEmailSubscriptionMessage: '',
  cinemaEmailSubscriptionMessage: '',
  transitEmailSubscriptionMessage: '',
  flightEmailSubscriptionMessage: '',
  busEmailHtmlCode: '',
  trainEmailHtmlCode: '',
  boatEmailHtmlCode: '',
  hotelEmailHtmlCode: '',
  cinemaEmailHtmlCode: '',
  flightEmailHtmlCode: '',
  eventEmailHtmlCode: '',
  newAccountEmailHtmlCode: '',
  couponEmailHtmlCode: '',
  offerEmailHtmlCode: '',
  storeCardUpdateEmailHtmlCode: '',
  couponUpdateEmailHtmlCode: '',
  eventUpdateEmailHtmlCode: '',
  flightUpdateEmailHtmlCode: '',
  cinemaUpdateEmailHtmlCode: '',
  hotelUpdateEmailHtmlCode: '',
  boatUpdateEmailHtmlCode: '',
  trainUpdateEmailHtmlCode: '',
  busUpdateEmailHtmlCode: '',
  //Loading
  loading: false,
  loadingSaveCommunicationChannels: false,

  //storecard
  enableStorecardRemainderEmailMessage: false,
  enableNewStorecardSMSMessage: false,
  enableStorecardRemainderSMSMessage: false,
  enableNewUpdateNotification: false,
  //event
  enableUpdateEventEmailMessage: false,
  enableUpdateEventNotification: false,
  //coupon
  enableUpdateCouponEmailMessage: false,
  enableWelcomeCouponEmailMessageForExternalClients: false,
  enableWelcomeCouponEmailMessageForInternalClients: false,
  enableWelcomeCouponEmailMessageForCRMClients: false,
  enableUpdateCouponSMSMessage: false,
  enableExpiredCouponSMSMessage: false,
  enableWelcomeCouponSMSMessageForExternalClients: false,
  enableWelcomeCouponSMSMessageForInternalClients: false,
  enableWelcomeCouponSMSMessageForCRMClients: false,
  enableWelcomeCouponNotificationMessageForExternalClients: false,
  enableWelcomeCouponNotificationMessageForInternalClients: false,
  enableWelcomeCouponNotificationMessageForCRMClients: false,
  enableUpdateCouponNotificationMessage: false,
  enableNewCouponNotificationMessage: false,
  enableNewStorecardEmailMessage: false,
  enableExpiredOfferEmailMessage: false,
  enableNewOfferNotificationMessage: false,
  enableExpiredOfferNotificationMessage: false,
  enableExpiredOfferSMSMessage: false,
  enableStorecardReminderEmailMessage: false,
  enableStorecardReminderSMSMessage: false,
};

export default (state = INITIAL_STATE, action: SettingsActionTypes) => {
  switch (action.type) {
    case SETTINGS_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };

    case SETTINGS_GET_WALLET_SETTINGS:
      return {
        ...state,
        ...action.payload,
      };

    case SETTINGS_GET_WALLET_DETAIL:
      return {
        ...state,
        wallet: action.payload,
      };

    case SETTINGS_GET_WALLET_FORM:
      return {
        ...state,
        walletForm: action.payload,
      };

    case SETTINGS_INITIAL_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};
