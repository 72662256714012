import moment, { Moment } from 'moment';
import {
  getRailsList,
  getRailsChildrenList,
  removeCustomerRail,
} from '../apis/railsApi';
import { removeRail } from '../apis/railApi';

import {
  RAILS_GET_LIST_RAILS,
  RAILS_INITIAL_STATE,
  AppThunk,
  RAILS_PROPS_CHANGED,
  RAILS_FILTER_RAILS,
  RAILS_DELETE_RAIL_LOADING,
  RAILS_DELETE_RAIL,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
  RAILS_DELETE_CUSTOMER_RAIL,
  RAILS_GET_SENT_RAILS,
} from './types';
import { TransitsApiResponse } from '../apis/ApiTypes';
import {
  RailsChangedAction,
  RailsInitialStateAction,
} from '../modules/Rails/rails.types';

export const railsGetAllRails = (walletId: string): AppThunk => (dispatch) => {
  getRailsList(walletId).then((res: Array<TransitsApiResponse>) => {
    dispatch({
      type: RAILS_GET_LIST_RAILS,
      payload: res,
    });
  });
};

export const railsPropsChanged = (
  prop: string,
  value: any
): RailsChangedAction => {
  return { type: RAILS_PROPS_CHANGED, prop, value };
};

export const railsFilterRails = (
  status: string,
  allTime: boolean,
  startDate: Moment | null,
  endDate: Moment | null,
  rails: Array<TransitsApiResponse>
): AppThunk => (dispatch) => {
  let newRails: Array<TransitsApiResponse> = Object.assign([], rails);
  const result: Array<TransitsApiResponse> = [];
  let dateStart = `${moment(startDate).format('YYYY-MM-DD')}T00:00:00.000Z`;
  let dateEnd = `${moment(endDate).format('YYYY-MM-DD')}T00:00:00.000Z`;

  if (startDate && endDate && startDate.isAfter(endDate, 'day')) {
    dispatch({
      type: RAILS_PROPS_CHANGED,
      prop: 'endDateError',
      value: 'offer.error.endDateBeforeStartDate',
    });
  } else {
    dispatch({
      type: RAILS_PROPS_CHANGED,
      prop: 'endDateError',
      value: '',
    });

    // if (allTime) {
    //   if (status === 'ALL') {
    //     dispatch({
    //       type: RAILS_FILTER_RAILS,
    //       allTime,
    //       payload: newRails,
    //     });
    //   } else {
    //     newRails.forEach((item) => {
    //       if (item.status === status) {
    //         result.push(item);
    //       }
    //     });
    //     dispatch({
    //       type: RAILS_FILTER_RAILS,
    //       allTime,
    //       payload: result,
    //     });
    //   }
    // } else if (startDate && endDate) {
    //   if (status === 'ALL') {
    //     newRails.forEach((item) => {
    //       if (
    //         moment(item.departureTime).isSameOrAfter(dateStart) &&
    //         moment(item.arrivalTime).isSameOrBefore(dateEnd)
    //       ) {
    //         result.push(item);
    //       }
    //     });
    //     dispatch({
    //       type: RAILS_FILTER_RAILS,
    //       allTime,
    //       payload: result,
    //     });
    //   } else {
    //     newRails.forEach((item) => {
    //       if (
    //         item.status === status &&
    //         moment(item.departureTime).isSameOrAfter(dateStart) &&
    //         moment(item.arrivalTime).isSameOrBefore(dateEnd)
    //       ) {
    //         result.push(item);
    //       }
    //     });

    //     dispatch({
    //       type: RAILS_FILTER_RAILS,
    //       allTime,
    //       payload: result,
    //     });
    //   }
    // } else {
    //   if (status === 'ALL') {
    //     dispatch({
    //       type: RAILS_FILTER_RAILS,
    //       allTime,
    //       payload: newRails,
    //     });
    //   } else {
    //     newRails.forEach((item) => {
    //       if (item.status === status) {
    //         result.push(item);
    //       }
    //     });
    //     dispatch({
    //       type: RAILS_FILTER_RAILS,
    //       allTime,
    //       payload: result,
    //     });
    //   }
    // }

    if (startDate) {
      newRails.forEach((item) => {
        if (moment(item.departureTime).isSameOrAfter(moment(dateStart))) {
          result.push(item);
        }
      });

      dispatch({
        type: RAILS_FILTER_RAILS,
        allTime,
        payload: result,
      });
    } else if (startDate && endDate) {
      newRails.forEach((item) => {
        if (
          moment(item.departureTime).isSameOrAfter(moment(dateStart)) &&
          moment(item.arrivalTime).isSameOrBefore(moment(dateEnd))
        ) {
          result.push(item);
        }
      });

      dispatch({
        type: RAILS_FILTER_RAILS,
        allTime,
        payload: result,
      });
    } else {
      dispatch({
        type: RAILS_FILTER_RAILS,
        allTime,
        payload: newRails,
      });
    }
  }
};

export const deleteRail = (railId: string, walletId: string): AppThunk => (
  dispatch
) => {
  dispatch({
    type: RAILS_DELETE_RAIL_LOADING,
    payload: true,
  });
  removeRail(railId, walletId).then((res) => {
    if (res.data.status === 'success') {
      dispatch({
        type: RAILS_DELETE_RAIL,
        payload: railId,
      });
      dispatch({
        type: SNACKBAR_SUCCESS,
        value: 'transit.success.deleted',
      });
    } else
      dispatch({
        type: SNACKBAR_ERROR,
        value: 'wallet.error.occured',
      });
  });
};

export const railsGetChildrens = (
  walletId: string,
  railId: string,
  page: number | string,
  size: number | string,
  searchFilter: any
): AppThunk => (dispatch) => {
  getRailsChildrenList(walletId, railId, page, size, searchFilter).then(
    (res: any) => {
      if (res.status === 500) {
        dispatch({
          type: SNACKBAR_ERROR,
          value: 'wallet.error.occured',
        });
      } else if (res.status === 401) {
        dispatch({
          type: SNACKBAR_ERROR,
          value: 'Id malformés',
        });
        railsPropsChanged('loading', false);
      } else
        dispatch({
          type: RAILS_GET_SENT_RAILS,
          payload: res.data,
          total: res.total,
        });
    }
  );
};

export const deleteCustomerRail = (
  railId: string,
  customerId: string,
  passId: string,
  walletId: string
): AppThunk => (dispatch) => {
  dispatch({
    type: RAILS_DELETE_RAIL_LOADING,
    payload: true,
  });
  removeCustomerRail(railId, customerId, walletId).then((res) => {
    if (res.data.status === 'success') {
      dispatch({
        type: RAILS_DELETE_CUSTOMER_RAIL,
        payload: passId,
      });

      dispatch({
        type: SNACKBAR_SUCCESS,
        value: 'transit.success.deleted',
      });
    } else {
      dispatch({
        type: SNACKBAR_ERROR,
        value: 'wallet.error.occured',
      });
    }
  });
};

export const railsInitialState = (): RailsInitialStateAction => {
  return { type: RAILS_INITIAL_STATE };
};
