import moment from 'moment';
import {
  CUSTOMER_FERRY_FORM_PROPS_CHANGED,
  EDIT_FERRY_INITIAL_STATE,
  FERRY_FORM_PROPS,
  FERRY_FORM_PROPS_CHANGED,
  FERRY_GET_FERRY_PROPS,
  FERRY_INITIAL_STATE,
  CUSTOMER_FERRY_FORM_PROPS,
} from '../actions/types';
import { FerryActionTypes, FerryState } from '../modules/Ship/ship.types';

const INITIAL_STATE: Partial<FerryState> = {
  loading: false,
  passengerType: '',
  tripType: '',
  departureDate: moment(),
  arrivalDate: moment(),
  customerId: '',
  ferryId: '',
  //errors
  passengerTypeError: '',
  tripTypeError: '',
  departureDateError: '',
  arrivalDateError: '',
};

export default (state = INITIAL_STATE, action: FerryActionTypes) => {
  switch (action.type) {
    case CUSTOMER_FERRY_FORM_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };
    case CUSTOMER_FERRY_FORM_PROPS:
      return { ...state, ...action.newState, loading: false };
    default:
      return state;
  }
};
