import {
  TRAMS_GET_LIST_TRAMS,
  TRAMS_INITIAL_STATE,
  TRAMS_ADD_TRAM_TO_LIST,
  TRAMS_PROPS_CHANGED,
  TRAMS_FILTER_TRAMS,
  TRAMS_DELETE_TRAM,
  TRAMS_DELETE_TRAM_LOADING,
  TRAMS_GET_SENT_TRAMS,
} from '../actions/types';
import { TramsActionTypes, TramsState } from '../modules/Trams/trams.types';
import { TransitsApiResponse } from '../apis/ApiTypes';

const INITIAL_STATE: any = {
  tramsList: [],
  loading: true,
  tramsFilterList: [],
  startDate: null,
  endDate: null,
  allTime: false,
  //error
  startDateError: '',
  endDateError: '',
  search: '',
  select: '',
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case TRAMS_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };
    case TRAMS_FILTER_TRAMS:
      if (action.allTime) {
        return {
          ...state,
          startDate: null,
          endDate: null,
          tramsList: action.payload,
        };
      }
      return {
        ...state,
        tramsList: action.payload,
      };
    case TRAMS_GET_LIST_TRAMS:
      return {
        ...state,
        tramsList: action.payload,
        tramsFilterList: action.payload,
        loading: false,
      };
    case TRAMS_INITIAL_STATE:
      return { ...INITIAL_STATE };
    case TRAMS_ADD_TRAM_TO_LIST:
      let tramsSortedByStartDate: Array<TransitsApiResponse> = Object.assign(
        [],
        state.tramsList
      );

      tramsSortedByStartDate = [...state.tramsList, ...[action.payload]];

      return {
        ...state,
        tramsList: [
          ...tramsSortedByStartDate.sort(
            (a, b) => <any>new Date(a.createdAt) - <any>new Date(b.createdAt)
          ),
        ],
      };
    case TRAMS_DELETE_TRAM_LOADING: {
      return { ...state, loading: true };
    }
    case TRAMS_DELETE_TRAM:
      var newTramsList = state.tramsList.filter(function (item: any) {
        return item._id !== action.payload;
      });
      return { ...state, loading: false, tramsList: newTramsList };
    case TRAMS_GET_SENT_TRAMS: {
      return {
        ...state,
        tramsList: action.payload,
        total: action.total,
        loading: false,
      };
    }

    default:
      return state;
  }
};
