import { css } from 'styled-components';

export const CustomerInscriptionStyles = (props: any) => {
  return css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10px;
      width: 90%;
      max-width: 500px;
      background: #ffffff;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
      .applinks-cntr {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width: 50%;
        padding: 10px;
        border: none;
        img {
          padding-right: 10px;
          width: auto;
          height: 40px;
        }
      }
    }
  `;
};
