import {
  EVENT_FORM_PROPS,
  EVENT_FORM_PROPS_CHANGED,
  AppThunk,
  EVENTS_ADD_EVENT_TO_LIST,
  EVENT_INITIAL_STATE,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
  EVENTS_DELETE_EVENT,
  EVENTS_DELETE_EVENT_LOADING,
  CINEMATICKETS_DELETE_CINEMATICKET,
  CINEMATICKETS_DELETE_CINEMATICKET_LOADING,
} from './types';
import {
  EventInitialStateAction,
  EventPropsChangedAction,
  EventState,
} from '../modules/CreateEvent/createEvent.types';

import { URL_IMAGE } from '../apis/config';
import {
  validateFieldsCinemaTicket,
  newCinemaTicketObject,
} from '../modules/CreateCinemaTicket/createEvent.utils';
import {
  createCinemaTicket,
  removeCinemaTicket,
} from '../apis/cinemaTicketApi';

export const cinemaTicketPropsChanged = (
  prop: string,
  value: any
): EventPropsChangedAction => {
  return { type: EVENT_FORM_PROPS_CHANGED, prop, value };
};

export const addCinemaTicket = (
  walletId: string | null,
  props: any,
  callback: () => void
): AppThunk => (dispatch) => {
  const { newState, valid } = validateFieldsCinemaTicket(props);
  dispatch({ type: EVENT_FORM_PROPS, newState });
  const newCinemaTicket = newCinemaTicketObject(newState);

  if (valid) {
    dispatch({
      type: EVENT_FORM_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });

    createCinemaTicket(walletId, newCinemaTicket).then((res: any) => {
      if (res.status == 200) {
        dispatch({
          type: EVENTS_ADD_EVENT_TO_LIST,
          payload: res.data,
        });
        dispatch({
          type: SNACKBAR_SUCCESS,
          value: 'cinema.success.added',
        });
        callback();
      } else {
        dispatch({
          type: SNACKBAR_ERROR,
          value: 'wallet.error.occured',
        });
        dispatch({
          type: EVENT_FORM_PROPS_CHANGED,
          prop: 'loading',
          value: false,
        });
      }
    });
  }
};

export const deleteCinemaTicket = (
  cinemaTicketId: string,
  walletId: string
): AppThunk => (dispatch) => {
  dispatch({
    type: CINEMATICKETS_DELETE_CINEMATICKET_LOADING,
    payload: true,
  });
  removeCinemaTicket(cinemaTicketId, walletId).then((res) => {
    if (res.data.status === 'success') {
      dispatch({
        type: CINEMATICKETS_DELETE_CINEMATICKET,
        payload: cinemaTicketId,
      });
      dispatch({
        type: SNACKBAR_SUCCESS,
        value: 'cinema.success.deleted',
      });
    } else
      dispatch({
        type: SNACKBAR_ERROR,
        value: 'wallet.error.occured',
      });
  });
};

export const cinemaTicketInitialState = (): EventInitialStateAction => {
  return { type: EVENT_INITIAL_STATE };
};
