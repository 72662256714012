import { addCustumerToWallet } from '../apis/addCustomerApi';
import {
  ADD_CUSTOMER_PROPS_CHANGED,
  ADD_CUSTOMER_FORM_CUSTOMER,
  ADD_CUSTOMER_INITIAL_STATE,
  AppThunk,
  ADD_CUSTOMER_TO_CUSTOMERS_LIST,
  SNACKBAR_SUCCESS,
  SNACKBAR_ERROR,
} from './types';
import { validateFieldsAddCustomer } from '../modules/AddCustomer/addCustomer.utils';

import {
  AddCustomerState,
  AddCustomerPropsChangedAction,
  AddCustomerInitialStateAction,
} from '../modules/AddCustomer/addCustomer.types';

export const addCustomerPropsChanged = (
  prop: string,
  value: any
): AddCustomerPropsChangedAction => {
  return { type: ADD_CUSTOMER_PROPS_CHANGED, prop, value };
};

export const addCustomer = (
  walletId: string,
  props: AddCustomerState,
  callback: () => void
): AppThunk => (dispatch) => {
  const { newProps, valid } = validateFieldsAddCustomer(props);

  // if (
  //   newProps.phoneNumber.toString().length > 0 &&
  //   newProps.phoneNumber.toString().charAt(0) !== '+'
  // ) {
  //   newProps.phoneNumber = '+' + newProps.phoneNumber.toString();
  // }

  dispatch({ type: ADD_CUSTOMER_FORM_CUSTOMER, newProps });

  if (valid) {
    dispatch({
      type: ADD_CUSTOMER_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });
    addCustumerToWallet(walletId, newProps).then((res) => {
      dispatch({
        type: ADD_CUSTOMER_PROPS_CHANGED,
        prop: 'loading',
        value: false,
      });
      if (res.data.status === 'success') {
        let load = Object.assign(res.data.response.data.newCustomerResult, {
          status: 'inactif',
          isDeleted: false,
          devices: null,
        });
        dispatch({
          type: ADD_CUSTOMER_TO_CUSTOMERS_LIST,
          payload: [
            {
              ...load,
              history: [],
              loading: false,
            },
          ],
        });
        dispatch({
          type: SNACKBAR_SUCCESS,
          value: 'user.success.added',
        });
        callback();
        return;
      }

      if (res.status === 400) {
        let errorMessage = '';
        if (
          res.data.response?.data.smsStatus == false &&
          res.data.response?.data.emailSentStatus == false
        ) {
          errorMessage = 'emailsms.error.failedSent';
        }

        if (
          res.data.response?.data.smsStatus == false &&
          res.data.response?.data.emailSentStatus == null
        ) {
          errorMessage = 'sms.error.failedSent';
        }

        if (
          res.data.response?.data.smsStatus == null &&
          res.data.response?.data.emailSentStatus == false
        ) {
          errorMessage = 'email.error.failedSent';
        }

        if (res.data.includes('credit not available')) {
          errorMessage = 'sms.error.insuffisantCredit';
        }

        if (res.data.includes('phoneNumber already')) {
          errorMessage = 'phone.error.alreadyExists';
        }

        if (res.data.includes('email:')) {
          errorMessage = 'email.error.alreadyExists';
        }

        if (res.data.includes('firstName')) {
          errorMessage = 'addCustomer.error.firstNameLettersOnly';
          if (res.data.includes('length')) {
            errorMessage = 'addCustomer.error.firstNameMaxLength';
          }
        }

        if (res.data.includes('lastName')) {
          errorMessage = 'addCustomer.error.lastNameLettersOnly';
          if (res.data.includes('length')) {
            errorMessage = 'addCustomer.error.lastNameMaxLength';
          }
        }
        if (res.data.includes('error getting credits')) {
          errorMessage = 'sms.error.failedCredits';
        }

        if (errorMessage) {
          dispatch({
            type: ADD_CUSTOMER_PROPS_CHANGED,
            prop: 'emailBarcodeError',
            value: errorMessage,
          });
          return;
        }

        dispatch({
          type: SNACKBAR_ERROR,
          value: 'wallet.error.occured',
        });
      }
    });
  }
};

export const addCustomerInitialState = (): AddCustomerInitialStateAction => {
  return { type: ADD_CUSTOMER_INITIAL_STATE };
};
