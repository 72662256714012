import { CouponState, ColorProps } from './coupon.types';
import { isEmpty, isNumber } from '../../utils/validations';
import moment from 'moment';

export const validateFieldsCoupon = (state: CouponState) => {
  let newState: CouponState = Object.assign({}, state);

  if (!newState.expirationDate) {
    newState.expirationDateError = 'coupon.error.expirationDateRequired';
  } else if (
    newState.expirationDate &&
    !moment(newState.expirationDate)?.isValid()
  ) {
    newState.expirationDateError = 'coupon.error.expirationDateInvalid';
  } else {
    newState.expirationDateError = '';
  }

  if (!newState.labelColor) {
    newState.labelColorError = 'coupon.error.labelColorRequired';
  } else {
    newState.labelColorError = '';
  }

  if (!newState.backgroundColor) {
    newState.backgroundColorError = 'coupon.error.backgroundColorRequired';
  } else {
    newState.backgroundColorError = '';
  }

  if (!newState.foregroundColor) {
    newState.foregroundColorError = 'coupon.error.foregroundColorRequired';
  } else {
    newState.foregroundColorError = '';
  }

  if (isEmpty(newState.percentReduction)) {
    newState.percentReductionError = 'coupon.error.percentReductionRequired';
  } else if (!isNumber(newState.percentReduction)) {
    newState.percentReductionError = 'coupon.error.percentReductionNotNumber';
  } else {
    newState.percentReductionError = '';
  }

  if (!newState.image) {
    newState.imageError = 'coupon.error.imageRequired';
  } else {
    newState.imageError = '';
  }

  if (isEmpty(newState.promoCode)) {
    newState.promoCodeError = 'coupon.error.promoCodeRequired';
  } else {
    newState.promoCodeError = '';
  }

  if (isEmpty(newState.description)) {
    newState.descriptionError = 'coupon.error.descriptionRequired';
  } else {
    newState.descriptionError = '';
  }
  if (isEmpty(newState.name)) {
    newState.nameError = 'coupon.error.nameRequired';
  } else {
    newState.nameError = '';
  }
  let valid = false;
  if (
    newState.expirationDateError === '' &&
    newState.labelColorError === '' &&
    newState.backgroundColorError === '' &&
    newState.foregroundColorError === '' &&
    newState.imageError === '' &&
    newState.percentReductionError === '' &&
    newState.promoCodeError === '' &&
    newState.descriptionError === '' &&
    newState.nameError === ''
  ) {
    valid = true;
  }
  return { newState, valid };
};

export const newCouponObject = (coupon: CouponState) => {
  let formData = new FormData();

  let expirationDate = `${moment(coupon.expirationDate).format(
    'YYYY-MM-DD'
  )}T22:59:59.000+01:00`;

  if (coupon.image) {
    formData.append('expirationDate', expirationDate);
    formData.append('labelColor', objectColorJSON(coupon.labelColor));
    formData.append('backgroundColor', objectColorJSON(coupon.backgroundColor));
    formData.append('foregroundColor', objectColorJSON(coupon.foregroundColor));
    formData.append('strip', coupon.image);
    formData.append('name', coupon.name);
    formData.append('source', coupon.source);
    formData.append('barcodeType', coupon.barcodeType || 'CODE128');
    formData.append('value', coupon.percentReduction + ' ' + coupon.symbol);
    formData.append('promoCode', coupon.promoCode);
    formData.append('description', coupon.description);

    return formData;
  }

  return null;
};

export const objectColorJSON = (objectColor: ColorProps) => {
  let newObjectColor = {
    hex: objectColor.hex,
    rgb:
      typeof objectColor.rgb.r === 'number' &&
      typeof objectColor.rgb.g === 'number' &&
      typeof objectColor.rgb.b === 'number'
        ? `rgb(${objectColor.rgb.r},${objectColor.rgb.g},${objectColor.rgb.b})`
        : objectColor.rgb,
  };

  const newObjectColorJSON = JSON.stringify(newObjectColor);
  return newObjectColorJSON;
};

export const validateCustomerFieldsCoupon = (state: any) => {
  let newState: any = Object.assign({}, state);

  if (!newState.expirationDate) {
    newState.expirationDateError = 'coupon.error.expirationDateRequired';
  } else if (
    newState.expirationDate &&
    !moment(newState.expirationDate)?.isValid()
  ) {
    newState.expirationDateError = 'coupon.error.expirationDateInvalid';
  } else {
    newState.expirationDateError = '';
  }

  if (isEmpty(newState.percentReduction)) {
    newState.percentReductionError = 'coupon.error.percentReductionRequired';
  } else if (!isNumber(newState.percentReduction)) {
    newState.percentReductionError = 'coupon.error.percentReductionNotNumber';
  } else {
    newState.percentReductionError = '';
  }

  if (isEmpty(newState.description)) {
    newState.descriptionError = 'coupon.error.descriptionRequired';
  } else {
    newState.descriptionError = '';
  }

  let valid = false;
  if (
    newState.expirationDateError === '' &&
    newState.percentReductionError === '' &&
    newState.descriptionError === ''
  ) {
    valid = true;
  }
  return { newState, valid };
};

export const newCustomerCouponObject = (coupon: any) => {
  let expirationDate = `${moment(coupon.expirationDate).format(
    'YYYY-MM-DD'
  )}T22:59:59.000+01:00`;

  return {
    expirationDate,
    value: coupon.percentReduction + ' ' + coupon.symbol,
    description: coupon.description,
    customerId: coupon.customerId,
  };
};
