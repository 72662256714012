import { createEvent, getEvent, updateEvent } from '../apis/eventApi';
import {
  EVENT_FORM_PROPS,
  EVENT_FORM_PROPS_CHANGED,
  AppThunk,
  EVENTS_ADD_EVENT_TO_LIST,
  EVENT_INITIAL_STATE,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
  EVENT_GET_EVENT_PROPS,
  EVENTS_UPDATE_EVENT,
  EVENTS_GET_LIST_EVENTS,
} from './types';
import {
  EventInitialStateAction,
  EventPropsChangedAction,
  EventState,
} from '../modules/CreateEvent/createEvent.types';

import {
  validateFieldsEvent,
  newEventObject,
} from '../modules/CreateEvent/createEvent.utils';
import { URL_IMAGE } from '../apis/config';
import { EventsApiResponse } from '../apis/ApiTypes';
import { getEventsList } from '../apis/eventsApi';

export const eventPropsChanged = (
  prop: string,
  value: any
): EventPropsChangedAction => {
  return { type: EVENT_FORM_PROPS_CHANGED, prop, value };
};

export const addEvent = (
  walletId: string | null,
  props: EventState,
  callback: () => void
): AppThunk => (dispatch) => {
  const { newState, valid } = validateFieldsEvent(props);
  dispatch({ type: EVENT_FORM_PROPS, newState });
  const newEvent = newEventObject(newState);

  if (valid) {
    dispatch({
      type: EVENT_FORM_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });

    createEvent(walletId, newEvent).then((res: any) => {
      if (res.status == 200) {
        dispatch({
          type: EVENTS_ADD_EVENT_TO_LIST,
          payload: res.data,
        });
        dispatch({
          type: SNACKBAR_SUCCESS,
          value: 'event.success.added',
        });
        callback();
      } else {
        dispatch({
          type: SNACKBAR_ERROR,
          value: 'wallet.error.occured',
        });
        dispatch({
          type: EVENT_FORM_PROPS_CHANGED,
          prop: 'loading',
          value: false,
        });
      }
    });
  }
};

export const getEventbyId = (walletId: string, eventId: string): AppThunk => (
  dispatch
) => {
  getEvent(walletId, eventId).then((res: any) => {
    const event = res.response.data;
    dispatch({
      type: EVENT_GET_EVENT_PROPS,
      payload: {
        ...event,
        strip: `${URL_IMAGE}/${event.strip}`,
      },
    });
  });
};

export const editEvent = (
  walletId: string,
  eventId: string,
  props: EventState,
  callback: () => void
): AppThunk => (dispatch) => {
  const { newState, valid } = validateFieldsEvent(props);
  dispatch({ type: EVENT_FORM_PROPS, newState });
  const newEvent = newEventObject(newState);

  if (valid) {
    dispatch({
      type: EVENT_FORM_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });

    updateEvent(walletId, eventId, newEvent).then((res: any) => {
      dispatch({
        type: EVENT_FORM_PROPS_CHANGED,
        prop: 'loading',
        value: false,
      });
      if (res.status == 200) {
        dispatch({
          type: SNACKBAR_SUCCESS,
          value: 'event.success.update',
        });
        callback();
        dispatch({
          type: EVENTS_UPDATE_EVENT,
          payload: { ...props, _id: eventId },
        });
      } else {
        dispatch({
          type: SNACKBAR_ERROR,
          value: 'wallet.error.occured',
        });
      }
    });
  }
};
export const eventInitialState = (): EventInitialStateAction => {
  return { type: EVENT_INITIAL_STATE };
};
