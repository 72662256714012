import { ColorProps, FerryState } from './ship.types';
import { isEmpty } from '../../utils/validations';
import { convertMomentToTime } from '../../utils/common';

export const validateFieldsFerry = (state: FerryState) => {
  let newState: FerryState = Object.assign({}, state);

  if (!newState.number) {
    newState.numberError = 'ship.error.numberRequired';
  } else if (newState.number && !newState.number) {
    newState.numberError = 'ship.error.numberInvalid';
  } else {
    newState.numberError = '';
  }

  if (!newState.labelColor) {
    newState.labelColorError = 'ship.error.labelColorRequired';
  } else {
    newState.labelColorError = '';
  }

  if (!newState.backgroundColor) {
    newState.backgroundColorError = 'ship.error.backgroundColorRequired';
  } else {
    newState.backgroundColorError = '';
  }

  if (!newState.foregroundColor) {
    newState.foregroundColorError = 'ship.error.foregroundColorRequired';
  } else {
    newState.foregroundColorError = '';
  }

  if (!newState.originName) {
    newState.originNameError = 'ship.error.originNameRequired';
  } else if (newState.originName && !newState.originName) {
    newState.originNameError = 'ship.error.originNameInvalid';
  } else {
    newState.originNameError = '';
  }
  if (!newState.destinationName) {
    newState.destinationNameError = 'ship.error.destinationNameRequired';
  } else if (newState.destinationName && !newState.destinationName) {
    newState.destinationNameError = 'ship.error.destinationNameInvalid';
  } else {
    newState.destinationNameError = '';
  }
  if (!newState.originStationCode) {
    newState.originStationCodeError = 'ship.error.originStationCodeRequired';
  } else if (newState.originStationCode && !newState.originStationCode) {
    newState.originStationCodeError = 'ship.error.originStationCodeInvalid';
  } else {
    newState.originStationCodeError = '';
  }
  if (!newState.destinationStationCode) {
    newState.destinationStationCodeError =
      'ship.error.destinationStationCodeRequired';
  } else if (
    newState.destinationStationCode &&
    !newState.destinationStationCode
  ) {
    newState.destinationStationCodeError =
      'ship.error.destinationStationCodeInvalid';
  } else {
    newState.destinationStationCodeError = '';
  }

  if (!newState.image) {
    newState.imageError = 'ship.error.imageRequired';
  } else {
    newState.imageError = '';
  }

  if (isEmpty(newState.details)) {
    newState.detailsError = 'ship.error.detailsRequired';
  } else {
    newState.detailsError = '';
  }
  if (isEmpty(newState.tarif)) {
    newState.tarifError = 'ship.error.tarifRequired';
  } else {
    newState.tarifError = '';
  }
  if (isEmpty(newState.customerServiceNumber)) {
    newState.customerServiceNumberError =
      'ship.error.customerServiceNumberRequired';
  } else {
    newState.customerServiceNumberError = '';
  }
  if (isEmpty(newState.terms)) {
    newState.termsError = 'ship.error.termsRequired';
  } else {
    newState.termsError = '';
  }

  let valid = false;
  if (
    newState.labelColorError === '' &&
    newState.backgroundColorError === '' &&
    newState.foregroundColorError === '' &&
    newState.imageError === '' &&
    newState.arrivalTimeError === '' &&
    newState.departureTimeError === '' &&
    newState.originStationCodeError === '' &&
    newState.originNameError === '' &&
    newState.destinationNameError === '' &&
    newState.destinationStationCodeError === '' &&
    newState.numberError === '' &&
    newState.tarifError === '' &&
    newState.detailsError === '' &&
    newState.termsError === '' &&
    newState.stripError === '' &&
    newState.customerServiceNumberError === ''
  ) {
    valid = true;
  }

  return { newState, valid };
};

export const newFerryObject = (ferry: FerryState) => {
  let formData = new FormData();

  if (ferry.image) {
    formData.append('departureTime', convertMomentToTime(ferry.departureTime));
    formData.append('arrivalTime', convertMomentToTime(ferry.arrivalTime));
    formData.append('labelColor', objectColorJSON(ferry.labelColor));
    formData.append('backgroundColor', objectColorJSON(ferry.backgroundColor));
    formData.append('foregroundColor', objectColorJSON(ferry.foregroundColor));
    formData.append('strip', ferry.image);
    formData.append('originName', ferry.originName);
    formData.append('destinationName', ferry.destinationName);
    formData.append('originStationCode', ferry.originStationCode);
    formData.append('destinationStationCode', ferry.destinationStationCode);
    formData.append('number', ferry.number);
    formData.append('details', ferry.details);
    formData.append('terms', ferry.terms);
    formData.append('tarif', ferry.tarif);
    formData.append('customerServiceNumber', ferry.customerServiceNumber);
    formData.append('type', 'ferry');
    formData.append('barcodeType', ferry.barcodeType);
    const security = {
      enableOneTimeUse: ferry.enableOneTimeUse,
      disableIOSPassSharing: ferry.disableIOSPassSharing,
      disableMultipleHolders: ferry.disableMultipleHolders,
      enableSecurityAnimation: ferry.enableSecurityAnimation,
      enableRotatingBarcode: ferry.enableRotatingBarcode,
      disableEmailSharing: ferry.disableEmailSharing,
    };

    formData.append('security', JSON.stringify(security));
    formData.append(
      'locations',
      JSON.stringify([
        {
          longitude: 0,
          latitude: 0,
        },
      ])
    );
    return formData;
  }

  return null;
};

export const objectColorJSON = (objectColor: ColorProps) => {
  let newObjectColor = {
    hex: objectColor.hex,
    rgb:
      typeof objectColor.rgb.r === 'number' &&
      typeof objectColor.rgb.g === 'number' &&
      typeof objectColor.rgb.b === 'number'
        ? `rgb(${objectColor.rgb.r},${objectColor.rgb.g},${objectColor.rgb.b})`
        : objectColor.rgb,
  };

  const newObjectColorJSON = JSON.stringify(newObjectColor);
  return newObjectColorJSON;
};
