import React from 'react';
import { useIntl } from 'react-intl';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import ConfirmationModal from '../../Modal';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Switch from '@material-ui/core/Switch';
import Divider, { Box } from '@material-ui/core/';
import clsx from 'clsx';
import moment from 'moment';
//Styles
import logo from '../../../assets/images/logo-BestWallet.png';

import { TransitsApiResponse } from '../../../apis/ApiTypes';
import { URL_IMAGE } from '../../../apis/config';
import Text from '../../../commons/Text';
import FerryCard from '../../../icons/FerryCard';
import ComButton from '../../../commons/Button';

import { useStyles } from './shipCard.styles';

type FerryCardProps = {
  ferry: TransitsApiResponse;
  onClickRedirect: () => void;
  onClickEdit: (id: string) => void;
  onClickSend: (value: string) => void;
  onClickDelete: (ferryId: string) => void;
  loading: boolean;
  ferryId: string;
};

const ShipCard: React.FC<FerryCardProps> = (props) => {
  const intl = useIntl();
  const classes = useStyles();
  const {
    ferry,
    onClickRedirect,
    onClickDelete,
    onClickEdit,
    onClickSend,
    loading,
    ferryId,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [is_open, setIsOpen] = React.useState<any>(null);

  const [is_loading, setIsLoading] = React.useState<any>(null);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <Card className={classes.root} onClick={() => onClickRedirect()}>
      <div className={clsx(classes.cardHeader, classes.cardPosition)}>
        <div className={classes.cardItemNum}>
          <Text color='#828282' fontSize={14} lineHeight={21} fontWeight={500}>
            N°: &nbsp;
          </Text>
          <Text color='#404040' fontSize={14} lineHeight={21} fontWeight={500}>
            {ferry.number}
          </Text>
        </div>
        <IconButton
          aria-label='more'
          aria-controls='long-menu'
          aria-haspopup='true'
          // className={classes.iconButton}
          onClick={handleClick}
          style={{
            background: '#FF5078',
            padding: '2px',
          }}
        >
          <MoreHorizIcon fontSize='small' className={classes.menuIcon} />
        </IconButton>
        <Menu
          id='simple-menu'
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: -50 }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          keepMounted
          open={Boolean(anchorEl)}
          onClick={handleClose}
          className={classes.cardPosition}
        >
          <MenuItem
            onClick={() => {
              onClickEdit(ferry._id);
            }}
            onMouseEnter={(e) =>
              ((e.target as HTMLInputElement).style.color = '#FF5078')
            }
            onMouseLeave={(e) =>
              ((e.target as HTMLInputElement).style.color = '#000000')
            }
          >
            {intl.formatMessage({ id: 'event.label.update' })}
          </MenuItem>
          <MenuItem
            onClick={() => {
              setIsOpen(true);
            }}
            onMouseEnter={(e) =>
              ((e.target as HTMLInputElement).style.color = '#FF5078')
            }
            onMouseLeave={(e) =>
              ((e.target as HTMLInputElement).style.color = '#000000')
            }
          >
            {intl.formatMessage({ id: 'settings.button.delete' })}
          </MenuItem>
        </Menu>

        <ConfirmationModal open={is_open}>
          <div className='modal-cntr'>
            <span style={{ marginTop: '15 px' }}>
              Voulez vous Supprimer le transit ?{' '}
            </span>
            {is_open ? (
              <div
                style={{
                  display: 'flex',
                  marginTop: '10px',
                  justifyContent: 'space-evenly',
                }}
              >
                <ComButton
                  styled='secondary'
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  {intl.formatMessage({ id: 'settings.button.cancel' })}
                </ComButton>

                <ComButton
                  styled='primary'
                  onClick={() => {
                    onClickDelete(ferry._id);
                  }}
                >
                  {is_loading ? (
                    <CircularProgress size={20} color='inherit' />
                  ) : (
                    ''
                  )}
                  {intl.formatMessage({ id: 'resetPassword.button.confirm' })}{' '}
                </ComButton>
              </div>
            ) : (
              ''
            )}
          </div>
        </ConfirmationModal>
      </div>

      <div className={classes.cardFirstSection}>
        <div className={classes.cardLogo}>
          <FerryCard />
        </div>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          style={{ gap: 12 }}
        >
          <div className={classes.cardInfos}>
            <div className={classes.cardItemsInfo}>
              <Text
                color='#828282'
                fontSize={14}
                lineHeight={21}
                fontWeight={500}
              >
                {intl.formatMessage({ id: 'ships.label.origin' })}
              </Text>
              <Text
                color='#404040'
                fontSize={14}
                lineHeight={21}
                fontWeight={500}
              >
                {ferry.originName}
              </Text>
            </div>
            <div className={classes.cardItemsInfo}>
              <Text
                color='#828282'
                fontSize={14}
                lineHeight={21}
                fontWeight={500}
              >
                {intl.formatMessage({ id: 'transitCard.label.destination' })}
              </Text>
              <Text
                color='#404040'
                fontSize={14}
                lineHeight={21}
                fontWeight={500}
              >
                {ferry.destinationName}
              </Text>
            </div>
          </div>
          <div className={classes.cardInfos}>
            <div className={classes.cardItem}>
              <Text
                color='#828282'
                fontSize={14}
                lineHeight={21}
                fontWeight={500}
              >
                {intl.formatMessage({ id: 'ships.label.departureTime' })}
              </Text>
              <Text
                color='#404040'
                fontSize={14}
                lineHeight={21}
                fontWeight={500}
              >
                {ferry.departureTime}
              </Text>
            </div>
            <div className={classes.cardItem}>
              <Text
                color='#828282'
                fontSize={14}
                lineHeight={21}
                fontWeight={500}
              >
                {intl.formatMessage({ id: 'ships.label.arrivalTime' })}
              </Text>
              <Text
                color='#404040'
                fontSize={14}
                lineHeight={21}
                fontWeight={500}
              >
                {ferry.arrivalTime}
              </Text>
            </div>
          </div>
        </Box>
      </div>
      <div className={classes.cardSecendSection}>
        <div className={classes.cardItemID}>
          <Text
            color='#828282'
            fontSize={10}
            lineHeight={21}
            fontWeight={500}
            style={{ marginRight: '5px' }}
          >
            ID:
          </Text>
          <Text color='#404040' fontSize={10} lineHeight={21} fontWeight={500}>
            {ferry._id}
          </Text>
        </div>
      </div>
    </Card>
  );
};

export default ShipCard;
