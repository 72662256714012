import moment, { Moment } from 'moment';
import {
  getTramsList,
  getTramsChildrenList,
  removeCustomerTram,
} from '../apis/tramsApi';
import { removeTram } from '../apis/tramApi';

import {
  TRAMS_GET_LIST_TRAMS,
  TRAMS_INITIAL_STATE,
  AppThunk,
  TRAMS_PROPS_CHANGED,
  TRAMS_FILTER_TRAMS,
  TRAMS_DELETE_TRAM_LOADING,
  TRAMS_DELETE_TRAM,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
  TRAMS_DELETE_CUSTOMER_TRAM,
  TRAMS_GET_SENT_TRAMS,
} from './types';
import { TransitsApiResponse } from '../apis/ApiTypes';
import {
  TramsChangedAction,
  TramsInitialStateAction,
} from '../modules/Trams/trams.types';

export const tramsGetAllTrams = (walletId: string): AppThunk => (dispatch) => {
  getTramsList(walletId).then((res: Array<TransitsApiResponse>) => {
    dispatch({
      type: TRAMS_GET_LIST_TRAMS,
      payload: res,
    });
  });
};

export const tramsPropsChanged = (
  prop: string,
  value: any
): TramsChangedAction => {
  return { type: TRAMS_PROPS_CHANGED, prop, value };
};

export const tramsFilterTrams = (
  status: string,
  allTime: boolean,
  startDate: Moment | null,
  endDate: Moment | null,
  trams: Array<TransitsApiResponse>
): AppThunk => (dispatch) => {
  let newTrams: Array<TransitsApiResponse> = Object.assign([], trams);

  const result: Array<TransitsApiResponse> = [];
  let dateStart = `${moment(startDate).format('YYYY-MM-DD')}T00:00:00.000Z`;
  let dateEnd = `${moment(endDate).format('YYYY-MM-DD')}T00:00:00.000Z`;
  // let offersFilterByStatus = newTrams.filter(
  //   (item: OfferApiResponse) => item.status === status
  //   // &&moment(item.startDate).isSameOrAfter(dateStart)
  //   // &&moment(item.endDate).isSameOrBefore(dateEnd);
  // );
  if (startDate && endDate && moment(startDate).isAfter(endDate, 'day')) {
    dispatch({
      type: TRAMS_PROPS_CHANGED,
      prop: 'endDateError',
      value: 'offer.error.endDateBeforeStartDate',
    });
  } else {
    dispatch({
      type: TRAMS_PROPS_CHANGED,
      prop: 'endDateError',
      value: '',
    });

    // if (allTime) {
    //   if (status === 'ALL') {
    //     dispatch({
    //       type: TRAMS_FILTER_TRAMS,
    //       allTime,
    //       payload: newTrams,
    //     });
    //   } else {
    //     newTrams.forEach((item) => {
    //       if (item.status === status) {
    //         result.push(item);
    //       }
    //     });
    //     dispatch({
    //       type: TRAMS_FILTER_TRAMS,
    //       allTime,
    //       payload: result,
    //     });
    //   }
    // } else if (startDate && endDate) {
    //   if (status === 'ALL') {
    //     newTrams.forEach((item) => {
    //       if (
    //         moment(item.departureTime).isSameOrAfter(dateStart) &&
    //         moment(item.arrivalTime).isSameOrBefore(dateEnd)
    //       ) {
    //         result.push(item);
    //       }
    //     });
    //     dispatch({
    //       type: TRAMS_FILTER_TRAMS,
    //       allTime,
    //       payload: result,
    //     });
    //   } else {
    //     newTrams.forEach((item) => {
    //       if (
    //         item.status === status &&
    //         moment(item.departureTime).isSameOrAfter(dateStart) &&
    //         moment(item.arrivalTime).isSameOrBefore(dateEnd)
    //       ) {
    //         result.push(item);
    //       }
    //     });

    //     dispatch({
    //       type: TRAMS_FILTER_TRAMS,
    //       allTime,
    //       payload: result,
    //     });
    //   }
    // } else {
    //   if (status === 'ALL') {
    //     dispatch({
    //       type: TRAMS_FILTER_TRAMS,
    //       allTime,
    //       payload: newTrams,
    //     });
    //   } else {
    //     newTrams.forEach((item) => {
    //       if (item.status === status) {
    //         result.push(item);
    //       }
    //     });
    //     dispatch({
    //       type: TRAMS_FILTER_TRAMS,
    //       allTime,
    //       payload: result,
    //     });
    //   }
    // }

    if (startDate) {
      newTrams.forEach((item) => {
        if (moment(item.departureTime).isSameOrAfter(moment(dateStart))) {
          result.push(item);
        }
      });

      dispatch({
        type: TRAMS_FILTER_TRAMS,
        allTime,
        payload: result,
      });
    } else if (startDate && endDate) {
      newTrams.forEach((item) => {
        if (
          moment(item.departureTime).isSameOrAfter(moment(dateStart)) &&
          moment(item.arrivalTime).isSameOrBefore(moment(dateEnd))
        ) {
          result.push(item);
        }
      });

      dispatch({
        type: TRAMS_FILTER_TRAMS,
        allTime,
        payload: result,
      });
    } else {
      dispatch({
        type: TRAMS_FILTER_TRAMS,
        allTime,
        payload: newTrams,
      });
    }
  }
};

export const deleteTram = (tramId: string, walletId: string): AppThunk => (
  dispatch
) => {
  dispatch({
    type: TRAMS_DELETE_TRAM_LOADING,
    payload: true,
  });
  removeTram(tramId, walletId).then((res) => {
    if (res.data.status === 'success') {
      dispatch({
        type: TRAMS_DELETE_TRAM,
        payload: tramId,
      });
      dispatch({
        type: SNACKBAR_SUCCESS,
        value: 'transit.success.deleted',
      });
    } else
      dispatch({
        type: SNACKBAR_ERROR,
        value: 'wallet.error.occured',
      });
  });
};

export const tramsGetChildrens = (
  walletId: string,
  tramId: string,
  page: number | string,
  size: number | string,
  searchFilter: any
): AppThunk => (dispatch) => {
  getTramsChildrenList(walletId, tramId, page, size, searchFilter).then(
    (res: any) => {
      if (res.status === 500) {
        dispatch({
          type: SNACKBAR_ERROR,
          value: 'wallet.error.occured',
        });
      } else if (res.status === 401) {
        dispatch({
          type: SNACKBAR_ERROR,
          value: 'Id malformés',
        });
        tramsPropsChanged('loading', false);
      } else
        dispatch({
          type: TRAMS_GET_SENT_TRAMS,
          payload: res.data,
          total: res.total,
        });
    }
  );
};

export const deleteCustomerTram = (
  tramId: string,
  customerId: string,
  passId: string,
  walletId: string
): AppThunk => (dispatch) => {
  dispatch({
    type: TRAMS_DELETE_TRAM_LOADING,
    payload: true,
  });
  removeCustomerTram(tramId, customerId, walletId).then((res) => {
    if (res.data.status === 'success') {
      dispatch({
        type: TRAMS_DELETE_CUSTOMER_TRAM,
        payload: passId,
      });

      dispatch({
        type: SNACKBAR_SUCCESS,
        value: 'transit.success.deleted',
      });
    } else {
      dispatch({
        type: SNACKBAR_ERROR,
        value: 'wallet.error.occured',
      });
    }
  });
};
export const tramsInitialState = (): TramsInitialStateAction => {
  return { type: TRAMS_INITIAL_STATE };
};
