import moment, { Moment } from 'moment';
import { removeFlight } from '../apis/flightApi';

import {
  FLIGHTS_GET_LIST_FLIGHTS,
  FLIGHTS_INITIAL_STATE,
  AppThunk,
  FLIGHTS_PROPS_CHANGED,
  FLIGHTS_FILTER_FLIGHTS,
  FLIGHTS_DELETE_FLIGHT_LOADING,
  FLIGHTS_DELETE_FLIGHT,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
  FLIGHTS_GET_SENT_FLIGHTS,
} from './types';
import { FlightsApiResponse } from '../apis/ApiTypes';
import {
  FlightsChangedAction,
  FlightsInitialStateAction,
} from '../modules/Flights/flights.types';
import {
  getCouponsChildrenList,
  getFlightsList,
  removeCustomerFlight,
} from '../apis/flightsApi';

export const flightsGetAllFlights = (walletId: string): AppThunk => (
  dispatch
) => {
  getFlightsList(walletId).then((res: Array<FlightsApiResponse>) => {
    dispatch({
      type: FLIGHTS_GET_LIST_FLIGHTS,
      payload: res,
    });
  });
};

export const flightsPropsChanged = (
  prop: string,
  value: any
): FlightsChangedAction => {
  return { type: FLIGHTS_PROPS_CHANGED, prop, value };
};

export const flightsFilterFlights = (
  status: string,
  allTime: boolean,
  startDate: Moment | null,
  endDate: Moment | null,
  flights: Array<FlightsApiResponse>
): AppThunk => (dispatch) => {
  let newflights: Array<FlightsApiResponse> = Object.assign([], flights);
  const result: Array<FlightsApiResponse> = [];

  let dateStart = `${moment(startDate).format('YYYY-MM-DD')}T00:00:00.000Z`;
  let dateEnd = `${moment(endDate).format('YYYY-MM-DD')}T00:00:00.000Z`;

  if (startDate && endDate && moment(startDate).isAfter(endDate, 'day')) {
    dispatch({
      type: FLIGHTS_PROPS_CHANGED,
      prop: 'endDateError',
      value: 'offer.error.endDateBeforeStartDate',
    });
  } else {
    dispatch({
      type: FLIGHTS_PROPS_CHANGED,
      prop: 'endDateError',
      value: '',
    });

    if (startDate && endDate) {
      newflights.forEach((item) => {
        if (
          moment(item.departureTime).isSameOrAfter(moment(dateStart)) &&
          moment(item.arrivalTime).isSameOrBefore(moment(dateEnd))
        ) {
          result.push(item);
        }
      });

      dispatch({
        type: FLIGHTS_FILTER_FLIGHTS,
        allTime,
        payload: result,
      });
    } else if (status && startDate) {
      newflights.forEach((item) => {
        if (moment(item.departureTime).isSameOrAfter(moment(dateStart))) {
          result.push(item);
        }
      });
      dispatch({
        type: FLIGHTS_FILTER_FLIGHTS,
        allTime,
        payload: result,
      });
    } else {
      dispatch({
        type: FLIGHTS_FILTER_FLIGHTS,
        allTime,
        payload: newflights,
      });
    }
  }
};

export const deleteFlight = (flightId: string, walletId: string): AppThunk => (
  dispatch
) => {
  dispatch({
    type: FLIGHTS_DELETE_FLIGHT_LOADING,
    payload: true,
  });
  removeFlight(flightId, walletId).then((res) => {
    if (res.data.status === 'success') {
      dispatch({
        type: FLIGHTS_DELETE_FLIGHT,
        payload: flightId,
      });
      dispatch({
        type: SNACKBAR_SUCCESS,
        value: 'flight.success.deleted',
      });
    } else
      dispatch({
        type: SNACKBAR_ERROR,
        value: 'wallet.error.occured',
      });
  });
};

export const flightsInitialState = (): FlightsInitialStateAction => {
  return { type: FLIGHTS_INITIAL_STATE };
};

export const flightsGetChildrens = (
  walletId: string,
  flightId: string,
  page: number | string,
  size: number | string,
  searchFilter: any
): AppThunk => (dispatch) => {
  getCouponsChildrenList(walletId, flightId, page, size, searchFilter).then(
    (res: any) => {
      if (res.status === 500) {
        dispatch({
          type: SNACKBAR_ERROR,
          value: 'wallet.error.occured',
        });
      } else
        dispatch({
          type: FLIGHTS_GET_SENT_FLIGHTS,
          payload: res.data,
          total: res.total,
        });
    }
  );
};
export const deleteCustomerFlight = (
  flightId: string,
  customerId: string,
  passId: string,
  walletId: string
): AppThunk => (dispatch) => {
  dispatch({
    type: FLIGHTS_DELETE_FLIGHT_LOADING,
    payload: true,
  });
  removeCustomerFlight(flightId, customerId, walletId).then((res) => {
    if (res.data.status === 'success') {
      dispatch({
        type: FLIGHTS_DELETE_FLIGHT,
        payload: passId,
      });

      dispatch({
        type: SNACKBAR_SUCCESS,
        value: 'flight.success.deleted',
      });
    } else {
      dispatch({
        type: SNACKBAR_ERROR,
        value: 'wallet.error.occured',
      });
    }
  });
};
