import {
  FORGOT_PASSWORD_PROPS_CHANGED,
  FORGOT_PASSWORD_INITIAL_STATE,
  AppThunk,
} from './types';

import {
  ForgotPasswordPropsChangedAction,
  ForgotPasswordInitialStateAction,
} from '../modules/ForgotPassword/forgotPassword.types';
import { forgotPassword } from '../apis/forgotPasswordApi';

export const forgotPasswordPropsChanged = (
  prop: string,
  value: string
): ForgotPasswordPropsChangedAction => {
  return { type: FORGOT_PASSWORD_PROPS_CHANGED, prop, value };
};

export const forgotPasswordInitialState = (): ForgotPasswordInitialStateAction => {
  return { type: FORGOT_PASSWORD_INITIAL_STATE };
};

export const forgotPasswordConfirm = (
  username: string,
  callback: () => void
): AppThunk => (dispatch) => {
  dispatch({
    type: FORGOT_PASSWORD_PROPS_CHANGED,
    prop: 'loading',
    value: true,
  });

  forgotPassword(username).then((res: any) => {
    if (res.data.status === 'success') {
      setTimeout(() => {
        dispatch({
          type: FORGOT_PASSWORD_PROPS_CHANGED,
          prop: 'loading',
          value: false,
        });

        //   dispatch({
        //     type: FORGOT_PASSWORD_PROPS_CHANGED,
        //     prop: 'emailError',
        //     value: 'Veuillez vérifier votre boîte de réception',
        //   });

        callback();
      }, 1000);
    } else if (
      res.data &&
      res.data.error &&
      res.data.error.statusCode === 404
    ) {
      dispatch({
        type: FORGOT_PASSWORD_PROPS_CHANGED,
        prop: 'emailError',
        value: "Nom d'utilisateur non trouvé",
      });
      dispatch({
        type: FORGOT_PASSWORD_PROPS_CHANGED,
        prop: 'loading',
        value: false,
      });
    } else if (
      res.data &&
      res.data.error &&
      res.data.error.statusCode === 500
    ) {
      dispatch({
        type: FORGOT_PASSWORD_PROPS_CHANGED,
        prop: 'emailError',
        value:
          "Une erreur s'est produite lors de l'envoi de nom d'utilisateur. Réessayez plus tard!",
      });
      dispatch({
        type: FORGOT_PASSWORD_PROPS_CHANGED,
        prop: 'loading',
        value: false,
      });
    }
  });
};
