import { EventState, ColorProps, EventDateState } from './createEvent.types';
import { isEmpty } from '../../utils/validations';
import moment from 'moment';

export const validateFieldsEvent = (state: EventState) => {
  let newState: EventState = Object.assign({}, state);

  newState.date.openingHour = moment(newState.date.openingHour);
  newState.date.doorOpeningHour = moment(newState.date.doorOpeningHour);
  newState.date.closingHour = moment(newState.date.closingHour);

  if (!newState.strip) {
    newState.imageError = 'event.error.imageRequiredRequired';
  } else {
    newState.imageError = '';
  }

  if (isEmpty(newState.name)) {
    newState.nameError = 'event.error.nameRequired';
  } else {
    newState.nameError = '';
  }

  if (isEmpty(newState.locationName)) {
    newState.locationNameError = 'event.error.locationNameRequired';
  } else {
    newState.locationNameError = '';
  }

  if (isEmpty(newState.address)) {
    newState.addressError = 'event.error.addressRequired';
  } else {
    newState.addressError = '';
  }

  if (isEmpty(newState.emailSubscriptionMessage)) {
    newState.emailSubscriptionMessageError =
      'event.error.emailSubscriptionMessage';
  } else {
    newState.emailSubscriptionMessageError = '';
  }

  if (!newState.date.openingHour) {
    newState.openingHourError = 'event.error.openingHourErrorRequired';
  } else if (
    newState.date.openingHour &&
    !newState.date.openingHour.isValid()
  ) {
    newState.openingHourError = 'event.error.openingHourErrorInvalid';
  } else {
    newState.openingHourError = '';
  }

  if (!newState.date.closingHour) {
    newState.closingHourError = 'event.error.closingHourErrorRequired';
  } else if (
    newState.date.closingHour &&
    !newState.date.closingHour.isValid()
  ) {
    newState.closingHourError = 'event.error.closingHourErrorInvalid';
  } else {
    newState.closingHourError = '';
  }

  if (!newState.date.doorOpeningHour) {
    newState.doorOpeningHourError = 'event.error.doorOpeningHourRequired';
  } else if (
    newState.date.doorOpeningHour &&
    !newState.date.doorOpeningHour.isValid()
  ) {
    newState.doorOpeningHourError = 'event.error.doorOpeningHourErrorInvalid';
  } else {
    newState.doorOpeningHourError = '';
  }

  if (!newState.price) {
    newState.priceError = 'event.error.priceRequired';
  } else {
    newState.priceError = '';
  }

  if (!newState.terms) {
    newState.termsError = 'event.error.termsRequired';
  } else {
    newState.termsError = '';
  }

  if (isEmpty(newState.contactPhone)) {
    newState.contactPhoneError = 'event.error.contactPhoneRequired';
  } else {
    newState.contactPhoneError = '';
  }

  if (isEmpty(newState.email)) {
    newState.emailError = 'event.error.emailRequired';
  } else {
    newState.emailError = '';
  }

  if (isEmpty(newState.website)) {
    newState.websiteError = 'event.error.websiteError';
  } else {
    newState.websiteError = '';
  }

  let valid = false;
  if (
    newState.locationNameError === '' &&
    newState.nameError === '' &&
    newState.imageError === '' &&
    newState.addressError === '' &&
    newState.openingHourError === '' &&
    newState.closingHourError === '' &&
    newState.doorOpeningHourError === '' &&
    newState.priceError === '' &&
    newState.termsError === '' &&
    newState.contactPhoneError === '' &&
    newState.emailError === '' &&
    newState.websiteError === '' &&
    newState.labelColorError === '' &&
    newState.foregroundColorError === '' &&
    newState.backgroundColorError === '' &&
    newState.emailSubscriptionMessageError == ''
  ) {
    valid = true;
  }

  return { newState, valid };
};

export const newEventObject = (event: EventState) => {
  let formData = new FormData();

  if (event.strip) {
    formData.append('address', event.address);

    formData.append('backgroundColor', objectColorJSON(event.backgroundColor));
    formData.append('contactPhone', event.contactPhone);
    formData.append('date', JSON.stringify(event.date));
    formData.append('email', event.email);
    formData.append('strip', event.strip);
    formData.append('foregroundColor', objectColorJSON(event.foregroundColor));
    formData.append('labelColor', objectColorJSON(event.labelColor));
    formData.append('locationName', event.locationName);
    formData.append('name', event.name);
    formData.append('barcodeType', event.barcodeType);

    const security = {
      enableOneTimeUse: event.enableOneTimeUse,
      disableIOSPassSharing: event.disableIOSPassSharing,
      disableMultipleHolders: event.disableMultipleHolders,
      enableSecurityAnimation: event.enableSecurityAnimation,
      enableRotatingBarcode: event.enableRotatingBarcode,
      disableEmailSharing: event.disableEmailSharing,
    };

    formData.append('security', JSON.stringify(security));

    formData.append('price', event.price.toString());
    formData.append('terms', event.terms);
    formData.append('website', event.website);
    formData.append('locations', JSON.stringify(event.locations));
    formData.append('emailSubscriptionMessage', event.emailSubscriptionMessage);

    return formData;
  }

  return null;
};

export const objectColorJSON = (objectColor: ColorProps) => {
  let newObjectColor = {
    hex: objectColor.hex,
    rgb:
      typeof objectColor.rgb.r === 'number' &&
      typeof objectColor.rgb.g === 'number' &&
      typeof objectColor.rgb.b === 'number'
        ? `rgb(${objectColor.rgb.r},${objectColor.rgb.g},${objectColor.rgb.b})`
        : objectColor.rgb,
  };

  const newObjectColorJSON = JSON.stringify(newObjectColor);
  return newObjectColorJSON;
};

export const objectDateJSON = (objectDate: EventDateState) => {
  let newEventDate = {
    openingHour: objectDate.openingHour,
    closingHour: objectDate.closingHour,
    doorOpeningHour: objectDate.doorOpeningHour,
  };

  const newObjectEventDateJSON = JSON.stringify(newEventDate);
  return newObjectEventDateJSON;
};
