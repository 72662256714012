import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { useStyles } from './table.styles';
import moment from 'moment';
import { CircularProgress } from '@material-ui/core';
// import { ic_delete } from 'react-icons-kit/md';

//Images
import emptyState from '../../assets/images/emptyState.svg';
import Button from '../../commons/Button';
import WalletModal from '../Modal';
import Text from '../../commons/Text';

type RowProps = {
  row: any;
  // walletId: string;
};

const Row: React.FC<RowProps> = (props) => {
  const intl = useIntl();
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const { row } = props;

  const [open, setOpen] = React.useState<boolean>(false);
  const classes = useStyles();

  let source =
    row.source === 'internal'
      ? intl.formatMessage({ id: 'customersTable.source.internal' })
      : intl.formatMessage({ id: 'customersTable.source.external' });

  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell />
        <TableCell component='th' scope='row'>
          {row.ticketId}
        </TableCell>
        <TableCell align='center'>{row.firstName}</TableCell>
        <TableCell align='center'>{row.lastName}</TableCell>
        <TableCell align='center'>{row.email}</TableCell>
        <TableCell align='center'>
          {row.status === 'Actif'
            ? intl.formatMessage({ id: 'EventsCustomersTable.enregistre.true' })
            : intl.formatMessage({
                id: 'EventsCustomersTable.enregistre.false',
              })}
        </TableCell>
        <TableCell align='center'>{row.devices}</TableCell>
      </TableRow>
    </React.Fragment>
  );
};

type UncollapsibleTableProps = {
  data: Array<any>;
  // walletId: string;
};

const UncollapsibleTable: React.FC<UncollapsibleTableProps> = (props) => {
  const classes = useStyles();
  const intl = useIntl();
  const { data } = props;
  return (
    <TableContainer
      className={classes.tableContainer}
      component={Paper}
      elevation={0}
      // style={{ border: '1px solid #e0e0e0', borderBottom: '0' }}
    >
      <Table aria-label='collapsible table'>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>
              {intl.formatMessage({ id: 'EventsCustomersTable.Id' })}
            </TableCell>
            <TableCell align='center'>
              {intl.formatMessage({ id: 'EventsCustomersTable.firstName' })}
            </TableCell>
            <TableCell align='center'>
              {intl.formatMessage({ id: 'EventsCustomersTable.lastName' })}
            </TableCell>
            <TableCell align='center'>
              {intl.formatMessage({ id: 'EventsCustomersTable.email' })}
            </TableCell>
            <TableCell align='center'>
              {intl.formatMessage({ id: 'EventsCustomersTable.enregistre' })}
            </TableCell>
            <TableCell align='center'>
              {intl.formatMessage({ id: 'EventsCustomersTable.systeme' })}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row: any) => (
            <Row key={row._id} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UncollapsibleTable;
