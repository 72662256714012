import { css } from 'styled-components';

export type OffersStylesProps = {
  loading: string;
  offersLength: number;
};

export const offersStyles = (props: OffersStylesProps) => {
  let flex = 0;
  let justifyContent = 'flex-start';

  if (props.loading === 'true') {
    flex = 1;
    justifyContent = 'center';
  } else if (props.offersLength === 0 && props.loading === 'false') {
    flex = 1;
    justifyContent = 'center';
  }
  return css`
    display: flex;
    flex-direction: column;
    height: calc(100vh - 105px);
    box-sizing: border-box;
    .div-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 15px;
    }

    .filter-container {
      display: flex;
      max-width: 516px;
      flex-direction: row;
      align-items: center;
      > input {
        background-color: #fff;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #404040;
        box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.08);
        border-radius: 15px;
      }
    }
    .MuiOutlinedInput-adornedEnd {
      background-color: #fff;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #404040;
      box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.08);
      border-radius: 15px;
    }
    .MuiIconButton-root.Mui-disabled {
      color: rgba(0, 0, 0, 0.38) !important;
    }
    .MuiCheckbox-root {
      color: #ff5078;
    }
    .MuiIconButton-root {
      color: #ff5078;
    }

    .filter-button {
      display: flex;
      position: absolute;
      right: 0;
      width: 100px;
      align-items: center;
      justify-content: space-between;
      background: #691fde;
      height: 53px;
      box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.08);
      border-radius: 15px;
      padding: 0 10px;
      transition: all 0.3s ease;
      :hover {
        cursor: pointer;
        background: #6438f5;
      }
      > h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: white;
        width: 80px;
      }
    }
    .filter-opened {
      transform: rotate(180deg);
    }

    .select-input {
      width: 150px;
      visibility: hidden;
      position: absolute;
      right: 0;
      top: 80px;
    }
    .div-search {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      flex-wrap: wrap;
      gap: 18px;

      .btn-search {
        background: #ff5078;
      }
    }
    .divider {
      height: 28px;
      width: 2px;
      margin: 0px;
      background: #404040;
    }
    .MuiDivider-root {
      background-color: #c7c7c7 !important;
    }
    .icon {
      svg path {
        fill: #ff5078;
      }
    }

    .div-list {
      display: flex;
      flex: ${flex};
      align-items: center;
      flex-wrap: wrap;
      justify-content: ${justifyContent};
      margin: 20px 0;
      gap: 16px;
    }
  `;
};
