import React, { Fragment, useMemo } from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
//Style
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { eventCustomersDetailStyles, useStyles } from './eventCustomers.styles';
//Material
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';

import CircularProgress from '@material-ui/core/CircularProgress';
import { TextField } from '@material-ui/core';
//Components
import UncollapsibleTable from '../../components/UncollapsibleTable';
import EmptyData from '../../components/EmptyData';
import WalletModal from '../../components/Modal';
//Actions

//Api
import { URL_IMAGE } from '../../apis/config';

import emptyState from '../../assets/images/emptyState.svg';
import { EventsCustomersProps } from './eventCustomers.types';
import { RootState } from '../../reducers';
import Button from '../../commons/Button';
import AddCustomer from '../AddCustomer';
import Text from '../../commons/Text';
import { getDataFromCache } from '../../utils/cache';
import Input from '../../commons/Inputs/Input';
import CouponCard from '../../components/Cards/CouponCard';
import { CouponApiResponse } from '../../apis/ApiTypes';
import TablePagination from '@material-ui/core/TablePagination';

import {
  couponsUpdateCouponStatus,
  couponsGetAllCoupons,
  couponsSendCoupon,
  couponsInitialState,
  editCouponInitialState,
  deleteCoupon,
  couponsGetChildrens,
  couponsPropsChanged,
  deleteCustomerCoupon,
} from '../../actions';
import CouponModal from '../../components/Modal';
import EditCustomerCoupon from '../EditCustomerCoupon';
import { useHistory } from 'react-router-dom';
import SimpleDialog from '../../components/Dialog';

import { couponsStyles, CouponsStylesProps } from './coupons.styles';
import SortableTable from '../../components/sortableTable';

const CouponCustomers: React.FC<any> = (props) => {
  const { couponsList, loading, loadingSend, search, select, total } = props;

  const intl = useIntl();
  const [open, setOpen] = React.useState<boolean>(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [couponId, setCouponId] = React.useState('');
  const [couponIdConfirmed, setCouponIdConfirmed] = React.useState('');
  const [EditDialogOpen, setEditDialogOpen] = React.useState(false);
  const [deleteBoxOpen, setDeleteBoxOpen] = React.useState(false);

  const objectUser: any = getDataFromCache('user');
  const classes = useStyles();
  const { couponID } = useParams<{ couponID: string }>();
  const [selectOpener, setSelectOpener] = React.useState<boolean>(false);
  const [index, setIndex] = React.useState<number>(0);

  const history = useHistory();
  const query = new URLSearchParams(props.location.search);
  let page = query.get('page') || 0;
  let size = query.get('size') || 10;
  let coupon = query.get('couponId') || '';
  let customerId = query.get('customerId') || '';
  let creationDate = query.get('creationDate') || '';
  let lastName = query.get('lastName') || '';

  const [searchFilter, setSearchFilter] = React.useState<any[]>([
    { value: creationDate, name: 'creationDate' },
    { value: lastName, name: 'lastName' },
    { value: customerId, name: 'customerId' },
    { value: couponId, name: 'couponId' },
  ]);

  React.useEffect(() => {
    const selection = searchFilter.find((elem) => elem.value !== '');

    props.couponsPropsChanged('search', selection?.value || '');

    props.couponsGetChildrens(
      objectUser.wallet,
      couponID,
      page,
      size,
      searchFilter
    );
  }, []);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    query.delete('page');
    query.append('page', newPage.toString());
    history.push({ search: query.toString() });
    props.couponsGetChildrens(objectUser.wallet, couponID, newPage, size);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    query.delete('size');
    query.append('size', event.target.value.toString());
    history.push({ search: query.toString() });
    props.couponsGetChildrens(
      objectUser.wallet,
      couponID,
      page,
      event.target.value.toString(),
      searchFilter
    );
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCouponIdConfirmed('');
  };

  const handleClickDeletecoupon = (couponId: string) => {
    props.deleteCoupon(couponId, objectUser.wallet);
  };
  const handleSelectChange = (event: React.ChangeEvent<{ value: any }>) => {
    history.push({ search: query.toString() });

    props.couponsPropsChanged('search', '');
    props.couponsPropsChanged('select', event.target.value);

    searchFilter.forEach((elem, index) => {
      if (elem.name !== event.target.value) {
        query.delete(elem.name);

        searchFilter[index].value = '';
      }
    });
    history.replace({ search: query.toString() });

    setSearchFilter(searchFilter);
  };

  const updateQueryParams = (param: string, value: string) => {
    query.delete(param);
    query.append(param, value);
    history.replace({ search: query.toString() });
  };
  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'search') {
      if (event.target.value.length > 60) return;
      props.couponsPropsChanged(event.target.name, event.target.value);
      const val = event.target.value;

      if (val == '') {
        searchFilter.forEach((elem) => {
          query.delete(elem.name);
        });
        history.replace({ search: query.toString() });
        searchFilter.forEach((elem, index) => {
          searchFilter[index].value = '';
        });
        setSearchFilter(searchFilter);
      } else
        searchFilter.forEach((elem, index) => {
          if (elem.name === select) {
            searchFilter[index].value = val;
            updateQueryParams(select, val);
          }
        });
      setSearchFilter(searchFilter);
    }

    props.couponsPropsChanged(event.target.name, event.target.value);
  };

  const handleDeleteCustomerCoupon = () => {
    const coupon = couponsList.find((c: any) => c.passId == index);

    props.deleteCustomerCoupon(
      coupon._id,
      coupon.customerId,
      coupon.passId,
      objectUser.wallet
    );
    setDeleteBoxOpen(false);
  };

  const handleClickSearch = () => {
    props.couponsPropsChanged('search', search);
    props.couponsGetChildrens(
      objectUser.wallet,
      couponID,
      page,
      size,
      searchFilter
    );
  };
  return (
    <Wrapper loading={loading.toString()} couponsLength={couponsList.length}>
      <div className='div-header'>
        <div className='div-input-filter'>
          <FormControl className='filter-container' fullWidth>
            <Input value={select} type='text' disabled />
            <div
              className='filter-button'
              onClick={() => setSelectOpener(!selectOpener)}
            >
              <h3>
                {intl.formatMessage({
                  id: 'wallet.label.filter',
                })}
              </h3>
              <ExpandMoreRoundedIcon
                style={{ color: 'white' }}
                className={selectOpener ? 'filter-opened' : ''}
              />
            </div>
            <Select
              className='select-input'
              value={select}
              onChange={handleSelectChange}
              placeholder={intl.formatMessage({
                id: 'wallet.label.filter',
              })}
              id='filter'
              inputProps={{ className: 'input' }}
              open={selectOpener}
              onClose={() => setSelectOpener(false)}
            >
              <MenuItem value='couponId'>
                <span>Coupon ID</span>
              </MenuItem>
              <MenuItem value='customerId'>
                <span>Client ID</span>
              </MenuItem>
              <MenuItem value='lastName'>
                {' '}
                <span>Prénom </span>
              </MenuItem>
              <MenuItem value='creationDate'>
                {' '}
                <span>Date de création</span>
              </MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className='div-input-search'>
          <Input
            value={search}
            background='#fff'
            type='text'
            name='search'
            placeholder={intl.formatMessage({ id: 'wallet.button.rechercher' })}
            // width={690}
            margin='10px 0'
            className='input-search'
            onChange={handleEventChange}
          />
          <Button
            onClick={handleClickSearch}
            width={120}
            background='#FF5078'
            className='button-search'
          >
            {intl.formatMessage({ id: 'wallet.button.rechercher' })}
          </Button>
        </div>
      </div>
      <div className='div-header'>
        <Text color='#404040' fontSize={24} lineHeight={36} fontWeight={600}>
          Liste des sous-coupons
        </Text>
      </div>
      <CouponModal
        open={EditDialogOpen}
        onClose={() => setEditDialogOpen(false)}
      >
        <EditCustomerCoupon
          walletId={objectUser.wallet}
          couponId={couponsList.find((c: any) => c.passId == index)?._id}
          customerId={
            couponsList.find((c: any) => c.passId == index)?.customerId
          }
          originalCouponId={couponID}
          onCancel={() => setEditDialogOpen(false)}
          onSaveChanged={() => setEditDialogOpen(!EditDialogOpen)}
        ></EditCustomerCoupon>
      </CouponModal>
      <div
        className='div-list'
        style={{ marginRight: '33px', marginLeft: '33px' }}
      >
        <EmptyData
          data={couponsList}
          loading={loading}
          message={intl.formatMessage({ id: 'coupons.label.emptyList' })}
          image={emptyState}
        >
          <EmptyData
            data={couponsList}
            loading={loading}
            message='La liste des clients est vide'
            image={emptyState}
          >
            <SortableTable
              data={couponsList}
              onClickEdit={(passId: number) => {
                const coupon = couponsList.find((c: any) => c.passId == passId);

                props.editCouponInitialState(coupon, props.currencies);
                setIndex(passId);
                setEditDialogOpen(true);
              }}
              onClickDelete={(passId: number) => {
                setIndex(passId);
                setDeleteBoxOpen(true);
              }}
              page={+page}
              rowsPerPage={+size}
            ></SortableTable>
            {couponsList.length != 0 && (
              <TablePagination
                count={total}
                page={+page}
                onChangePage={handleChangePage}
                rowsPerPage={+size}
                rowsPerPageOptions={[10, 25, 50, 100]}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={'Lignes par page'}
              />
            )}
          </EmptyData>
        </EmptyData>
        <SimpleDialog
          title={intl.formatMessage({ id: 'offers.dialog.title' })}
          text={'Etes-vous sûr de vouloir supprimer ce coupon?'}
          handleAgree={handleDeleteCustomerCoupon}
          open={deleteBoxOpen}
          handleClose={() => setDeleteBoxOpen(false)}
        ></SimpleDialog>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled('div')<CouponsStylesProps>`
  ${(props) => couponsStyles(props)}
`;

const mapStateToProps = ({ coupons, walletForm }: RootState) => {
  const { couponsList, loading, loadingSend, search, select, total } = coupons;

  return {
    couponsList,
    loading,
    currencies: walletForm.currencies,
    loadingSend,
    search,
    select,
    total,
  };
};

export const connector = connect(mapStateToProps, {
  couponsGetAllCoupons,
  couponsSendCoupon,
  couponsInitialState,
  couponsUpdateCouponStatus,
  editCouponInitialState,
  deleteCoupon,
  couponsGetChildrens,
  couponsPropsChanged,
  deleteCustomerCoupon,
});

export default connector(CouponCustomers);
