import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
//Style
import styled from 'styled-components';
import { updateGainStyles } from './updateGain.styles';
//Material
import { CircularProgress } from '@material-ui/core';
//Commomns
import InputLabel from '../../commons/Inputs/InputLabel';
import Button from '../../commons/Button';
import { getDataFromCache } from '../../utils/cache';

//Actions
import {
  updateGainPropsChanged,
  updateGain,
  updateGainInitialState,
} from '../../actions';
//Reducers
import { RootState } from '../../reducers';
//Type
import { UpdateGainFormProps } from './updateGain.types';
import { getDecimal, getNatural, isNumber } from '../../utils/validations';

const UpdateGain: React.FC<UpdateGainFormProps> = (props) => {
  const intl = useIntl();
  const currentWallet = getDataFromCache('wallet');

  const {
    walletId,
    customerId,
    gain,
    gainError,
    loading,
    onSaveChanged,
  } = props;

  React.useEffect(() => {
    props.updateGainInitialState();
    /* eslint-disable-next-line */
  }, []);

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;
    if (!isNumber(+value)) {
      return;
    } else {
      if (getNatural(value).toString().length > 4) {
        props.updateGainPropsChanged(
          'gainError',
          'Vous avez dépassé le nombre maximum avant le point'
        );
        return;
      }
      if (getDecimal(value).toString().length > 3) {
        props.updateGainPropsChanged(
          'gainError',
          'Vous avez dépassé le nombre maximum aprés le point'
        );
        return;
      }
      props.updateGainPropsChanged('gainError', '');
    }

    props.updateGainPropsChanged(event.target.name, event.target.value);
  };

  const handleClickConfirm = () => {
    if (!isNumber(gain)) {
      props.updateGainPropsChanged(
        'gainError',
        intl.formatMessage({
          id: 'updateGain.error.gainNotNumber',
        })
      );
    } else {
      props.updateGainPropsChanged('gainError', '');
      props.updateGain(walletId, customerId, gain, () => onSaveChanged());
    }
  };

  return (
    <Wrapper>
      <div className='div-container'>
        <InputLabel
          label={`${intl.formatMessage({ id: 'updateGain.label.gain' })} (${
            currentWallet.gainUnit
          })`}
          name='gain'
          value={gain}
          error={gainError}
          placeholder={intl.formatMessage({ id: 'updateGain.placehoder.gain' })}
          onChange={(e) => handleEventChange(e)}
        />
      </div>

      <Button onClick={handleClickConfirm} margin='10px'>
        {loading ? (
          <CircularProgress size={20} color='inherit' />
        ) : (
          intl.formatMessage({ id: 'updateGain.button.confirm' })
        )}
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${(props) => updateGainStyles(props)}
`;

const mapStateToProps = ({ updateGain }: RootState) => {
  const { gain, gainError, loading } = updateGain;
  return {
    gain,
    gainError,
    loading,
  };
};

export const connector = connect(mapStateToProps, {
  updateGainPropsChanged,
  updateGain,
  updateGainInitialState,
});

export default connector(UpdateGain);
