import 'date-fns';
import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { shipStyles } from './ferry.styles';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from '@material-ui/core';
import { TimePicker } from '@material-ui/pickers';
import InputLabel from '../../commons/Inputs/InputLabel';
import Button from '../../commons/Button';
import { getImageErrorMessage } from '../../utils/common';

//Actions
import {
  ferryPropsChanged,
  ferryInitialState,
  editFerryConfirm,
  getFerrybyId,
} from '../../actions';
//Reducers
import { RootState } from '../../reducers';
import cover from '../../assets/images/lg-thumbnail.png';
import { FerryFormProps, FerryStateError } from './ferry.types';
import InputFile from '../../commons/Inputs/InputFile';
import { CircularProgress } from '@material-ui/core';
import Text from '../../commons/Text';
import moment from 'moment';
import ColorPicker from '../../commons/ColorPicker';
import FerryWireframeAndroid from '../../components/FerryWireframeAndroid';
import FerryWireframeIPhone from '../../components/FerryWireframeIPhone';
import {
  isNumber,
  getNatural,
  getDecimal,
  isUrl,
  isEmail,
  isPhoneNumber,
} from '../../utils/validations';
import { getDataFromCache } from '../../utils/cache';
import Icon from 'react-icons-kit';
import { ic_warning } from 'react-icons-kit/md/ic_warning';
import { convertToMoment } from '../../utils/common';
import maxChars from '../../utils/fieldsMaxLength';
import BarcodeTypeSelection from '../../components/barcodeSelection';
import SecurityOptions from '../../components/SecurityOptions';

const EditFerry: React.FC<FerryFormProps> = (props) => {
  const intl = useIntl();
  const [hasStateChanged, setHasStateChange] = React.useState<boolean>(false);
  const [switchPreview, setSwitchPreview] = React.useState<string>('android');
  const [QRcode, setQRcode] = React.useState<any>({ state: true, value: '' });
  const {
    walletId,
    ferryId,
    loading,
    onSaveChanged,
    image,
    labelColor,
    backgroundColor,
    foregroundColor,
    number,
    originStationCode,
    originName,
    destinationStationCode,
    destinationName,
    departureTime,
    arrivalTime,
    tarif,
    barcodeType,
    details,
    terms,
    enableOneTimeUse,
    disableIOSPassSharing,
    disableMultipleHolders,
    enableSecurityAnimation,
    enableRotatingBarcode,
    disableEmailSharing,
    customerServiceNumber,
    departureTimeError,
    numberError,
    arrivalTimeError,
    onCancel,
  } = props;

  const handleFerryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const fieldName = event.target.name;

    setHasStateChange(true);

    props.ferryPropsChanged(`${fieldName}Error`, '');
    if (value == '') {
      props.ferryPropsChanged(fieldName, value);
    }

    if (fieldName === 'number') {
      if (!isNumber(value)) {
        props.ferryPropsChanged(
          `${fieldName}Error`,
          intl.formatMessage({ id: 'common.label.invalidNumber' })
        );
      }
    }

    props.ferryPropsChanged(fieldName, value);
  };

  const handleColorChange = (color: any, prop: string) => {
    props.ferryPropsChanged(prop, color);
  };

  const handleFileChange = (event: any, prop: string) => {
    const file = event.target.files[0];
    if (!['image/jpeg', 'image/png'].includes(file.type)) {
      props.ferryPropsChanged(`${prop}Error`, getImageErrorMessage(file));
      return;
    }
    props.ferryPropsChanged(prop, file);
  };
  const handleDateChange = (prop: string) => (date: Date | null) => {
    props.ferryPropsChanged(prop, moment(date));
  };
  const handleClickConfirm = () => {
    //setHasStateChange(false);
    props.editFerryConfirm(walletId, ferryId, props, () => onSaveChanged());
  };
  const getFormatedMessage = (prop: FerryStateError) => {
    return props[prop] !== ''
      ? intl.formatMessage({ id: props[prop] })
      : props[prop];
  };
  const handleSelectChange = (event: any) => {
    props.ferryPropsChanged('source', event.target.value);
  };

  const handlePreviewChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSwitchPreview((event.target as HTMLInputElement).value);
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Text color='#404040' fontSize={16} fontWeight={600} lineHeight={24}>
            <span className='title'>Modifier Transit</span>
          </Text>
        </div>
        <div style={{ display: 'flex' }}>
          <Button onClick={onCancel}>
            {intl.formatMessage({ id: 'settings.button.cancel' })}
          </Button>
          <Button width={135} onClick={handleClickConfirm}>
            {loading ? (
              <CircularProgress size={20} color='inherit' />
            ) : (
              intl.formatMessage({ id: 'ship.button.add' })
            )}
          </Button>
        </div>
      </div>
      <Wrapper>
        <div className='section'>
          <div className='section-one '>
            <InputFile
              className='input-label'
              width={270}
              label={intl.formatMessage({ id: 'ship.label.logo' })}
              image={image}
              defaultImage={cover}
              error={getFormatedMessage('imageError')}
              onChange={(e) => {
                handleFileChange(e, 'image');
              }}
            ></InputFile>
            <div className='div-content' style={{ marginLeft: '30px' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '26px',
                }}
              >
                <ColorPicker
                  label={intl.formatMessage({ id: 'coupon.label.textColor' })}
                  color={labelColor}
                  error={getFormatedMessage('labelColorError')}
                  onChange={(color: any) => {
                    handleColorChange(color, 'labelColor');
                  }}
                />
                <ColorPicker
                  label={intl.formatMessage({
                    id: 'coupon.label.foregroundColor',
                  })}
                  color={foregroundColor}
                  error={getFormatedMessage('foregroundColorError')}
                  onChange={(color: any) => {
                    handleColorChange(color, 'foregroundColor');
                  }}
                />
                <ColorPicker
                  label={intl.formatMessage({
                    id: 'coupon.label.backgroundColor',
                  })}
                  color={backgroundColor}
                  error={getFormatedMessage('backgroundColorError')}
                  onChange={(color: any) => {
                    handleColorChange(color, 'backgroundColor');
                  }}
                />
              </div>
            </div>
            <div className='section-row'>
              <BarcodeTypeSelection
                setSelectedBarcode={(barcodeType) => {
                  props.ferryPropsChanged('barcodeType', barcodeType);
                }}
                barcodeType={barcodeType}
              ></BarcodeTypeSelection>
              <SecurityOptions
                handleSecurityPropsChange={(name: string, value: boolean) => {
                  props.ferryPropsChanged(name, value);
                }}
                {...{
                  enableOneTimeUse,
                  disableIOSPassSharing,
                  disableMultipleHolders,
                  enableSecurityAnimation,
                  enableRotatingBarcode,
                  disableEmailSharing,
                }}
              ></SecurityOptions>
            </div>
            <div>
              <InputLabel
                label={intl.formatMessage({ id: 'ship.label.number' })}
                placeholder={intl.formatMessage({ id: 'ship.label.number' })}
                name='number'
                value={number}
                error={getFormatedMessage('numberError')}
                onChange={(e) => handleFerryChange(e)}
              />
              <InputLabel
                label={intl.formatMessage({
                  id: 'ship.label.originStationCode',
                })}
                placeholder='station code'
                name='originStationCode'
                value={originStationCode}
                error={getFormatedMessage('originStationCodeError')}
                limit={
                  maxChars.transit.originStationCode ||
                  maxChars.originStationCode
                }
                onChange={(e) => handleFerryChange(e)}
              />
              <InputLabel
                label={intl.formatMessage({
                  id: 'transitCard.label.originName',
                })}
                placeholder='Paris'
                name='originName'
                value={originName}
                error={getFormatedMessage('originNameError')}
                limit={maxChars.transit.originName || maxChars.originName}
                onChange={(e) => handleFerryChange(e)}
              />
              <InputLabel
                label={intl.formatMessage({
                  id: 'transit.label.destinationStationCode',
                })}
                placeholder=''
                name='destinationStationCode'
                value={destinationStationCode}
                error={getFormatedMessage('destinationStationCodeError')}
                limit={
                  maxChars.transit.destinationStationCode ||
                  maxChars.destinationStationCode
                }
                onChange={(e) => handleFerryChange(e)}
              />
              <InputLabel
                label={intl.formatMessage({
                  id: 'transitCard.label.destinationName',
                })}
                placeholder=''
                name='destinationName'
                value={destinationName}
                error={getFormatedMessage('destinationNameError')}
                limit={
                  maxChars.transit.destinationName || maxChars.destinationName
                }
                onChange={(e) => handleFerryChange(e)}
              />

              <TimePicker
                required
                fullWidth
                margin='normal'
                ampm={false}
                label={intl.formatMessage({ id: 'ship.label.departureTime' })}
                inputVariant='outlined'
                value={convertToMoment(departureTime)}
                onChange={handleDateChange('departureTime')}
                error={departureTimeError !== ''}
                helperText={getFormatedMessage('departureTimeError')}
              />

              <TimePicker
                required
                fullWidth
                margin='normal'
                ampm={false}
                label={intl.formatMessage({ id: 'ship.label.arrivalTime' })}
                inputVariant='outlined'
                value={convertToMoment(arrivalTime)}
                onChange={handleDateChange('arrivalTime')}
                error={arrivalTimeError !== ''}
                helperText={getFormatedMessage('arrivalTimeError')}
              />

              <InputLabel
                label={intl.formatMessage({ id: 'ship.label.tarif' })}
                placeholder='15$'
                name='tarif'
                value={tarif}
                error={getFormatedMessage('tarifError')}
                onChange={(e) => handleFerryChange(e)}
              />
              <InputLabel
                label={intl.formatMessage({
                  id: 'ship.label.customerServiceNumber',
                })}
                placeholder='numéro Télephone'
                name='customerServiceNumber'
                value={customerServiceNumber}
                error={getFormatedMessage('customerServiceNumberError')}
                limit={
                  maxChars.transit.customerServiceNumber ||
                  maxChars.customerServiceNumber
                }
                onChange={(e) => handleFerryChange(e)}
              />
              <InputLabel
                label={intl.formatMessage({ id: 'ship.label.details' })}
                placeholder='description...'
                name='details'
                value={details}
                error={getFormatedMessage('detailsError')}
                limit={maxChars.transit.details || maxChars.details}
                onChange={(e) => handleFerryChange(e)}
              />
              <InputLabel
                label={intl.formatMessage({ id: 'ship.label.terms' })}
                placeholder='terms'
                name='terms'
                value={terms}
                error={getFormatedMessage('termsError')}
                limit={maxChars.transit.terms || maxChars.terms}
                onChange={(e) => handleFerryChange(e)}
              />
            </div>
          </div>
          <div className='section-two '>
            <div
              className=' wireframe-section'
              style={{ marginLeft: 25, marginRight: 25, maxWidth: 470 }}
            >
              <RadioGroup
                name='previews'
                value={switchPreview}
                onChange={handlePreviewChange}
                className='radioGroup'
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <FormControlLabel
                  value='android'
                  control={<Radio />}
                  label='Android'
                />
                <FormControlLabel value='iOS' control={<Radio />} label='iOS' />
              </RadioGroup>

              {switchPreview === 'android' && (
                <FerryWireframeAndroid
                  backgroundColor={backgroundColor}
                  strip={image}
                  number={number}
                  originStationCode={originStationCode}
                  originName={originName}
                  destinationName={destinationName}
                  destinationStationCode={destinationStationCode}
                  departureTime={departureTime}
                  arrivalTime={arrivalTime}
                  tarif={tarif}
                  customerServiceNumber={customerServiceNumber}
                  details={details}
                  terms={terms}
                  QrCode={QRcode}
                  name={'wallet name'}
                  barcodeType={barcodeType}
                  animatedBorder={enableSecurityAnimation}
                />
              )}

              {switchPreview === 'iOS' && (
                <FerryWireframeIPhone
                  backgroundColor={backgroundColor}
                  strip={image}
                  number={number}
                  originStationCode={originStationCode}
                  originName={originName}
                  destinationName={destinationName}
                  destinationStationCode={destinationStationCode}
                  departureTime={departureTime}
                  arrivalTime={arrivalTime}
                  tarif={tarif}
                  customerServiceNumber={customerServiceNumber}
                  details={details}
                  terms={terms}
                  QrCode={QRcode}
                  titleColor={labelColor.hex}
                  textColor={foregroundColor.hex}
                  barcodeType={barcodeType}
                />
              )}
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

const Wrapper = styled('div')`
  ${(props) => shipStyles(props)}
`;

const mapStateToProps = ({ ferry }: RootState) => {
  const {
    loading,
    image,
    strip,
    labelColor,
    backgroundColor,
    foregroundColor,
    barcodeType,
    number,
    originStationCode,
    originName,
    destinationStationCode,
    destinationName,
    departureTime,
    arrivalTime,
    tarif,
    details,
    terms,
    customerServiceNumber,
    enableOneTimeUse,
    disableIOSPassSharing,
    disableMultipleHolders,
    enableSecurityAnimation,
    enableRotatingBarcode,
    disableEmailSharing,
    //errors
    stripError,
    labelColorError,
    backgroundColorError,
    foregroundColorError,

    numberError,
    originStationCodeError,
    originNameError,
    destinationStationCodeError,
    destinationNameError,
    departureTimeError,
    arrivalTimeError,
    tarifError,
    detailsError,
    termsError,
    customerServiceNumberError,
    imageError,
  } = ferry;
  return {
    loading,
    image,
    strip,
    labelColor,
    backgroundColor,
    foregroundColor,
    barcodeType,
    number,
    originStationCode,
    originName,
    destinationStationCode,
    destinationName,
    departureTime,
    arrivalTime,
    tarif,
    details,
    terms,
    customerServiceNumber,
    enableOneTimeUse,
    disableIOSPassSharing,
    disableMultipleHolders,
    enableSecurityAnimation,
    enableRotatingBarcode,
    disableEmailSharing,
    //errors
    stripError,
    labelColorError,
    backgroundColorError,
    foregroundColorError,

    numberError,
    originStationCodeError,
    originNameError,
    destinationStationCodeError,
    destinationNameError,
    departureTimeError,
    arrivalTimeError,
    tarifError,
    detailsError,
    termsError,
    customerServiceNumberError,
    imageError,
  };
};

export const connector = connect(mapStateToProps, {
  ferryPropsChanged,
  ferryInitialState,
  getFerrybyId,
  editFerryConfirm,
});

export default connector(EditFerry);
