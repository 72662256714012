import moment, { Moment } from 'moment';
import {
  getHotelsList,
  getHotelsChildrenList,
  removeCustomerHotel,
} from '../apis/hotelsApi';
import { removeHotel } from '../apis/hotelApi';

import {
  HOTELS_GET_LIST_HOTELS,
  HOTELS_INITIAL_STATE,
  AppThunk,
  HOTELS_PROPS_CHANGED,
  HOTELS_FILTER_HOTELS,
  HOTELS_DELETE_HOTEL_LOADING,
  HOTELS_DELETE_HOTEL,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
  HOTELS_GET_SENT_HOTELS,
  HOTELS_DELETE_CUSTOMER_HOTEL,
} from './types';
import { HotelPassApiResponse } from '../apis/ApiTypes';
import {
  HotelsChangedAction,
  HotelsInitialStateAction,
} from '../modules/Hotels/hotels.types';

export const hotelsGetAllHotels = (
  walletId: string,
  searchFilter: any
): AppThunk => (dispatch) => {
  getHotelsList(walletId, searchFilter).then(
    (res: Array<HotelPassApiResponse>) => {
      dispatch({
        type: HOTELS_GET_LIST_HOTELS,
        payload: res,
      });
    }
  );
};

export const hotelsPropsChanged = (
  prop: string,
  value: any
): HotelsChangedAction => {
  return { type: HOTELS_PROPS_CHANGED, prop, value };
};

export const deleteHotel = (hotelId: string, walletId: string): AppThunk => (
  dispatch
) => {
  dispatch({
    type: HOTELS_DELETE_HOTEL_LOADING,
    payload: true,
  });
  removeHotel(hotelId, walletId).then((res) => {
    if (res.data.status === 'success') {
      dispatch({
        type: HOTELS_DELETE_HOTEL,
        payload: hotelId,
      });
      dispatch({
        type: SNACKBAR_SUCCESS,
        value: 'room.success.deleted',
      });
    } else
      dispatch({
        type: SNACKBAR_ERROR,
        value: 'wallet.error.occured',
      });
  });
};
export const hotelsGetChildrens = (
  walletId: string,
  ticketId: string,
  page: number | string,
  size: number | string,
  searchFilter: any
): AppThunk => (dispatch) => {
  getHotelsChildrenList(walletId, ticketId, page, size, searchFilter).then(
    (res: any) => {
      if (res.status === 500) {
        dispatch({
          type: SNACKBAR_ERROR,
          value: 'wallet.error.occured',
        });
      } else if (res.status === 401) {
        dispatch({
          type: SNACKBAR_ERROR,
          value: 'Id malformés',
        });
        hotelsPropsChanged('loading', false);
      } else
        dispatch({
          type: HOTELS_GET_SENT_HOTELS,
          payload: res.data,
          total: res.total,
        });
    }
  );
};

export const deleteCustomerHotel = (
  hotelId: string,
  customerId: string,
  passId: string,
  walletId: string
): AppThunk => (dispatch) => {
  dispatch({
    type: HOTELS_DELETE_HOTEL_LOADING,
    payload: true,
  });
  removeCustomerHotel(hotelId, customerId, walletId).then((res) => {
    if (res.data.status === 'success') {
      dispatch({
        type: HOTELS_DELETE_CUSTOMER_HOTEL,
        payload: passId,
      });

      dispatch({
        type: SNACKBAR_SUCCESS,
        value: 'room.success.deleted',
      });
    } else {
      dispatch({
        type: SNACKBAR_ERROR,
        value: 'wallet.error.occured',
      });
    }
  });
};
export const hotelsInitialState = (): HotelsInitialStateAction => {
  return { type: HOTELS_INITIAL_STATE };
};
