import axios from 'axios';
import { URL } from './config';
import { EventsApiResponse, EventsCustomersApiResponse } from './ApiTypes';

export const getEventsList = (walletID: string) => {
  const requestURL = `${URL}/wallets/${walletID}/events`;

  return axios
    .get(requestURL)
    .then(({ data }: { data: Array<EventsApiResponse> }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const getEventsCustomersList = (walletID: string, eventID: string) => {
  const requestURL = `${URL}/wallets/${walletID}/events/${eventID}/customers`;

  return axios
    .get(requestURL)
    .then(({ data }: { data: [EventsCustomersApiResponse] }) => {
      return data;
    })
    .catch((error) => {
      return error.response;
    });
};
