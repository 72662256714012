import { createBus, getBus, updateBus, editCustomerBus } from '../apis/busApi';
import {
  BUS_FORM_PROPS,
  BUS_FORM_PROPS_CHANGED,
  AppThunk,
  BUSES_ADD_BUS_TO_LIST,
  BUS_INITIAL_STATE,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
  BUS_GET_BUS_PROPS,
  BUSES_GET_LIST_BUSES,
  EDIT_BUS_INITIAL_STATE,
  BUS_PROPS_CHANGED,
  UPDATE_SENT_BUSES_LIST,
  CUSTOMER_BUS_FORM_PROPS_CHANGED,
  CUSTOMER_BUS_FORM_PROPS,
} from './types';
import {
  BusInitialStateAction,
  BusPropsChangedAction,
  BusState,
  CustomerBusPropsChangedAction,
} from '../modules/Bus/bus.types';

import {
  validateFieldsBus,
  newBusObject,
  validateCustomerFieldsBus,
} from '../modules/Bus/bus.utils';
import { URL_IMAGE } from '../apis/config';
import { getBusesList } from '../apis/busesApi';
import { TransitsApiResponse } from '../apis/ApiTypes';
import moment from 'moment';

export const busPropsChanged = (
  prop: string,
  value: any
): BusPropsChangedAction => {
  return { type: BUS_FORM_PROPS_CHANGED, prop, value };
};

export const addBus = (
  walletId: string | null,
  props: BusState,
  callback: () => void
): AppThunk => (dispatch) => {
  const { newState, valid } = validateFieldsBus(props);
  dispatch({ type: BUS_FORM_PROPS, newState });
  const newBus = newBusObject(newState);

  if (valid) {
    dispatch({
      type: BUS_FORM_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });

    createBus(walletId, newBus).then((res: any) => {
      if (res.status == 200) {
        dispatch({
          type: BUSES_ADD_BUS_TO_LIST,
          payload: res.data,
        });
        dispatch({
          type: SNACKBAR_SUCCESS,
          value: 'transit.success.added',
        });
        callback();
      } else {
        dispatch({
          type: SNACKBAR_ERROR,
          value: 'wallet.error.occured',
        });
        dispatch({
          type: BUS_FORM_PROPS_CHANGED,
          prop: 'loading',
          value: false,
        });
      }
    });
  }
};

export const getBusbyId = (walletId: string, busId: string): AppThunk => (
  dispatch
) => {
  getBus(walletId, busId).then((res: any) => {
    const bus = res.response.data;
    dispatch({
      type: BUS_GET_BUS_PROPS,
      payload: {
        ...bus,
      },
    });
  });
};

export const editBusConfirm = (
  walletId: string | null,
  busId: string | null,
  props: BusState,
  callback: () => void
): AppThunk => (dispatch) => {
  const { newState, valid } = validateFieldsBus(props);
  dispatch({ type: BUS_FORM_PROPS, newState });
  const newBus = newBusObject(newState);
  if (valid) {
    dispatch({
      type: BUS_FORM_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });

    updateBus(walletId, busId, newBus)
      .then((res: any) => {
        if (res.status === 200) {
          dispatch({
            type: SNACKBAR_SUCCESS,
            value: 'transit.success.update',
          });

          callback();
        } else if (res.status === 400) {
          dispatch({
            type: BUS_FORM_PROPS_CHANGED,
            prop: 'messageError',
            value: 'coupon.error.datesUnavailable',
          });
          dispatch({
            type: SNACKBAR_ERROR,
            value: 'wallet.error.occured',
          });
        }
        dispatch({
          type: BUS_FORM_PROPS_CHANGED,
          prop: 'loading',
          value: false,
        });
      })
      .finally(() => {
        getBusesList(walletId as any).then(
          (res: Array<TransitsApiResponse>) => {
            dispatch({
              type: BUSES_GET_LIST_BUSES,
              payload: res,
            });
          }
        );
      });
  }
};

export const busInitialState = (): BusInitialStateAction => {
  return { type: BUS_INITIAL_STATE };
};

export const editBusInitialState = (bus: any): any => {
  return {
    type: EDIT_BUS_INITIAL_STATE,
    prop: 'payload',
    value: {
      ...bus,
      image: URL_IMAGE + '/' + bus.transitPictureUrl,
    },
  };
};
// export const editBusCustomerConfirm = (
//   walletId: string | null,
//   busId: string | null,
//   customerId: string | null,
//   originalBusId: string | null,
//   props: BusState,
//   callback: () => void
// ): AppThunk => (dispatch) => {
//   const { newState, valid } = validateCustomerFieldsBus(props);
//   dispatch({ type: BUS_FORM_PROPS, newState });
//   newState.customerId = customerId;
//   const { formData: newBus, objectData } = newCustomerBusObject(newState);

//   if (valid) {
//     dispatch({
//       type: BUS_PROPS_CHANGED,
//       prop: 'loading',
//       value: true,
//     });

//     editBus(walletId, busId, newBus)
//       .then((res: any) => {
//         if (res.status === 200) {
//           dispatch({
//             type: SNACKBAR_SUCCESS,
//             value: 'Le transit a été modifié avec succès',
//           });

//           callback();
//         } else if (res.status === 400) {
//           dispatch({
//             type: BUS_PROPS_CHANGED,
//             prop: 'messageError',
//             value: 'coupon.error.datesUnavailable',
//           });
//           dispatch({
//             type: SNACKBAR_ERROR,
//             value: "wallet.error.occured",
//           });
//         }
//         dispatch({
//           type: BUS_PROPS_CHANGED,
//           prop: 'loading',
//           value: false,
//         });
//       })
//       .finally(() => {
//         dispatch({
//           type: UPDATE_SENT_BUSES_LIST,
//           row: { ...objectData, _id: busId },
//         });
//       });
//   }
// };

export const editBusCustomerConfirm = (
  walletId: string | null,
  busId: string | null,
  customerId: string | null,
  props: BusState,
  callback: () => void
): AppThunk => (dispatch) => {
  const { newState, valid } = validateCustomerFieldsBus(props);
  dispatch({ type: CUSTOMER_BUS_FORM_PROPS, newState });
  const { passengerType, tripType, arrivalDate, departureDate } = newState;
  const busObject = {
    passengerType,
    tripType,
    arrivalDate: arrivalDate.format('YYYY-MM-DD').slice(0, 10),
    departureDate: moment(departureDate).format('YYYY-MM-DD'),
    customerId,
  };

  if (valid) {
    dispatch({
      type: BUS_FORM_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });
    editCustomerBus(walletId, busId, busObject)
      .then((res: any) => {
        if (res.status === 200) {
          dispatch({
            type: SNACKBAR_SUCCESS,
            value: 'transit.success.update',
          });

          callback();
        } else if (res.status === 400) {
          dispatch({
            type: SNACKBAR_ERROR,
            value: 'wallet.error.occured',
          });
        }
        dispatch({
          type: BUS_FORM_PROPS_CHANGED,
          prop: 'loading',
          value: false,
        });
      })
      .finally(() => {
        getBusesList(walletId as any).then(
          (res: Array<TransitsApiResponse>) => {
            dispatch({
              type: UPDATE_SENT_BUSES_LIST,
              payload: res,
            });
          }
        );
      });
  }
};

export const customerBusPropsChanged = (
  prop: string,
  value: any
): CustomerBusPropsChangedAction => {
  return { type: CUSTOMER_BUS_FORM_PROPS_CHANGED, prop, value };
};
