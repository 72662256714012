import { CinemasTicketsApiResponse } from '../apis/ApiTypes';
import {
  getCinemaTicketsChildrenList,
  getMoviesTicketsList,
} from '../apis/cinemaTicketsApi';
import {
  AppThunk,
  CINEMATICKETS_GET_LIST_CINEMATICKETS,
  CINEMATICKETS_GET_SENT_CINEMATICKETS,
  CINEMATICKETS_PROPS_CHANGED,
  SNACKBAR_ERROR,
  WALLET_FORM_PROPS_CHANGED,
} from './types';

export const getAllMoviesTickets = (
  walletId: string,
  searchFilter: any
): AppThunk => (dispatch) => {
  getMoviesTicketsList(walletId, searchFilter).then(
    (res: Array<CinemasTicketsApiResponse>) => {
      dispatch({
        type: CINEMATICKETS_GET_LIST_CINEMATICKETS,
        payload: res,
      });
    }
  );
};

export const cinemaTicketsPropsChanged = (prop: string, value: any): any => {
  return { type: CINEMATICKETS_PROPS_CHANGED, prop, value };
};

export const cinemaTicketsGetChildrens = (
  walletId: string,
  ticketId: string,
  page: number | string,
  size: number | string,
  searchFilter: any
): AppThunk => (dispatch) => {
  getCinemaTicketsChildrenList(
    walletId,
    ticketId,
    page,
    size,
    searchFilter
  ).then((res: any) => {
    if (res.status === 500) {
      dispatch({
        type: SNACKBAR_ERROR,
        value: 'wallet.error.occured',
      });
    } else if (res.status === 401) {
      dispatch({
        type: SNACKBAR_ERROR,
        value: 'Id malformés',
      });
      cinemaTicketsPropsChanged('loading', false);
    } else
      dispatch({
        type: CINEMATICKETS_GET_SENT_CINEMATICKETS,
        payload: res.data,
        total: res.total,
      });
  });
};
