import React from 'react';

function Icon() {
  return (
    <svg
      width='85'
      height='123'
      viewBox='0 0 122 123'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      {' '}
      <g filter='url(#filter0_d_180_9308)'>
        {' '}
        <rect
          x='61'
          y='21'
          width='52'
          height='52'
          rx='7'
          transform='rotate(45 61 21)'
          fill='white'
        />{' '}
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M82.9372 55.7137C81.7367 55.0344 81.1447 54.8968 75.6494 54.0201C73.3414 53.652 71.4036 53.2955 71.343 53.228C71.1849 53.0522 67.6636 47.554 67.6663 47.4873C67.6675 47.4567 68.0674 47.319 68.5551 47.1814C69.2911 46.9736 69.5248 46.8636 69.9303 46.5341C70.3088 46.2264 70.4461 46.0614 70.5405 45.8002C71.0287 44.4499 68.8577 43.1833 65.9126 43.1004L64.8005 43.0691L63.351 40.8153L61.9013 38.5613L59.629 38.2902C58.3792 38.141 57.3548 38.0652 57.3525 38.1218C57.3503 38.1782 58.4308 41.5372 59.7536 45.5862C61.0764 49.6351 62.157 52.9896 62.155 53.0404C62.1516 53.1273 48.0512 53.9615 47.7777 53.8911C47.7082 53.8733 46.9681 52.7635 46.133 51.4249L44.6146 48.9912L43.5633 48.9406L42.5119 48.8899L42.2266 56.1159L41.9412 63.3418L42.9841 63.3855C43.632 63.4126 44.0598 63.3814 44.1137 63.3029C44.7919 62.3151 47.53 58.7462 47.6064 58.7505C48.416 58.7965 61.8536 60.6839 61.8509 60.7513C61.8489 60.8028 60.5074 64.0623 58.8699 67.9948C57.2323 71.9273 55.8906 75.1906 55.8884 75.2469C55.8862 75.303 56.9134 75.3075 58.1711 75.2569L60.4578 75.1648L62.0763 73.037L63.6947 70.9092L64.8105 70.9604C66.6456 71.0445 68.1822 70.6933 69.0634 69.9881C69.8954 69.3223 69.904 68.622 69.0898 67.8464C68.7115 67.4862 68.4872 67.3582 67.7699 67.0933C67.2945 66.9179 66.9066 66.7492 66.9078 66.7186C66.9104 66.6519 70.8539 61.4469 71.0253 61.284C71.091 61.2215 73.0509 61.0181 75.3807 60.8321C80.148 60.4515 80.7508 60.3819 81.7161 60.1015C83.4209 59.6062 84.2721 58.843 84.3144 57.7717C84.3459 56.973 83.8481 56.2291 82.9372 55.7137Z'
          fill='#FF5078'
        />{' '}
      </g>{' '}
      <defs>
        {' '}
        <filter
          id='filter0_d_180_9308'
          x='0.130859'
          y='0.899414'
          width='121.738'
          height='121.74'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          {' '}
          <feFlood floodOpacity='0' result='BackgroundImageFix' />{' '}
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />{' '}
          <feOffset dy='4' /> <feGaussianBlur stdDeviation='13.5' />{' '}
          <feComposite in2='hardAlpha' operator='out' />{' '}
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0'
          />{' '}
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_180_9308'
          />{' '}
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_180_9308'
            result='shape'
          />{' '}
        </filter>{' '}
      </defs>{' '}
    </svg>
  );
}

export default Icon;
