import { css } from 'styled-components';

export const forgotPasswordStyles = (props: any) => {
  return css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-radius: 10px;
    .div-container {
      display: flex;
      height: 60%;
      width: 80%;
      justify-content: center;
      align-items: center;
    }
  `;
};
