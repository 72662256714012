import React from 'react';

function Icon() {
    return (
        <svg width="19" height="160" viewBox="0 0 19 160" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="19" y="77" width="1" height="19" transform="rotate(90 19 77)" fill="#404040" />
            <rect x="19" y="159" width="1" height="19" transform="rotate(90 19 159)" fill="#404040" />
            <rect x="1" y="160" width="1.00001" height="160" transform="rotate(-180 1 160)" fill="#404040" />
        </svg>
    );
}

export default Icon;
