import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      width: 340,
      maxHeight: 240,
      background: 'linear-gradient(180deg, #FDF3FE 0%, #FFFFFF 100%)',
      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
      padding: '14px',
      borderRadius: '20px',
      boxSizing: 'content-box',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    cardItem: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    cardItemNum: {
      display: 'flex',
      alignItems: 'center',
    },

    cardItemID: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '8px',
    },
    cardHeader: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    menuIcon: {
      fill: '#ffffff',
    },
    cardPosition: {
      '& .MuiPaper-root .MuiMenu-paper': {
        top: '176px',
        left: '324px',
      },
    },
    '$root.Mui-MuiMenu-paper &': {
      top: '176px',
      left: '324px',
    },
    cardFirstSection: {
      display: 'flex',
      height: '80%',
      alignItems: 'center',
    },
    cardSecendSection: {
      display: 'flex',
      justifyContent: 'center',
    },
    cardInfos: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: '16px',
    },
    cardLogo: {
      marginLeft: -16,
    },
    cardItemsInfo: {
      display: 'flex',
      flexDirection: 'column',
    },
  })
);
