import {
  EventsCustomersState,
  EventsCustomersActionTypes,
} from '../modules/EventCustomers/eventCustomers.types';

import {
  EVENTS_CUSTOMERS_INITIAL_STATE,
  EVENTS_GET_CUSTOMERS_LIST,
  EVENT_CUSTOMERS_PROPS_CHANGED,
  EVENT_FILTER_CUSTOMERS,
} from '../actions/types';
import { EventsCustomersApiResponse } from '../apis/ApiTypes';

const INITIAL_STATE: EventsCustomersState = {
  eventsCustomersList: [],
  loading: true,
  search: '',
  select: 'firstName',
};

export default (state = INITIAL_STATE, action: EventsCustomersActionTypes) => {
  switch (action.type) {
    case EVENTS_GET_CUSTOMERS_LIST:
      return {
        ...state,
        eventsCustomersList: action.payload,
        loading: false,
      };
    case EVENT_CUSTOMERS_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };
    case EVENT_FILTER_CUSTOMERS: {
      return {
        ...state,
        search: action.search,
        eventsCustomersList: [
          { ...state.eventsCustomersList[0], customers: action.customers },
        ],
      };
    }
    case EVENTS_CUSTOMERS_INITIAL_STATE:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
