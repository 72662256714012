import { css } from 'styled-components';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const walletDetailStyles = (props: { background?: string }) => {
  return css`
    display: flex;
    flex-direction: column;
    /* padding: 10px; */
    .MuiTablePagination-root {
      float: right;
    }

    .div-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 30px 0;
    }
    .div-input-filter {
      min-width: 400px;
      margin-right: 15px;
    }
    .select-input {
      width: 150px;
      visibility: hidden;
      position: absolute;
      left: 260px;
      top: 70px;
    }
    .filter-button {
      display: flex;
      position: absolute;
      left: 290px;
      width: 100px;
      align-items: center;
      justify-content: space-between;
      background: #691fde;
      height: 44px;
      box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.08);
      border-radius: 15px;
      padding: 0 10px;
      transition: all 0.3s ease;
      :hover {
        cursor: pointer;
        background: #6438f5;
      }
      > h3 {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: white;
        width: 80px;
      }
    }
    .filter-opened {
      transform: rotate(180deg);
    }
    .filter-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      > input {
        background-color: #fff;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #404040;
        box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.08);
        border-radius: 15px;
        width: 480px;
      }
    }
    .div-input-search {
      flex: 1;
      position: relative;
      margin-left: 15px;
      min-width: 400px;
      max-width: 680px;
      .input-search {
        box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.08);
        border-radius: 15px;
      }
      .button-search {
        position: absolute;
        top: 10px;
        right: -1px;
        margin: 0;
        height: 53px;
        border-radius: 15px;
      }
    }

    .table-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 15px;
    }
    .text-error {
      font-size: 24px;
      color: red;
    }
    label.Mui-focused {
      color: #ff5078;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #ff5078 !important;
      border-width: 2px;
    }
    .MuiListItem-root.Mui-selected .MuiListItem-root.Mui-selected:hover {
      background-color: transparent;
    }

    .selectedRows {
      font-size: 0.875rem;
      font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
      font-weight: 400;
      line-height: 1.43;
      letter-spacing: 0.01071em;
    }

    .div-search {
      display: flex;
      align-items: center;
      margin-top: 30px;
      margin-bottom: 15px;
      /* width: 100%; */
      .div-search-inputs {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: space-between;
      }

      .div-search-date {
        display: flex;
        margin: 0 30px;
        gap: 15px;
        /* position: relative; */
        /* .div-checkbox {
          position: absolute;
          bottom: -35px; */
      }
    }
  `;
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },

    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },

    buttonProgressImport: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      color: 'white',
      marginTop: -12,
      marginLeft: -117,
    },

    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
      display: 'flex',
      flexDirection: 'row-reverse',
    },
    circularProgress: {
      color: '#ff5078',
    },
    modalDelete: {
      height: '160px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    importDialog: {
      width: 550,
      height: 'auto',
      textAlign: 'center',
      fontSize: 18,
    },
    modalRelance: {
      height: '160px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    search: {
      display: 'flex !important',
      alignItems: 'center',
    },

    inputSearch: {
      boxShadow: '0px 0px 20px 2px rgba(55, 55, 55, 0.08)',
      borderRadius: '15px',
      height: '43px !important',
      minHeight: '43px !important',
    },

    buttonSearch: {
      position: 'absolute',
      right: '106px',
      width: 'fit-content !important',
      height: '43px  !important',
    },
    relaunchButton: {
      width: 'max-content !important',
      height: '43px !important',
    },
    tableBottom: {
      display: 'flex',
      alignContent: 'center',
      justifyContent: ' space-between',
      alignItems: 'center',
    },
    buttonSendAll: {
      background: 'white !important',
      color: '#ff5078 !important',
      borderColor: '#ff5078 !important',
      borderStyle: 'solid !important',
      borderWidth: '1px !important',
      width: 'max-content !important',
      height: '43px !important',
    },
  })
);
