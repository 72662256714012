import React from 'react';
import { Wrapper } from './hotelWireframe.style';

import { ic_close } from 'react-icons-kit/md/ic_close';
import { ic_call } from 'react-icons-kit/md/ic_call';
import { ic_email } from 'react-icons-kit/md/ic_email';
import { ic_location_on } from 'react-icons-kit/md/ic_location_on';

import { ratio } from 'wcag-color';
import barcode from '../../assets/images/barcode.png';

import cover from '../../assets/images/lg-thumbnail.png';

import { ic_more_vert } from 'react-icons-kit/md/ic_more_vert';
import Icon from 'react-icons-kit';

import moment from 'moment';

import { getDataFromCache } from '../../utils/cache';
import { URL_IMAGE } from '../../apis/config';
import QRCode from 'qrcode.react';
// import Ferry from '../../icons/Ferry';
import { convertToMoment } from '../../utils/common';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useIntl } from 'react-intl';

import CODE128 from '../../assets/images/code128.png';
import PDF417 from '../../assets/images/pdf417.png';
import QR from '../../assets/images/qr.png';
import AZTEC from '../../assets/images/aztec.png';
import { switchBarcode } from '../../utils/common';

type WireframeProps = {
  backgroundColor: any;
  strip: any;
  number: any;
  name: any;
  terms: any;
  type: any;
  email: any;
  address: any;
  contactPhone: any;
  website: any;
  QrCode: any;
  logo?: any;
  barcodeType: string;
};

const HotelWireframeAndroid: React.FC<WireframeProps> = (props) => {
  const {
    backgroundColor,
    strip,
    number,
    name,
    terms,
    type,
    email,
    address,
    contactPhone,
    website,
    QrCode,
    logo,
    barcodeType,
  } = props;
  const currentWallet = getDataFromCache('wallet');
  const [showDetails, setShowDetails] = React.useState(false);
  const [height, setHeight] = React.useState<string>('');
  const intl = useIntl();
  const handleChange = () => {
    setShowDetails(!showDetails);
    height == '100%' ? setHeight('100%') : setHeight('100%');
  };
  const { barcode, barcodeClass } = switchBarcode(barcodeType, {
    CODE128,
    PDF417,
    QR,
    AZTEC,
  });

  return (
    <Wrapper
      backgroundColor={backgroundColor}
      mainContentTextColor={
        ratio('#ffffff', backgroundColor.hex) > 3.0 ? '#ffffff' : '#000000'
      }
      height={height}
    >
      <div className='mainSection'>
        <div className='mainSection-header'>
          <Icon
            size={18}
            icon={ic_close}
            style={
              ratio('#ffffff', backgroundColor.hex) > 3.0
                ? { color: '#ffffff' }
                : { color: '#000000' }
            }
          />
          <Icon
            size={18}
            icon={ic_more_vert}
            style={
              ratio('#ffffff', backgroundColor.hex) > 3.0
                ? { color: '#ffffff' }
                : { color: '#000000' }
            }
          />
        </div>
        <div className='mainSection-header-logo'>
          <img
            src={logo ? logo : `${URL_IMAGE}/${currentWallet.logoPictureUrl}`}
            alt='wallet Logo'
          />
        </div>
        <div className='mainSection-content-enseigne'>
          <div className='mainSection-issuerName'>Prenom Nom</div>
          <div className='mainSection-issuerName'>{name}</div>
          <div className='mainSection-issuerName'>{address}</div>
          <div className='mainSection-content-banner'>
            <img
              src={
                !strip
                  ? cover
                  : typeof strip === 'string'
                  ? strip
                  : URL.createObjectURL(strip)
              }
              alt='banner'
            />
          </div>
        </div>
        <div className='datasect'>
          <div className='date'>
            <h3 className='mainSection-data'>Type:</h3>
            <h3 className='mainSection-data'>{type}</h3>
          </div>
          <div className='date'>
            <h3 className='mainSection-data'>
              {intl.formatMessage({ id: 'ship.label.number' })}:
            </h3>{' '}
            <h3 className='mainSection-data'>{number}</h3>
          </div>
        </div>

        <div
          className={
            barcodeType === 'NONE'
              ? 'mainSection-content-barCode hidden'
              : 'mainSection-content-barCode'
          }
        >
          {' '}
          <div className='barCode'>
            <img className={barcodeClass} src={barcode} alt='barcode' />
          </div>
          <p className='walletId'>6066e4315abbcc1f9526d392</p>
        </div>
      </div>
      <div className='dataSection'>
        <div className='margins' onClick={handleChange}>
          {!showDetails ? (
            <div className='details_btn'>
              <ExpandMoreIcon color='primary' />
              Show details
            </div>
          ) : (
            <div className='details_btn'>
              <ExpandLessIcon />
              Hide details
            </div>
          )}
        </div>
      </div>
      {showDetails && (
        <div className='dataSection'>
          <div className='sectionLabel'>
            <h3 className='label'>BENEFICIARE DU BILLET</h3>
            <h3 className='data'>{'Nom Prenom'}</h3>
          </div>
          <div className='sectionLabel'>
            <h3 className='label'>ADDRESS</h3>
            <h3 className='data'>{address}</h3>
          </div>{' '}
          <div className='mainSection-content-banner'>
            <img
              src={
                !strip
                  ? cover
                  : typeof strip === 'string'
                  ? strip
                  : URL.createObjectURL(strip)
              }
              alt='banner'
            />
          </div>
          <div className='sectionLabel'>
            <h3 className='label'>CONDITION UTILISATION</h3>
            <h3 className='data'>{terms}</h3>
          </div>
          <div className='sectionIcon'>
            <Icon icon={ic_call} size={18} style={{ color: '#999999' }} />
            <h3 className='data'>{contactPhone}</h3>
          </div>
          <div className='sectionIcon'>
            <Icon icon={ic_email} size={18} style={{ color: '#999999' }} />
            <h3 className='data'>{website}</h3>
          </div>
        </div>
      )}
    </Wrapper>
  );
};

export default HotelWireframeAndroid;
