import axios from 'axios';
import { URL } from './config';

export const getRail = (walletId: string, railId: string) => {
  const requestURL = `${URL}/wallets/${walletId}/transits/${railId}`;

  return axios
    .get(requestURL)
    .then(({ data }: { data: any }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const createRail = (walledId: string | null, rail: any) => {
  // rail.locations = [
  //   {
  //     longitude: 0,
  //     latitude: 0,
  //   },
  // ];
  // rail.type = 'rail';
  const requestURL = `${URL}/wallets/${walledId}/transits?=rail`;
  return axios
    .post(requestURL, rail)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export const updateRail = (
  walledId: string | null,
  railId: string | null,
  rail: any
): Promise<any> => {
  const requestURL = `${URL}/wallets/${walledId}/transits/${railId}`;
  return axios
    .put(requestURL, rail)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export const removeRail = (railId: string, walletId: string) => {
  const requestURL = `${URL}/wallets/${walletId}/transits/${railId}`;

  return axios
    .delete(requestURL)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export const editRail = (
  walletId: string | null,
  railId: string | null,
  rail: any
) => {
  const requestURL = `${URL}/wallets/${walletId}/transits/${railId}/customer`;
  return axios
    .put(requestURL, rail)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};
