import 'date-fns';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { EventsStyles, EventsStylesProps } from './events.styles';
import EventCard from '../../components/Cards/EventCard';
import { useHistory } from 'react-router-dom';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
//Material
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';

import { KeyboardDatePicker } from '@material-ui/pickers';

import Button from '../../commons/Button';
import Input from '../../commons/Inputs/Input';
import Text from '../../commons/Text';

import EventModal from '../../components/Modal';

import emptyState from '../../assets/images/emptyState.svg';
import EmptyData from '../../components/EmptyData';
import { EventsApiResponse } from '../../apis/ApiTypes';

import {
  eventsGetAllEvents,
  eventsInitialState,
  eventsPropsChanged,
  eventsFilterEvents,
  deleteEvent,
} from '../../actions';

import { RootState } from '../../reducers';
import CreateEvent from '../CreateEvent';
import clsx from 'clsx';
import { getDataFromCache } from '../../utils/cache';
import { createEventStyles } from '../CreateEvent/createEvent.styles';

import { StyledCheckbox } from '../../commons/CheckBox/index';
import Calendar from '../../icons/Calendar';
import Divider from '@material-ui/core/Divider';
import { IconButton, TextField, InputAdornment } from '@material-ui/core';

const Events: React.FC<any> = (props) => {
  const intl = useIntl();
  const [selectOpener, setSelectOpener] = useState<boolean>(false);
  const {
    eventsList,
    eventsFilterList,
    startDate,
    endDate,
    allTime,
    loading,
    status,
  } = props;
  const [open, setOpen] = useState<boolean>(false);
  const history = useHistory();

  const objectUser: any = getDataFromCache('user');

  useEffect(() => {
    props.eventsGetAllEvents(objectUser.wallet);

    /* eslint-disable-next-line */
  }, []);

  // useEffect(() => {
  //   return () => {
  //     props.eventsInitialState();
  //   };
  // }, []);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.eventsPropsChanged(event.target.name, event.target.checked);
  };

  const handleDateChange = (prop: string) => (date: Date | null) => {
    props.eventsPropsChanged(prop, date);
  };

  const HandleClickSearch = () => {
    props.eventsFilterEvents(
      allTime,
      startDate,
      endDate,
      eventsFilterList,
      status
    );
  };

  const RedirectToCustomers = (url: string) => {
    history.push(url);
  };
  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    props.eventsPropsChanged('status', event.target.value);
  };

  const handleClickDeleteEvent = (eventId: string) => {
    props.deleteEvent(eventId, objectUser.wallet);
  };

  return (
    <Wrapper loading={loading.toString()} eventsLength={eventsList.length}>
      {/* <div className='div-search'>
        <FormControl className='filter-container' fullWidth>
          <Input
            value={
              status === 'ALL'
                ? intl.formatMessage({ id: 'offers.select.all' })
                : status === 'OPENED'
                ? intl.formatMessage({ id: 'offers.select.opened' })
                : status === 'ACTIF'
                ? intl.formatMessage({ id: 'offers.select.actif' })
                : status === 'INACTIF'
                ? intl.formatMessage({ id: 'offers.select.inactif' })
                : intl.formatMessage({ id: 'offers.select.expired' })
            }
            type='text'
            margin='0'
            disabled
          />
          <div
            className='filter-button'
            onClick={() => setSelectOpener(!selectOpener)}
          >
            <h3>
              {intl.formatMessage({
                id: 'wallet.label.filter',
              })}
            </h3>
            <ExpandMoreRoundedIcon
              style={{ color: 'white' }}
              className={selectOpener ? 'filter-opened' : ''}
            />
          </div>
          <Select
            className='select-input'
            value={status}
            onChange={handleSelectChange}
            placeholder={intl.formatMessage({
              id: 'wallet.label.filter',
            })}
            id='filter'
            inputProps={{ className: 'input' }}
            open={selectOpener}
            onClose={() => setSelectOpener(false)}
          >
            <MenuItem value='ACTIF'>
              <span>{intl.formatMessage({ id: 'offers.select.actif' })}</span>
            </MenuItem>
            <MenuItem value='INACTIF'>
              <span>{intl.formatMessage({ id: 'offers.select.inactif' })}</span>
            </MenuItem>
            <MenuItem value='EXPIRED'>
              <span>{intl.formatMessage({ id: 'offers.select.expired' })}</span>
            </MenuItem>
            <MenuItem value=''>
              <span>Tous</span>
            </MenuItem>
          </Select>
        </FormControl>
        <KeyboardDatePicker
          id='start-date'
          label={intl.formatMessage({ id: 'offers.label.startDate' })}
          format='MMM dd yyyy'
          value={startDate}
          onChange={handleDateChange('startDate')}
          inputVariant='outlined'
          TextFieldComponent={({ InputProps, ...rest }) => (
            <TextField
              {...rest}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <Divider
                      className='divider'
                      orientation='vertical'
                      variant='inset'
                      light
                    />
                    <IconButton
                      onClick={
                        (InputProps as any)?.endAdornment.props.children.props
                          .onClick
                      }
                    >
                      <Calendar />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />

        <KeyboardDatePicker
          id='end-date'
          label={intl.formatMessage({ id: 'offers.label.endDate' })}
          format='MMM dd yyyy'
          inputVariant='outlined'
          value={endDate}
          onChange={handleDateChange('endDate')}
          disabled={startDate === null ? true : false}
          TextFieldComponent={({ InputProps, ...rest }) => (
            <TextField
              {...rest}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <Divider
                      className='divider'
                      orientation='vertical'
                      variant='inset'
                      light
                    />
                    <IconButton
                      onClick={
                        (InputProps as any)?.endAdornment.props.children.props
                          .onClick
                      }
                    >
                      <Calendar />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />

        <FormControlLabel
          control={
            <StyledCheckbox
              checked={allTime}
              onChange={handleCheckboxChange}
              name='allTime'
            />
          }
          label={intl.formatMessage({ id: 'offers.label.allTime' })}
        />

        <Button background='#FF5078' onClick={HandleClickSearch}>
          {intl.formatMessage({ id: 'offers.button.search' })}
        </Button>
      </div> */}

      <div className='div-header'>
        <Text color='#404040' fontSize={24} lineHeight={36} fontWeight={600}>
          {intl.formatMessage({ id: 'events.label.title' })}
        </Text>
        <Button onClick={() => history.push('event/create')}>
          {intl.formatMessage({ id: 'events.button.create' })}
        </Button>
      </div>

      <EventModal height={700} open={open} onClose={() => setOpen(!open)}>
        <CreateEvent
          walletId={objectUser.wallet}
          onSaveChanged={() => setOpen(!open)}
        ></CreateEvent>
      </EventModal>

      <div className='div-list'>
        <EmptyData
          data={eventsList}
          loading={loading}
          message={intl.formatMessage({ id: 'events.label.emptyList' })}
          image={emptyState}
        >
          {eventsList.map((event: EventsApiResponse) => (
            <EventCard
              key={event._id}
              event={event}
              onClickEdit={() =>
                RedirectToCustomers('/event/edit/' + event._id)
              }
              onClickRedirect={() => RedirectToCustomers('/event/' + event._id)}
              onClickDelete={handleClickDeleteEvent}
            ></EventCard>
          ))}
        </EmptyData>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled('div')<EventsStylesProps>`
  ${(props) => EventsStyles(props)}
`;

const mapStateToProps = ({ events }: RootState) => {
  const {
    eventsList,
    loading,
    eventsFilterList,
    startDate,
    endDate,
    allTime,
    status,
  } = events;
  return {
    eventsList,
    loading,
    eventsFilterList,
    startDate,
    endDate,
    allTime,
    status,
  };
};

export const connector = connect(mapStateToProps, {
  eventsGetAllEvents,
  eventsInitialState,
  eventsPropsChanged,
  eventsFilterEvents,
  deleteEvent,
});

export default connector(Events);
