import React from 'react';
import { useIntl } from 'react-intl';
import { Route, Switch, RouteComponentProps, Router } from 'react-router-dom';
import SideBar from '../../components/Drawer';

import Ships from '../../modules/Ships';
import { useStyles } from './ShipsRouter.styles';

import NotFound from '../../modules/NotFound';
import Ferry from '../../modules/SentTransits/Ferry';
//Modules

const ShipsRouter: React.FC<RouteComponentProps> = (props) => {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <div className={classes.rootAppRouter}>
      <SideBar title={intl.formatMessage({ id: 'drawer.menuList.ships' })}>
        <Switch>
          <Route path='/ships/:ferryID' component={Ferry} />
          <Route path='/ships' component={Ships} exact />
          <Route path='/ships/*' component={NotFound} />
        </Switch>
      </SideBar>
    </div>
  );
};

export default ShipsRouter;
