import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='21'
      height='24'
      fill='none'
      viewBox='0 0 21 24'
    >
      <path
        fill='#404040'
        d='M16.909 12.763c-.028-3.032 2.546-4.507 2.664-4.576-1.458-2.072-3.718-2.355-4.512-2.378-1.898-.195-3.739 1.107-4.706 1.107-.986 0-2.475-1.088-4.08-1.056C4.212 5.89 2.28 7.057 1.22 8.867c-2.186 3.69-.556 9.113 1.539 12.097 1.048 1.46 2.272 3.092 3.875 3.035 1.568-.063 2.153-.975 4.046-.975 1.874 0 2.424.974 4.059.938 1.683-.026 2.742-1.468 3.753-2.942 1.211-1.674 1.697-3.324 1.717-3.408-.04-.013-3.268-1.215-3.3-4.849zM13.821 3.847c.844-1.029 1.42-2.427 1.26-3.847-1.22.053-2.747.823-3.626 1.829-.777.886-1.472 2.339-1.293 3.704 1.371.1 2.78-.674 3.66-1.686z'
      ></path>
    </svg>
  );
}

export default Icon;
