import React, { Dispatch, SetStateAction } from 'react';
import { useIntl } from 'react-intl';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { CardActionArea } from '@material-ui/core';
import CountUp from 'react-countup';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { StatsDetailsFilterSMS } from '../../../actions';

//Styles
import {
  childStyles,
  crmStyles,
  externesStyles,
  internesStyles,
  useStyles,
} from './statsCard.styles';

interface IProps {
  isTypeSms?: boolean;
  setIsTypeSms?: Dispatch<SetStateAction<boolean>>;
  isTypeClient?: boolean;
  setIsTypeClient?: Dispatch<SetStateAction<boolean>>;
  isTypeEmail?: boolean;
  setIsTypeEmail?: Dispatch<SetStateAction<boolean>>;
  title?: any;
  count?: any;
  walletId?: any;
  startDate?: any;
  endDate?: any;
  allTime?: any;
  type?: any;
  icon?: any;
  style?: any;
  className?: any;
  StatsDetailsFilterSMS?: any;
}
const StatsCard: React.FC<any> = (props: IProps) => {
  const intl = useIntl();
  const classes = useStyles();
  const reclasses = childStyles();
  const internes = internesStyles();
  const externes = externesStyles();
  const crm = crmStyles();
  const {
    title,
    count,
    walletId,
    startDate,
    endDate,
    allTime,
    type,
    icon,
    style,
    className,
    isTypeSms,
    isTypeEmail,
    isTypeClient,
  } = props;
  const [is_loading, setIsLoading] = React.useState<any>(null);

  const HandleClickExpand = () => {
    if (type == 'client' || type == 'email') return;
    props.StatsDetailsFilterSMS(walletId, allTime, startDate, endDate, type);
  };
  const renderEmailCard = () => {
    return (
      <>
        <div
          className={`${
            ['total', 'Total'].includes(title) ? `${reclasses.totalCard}` : ''
          }`}
        >
          <div
            className={`${
              ['total', 'Total'].includes(title)
                ? `${reclasses.emailContainer}`
                : `${classes.emailContainer}`
            }`}
          >
            <div
              className={`${
                ['total', 'Total'].includes(title)
                  ? `${reclasses.emailCircle}`
                  : `${classes.emailCircle}`
              }`}
            >
              <CountUp end={count} duration={1} />
            </div>
            <div
              onClick={HandleClickExpand}
              className={`${
                ['total', 'Total'].includes(title)
                  ? `${reclasses.Container}`
                  : `${classes.Container}`
              }`}
            >
              <Typography
                variant='subtitle2'
                //component='p'
                align='center'
                gutterBottom
              >
                {title}
              </Typography>
            </div>
          </div>
        </div>
      </>
    );
  };
  const renderClientCard = () => {
    return (
      <>
        <div
          className={`${
            ['Total des clients', 'Total clients'].includes(title)
              ? classes.clientTotalCard
              : ''
          }`}
          style={style}
        >
          <div
            className={`${
              ['Total des clients', 'Total clients'].includes(title)
                ? `${reclasses.clientContainer}`
                : `${classes.clientContainer}`
            }`}
          >
            <div
              className={clsx(
                `${
                  ['Total des clients', 'Total clients'].includes(title)
                    ? `${reclasses.clientRect}`
                    : `${classes.clientRect}`
                }`,
                `${
                  ['Internes', 'Internal'].includes(title)
                    ? `${externes.clientRect}`
                    : `${classes.clientRect}`
                }`,
                `${
                  ['Externes', 'External'].includes(title)
                    ? `${internes.clientRect}`
                    : `${classes.clientRect}`
                }`,
                `${
                  ['CRM'].includes(title)
                    ? `${crm.clientRect}`
                    : `${classes.clientRect}`
                }`
              )}
            >
              <CountUp end={count} duration={1} />
            </div>
            <div
              onClick={HandleClickExpand}
              className={clsx(
                `${
                  ['Total des clients', 'Total clients'].includes(title)
                    ? `${reclasses.clientContent}`
                    : `${classes.clientContent}`
                }`,
                `${
                  ['Internes', 'Internal'].includes(title)
                    ? `${externes.clientContent}`
                    : `${classes.clientContent}`
                }`,
                `${
                  ['Externes', 'External'].includes(title)
                    ? `${internes.clientContent}`
                    : `${classes.clientContent}`
                }`,
                `${
                  ['CRM'].includes(title)
                    ? `${crm.clientContent}`
                    : `${classes.clientContent}`
                }`
              )}
            >
              <Typography
                variant='subtitle2'
                //component='p'
                align='center'
                //gutterBottom
              >
                {title}
              </Typography>
              <div style={{ marginLeft: '5px' }}>{icon}</div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {isTypeSms && (
        <div className={className}>
          <Card
            className={classes.root}
            // onClick={HandleClickExpand}
            style={style}
          >
            <CardActionArea>
              <CardContent className={classes.content}>
                <div className={classes.container}>
                  {icon}
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <div className={classes.titleCard}>
                      {title}&nbsp;
                      <CountUp
                        end={count}
                        duration={1}
                        style={{ marginTop: '3px' }}
                      />
                    </div>
                  </div>
                </div>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
      )}

      {isTypeEmail && renderEmailCard()}
      {isTypeClient && renderClientCard()}
    </>
  );
};

export const connector = connect(null, {
  StatsDetailsFilterSMS,
});

export default connector(StatsCard);
