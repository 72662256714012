import { css } from 'styled-components';
import { TextProps } from '.';

export const textStyles = (props: TextProps) => {
  const fontStyle = props.fontStyle ? props.fontStyle : 'normal';
  const fontWeight = props.fontWeight ? props.fontWeight : 400;
  const fontSize = props.fontSize ? `${props.fontSize}px` : '14px';
  const lineHeight = props.lineHeight ? `${props.lineHeight}px` : '16px';
  const color = props.color ? props.color : '#000000';
  const margin = props.margin ? `${props.margin}px` : '0px';

  if (props.error) {
    return css`
      font-style: ${fontStyle};
      font-weight: ${fontWeight};
      font-size: ${fontSize};
      line-height: ${lineHeight};
      color: #ed0723;
      margin-left: 12px;
    `;
  }
  return css`
    font-style: ${fontStyle};
    font-weight: ${fontWeight};
    font-size: ${fontSize};
    line-height: ${lineHeight};
    color: ${color};
    margin: ${margin};
  `;
};
