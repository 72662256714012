import { getDataFromCache } from './cache';

export const isAllowed = (
  targetPath: string,
  allowedPasses: string[] = getDataFromCache('user').allowedPasses
): boolean => {
  return (
    allowedPasses.some(
      (elem: string) => elem === 'all' || elem === targetPath
    ) || false
  );
};
