import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 340,
      marginRight: '15px',
      background: '#FFFFFF',
      boxShadow: '0px 0px 20px 2px rgba(55, 55, 55, 0.08)',
      borderRadius: '20px',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    cardContent: {
      marginTop: '12px',
      padding: '8px 16px',
    },
    media: {
      height: 225,
      borderRadius: '20px 20px 0 0',
      border: '1px solid #CDCDCD',
      boxSizing: 'border-box',
    },
    label: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '8px',
    },
    location: {
      display: 'flex',
      flexDirection: 'column',
      gap: '5px',
      justifyContent: 'space-between',
      marginBottom: '8px',
      marginRight: '20px',
    },
    text2: {
      marginLeft: '8px',
    },
    cardDescription: {
      padding: '8px',
      border: '1px solid #C7C7C7',
      borderRadius: '10px',
      marginTop: '8px',
    },
    description: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    activateCoupon: {
      display: 'flex',
      alignItems: 'center',
    },
    cardMenu: {
      position: 'relative',
      height: '0px',
      left: '300px',
      top: '15px',
    },
    menuIcon: {
      fill: '#ffffff',
    },
    iconButton: {},
    cardPosition: {
      '& .MuiPaper-root .MuiMenu-paper': {
        top: '176px',
        left: '324px',
      },
    },
    '$root.Mui-MuiMenu-paper &': {
      top: '176px',
      left: '324px',
    },
  })
);
