import React from 'react';

function Icon({
  width = '28',
  height = '28',
}: {
  width?: string;
  height?: string;
}) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      fill='none'
      viewBox='0 0 28 28'
    >
      <path
        fill='#C7C7C7'
        fillRule='evenodd'
        d='M22.364 5.02C22.025 5.066 6.68 7.74 6.48 7.788c-.093.023 18.206.101 18.611.08.005 0-.053-.34-.13-.756-.099-.546-.175-.829-.274-1.023-.147-.287-.552-.69-.842-.836-.404-.204-.998-.298-1.48-.233zM3.234 9.518c-.137.023-.35.092-.474.155-.283.14-.626.513-.7.756-.08.262-.08 10.299 0 10.612.076.302.474.694.858.847L3.2 22H24.8l.282-.112c.384-.153.782-.545.859-.847.08-.313.079-10.35 0-10.612-.074-.242-.418-.615-.696-.754a2.169 2.169 0 00-.52-.16c-.373-.06-21.128-.056-21.492.003zm6.21 1.953l-.013.706-.831.013-.832.013v-1.439h1.689l-.013.707zm12.085 1.051c.412.185.576.71.355 1.139-.057.11-.372.436-.775.805-.636.58-.677.628-.64.74.023.068.286.935.585 1.928l.544 1.805-.35.338c-.336.325-.421.374-.526.301-.027-.018-.425-.711-.884-1.54a74.257 74.257 0 00-.866-1.54c-.017-.018-.384.316-.815.743l-.784.777.121.595.123.595-.226.226c-.123.124-.246.226-.273.226-.027 0-.584-.529-1.237-1.175-.947-.936-1.183-1.194-1.16-1.265.016-.05.113-.171.216-.27l.187-.181.61.124.61.124.76-.768c.42-.422.767-.781.773-.798.006-.017-.673-.402-1.509-.857-.836-.454-1.544-.855-1.573-.89-.085-.1-.02-.21.323-.547l.322-.316 1.889.56a71.63 71.63 0 001.941.56c.03 0 .337-.303.683-.672.387-.413.703-.709.818-.765a.88.88 0 01.758-.002zM9.444 14.32l.013.707H7.768v-1.439l.832.013.831.013.013.706zm0 2.85l-.013.706-.831.013-.832.013v-1.439h1.689l-.013.707zm0 2.85l.013.706H7.768v-1.439l.832.013.831.013.013.706z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}

export default Icon;
