import 'date-fns';
import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
//Style
import styled from 'styled-components';
//import { shipStyles } from './ship.styles';
//Material
import {
  Divider,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Typography,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  TimePicker,
} from '@material-ui/pickers';
//Components
import InputLabel from '../../commons/Inputs/InputLabel';
//Commomns
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import Button from '../../commons/Button';
import { getImageErrorMessage } from '../../utils/common';

//Actions
import { tramPropsChanged, addTram, tramInitialState } from '../../actions';
//Reducers
import { RootState } from '../../reducers';
//Type

// import { AddCustomerFormProps } from './addCustomer.types';

import cover from '../../assets/images/lg-thumbnail.png';
import { TramFormProps, TramStateError } from './tram.types';
import InputFile from '../../commons/Inputs/InputFile';
import { CircularProgress } from '@material-ui/core';
import Text from '../../commons/Text';
import moment from 'moment';
import ColorPicker from '../../commons/ColorPicker';
import TramWireframeAndroid from '../../components/TramWireframeAndroid';
import TramWireframeIPhone from '../../components/TramWireframeIPhone';
import {
  isNumber,
  getNatural,
  getDecimal,
  isPhoneNumber,
} from '../../utils/validations';
import { getDataFromCache } from '../../utils/cache';
import { useHistory } from 'react-router-dom';
import Calendar from '../../icons/Calendar';
import Icon from 'react-icons-kit';
import { ic_warning } from 'react-icons-kit/md/ic_warning';
import { tramStyles } from './tram.styles';
import maxChars from '../../utils/fieldsMaxLength';
import BarcodeTypeSelection from '../../components/barcodeSelection';
import SecurityOptions from '../../components/SecurityOptions';

const Tram: React.FC<TramFormProps> = (props) => {
  const intl = useIntl();
  const [hasStateChanged, setHasStateChange] = React.useState<boolean>(false);
  const [switchPreview, setSwitchPreview] = React.useState<string>('android');
  const [QRcode, setQRcode] = React.useState<any>({ state: true, value: '' });
  const history = useHistory();

  const {
    walletId,
    loading,
    onSaveChanged,
    image,
    strip,
    labelColor,
    backgroundColor,
    foregroundColor,
    number,
    originStationCode,
    originName,
    destinationStationCode,
    destinationName,
    departureTime,
    arrivalTime,
    tarif,
    details,
    terms,
    enableOneTimeUse,
    disableIOSPassSharing,
    disableMultipleHolders,
    enableSecurityAnimation,
    enableRotatingBarcode,
    disableEmailSharing,
    customerServiceNumber,
    stripError,
    labelColorError,
    backgroundColorError,
    foregroundColorError,
    numberError,
    originStationCodeError,
    originNameError,
    destinationStationCodeError,
    destinationNameError,
    departureTimeError,
    arrivalTimeError,
    tarifError,
    detailsError,
    termsError,
    customerServiceNumberError,
    imageError,
    barcodeType,
  } = props;

  const objectUser = getDataFromCache('user');
  const myRef = useRef(null);

  React.useEffect(() => {
    props.tramInitialState();
  }, []);

  const handleColorChange = (color: any, prop: string) => {
    props.tramPropsChanged(prop, color);
  };

  const getFormatedMessage = (prop: TramStateError) => {
    return props[prop] !== ''
      ? intl.formatMessage({ id: props[prop] })
      : props[prop];
  };
  const handleFileChange = (event: any, prop: string) => {
    const file = event.target.files[0];
    if (!['image/jpeg', 'image/png'].includes(file.type)) {
      props.tramPropsChanged(`${prop}Error`, getImageErrorMessage(file));
      return;
    }
    props.tramPropsChanged(prop, file);
  };
  const handlePreviewChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSwitchPreview((event.target as HTMLInputElement).value);
  };

  const isPriceValid = (value: string): boolean => {
    if (!isNumber(value)) return false;
    if (getNatural(value).toString().length > 5) {
      props.tramPropsChanged(
        'tarifError',
        'Vous avez dépassé le nombre maximum avant le point/virgule'
      );
      return false;
    }

    if (getDecimal(value).toString().length > 2) {
      props.tramPropsChanged(
        'tarifError',
        'Vous avez dépassé le nombre maximum aprés le point/virgule'
      );
      return false;
    }
    return true;
  };
  const areTextAreasValid = (fieldName: string, value: string): boolean => {
    if (fieldName === 'customerServiceNumber') {
      if (!isPhoneNumber(value)) {
        props.tramPropsChanged(fieldName, value);
        props.tramPropsChanged(`${fieldName}Error`, 'Phone is invalid');
        return false;
      }
    }

    if (value.length > (maxChars.transit[fieldName] || maxChars[fieldName])) {
      props.tramPropsChanged(
        `${fieldName}Error`,
        'La longueur maximale a été atteinte'
      );
      return false;
    }
    return true;
  };

  ////////////////////////////////////
  const handleClickConfirm = () => {
    setHasStateChange(false);
    props.addTram(objectUser.wallet, props, () => history.push('/ships'));
  };
  const handleClickAddTram = () => {
    props.addTram(objectUser.wallet, props, () => onSaveChanged());
  };
  ///////////////////////////////////

  /////////////////////////////////////////
  const handleTramChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHasStateChange(true);
    const textAreas = ['details', 'terms'];

    const inputFeilds = [
      'number',
      'originStationCode',
      'originName',
      'destinationStationCode',
      'destinationName',
      'departureTime',
      'arrivalTime',
      'tarif',
      'details',
      'customerServiceNumber',
    ];
    const value = event.target.value;
    const fieldName = event.target.name;
    props.tramPropsChanged(`${fieldName}Error`, '');

    if (fieldName === 'tarif') {
      if (!isPriceValid(value)) return;
    }

    if (!areTextAreasValid(fieldName, value)) return;

    props.tramPropsChanged(fieldName, value);
  };
  /////////////////////////////////////
  const handleDateChange = (prop: string) => (date: Date | null) => {
    props.tramPropsChanged(prop, date);
  };
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Text color='#404040' fontSize={16} fontWeight={600} lineHeight={24}>
            <span className='title'>
              {' '}
              {intl.formatMessage({ id: 'transit.label.addTrip' })}
            </span>
          </Text>
        </div>
        <div>
          <div className='action'>
            {(imageError ||
              numberError ||
              originStationCodeError ||
              originNameError ||
              destinationStationCodeError ||
              destinationNameError ||
              tarifError ||
              detailsError ||
              termsError ||
              customerServiceNumberError ||
              stripError ||
              labelColorError ||
              backgroundColorError ||
              foregroundColorError) && (
              <div className='error-icon'>
                <Icon
                  icon={ic_warning}
                  size={24}
                  style={{ color: '#FF5078' }}
                />
              </div>
            )}
          </div>
          <Button width={135} onClick={handleClickAddTram}>
            {loading ? (
              <CircularProgress size={20} color='inherit' />
            ) : (
              intl.formatMessage({ id: 'ship.button.add' })
            )}
          </Button>
        </div>
      </div>
      <Wrapper>
        <div className='section'>
          <div className='section-one '>
            <InputFile
              className='input-label'
              width={270}
              label={intl.formatMessage({ id: 'ship.label.logo' })}
              image={image}
              defaultImage={cover}
              error={getFormatedMessage('imageError')}
              onChange={(e) => {
                handleFileChange(e, 'image');
              }}
            ></InputFile>
            <div className='div-content' style={{ marginLeft: '30px' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '26px',
                }}
              >
                <ColorPicker
                  label={intl.formatMessage({ id: 'coupon.label.textColor' })}
                  color={labelColor}
                  error={getFormatedMessage('labelColorError')}
                  onChange={(color: any) => {
                    handleColorChange(color, 'labelColor');
                  }}
                />
                <ColorPicker
                  label={intl.formatMessage({
                    id: 'coupon.label.foregroundColor',
                  })}
                  color={foregroundColor}
                  error={getFormatedMessage('foregroundColorError')}
                  onChange={(color: any) => {
                    handleColorChange(color, 'foregroundColor');
                  }}
                />
                <ColorPicker
                  label={intl.formatMessage({
                    id: 'coupon.label.backgroundColor',
                  })}
                  color={backgroundColor}
                  error={getFormatedMessage('backgroundColorError')}
                  onChange={(color: any) => {
                    handleColorChange(color, 'backgroundColor');
                  }}
                />
              </div>
            </div>
            <div className='section-row'>
              <BarcodeTypeSelection
                setSelectedBarcode={(barcodeType) => {
                  props.tramPropsChanged('barcodeType', barcodeType);
                }}
                barcodeType={barcodeType}
              ></BarcodeTypeSelection>
              <SecurityOptions
                handleSecurityPropsChange={(name: string, value: boolean) => {
                  props.tramPropsChanged(name, value);
                }}
                {...{
                  enableOneTimeUse,
                  disableIOSPassSharing,
                  disableMultipleHolders,
                  enableSecurityAnimation,
                  enableRotatingBarcode,
                  disableEmailSharing,
                }}
              ></SecurityOptions>
            </div>
            <div>
              <InputLabel
                label={intl.formatMessage({ id: 'ship.label.number' })}
                placeholder={intl.formatMessage({ id: 'ship.label.number' })}
                name='number'
                value={number}
                error={getFormatedMessage('numberError')}
                limit={maxChars.transit.number || maxChars.number}
                onChange={(e) => handleTramChange(e)}
              />
              <InputLabel
                label={intl.formatMessage({
                  id: 'ship.label.originStationCode',
                })}
                placeholder='station code'
                name='originStationCode'
                value={originStationCode}
                error={getFormatedMessage('originStationCodeError')}
                limit={
                  maxChars.transit.originStationCode ||
                  maxChars.originStationCode
                }
                onChange={(e) => handleTramChange(e)}
              />
              <InputLabel
                label={intl.formatMessage({
                  id: 'transitCard.label.originName',
                })}
                placeholder='Paris'
                name='originName'
                value={originName}
                error={getFormatedMessage('originNameError')}
                limit={maxChars.transit.originName || maxChars.originName}
                onChange={(e) => handleTramChange(e)}
              />
              <InputLabel
                label={intl.formatMessage({
                  id: 'transit.label.destinationStationCode',
                })}
                placeholder=''
                name='destinationStationCode'
                value={destinationStationCode}
                error={getFormatedMessage('destinationStationCodeError')}
                limit={
                  maxChars.transit.destinationStationCode ||
                  maxChars.destinationStationCode
                }
                onChange={(e) => handleTramChange(e)}
              />
              <InputLabel
                label={intl.formatMessage({
                  id: 'transitCard.label.destinationName',
                })}
                placeholder=''
                name='destinationName'
                value={destinationName}
                error={getFormatedMessage('destinationNameError')}
                limit={
                  maxChars.transit.destinationName || maxChars.destinationName
                }
                onChange={(e) => handleTramChange(e)}
              />

              <TimePicker
                required
                fullWidth
                margin='normal'
                ampm={false}
                label={intl.formatMessage({ id: 'ship.label.departureTime' })}
                //format='E MMM dd yyyy HH:MM'
                inputVariant='outlined'
                value={departureTime}
                onChange={handleDateChange('departureTime')}
                error={departureTimeError !== ''}
                helperText={getFormatedMessage('departureTimeError')}
              />

              <TimePicker
                required
                fullWidth
                margin='normal'
                ampm={false}
                label={intl.formatMessage({ id: 'ship.label.arrivalTime' })}
                //format='E MMM dd yyyy HH:MM'
                inputVariant='outlined'
                value={arrivalTime}
                onChange={handleDateChange('arrivalTime')}
                error={arrivalTimeError !== ''}
                helperText={getFormatedMessage('arrivalTimeError')}
              />

              <InputLabel
                label={intl.formatMessage({ id: 'ship.label.tarif' })}
                placeholder='15€'
                name='tarif'
                value={tarif}
                error={getFormatedMessage('tarifError')}
                limit={maxChars.transit.tarif || maxChars.tarif}
                onChange={(e) => handleTramChange(e)}
              />
              <InputLabel
                label={intl.formatMessage({
                  id: 'ship.label.customerServiceNumber',
                })}
                placeholder='numéro Télephone'
                name='customerServiceNumber'
                value={customerServiceNumber}
                error={getFormatedMessage('customerServiceNumberError')}
                limit={
                  maxChars.transit.customerServiceNumber || maxChars.phoneNumber
                }
                onChange={(e) => handleTramChange(e)}
              />
              <InputLabel
                label={intl.formatMessage({ id: 'ship.label.details' })}
                placeholder='description...'
                name='details'
                value={details}
                error={getFormatedMessage('detailsError')}
                limit={maxChars.transit.details || maxChars.details}
                onChange={(e) => handleTramChange(e)}
              />
              <InputLabel
                label={intl.formatMessage({ id: 'ship.label.terms' })}
                placeholder='terms'
                name='terms'
                value={terms}
                error={getFormatedMessage('termsError')}
                limit={maxChars.transit.terms || maxChars.terms}
                onChange={(e) => handleTramChange(e)}
              />
            </div>
          </div>
          <div className='section-two '>
            <div
              className=' wireframe-section'
              style={{ marginLeft: 25, marginRight: 25, maxWidth: 470 }}
            >
              <RadioGroup
                name='previews'
                value={switchPreview}
                onChange={handlePreviewChange}
                className='radioGroup'
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <FormControlLabel
                  value='android'
                  control={<Radio />}
                  label='Android'
                />
                <FormControlLabel value='iOS' control={<Radio />} label='iOS' />
              </RadioGroup>

              {switchPreview === 'android' && (
                <TramWireframeAndroid
                  backgroundColor={backgroundColor}
                  strip={image}
                  number={number}
                  originStationCode={originStationCode}
                  originName={originName}
                  destinationName={destinationName}
                  destinationStationCode={destinationStationCode}
                  departureTime={departureTime}
                  arrivalTime={arrivalTime}
                  tarif={tarif}
                  customerServiceNumber={customerServiceNumber}
                  details={details}
                  terms={terms}
                  QrCode={QRcode}
                  barcodeType={barcodeType}
                  animatedBorder={enableSecurityAnimation}
                  name={'name'}
                />
              )}

              {switchPreview === 'iOS' && (
                <TramWireframeIPhone
                  backgroundColor={backgroundColor}
                  strip={image}
                  number={number}
                  originStationCode={originStationCode}
                  originName={originName}
                  destinationName={destinationName}
                  destinationStationCode={destinationStationCode}
                  departureTime={departureTime}
                  arrivalTime={arrivalTime}
                  tarif={tarif}
                  customerServiceNumber={customerServiceNumber}
                  details={details}
                  terms={terms}
                  QrCode={QRcode}
                  titleColor={labelColor.hex}
                  textColor={foregroundColor.hex}
                  barcodeType={barcodeType}
                />
              )}
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

const Wrapper = styled('div')`
  ${(props) => tramStyles(props)}
`;

const mapStateToProps = ({ tram }: RootState) => {
  const {
    loading,
    image,
    strip,
    labelColor,
    backgroundColor,
    foregroundColor,

    number,
    originStationCode,
    originName,
    destinationStationCode,
    destinationName,
    departureTime,
    arrivalTime,
    tarif,
    details,
    terms,
    customerServiceNumber,
    enableOneTimeUse,
    disableIOSPassSharing,
    disableMultipleHolders,
    enableSecurityAnimation,
    enableRotatingBarcode,
    disableEmailSharing,
    //errors
    stripError,
    labelColorError,
    backgroundColorError,
    foregroundColorError,

    numberError,
    originStationCodeError,
    originNameError,
    destinationStationCodeError,
    destinationNameError,
    departureTimeError,
    arrivalTimeError,
    tarifError,
    detailsError,
    termsError,
    customerServiceNumberError,
    imageError,
    barcodeType,
  } = tram;
  return {
    loading,
    image,
    strip,
    labelColor,
    backgroundColor,
    foregroundColor,

    number,
    originStationCode,
    originName,
    destinationStationCode,
    destinationName,
    departureTime,
    arrivalTime,
    tarif,
    details,
    terms,
    customerServiceNumber,
    enableOneTimeUse,
    disableIOSPassSharing,
    disableMultipleHolders,
    enableSecurityAnimation,
    enableRotatingBarcode,
    disableEmailSharing,
    //errors
    stripError,
    labelColorError,
    backgroundColorError,
    foregroundColorError,

    numberError,
    originStationCodeError,
    originNameError,
    destinationStationCodeError,
    destinationNameError,
    departureTimeError,
    arrivalTimeError,
    tarifError,
    detailsError,
    termsError,
    customerServiceNumberError,
    imageError,
    barcodeType,
  };
};

export const connector = connect(mapStateToProps, {
  tramPropsChanged,
  addTram,
  tramInitialState,
});

export default connector(Tram);
