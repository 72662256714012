import axios from 'axios';
import { URL } from './config';
import { TransitsApiResponse } from './ApiTypes';

export const getTramsList = (walletID: string) => {
  const requestURL = `${URL}/wallets/${walletID}/transits?type=tram`;

  return axios
    .get(requestURL)
    .then(({ data }: { data: Array<TransitsApiResponse> }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const getTramsChildrenList = (
  walletId: any,
  tramId: any,
  page: number | string,
  size: number | string,
  searchFilter: any | null = null
) => {
  let requestURL = `${URL}/wallets/${walletId}/transits/${tramId}/sent`;
  if (searchFilter) {
    const filterItem = searchFilter.find(
      (elem: { name: string; value: string }) => elem.value !== ''
    );
    if (filterItem !== undefined)
      requestURL = `${URL}/wallets/${walletId}/transits/${tramId}/sent?${filterItem.name}=${filterItem.value}`;
  }
  return axios
    .get(requestURL, {
      params: {
        page,
        size,
      },
    })
    .then(({ data }: { data: Array<any> }) => {
      return data;
    })
    .catch((error) => {
      return error.response;
    });
};

export const removeCustomerTram = (
  tramId: string,
  customerId: string,
  walletId: string
) => {
  const requestURL = `${URL}/wallets/${walletId}/transits/${tramId}/customers/${customerId}`;

  return axios
    .delete(requestURL)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};
