import axios from 'axios';
import { URL } from './config';

export const updateCustumerGain = (
  walletId: string,
  customerId: string,
  gain: number
) => {
  const requestURL = `${URL}/customers/${customerId}/${walletId}`;
  return axios
    .patch(requestURL, {
      gain,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};
