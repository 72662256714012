import 'date-fns';
import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
//Style
import styled from 'styled-components';
import { couponStyles } from './flight.styles';
//Material
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
//Components
import InputLabel from '../../commons/Inputs/InputLabel';
//Commomns
import ColorPicker from '../../commons/ColorPicker';

import Button from '../../commons/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '../../commons/Inputs/Input';
import Select from '@material-ui/core/Select';
//Actions
import {
  customerFlightPropsChanged,
  couponInitialState,
  editCouponCustomerConfirm,
  editFlightCustomerConfirm,
} from '../../actions';
//Reducers
import { RootState } from '../../reducers';
//Type

const EditCustomerFlight: React.FC<any> = (props) => {
  const intl = useIntl();
  const {
    flightId,
    customerId,
    walletId,
    loading,
    seatNumber,
    boardingGroup,
    seatClass,
    confirmationCode,
    flightTemplateId,
    //errors
    seatNumberError,
    boardingGroupError,
    seatClassError,
    confirmationCodeError,
    onSaveChanged,
    onCancel,
  } = props;
  /*
  React.useEffect(() => {
    props.couponInitialState();
  }, []);
*/
  const handleClickConfirm = () => {
    props.editFlightCustomerConfirm(walletId, customerId, props, () =>
      onSaveChanged()
    );
  };

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    const name = event.target.name;
    if (value == '') {
      props.customerFlightPropsChanged(name, value);
    }

    props.customerFlightPropsChanged(name, value);
  };

  const getFormatedMessage = (prop: any) => {
    return props[prop] !== ''
      ? intl.formatMessage({ id: props[prop] })
      : props[prop];
  };

  return (
    <Wrapper>
      <span className='title'>Modifier Vol</span>
      <div className='div-container' style={{ marginLeft: '15px' }}>
        <div className='div-content' style={{ marginLeft: '30px' }}>
          <InputLabel
            label={intl.formatMessage({ id: 'flight.label.seatNumber' })}
            name='seatNumber'
            value={seatNumber}
            error={getFormatedMessage('seatNumberError')}
            placeholder={intl.formatMessage({
              id: 'coupon.placeholder.description',
            })}
            onChange={(e) => handleEventChange(e)}
          />
          <InputLabel
            label={intl.formatMessage({ id: 'flight.label.boardingGroup' })}
            name='boardingGroup'
            value={boardingGroup}
            error={getFormatedMessage('boardingGroupError')}
            placeholder={intl.formatMessage({
              id: 'coupon.placeholder.description',
            })}
            onChange={(e) => handleEventChange(e)}
          />
          <InputLabel
            label={intl.formatMessage({ id: 'flight.label.seatClass' })}
            name='seatClass'
            value={seatClass}
            error={getFormatedMessage('seatClassError')}
            placeholder={intl.formatMessage({
              id: 'coupon.placeholder.description',
            })}
            onChange={(e) => handleEventChange(e)}
          />
          <InputLabel
            label={intl.formatMessage({ id: 'flight.label.confirmationCode' })}
            name='confirmationCode'
            value={confirmationCode}
            error={getFormatedMessage('confirmationCodeError')}
            placeholder={intl.formatMessage({
              id: 'coupon.placeholder.description',
            })}
            onChange={(e) => handleEventChange(e)}
          />
        </div>
        <div
          className='section wireframe-section'
          style={{ marginLeft: 25, marginRight: 25, maxWidth: 356 }}
        ></div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
        }}
      >
        <Button onClick={onCancel}>
          {intl.formatMessage({ id: 'settings.button.cancel' })}
        </Button>
        <Button onClick={handleClickConfirm}>
          {loading ? (
            <CircularProgress size={20} color='inherit' />
          ) : (
            'Sauvgarder'
          )}
        </Button>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${(props) => couponStyles(props)}
`;

const mapStateToProps = ({ customerflight }: RootState) => {
  const {
    loading,
    seatNumber,
    boardingGroup,
    seatClass,
    confirmationCode,
    customerId,
    flightTemplateId,
    flightId,
    //errors
    seatNumberError,
    boardingGroupError,
    seatClassError,
    confirmationCodeError,
  } = customerflight;
  return {
    loading,
    seatNumber,
    boardingGroup,
    seatClass,
    confirmationCode,
    customerId,
    flightTemplateId,
    flightId,
    //errors
    seatNumberError,
    boardingGroupError,
    seatClassError,
    confirmationCodeError,
  };
};

export const connector = connect(mapStateToProps, {
  customerFlightPropsChanged,
  couponInitialState,
  editFlightCustomerConfirm,
});

export default connector(EditCustomerFlight);
