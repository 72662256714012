import React from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
//Style
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
//Material
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';

//Components
import EmptyData from '../../../components/EmptyData';
//Actions

//Api

import emptyState from '../../../assets/images/emptyState.svg';
import { RootState } from '../../../reducers';
import Button from '../../../commons/Button';
import Text from '../../../commons/Text';
import { getDataFromCache } from '../../../utils/cache';
import Input from '../../../commons/Inputs/Input';

import TablePagination from '@material-ui/core/TablePagination';

import {
  ferriesPropsChanged,
  ferriesGetChildrens,
  deleteCustomerFerry,
  editFerryInitialState,
  customerFerryPropsChanged,
} from '../../../actions';
import { useHistory } from 'react-router-dom';

import { ferriesStyles, FerriesStylesProps } from './ferries.styles';
import SortableTable from '../FerryCustomerTable';
import SimpleDialog from '../../../components/Dialog';
import FerryModal from '../../../components/Modal';
import EditCustomerFerry from '../EditCustomerFerry';
import moment from 'moment';
const SentFerries: React.FC<any> = (props) => {
  const { ferriesList, loading, search, select, total } = props;

  const intl = useIntl();

  const objectUser: any = getDataFromCache('user');
  const { ferryID } = useParams<{ ferryID: string }>();
  const [selectOpener, setSelectOpener] = React.useState<boolean>(false);
  const [deleteBoxOpen, setDeleteBoxOpen] = React.useState(false);
  const [index, setIndex] = React.useState<number>(0);
  const [EditDialogOpen, setEditDialogOpen] = React.useState(false);

  const history = useHistory();
  const query = new URLSearchParams(props.location.search);
  let page = query.get('page') || 0;
  let size = query.get('size') || 10;
  let ferryId = query.get('ferryId') || '';
  let creationDate = query.get('creationDate') || '';
  let lastName = query.get('lastName') || '';

  const [searchFilter, setSearchFilter] = React.useState<any[]>([
    { value: creationDate, name: 'creationDate' },
    { value: lastName, name: 'lastName' },
    { value: ferryId, name: 'ferryId' },
  ]);

  React.useEffect(() => {
    const selection = searchFilter.find((elem) => elem.value !== '');

    props.ferriesPropsChanged('search', selection?.value || '');

    props.ferriesGetChildrens(
      objectUser.wallet,
      ferryID,
      page,
      size,
      searchFilter
    );
  }, []);
  const getFerry = () => {
    let ferry: any = null;
    ferriesList.forEach((element: any) => {
      if (element.passId == index) {
        ferry = element;
      }
    });
    return ferry;
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    query.delete('page');
    query.append('page', newPage.toString());
    history.push({ search: query.toString() });
    props.ferriesGetChildrens(objectUser.wallet, ferryID, newPage, size);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    query.delete('size');
    query.append('size', event.target.value.toString());
    history.push({ search: query.toString() });
    props.ferriesGetChildrens(
      objectUser.wallet,
      ferryID,
      page,
      event.target.value.toString(),
      searchFilter
    );
  };

  const handleSelectChange = (event: React.ChangeEvent<{ value: any }>) => {
    history.push({ search: query.toString() });

    props.ferriesPropsChanged('search', '');
    props.ferriesPropsChanged('select', event.target.value);

    searchFilter.forEach((elem, index) => {
      if (elem.name !== event.target.value) {
        query.delete(elem.name);
        searchFilter[index].value = '';
      }
    });
    history.replace({ search: query.toString() });

    setSearchFilter(searchFilter);
  };

  const updateQueryParams = (param: string, value: string) => {
    query.delete(param);
    query.append(param, value);
    history.replace({ search: query.toString() });
  };
  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'search') {
      if (event.target.value.length > 60) return;
      props.ferriesPropsChanged(event.target.name, event.target.value);
      const val = event.target.value;

      if (val == '') {
        searchFilter.forEach((elem) => {
          query.delete(elem.name);
        });
        history.replace({ search: query.toString() });
        searchFilter.forEach((elem, index) => {
          searchFilter[index].value = '';
        });
        setSearchFilter(searchFilter);
      } else
        searchFilter.forEach((elem, index) => {
          if (elem.name === select) {
            searchFilter[index].value = val;
            updateQueryParams(select, val);
          }
        });
      setSearchFilter(searchFilter);
    }

    props.ferriesPropsChanged(event.target.name, event.target.value);
  };
  const handleDeleteCustomerFerry = () => {
    let ferry: any = null;
    ferriesList.forEach((element: any) => {
      if (element.passId == index) {
        ferry = element;
      }
    });
    if (ferry == null) return;
    props.deleteCustomerFerry(
      ferry.classId,
      ferry.customerId,
      ferry.passId,
      objectUser.wallet
    );
    setDeleteBoxOpen(false);
  };
  const handleClickSearch = () => {
    props.ferriesPropsChanged('search', search);
    props.ferriesGetChildrens(
      objectUser.wallet,
      ferryID,
      page,
      size,
      searchFilter
    );
  };
  return (
    <Wrapper loading={loading.toString()} couponsLength={ferriesList.length}>
      <div className='div-header'>
        <div className='div-input-filter'>
          <FormControl className='filter-container' fullWidth>
            <Input value={select} type='text' disabled />
            <div
              className='filter-button'
              onClick={() => setSelectOpener(!selectOpener)}
            >
              <h3>
                {intl.formatMessage({
                  id: 'wallet.label.filter',
                })}
              </h3>
              <ExpandMoreRoundedIcon
                style={{ color: 'white' }}
                className={selectOpener ? 'filter-opened' : ''}
              />
            </div>
            <Select
              className='select-input'
              value={select}
              onChange={handleSelectChange}
              placeholder={intl.formatMessage({
                id: 'wallet.label.filter',
              })}
              id='filter'
              inputProps={{ className: 'input' }}
              open={selectOpener}
              onClose={() => setSelectOpener(false)}
            >
              <MenuItem value='ferryId'>
                <span>Hotel ID</span>
              </MenuItem>

              <MenuItem value='lastName'>
                <span>Prénom</span>
              </MenuItem>
              <MenuItem value='creationDate'>
                <span>Date de création</span>
              </MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className='div-input-search'>
          <Input
            value={search}
            background='#fff'
            type='text'
            name='search'
            placeholder={intl.formatMessage({ id: 'wallet.button.rechercher' })}
            margin='10px 0'
            className='input-search'
            onChange={handleEventChange}
          />
          <Button
            onClick={handleClickSearch}
            width={120}
            background='#FF5078'
            className='button-search'
          >
            {intl.formatMessage({ id: 'wallet.button.rechercher' })}
          </Button>
        </div>
      </div>
      <div className='div-header'>
        <Text color='#404040' fontSize={24} lineHeight={36} fontWeight={600}>
          Liste des sous-transit
        </Text>
      </div>
      <FerryModal
        open={EditDialogOpen}
        onClose={() => setEditDialogOpen(false)}
      >
        <EditCustomerFerry
          walletId={objectUser.wallet}
          ferryId={getFerry()?.classId}
          customerId={
            ferriesList.find((c: any) => c.passId == index)?.customerId
          }
          onCancel={() => setEditDialogOpen(false)}
          onSaveChanged={() => setEditDialogOpen(!EditDialogOpen)}
        ></EditCustomerFerry>
      </FerryModal>
      <div
        className='div-list'
        style={{ marginRight: '33px', marginLeft: '33px' }}
      >
        <EmptyData
          data={ferriesList}
          loading={loading}
          message={intl.formatMessage({ id: 'ships.label.emptyList' })}
          image={emptyState}
        >
          <EmptyData
            data={ferriesList}
            loading={loading}
            message='La liste des transits est vide'
            image={emptyState}
          >
            <SortableTable
              data={ferriesList}
              onClickEdit={(ferry: any) => {
                setIndex(ferry.passId);
                setEditDialogOpen(true);
                props.customerFerryPropsChanged(
                  'departureDate',
                  ferry.departureDate
                );
                props.customerFerryPropsChanged(
                  'arrivalDate',
                  moment(ferry.arrivalDate).format('YYYY-MM-DD')
                );
                props.customerFerryPropsChanged(
                  'passengerType',
                  ferry.passengerType
                );
                props.customerFerryPropsChanged('tripType', ferry.tripType);

                props.customerFerryPropsChanged('customerId', ferry.customerId);
                props.customerFerryPropsChanged('passId', ferry.passId);
              }}
              onClickDelete={(passId: number) => {
                setIndex(passId);
                setDeleteBoxOpen(true);
              }}
              page={+page}
              rowsPerPage={+size}
            ></SortableTable>

            {ferriesList.length != 0 && (
              <TablePagination
                count={total}
                page={+page}
                onChangePage={handleChangePage}
                rowsPerPage={+size}
                rowsPerPageOptions={[10, 25, 50, 100]}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={'Lignes par page'}
              />
            )}
          </EmptyData>
        </EmptyData>
        <SimpleDialog
          title={intl.formatMessage({ id: 'offers.dialog.title' })}
          text={'Etes-vous sûr de vouloir supprimer ce transit?'}
          handleAgree={handleDeleteCustomerFerry}
          open={deleteBoxOpen}
          handleClose={() => setDeleteBoxOpen(false)}
        ></SimpleDialog>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled('div')<FerriesStylesProps>`
  ${(props) => ferriesStyles(props)}
`;

const mapStateToProps = ({ ferries }: RootState) => {
  const { ferriesList, loading, search, select, total } = ferries;

  return {
    ferriesList,
    loading,
    search,
    select,
    total,
  };
};

export const connector = connect(mapStateToProps, {
  ferriesGetChildrens,
  ferriesPropsChanged,
  deleteCustomerFerry,
  editFerryInitialState,
  customerFerryPropsChanged,
});

export default connector(SentFerries);
