import React from 'react';

function Icon({
  width = '28',
  height = '28',
}: {
  width?: string;
  height?: string;
}) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      fill='none'
      viewBox='0 0 28 28'
    >
      <path
        fill='#C7C7C7'
        fillRule='evenodd'
        d='M11.077.012c-2.438.09-4.21.357-5.57.84-1.036.37-1.714.779-2.318 1.4-.609.628-.955 1.305-1.162 2.274-.09.426-.095.839-.095 8.165 0 6.298.012 7.774.07 8.033.171.78.546 1.522 1.036 2.054l.25.271.018 1.427c.018 1.366.024 1.438.14 1.706.16.364.504.675.866.783.217.064.478.078 1.215.065.932-.018.94-.019 1.219-.176.287-.162.543-.468.656-.783.037-.101.062-.511.065-1.027l.003-.859H18.6l.004.859c.002.516.028.926.064 1.027.113.315.37.62.656.783.278.157.287.159 1.22.176.736.013.998 0 1.215-.065.361-.108.705-.42.864-.783.117-.268.123-.34.14-1.706l.02-1.427.25-.271c.49-.532.864-1.274 1.036-2.054.057-.26.07-1.735.07-8.033 0-7.326-.005-7.739-.096-8.165-.207-.97-.553-1.646-1.162-2.273C21.687 1.023 19.829.385 16.64.11c-.859-.074-4.474-.138-5.564-.098zm10.292 9.23l-.013 3.554H4.714L4.7 9.242l-.014-3.554h16.696l-.014 3.554zM7.367 17.168c1.246.381 1.842 1.782 1.267 2.976-.627 1.3-2.309 1.59-3.304.569a2.24 2.24 0 01-.59-1.808c.174-1.268 1.435-2.102 2.627-1.737zm12.482-.003c.135.03.38.144.545.253.902.599 1.203 1.704.74 2.718-.47 1.03-1.694 1.481-2.706.996a1.994 1.994 0 01-.992-.988c-.523-1.087-.086-2.36.984-2.863.47-.22.833-.25 1.43-.116z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}

export default Icon;
