import styled from 'styled-components';

export const Wrapper = styled('div')<{}>`
  display: flex;
  flex-direction: column;
  background-color: #f8f6fe;
  border-radius: 10px;
  padding: 20px;
  width: 359px;
  padding-bottom: 33px;
  .MuiFormGroup-root {
    display: grid;
    grid-template-columns: 130px 130px;
    grid-template-rows: 101px 111px;
    gap: 9px 56px;
    justify-content: center;
  }

  .imgSquare {
    width: 77px;
    height: 77px;
    margin: auto;
  }

  .imgRectangular {
    width: 100%;
    height: 35px;
  }

  .MuiFormControlLabel-root {
    margin-left: -11px !important;
  }
`;
export const Barcode = styled('div')`
   {
    display: flex;
    flex-direction: column;
  }
`;

export const BarcodeGroup = styled('div')`
   {
  }
`;
export const Title = styled('div')`
   {
    color: #ff5078;
    font-size: 16px;
    font-weight: 401;
    margin-bottom: 7px;
  }
`;
