import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import InactiveCustomerModal from '../../components/Modal';

import Button from '../../commons/Button';
import Text from '../../commons/Text';
import Input from '../../commons/Inputs/Input';
import TablePagination from '@material-ui/core/TablePagination';
import IncativeCustomerTable from '../../components/InactiveCustomer';
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '../../components/Modal';
import { walletDetailStyles, useStyles } from './wallet.styles';

const InactiveClientsModal: React.FC<any> = (props: any) => {
  const classes = useStyles();

  const {
    walletId,
    inactives,
    handleClickSearchInactives,
    HandleSendSelectedCustomerIds,
    handleClickRow,
    size,
    total,
    page,
    firstName,
    lastName,
    email,
    relanceOpen,
    searchQuery,
    loading,
    inactiveCustomerOpen,
    setRelanceOpen,
    setInactiveCustomerOpen,
    inactivesCustomersPropsChanged,
    getInactiveCustomersByWallet,
    selectedId,
  } = props;

  const [relance_is_loading, setIsLoading] = React.useState<any>(null);
  const intl = useIntl();

  const handleChangeInactivesPage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    inactivesCustomersPropsChanged('loading', true);
    inactivesCustomersPropsChanged('page', newPage);
    inactivesCustomersPropsChanged('inactives', []);

    getInactiveCustomersByWallet(walletId, {
      page: newPage,
      size,
      total,
      firstName,
      lastName,
      email,
    });
  };

  const handleChangeRowsPerInactivesPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    inactivesCustomersPropsChanged('loading', true);
    inactivesCustomersPropsChanged('page', 0);
    inactivesCustomersPropsChanged('size', event.target.value);

    getInactiveCustomersByWallet(walletId, {
      page,
      size: event.target.value,
      total,
      firstName,
      lastName,
      email,
    });
  };

  return (
    <>
      <div>
        <InactiveCustomerModal
          open={inactiveCustomerOpen}
          onClose={() => {
            inactivesCustomersPropsChanged('inactives', []);
            setInactiveCustomerOpen(false);
          }}
        >
          <div className='div-list' style={{ margin: '0 auto' }}>
            <div className={classes.search}>
              <Input
                value={searchQuery}
                background='#fff'
                type='text'
                name='search'
                placeholder={intl.formatMessage({
                  id: 'wallet.button.rechercher',
                })}
                margin='10px 0'
                className={classes.inputSearch}
                onChange={(event) => {
                  inactivesCustomersPropsChanged(
                    'firstName',
                    event.target.value
                  );
                  inactivesCustomersPropsChanged('page', 0);

                  getInactiveCustomersByWallet(walletId, {
                    page,
                    size,
                    total,
                    firstName: event.target.value,
                  });
                }}
              />
              <Button
                onClick={handleClickSearchInactives}
                width={120}
                background='#FF5078'
                className={classes.buttonSearch}
              >
                {intl.formatMessage({
                  id: 'wallet.button.rechercher',
                })}
              </Button>{' '}
              <div style={{ display: 'flex' }}>
                <Button
                  className={classes.relaunchButton}
                  onClick={() => {
                    setRelanceOpen(true);
                    inactivesCustomersPropsChanged('sendAll', true);
                  }}
                >
                  {relance_is_loading ? (
                    <CircularProgress size={20} color='inherit' />
                  ) : (
                    ''
                  )}
                  {intl.formatMessage({
                    id: 'inactives.message.resendAll',
                  })}
                </Button>
                <Button
                  className={classes.relaunchButton}
                  onClick={() => {
                    setRelanceOpen(true);
                  }}
                >
                  {relance_is_loading ? (
                    <CircularProgress size={20} color='inherit' />
                  ) : (
                    ''
                  )}
                  {intl.formatMessage({
                    id: 'inactives.message.resend',
                  })}
                </Button>
              </div>
            </div>

            <IncativeCustomerTable
              data={inactives}
              handleClickRow={handleClickRow}
              loading={loading}
              total={total}
              size={size}
            />
            {inactives.length != 0 && (
              <div className={classes.tableBottom}>
                <div>
                  <span>{selectedId.length || 0}</span>{' '}
                  <span>
                    {intl.formatMessage({
                      id: 'inactives.message.selected',
                    })}
                  </span>
                </div>

                <TablePagination
                  style={{ display: 'inline' }}
                  count={total}
                  page={+page}
                  onChangePage={handleChangeInactivesPage}
                  rowsPerPage={+size}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  onChangeRowsPerPage={handleChangeRowsPerInactivesPage}
                  labelRowsPerPage={'Lignes par page'}
                />
              </div>
            )}
          </div>
        </InactiveCustomerModal>
        <Modal open={relanceOpen} onClose={() => setRelanceOpen(!relanceOpen)}>
          <div className={classes.modalRelance}>
            <Text
              lineHeight={24}
              fontSize={16}
              color='#404040'
              style={{ textAlign: 'center' }}
            >
              {intl.formatMessage({
                id: 'inactives.message.sendToInactiveClients',
              })}
              <br />
            </Text>
            {relanceOpen ? (
              <div
                style={{
                  display: 'flex',
                  gap: '25px',
                  marginTop: '30px',
                  justifyContent: 'center',
                }}
              >
                <Button
                  background='#fff'
                  color='#FF5078'
                  border='1px solid #FF5078'
                  onClick={() => {
                    setRelanceOpen(false);
                  }}
                >
                  Non
                </Button>

                <Button onClick={HandleSendSelectedCustomerIds}>
                  {relance_is_loading ? (
                    <CircularProgress size={20} color='inherit' />
                  ) : (
                    ''
                  )}
                  Oui
                </Button>
              </div>
            ) : (
              ''
            )}
          </div>
        </Modal>
      </div>
    </>
  );
};

export default InactiveClientsModal;
