import axios from 'axios';
import { URL } from './config';
import { EventsApiResponse } from './ApiTypes';

export const getCinemaTicket = (walletId: string, cinemaTicketId: string) => {
  const requestURL = `${URL}/wallets/${walletId}/cinemas/${cinemaTicketId}`;

  return axios
    .get(requestURL)
    .then(({ data }: { data: any }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const createCinemaTicket = (
  walledId: string | null,
  cinemaTicket: any
) => {
  const requestURL = `${URL}/wallets/${walledId}/cinemas`;
  return axios
    .post(requestURL, cinemaTicket)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export const removeCinemaTicket = (
  cinemaTicketId: string,
  walletId: string
) => {
  const requestURL = `${URL}/wallets/${walletId}/cinemas/${cinemaTicketId}`;

  return axios
    .delete(requestURL)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};
