import { css } from 'styled-components';

export type TramsStylesProps = {
  // loading: string;
  //shipsLength: number;
};

export const TramsStyles = (props: any) => {
  let flex = 0;
  let justifyContent = 'center';
  if (props.loading === 'true') {
    flex = 1;
    justifyContent = 'center';
  } else if (props.shipsLength === 0 && props.loading === 'false') {
    flex = 1;
    justifyContent = 'center';
  }
  return css`
    display: flex;
    flex-direction: column;
    .div-search {
      display: grid;
      grid-template-columns: repeat(auto-fill, 380px);
      gap: 16px;
      margin-top: 16px;
      .filter-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        > input {
          background-color: #fff;
          font-family: Poppins;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          color: #404040;
          box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.08);
          border-radius: 15px;
        }
        .filter-button {
          display: flex;
          position: absolute;
          right: 0;
          width: 100px;
          align-items: center;
          justify-content: space-between;
          background: #691fde;
          height: 53px;
          box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.08);
          border-radius: 0 15px 15px 0;
          padding: 0 10px;
          transition: all 0.3s ease;
          :hover {
            cursor: pointer;
            background: #6438f5;
          }
          > h3 {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: white;
            width: 80px;
          }
        }
        .filter-opened {
          transform: rotate(180deg);
        }
        .select-input {
          width: 150px;
          visibility: hidden;
          position: absolute;
          right: 0;
          top: 80px;
        }
      }
      .input-search {
        box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.08);
        border-radius: 15px;
      }
    }
    .div-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px;
    }

    /* .div-input-filter {
      min-width: 400px;
      margin-right: 15px;
    } */

    .MuiOutlinedInput-adornedEnd {
      background-color: #fff;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #404040;
      box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.08);
      border-radius: 15px;
      padding: 0 4px 0 0;
    }
    .MuiIconButton-root.Mui-disabled {
      color: rgba(0, 0, 0, 0.38) !important;
    }
    .MuiCheckbox-root {
      color: #ff5078;
    }
    .MuiIconButton-root {
      color: #ff5078;
    }

    .divider {
      height: 28px;
      width: 2px;
      margin: 0px;
      background: #404040;
    }
    .MuiDivider-root {
      background-color: #c7c7c7;
    }
    .icon {
      svg path {
        fill: #ff5078;
      }
    }
    .div-list {
      display: grid;
      grid-template-columns: repeat(auto-fill, 370px);
      margin: 15px 0;
      gap: 16px;
    }
  `;
};
