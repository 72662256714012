import React from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Pagination from '@material-ui/lab/Pagination';

import { couponsStyles, CouponsStylesProps } from './coupons.styles';
import Button from '../../commons/Button';
import CouponModal from '../../components/Modal';
import CreateCoupon from '../Coupon';
import CouponCard from '../../components/Cards/CouponCard';

import {
  couponsGetAllCoupons,
  couponsSendCoupon,
  couponsInitialState,
  couponsUpdateCouponStatus,
  deleteCoupon,
  editCouponInitialState,
  couponsPropsChanged,
  couponsFilterCoupons,
} from '../../actions';

import { RootState } from '../../reducers';
//Images
import emptyState from '../../assets/images/emptyState.svg';
import EmptyData from '../../components/EmptyData';
import SimpleDialog from '../../components/Dialog';
import { CouponApiResponse } from '../../apis/ApiTypes';
import logo from '../../assets/images/logo-BestWallet.png';
import { getDataFromCache } from '../../utils/cache';
import Text from '../../commons/Text';
import EditCoupon from '../EditCoupon';
import { useHistory } from 'react-router-dom';
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Input from '../../commons/Inputs/Input';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import { StyledCheckbox } from '../../commons/CheckBox/index';
import Calendar from '../../icons/Calendar';
import { IconButton, TextField, InputAdornment } from '@material-ui/core';
import maxChars from '../../utils/fieldsMaxLength';

const Coupons: React.FC<any> = (props) => {
  const intl = useIntl();
  const rowsperpage = 20;

  const [page, setPage] = React.useState(0);
  const [open, setOpen] = React.useState<boolean>(false);

  const [openDialog, setOpenDialog] = React.useState(false);
  const [couponId, setCouponId] = React.useState('');
  const [couponIdConfirmed, setCouponIdConfirmed] = React.useState('');
  const [selectOpener, setSelectOpener] = React.useState<boolean>(false);

  const {
    couponsList,
    loading,
    loadingSend,
    status,
    allTime,
    startDate,
    endDate,
    endDateError,
    couponsFilteredList,
  } = props;

  const [EditDialogOpen, setEditDialogOpen] = React.useState(false);
  const objectUser: any = getDataFromCache('user');

  React.useEffect(() => {
    props.couponsGetAllCoupons(objectUser.wallet);
  }, []);

  React.useEffect(() => {
    return () => {
      props.couponsInitialState();
    };
  }, []);

  const isThereActifCoupon = (): boolean => {
    couponsList.forEach((element: any) => {
      if (element.status === 'ACTIF') {
        return true;
      }
    });
    return false;
  };

  if (couponsList.length > 0) {
    var actifCoupon = isThereActifCoupon();
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCouponIdConfirmed('');
  };

  const setCouponActif = (id: string, status: string) => {
    if (status === 'ACTIF') {
      props.couponsUpdateCouponStatus(objectUser.wallet, id, 'INACTIF');
    } else {
      props.couponsUpdateCouponStatus(objectUser.wallet, id, 'ACTIF');
    }
  };

  const handleClickSend = () => {
    props.couponsSendCoupon(objectUser.wallet, couponId);
    setCouponIdConfirmed(couponId);
    setOpenDialog(false);
  };

  const handleClickDeletecoupon = (couponId: string) => {
    props.deleteCoupon(couponId, objectUser.wallet);
  };
  const history = useHistory();

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    props.couponsPropsChanged('status', event.target.value);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.couponsPropsChanged(event.target.name, event.target.checked);
  };

  const handleDateChange = (prop: string) => (date: Date | null) => {
    props.couponsPropsChanged(prop, date);
  };
  const HandleClickSearch = () => {
    props.couponsFilterCoupons(
      status,
      allTime,
      startDate,
      endDate,
      couponsFilteredList
    );
  };

  const getFormatedMessage = (prop: any) => {
    return props[prop] !== ''
      ? intl.formatMessage({ id: props[prop] })
      : props[prop];
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value - 1);
  };

  const calculatePagesCount = (pageSize: number, totalCount: number) => {
    // we suppose that if we have 0 items we want 1 empty page
    return totalCount < pageSize ? 1 : Math.ceil(totalCount / pageSize);
  };

  return (
    <Wrapper loading={loading.toString()} couponsLength={couponsList.length}>
      <div className='div-search'>
        <FormControl className='filter-container' fullWidth>
          <Input
            value={
              status === 'ALL'
                ? intl.formatMessage({ id: 'offers.select.all' })
                : status === 'OPENED'
                ? intl.formatMessage({ id: 'offers.select.opened' })
                : status === 'ACTIF'
                ? intl.formatMessage({ id: 'offers.select.actif' })
                : status === 'INACTIF'
                ? intl.formatMessage({ id: 'offers.select.inactif' })
                : intl.formatMessage({ id: 'offers.select.expired' })
            }
            type='text'
            disabled
          />
          <div
            className='filter-button'
            onClick={() => setSelectOpener(!selectOpener)}
          >
            <h3>
              {intl.formatMessage({
                id: 'wallet.label.filter',
              })}
            </h3>
            <ExpandMoreRoundedIcon
              style={{ color: 'white' }}
              className={selectOpener ? 'filter-opened' : ''}
            />
          </div>
          <Select
            className='select-input'
            value={status}
            onChange={handleSelectChange}
            placeholder={intl.formatMessage({
              id: 'wallet.label.filter',
            })}
            id='filter'
            inputProps={{ className: 'input' }}
            open={selectOpener}
            onClose={() => setSelectOpener(false)}
          >
            <MenuItem
              value='ALL'
              onMouseEnter={(e) =>
                ((e.target as HTMLInputElement).style.color = '#f742ee')
              }
              onMouseLeave={(e) =>
                ((e.target as HTMLInputElement).style.color = '#000000')
              }
            >
              <span>{intl.formatMessage({ id: 'offers.select.all' })}</span>
            </MenuItem>
            <MenuItem
              value='ACTIF'
              onMouseEnter={(e) =>
                ((e.target as HTMLInputElement).style.color = '#f742ee')
              }
              onMouseLeave={(e) =>
                ((e.target as HTMLInputElement).style.color = '#000000')
              }
            >
              <span>{intl.formatMessage({ id: 'offers.select.actif' })}</span>
            </MenuItem>
            <MenuItem
              value='INACTIF'
              onMouseEnter={(e) =>
                ((e.target as HTMLInputElement).style.color = '#f742ee')
              }
              onMouseLeave={(e) =>
                ((e.target as HTMLInputElement).style.color = '#000000')
              }
            >
              <span>{intl.formatMessage({ id: 'offers.select.inactif' })}</span>
            </MenuItem>
            <MenuItem
              value='EXPIRED'
              onMouseEnter={(e) =>
                ((e.target as HTMLInputElement).style.color = '#f742ee')
              }
              onMouseLeave={(e) =>
                ((e.target as HTMLInputElement).style.color = '#000000')
              }
            >
              <span>{intl.formatMessage({ id: 'offers.select.expired' })}</span>
            </MenuItem>
          </Select>
        </FormControl>

        {!allTime && (
          <>
            <KeyboardDatePicker
              id='start-date'
              label={intl.formatMessage({ id: 'offers.label.startDate' })}
              format='MMM dd yyyy'
              value={startDate}
              onChange={handleDateChange('startDate')}
              inputVariant='outlined'
              style={{ width: '250px' }}
              clearable
              TextFieldComponent={({ InputProps, ...rest }) => (
                <TextField
                  {...rest}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Divider
                          className='divider'
                          orientation='vertical'
                          variant='inset'
                          light
                        />
                        <IconButton
                          onClick={
                            (InputProps as any)?.endAdornment.props.children
                              .props.onClick
                          }
                        >
                          <Calendar />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />

            <KeyboardDatePicker
              id='end-date'
              label={intl.formatMessage({ id: 'offers.label.endDate' })}
              format='MMM dd yyyy'
              inputVariant='outlined'
              value={endDate}
              onChange={handleDateChange('endDate')}
              error={endDateError !== ''}
              helperText={getFormatedMessage('endDateError')}
              style={{ width: '270px' }}
              disabled={startDate === null ? true : false}
              minDate={startDate}
              clearable
              TextFieldComponent={({ InputProps, ...rest }) => (
                <TextField
                  {...rest}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Divider
                          className='divider'
                          orientation='vertical'
                          variant='inset'
                          light
                        />
                        <IconButton
                          onClick={
                            (InputProps as any)?.endAdornment.props.children
                              .props.onClick
                          }
                        >
                          <Calendar />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </>
        )}
        <FormControlLabel
          control={
            <StyledCheckbox
              checked={allTime}
              onChange={handleCheckboxChange}
              name='allTime'
            />
          }
          label={intl.formatMessage({ id: 'offers.label.allTime' })}
        />

        <Button background='#FF5078' onClick={HandleClickSearch}>
          {intl.formatMessage({ id: 'offers.button.search' })}
        </Button>
      </div>

      <div className='div-header'>
        <Text color='#404040' fontSize={24} lineHeight={36} fontWeight={600}>
          {intl.formatMessage({ id: 'coupons.label.title' })}
        </Text>
        <Button
          onClick={() => {
            setOpen(!open);
          }}
        >
          {intl.formatMessage({ id: 'coupons.button.create' })}
        </Button>
      </div>

      <CouponModal open={open} onClose={() => setOpen(!open)}>
        <CreateCoupon
          walletId={objectUser.wallet}
          onSaveChanged={() => setOpen(!open)}
        ></CreateCoupon>
      </CouponModal>

      <CouponModal
        open={EditDialogOpen}
        onClose={() => setEditDialogOpen(false)}
      >
        <EditCoupon
          walletId={objectUser.wallet}
          couponId={couponId}
          onCancel={() => setEditDialogOpen(false)}
          onSaveChanged={() => setEditDialogOpen(!EditDialogOpen)}
        ></EditCoupon>
      </CouponModal>

      <div className='div-list'>
        <SimpleDialog
          title={intl.formatMessage({ id: 'coupons.dialog.title' })}
          text={intl.formatMessage({ id: 'coupons.dialog.text' })}
          handleAgree={handleClickSend}
          open={openDialog}
          handleClose={handleCloseDialog}
        ></SimpleDialog>

        <EmptyData
          data={couponsList}
          loading={loading}
          message={intl.formatMessage({ id: 'coupons.label.emptyList' })}
          image={emptyState}
        >
          {couponsList
            ?.slice(page * rowsperpage, page * rowsperpage + rowsperpage)
            ?.map((coupon: CouponApiResponse) => (
              <CouponCard
                status={coupon.status === 'ACTIF' ? true : false}
                disabled={
                  coupon.status === 'EXPIRED'
                    ? true
                    : coupon.status === 'INACTIF' && actifCoupon
                    ? true
                    : false
                }
                loading={loadingSend}
                onClickActivate={setCouponActif}
                key={coupon._id}
                onClickDelete={handleClickDeletecoupon}
                coupon={coupon}
                couponId={couponIdConfirmed}
                onClickRedirect={() => history.push('/coupons/' + coupon._id)}
                onClickSend={() => {
                  setOpenDialog(true);
                  setCouponId(coupon._id);
                }}
                onClickEdit={(id) => {
                  props.editCouponInitialState(coupon, props.currencies);
                  setEditDialogOpen(true);
                  setCouponId(coupon._id);
                }}
              ></CouponCard>
            ))}
        </EmptyData>
      </div>
      <Box display='flex' justifyContent='flex-end' marginTop='auto'>
        <Pagination
          count={calculatePagesCount(rowsperpage, couponsList?.length || 0)}
          variant='outlined'
          shape='rounded'
          onChange={handleChange}
        />
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled('div')<CouponsStylesProps>`
  ${(props) => couponsStyles(props)}
`;

const mapStateToProps = ({ coupons, walletForm }: RootState) => {
  const {
    couponsList,
    startDate,
    allTime,
    endDate,
    couponsFilteredList,
    loading,
    loadingSend,
    status,
    endDateError,
  } = coupons;

  return {
    couponsList,
    couponsFilteredList,
    allTime,
    loading,
    currencies: walletForm.currencies,
    loadingSend,
    startDate,
    endDate,
    status,
    endDateError,
  };
};

export const connector = connect(mapStateToProps, {
  couponsGetAllCoupons,
  couponsSendCoupon,
  couponsInitialState,
  couponsUpdateCouponStatus,
  editCouponInitialState,
  couponsPropsChanged,
  deleteCoupon,
  couponsFilterCoupons,
});

export default connector(Coupons);
