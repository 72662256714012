import moment, { Moment } from 'moment';
import {
  getFerriesChildrenList,
  getFerriesList,
  removeCustomerFerry,
} from '../apis/ferriesApi';
import { removeFerry } from '../apis/ferryApi';

import {
  FERRIES_GET_LIST_FERRIES,
  FERRIES_INITIAL_STATE,
  AppThunk,
  FERRIES_PROPS_CHANGED,
  FERRIES_FILTER_FERRIES,
  FERRIES_DELETE_FERRY_LOADING,
  FERRIES_DELETE_FERRY,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
  FERRIES_GET_SENT_FERRIES,
  FERRIES_DELETE_CUSTOMER_FERRY,
} from './types';
import { TransitsApiResponse } from '../apis/ApiTypes';
import {
  FerriesChangedAction,
  FerriesInitialStateAction,
} from '../modules/Ships/ships.types';

export const ferriesGetAllFerries = (walletId: string): AppThunk => (
  dispatch
) => {
  getFerriesList(walletId).then((res: Array<TransitsApiResponse>) => {
    dispatch({
      type: FERRIES_GET_LIST_FERRIES,
      payload: res,
    });
  });
};

export const ferriesPropsChanged = (
  prop: string,
  value: any
): FerriesChangedAction => {
  return { type: FERRIES_PROPS_CHANGED, prop, value };
};

export const ferriesFilterFerries = (
  status: string,
  allTime: boolean,
  startDate: Moment | null,
  endDate: Moment | null,
  ferries: Array<TransitsApiResponse>
): AppThunk => (dispatch) => {
  let newFerries: Array<TransitsApiResponse> = Object.assign([], ferries);
  const result: Array<TransitsApiResponse> = [];
  let dateStart = `${moment(startDate).format('YYYY-MM-DD')}T00:00:00.000Z`;
  let dateEnd = `${moment(endDate).format('YYYY-MM-DD')}T00:00:00.000Z`;
  // let offersFilterByStatus = newFerries.filter(
  //   (item: OfferApiResponse) => item.status === status
  //   // &&moment(item.startDate).isSameOrAfter(dateStart)
  //   // &&moment(item.endDate).isSameOrBefore(dateEnd);
  // );

  if (startDate && endDate && startDate.isAfter(endDate, 'day')) {
    dispatch({
      type: FERRIES_PROPS_CHANGED,
      prop: 'endDateError',
      value: 'offer.error.endDateBeforeStartDate',
    });
  } else {
    dispatch({
      type: FERRIES_PROPS_CHANGED,
      prop: 'endDateError',
      value: '',
    });

    // if (allTime) {
    //   if (status === 'ALL') {
    //     dispatch({
    //       type: FERRIES_FILTER_FERRIES,
    //       allTime,
    //       payload: newFerries,
    //     });
    //   } else {
    //     newFerries.forEach((item) => {
    //       if (item.status === status) {
    //         result.push(item);
    //       }
    //     });
    //     dispatch({
    //       type: FERRIES_FILTER_FERRIES,
    //       allTime,
    //       payload: result,
    //     });
    //   }
    // } else if (startDate && endDate) {
    //   if (status === 'ALL') {
    //     newFerries.forEach((item) => {
    //       if (
    //         moment(item.departureTime).isSameOrAfter(dateStart) &&
    //         moment(item.arrivalTime).isSameOrBefore(dateEnd)
    //       ) {
    //         result.push(item);
    //       }
    //     });
    //     dispatch({
    //       type: FERRIES_FILTER_FERRIES,
    //       allTime,
    //       payload: result,
    //     });
    //   } else {
    //     newFerries.forEach((item) => {
    //       if (
    //         item.status === status &&
    //         moment(item.departureTime).isSameOrAfter(dateStart) &&
    //         moment(item.arrivalTime).isSameOrBefore(dateEnd)
    //       ) {
    //         result.push(item);
    //       }
    //     });

    //     dispatch({
    //       type: FERRIES_FILTER_FERRIES,
    //       allTime,
    //       payload: result,
    //     });
    //   }
    // } else {
    //   if (status === 'ALL') {
    //     dispatch({
    //       type: FERRIES_FILTER_FERRIES,
    //       allTime,
    //       payload: newFerries,
    //     });
    //   } else {
    //     newFerries.forEach((item) => {
    //       if (item.status === status) {
    //         result.push(item);
    //       }
    //     });
    //     dispatch({
    //       type: FERRIES_FILTER_FERRIES,
    //       allTime,
    //       payload: result,
    //     });
    //   }
    // }
    if (startDate) {
      newFerries.forEach((item) => {
        if (moment(item.departureTime).isSameOrAfter(moment(dateStart))) {
          result.push(item);
        }
      });

      dispatch({
        type: FERRIES_FILTER_FERRIES,
        allTime,
        payload: result,
      });
    } else if (startDate && endDate) {
      newFerries.forEach((item) => {
        if (
          moment(item.departureTime).isSameOrAfter(moment(dateStart)) &&
          moment(item.arrivalTime).isSameOrBefore(moment(dateEnd))
        ) {
          result.push(item);
        }
      });

      dispatch({
        type: FERRIES_FILTER_FERRIES,
        allTime,
        payload: result,
      });
    } else {
      dispatch({
        type: FERRIES_FILTER_FERRIES,
        allTime,
        payload: newFerries,
      });
    }
  }
};

export const deleteFerry = (ferryId: string, walletId: string): AppThunk => (
  dispatch
) => {
  dispatch({
    type: FERRIES_DELETE_FERRY_LOADING,
    payload: true,
  });
  removeFerry(ferryId, walletId).then((res) => {
    if (res.data.status === 'success') {
      dispatch({
        type: FERRIES_DELETE_FERRY,
        payload: ferryId,
      });
      dispatch({
        type: SNACKBAR_SUCCESS,
        value: 'transit.success.deleted',
      });
    } else
      dispatch({
        type: SNACKBAR_ERROR,
        value: 'wallet.error.occured',
      });
  });
};
export const ferriesGetChildrens = (
  walletId: string,
  ferryId: string,
  page: number | string,
  size: number | string,
  searchFilter: any
): AppThunk => (dispatch) => {
  getFerriesChildrenList(walletId, ferryId, page, size, searchFilter).then(
    (res: any) => {
      if (res.status === 500) {
        dispatch({
          type: SNACKBAR_ERROR,
          value: 'wallet.error.occured',
        });
      } else if (res.status === 401) {
        dispatch({
          type: SNACKBAR_ERROR,
          value: 'Id malformés',
        });
        ferriesPropsChanged('loading', false);
      } else
        dispatch({
          type: FERRIES_GET_SENT_FERRIES,
          payload: res.data,
          total: res.total,
        });
    }
  );
};

export const deleteCustomerFerry = (
  ferryId: string,
  customerId: string,
  passId: string,
  walletId: string
): AppThunk => (dispatch) => {
  dispatch({
    type: FERRIES_DELETE_FERRY_LOADING,
    payload: true,
  });
  removeCustomerFerry(ferryId, customerId, walletId).then((res) => {
    if (res.data.status === 'success') {
      dispatch({
        type: FERRIES_DELETE_CUSTOMER_FERRY,
        payload: passId,
      });

      dispatch({
        type: SNACKBAR_SUCCESS,
        value: 'transit.success.deleted',
      });
    } else {
      dispatch({
        type: SNACKBAR_ERROR,
        value: 'wallet.error.occured',
      });
    }
  });
};

export const ferriesInitialState = (): FerriesInitialStateAction => {
  return { type: FERRIES_INITIAL_STATE };
};
