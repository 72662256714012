import { getEventsCustomersList } from '../apis/eventsApi';
import {
  EVENTS_GET_CUSTOMERS_LIST,
  EVENTS_CUSTOMERS_INITIAL_STATE,
  AppThunk,
  EVENT_CUSTOMERS_PROPS_CHANGED,
  WALLET_FILTER_CUSTOMERS,
  EVENT_FILTER_CUSTOMERS,
} from './types';
import { EventsCustomersApiResponse } from '../apis/ApiTypes';
import {
  EventsCustomersInitialStateAction,
  EventCustomersChangedAction,
  SelectCustomerProps,
} from '../modules/EventCustomers/eventCustomers.types';

export const eventsCustomersGetAllCustomers = (
  walletId: string,
  eventId: string
): AppThunk => (dispatch) => {
  getEventsCustomersList(walletId, eventId).then((res: any) => {
    dispatch({
      type: EVENTS_GET_CUSTOMERS_LIST,
      payload: res,
    });
  });
};

export const eventCustomersPropsChanged = (
  prop: string,
  value: any
): EventCustomersChangedAction => {
  return { type: EVENT_CUSTOMERS_PROPS_CHANGED, prop, value };
};

export const eventCustomersFilterCustomers = (
  select: SelectCustomerProps,
  search: string,
  customers: any
): AppThunk => (dispatch) => {
  let newCustomers: any = Object.assign([], customers);

  const result = newCustomers[0].customers.filter((item: any) => {
    if (typeof item[select] === 'string') {
      return item[select].toLocaleLowerCase().match(search.toLocaleLowerCase());
    }
  });
  dispatch({
    type: EVENT_FILTER_CUSTOMERS,
    search,
    customers: result,
  });
};

export const eventsCustomersInitialState = (): EventsCustomersInitialStateAction => {
  return { type: EVENTS_CUSTOMERS_INITIAL_STATE };
};
