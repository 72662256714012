import axios from 'axios';
import { URL } from './config';
import { CouponApiResponse } from './ApiTypes';

export const getCouponsList = (walletID: any) => {
  const requestURL = `${URL}/wallets/${walletID}/coupons`;

  return axios
    .get(requestURL)
    .then(({ data }: { data: Array<CouponApiResponse> }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const getCouponsChildrenList = (
  walletId: any,
  couponId: any,
  page: number | string,
  size: number | string,
  searchFilter: any | null = null
) => {
  let requestURL = `${URL}/wallets/${walletId}/coupons/${couponId}/sent`;
  if (searchFilter) {
    const filterItem = searchFilter.find(
      (elem: { name: string; value: string }) => elem.value !== ''
    );
    if (filterItem !== undefined)
      requestURL = `${URL}/wallets/${walletId}/coupons/${couponId}/sent?${filterItem.name}=${filterItem.value}`;
  }
  return axios
    .get(requestURL, {
      params: {
        page,
        size,
      },
    })
    .then(({ data }: { data: Array<CouponApiResponse> }) => {
      return data;
    })
    .catch((error) => {
      return error.response;
    });
};

export const sendCoupon = (walletID: string, couponId: string) => {
  const requestURL = `${URL}/wallets/${walletID}/coupons/${couponId}/send`;

  return axios
    .post(requestURL)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export const activateCoupon = (
  walletId: string,
  couponId: string,
  status: string
) => {
  const requestURL = `${URL}/wallets/${walletId}/coupons/${couponId}`;

  return axios
    .patch(requestURL, { status })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export const removeCoupons = (couponId: string, walletId: string) => {
  const requestURL = `${URL}/wallets/${walletId}/coupons/${couponId}`;

  return axios
    .delete(requestURL)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};
export const removeCustomerCoupon = (
  couponId: string,
  customerId: string,
  walletId: string
) => {
  const requestURL = `${URL}/wallets/${walletId}/coupons/${couponId}/customers/${customerId}`;

  return axios
    .delete(requestURL)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};
