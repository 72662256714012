import React from 'react';

// TramIconCard

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='85'
      height='123'
      fill='none'
      viewBox='0 0 122 123'
    >
      <g filter='url(#filter0_d_185_22614)'>
        <rect
          width='52'
          height='52'
          x='61'
          y='21'
          fill='#fff'
          rx='7'
          transform='rotate(45 61 21)'
        ></rect>
        <path
          fill='#FF5078'
          fillRule='evenodd'
          d='M53.29 40.095c-2.681.542-4.648 2.512-5.166 5.175-.165.846-.165 16.792 0 17.703a6.434 6.434 0 001.812 3.402c.822.798 1.555 1.24 2.686 1.617l.51.17-1.884 3.053c-1.594 2.581-1.89 3.11-1.921 3.425-.074.756.47 1.36 1.225 1.36.707 0 .898-.169 1.736-1.535l.637-1.038h16.17l.602.985C70.57 75.837 70.751 76 71.47 76c.753 0 1.297-.605 1.224-1.36-.03-.315-.327-.843-1.919-3.422l-1.883-3.05.735-.26a6.312 6.312 0 002.526-1.616c1.004-1.041 1.582-2.187 1.775-3.524.066-.454.085-3.069.066-9.042-.028-9.125.002-8.503-.468-9.748-.235-.62-.85-1.53-1.42-2.1-.56-.56-1.499-1.184-2.14-1.422-1.25-.463-.732-.436-8.752-.453-5.879-.012-7.503.007-7.923.092zm15.28 3.962c.578.285.92.61 1.221 1.16l.279.508v9.086l-.278.507c-.308.563-.561.805-1.176 1.126l-.415.217H53.819l-.514-.276c-.556-.298-.883-.636-1.172-1.21l-.183-.364v-9.086l.2-.402c.348-.694 1.018-1.236 1.75-1.414.134-.033 3.407-.054 7.272-.047l7.029.013.368.182zM55.023 60.67c.834.345 1.038 1.428.389 2.07-.527.522-1.28.522-1.807 0-1.005-.994.104-2.614 1.417-2.07zm13 0c.835.345 1.039 1.428.39 2.07-.913.904-2.41.095-2.17-1.173.144-.757 1.035-1.206 1.78-.897zm-1.322 8.878l.776 1.264-3.234.021c-1.779.012-4.69.012-6.469 0l-3.234-.02.776-1.265.777-1.264h9.832l.776 1.264z'
          clipRule='evenodd'
        ></path>
      </g>
      <defs>
        <filter
          id='filter0_d_185_22614'
          width='121.738'
          height='121.74'
          x='0.131'
          y='0.899'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feColorMatrix
            in='SourceAlpha'
            result='hardAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          ></feColorMatrix>
          <feOffset dy='4'></feOffset>
          <feGaussianBlur stdDeviation='13.5'></feGaussianBlur>
          <feComposite in2='hardAlpha' operator='out'></feComposite>
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0'></feColorMatrix>
          <feBlend
            in2='BackgroundImageFix'
            result='effect1_dropShadow_185_22614'
          ></feBlend>
          <feBlend
            in='SourceGraphic'
            in2='effect1_dropShadow_185_22614'
            result='shape'
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

export default Icon;
