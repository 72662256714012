import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { wrap } from 'module';
import styled from 'styled-components';

const drawerWidth = 75;
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: 1,
      height: '100vh',
    },
    appBar: {
      backgroundColor: '#ffffff',
      borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 8,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      // boxShadow: '0px 0px 20px 2px rgba(73, 20, 244, 0.16)',
      backgroundColor: '#F9F9F9',
      zIndex: 1300,
      borderRadius: '0px 20px 0px 0px',
    },
    drawerOpen: {
      backgroundColor: '#FFF',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),

      borderRadius: '0px 20px 0px 0px',
    },
    drawerClose: {
      borderRadius: '0px 20px 0px 0px',
      backgroundColor: '#F9F9F9',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(7) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      // padding: theme.spacing(0, 1),
      height: '150px',
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    navToolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      // padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      borderBottom: '1px solid #E5E5E5',
      // padding: '10px 0',
      background:
        'linear-gradient(270deg, rgba(255, 80, 120, 0.33) 45.81%, rgba(154, 48, 190, 0.09) 100%)',
      marginLeft: '-16px',
      height: '60px',
    },
    content: {
      flexGrow: 1,

      // padding: theme.spacing(2),
      padding: '0 0 16px 0px',
    },
    listItem: {
      justifyContent: 'center',
      color: '#FF5078',
    },
    listItemIcon: {
      color: '#FF5078',
      minWidth: 'unset',
      '&:hover': {
        fill: 'FF5078',
      },
    },
    listItemIconSelected: {
      color: '#E45C9A',
      minWidth: 'unset',
    },
    listItemText: {
      color: '#FF5078',
    },
    svg: {
      minWidth: 'unset',
      '& svg  path ': {
        // fill: 'linear-gradient(265.47deg, #FF5078 3.98%, #E45C9A 96.03%)',
        fill: '#FF5078',
      },
    },
    logoContainer: {
      // minWidth: '160px',
      // height: '60px',
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '30px',
    },
    logoSymbole: {
      height: '25px',
      objectFit: 'contain',
      margin: '10px',
    },
    logoText: {
      height: '10px',
      objectFit: 'contain',
    },
    circleIcon: {
      cursor: 'pointer',
      color: '#fff',
    },
    headerSettings: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    pageTitle: {
      fontSize: '24px',
      fontWeight: 600,
      lineHeight: '36px',
      flex: 1,
      textAlign: 'center',
      color: '#404040',
    },
    profile: {
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      gap: '16px',
      boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.06)',
      // borderRadius: '15px',
      marginRight: '5rem',
      background: '#FFF',
      borderBottomLeftRadius: '20px',
      borderBottomRightRadius: '15px',
      borderTopLeftRadius: '20px',
      borderTopRightRadius: '15px',
      '& svg': {
        width: '25px',
        height: '25px',
        marginRight: '5px',
      },
    },
    profilePicture: {
      height: '40px',
      width: '40px',
      background: 'linear-gradient(265.47deg, #9D30BB 3.98%, #8C2CCC 96.03%)',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    accountData: {
      fontWeight: 500,
      size: '12px',
      color: ' #828282',
    },
    menu: {
      '& .MuiMenu-paper': {
        top: '52px !important',
      },
    },
    walletCont: {
      display: 'flex',
      alignItems: 'center',
      //borderRadius: '20px',
      //boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.06)',
      marginLeft: '5rem',
    },
    walletLogoCont: {
      height: '56px',
      width: '56px',
    },
    walletLogo: {
      // height: '100%',
      // width: '100%',
      height: '56px',
      width: '56px',
      objectFit: 'cover',
      borderRadius: '10px',
    },
    walletIcon: {
      stopColor: 'red',
    },
  })
);

export const Root = styled.div`
  display: flex;
  flex: 1;
  height: 100vh;
  .MuiListItemIcon-root svg path:hover {
    //color: inherit;
    fill: #ff5078;
  }
  .hoverable:hover {
    cursor: pointer;
  }
  .MuiListItem-button:hover {
    background-color: transparent;
  }
  .MuiList-padding {
    padding-top: 25px !important;
  }
  .container {
    padding: 20px;
    height: calc(100vh - 65px);
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
  }
`;
