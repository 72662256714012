import styled from 'styled-components';

export const LoginStyles = styled.div`
  display: grid;
  width: 100%;
  max-height: 100vh;
  grid-template-columns: 3fr 5fr;
  background: linear-gradient(
    141.47deg,
    #ff5078 26.06%,
    #9d30bb 53.44%,
    #8c2ccc 71.62%,
    #691fde 94.22%
  );
  overflow: hidden;
  .panel {
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    .panel-logo {
      position: absolute;
      top: 30%;
      left: 50%;
      height: 250px;
      transform: translate(-35%);
      z-index: 99;
      img {
        height: 100%;
        width: auto;
        object-fit: contain;
        &:hover {
          animation: glitch 0.3s linear infinite;
          cursor: pointer;
        }
      }
    }
    .shape {
      position: absolute;
      top: -13%;
      right: 0;
      width: 100%;
      min-width: 700px;
      height: 215vh;
      background-color: #fff;
      border-radius: 0px 50px 50px 0px;
      transform: rotate(21.55deg);
    }
  }

  .form-container {
    position: relative;
    display: flex;
    height: 100vh;
    align-items: center;
    .signin {
      position: absolute;
      display: flex;
      top: 37%;
      left: 35%;
      max-width: max-content;
      flex-direction: column;

      .signin-logo {
        display: none;
      }

      .signin-bestwallet {
        height: 65px;
        width: 450px;
        margin-bottom: 30px;
        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
      .signin-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 40px;
        box-sizing: border-box;
        .title {
          color: #fff;
          margin-right: auto;
        }
        .forgot-password-text {
          align-self: flex-end;
          font-size: 14px;
          color: #fff;
          text-decoration: none;
          margin-top: 4px;
          margin-bottom: 20px;
          cursor: pointer;
          font-weight: 500;
        }
        .login-text-error {
          font-size: 16px;
          font-weight: 500;
          margin: 10px 0;
          color: #fff;
        }
      }
    }
  }

  @media only screen and (max-width: 1180px) {
    display: flex;
    width: 100%;
    max-height: 100vh;
    flex-direction: column;
    background: linear-gradient(
      141.47deg,
      #ff5078 26.06%,
      #9d30bb 53.44%,
      #8c2ccc 71.62%,
      #691fde 94.22%
    );
    overflow: hidden;
    .panel {
      display: none;
    }
    .form-container {
      justify-content: center;
      .signin {
        position: unset;
        .signin-logo {
          display: flex;
          width: 150px;
          height: 150px;
          align-items: center;
          justify-content: center;
          background-color: #fff;
          border-radius: 50%;
          margin: 0 auto 60px auto;
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          img {
            width: 95%;
            width: 95%;
            object-fit: contain;
          }
        }
      }
    }
  }

  @keyframes glitch {
    0% {
      transform: translate(0);
    }
    20% {
      transform: translate(-2px, 2px);
    }
    40% {
      transform: translate(-2px, -2px);
    }
    60% {
      transform: translate(2px, 2px);
    }
    80% {
      transform: translate(2px, -2px);
    }
    100% {
      transform: translate(0);
    }
  }
`;
