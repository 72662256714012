import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';
import {
  Body,
  Devices,
  Header,
  Icon,
  SectionTitle,
  Title,
  Wrapper,
} from './barcodeSelection.style';

import IOS from '../../assets/images/ios.svg';
import ANDROID from '../../assets/images/android.svg';

type barcodeTypeSelectionProps = {
  handleSecurityPropsChange: (name: string, value: boolean) => void;
  enableOneTimeUse: boolean;
  disableIOSPassSharing: boolean;
  disableMultipleHolders: boolean;
  enableSecurityAnimation: boolean;
  enableRotatingBarcode: boolean;
  disableEmailSharing: boolean;
  layout?: string;
};

const SecurityOptions: React.FC<barcodeTypeSelectionProps> = (props) => {
  const {
    handleSecurityPropsChange,
    enableOneTimeUse,
    disableIOSPassSharing,
    disableMultipleHolders,
    enableSecurityAnimation,
    enableRotatingBarcode,
    disableEmailSharing,
    layout,
  } = props;

  const intl = useIntl();
  return (
    <div>
      <SectionTitle>
        {intl.formatMessage({ id: 'security.label.title' })}
      </SectionTitle>
      <Devices layout={layout}>
        <Wrapper>
          <Header>
            <Title>IOS</Title>
            <Icon>
              <img src={IOS} alt='IOS' />
            </Icon>
          </Header>
          <Body>
            <FormControlLabel
              defaultChecked
              control={
                <Checkbox
                  value={disableIOSPassSharing}
                  onChange={(e) => {
                    handleSecurityPropsChange(
                      'disableIOSPassSharing',
                      e.target.checked
                    );
                  }}
                  checked={disableIOSPassSharing}
                />
              }
              label={intl.formatMessage({
                id: 'security.ios.disableSharing',
              })}
            />
            <FormControlLabel
              defaultChecked
              control={
                <Checkbox
                  value={enableOneTimeUse}
                  onChange={(e) =>
                    handleSecurityPropsChange(
                      'enableOneTimeUse',
                      e.target.checked
                    )
                  }
                  checked={enableOneTimeUse}
                />
              }
              label={intl.formatMessage({
                id: 'security.all.enableOneTimeUse',
              })}
            />
            <FormControlLabel
              disabled
              defaultChecked
              control={
                <Checkbox
                  value={disableEmailSharing}
                  onChange={(e) =>
                    handleSecurityPropsChange(
                      'disableEmailSharing',
                      e.target.checked
                    )
                  }
                  checked={disableEmailSharing}
                />
              }
              label={
                intl.formatMessage({
                  id: 'security.all.disableEmailSharing',
                }) + ' (Coming soon)'
              }
            />
          </Body>
        </Wrapper>
        <Wrapper>
          <Header>
            <Title>Android</Title>
            <Icon>
              <img src={ANDROID} alt='ANDROID' />
            </Icon>
          </Header>
          <Body>
            <FormControlLabel
              defaultChecked
              control={
                <Checkbox
                  value={enableSecurityAnimation}
                  onChange={(e) =>
                    handleSecurityPropsChange(
                      'enableSecurityAnimation',
                      e.target.checked
                    )
                  }
                  checked={enableSecurityAnimation}
                />
              }
              label={intl.formatMessage({
                id: 'security.android.enableSecurityAnimation',
              })}
            />
            <FormControlLabel
              defaultChecked
              control={
                <Checkbox
                  value={disableMultipleHolders}
                  onChange={(e) =>
                    handleSecurityPropsChange(
                      'disableMultipleHolders',
                      e.target.checked
                    )
                  }
                  checked={disableMultipleHolders}
                />
              }
              label={intl.formatMessage({
                id: 'security.android.disableMultipleHolders',
              })}
            />
            <FormControlLabel
              defaultChecked
              control={
                <Checkbox
                  value={enableOneTimeUse}
                  onChange={(e) =>
                    handleSecurityPropsChange(
                      'enableOneTimeUse',
                      e.target.checked
                    )
                  }
                  checked={enableOneTimeUse}
                />
              }
              label={intl.formatMessage({
                id: 'security.all.enableOneTimeUse',
              })}
            />{' '}
            <FormControlLabel
              defaultChecked
              disabled
              control={
                <Checkbox
                  value={enableRotatingBarcode}
                  onChange={(e) =>
                    handleSecurityPropsChange(
                      'enableRotatingBarcode',
                      e.target.checked
                    )
                  }
                  checked={enableRotatingBarcode}
                />
              }
              label={
                intl.formatMessage({
                  id: 'security.android.enableBarcodeRotation',
                }) + ' (Coming soon)'
              }
            />
            <FormControlLabel
              defaultChecked
              disabled
              control={
                <Checkbox
                  value={disableEmailSharing}
                  onChange={(e) =>
                    handleSecurityPropsChange(
                      'disableEmailSharing',
                      e.target.checked
                    )
                  }
                  checked={disableEmailSharing}
                />
              }
              label={
                intl.formatMessage({
                  id: 'security.all.disableEmailSharing',
                }) + ' (Coming soon)'
              }
            />
          </Body>
        </Wrapper>
      </Devices>
    </div>
  );
};

export default SecurityOptions;
