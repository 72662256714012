import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      width: 'fit-content',
      height: 'fit-content',
      // maxWidth: '650px',
      minWidth: '420px',
      maxHeight: '582px',
      overflowY: 'auto',
      padding: '30px',
      margin: 'auto',
      background: '#FFFFFF',
      boxShadow: '0px 0px 20px 2px rgba(55, 55, 55, 0.08)',
      borderRadius: '20px',
    },
    modal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);
