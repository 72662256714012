import React from 'react';
import { useIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

//Style
import { LoginStyles } from './login.styles';
//Commomns
import Input from '../../commons/Inputs/Input';
import Button from '../../commons/Button';
import ForgotPasswpordModal from '../../components/Modal';
import ForgotPassword from '../ForgotPassword';
//Actions
import { loginPropsChanged, onLogin } from '../../actions';

import logo from '../../assets/images/logo-bestwallet-symbole.png';
import bestwallet from '../../assets/images/logo-bestwallet-nom-transparant.png';
import Wallet from '../../assets/images/Wallet';
import { RootState } from '../../reducers';
import { LoginFormProps, LoginStateError } from './login.types';
import { CircularProgress } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import { getDataFromCache } from '../../utils/cache';
import clsx from 'clsx';
import { LanguagesSelect } from '../../commons';
import { isWindows } from 'react-device-detect';
const Login: React.FC<LoginFormProps> = (props) => {
  const intl = useIntl();
  const [open, setOpen] = React.useState<boolean>(false);
  const { username, password, error, loading } = props;
  const [passwordShown, setPasswordShown] = React.useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const token = getDataFromCache('token');
  const objectUser: any = getDataFromCache('user');

  // if (token && objectUser.wallet) {
  //   return <Redirect to={{ pathname: `/wallet/${walletId}` }} />;
  // }

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.loginPropsChanged(event.target.name, event.target.value);
  };

  const handleLogin = () => {
    if (username.trim() === '' && password.trim() === '') {
      props.loginPropsChanged(
        'error',
        intl.formatMessage({ id: 'login.error.fieldsReqiured' })
      );
    } else
      props.onLogin(username, password, (walletId: string) => {
        // props.history.push(`/wallet/${walletId}`);
        props.history.push(`/statistics`);
        //window.location.reload();
      });

    //  else {
    //   props.loginPropsChanged(
    //     'error',
    //     intl.formatMessage({ id: 'login.error.errorMessage' })
    //   );
    // }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13 || event.which === 13) {
      handleLogin();
    }
  };

  const getFormatedMessage = (prop: LoginStateError) => {
    return props[prop] !== ''
      ? intl.formatMessage({ id: props[prop] })
      : props[prop];
  };

  return (
    <LoginStyles>
      <ForgotPasswpordModal open={open} onClose={() => setOpen(!open)}>
        <ForgotPassword onSaveChanged={() => setOpen(!open)}></ForgotPassword>
      </ForgotPasswpordModal>

      <div className='panel'>
        <div className='panel-logo'>
          <img src={logo} alt='logo' />
        </div>
        <div className='shape' />
      </div>
      <div className='form-container'>
        <LanguagesSelect />
        <div className='signin'>
          <div className='signin-logo'>
            <img src={logo} alt='logo' />
          </div>
          <div className='signin-bestwallet'>
            <img src={bestwallet} alt='bestwallet' />
          </div>

          <div className='signin-form'>
            <label className='title'>
              {intl.formatMessage({
                id: 'profile.label.communicationEmailUser',
              })}
            </label>
            <Input
              // error={intl.formatMessage({ id: 'login.error.errorMessage' })}
              value={username}
              type='text'
              name='username'
              placeholder={intl.formatMessage({
                id: 'login.placeholder.username',
              })}
              onChange={(e) => handleEventChange(e)}
              onKeyPress={(e) => handleKeyPress(e)}
              margin={'15px 0px'}
            />
            <label className='title'>
              {intl.formatMessage({
                id: 'profile.label.password',
              })}
            </label>
            <Input
              // error={intl.formatMessage({ id: 'login.error.errorMessage' })}
              value={password}
              type={passwordShown ? 'text' : 'password'}
              name='password'
              placeholder={intl.formatMessage({
                id: 'login.placeholder.password',
              })}
              onChange={(e) => handleEventChange(e)}
              onKeyPress={(e) => handleKeyPress(e)}
              margin={'15px 0px'}
            />
            <InputAdornment position='end'>
              <IconButton
                onClick={togglePassword}
                style={{
                  position: 'relative',
                  left: '9rem',
                  top: '-41px',
                }}
              >
                {passwordShown ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
            <span
              onClick={() => setOpen(!open)}
              className='forgot-password-text'
            >
              {intl.formatMessage({ id: 'login.label.forgotPassword' })}
            </span>
            {error && (
              <span className='login-text-error'>
                {getFormatedMessage('error')}
                {/* {intl.formatMessage({ id: 'login.error.errorMessage' })} */}
              </span>
            )}
            <Button onClick={handleLogin} margin='10px' background='#FF5078'>
              {loading ? (
                <CircularProgress size={20} color='inherit' />
              ) : (
                intl.formatMessage({ id: 'login.button.login' })
              )}
            </Button>
          </div>
        </div>
      </div>
    </LoginStyles>
  );
};

const mapStateToProps = ({ login }: RootState) => {
  return {
    username: login.username,
    password: login.password,
    error: login.error,
    loading: login.loading,
  };
};

export const connector = connect(mapStateToProps, {
  loginPropsChanged,
  onLogin,
});

export default connector(Login);
