import {
  resetPassword,
  resetPasswordVerification,
} from '../apis/resetPasswordApi';
import {
  RESET_PASSWORD_PROPS_CHANGED,
  RESET_PASSWORD_VALIDATION_PROPS,
  RESET_PASSWORD_INITIAL_STATE,
  RESET_PASSWORD_VERIFICATION_TOKEN_FAILED,
  RESET_PASSWORD_VERIFICATION_TOKEN_SUCCESS,
  AppThunk,
} from './types';
import { validateFieldsResetPassword } from '../modules/ResetPassword/resetPassword.utils';

import {
  ResetPasswordState,
  ResetPasswordPropsChangedAction,
  ResetPasswordInitialStateAction,
} from '../modules/ResetPassword/resetPassword.types';

export const resetPasswordPropsChanged = (
  prop: string,
  value: any
): ResetPasswordPropsChangedAction => {
  return { type: RESET_PASSWORD_PROPS_CHANGED, prop, value };
};

export const resetPasswordverificationToken = (token: string): AppThunk => (
  dispatch
) => {
  resetPasswordVerification(token).then((res: any) => {
    if (
      res.data.error &&
      res.data.error.status === 'fail' &&
      res.data.error.statusCode === 400
    ) {
      dispatch({
        type: RESET_PASSWORD_VERIFICATION_TOKEN_FAILED,
        payload: 'resetPassword.error.tokenExpired',
      });
    } else if (res.data.status === 'success') {
      dispatch({
        type: RESET_PASSWORD_VERIFICATION_TOKEN_SUCCESS,
        payload: '',
      });
    }
  });
};

export const resetPasswordConfirm = (
  token: string,
  props: ResetPasswordState,
  callback: () => void
): AppThunk => (dispatch) => {
  const { newState, valid } = validateFieldsResetPassword(props);
  dispatch({ type: RESET_PASSWORD_VALIDATION_PROPS, newState });
  if (valid) {
    dispatch({
      type: RESET_PASSWORD_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });

    resetPassword(token, newState).then((res: any) => {
      if (res.data.status === 'success') {
        setTimeout(() => {
          dispatch({
            type: RESET_PASSWORD_PROPS_CHANGED,
            prop: 'loading',
            value: false,
          });

          callback();
        }, 2000);
      } else if (res.status === 400) {
        dispatch({
          type: RESET_PASSWORD_PROPS_CHANGED,
          prop: 'errorMessage',
          value: 'resetPassword.error.tokenExpired',
        });
        dispatch({
          type: RESET_PASSWORD_PROPS_CHANGED,
          prop: 'loading',
          value: false,
        });
      } else {
        dispatch({
          type: RESET_PASSWORD_PROPS_CHANGED,
          prop: 'loading',
          value: false,
        });
      }
    });
  }
};

export const resetPasswordIntialState = (): ResetPasswordInitialStateAction => {
  return { type: RESET_PASSWORD_INITIAL_STATE };
};
